// third-party
import _ from 'underscore';

import { createJsonLocalStorage } from 'js/common/services/localStorage';

import * as backboneReduxSync from '../backboneReduxSync';
import * as app from './app';
import * as viewer from './viewer';

const {
  actionTypes: { SYNC_CHANGES_FROM_BACKBONE, SYNC_FROM_BACKBONE },
} = backboneReduxSync;

// We store some settings as "per project" and some settings as "per user" so we require 2 local stores
let localStorageInstanceForGroup;
const createLocalStorageKeyForGroup = (groupId) => `MMS.Monitoring.UIPreferences.${groupId}`;
const getLocalStorageForGroup = (rootState) => {
  const groupId = app.getActiveGroupId(rootState);
  if (!groupId) {
    return null;
  }

  const key = createLocalStorageKeyForGroup(groupId);

  if (!localStorageInstanceForGroup || localStorageInstanceForGroup.key !== key) {
    localStorageInstanceForGroup = createJsonLocalStorage(key);
  }

  return localStorageInstanceForGroup;
};

let localStorageInstanceForUser;
const createLocalStorageKeyForUser = (userId) => `MMS.UIPreferences.user.${userId}`;
const getLocalStorageForUser = (rootState) => {
  const userId = viewer.getId(rootState);
  if (!userId) {
    return null;
  }

  const key = createLocalStorageKeyForUser(userId);

  if (!localStorageInstanceForUser || localStorageInstanceForUser.key !== key) {
    localStorageInstanceForUser = createJsonLocalStorage(key);
  }

  return localStorageInstanceForUser;
};

const SYNC_FROM_LOCAL_STORAGE = 'uiPreferences/SYNC_FROM_LOCAL_STORAGE';
const SET_DEPLOYMENT_VIEW = 'uiPreferences/SET_DEPLOYMENT_VIEW';
const SET_TABLE_FILTER = 'uiPreferences/SET_TABLE_FILTER';
const SET_SHOW_MONGOD_SERVERS = 'uiPreferences/SET_SHOW_MONGOD_SERVERS';
const SET_SHOW_MONGOS_SERVERS = 'uiPreferences/SET_SHOW_MONGOS_SERVERS';
const SET_SHOW_CONFIGS_SERVERS = 'uiPreferences/SET_SHOW_CONFIGS_SERVERS';
const SET_SUPPRESS_MULTI_FACTOR_AUTH_WARNING = 'uiPreferences/SET_SUPPRESS_MULTI_FACTOR_AUTH_WARNING';
const SET_SUPPRESS_UBUNTU_LIBCURL_WARNING = 'uiPreferences/SET_SUPPRESS_UBUNTU_LIBCURL_WARNING';
const SET_SUPPRESS_MAINTENANCE_UPGRADE_WARNING = 'uiPreferences/SET_SUPPRESS_MAINTENANCE_UPGRADE_WARNING';
const SET_SUPPRESS_ATLAS_DEPLOYMENT_BANNER = 'uiPreferences/SET_SUPPRESS_ATLAS_DEPLOYMENT_BANNER';
const SET_SUPPRESS_FOUR_TWO_BACKUP_BANNER = 'uiPreferences/SET_SUPPRESS_FOUR_TWO_BACKUP_BANNER';
const SET_SUPPRESS_FOUR_TWO_BACKUP_AGENT_UPGRADE_BANNER =
  'uiPreferences/SET_SUPPRESS_FOUR_TWO_BACKUP_AGENT_UPGRADE_BANNER';
const SET_REGISTRATION_DATA = 'uiPreferences/SET_REGISTRATION_DATA';
const SET_PRIVATE_IP_MODE = 'uiPreferences/SET_PRIVATE_IP_MODE';
const SET_SUPPRESS_PRIVATE_IP_MODE_WARNING = 'uiPreferences/SET_SUPPRESS_PRIVATE_IP_MODE_WARNING';
const SET_EXPAND_ALL_DEPLOYMENT_CLUSTER_CARDS = 'uiPreferences/SET_EXPAND_ALL_DEPLOYMENT_CLUSTER_CARDS';
const SET_LOCAL_CURRENCY = 'uiPreferences/SET_CURRENCY';

const groupPreferencesFields = {
  deploymentView: 'deployment/topology',
  deploymentProcessesView: 'deployment/topology',
  tableFilter: 'mongod',
  showMongodServers: true,
  showMongosServers: true,
  showConfigsServers: true,
  suppressUpgradeWarning: false,
  suppressAtlasDeploymentBanner: false,
  suppressFourTwoBackupBanner: false,
  suppressFourTwoBackupAgentUpgradeBanner: false,
  registrationData: {},
  suppressPrivateIpModeWarning: false,
};

const userPreferencesFields = {
  suppressMultiFactorAuthWarning: false,
  suppressUbuntuLibcurlWarning: false,
  expandAllDeploymentClusterCards: false,
};

const groupPreferencesKeys = Object.keys(groupPreferencesFields);
const userPreferencesKeys = Object.keys(userPreferencesFields);

interface RegistrationData {
  csrfTime?: string;
  csrfToken?: string;
  email?: string;
  groupId?: string;
  initialLogin?: string;
  isInviteFlow?: boolean;
  nds?: string;
  needsMfa?: string;
  uId?: string;
  userId?: string;
  user_intent?: string;
  username?: string;
}

interface UIPreferencesState {
  deploymentView: string;
  deploymentProcessesView: string;
  tableFilter: string;
  showMongodServers: boolean;
  showMongosServers: boolean;
  showConfigsServers: boolean;
  suppressUpgradeWarning: boolean;
  suppressAtlasDeploymentBanner: boolean;
  suppressFourTwoBackupBanner: boolean;
  suppressFourTwoBackupAgentUpgradeBanner: boolean;
  suppressMultiFactorAuthWarning: boolean;
  suppressUbuntuLibcurlWarning: boolean;
  registrationData: RegistrationData;
  privateIpMode: {
    enabled: boolean;
  };
  suppressPrivateIpModeWarning: boolean;
  expandAllDeploymentClusterCards: boolean;
  localCurrency: string;
}

const initialState: UIPreferencesState = {
  deploymentView: 'deployment/topology',
  deploymentProcessesView: 'deployment/topology',
  tableFilter: 'mongod',
  showMongodServers: true,
  showMongosServers: true,
  showConfigsServers: true,
  suppressUpgradeWarning: false,
  suppressAtlasDeploymentBanner: false,
  suppressFourTwoBackupBanner: false,
  suppressFourTwoBackupAgentUpgradeBanner: false,
  suppressMultiFactorAuthWarning: false,
  suppressUbuntuLibcurlWarning: false,
  registrationData: {},
  privateIpMode: { enabled: false },
  suppressPrivateIpModeWarning: false,
  expandAllDeploymentClusterCards: false,
  localCurrency: '₹ INR',
};
const keysStoredInLocalStorage = Object.keys(initialState);

const backboneToReduxKeyMapping = _.reduce(
  keysStoredInLocalStorage,
  (acc, key) => {
    acc[key] = key;
    return acc;
  },
  {}
);

// Reducer
export default function uiPreferencesReducer(state = initialState, action) {
  switch (action.type) {
    case SYNC_FROM_LOCAL_STORAGE: {
      const whitelistedData = _.pick(action.payload, groupPreferencesKeys);
      return {
        ...state,
        ...whitelistedData,
      };
    }
    case SET_DEPLOYMENT_VIEW: {
      return {
        ...state,
        deploymentView: action.payload,
      };
    }
    case SET_TABLE_FILTER: {
      return {
        ...state,
        tableFilter: action.payload,
      };
    }
    case SET_SHOW_MONGOD_SERVERS: {
      return {
        ...state,
        showMongodServers: action.payload,
      };
    }
    case SET_SHOW_MONGOS_SERVERS: {
      return {
        ...state,
        showMongosServers: action.payload,
      };
    }
    case SET_SHOW_CONFIGS_SERVERS: {
      return {
        ...state,
        showConfigsServers: action.payload,
      };
    }
    case SET_SUPPRESS_MULTI_FACTOR_AUTH_WARNING: {
      return {
        ...state,
        suppressMultiFactorAuthWarning: action.payload,
      };
    }
    case SET_SUPPRESS_UBUNTU_LIBCURL_WARNING: {
      return {
        ...state,
        suppressUbuntuLibcurlWarning: action.payload,
      };
    }
    case SET_SUPPRESS_MAINTENANCE_UPGRADE_WARNING: {
      return {
        ...state,
        suppressUpgradeWarning: action.payload,
      };
    }
    case SET_SUPPRESS_ATLAS_DEPLOYMENT_BANNER: {
      return {
        ...state,
        suppressAtlasDeploymentBanner: action.payload,
      };
    }
    case SET_SUPPRESS_FOUR_TWO_BACKUP_BANNER: {
      return {
        ...state,
        suppressFourTwoBackupBanner: action.payload,
      };
    }
    case SET_SUPPRESS_FOUR_TWO_BACKUP_AGENT_UPGRADE_BANNER: {
      return {
        ...state,
        suppressFourTwoBackupAgentUpgradeBanner: action.payload,
      };
    }
    case SET_REGISTRATION_DATA: {
      return {
        ...state,
        registrationData: action.payload,
      };
    }
    case SET_PRIVATE_IP_MODE: {
      return {
        ...state,
        privateIpMode: action.payload,
      };
    }
    case SET_SUPPRESS_PRIVATE_IP_MODE_WARNING: {
      return {
        ...state,
        suppressPrivateIpModeWarning: action.payload,
      };
    }
    case SET_EXPAND_ALL_DEPLOYMENT_CLUSTER_CARDS: {
      return {
        ...state,
        expandAllDeploymentClusterCards: action.payload,
      };
    }
    case SET_LOCAL_CURRENCY: {
      return {
        ...state,
        localCurrency: action.payload,
      };
    }
    case SYNC_FROM_BACKBONE: {
      const localStorageInstanceForGroup = getLocalStorageForGroup(action.meta.state);
      const localStorageForUser = getLocalStorageForUser(action.meta.state);
      const nextState = backboneReduxSync.handleSyncFromBackbone(state, action, backboneToReduxKeyMapping);

      // On the first invocation of this event we don't have any activeGroupId, so we ignore saving this
      // to localStorage.
      if (localStorageInstanceForGroup) {
        localStorageInstanceForGroup.saveData(_.pick(nextState, groupPreferencesKeys));
      }
      if (localStorageForUser) {
        localStorageForUser.saveData(_.pick(nextState, userPreferencesKeys));
      }

      return nextState;
    }
    case SYNC_CHANGES_FROM_BACKBONE: {
      const localStorageInstanceForGroup = getLocalStorageForGroup(action.meta.state);
      const localStorageForUser = getLocalStorageForUser(action.meta.state);
      const nextState = backboneReduxSync.handleSyncChangesFromBackbone(state, action, backboneToReduxKeyMapping);

      // On the first invocation of this event we don't have any activeGroupId, so we ignore saving this
      // to localStorage.
      if (localStorageInstanceForGroup) {
        localStorageInstanceForGroup.saveData(_.pick(nextState, groupPreferencesKeys));
      }

      if (localStorageForUser) {
        localStorageForUser.saveData(_.pick(nextState, userPreferencesKeys));
      }

      return nextState;
    }
    default:
      return state;
  }
}

// Selectors

export const getDeploymentView = (state) => state.uiPreferences.deploymentView;
export const getDeploymentProcessesView = (state) => state.uiPreferences.deploymentProcessesView;
export const getTableFilter = (state) => state.uiPreferences.tableFilter;
export const showMongodServers = (state) => state.uiPreferences.showMongodServers;
export const showMongosServers = (state) => state.uiPreferences.showMongosServers;
export const showConfigsServers = (state) => state.uiPreferences.showConfigsServers;
export const suppressMultiFactorAuthWarning = (state) => state.uiPreferences.suppressMultiFactorAuthWarning;
export const suppressUbuntuLibcurlWarning = (state) => state.uiPreferences.suppressUbuntuLibcurlWarning;
export const suppressUpgradeWarning = (state) => state.uiPreferences.suppressUpgradeWarning;
export const suppressAtlasDeploymentBanner = (state) => state.uiPreferences.suppressAtlasDeploymentBanner;
export const suppressFourTwoBackupBanner = (state) => state.uiPreferences.suppressFourTwoBackupBanner;
export const suppressFourTwoBackupAgentUpgradeBanner = (state) =>
  state.uiPreferences.suppressFourTwoBackupAgentUpgradeBanner;
export const getRegistrationData = (state): RegistrationData => state.uiPreferences.registrationData;
export const getPrivateIpMode = (state) => state.uiPreferences.privateIpMode;
export const suppressPrivateIpModeWarning = (state) => state.uiPreferences.suppressPrivateIpModeWarning;
export const getExpandAllDeploymentClusterCards = (state) => state.uiPreferences.expandAllDeploymentClusterCards;
export const getLocalCurrency = (state): string => state.uiPreferences.localCurrency;

// Action Creators

export const syncFromLocalStorage = () => (dispatch, getState) => {
  const localStorageForGroup = getLocalStorageForGroup(getState());
  const localStorageForUser = getLocalStorageForUser(getState());

  const localStorageData = { ...localStorageForGroup.getData(), ...localStorageForUser.getData() };

  dispatch({
    type: SYNC_FROM_LOCAL_STORAGE,
    payload: localStorageData,
  });
};

export const setRegistrationData = (payload) => {
  return {
    type: SET_REGISTRATION_DATA,
    payload,
  };
};

// This creates an action creator that, after we dispatch and update the redux store,
// we then save that data in localStorage for persistence.
// The side effect here is saving to localStorage.
const createActionCreatorWithSideEffect = (actionType) => (payload) => (dispatch, getState) => {
  const localStorageForGroup = getLocalStorageForGroup(getState());
  const localStorageForUser = getLocalStorageForUser(getState());

  const response = dispatch({
    type: actionType,
    payload,
  });

  const data = getState().uiPreferences;
  localStorageForGroup.saveData(_.pick(data, groupPreferencesKeys));
  localStorageForUser.saveData(_.pick(data, userPreferencesKeys));

  return response;
};

export const setDeploymentView = createActionCreatorWithSideEffect(SET_DEPLOYMENT_VIEW);
export const setTableFilter = createActionCreatorWithSideEffect(SET_TABLE_FILTER);
export const setShowMongodServers = createActionCreatorWithSideEffect(SET_SHOW_MONGOD_SERVERS);
export const setShowMongosServers = createActionCreatorWithSideEffect(SET_SHOW_MONGOS_SERVERS);
export const setShowConfigsServers = createActionCreatorWithSideEffect(SET_SHOW_CONFIGS_SERVERS);
export const setSuppressMultiFactorAuthWarning = createActionCreatorWithSideEffect(
  SET_SUPPRESS_MULTI_FACTOR_AUTH_WARNING
);
export const setSuppressUbuntuLibcurlWarning = createActionCreatorWithSideEffect(SET_SUPPRESS_UBUNTU_LIBCURL_WARNING);

export const setSuppressMaintenanceUpgradeWarning = createActionCreatorWithSideEffect(
  SET_SUPPRESS_MAINTENANCE_UPGRADE_WARNING
);
export const setSuppressAtlasDeploymentBanner = createActionCreatorWithSideEffect(SET_SUPPRESS_ATLAS_DEPLOYMENT_BANNER);
export const setSuppressFourTwoBackupBanner = createActionCreatorWithSideEffect(SET_SUPPRESS_FOUR_TWO_BACKUP_BANNER);
export const setSuppressFourTwoBackupAgentUpgradeBanner = createActionCreatorWithSideEffect(
  SET_SUPPRESS_FOUR_TWO_BACKUP_AGENT_UPGRADE_BANNER
);
export const setPrivateIpMode = createActionCreatorWithSideEffect(SET_PRIVATE_IP_MODE);
export const setSuppressPrivateIpModeWarning = createActionCreatorWithSideEffect(SET_SUPPRESS_PRIVATE_IP_MODE_WARNING);
export const setRegistrationDataInReduxAndLocalStorage = createActionCreatorWithSideEffect(SET_REGISTRATION_DATA);
export const setExpandAllDeploymentClusterCards = createActionCreatorWithSideEffect(
  SET_EXPAND_ALL_DEPLOYMENT_CLUSTER_CARDS
);
export const setLocalCurrency = createActionCreatorWithSideEffect(SET_LOCAL_CURRENCY);

export { createLocalStorageKeyForGroup, createLocalStorageKeyForUser };
