import { useEffect, useReducer, useRef } from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Button from '@leafygreen-ui/button';
import Card from '@leafygreen-ui/card';
import { H2, H3 } from '@leafygreen-ui/typography';
import queryString from 'query-string';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { FieldAction, FieldNames } from '@packages/types/auth/field';
import { Search } from '@packages/types/auth/search';

import Accordion from '@packages/components/Accordion';
import AutoAdvanceInputs, { Icon as AutoAdvanceInputsIcon } from '@packages/components/AutoAdvanceInputs';
import LineDivider from '@packages/components/LineDivider';
import { ThirdPartyIntegrationLineDivider } from '@packages/components/ThirdPartyIntegration/styles/common';
import ThirdPartyIntegrationLayout from '@packages/components/ThirdPartyIntegration/ThirdPartyIntegrationLayout';
import ThirdPartyIntegrationPage from '@packages/components/ThirdPartyIntegration/ThirdPartyIntegrationPage';

import * as errorHelper from 'js/common/services/authErrorHelper';
import { AuthType, useAppUserParams } from 'js/common/context/AppUserContext';
import { useRequestParams } from 'js/common/context/RequestParamsContext';
import { user } from 'js/common/services/api';
import { justLoggedInRedirect } from 'js/common/utils/authRedirectUtils';
import { mq } from 'js/common/utils/mediaQueries';

import colors from 'js/auth/utils/palette';

import HeaderLogo from './HeaderLogo';
import Layout from './Layout';
import { header } from './styles/form';
import { MultiFactorAuthContainer } from './styles/multiFactorPage';

const ErrorMessage = styled.p`
  color: ${colors.warning};
`;

const cardStyles = css(
  mq({
    boxSizing: 'border-box',
    height: [184, 220, 228, 184],
    border: `1px solid ${colors.cardBorder}`,
    borderRadius: '4px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 4px 10px -4px rgba(184,196,194,0.5)',
    padding: '20px 0 20px 20px',
  })
);

const vercelCardStyles = css(
  mq({
    boxSizing: 'border-box',
    border: `1px solid ${colors.cardBorder}`,
    borderRadius: '4px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 4px 10px -4px rgba(184,196,194,0.5)',
    padding: '20px 20px 20px 20px',
  })
);

const CardHeadlineText = styled.div`
  color: ${colors.cardHeadlineText};
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
`;

const Reset2FALink = styled(Link)(() => ({
  display: 'block',
  color: colors.link,
  textDecoration: 'none',
  margin: '16px 0 20px',
  fontSize: '13px',
  lineHeight: '20px',
  letterSpacing: 0,
}));

const AccordionContainer = styled.div`
  margin-top: 24px;
`;

const ResendContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 15px 0;
`;

const resendButton = css(
  mq({
    flex: 1,
    height: [32, 40, 40, 32],
    justifyContent: 'center',

    '& + &': {
      marginLeft: 10,
    },
  })
);

const Subtitle = styled.div`
  font-size: 13px;
  color: ${colors.subtitleText};
  margin: 4px 0px 16px;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 21px;
`;

const Help = styled.div(() =>
  mq({
    fontSize: '13px',
    color: colors.text,
    marginTop: '24px',
    textAlign: ['justify', 'center', 'justify', 'justify'],

    '& a': {
      color: colors.link,
      paddingLeft: '5px',
      textDecoration: 'none',
    },
  })
);

const StyledLineDivider = styled(LineDivider)(
  mq({
    width: ['auto', 'calc(100vw - 32px)', 'calc(100vw - 450px)', 'auto'],
    margin: '20px auto',
  })
);

type IconType = 'accordionIcon' | 'cardIcon';

interface ReducerState {
  authType: AuthType;
  username: string;
  hasBackupPhone: boolean;
  accordionIcon: AutoAdvanceInputsIcon;
  cardIcon: AutoAdvanceInputsIcon;
  hasResentCode: boolean;
  hasResentBackupCode: boolean;
  accordionOpen: 'sendNewCode' | 'backupPhone' | 'recovery' | false;
  errorCode: string;
}

interface Params {
  authType: AuthType;
  username: string;
  hasBackupPhone: boolean;
}

interface SetParams {
  type: 'setParams';
  payload: Params;
}

interface ProcessMFAValidateErrorResponse {
  type: 'processMfaValidateErrorResponse';
  payload: { errorCode: string; icon: IconType };
}

interface LoadingAction {
  type: 'loading';
  payload: { loadingState: 'spinner' | 'complete'; icon: IconType };
}

interface ClearErrorAction {
  type: 'clearError';
}

const reducerState: ReducerState = {
  authType: null,
  username: '',
  hasBackupPhone: false,
  accordionIcon: '',
  cardIcon: '',
  hasResentCode: false,
  hasResentBackupCode: false,
  accordionOpen: false,
  errorCode: '',
};

function multiFactorReducer(
  state: ReducerState,
  action: SetParams | LoadingAction | FieldAction | ProcessMFAValidateErrorResponse | ClearErrorAction
) {
  switch (action.type) {
    case 'setParams': {
      const { authType, username, hasBackupPhone } = action.payload;
      return {
        ...state,
        authType,
        username,
        hasBackupPhone,
      };
    }
    case 'clearError': {
      return {
        ...state,
        errorCode: '',
      };
    }
    case 'loading': {
      const { loadingState, icon } = action.payload;
      return {
        ...state,
        [icon]: loadingState,
      };
    }
    case 'field': {
      const { field, value } = action.payload;
      return {
        ...state,
        [field]: typeof value !== 'undefined' ? value : true,
      };
    }
    case 'processMfaValidateErrorResponse': {
      const { errorCode, icon } = action.payload;
      return {
        ...state,
        [icon]: '',
        errorCode,
      };
    }
    default:
      return state;
  }
}

function getHeadlineText(authType) {
  const textMap = {
    google: {
      headlineText: 'Enter a code from Google Authenticator',
    },
    voice: {
      headlineText: 'Enter a code from a Voice/SMS number',
    },
    sms: {
      headlineText: 'Enter a code from a Voice/SMS number',
    },
  };

  return textMap[authType].headlineText;
}

const commonSubtitle = (
  <Subtitle>An authentication code has been sent to your device. Enter the code to continue.</Subtitle>
);

interface MultiFactorPageProps {
  location?: Pick<Location, 'search'>;
  windowLocation: Pick<Location, 'assign'>;
}

export default function MultiFactorPage({
  location: { search } = { search: '' },
  windowLocation = window.location,
}: MultiFactorPageProps) {
  const [
    {
      authType,
      username,
      hasBackupPhone,
      accordionIcon,
      cardIcon,
      hasResentCode,
      hasResentBackupCode,
      accordionOpen,
      errorCode,
    },
    dispatch,
  ] = useReducer(multiFactorReducer, reducerState);
  const parsedSearchLocation = queryString.parse(location.search) as Search;
  const { idpId, inviteToken, isVercelIntegration } = parsedSearchLocation;

  const { baasCentralUrl, onPrem, centralUrl, docsUrl, multiFactorAuthResetAllowed } = useRequestParams();

  const primaryCodeInputRef = useRef(null);
  const resendCodeInputRef = useRef(null);
  const backupInputRef = useRef(null);
  const recoveryInputRef = useRef(null);

  const { appUserParams, loadAppUserParams } = useAppUserParams();
  const { authType: authTypeParam, hasBackupPhone: hasBackupPhoneParam, username: usernameParam } = appUserParams;

  useEffect(() => {
    async function sendCode() {
      await loadAppUserParams();

      if (authTypeParam === 'voice' || authTypeParam === 'sms') {
        await user.multiFactorSendCode({ centralUrl });
      }

      dispatch({
        type: 'setParams',
        payload: {
          authType: authTypeParam,
          hasBackupPhone: hasBackupPhoneParam,
          username: usernameParam,
        },
      });
    }

    sendCode();
  }, [authTypeParam, hasBackupPhoneParam, usernameParam, centralUrl, loadAppUserParams]);

  const accordionToggleCreator = (name) =>
    dispatch({
      type: 'field',
      payload: { field: FieldNames.ACCORDION_OPEN, value: name === 'none' ? false : name },
    });

  const createOnCompleteHandler =
    (instanceProperty, icon: IconType) =>
    ({ value }) => {
      dispatch({ type: 'loading', payload: { icon, loadingState: 'spinner' } });

      user
        .validateMfa({
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ username: string; authcode: an... Remove this comment to see the full error message
          username,
          authcode: value,
          inviteToken,
          idpId,
          centralUrl,
          clientState: parsedSearchLocation,
        })
        .then(({ errorCode: errorCodeResponse, resource, mfaValidated }) => {
          if (errorCodeResponse !== 'NONE') {
            instanceProperty.current.reset();
            dispatch({
              type: 'processMfaValidateErrorResponse',
              payload: { errorCode: errorCodeResponse, icon },
            });

            if (errorHelper.hasMatchingErrorCode(errorCodeResponse) && resource) {
              windowLocation.assign(`${resource}?reason=${errorCodeResponse}`);
            }
          } else {
            dispatch({ type: 'loading', payload: { icon, loadingState: 'complete' } });
            dispatch({ type: 'clearError' });

            if (mfaValidated) {
              justLoggedInRedirect({
                baasCentralUrl,
                username,
                windowLocation,
                query: parsedSearchLocation,
                centralUrl,
              });
            }
          }
        });
    };

  const sendCode = (sendFn, field) => () => {
    dispatch({ type: 'field', payload: { field } });
    dispatch({ type: 'clearError' });
    sendFn({ username, centralUrl }).catch(({ errorCode }) => {
      dispatch({
        type: 'processMfaValidateErrorResponse',
        // This is only used from the accordion so we can pass the accordionIcon
        payload: { icon: 'accordionIcon', errorCode },
      });
    });
  };

  const resendCode = (sendFn) => {
    return sendCode(sendFn, 'hasResentCode');
  };

  const sendBackupCode = (sendFn) => {
    return sendCode(sendFn, 'hasResentBackupCode');
  };

  const isGoogleType = authType === 'google';

  if (!authType) {
    return null;
  }

  const renderMfaVerification = () => {
    return (
      <>
        {isVercelIntegration ? (
          <H3 css={header}>Two-Factor Authentication</H3>
        ) : (
          <H2 css={header}>Two-Factor Authentication</H2>
        )}
        {accordionIcon !== 'spinner' && errorCode && (
          <ErrorMessage>{errorHelper.getErrorMessageFromCode(errorCode)}</ErrorMessage>
        )}
        <Card css={isVercelIntegration ? vercelCardStyles : cardStyles}>
          <CardHeadlineText>{getHeadlineText(authType)}</CardHeadlineText>
          {commonSubtitle}
          <AutoAdvanceInputs
            inputsType="code"
            onComplete={createOnCompleteHandler(primaryCodeInputRef, 'cardIcon')}
            icon={cardIcon}
            ref={primaryCodeInputRef}
          />
          {multiFactorAuthResetAllowed && !isVercelIntegration && (
            <Reset2FALink to={onPrem ? '/reset/mfa/ops' : '/reset/mfa/atlas'}>
              Reset your two factor authentication
            </Reset2FALink>
          )}
        </Card>
        {!isGoogleType && (
          <>
            {isVercelIntegration ? (
              <ThirdPartyIntegrationLineDivider>or</ThirdPartyIntegrationLineDivider>
            ) : (
              <StyledLineDivider>or</StyledLineDivider>
            )}
            <Accordion
              active={accordionOpen === 'sendNewCode'}
              isOnWhite
              onHeadlineClick={() => accordionToggleCreator('sendNewCode')}
              headlineText="Send a new code"
            >
              <ResendContainer>
                <Button name="resendSMS" onClick={resendCode(user.authForceText)} css={resendButton}>
                  Resend via SMS
                </Button>
                <Button name="resendVoiceCall" onClick={resendCode(user.authForceCall)} css={resendButton}>
                  Resend via Voice Call
                </Button>
              </ResendContainer>
              {hasResentCode && commonSubtitle}
              {hasResentCode && (
                <AutoAdvanceInputs
                  inputsType="code"
                  onComplete={createOnCompleteHandler(resendCodeInputRef, 'accordionIcon')}
                  icon={accordionIcon}
                  ref={resendCodeInputRef}
                />
              )}
            </Accordion>
          </>
        )}
        {hasBackupPhone && (
          <AccordionContainer>
            <Accordion
              active={accordionOpen === 'backupPhone'}
              isOnWhite
              onHeadlineClick={() => accordionToggleCreator('backupPhone')}
              headlineText="Send a code to your backup phone"
            >
              <ResendContainer>
                <Button onClick={sendBackupCode(user.authForceBackupText)} css={resendButton}>
                  Send via SMS
                </Button>
                <Button onClick={sendBackupCode(user.authForceBackupCall)} css={resendButton}>
                  Send via Voice Call
                </Button>
              </ResendContainer>
              {hasResentBackupCode && commonSubtitle}
              {hasResentBackupCode && (
                <AutoAdvanceInputs
                  inputsType="code"
                  onComplete={createOnCompleteHandler(backupInputRef, 'accordionIcon')}
                  icon={accordionIcon}
                  ref={backupInputRef}
                />
              )}
            </Accordion>
          </AccordionContainer>
        )}
        <AccordionContainer>
          <Accordion
            active={accordionOpen === 'recovery'}
            isOnWhite
            onHeadlineClick={() => accordionToggleCreator('recovery')}
            headlineText="Enter a recovery code"
          >
            <Subtitle css={css({ marginTop: '-20px' })}>
              Use one-time codes generated in the settings area of the application.
            </Subtitle>
            {accordionOpen === 'recovery' && (
              <AutoAdvanceInputs
                inputsType="codeRecovery"
                onComplete={createOnCompleteHandler(recoveryInputRef, 'accordionIcon')}
                icon={accordionIcon}
                ref={recoveryInputRef}
              />
            )}
          </Accordion>
        </AccordionContainer>
        <Help>
          Need more help? View documentation on
          <a href={`${docsUrl}/core/two-factor-authentication`} target="_blank" rel="noopener noreferrer">
            Managing Your Two-Factor Authentication Options
          </a>
        </Help>
      </>
    );
  };

  if (isVercelIntegration) {
    return (
      <ThirdPartyIntegrationPage pageTitle="Two-Factor Authentication">
        <ThirdPartyIntegrationLayout>
          <form method="post">{renderMfaVerification()}</form>
        </ThirdPartyIntegrationLayout>
      </ThirdPartyIntegrationPage>
    );
  }

  return (
    <>
      <Helmet title="Two-Factor Authentication" />
      <Layout contentPlacement="right">
        <MultiFactorAuthContainer>
          <HeaderLogo />
          {renderMfaVerification()}
        </MultiFactorAuthContainer>
      </Layout>
    </>
  );
}
