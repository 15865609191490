import {
  OktaMfaFactor,
  OktaMfaFactorActivationResponse,
  OktaMfaFactorEnrollmentRequest,
  OktaMfaFactorVerificationRequest,
  OktaMfaFactorVerificationResponse,
} from '@packages/types/accountMultiFactorAuth';

import fetchWrapper from './fetchWrapper';

export function getUserFactors(): Promise<Array<OktaMfaFactor>> {
  return fetchWrapper('/account/mfa', {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function enrollFactor(
  mfaFactorEnrollmentRequest: OktaMfaFactorEnrollmentRequest,
  isDeleteFlow?: boolean
): Promise<OktaMfaFactor> {
  return fetchWrapper(`/account/mfa/${isDeleteFlow}`, {
    method: 'POST',
    body: JSON.stringify(mfaFactorEnrollmentRequest),
  }).then((response) => response.json());
}

export function activateFactor(
  mfaFactorActivationRequest: OktaMfaFactorVerificationRequest
): Promise<OktaMfaFactorActivationResponse> {
  return fetchWrapper(`/account/mfa/activate`, {
    method: 'POST',
    body: JSON.stringify(mfaFactorActivationRequest),
  }).then((response) => response.json());
}

export function verifyFactor(
  mfaFactorVerificationRequest: OktaMfaFactorVerificationRequest,
  isDeleteFlow?: boolean
): Promise<OktaMfaFactorVerificationResponse> {
  return fetchWrapper(`/account/mfa/verify/${isDeleteFlow}`, {
    method: 'POST',
    body: JSON.stringify(mfaFactorVerificationRequest),
  }).then((response) => response.json());
}

export function verifyPushFactorChallenge({
  factorId,
  transactionId,
}: {
  factorId: string;
  transactionId?: string;
}): Promise<OktaMfaFactorVerificationResponse> {
  return fetchWrapper(`/account/mfa/verify/${factorId}/transaction/${transactionId}`, {
    method: 'GET',
  }).then((response) => response.json());
}

export function deleteFactor({ factorId }: { factorId: String }) {
  return fetchWrapper(`/account/mfa/${factorId}`, { method: 'DELETE' });
}

export function setMfaEncouragementSnoozedDateToCurrentDate() {
  return fetchWrapper('/account/mfa/encouragement/snooze', {
    method: 'PATCH',
  });
}
