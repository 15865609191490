import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

export const spinner = keyframes({
  '0%': {
    transform: 'rotate(0deg)',
  },
  '100%': {
    transform: 'rotate(360deg)',
  },
});

export const Spinner = styled.img`
  margin: 12px 10px;
  height: 20px;
  width: 20px;
  animation: ${spinner} 1.3s linear infinite;
`;

export const Check = styled.img`
  vertical-align: middle;
  margin: 10px;
  height: 20px;
  width: 20px;
`;
