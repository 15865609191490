import Cookies from 'js-cookie';

import * as settings from '@packages/redux/modules/settings';
import * as viewer from '@packages/redux/modules/viewer';

import * as settingsHelpers from 'js/common/utils/settingsHelpers';
import { hideIntercom, showIntercom } from 'js/common/services/showHideIntercom';

import * as intercomSelectors from './reduxSelectors';

const constructIntercomSettingsForProject = (settingsModel) => {
  return {
    app_id: settingsModel.get('INTERCOM_APP_ID'),
    user_id: settingsModel.get('USER_AUID'),
    user_hash: settingsModel.get('INTERCOM_USER_HASH'),
    name: `${settingsModel.get('FIRST_NAME')} ${settingsModel.get('LAST_NAME')}`,
    email: settingsModel.get('PRIMARY_EMAIL'),
    created_at: settingsModel.get('USER_DATE_CREATED') ? settingsModel.get('USER_DATE_CREATED').getTime() / 1000 : null,
    trigger: 'none',
    company: {
      id: settingsModel.get('GROUP_ID'),
      name: settingsModel.get('GROUP_NAME'),
      plan: settingsModel.get('PLAN_MARKETING_NAME'),
      created_at: new Date(settingsModel.get('GROUP_CREATED')).getTime() / 1000,
      num_billable_server: settingsModel.get('SUMMARY_NUM_BILLABLE_SERVER'),
      num_cluster: settingsModel.get('SUMMARY_NUM_CLUSTER'),
      num_total_atlas_clusters: settingsModel.get('NUM_TOTAL_ATLAS_CLUSTERS'),
      num_paid_atlas_clusters: settingsModel.get('NUM_PAID_ATLAS_CLUSTERS'),
      data_size_bytes: (settingsModel.get('SUMMARY_DATA_SIZE_BYTES') || 0).toFixed(1),
      data_size_backed_up_bytes: (settingsModel.get('SUMMARY_DATA_SIZE_BACKED_UP_BYTES') || 0).toFixed(1),
      monthly_spend: settingsModel.get('SUMMARY_LAST_INVOICE_AMOUNT_CENTS')
        ? Math.round(settingsModel.get('SUMMARY_LAST_INVOICE_AMOUNT_CENTS') / 100)
        : null,
      support_level: settingsModel.get('SUMMARY_SUPPORT_LEVEL_LABEL'),
    },
    hide_default_launcher: !settingsHelpers.isNDSPlan(settingsModel.get('PLAN_TYPE')),
  };
};

const isIntercomEnabledForEnvAndGroupAndUser = (settingsModel) => {
  return (
    settingsModel.get('INTERCOM_ENABLED') &&
    settingsModel.get('INTERCOM_ENABLED_LOGGED_IN_PAGES') &&
    settingsModel.get('IS_GROUP_INTERCOM_ELIGIBLE') &&
    !settingsModel.get('HAS_GLOBAL_READ_ONLY')
  );
};

const constructIntercomSettingsForLoggedInUser = (reduxState) => {
  const createdAt = viewer.getCreated(reduxState);

  return {
    app_id: intercomSelectors.getIntercomAppId(reduxState),
    user_id: settings.getAuid(reduxState),
    user_hash: intercomSelectors.getIntercomUserHash(reduxState),
    name: `${viewer.getFirstName(reduxState)} ${viewer.getLastName(reduxState)}`,
    email: viewer.getPrimaryEmail(reduxState),
    created_at: createdAt ? new Date(createdAt).getTime() / 1000 : null,
    trigger: 'none',
  };
};

const isIntercomEnabledForLoggedInUser = (reduxState): boolean => {
  return (
    intercomSelectors.isIntercomEnabled(reduxState) &&
    intercomSelectors.isIntercomEnabledLoggedInPages(reduxState) &&
    !viewer.isGlobalReadOnly(reduxState) &&
    !settings.isOnPrem(reduxState)
  );
};

/* [TODO: JT] this and other functions in this file need to be updated to only accept `params` as the arg
  to make it the longer term solution should we decide to move away from redux across the entire app.
*/
const constructIntercomSettingsForLoggedOutUser = (reduxState, params = {}) => {
  return {
    app_id: (params as any).intercomAppId || intercomSelectors.getIntercomAppId(reduxState),
    trigger: 'none',
    hide_default_launcher: false,
  };
};

const isIntercomEnabledForLocation = (location) => {
  const disabledLocations = ['/register', '/vercel'];

  // disable intercom for /register and all Vercel pages
  // Vercel pages not using /vercel in path have `isVercelIntegration` query param
  return (
    !location.pathname ||
    (!disabledLocations.some((pathname) => location.pathname.includes(pathname)) &&
      !location.search.includes('isVercelIntegration'))
  );
};

const isIntercomEnabledForLoggedOutUser = (reduxState, params = {}, location) => {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'isIntercomEnabled' does not exist on typ... Remove this comment to see the full error message
  const { isIntercomEnabled, isOnPrem } = params;

  const intercomEnabledForLocation = isIntercomEnabledForLocation(location);
  const intercomEnabled = isIntercomEnabled ?? intercomSelectors.isIntercomEnabled(reduxState);

  const onPrem = isOnPrem ?? settings.isOnPrem(reduxState);

  return intercomEnabled && intercomEnabledForLocation && !onPrem;
};

export default {
  createWidgetForProject({ settingsModel }) {
    if (!isIntercomEnabledForEnvAndGroupAndUser(settingsModel)) {
      return;
    }

    const intercomSettings = constructIntercomSettingsForProject(settingsModel);
    (intercomSettings as any).widget = {
      activator: '#IntercomDefaultWidget',
    };

    if ((window as any).Intercom) {
      (window as any).Intercom('boot', intercomSettings);
    }
  },

  updateWidgetForProject({ settingsModel, trigger }: { settingsModel; trigger? }) {
    if (!isIntercomEnabledForEnvAndGroupAndUser(settingsModel)) {
      return;
    }

    const intercomSettings = constructIntercomSettingsForProject(settingsModel);

    if (trigger) {
      intercomSettings.trigger = trigger;
    }

    if ((window as any).Intercom) {
      (window as any).Intercom('update', intercomSettings);
    }
  },

  createWidgetForLoggedInUser(reduxState) {
    if (!isIntercomEnabledForLoggedInUser(reduxState)) {
      return;
    }

    const intercomSettings = constructIntercomSettingsForLoggedInUser(reduxState);
    (intercomSettings as any).widget = {
      activator: '#IntercomDefaultWidget',
    };

    if ((window as any).Intercom) {
      (window as any).Intercom('boot', intercomSettings);
    }
  },

  updateWidgetForLoggedInUser(reduxState) {
    if (!isIntercomEnabledForLoggedInUser(reduxState)) {
      return;
    }

    const intercomSettings = constructIntercomSettingsForLoggedInUser(reduxState);

    if ((window as any).Intercom) {
      (window as any).Intercom('update', intercomSettings);
    }
  },

  createWidgetForLoggedOutUser(reduxState, params = {}, location = {}) {
    if (!isIntercomEnabledForLoggedOutUser(reduxState, params, location)) {
      return;
    }

    const intercomSettings = constructIntercomSettingsForLoggedOutUser(reduxState, params);
    (intercomSettings as any).widget = {
      activator: '#IntercomDefaultWidget',
    };

    if ((window as any).Intercom) {
      (window as any).Intercom('boot', intercomSettings);
    }
  },

  updateWidgetForLoggedOutUser(reduxState, params = {}, location = {}) {
    const isIntercomEnabled = isIntercomEnabledForLoggedOutUser(reduxState, params, location);
    let intercomSettings = {};

    if (isIntercomEnabled) {
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 1-2 arguments, but got 3.
      intercomSettings = constructIntercomSettingsForLoggedOutUser(reduxState, params, isIntercomEnabled);
    } else {
      intercomSettings = {
        hide_default_launcher: true,
      };
    }

    if ((window as any).Intercom) {
      (window as any).Intercom('update', intercomSettings);
    }
  },

  clearWidgetChatHistory({ path = '/', domain = '.mongodb.com' } = {}) {
    // window.Intercom('shutdown') was not cleaning up the cookies, so this had to be manual
    Object.keys(Cookies.get())
      .filter((cookieName) => cookieName.startsWith('intercom-'))
      .forEach((cookieName) => Cookies.remove(cookieName, { path, domain }));
  },
  showIntercom,
  hideIntercom,
  isIntercomEnabledForLoggedInUser,
};
