import * as api from 'js/common/services/api';

// Action Types
const SET_USER_SECURITY = 'nds/userSecurity/SET_USER_SECURITY';

const initialState = {};

// Reducer
// eslint-disable-next-line no-multi-assign
export default function userSecurityReducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER_SECURITY: {
      const newState = {
        ...state,
        ...action.payload,
      };
      return newState;
    }
    default:
      return state;
  }
}

const getUserSecurityData = (state) => {
  return state.nds.userSecurity;
};

// Selectors
export const getUserSecurity = (state) => getUserSecurityData(state) || {};

// Action Creators
const setUserSecurity = (payload) => ({
  type: SET_USER_SECURITY,
  payload,
});
export { setUserSecurity };

export const loadUserSecurity = (groupId) => (dispatch) => {
  return api.nds.userSecurity.getUserSecurity(groupId).then((response) => dispatch(setUserSecurity(response)));
};
