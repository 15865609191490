import { EndpointReturnType } from 'baas-admin-sdk';
import heliotrope, {
  CommonIntegrations,
  ENVIRONMENT,
  HeliotropeReturn,
  InitOptions,
  SegmentUserObject,
  TrackOptions,
  UpdateStateOptions,
} from 'heliotrope';

import { Assignments, NonNullAssignment, TestGroupName, TestName } from '@packages/types/abTest';
import { ChartsUserRoleInfo } from '@packages/types/chartsUserRoles';
import { BasicClusterEventProperties } from '@packages/types/clusters/clusterTracking';
import { CLT_SIDEBAR_TRACK_CONTEXT, DbToolDrawer, ValidCommand } from '@packages/types/clusters/commandLineTools';
import { UserGoals } from '@packages/types/clusters/userGoals';
import { Title as DataModelingTemplateTitle } from '@packages/types/dataModeling';
import { TriggerNames as FlexConsultingTriggerNames } from '@packages/types/flexConsulting';
import { GroupId } from '@packages/types/groups';
import { LastDataExplorerVisitedEventProps, MonitoringIdType } from '@packages/types/metrics/lastDataExplorerVisited';
import { BackingCloudProvider, CloudProvider, InstanceSize } from '@packages/types/nds/provider';
import { RegionName } from '@packages/types/nds/region';

import { getAbTestPersistedProps } from 'js/common/utils/abTestUtil';
import { CloudTeams, sendError } from 'js/common/utils/bugsnag';
import getHeliotropeEnvironment from 'js/common/utils/getHeliotropeEnvironment';

/* eslint-disable camelcase */
export interface IdentifyParams {
  first_name: string;
  last_name: string;
  email: string;
  active_project_id: GroupId;
  is_cloud_manager_user: boolean;
  test_assignments: Assignments;
}

/* eslint-enable camelcase */

export const E2E_SDK_URL = '/static/cypress/support/assets/segment-sdk-mocked.js';

export type SegmentEvents = (typeof SEGMENT_EVENTS)[keyof typeof SEGMENT_EVENTS];

type TrackArgs = [SegmentEvents, TrackProperties, OptionsProperties?, ((...args: Array<any>) => void)?];
type PageArgs = [string, PageProperties?];
type IdentifyArgs = [object, number?];
type UpdateStateArgs = [UpdateStateOptions, object?];

interface InitProperties {
  analyticsEnabled: boolean;
  segmentId: string;
  appId: string;
  userId?: string;
  orgId?: string;
  groupId?: string;
  email?: string;
  testAssignments?: Array<NonNullAssignment>;
  appEnv: AppEnv;
  accountCentralUrl: string;
  shouldRequestAuid?: boolean;
}

export enum AppEnv {
  LOCAL = 'LOCAL',
  QA = 'QA',
  DEV = 'DEV',
  PROD = 'PROD',
  TEST = 'TEST',
  STAGING = 'STAGING',
  HOSTED = 'HOSTED',
}

export interface OptionsProperties {
  timeout?: number;
}

interface PersistedProperties {
  cloud_user_id?: string;
  org_id?: string;
  group_id?: string;
  experiment_ids?: Array<string>;
  experiment_variant_ids?: Array<string>;
  test_assignments?: Array<string>;
  email?: string;
}

interface AnalyticsProperties {
  track: (...args: TrackArgs) => Promise<HeliotropeReturn>;
  page: (...args: PageArgs) => Promise<HeliotropeReturn>;
  identify: (...args: IdentifyArgs) => Promise<HeliotropeReturn>;
  user: () => SegmentUserObject | undefined;
  updateState: (...args: UpdateStateArgs) => Promise<HeliotropeReturn>;
  reset: () => void;
}

const getIntegrations = (orgId?: string): CommonIntegrations => {
  let integrations;

  if (orgId) {
    integrations = buildIntegrationsObject(orgId);
  } else {
    integrations = {};
  }

  integrations['Google Analytics'] = true;

  return integrations;
};

const initializeAnalytics = async ({
  analyticsEnabled,
  segmentId,
  appId,
  userId,
  orgId,
  groupId,
  email,
  testAssignments,
  appEnv,
  accountCentralUrl,
  shouldRequestAuid = true,
}: InitProperties): Promise<void> => {
  if (!analyticsEnabled) {
    return;
  }
  const environment: ENVIRONMENT = getHeliotropeEnvironment(accountCentralUrl, appEnv);
  const initOptions: InitOptions = {
    enabled: analyticsEnabled,
    segmentId,
    appId,
    integrations: getIntegrations(orgId),
    canSetAuidCookie: true,
    environment,
    shouldRequestAuid,
  };

  if (environment === ENVIRONMENT.E2E) {
    initOptions.e2eSdkUrl = E2E_SDK_URL;
  }

  const persistedProperties: PersistedProperties = testAssignments ? getAbTestPersistedProps(testAssignments) : {};

  if (email) {
    persistedProperties.email = email;
  }

  if (userId) {
    persistedProperties.cloud_user_id = userId;
  }

  if (orgId) {
    persistedProperties.org_id = orgId;
  }

  if (groupId) {
    persistedProperties.group_id = groupId;
  }

  if (Object.keys(persistedProperties).length > 0) {
    initOptions.persistedProperties = persistedProperties;
  }
  try {
    await heliotrope.init(initOptions);
  } catch (error) {
    sendError({ error, team: CloudTeams.AtlasGrowth });
  }
};

const analytics: AnalyticsProperties = {
  track: async (...args) => {
    const [eventName, properties, options = {}] = args;
    const trackOpts: TrackOptions<TrackProperties> = { eventName, properties };

    // Add timeout if passed in:
    const { timeout } = options;
    if (timeout) {
      trackOpts.timeout = timeout;
    }

    try {
      // await needed for handling promise rejections as well as thrown errors
      return await heliotrope.track(trackOpts);
    } catch (error) {
      sendError({ error, team: CloudTeams.AtlasGrowth });
      return error;
    }
  },
  page: async (...args) => {
    const [pageName, properties] = args;

    try {
      return await heliotrope.page({ pageName, properties });
    } catch (error) {
      sendError({ error, team: CloudTeams.AtlasGrowth });
      return error;
    }
  },
  identify: async (...args) => {
    const [traits, timeout] = args;

    try {
      return await heliotrope.identify({ traits, timeout });
    } catch (error) {
      sendError({ error, team: CloudTeams.AtlasGrowth });
      return error;
    }
  },
  user: () => heliotrope.user(),
  updateState: async (newState, options) => {
    try {
      return await heliotrope.updateState(newState, options);
    } catch (error) {
      sendError({ error, team: CloudTeams.AtlasGrowth });
      return error;
    }
  },
  reset: () => heliotrope.reset(),
};

// cluster description metadata from clusterTrackingUtil.js
export interface ClusterDescriptionTrackProperties {
  cloud_provider?: string;
  region?: string;
  cross_region?: boolean;
  global_cluster?: boolean;
  instance_size?: string;
  disk_size_gb?: number;
  IOPS_speed?: number;
  auto_expand_storage?: boolean;
  db_version?: string;
  backup_selected?: boolean;
  backup_option?: string;
  sharding?: boolean;
  shard_count?: number;
  BI_connector?: boolean;
  BI_node_type?: string;
  encryption_key_management?: boolean;
  key_index_limit?: boolean;
  require_indexes?: boolean;
  server_side_JS?: boolean;
  cluster_id?: string;
  cluster_name?: string;
  cluster_unique_id?: string;
  data_size?: any;
  auto_scaling_compute_enabled?: boolean;
  auto_scaling_compute_scaledown_enabled?: boolean;
  auto_scaling_compute_max_instance?: string;
  auto_scaling_compute_min_instance?: string;
  termination_protection_enabled?: boolean;
}

/**
 * @deprecated
 *
 * Please do not extend this interface with further properties!
 *
 * Having one large tracking property interface with many optional properties leads to
 * loose type definitions and potentionally incorrect event tracking. Over time, this interface will be
 * whittled down and removed.
 *
 * Instead, please define a new interface specific to the track event you're introducing with as few optional properties
 * as possible. (Similar to RegionSelectedEvent below.) Comment each property as is sensible and ensure each property
 * name is separated by underscores (_) instead of being camelCased.
 *
 * Once a new interface is created, add it to the TrackProperties type union to use it in a `track` call downstream.
 * */
export interface TrackPropertiesLegacy extends ClusterDescriptionTrackProperties {
  context?: string;
  action?: string;
  value?: string;
  environment?: string;
  signup_source?: string;
  signup_method?: string;
  error_code?: string;
  error_message?: string;
  email?: string;
  first_name?: string;
  last_name?: string;
  company?: string;
  country?: string;
  isEdit?: boolean;
  pre_selected_tier?: InstanceSize;
  userAgent?: string;
  revenue?: number;
  quantity?: number;
  price?: number;
  sku?: string;
  name?: string;
  referrer?: string;
  has_project_name_changed?: boolean;
  has_company_name_changed?: boolean;
  preferred_programming_language?: string | undefined;
  optimizelyEndUserId?: string;
  url?: string;
  tck?: string;
  ad_id?: string;
  ad_id_set?: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_source?: string;
  utm_term?: string;
  utm_medium?: string;
  utm_adid?: string;
  utm_ad?: string;
  utm_ad_campaign_id?: string;
  gclid?: string;
  form_url?: string;
  hourly_cost?: string;
  tier?: string;
  error_count?: number;
  step_name?: string;
  nextUrl?: string;
  pathfinder_filter?: string;
  goal?: string | undefined;
  app_use_case?: string;
  app_use_case_other?: string;
  user_type?: string;
  is_temporary?: boolean;
  has_payment_method?: boolean;
  tooltip_id?: string;
  campaign?: string;
  search_string?: string;
  message?: string;
  multi_cloud_view_is_open?: boolean;
}

interface TriggersPreimageStateChangeEvent {
  new_preimage_state: boolean;
}

interface TriggersSavedEvent {
  enabled: boolean;
  trigger_type: string;
  skip_catchup_events: boolean;
  event_type: string;
  is_update: boolean;
}

interface DatabaseTriggersSaveEvent extends TriggersSavedEvent {
  ordered: boolean;
  op_type_insert: boolean;
  op_type_update: boolean;
  op_type_delete: boolean;
  op_type_replace: boolean;
  full_document: boolean;
  auto_resume: boolean;
}

interface TriggersDependencyConfigurationStartedEvent {
  from_view: string;
}

interface DataAPIEnableDisableEvent {
  context: string;
  action: string;
  tier: InstanceSize;
  cloudProvider: CloudProvider | BackingCloudProvider;
  region: RegionName;
}

interface DataAPISnippetCopiedEvent {
  context: string;
  action: string;
  snippetLanguage: string;
}

export interface ExperimentViewedBaseEvent extends Record<string, string | number> {
  test_name: TestName;
  // Note we are sending in TestGroupName under two params. test_group_id is being kept around for backwards compat,
  // and will be removed when other areas of the system have been updated.
  test_group_id: TestGroupName;
  test_group_name: TestGroupName;
  entity_type: NonNullAssignment['entityType'];
  entity_id: NonNullAssignment['entityId'];
  test_tag: NonNullAssignment['tag'];
  assignment_date: NonNullAssignment['assignmentDate'];
  test_group_database_id: NonNullAssignment['testGroupDatabaseId'];
  test_id: NonNullAssignment['testId'];
  test_assignment: string;
}

interface ExperimentViewedFlexUpsellExperimentProperties {
  trigger: FlexConsultingTriggerNames;
}

interface ExperimentViewedChartsAwarenessExperimentProperties {
  num_clusters: number;
}

interface LocalCurrencyExperimentProperties {
  currency_selected?: string;
  selected_currency?: string;
}

export type ExperimentViewedAdditionalProperties =
  | ExperimentViewedFlexUpsellExperimentProperties
  | ExperimentViewedChartsAwarenessExperimentProperties
  | undefined;

export type ExperimentEventViewed =
  | ExperimentViewedBaseEvent
  | (ExperimentViewedBaseEvent & ExperimentViewedAdditionalProperties);

// event fired when a region is selected in the cluster builder region picker
interface RegionSelectedEvent {
  // which cluster builder form the event was fired from
  deployment_type: string;
  // e.g. US_EAST_1
  region_key: string;
  // a human legible location for a region, e.g. N. Virginia
  region_location: string;
  // whether or not a free tier is available for the newly selected region
  free_tier_available: boolean;
  // if the selected region is a grayed out paid tier region (experiment AG-360)
  grayed_out_paid_tier: boolean;
  // if the selected region is a low carbon region (experiment AG-583)
  low_carbon_region: boolean;
}

interface ClusterCardTipClick {
  context: string;
  action: string;
  tip_position?: string;
  instance_size?: string;
}

interface LastVisitedDataExplorerUpdatedEvent {
  context: LastDataExplorerVisitedEventProps.CONTEXT;
  action: LastDataExplorerVisitedEventProps.ACTION;
  monitoring_id: string;
  monitoring_id_type: MonitoringIdType;
}

export interface PurchaseButtonClickedEvent {
  action: string;
  context: string;
  ctaRef?: string;
}

export interface PartnerLinkOrgButtonClickedEvent {
  action: string;
  context: string;
  org_id: string;
}

export interface FeatureUpdatesTriggerClickedEvent {
  action: string;
  context: string;
  copy: string;
  unread_count: string;
}

export interface TieredClusterSubmittedSuccessfullyEvent extends BasicClusterEventProperties {
  isEdit: boolean;
  pre_selected_tier?: InstanceSize;
  context: string;
  hourly_cost: string;
  region_autoselect: RegionName;
  user_country_code: string;
}

export interface ServerlessClusterSubmittedSuccessfullyEvent extends BasicClusterEventProperties {
  context: string;
  region_autoselect: RegionName;
  user_country_code: string;
}

type ClusterSubmittedSuccessfullyEvent =
  | ServerlessClusterSubmittedSuccessfullyEvent
  | TieredClusterSubmittedSuccessfullyEvent;

export enum FormType {
  STANDARD = 'standard',
  REGISTRATION = 'registration',
}

export enum EmailType {
  STANDARD = 'standard',
  BUSINESS = 'business',
}

export interface RegistrationFormSubmittedEvent {
  context: string;
  action: string;
  email_type: EmailType;
  form_type: FormType;
}

export interface IpWhitelistFormSubmittedEvent {
  context: string;
  adding_current_ip_address: boolean;
}

interface CLTDropdownBase {
  context: typeof CLT_SIDEBAR_TRACK_CONTEXT;
}

export interface CLTSidebarDrawerEvent extends CLTDropdownBase {
  action: 'Drawer Opened';
  drawer_name: DbToolDrawer;
}

export interface CLTSidebarCodeSampleEvent extends CLTDropdownBase {
  action: 'Code Sample Copied';
  code_sample_name: ValidCommand;
}

export interface CLTSidebarDropdownEvent extends CLTDropdownBase {
  action: 'Cluster Dropdown Changed';
}

export interface SurfaceUserGoalsLinkClickedEvent {
  action: string;
  context: 'User Goal Resources';
  section: UserGoals;
}

export interface SettingsToggleCheckedEvent {
  context: string;
  settings_name: string;
  checked: boolean;
}

export interface PagerDutyActivationEvent {
  configuration_method: string;
}

export interface CheckoutStepViewedEvent {
  step: 'Address' | 'Payment';
  percent_form_completed?: string;
}

export interface PaymentInfoErrorEvent {
  code_value: string;
  error_message: string;
}

export interface PaymentInfoSubmittedSuccessfullyEvent {
  code_value: string;
  brand: string | null;
}

export interface PaymentInfoEnteredEvent {
  brand: string | null;
}

export enum PaymentInfoStepName {
  Address = 'Address',
  Payment = 'Payment',
}

export interface PaymentInfoEditInitiatedEvent {
  step: PaymentInfoStepName;
  brand: string | null;
}

export interface DataModelingTemplatesSidebarToggled {
  action: 'Generate Data Sidebar Opened';
  context: 'Data Modeling Templates Page';
  dataset: DataModelingTemplateTitle;
}

export interface DataModelingTemplatesLoadDatasetClicked {
  action: 'Clicked Load Sample Data';
  context: 'Data Modeling Templates Page';
  dataset: DataModelingTemplateTitle;
}

export type DataModelingTemplatesEvents = DataModelingTemplatesSidebarToggled | DataModelingTemplatesLoadDatasetClicked;

interface PersonalizationWizardFormSubmittedEvent {
  atlas_search: boolean;
  metrics_monitoring: boolean;
  data_lake: boolean;
  charts: boolean;
  app_services: boolean;
  device_sync: boolean;
}

export interface MarketplaceFeedbackSubmitEvent {
  context: string;
  action: string;
  feedback_data: object;
}

export interface AtlasGoToCommandCompletedEvent {
  command: string;
}

export interface ResetPasswordEvent {
  context: string;
  action: string;
  username: string;
}

export interface DataAPIReturnTypeChangeEvent {
  context: string;
  action: string;
  return_type: EndpointReturnType;
}

export interface ClusterStarterTemplate {
  action: string;
  template_name: string;
}

export interface CartResurrectionTemplate {
  action: string;
  context: string;
  cluster_builder_url: string;
  instance_size: string;
  cluster_name: string;
  is_edit: boolean;
  cloud_provider: string;
  region: string;
  tier: string;
  is_shared: boolean;
  db_version: string;
  cluster_id: string;
}

interface MFAEncouragementFirstFactorSetup {
  factor_name: string;
}

interface MFAEncouragementSecondFactorSetup {
  factor_name: string;
}

export type ChartsActivationEvent = ChartsUserRoleInfo & { source: string };

interface CommandLineToolsButtonClickedEvent {
  is_open: boolean;
}

/**
 * The different modules displayed on Home Center page
 */
export enum HomeCenterModule {
  ActivityLog = 'Activity Log',
  Feedback = 'Feedback',
  ResourcesCenter = 'Resources Center',
  Toolkit = 'Toolkit',
  Deployments = 'Deployments',
}

/**
 * Purposefully duplicating these options from the UI implementation. If the
 * UI changes the analytics won't be effected.
 */
export enum ToolkitSelectionOptions {
  AppDevelopment = 'App development',
  DataManagement = 'Data management',
}

interface BaseHomeCenterModuleEvent {
  context: 'Atlas Home Center';
}

export interface HomeCenterFeedbackModuleDismissEvent extends BaseHomeCenterModuleEvent {
  home_center_module: HomeCenterModule.Feedback;
}

export interface HomeCenterFeedbackModuleSubmitEvent extends BaseHomeCenterModuleEvent {
  home_center_module: HomeCenterModule.Feedback;
  feedback_option_selected?: 'positive' | 'negative';
  feedback_text?: string;
}

export interface HomeCenterToolkitModuleSegmentControlClickEvent extends BaseHomeCenterModuleEvent {
  home_center_module: HomeCenterModule.Toolkit;
  toolkit_selection: ToolkitSelectionOptions;
}

export interface HomeCenterToolkitModuleItemClickEvent extends BaseHomeCenterModuleEvent {
  action: string;
  copy: string;
  home_center_module: HomeCenterModule.Toolkit;
}

export interface HomeCenterActivtyLogModuleClickAnchorElementEvent extends BaseHomeCenterModuleEvent {
  action: string;
  home_center_module: HomeCenterModule.ActivityLog;
}

// streamline onboarding experiment
interface AutoSetupActionEvent {
  context: string;
  action: string;
  automate_security?: boolean;
  load_sample_dataset?: boolean;
}

interface PlainTextPasswordToggledEvent {
  context: string;
  show_plain_text_password: boolean;
}

// A union of all possible tracking property shapes
export type TrackProperties =
  | TrackPropertiesLegacy
  | ExperimentEventViewed
  | TriggersPreimageStateChangeEvent
  | TriggersSavedEvent
  | DatabaseTriggersSaveEvent
  | TriggersDependencyConfigurationStartedEvent
  | RegionSelectedEvent
  | ClusterCardTipClick
  | LastVisitedDataExplorerUpdatedEvent
  | PurchaseButtonClickedEvent
  | ClusterSubmittedSuccessfullyEvent
  | RegistrationFormSubmittedEvent
  | DataAPIEnableDisableEvent
  | DataAPISnippetCopiedEvent
  | DataAPIReturnTypeChangeEvent
  | PartnerLinkOrgButtonClickedEvent
  | FeatureUpdatesTriggerClickedEvent
  | IpWhitelistFormSubmittedEvent
  | CLTSidebarDrawerEvent
  | CLTSidebarCodeSampleEvent
  | CLTSidebarDropdownEvent
  | LocalCurrencyExperimentProperties
  | SurfaceUserGoalsLinkClickedEvent
  | SettingsToggleCheckedEvent
  | PagerDutyActivationEvent
  | CheckoutStepViewedEvent
  | PaymentInfoErrorEvent
  | PaymentInfoSubmittedSuccessfullyEvent
  | PaymentInfoEnteredEvent
  | PaymentInfoEditInitiatedEvent
  | PersonalizationWizardFormSubmittedEvent
  | DataModelingTemplatesEvents
  | MarketplaceFeedbackSubmitEvent
  | AtlasGoToCommandCompletedEvent
  | ResetPasswordEvent
  | ClusterStarterTemplate
  | CartResurrectionTemplate
  | MFAEncouragementFirstFactorSetup
  | MFAEncouragementSecondFactorSetup
  | ChartsActivationEvent
  | HomeCenterActivtyLogModuleClickAnchorElementEvent
  | HomeCenterFeedbackModuleDismissEvent
  | HomeCenterFeedbackModuleSubmitEvent
  | HomeCenterToolkitModuleItemClickEvent
  | HomeCenterToolkitModuleSegmentControlClickEvent
  | CommandLineToolsButtonClickedEvent
  | AutoSetupActionEvent
  | PlainTextPasswordToggledEvent;

interface RegistrationPage {
  signup_source: string;
  isFromMp: boolean;
  marketplaceSource: string;
}

interface LoginPage {
  isFromMp: boolean;
  marketplaceSource: string;
}

interface GenericPageProperties {
  title?: string;
  url?: string;
  path?: string;
  search?: string;
  referrer?: string;
  signup_source?: string;
  userId?: string;
  resend?: boolean;
  context?: string;
}

// A union of all possible page property shapes
export type PageProperties = RegistrationPage | LoginPage | GenericPageProperties;

// The below are the only supported event names for Segment events
// Analytics should be sent with these event names, with more granular information as event properties
const SEGMENT_EVENTS = {
  UX_ACTION_PERFORMED: 'UX Action Performed',
  BUTTON_CLICKED: 'Button Clicked',
  DEPLOYMENT_SELECTED: 'Deployment Selected',
  TOOLTIP_VIEWED: 'Tooltip Viewed',
  MIGRATE_CLICKED: 'Migrate Clicked',
  MIGRATE_MODAL_EXITED: 'Migrate Model Exited',
  MIGRATE_MODAL_CLICKED_READY: 'Migrate Modal Clicked Ready',
  MIGRATE_RELATIONAL_DATABASE_LINK_CLICKED: 'Migrate from Relational Database Link Clicked',
  CREDENTIALS_PROVIDED: 'Credentials Provided',
  CUTOVER_STARTED: 'Cutover Started',
  REGISTRATION_FORM_SUBMITTED: 'Registration Form Submitted',
  REGISTRATION_FORM_SUBMITTED_BUSINESS_EMAIL: 'Registration Form Submitted - Business Email',
  REGISTRATION_FAILED: 'Registration Failed',
  REGISTRATION_SUCCESS_SEEN: 'Registration Success Seen',
  VERCEL_CONFIRMATION_SEEN: 'Vercel Confirmation Page Seen',
  TOS_ACCEPTANCE_FAILED: 'ToS Acceptance Failed',
  FORM_SUBMITTED: 'Form Submitted',
  DATABASE_USER_FORM_SUBMITTED: 'Database User Form Submitted',
  IP_WHITELIST_FORM_SUBMITTED: 'IP Whitelist Form Submitted',
  IP_WHITELIST_ENTRY_REMOVED: 'IP Whitelist Entry Removed',
  IP_WHITELIST_ENTRY_EDITED: 'IP Whitelist Entry Edited',
  EXPERIMENT_VIEWED: 'Experiment Viewed',
  PROJECT_SETTINGS_TOGGLE_CHECKED: 'Project Settings Toggle Checked',
  USER_SETTINGS_TOGGLE_CHECKED: 'User Settings Toggle Checked',
  MODAL_CLOSED: 'Modal Closed',
  MFA_ENCOURAGEMENT_SKIPPED: 'MFA Encouragement Skipped',
  MFA_ENCOURAGEMENT_FIRST_FACTOR_SETUP: 'MFA Encouragement First Factor Setup',
  MFA_ENCOURAGEMENT_SECOND_FACTOR_SETUP: 'MFA Encouragement Second Factor Setup',

  // events from billing overview page
  APPLY_CREDIT_MODAL_SHOWN: 'Apply Credit Modal Shown',
  APPLY_CREDIT_CODE_APPLIED_SUCCESSFULLY: 'Credit Activation Code Applied Successfully',

  // events only fired from the cluster builder (general)
  CLUSTER_SUBMITTED_SUCCESSFULLY: 'Cluster Submitted Successfully',
  CLUSTER_PROVISIONED: 'Cluster Provisioned',
  /**
   * CLUSTER_TIER_BUMPED: when user selects region/provider where tier unavailable => auto selects tier
   * @repro Provider change: Dedicated (N. Virginia) -> choose M700 -> select GCP -> notice M10 now chosen
   * @repro Region change: Dedicated (N. Virginia) -> choose M700 -> select Sau Paulo -> notice M10 now chosen
   */
  CLUSTER_TIER_BUMPED: 'Cluster Tier Bumped',

  // events only fired from the payment page
  CHECKOUT_STEP_COMPLETED: 'Checkout Step Completed',
  DEPRECATED_CODE_PATH: 'Deprecated Code Path',
  ORDER_COMPLETED: 'Order Completed',
  PAYMENT_INFO_ERROR: 'Payment Info Error',
  PAYMENT_FULL_PAGE_SHOWN: 'Payment Full-Page Shown',
  PAYMENT_FULL_PAGE_CLOSED: 'Payment Full-Page Closed',
  PAYMENT_INFO_EDIT_INITIATED: 'Payment Info Edit Initiated',

  LOAD_SAMPLE_DATA_CLICKED: 'Load Sample Data Clicked',
  LOAD_SAMPLE_DATA_CANCELLED: 'Load Sample Data Cancelled',
  LOAD_SAMPLE_DATA_STARTED: 'Load Sample Data Started',
  SUPPORT_PLAN_ACTIVATED: 'Support Plan Activated',
  SUPPORT_PLAN_CANCELLED: 'Support Plan Cancelled',
  CLOUD_MANAGER_PLAN_UPGRADED: 'Cloud Manager Plan Upgraded',

  // Data Explorer CRUD Operations
  DOCUMENT_INSERTED: 'Document Inserted',
  DOCUMENT_INSERTED_MANY: 'Many Documents Inserted via JSON',
  DOCUMENT_CLONED: 'Document Cloned',
  DOCUMENT_CLONED_MANY: 'Document Cloned with Multiple Insertions via JSON',
  DOCUMENT_UPDATED: 'Document Updated',
  DOCUMENT_DELETED: 'Document Deleted',
  QUERY_EXECUTED: 'Query Executed',
  NAMESPACE_CREATED: 'Namespace Created',
  INDEX_CREATED: 'Index Created',
  INDEX_DELETED: 'Index Deleted',
  NAMESPACE_DELETED: 'Namespace Deleted',
  READ_ONLY_AGGREGATION_EXECUTED: 'Read-only Aggregation Executed',
  READ_WRITE_AGGREGATION_EXECUTED: 'Read-write Aggregation Executed',
  SEARCH_AGGREGATION_EXECUTED: 'Search Aggregation Executed',

  // Peering
  PEERING_CONNECTION_SUBMITTED: 'Peering Connection Submitted',

  // MONGO NAV
  NAVIGATION_CLICKED: 'Navigation Clicked',
  TOP_NAV_SEARCH: 'Top Nav Search',

  // Online Archive
  VIEWED_ONLINE_ARCHIVE: 'Viewed Online Archive',

  // For all steppers:
  STEP_COMPLETED: 'Step Completed',

  // Cluster Card
  CLUSTER_CARD_VIEW_LOADED: 'Cluster Card View Loaded',

  // Recommend Atlas Search Banner
  RECOMMEND_ATLAS_SEARCH_BANNER_LOADED: 'Recommend Atlas Search Banner Loaded',

  // Cloud Feature Updates
  CLOUD_FEATURE_UPDATES_TRIGGER_CLICKED: 'Cloud Feature Updates Trigger Clicked',

  // Sample Data Load
  CLICKED_ADD_SAMPLE_DATA: 'Clicked Add Sample Data',

  // Local Currency
  CLICKED_CURRENCY_SELECTOR: 'Clicked Currency Selector',
  CLICKED_CURRENCY_TOGGLE: 'Clicked Currency Toggle',

  // Go To
  GO_TO_SHOWN: 'Atlas GoTo Shown',
  GO_TO_COMPLETED: 'Atlas GoTo Command Completed',
  GO_TO_TOAST_SHOWN: 'Atlas GoTo Toast Shown',

  // Cluster Edit Config Button
  CLUSTER_CARD_EDIT_CONFIG_BUTTON_OPENED: 'Cluster Card Edit Config Button Opened',
  CLUSTER_CARD_EDIT_CONFIG_BUTTON_CLICKED: 'Cluster Card Edit Config Button Clicked',

  // Leafygreen toast events
  TOAST_SHOWN: 'Toast Shown',
  TOAST_REDIRECT: 'Toast Redirect',

  // Marketplace
  ECOSYSTEM_VIEWED: 'Ecosystem',
  INTEGRATION_FEEDBACK_SUBMITTED: 'Integration Feedback Submitted',
  INTEGRATION_PAGE_VIEWED: 'Integration',
  INTEGRATION_CARD_CLICKED: 'Integration Card Clicked',
  FEEDBACK_CARD_CLICKED: 'Feedback Card Clicked',
  FEEDBACK_PAGE_VIEWED: 'Feedback',
  DOCUMENT_CARD_CLICKED: 'Document Card Clicked',
  BACK_BUTTON_CLICKED: 'Back Button Clicked',
  SIDE_NAV_CLICKED: 'Side Nav Clicked',
  TRENDING_SEE_MORE_CLICKED: 'Trending See More',
  POPULAR_SEE_MORE_CLICKED: 'Popular See More',
  INTEGRATIONS_AND_TOOLS_CLICKED: 'Integrations and Tools',
  SUBMIT_INTEGRATION_IDEA_LINK_CLICKED: 'Submit an Integration Idea Link',
  SUBMIT_YOUR_IDEA_CARD_CLICKED: 'Submit Your Idea Card',
  HAVE_AN_IDEA_CLICKED: 'Have an Idea',
  ALL_INTEGRATION_PAGE_VIEWED: 'All Integrations Page Viewed',

  // Cart Resurrection
  CLUSTER_CONFIGURATION_SAVED: 'Cluster Configuration Saved',
  CLUSTER_CONFIGURATION_RESET: 'Cluster Configuration Reset',

  // CDN Failed to Load
  CDN_BLOCKED: 'CDN Blocked',

  // Charts
  CHARTS_AUTOMATIC_ACTIVATION: 'Charts Activation Automatically Requested',

  // Home Center Events
  HOME_CENTER_FEEDBACK_CARD_DISMISSED: 'Feedback Card Dismissed',
  HOME_CENTER_FEEDBACK_SUBMITTED: 'Feedback Submitted',
  HOME_CENTER_TOOLKIT_TAB_TOGGLED: 'Toolkit Tab Toggled',

  // Vercel
  VERCEL_DATA_API_TOGGLE_STATUS: 'Vercel Data API Toggle Status',
  VERCEL_LINK_CLUSTER_CONFIRM: 'Vercel Link Cluster Confirm',
  VERCEL_UNLINK_CLUSER_CONFIRM: 'Vercel Unlink Cluster Confirm',

  // Streamlined Onboarding
  AUTO_SETUP_ACTION: 'Auto Setup Action Performed',
  PLAIN_TEXT_PASSWORD_IN_CONNECTION_STRING_TOGGLED: 'Plain Text Password in Connection String Toggled',

  // Data Services Triggers
  TRIGGERS_DEPENDENCY_CONFIGURATION_STARTED: 'Data Services Triggers Dependency Configuration Started',
  TRIGGERS_LOGS_SEARCHED: 'Triggers Logs Searched',
  TRIGGERS_SAVED: 'Triggers Saved',
  TRIGGERS_DEPENDENCY_UPLOADED: 'Triggers Dependencies node_modules Folder Uploaded',
} as const;

const PAGE_CALLS = {
  REGISTRATION_SUCCESS: 'Registration Success',
  SIGN_IN: 'Sign In',
  REGISTRATION: 'Registration',
  TERMS_OF_SERVICE: 'Terms of Service',
  ATLAS_SEARCH: 'Atlas Search',
  FLEX_CONSULTING: 'Flex Consulting',
  OPEN_PROJECT_ALERTS: 'Open Project Alerts',
  VERIFICATION_EMAIL: 'Verification Email',
  VERIFICATION_EMAIL_BANNER: 'Verification Email Banner',
  MANAGE_PROJECT_ALERTS: 'Manage Project Alerts',
  OPEN_ORG_ALERTS: 'Open Org Alerts',
  MANAGE_ORG_ALERTS: 'Manage Org Alerts',
  ALL_CLUSTERS: 'All Clusters',
  DATA_API: 'Data Api',
  DATA_LAKE_PIPELINE: 'Data Lake Pipeline',
  DATA_FEDERATION: 'Data Federation',
  CLUSTER_STARTER_TEMPLATES: 'Cluster Starter Templates',
  MFA_ENCOURAGEMENT_PROMPT: 'MFA Encouragement Prompt',
  LIVE_MIGRATION: 'Live Migration',
} as const;

const buildIntegrationsObject = (orgId: string) => ({
  Amplitude: {
    groups: {
      Organization: orgId,
    },
  },
});

const sanitizeQueryString = () => {
  const search = window.location.search.replace(/^\?/, '');

  let sanitized = '';

  search.split('&').forEach((keyeqval) => {
    const lrvalues = keyeqval.split('=');
    const key = decodeURI(lrvalues[0]);
    const rvalue = lrvalues[1];

    if (key === '_ga' || key === 'gclid' || key === 'adpos' || !!key.match(/^utm_/)) {
      sanitized += sanitized === '' ? '?' : '&';
      sanitized += encodeURI(key);
      sanitized += '=';
      sanitized += rvalue;
    }
  });

  return sanitized;
};

// Given an array of strings this function will return a concatenated string of all elements
// that are not empty nor ObjectID
// i.e. given an array of ['foo', 'bar', '55a06f300cc6908d8bb5632b'] it should return '/foo/bar'
const addPathElements = (arr, resultString) => {
  return arr.reduce((acc, pathElem) => {
    if (pathElem && !pathElem.match(/^[0-9A-Fa-f]+$/)) {
      // skip empties and hex-looking strings.
      acc += `/${pathElem}`;
    }
    return acc;
  }, resultString);
};

const dataExplorerPathRegex = 'explorer\\/((.+\\/){2})?(.+)?';

const sanitizeDataExplorerPath = (path) => {
  const pathMatch = path.match(dataExplorerPathRegex);
  // remove database and collection name
  if (pathMatch && pathMatch.index && pathMatch[1]) {
    let sanitizedPath = '';
    path.split(pathMatch[1]).map((subst) => (sanitizedPath += subst));
    return sanitizedPath;
  }
  // remove database name
  if (pathMatch && pathMatch[3]) {
    return path.slice(0, -pathMatch[3].length);
  }
  return path;
};

const sanitizeLocation = (loc = window.location) => {
  let sanitized = '';
  const pathDelimiterRegex = /^\|\/$/;
  sanitized = addPathElements(loc.pathname.replace(pathDelimiterRegex, '').split('/'), sanitized);

  if (loc.search) {
    sanitized += sanitizeQueryString();
  }

  if (loc.hash) {
    let hash = sanitizeDataExplorerPath(loc.hash);
    // Some backbone router navigate calls can occasionally strip out a leading slash.
    // Add it back in to make URLs consistent. This shouldn't be needed anymore once we've moved to React Router.
    if (!hash.startsWith('#/')) {
      hash = '#/' + hash.slice(1);
    }
    hash = hash.replace(pathDelimiterRegex, '').split('/');
    sanitized += hash[0];
    hash = hash.slice(1);
    sanitized = addPathElements(hash, sanitized);
  }

  return sanitized;
};

export default analytics;
export { initializeAnalytics, SEGMENT_EVENTS, PAGE_CALLS, buildIntegrationsObject, sanitizeLocation, ENVIRONMENT };

export type { HeliotropeReturn };
