import { StrictMode } from 'react';

import LeafyGreenProvider from '@leafygreen-ui/leafygreen-provider';
import { createBrowserHistory } from 'history';
import ReactDOM from 'react-dom';
// redux
import { Provider } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { CompatRouter } from 'react-router-dom-v5-compat';

import createStore from '@packages/redux/createStore';
import rootReducer from '@packages/redux/rootReducer';

import useAnalytics from '@packages/hooks/useAnalytics';
import useBugsnag from '@packages/hooks/useBugsnag';

import { AccountUserProvider } from 'js/common/context/AccountUserContext';
import { AppUserProvider } from 'js/common/context/AppUserContext';
import { RequestParamsProvider, useRequestParams } from 'js/common/context/RequestParamsContext';
import { sanitize } from 'js/common/utils/authRedirectUtils';
import { initializeAuthClient } from 'js/common/utils/oktaUtils';
// analytics
import { AppEnv, initializeAnalytics } from 'js/common/utils/segmentAnalytics';

import useIntercomService from 'js/auth/hooks/useIntercomService';
import processAuth from 'js/auth/utils/postAuthUtils';
import 'js/wdyr';

import AccountMultiFactorAuthFactorSetupPage from './components/AccountMultiFactorAuthFactorSetupPage';
import AccountMultiFactorAuthPage from './components/AccountMultiFactorAuthPage';
import AccountMultiFactorAuthSetupPage from './components/AccountMultiFactorAuthSetupPage';
import { DeviceAuthPage } from './components/DeviceAuthPage';
import { DeviceAuthSuccessPage } from './components/DeviceAuthSuccessPage';
import EmailVerificationPage from './components/EmailVerificationPage';
import IdentityVerificationPage from './components/IdentityVerificationPage';
import LoginPage from './components/LoginPage';
import MfaEncouragementPromptPage from './components/MfaEncouragementPromptPage';
import MultiFactorPage from './components/MultiFactorPage';
import MultiFactorSetupPage from './components/MultiFactorSetupPage';
import RegistrationPage from './components/RegistrationPage';
import RegistrationSuccessPage from './components/RegistrationSuccessPage';
import ResetMultiFactorPage from './components/ResetMultiFactorPage';
import ResetPasswordPage from './components/ResetPasswordPage';
import TermsOfServicePage from './components/TermsOfServicePage';
import UniversityPasswordResetPage from './components/UniversityPasswordResetPage';
import VercelClusterCreationPage from './components/VercelClusterCreationPage';
import VercelClusterLinkingPage from './components/VercelClusterLinkingPage';
import VercelConfirmationPage from './components/VercelConfirmationPage';
import VercelErrorHandlingPage from './components/VercelErrorHandlingPage';
import VercelLoginPage from './components/VercelLoginPage';
import VercelOrgSelectionPage from './components/VercelOrgSelectionPage';
import VercelRegistrationPage from './components/VercelRegistrationPage';

const browserHistory = createBrowserHistory({
  basename: 'account',
});
const { pathname, hash } = browserHistory.location;

/* we should get rid of url fragments (aka hashes) in the urls
  that could possibly be present from the /user#/(atlas|cloud|ops)/login redirect
  but preserve the hash values after the plan type to correctly redirect to their new locations
*/
if (hash) {
  const sanitizedLocation = sanitize(browserHistory.location);

  browserHistory.replace({
    pathname: sanitizedLocation.pathname,
    search: sanitizedLocation.search,
    hash: sanitizedLocation.hash,
  });
}

const {
  appEnv = AppEnv.PROD,
  onPrem,
  oktaEnabled,
  oktaAuthRedirectBase,
  oktaBaseUrl,
  analyticsEnabled,
  segmentClientSideWriteKey,
  accountMultiFactorAuthEnabled,
  accountCentralUrl,
} = window.REQUEST_PARAMS;

// the Okta auth client needs to be initialized before the app is rendered so we can't move this into `useEffect`.
// also, the reason for the oktaEnabled check outside of OM is due to evergreen config for e2e testing, where most
// tests run with okta disabled (and UserSvcDb implementation)
if (!onPrem && oktaEnabled) {
  initializeAuthClient({
    oktaRedirectBase: oktaAuthRedirectBase,
    oktaUrl: oktaBaseUrl,
  });
}

const getShouldRequestAuid = (): boolean => {
  // we are only initializing analytics on the registration success page
  // and not on other pages of the auth app
  const pathName = window.location.pathname;
  return pathName === '/account/register/success';
};

initializeAnalytics({
  analyticsEnabled,
  segmentId: segmentClientSideWriteKey,
  appId: 'auth',
  appEnv,
  accountCentralUrl,
  shouldRequestAuid: getShouldRequestAuid(),
});

function App() {
  const requestParams = useRequestParams();
  const reduxStore = createStore(rootReducer);
  useIntercomService(browserHistory);
  useBugsnag('Registration', requestParams);
  useAnalytics(browserHistory);

  if (pathname === '/oauth') {
    // handles all the post auth logic from oidc/callback return
    processAuth({ requestParams, location: browserHistory.location });
    return null;
  }

  return (
    <Provider store={reduxStore}>
      <RequestParamsProvider>
        <AccountUserProvider>
          <AppUserProvider>
            <Router history={browserHistory}>
              <CompatRouter>
                <Switch>
                  <Route path="/login/mfa" component={MultiFactorPage} exact />
                  <Route path="/login/vercel" component={VercelLoginPage} exact />
                  <Route path="/login" component={LoginPage} exact />
                  <Route path="/register" component={RegistrationPage} exact />
                  <Route path="/register/success" component={RegistrationSuccessPage} exact />
                  <Route path="/register/vercel" component={VercelRegistrationPage} exact />
                  <Route path="/register/:flow" component={RegistrationPage} exact />
                  <Route path="/vercel/confirm" component={VercelConfirmationPage} exact />
                  <Route path="/vercel/cluster/link" component={VercelClusterLinkingPage} exact />
                  <Route path="/vercel/cluster/create" component={VercelClusterCreationPage} exact />
                  <Route path="/vercel/cluster/error" component={VercelErrorHandlingPage} exact />
                  <Route path="/vercel/org/selection" component={VercelOrgSelectionPage} exact />
                  <Route path="/tos" component={TermsOfServicePage} />
                  <Route path="/reset/password/:tempId?" component={ResetPasswordPage} />
                  <Route path="/reset/university/password" component={UniversityPasswordResetPage} exact />
                  <Route path="/connect" component={DeviceAuthPage} exact />
                  <Route path="/connect/success" component={DeviceAuthSuccessPage} exact />
                  <Route path="/reset/mfa/:planType/:tempId?" component={ResetMultiFactorPage} exact />
                  <Route path="/mfa/setup" component={MultiFactorSetupPage} />
                  <Route path="/auth/verify" component={IdentityVerificationPage} />
                  <Route path="/email/verify" component={EmailVerificationPage} />
                  {accountMultiFactorAuthEnabled && (
                    <Route path="/security/mfa" component={AccountMultiFactorAuthPage} exact />
                  )}
                  {accountMultiFactorAuthEnabled && (
                    <Route path="/security/mfa/setup" component={AccountMultiFactorAuthSetupPage} exact />
                  )}
                  {accountMultiFactorAuthEnabled && (
                    <Route path="/encourage/mfa" component={MfaEncouragementPromptPage} exact />
                  )}
                  {accountMultiFactorAuthEnabled && (
                    <Route path="/security/mfa/setup/:factor" component={AccountMultiFactorAuthFactorSetupPage} exact />
                  )}
                  <Route
                    path="/"
                    component={({ location }) => (
                      <Redirect
                        to={{
                          ...location,
                          pathname: '/login',
                        }}
                      />
                    )}
                  />
                </Switch>
              </CompatRouter>
            </Router>
          </AppUserProvider>
        </AccountUserProvider>
      </RequestParamsProvider>
    </Provider>
  );
}

ReactDOM.render(
  <StrictMode>
    <LeafyGreenProvider>
      <App />
    </LeafyGreenProvider>
  </StrictMode>,
  document.getElementById('mms-body-application')
);
