import { ServerResponseUserProperties, UserProperties } from '@packages/types/user';
import { OIDCAuthType } from '@packages/types/user';

import fetchWrapper from '../fetchWrapper';

export default {
  getUsers(groupId: string): Promise<Array<ServerResponseUserProperties>> {
    return fetchWrapper(`/nds/${groupId}/users`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getValidCerts(groupId: string, username: string) {
    return fetchWrapper(`/nds/${groupId}/users/${encodeURIComponent(username)}/certs`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  createUser(groupId: string, user: UserProperties) {
    // If OIDC Auth type is NONE, do not send it in the request. This is to hide the new feature.
    // TODO: CLOUDP-170837, begin including OIDC auth type in each request.
    if (user.oidcAuthType === OIDCAuthType.NONE) {
      user.oidcAuthType = undefined;
    }

    return fetchWrapper(`/nds/${groupId}/users`, {
      method: 'POST',
      body: JSON.stringify(user),
    }).then((resp) => resp.json());
  },

  updateUser(groupId: string, user: UserProperties) {
    // If OIDC Auth type is NONE, do not send it in the request. This is to hide the new feature.
    // TODO: CLOUDP-170837, begin including OIDC auth type in each request.
    if (user.oidcAuthType === OIDCAuthType.NONE) {
      user.oidcAuthType = undefined;
    }

    return fetchWrapper(`/nds/${groupId}/users/${user.db}/${encodeURIComponent(user.user)}`, {
      method: 'PATCH',
      body: JSON.stringify(user),
    }).then((resp) => resp.json());
  },

  revokeCert(groupId, user, serialNumber) {
    const username = user.user && !user.user.includes('CN=') ? `CN=${user.user}` : user.user;
    return fetchWrapper(`/nds/${groupId}/users/${encodeURIComponent(username)}/certs/${serialNumber}`, {
      method: 'DELETE',
    });
  },

  deleteUser(groupId: string, user: UserProperties) {
    return fetchWrapper(`/nds/${groupId}/users/${user.db}/${encodeURIComponent(user.user)}`, {
      method: 'DELETE',
    });
  },
};
