import styled from '@emotion/styled';
import { palette } from '@leafygreen-ui/palette';

import { mq } from 'js/common/utils/mediaQueries';

export const Container = styled.div(() =>
  mq({
    display: 'flex',
    marginTop: '14px',
    flexDirection: ['row', 'column', 'column', 'row'],
  })
);

export const AccordionBodyTextContainer = styled.div`
  margin-left: 42px;
`;

export const InfoText = styled.div`
  color: ${palette.gray.dark3};
  line-height: 21px;
  letter-spacing: 0;
`;

export const Step = styled.div(() =>
  mq({
    flex: '1 1 auto',
    color: palette.gray.dark2,
    fontSize: '13px',
    lineHeight: '20px',
    letterSpacing: '0',
    marginTop: [0, '16px', '16px', 0],
    marginBottom: [0, '20px', '20px', 0],
  })
);

export const ErrorMessage = styled.p`
  color: ${palette.red.base};
  margin: 8px 0 0;
`;

export const AutoAdvanceInputsContainer = styled.div`
  margin-top: 8px;
`;
