import * as api from 'js/common/services/api';

// Actions Types
const SET_BACKUPS = 'nds/tenantBackup/SET_BACKUPS';
const SET_RESTORES = 'nds/tenantBackup/SET_RESTORES';
const SET_METADATA = 'nds/tenantBackup/SET_METADATA';

const initialState = {};

// Reducer
export default function tenantBackupReducer(state = initialState, action) {
  switch (action.type) {
    case SET_BACKUPS: {
      const newState = {
        ...state,
        snapshots: action.payload,
      };

      return newState;
    }
    case SET_RESTORES: {
      const newState = {
        ...state,
        restores: action.payload,
      };

      return newState;
    }
    case SET_METADATA: {
      const newState = {
        ...state,
        metadata: action.payload,
      };

      return newState;
    }
    default:
      return state;
  }
}

const getTenantBackupData = (state) => state.nds.tenantBackup;

// Selectors
export const getSnapshots = (state) => getTenantBackupData(state).snapshots || [];
export const getRestores = (state) => getTenantBackupData(state).restores || [];
export const getMetadata = (state) => getTenantBackupData(state).metadata || {};

// Action Creators
export const loadSnapshots = (groupId, clusterUniqueId) => (dispatch) => {
  return api.nds.tenantBackup.getSnapshots(groupId, clusterUniqueId).then((response) => {
    dispatch({
      type: SET_BACKUPS,
      payload: response,
    });
  });
};

export const loadRestores = (groupId, clusterUniqueId) => (dispatch) => {
  return api.nds.tenantBackup.getRestores(groupId, clusterUniqueId).then((response) => {
    dispatch({
      type: SET_RESTORES,
      payload: response,
    });
  });
};

export const loadMetadata = (groupId, clusterUniqueId) => (dispatch) => {
  return api.nds.tenantBackup.getMetadata(groupId, clusterUniqueId).then((response) => {
    dispatch({
      type: SET_METADATA,
      payload: response,
    });
  });
};
