import { DataLakeTenant, StorageConfig, ValidationResponse } from '@packages/types/nds/dataLakes';

import fetchWrapper from 'js/common/services/api/fetchWrapper';

let controller = new AbortController();
const getControllerSignal = () => {
  controller.abort();
  controller = new AbortController();
  return controller.signal;
};

export default {
  getDataLakes(groupId: string, includeStorage = false) {
    return fetchWrapper(`/nds/dataLakes/${groupId}/tenants?includeStorage=${!!includeStorage}`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getDataLake(groupId, tenantName) {
    return fetchWrapper(`/nds/dataLakes/${groupId}/tenants/${tenantName}`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getAtlasSQLDataLake(groupId, clusterName) {
    return fetchWrapper(`/nds/dataLakes/${groupId}/atlasSQL/${clusterName}`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getDataLakeMetrics(groupId, tenantName) {
    return fetchWrapper(`/nds/dataLakes/${groupId}/tenants/${tenantName}/metrics`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  saveDataLake(groupId, dataLakeTenant, skipRoleValidation) {
    return fetchWrapper(`/nds/dataLakes/${groupId}/tenants?skipRoleValidation=${!!skipRoleValidation}`, {
      method: 'POST',
      body: JSON.stringify(dataLakeTenant),
    }).then((resp) => resp.json());
  },

  saveAtlasSQLDataLake(groupId, clusterName) {
    return fetchWrapper(`/nds/dataLakes/${groupId}/atlasSQL/${clusterName}`, {
      method: 'POST',
    }).then((resp) => resp.json());
  },

  updateDataLake(groupId, dataLakeTenantName, dataLakeTenant, skipRoleValidation) {
    return fetchWrapper(
      `/nds/dataLakes/${groupId}/tenants/${dataLakeTenantName}?skipRoleValidation=${!!skipRoleValidation}`,
      {
        method: 'PATCH',
        body: JSON.stringify(dataLakeTenant),
      }
    ).then((resp) => resp.json());
  },

  deleteDataLake(groupId, dataLakeTenantName) {
    return fetchWrapper(`/nds/dataLakes/${groupId}/tenants/${dataLakeTenantName}`, {
      method: 'DELETE',
    });
  },

  getDataLakeRegions(groupId) {
    return fetchWrapper(`/nds/dataLakes/${groupId}/availableRegions`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  validateStorageConfig(
    groupId: string,
    storageConfig: StorageConfig
  ): { response: Promise<ValidationResponse>; signal: AbortSignal } {
    const signal = getControllerSignal();
    const response = fetchWrapper(`/nds/dataLakes/${groupId}/validateStorage`, {
      method: 'POST',
      body: JSON.stringify(storageConfig),
      // only allow single validate request go at a time
      signal,
    }).then((resp) => resp.json());

    return {
      response,
      signal,
    };
  },

  // TODO: CLOUDP-170146 - verify cloud provider config details for s3 bucket access
  validateCloudProviderConfig(
    groupId: string,
    tenant: DataLakeTenant,
    skipRoleValidation: boolean
  ): Promise<DataLakeTenant> {
    return fetchWrapper(
      `/nds/dataLakes/${groupId}/validateCloudProviderConfig?skipRoleValidation=${skipRoleValidation}`,
      {
        method: 'POST',
        body: JSON.stringify(tenant),
      }
    ).then((resp) => resp.json());
  },
};
