export interface UserProperties {
  awsIAMType: AWSIAMType;
  db: string;
  deleteAfterDate?: string | null;
  deleteAfterHours?: number;
  expirationInterval?: {
    text: string;
  };
  hasUserToDNMapping: boolean;
  isEditable: boolean;
  ldapAuthType: LDAPAuthType;
  password?: string;
  roles: Array<Role>;
  user: string;
  x509Type: X509Type;
  oidcAuthType?: OIDCAuthType; // CLOUDP-170837 make this type required
}

export type ServerResponseUserProperties = Pick<
  UserProperties,
  | 'awsIAMType'
  | 'db'
  | 'deleteAfterDate'
  | 'hasUserToDNMapping'
  | 'isEditable'
  | 'ldapAuthType'
  | 'roles'
  | 'user'
  | 'x509Type'
  | 'oidcAuthType'
> & {
  labels: Array<Label>;
  scopes: Array<Scope>;
};

export interface CustomDbRole {
  roleName: string;
}

export interface DatabaseUser extends ServerResponseUserProperties {
  password?: string;
}

export interface UserCertificate {
  _id: string;
  groupId: string;
  subject: string;
  createdAt: string;
  notAfter: string;
  revokedAt?: string;
  issuerSerial: number;
}

export interface Role {
  collection: string | null;
  db: string | null;
  role: string;
}

export enum AuthMechanism {
  SCRAM_SHA = 'SCRAM_SHA',
  LDAP = 'LDAP',
  X509 = 'X509',
  MONGODB_AWS = 'MONGODB_AWS',
  OIDC = 'OIDC',
}

export enum LDAPAuthType {
  NONE = 'NONE',
  USER = 'USER',
  GROUP = 'GROUP',
}

export enum X509Type {
  NONE = 'NONE',
  CUSTOMER = 'CUSTOMER',
  MANAGED = 'MANAGED',
}

export enum AWSIAMType {
  NONE = 'NONE',
  USER = 'USER',
  ROLE = 'ROLE',
}

export enum OIDCAuthType {
  NONE = 'NONE',
  IDP_GROUP = 'IDP_GROUP',
}

export interface Label {
  key: string;
  value: string;
}

interface Scope {
  name: string;
  type: ScopeType;
}

export enum ScopeType {
  CLUSTER = 'CLUSTER',
  DATA_LAKE = 'DATA_LAKE',
}
