import { TenantBackupDeliveryMethod } from '@packages/types/nds/backup';

import fetchWrapper, { formParams } from '../fetchWrapper';

export default {
  getSnapshots(groupId, clusterUniqueId) {
    return fetchWrapper(`/nds/backup/tenant/${groupId}/${clusterUniqueId}/snapshots`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getRestores(groupId, clusterUniqueId) {
    return fetchWrapper(`/nds/backup/tenant/${groupId}/${clusterUniqueId}/restores`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getRestore(groupId, restoreId) {
    return fetchWrapper(`/nds/backup/tenant/${groupId}/restore/${restoreId}`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getMetadata(groupId, clusterUniqueId) {
    return fetchWrapper(`/nds/backup/tenant/${groupId}/${clusterUniqueId}/backup`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  createDownload(groupId, snapshotId) {
    const data = {
      snapshotId,
      deliveryMethod: TenantBackupDeliveryMethod.DOWNLOAD,
    };

    return fetchWrapper(`/nds/backup/tenant/${groupId}/restore`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams(data),
    }).then((resp) => resp.json());
  },

  performAutomatedRestore(groupId, snapshotId, clusterName, destinationGroupId, destinationClusterName) {
    const data = {
      snapshotId,
      deliveryMethod: TenantBackupDeliveryMethod.RESTORE,
      sourceClusterName: clusterName,
      targetGroupId: destinationGroupId,
      targetClusterName: destinationClusterName,
    };

    return fetchWrapper(`/nds/backup/tenant/${groupId}/restore`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams(data),
    }).then((resp) => resp.json());
  },

  // Note that this method does not use all arguments. This method signature is to keep consistent with the corresponding method in backupApi.js for generic use in AutomatedRestoreModal
  getRestoreDestinationItems(sourceGroupId, sourceClusterName, targetGroupId) {
    return fetchWrapper(`/automation/restore/tenant/${targetGroupId}/destinationReplicaSets`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },
};
