import _ from 'underscore';

/* eslint-disable no-console */

const storageImpl = {
  localStorage,
  sessionStorage,
};

function createStorageWrapper(storageType: keyof typeof storageImpl) {
  return {
    get type(): string {
      return storageType;
    },

    getItem(key: string, fallback?: string | null) {
      let result = fallback;
      try {
        result = storageImpl[storageType].getItem(key);
      } catch (error) {
        console.warn(`Unable to get data from ${storageType} for key: ${key}`);
        console.warn(error);
      }

      return result;
    },

    setItem(key: string, value: unknown) {
      try {
        storageImpl[storageType].setItem(key, String(value));
      } catch (error) {
        console.warn(`Unable to set data from ${storageType} for key: ${key}`);
        console.warn(error);
      }
    },

    removeItem(key: string) {
      try {
        storageImpl[storageType].removeItem(key);
      } catch (error) {
        console.warn(`Unable to remove data from ${storageType} for key: ${key}`);
        console.warn(error);
      }
    },
  };
}

const localStorageWrapper = createStorageWrapper('localStorage');
const sessionStorageWrapper = createStorageWrapper('sessionStorage');

const storageWrappers = {
  localStorage: localStorageWrapper,
  sessionStorage: sessionStorageWrapper,
};

function hasValues<T>(obj: T | {}): obj is T {
  return !_.isEmpty(obj);
}

interface JsonStorageOptions<T> {
  readonly typecheck?: (obj: unknown) => obj is T;
  readonly cleanData?: (obj: unknown) => T;
}

class JsonStorageClass<T> {
  private readonly _storageWrapper: ReturnType<typeof createStorageWrapper>;

  private readonly _key: string;

  private readonly _options: JsonStorageOptions<T>;

  constructor(
    storageWrapper: ReturnType<typeof createStorageWrapper>,
    key: string,
    options: JsonStorageOptions<T> = {}
  ) {
    this._storageWrapper = storageWrapper;
    this._key = key;
    this._options = options;
  }

  get key() {
    return this._key;
  }

  getData(): T | {} {
    const data = this._storageWrapper.getItem(this.key);
    if (data) {
      try {
        const parsed = JSON.parse(data);
        const { typecheck, cleanData } = this._options;
        if (cleanData != null) {
          // We were given a way to clean up whatever is stored into the expected shape,
          // so use that.
          return cleanData(parsed);
        }
        if (typecheck != null) {
          // We were given a way to confirm that the json at least matches the expected shape,
          // so check it to make sure it's correct.

          if (typecheck(parsed)) {
            return parsed;
          }
          return {};
        }

        // We don't have a way to typecheck, so just hope for the best
        return parsed;
      } catch (error) {
        console.warn(`Unable to parse ${this._storageWrapper.type} as JSON at key: ${this.key}`);
        console.warn(error);
      }
    }
    return {};
  }

  saveData(data: T): void {
    this._storageWrapper.setItem(this.key, JSON.stringify(data));
  }

  // Overriding some eslint rules because they don't understand TS function overloading
  /* eslint-disable no-dupe-class-members */
  /* eslint-disable lines-between-class-members */
  getItem<K extends keyof T>(jsonKey: K): T[K] | undefined;
  getItem<K extends keyof T>(jsonKey: K, fallback: T[K]): T[K];
  getItem<K extends keyof T>(jsonKey: K, fallback?: T[K]) {
    const data = this.getData();
    const value = hasValues(data) ? data[jsonKey] : undefined;
    return value ?? fallback;
  }
  /* eslint-enable lines-between-class-members */
  /* eslint-enable no-dupe-class-members */

  setItem<K extends keyof T>(jsonKey: K, jsonValue: T[K]) {
    // Technically, this.getData() might return {}, but setItem only makes sense when T extends Record<string, any>
    // so this cast should still be safe in all reasonable usecases.
    // TODO: Find some way to enforce that people are reasonable
    const data = this.getData() as T;
    data[jsonKey] = jsonValue;
    this.saveData(data);
  }

  removeItem(jsonKey: keyof T) {
    // Technically, this.getData() might return {}, but removeItem only makes sense when T extends Record<string, any>
    // so this cast should still be safe in all reasonable usecases.
    // TODO: Find some way to enforce that people are reasonable
    const data = this.getData() as T;
    delete data[jsonKey];
    this.saveData(data);
  }
}

// We don't want external users accessing the class itself, but they should be able to see the type
export type JsonStorage<T> = JsonStorageClass<T>;

/**
 * This creates a localStorage like object that allows you to store nested data as a JSON document but
 * providing an interface that is similar to the basic localStorage object.
 * By default localStorage or sessionStorage is a simple key/value store, where values can only be strings.
 * Sometimes it is desired to nest information within one key, which we can do by stringifying a JSON document.
 * Such that in localStorage we store:
 *      key: 'myKey'
 *      value: '"{"foo": "bar", "nested": "data"}"'
 */
function createJsonStorage(storageType: keyof typeof storageWrappers) {
  const storageWrapper = storageWrappers[storageType];

  return function <T extends {} = any>(storageKey: string, options?: JsonStorageOptions<T>): JsonStorage<T> {
    return new JsonStorageClass<T>(storageWrapper, storageKey, options);
  };
}

const createJsonLocalStorage = createJsonStorage('localStorage');
const createJsonSessionStorage = createJsonStorage('sessionStorage');

export default localStorageWrapper;

export const setLocalStorageImpl = (impl) => {
  storageImpl.localStorage = impl;
};

export const restoreLocalStorageImpl = () => {
  storageImpl.localStorage = localStorage;
};

export const setSessionStorageImpl = (impl) => {
  storageImpl.sessionStorage = impl;
};

export const restoreSessionStorageImpl = () => {
  storageImpl.sessionStorage = sessionStorage;
};

export { createJsonLocalStorage, createJsonSessionStorage };
