import { css } from '@emotion/react';
import Banner from '@leafygreen-ui/banner';
import Button from '@leafygreen-ui/button';
import Card from '@leafygreen-ui/card';
import { palette } from '@leafygreen-ui/palette';
import Tooltip from '@leafygreen-ui/tooltip';
import { Body, Link } from '@leafygreen-ui/typography';
import queryString from 'query-string';

import { Search } from '@packages/types/auth/search';

import useMfaFactors from '@packages/hooks/useMfaFactors';

import { MfaEncouragementLayout } from './MfaEncouragementLayout';
import MultiFactorAuthMethods from './MultiFactorAuthMethods';

const buttonStyle = css`
  margin: 24px 0 28px;
`;

export default function AccountMultiFactorAuthSetupPage({
  windowLocation = window.location,
  location: { search } = { search: '' },
}: {
  windowLocation: Pick<Location, 'assign'>;
  location?: Pick<Location, 'search'>;
}) {
  const parsedSearchLocation = queryString.parse(search) as Search;
  const { redirectUri } = parsedSearchLocation;

  const { factors, isLoading } = useMfaFactors();
  const userHasNoFactors = factors.length === 0;
  const userHasOneFactor = factors.length === 1;
  const userHasTwoOrMoreFactors = factors.length >= 2;

  const navigateToRedirectUri = () => {
    let urlSearchParams = new URLSearchParams(search);
    urlSearchParams.delete('redirectUri');

    windowLocation.assign(`${redirectUri || '/'}?${urlSearchParams.toString()}`);
  };

  let title;
  let bannerText;

  if (userHasNoFactors) {
    title = 'Secure your account by setting up MFA';
    bannerText = 'Enable your MFA and then set up an alternative MFA.';
  } else if (userHasOneFactor) {
    title = 'Enable another MFA method for your account';
    bannerText = 'Set up an alternative MFA.';
  } else {
    title = 'Multi-factor authentication setup is now complete';
    bannerText =
      'Your MFA methods have now been enabled. You can always add or remove more methods under your Account Application inside of the Security page.';
  }

  if (isLoading) {
    return null;
  }

  return (
    <MfaEncouragementLayout title={title}>
      <Banner
        variant={userHasTwoOrMoreFactors ? 'success' : 'info'}
        css={{ margin: '16px 0 20px', width: '560px', minHeight: 'auto' }}
      >
        {bannerText}
      </Banner>
      <Card
        css={{
          width: '542px',
          border: `1px solid ${palette.gray.light2}`,
          borderRadius: '7px',
        }}
      >
        <MultiFactorAuthMethods location={location} />
        <Body css={{ marginTop: '24px', color: palette.green.dark2 }} weight="medium">
          Why is this important for me?
        </Body>
        <Body css={{ marginTop: '4px' }}>
          MFA will enhance your account&apos;s security by requiring you to identify yourself by more than your email
          and password.
        </Body>
        <Link
          href="https://dochub.mongodb.org/core/atlas-mfa-protect-account"
          css={{ lineHeight: '10px', marginTop: '16px', color: palette.blue.base }}
        >
          Learn how this protects your account
        </Link>
      </Card>
      {(userHasNoFactors || userHasOneFactor) && (
        <Tooltip
          align="right"
          justify="start"
          trigger={
            <span>
              <Button css={buttonStyle} disabled>
                Continue
              </Button>
            </span>
          }
          triggerEvent="hover"
        >
          {userHasNoFactors
            ? 'You must set up two MFA methods to continue.'
            : 'You must set up an alternative MFA method to continue.'}
        </Tooltip>
      )}
      {userHasTwoOrMoreFactors && (
        <Button css={buttonStyle} variant="primary" onClick={navigateToRedirectUri}>
          Continue
        </Button>
      )}
    </MfaEncouragementLayout>
  );
}
