import { FieldNames, FieldPayload } from '@packages/types/auth/field';

import * as errorHelper from 'js/common/services/authErrorHelper';

import { ErrorField, ReducerAction, RegistrationFormFields } from 'js/auth/types/registration';

export interface ReducerState {
  formFields: RegistrationFormFields;
  errorMessage: string;
  errorField: ErrorField;
  captchaError: string;
  isCaptchaBoxChecked: boolean;
  formSubmitted: boolean;
  activationCodeInvalid: boolean;
  inviteTokenInvalid: boolean;
  isSocialSignupDisabled: boolean;
  isRetiredUsername: boolean;
}

export const reducerState: ReducerState = {
  formFields: {
    username: '',
    password: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    company: '',
    tos: false,
    honeypot: '',
    jobResponsibility: '',
    country: '',
  },
  errorMessage: '',
  errorField: '',
  captchaError: '',
  isCaptchaBoxChecked: false,
  formSubmitted: false,
  activationCodeInvalid: false,
  inviteTokenInvalid: false,
  isSocialSignupDisabled: true,
  isRetiredUsername: false,
};

export default function registrationReducer(state: ReducerState, action: ReducerAction): ReducerState {
  switch (action.type) {
    case 'formSubmitted': {
      return {
        ...state,
        formSubmitted: true,
      };
    }
    case 'reducerField': {
      const { field, value } = action.payload as FieldPayload;
      return {
        ...state,
        [field]: value,
        errorMessage: '', // reset the error message and errorField on every input change
        errorField: '',
      };
    }
    case 'field': {
      const { field, value } = action.payload as FieldPayload;
      return {
        ...state,
        formFields: {
          ...state.formFields,
          [field]: value,
        },
        errorMessage: '', // reset the error message and errorField on every input change
        errorField: '',
      };
    }
    case 'setError': {
      const errorCode = action.payload;
      const errorCodeStr = errorCode?.toString() ?? '';
      let retiredUsernameError = false;
      let errorFieldName: ErrorField = '';

      if (errorCodeStr === 'REGISTER_WITH_RETIRED_USERNAME') {
        retiredUsernameError = true;
      } else if (errorCodeStr.includes('PASSWORD')) {
        errorFieldName = FieldNames.PASSWORD;
      } else if (errorCodeStr.includes('USERNAME') || errorCodeStr.includes('EMAIL')) {
        errorFieldName = FieldNames.USERNAME;
      } else if (errorCodeStr === 'INVALID_GROUP_NAME') {
        errorFieldName = FieldNames.COMPANY;
      } else if (errorCodeStr.includes('FIRST_NAME')) {
        errorFieldName = FieldNames.FIRST_NAME;
      } else if (errorCodeStr.includes('LAST_NAME')) {
        errorFieldName = FieldNames.LAST_NAME;
      } else if (errorCodeStr.includes('PHONE_NUMBER')) {
        errorFieldName = FieldNames.PHONE_NUMBER;
      }

      return {
        ...state,
        errorMessage: errorHelper.getErrorMessageFromCodeForRegistration(errorCode),
        errorField: errorFieldName,
        formSubmitted: false,
        isCaptchaBoxChecked: false,
        isRetiredUsername: retiredUsernameError,
      };
    }
    case 'setInvalidActivationCode': {
      return {
        ...state,
        activationCodeInvalid: true,
      };
    }
    case 'setInvalidInviteToken': {
      return {
        ...state,
        inviteTokenInvalid: true,
      };
    }
    case 'processCaptchaError': {
      return {
        ...state,
        captchaError: errorHelper.getErrorMessageFromCodeForRegistration(action.payload),
        isCaptchaBoxChecked: false,
      };
    }
    case 'setCaptchaBoxChecked': {
      return {
        ...state,
        isCaptchaBoxChecked: action.payload,
      };
    }
    default:
      return state;
  }
}
