import { Variant as BannerVariant } from '@leafygreen-ui/banner';
import { produce } from 'immer';

import { FieldAction, FieldPayload } from '@packages/types/auth/field';

interface ChangeUsername {
  type: 'changeUsername';
}

interface ProcessIdpResponse {
  type: 'processIdpResponse';
  payload: {
    onlyUsernameShowing: boolean;
    errorCode: string;
    isSubmitDisabled: boolean;
  };
}

interface ProcessAuthErrorResponse {
  type: 'processAuthErrorResponse';
  payload: {
    errorCode: string;
    formSubmitting: boolean;
    bannerVariant?: BannerVariant;
    marketingUrl?: string;
  };
}

interface SetShowSupportLogout {
  type: 'setShowSupportLogout';
  payload?: true;
}

interface SetFormSubmitting {
  type: 'setFormSubmitting';
  payload: boolean;
}

interface IsVercelIntegration {
  type: 'isVercelIntegration';
  payload: string;
}

interface SetReCaptchaResponse {
  type: 'setReCaptchaResponse';
  payload: {
    reCaptchaResponse: string;
  };
}

interface SetReCaptchaV3Response {
  type: 'setReCaptchaV3Response';
  payload: {
    reCaptchaV3Response: string;
  };
}

interface BypassSso {
  type: 'bypassSso';
}

export type ReducerAction =
  | FieldAction
  | SetFormSubmitting
  | ProcessIdpResponse
  | SetShowSupportLogout
  | ProcessAuthErrorResponse
  | SetReCaptchaResponse
  | SetReCaptchaV3Response
  | BypassSso
  | ChangeUsername
  | IsVercelIntegration;

export interface ReducerState {
  username: string;
  password: string;
  onlyUsernameShowing: boolean;
  formSubmitting: boolean;
  // showSupportLogout: boolean; // TODO: AM CLOUDP-57726 uncomment and fix the support logout issue
  authErrorCode: string;
  reCaptchaResponse: null | string;
  reCaptchaV3Response?: null | string;
  bannerVariant?: BannerVariant;
  ssoBypassEnabled: boolean;
  differentUniversityPasswordError: boolean;
  marketingUrl?: string;
  isSubmitDisabled: boolean;
  isLoginDisabled: boolean;
}

export const reducerState: ReducerState = {
  username: '',
  password: '',
  onlyUsernameShowing: true,
  formSubmitting: false,
  // showSupportLogout: false, // TODO: AM CLOUDP-57726 uncomment and fix the support logout issue
  authErrorCode: '',
  bannerVariant: BannerVariant.Danger,
  reCaptchaResponse: null,
  reCaptchaV3Response: null,
  ssoBypassEnabled: false,
  differentUniversityPasswordError: false,
  marketingUrl: '',
  isSubmitDisabled: true,
  isLoginDisabled: true,
};

export const loginReducer = produce((draft: ReducerState, action: ReducerAction) => {
  switch (action.type) {
    case 'field': {
      const { field, value } = action.payload as FieldPayload;
      draft[field] = value;
      return;
    }
    case 'setFormSubmitting': {
      draft.formSubmitting = action.payload;
      return;
    }
    case 'processIdpResponse': {
      const { onlyUsernameShowing, errorCode, isSubmitDisabled } = action.payload;
      draft.onlyUsernameShowing = onlyUsernameShowing;
      draft.authErrorCode = errorCode;
      draft.isSubmitDisabled = isSubmitDisabled;
      return;
    }
    // TODO: AM CLOUDP-57726 uncomment and fix the support logout issue
    // case 'setShowSupportLogout': {
    //   draft.showSupportLogout = true;
    //   return;
    // }
    case 'processAuthErrorResponse': {
      const { errorCode, formSubmitting, bannerVariant, marketingUrl } = action.payload;
      const isUniversityPasswordError = errorCode === 'DIFFERENT_UNIVERSITY_PASSWORD';
      const isSubmitDisabled = errorCode === 'INVALID_USER_AUTH' || draft.onlyUsernameShowing;

      if (isUniversityPasswordError) {
        draft.differentUniversityPasswordError = true;
        return;
      }
      draft.marketingUrl = marketingUrl;
      draft.authErrorCode = errorCode;
      draft.bannerVariant = bannerVariant;
      draft.formSubmitting = formSubmitting;
      draft.isSubmitDisabled = isSubmitDisabled;
      return;
    }
    case 'setReCaptchaResponse': {
      const { reCaptchaResponse } = action.payload;

      draft.reCaptchaResponse = reCaptchaResponse;
      return;
    }
    case 'setReCaptchaV3Response': {
      const { reCaptchaV3Response } = action.payload;

      draft.reCaptchaV3Response = reCaptchaV3Response;
      return;
    }
    case 'bypassSso': {
      draft.ssoBypassEnabled = true;
      draft.onlyUsernameShowing = false;
      return;
    }
    case 'changeUsername': {
      draft.onlyUsernameShowing = true;
      draft.username = '';
      draft.password = '';
      draft.authErrorCode = '';
      draft.isSubmitDisabled = false;
      return;
    }
    default:
      return draft;
  }
}, reducerState);
