import { combineReducers } from 'redux';
import _ from 'underscore';

import { actionTypes } from '@packages/redux/backboneReduxSync';
import { createReducer, createScopedReducer } from '@packages/redux/reducerUtils';

import * as api from 'js/common/services/api';

import * as team from '../team';
// modules
import * as user from '../user';
import organizationGroupReducer, * as organizationGroup from './group';
import alertReducer from './alert';
import billingReducer from './billing';
import policyReducer from './policy';

const { SYNC_CHANGES_FROM_BACKBONE, SYNC_FROM_BACKBONE } = actionTypes;

// actions
const SET_ORGANIZATION = 'organization/SET_ORGANIZATION';

const SET_USERS = 'organization/SET_USERS';
const SET_TEAMS = 'organization/SET_TEAMS';
const REMOVE_TEAM = 'organization/REMOVE_TEAM';

const SET_API_USERS = 'organization/SET_API_USERS';
const ADD_API_USER = 'organization/ADD_API_USER';

const REMOVE_API_USER = 'organization/REMOVE_API_USER';

const SET_VAT_STATUS = 'organization/SET_VAT_STATUS';

const SET_ACTIVITIES = 'organization/SET_ACTIVITIES';

const SET_MULTI_FACTOR_AUTH_REQUIRED = 'organization/SET_MULTI_FACTOR_AUTH_REQUIRED';
const SET_PUBLIC_API_ACCESSLIST_REQUIRED = 'organization/SET_PUBLIC_API_ACCESSLIST_REQUIRED';
const SET_RESTRICT_EMPLOYEE_ACCESS = 'organization/SET_RESTRICT_EMPLOYEE_ACCESS';
const SET_CN_REGIONS_TERMS_OF_SERVICE_DATE = 'organization/SET_CN_REGIONS_TERMS_OF_SERVICE_DATE';

const SET_FEDERATION_SETTINGS = 'organization/SET_FEDERATION_SETTINGS';
const SET_DEFAULT_SERVER_TYPE = 'organization/SET_DEFAULT_SERVER_TYPE';

const SET_BYPASS_ACCESS_AUTO_RESTRICTION = 'organization/SET_BYPASS_ACCESS_AUTO_RESTRICTION';
const SET_IN_EMAIL_VERIFICATION_EXPERIMENT = 'organization/SET_IN_EMAIL_VERIFICATION_EXPERIMENT';

// Reducer

const organizationReducers = combineReducers({
  activities: createReducer([], {
    [SET_ACTIVITIES]: (state, action) => action.payload,
  }),
  data: createReducer(
    {},
    {
      [SET_ORGANIZATION]: (state, action) => action.payload,
      [SET_VAT_STATUS]: (state, action) => ({
        ...state,
        vatStatus: action.payload,
      }),
      [SET_MULTI_FACTOR_AUTH_REQUIRED]: (state, action) => ({
        ...state,
        multiFactorAuthRequired: action.payload,
      }),
      [SET_PUBLIC_API_ACCESSLIST_REQUIRED]: (state, action) => ({
        ...state,
        publicApiWhitelistRequired: action.payload,
      }),
      [SET_RESTRICT_EMPLOYEE_ACCESS]: (state, action) => ({
        ...state,
        restrictEmployeeAccess: action.payload,
      }),
      [SET_BYPASS_ACCESS_AUTO_RESTRICTION]: (state, action) => ({
        ...state,
        autoRestrictAccessInfo: {
          ...state.autoRestrictAccessInfo,
          bypassAccessAutoRestriction: action.payload,
        },
      }),
      [SET_CN_REGIONS_TERMS_OF_SERVICE_DATE]: (state, action) => ({
        ...state,
        acceptedCNRegionsTermsOfServiceDate: action.payload,
      }),
      [SET_FEDERATION_SETTINGS]: (state, action) => ({
        ...state,
        federationSettings: action.payload,
      }),
      [SET_DEFAULT_SERVER_TYPE]: (state, action) => ({
        ...state,
        defaultServerType: action.payload,
      }),
      [SET_IN_EMAIL_VERIFICATION_EXPERIMENT]: (state, action) => ({
        ...state,
        isInEmailVerificationExperiment: action.payload,
      }),
    }
  ),
  billing: billingReducer,
  group: organizationGroupReducer,
  alert: alertReducer,
  users: createReducer([], {
    [SET_USERS]: (state, action) => action.payload,
  }),
  apiUsers: createReducer([], {
    [SET_API_USERS]: (state, action) => action.payload,
    [ADD_API_USER]: (state, action) => [...state, action.payload],
    [REMOVE_API_USER]: (state, action) => state.filter((apiUserId) => apiUserId !== action.payload),
  }),
  teams: createReducer([], {
    [SET_TEAMS]: (state, action) => action.payload,
    [REMOVE_TEAM]: (state, action) => state.filter((id) => id !== action.payload),
  }),
  policies: policyReducer,
});

const scopedReducer = createScopedReducer((action) => action.meta && action.meta.orgId, organizationReducers);

const initialState = {};

// eslint-disable-next-line no-multi-assign
export default function organizationReducer(state = initialState, action) {
  if (scopedReducer.isScoped(action)) {
    return scopedReducer(state, action);
  }

  switch (action.type) {
    case organizationGroup.Actions.MOVE_GROUP: {
      const { oldOrgId, newOrgId } = action.meta;
      const newState = { ...state };
      if (!newState[newOrgId]) {
        newState[newOrgId] = { group: [] };
      }
      // Add to new org
      newState[newOrgId].group = [...newState[newOrgId].group, action.payload];
      // Remove from old org
      newState[oldOrgId].group = _.without(newState[oldOrgId].group, action.payload);
      return newState;
    }
    case SYNC_FROM_BACKBONE: {
      const settingsModel = action.payload;
      const org = settingsModel.get('CURRENT_ORGANIZATION');

      if (org) {
        return organizationReducer(state, setOrganization({ org, orgId: org.id }));
      }

      return state;
    }
    case SYNC_CHANGES_FROM_BACKBONE: {
      const changes = action.payload;
      const org = changes.CURRENT_ORGANIZATION;

      if (org) {
        return organizationReducer(state, setOrganization({ org, orgId: org.id }));
      }

      return state;
    }
    default:
      return state;
  }
}

// Selectors

const getOrgData = (state, props) => state.organization[props.orgId] || {};
export const getActivities = (state, props) => getOrgData(state, props).activities || [];

const getUserIds = (state, props) => getOrgData(state, props).users || [];
export const getHydratedUsers = (state, props) =>
  getUserIds(state, props).map((userId) => user.getUser(state, { userId }));

const getTeamIds = (state, props) => getOrgData(state, props).teams || [];

export const getHydratedTeams = (state, props) =>
  getTeamIds(state, props).map((teamId) => team.getTeamData(state, { teamId }));

export const getApiUserIds = (state, props) => getOrgData(state, props).apiUsers || [];

export const getFederationSettingsId = (state, props) => getOrgData(state, props).federationSettingsId;

// Action Creators

const setOrganization = ({ org, orgId }) => ({
  type: SET_ORGANIZATION,
  payload: org,
  meta: {
    orgId,
  },
});

const setVATStatus = ({ vatStatus, orgId }) => ({
  type: SET_VAT_STATUS,
  payload: vatStatus,
  meta: {
    orgId,
  },
});

export const loadVATStatus = (orgId) => (dispatch) => {
  return api.organization.loadVATStatus({ orgId }).then((response) => {
    dispatch(
      setVATStatus({
        vatStatus: response,
        orgId,
      })
    );
  });
};

export const updateVATStatus =
  ({ orgId, vatStatus }) =>
  (dispatch) => {
    return api.organization.updateVATStatus({ orgId, vatStatus }).then(() => {
      dispatch(
        setVATStatus({
          vatStatus,
          orgId,
        })
      );
    });
  };

const setActivities = ({ activities, orgId }) => ({
  type: SET_ACTIVITIES,
  payload: activities,
  meta: {
    orgId,
  },
});

const setUsers = ({ users, orgId }) => ({
  type: SET_USERS,
  payload: users,
  meta: {
    orgId,
  },
});

const setInEmailVerificationExperiment = ({ isInEmailVerificationExperiment, orgId }) => ({
  type: SET_IN_EMAIL_VERIFICATION_EXPERIMENT,
  payload: isInEmailVerificationExperiment,
  meta: { orgId },
});

const setTeams = ({ teams, orgId }) => ({
  type: SET_TEAMS,
  payload: teams,
  meta: {
    orgId,
  },
});
export { getOrgData, setOrganization, setVATStatus, setActivities, setUsers, setTeams };

const removeTeam = ({ orgId, teamId }) => ({
  type: REMOVE_TEAM,
  payload: teamId,
  meta: { orgId },
});

export const setApiUsers = ({ apiUsers, orgId }) => ({
  type: SET_API_USERS,
  payload: apiUsers,
  meta: {
    orgId,
  },
});

export const addApiUser = ({ apiUserId, orgId }) => ({
  type: ADD_API_USER,
  payload: apiUserId,
  meta: {
    orgId,
  },
});

export const removeApiUser = ({ apiUserId, orgId }) => ({
  type: REMOVE_API_USER,
  payload: apiUserId,
  meta: {
    orgId,
  },
});

const setMFARequired = ({ orgId, multiFactorAuthRequired }) => ({
  type: SET_MULTI_FACTOR_AUTH_REQUIRED,
  payload: multiFactorAuthRequired,
  meta: { orgId },
});

const setPubApiAccessListRequired = ({ orgId, publicApiWhitelistRequired }) => ({
  type: SET_PUBLIC_API_ACCESSLIST_REQUIRED,
  payload: publicApiWhitelistRequired,
  meta: { orgId },
});

const setRestrictEmployeeAccessAction = ({ orgId, restrictEmployeeAccess }) => ({
  type: SET_RESTRICT_EMPLOYEE_ACCESS,
  payload: restrictEmployeeAccess,
  meta: { orgId },
});

const setBypassAccessAutoRestrictionAction = ({ orgId, bypassAccessAutoRestriction }) => ({
  type: SET_BYPASS_ACCESS_AUTO_RESTRICTION,
  payload: bypassAccessAutoRestriction,
  meta: { orgId },
});

const setCNRegionsTermsOfServiceDateAction = ({ orgId }) => ({
  type: SET_CN_REGIONS_TERMS_OF_SERVICE_DATE,
  payload: new Date().toISOString(),
  meta: { orgId },
});

const setFederationSettings = ({ orgId, federationSettings }) => ({
  type: SET_FEDERATION_SETTINGS,
  payload: federationSettings,
  meta: {
    orgId,
  },
});

const setDefaultServerType = ({ orgId, defaultServerType }) => ({
  type: SET_DEFAULT_SERVER_TYPE,
  payload: defaultServerType,
  meta: {
    orgId,
  },
});

export const loadUsers = (orgId) => (dispatch) => {
  return api.organization.getUsersAndInvites({ orgId }).then((response) => {
    dispatch(user.setMultipleUsers(response.users));
    dispatch(
      setUsers({
        users: response.users.map((r) => r.userId),
        orgId,
      })
    );
    dispatch(
      setInEmailVerificationExperiment({
        isInEmailVerificationExperiment: response.isInEmailVerificationExperiment,
        orgId,
      })
    );
  });
};

export const loadActivities =
  ({ orgId, params = {} }) =>
  (dispatch) => {
    return api.organization.getActivities({ orgId, params }).then((response) => {
      dispatch(
        setActivities({
          activities: response.data,
          orgId,
        })
      );

      return {
        prev: response.prevId,
        next: response.nextId,
      };
    });
  };

export const loadFilteredActivities =
  ({ orgId, payload = {} }) =>
  (dispatch) => {
    return api.organization.filterActivities({ orgId, payload }).then((response) => {
      dispatch(
        setActivities({
          activities: response.data,
          orgId,
        })
      );

      return {
        prev: response.prevId,
        next: response.nextId,
      };
    });
  };

export const loadTeams = (orgId) => (dispatch) => {
  return api.organization.getTeams({ orgId }).then((response) => {
    dispatch(team.setMultipleTeams(response));

    dispatch(
      setTeams({
        teams: response.map((r) => r.id),
        orgId,
      })
    );
  });
};

export const deleteTeam =
  ({ orgId, teamId }) =>
  (dispatch) =>
    api.organization.deleteTeam({ orgId, teamId }).then(() => {
      dispatch(team.removeTeam(teamId));

      dispatch(removeTeam({ orgId, teamId }));
    });

export const loadOrg = (orgId) => (dispatch) => {
  return api.organization.organization({ orgId }).then((response) => {
    dispatch(
      setOrganization({
        org: response,
        orgId,
      })
    );
  });
};

export const updateOrgName = (orgId, orgName) => (dispatch) => {
  return api.organization.updateOrgName({ orgId, orgName }).then((response) => {
    dispatch(
      setOrganization({
        org: response,
        orgId,
      })
    );
    return response;
  });
};

export const setMultiFactorAuthRequired = (orgId, multiFactorAuthRequired) => (dispatch) => {
  dispatch(
    setMFARequired({
      orgId,
      multiFactorAuthRequired,
    })
  );

  return api.organization.setMultiFactorAuthRequired({ orgId, multiFactorAuthRequired }).catch(() => {
    // If the API call fails, set back to original state.
    dispatch(
      setMFARequired({
        orgId,
        multiFactorAuthRequired: !multiFactorAuthRequired,
      })
    );
  });
};

export const setPublicApiAccessListRequired = (orgId, publicApiWhitelistRequired) => (dispatch) => {
  dispatch(
    setPubApiAccessListRequired({
      orgId,
      publicApiWhitelistRequired,
    })
  );

  return api.organization.setPublicApiAccessListRequired({ orgId, publicApiWhitelistRequired }).catch(() => {
    dispatch(
      setPubApiAccessListRequired({
        orgId,
        publicApiWhitelistRequired: !publicApiWhitelistRequired,
      })
    );
  });
};

export const setRestrictEmployeeAccess = (orgId, restrictEmployeeAccess) => (dispatch) => {
  dispatch(
    setRestrictEmployeeAccessAction({
      orgId,
      restrictEmployeeAccess,
    })
  );

  return api.organization.setRestrictEmployeeAccess({ orgId, restrictEmployeeAccess }).catch(() => {
    dispatch(
      setRestrictEmployeeAccessAction({
        orgId,
        restrictEmployeeAccess: !restrictEmployeeAccess,
      })
    );
  });
};

export const setBypassAccessAutoRestriction = (orgId, bypassAccessAutoRestriction) => (dispatch) => {
  dispatch(
    setBypassAccessAutoRestrictionAction({
      orgId,
      bypassAccessAutoRestriction,
    })
  );

  return api.organization.setBypassAccessAutoRestriction({ orgId, bypassAccessAutoRestriction }).catch(() => {
    dispatch(
      setBypassAccessAutoRestrictionAction({
        orgId,
        bypassAccessAutoRestriction: !bypassAccessAutoRestriction,
      })
    );
  });
};

export const acceptCNRegionsTermsOfService = (orgId) => (dispatch) => {
  return api.organization.acceptCNRegionsTermsOfService({ orgId }).then(() => {
    dispatch(setCNRegionsTermsOfServiceDateAction({ orgId }));
  });
};

export const clearSlackToken = (orgId) => (dispatch) => {
  return api.organization.clearSlackToken({ orgId }).then((response) => {
    dispatch(
      setOrganization({
        org: response,
        orgId,
      })
    );
    return response;
  });
};

export const loadFederationSettings = (orgId) => (dispatch) => {
  return api.organization
    .getFederationSettings({ orgId })
    .then((response) => dispatch(setFederationSettings({ orgId, federationSettings: response })))
    .catch(() => undefined);
};

export const loadDefaultServerType = (orgId) => (dispatch) => {
  return api.organization.getDefaultServerType({ orgId }).then((response) => {
    dispatch(setDefaultServerType({ orgId, defaultServerType: response }));
  });
};
