// constants and types
import { CloudProvider, Providers, ProviderToOptionsMapping } from '@packages/types/nds/provider';

import fetchWrapper from '../fetchWrapper';

export default {
  getDefaultTemplates(groupId: string, centralUrl: string = '') {
    function fetchProviderDefault(provider) {
      return fetchWrapper(`${centralUrl}/nds/clusters/${groupId}/${provider}/default`, {
        method: 'GET',
        credentials: 'include',
      });
    }

    return Promise.all([
      fetchProviderDefault('AWS').then((r) => r.json()),
      fetchProviderDefault('AZURE').then((r) => r.json()),
      fetchProviderDefault('GCP').then((r) => r.json()),
    ]).then(([awsDefault, azureDefault, gcpDefault]) => ({
      AWS: awsDefault,
      AZURE: azureDefault,
      GCP: gcpDefault,
    }));
  },

  getProviderOptions(groupId: string, centralUrl: string = ''): Promise<Providers> {
    function fetchProviderOptions<P extends keyof ProviderToOptionsMapping>(
      provider: P
    ): Promise<ProviderToOptionsMapping[P]> {
      return fetchWrapper(`${centralUrl}/nds/clusters/${groupId}/${provider}/options`, {
        method: 'GET',
        credentials: 'include',
      }).then((r) => r.json());
    }

    return Promise.all([
      fetchProviderOptions(CloudProvider.AWS),
      fetchProviderOptions(CloudProvider.AZURE),
      fetchProviderOptions(CloudProvider.GCP),
      fetchProviderOptions(CloudProvider.FREE),
    ]).then(([awsOptions, azureOptions, gcpOptions, freeDefault]) => ({
      AWS: awsOptions,
      AZURE: azureOptions,
      GCP: gcpOptions,
      FREE: freeDefault,
    }));
  },
};
