import { AppEnv, ENVIRONMENT } from 'js/common/utils/segmentAnalytics';

const getHeliotropeEnvironment = (accountCentralUrl: string, appEnv?: AppEnv): ENVIRONMENT => {
  if (accountCentralUrl === 'http://localhost:9080') return ENVIRONMENT.E2E;
  if (appEnv === AppEnv.LOCAL) return ENVIRONMENT.LOCAL;
  if (appEnv === AppEnv.DEV) return ENVIRONMENT.DEV;
  if (appEnv === AppEnv.QA) return ENVIRONMENT.QA;

  return ENVIRONMENT.PROD;
};
export default getHeliotropeEnvironment;
