import * as React from 'react';
import { useContext } from 'react';

import { FieldNames } from '@packages/types/auth/field';

import { CountryCode } from 'js/common/utils/countries';
import { AppEnv } from 'js/common/utils/segmentAnalytics';

export interface RequestParams {
  appEnv?: AppEnv;
  onPrem?: boolean;
  onPremVersion?: string;
  hasUsers?: boolean;
  authLogoFileName: string;
  showAccountNonCommercialBanner: boolean;
  registrationExtraFields: Array<
    FieldNames.COMPANY | FieldNames.COUNTRY | FieldNames.JOB_RESPONSIBILITY | FieldNames.PHONE_NUMBER
  >;
  registrationDefaultCountry: CountryCode;
  oktaEnabled: boolean;
  oktaAuthRedirectBase: string;
  oktaBaseUrl: string;
  oktaSessionDiscoveryEnabled: boolean;
  baasCentralUrl: string;
  userRegistrationEnabled: boolean;
  userRegistrationDisabledRedirectUrl: string;
  isLdap: boolean;
  siteName: string;
  siteFullName: string;
  atlasSiteName: string;
  atlasSiteFullName: string;
  segmentDefaultUserId: string;
  analyticsEnabled: boolean;
  segmentClientSideWriteKey: string;
  intercomEnabled: boolean;
  intercomAppId: string;
  reCaptchaEnabledLogin: boolean;
  reCaptchaEnabledRegistration: boolean;
  reCaptchaPublic: string;
  reCaptchaV3Public: string;
  isGoogleAuthEnabled: boolean;
  isGithubAuthEnabled: boolean;
  eloquaSiteId?: string;
  centralUrl: string;
  universityCentralUrl: string;
  docsUrl: string;
  docsSearchUrl: string;
  multiFactorAuthResetAllowed: boolean;
  multiFactorAuthLevel: string;
  accountMultiFactorAuthEnabled: boolean;
  telecomSvcEnabled: boolean;
  isPersonalApiKeysPageVisible: boolean;
  browserErrorTrackingEnabled: boolean;
  isAccountDeletionEnabled: boolean;
  accountCentralUrl: string;
  isPersonalizationWizardEnabled: boolean;
  isNdsServerlessEnabled: boolean;
  setupServerlessCreateTestInstanceAllowed: boolean;
  isVercelIntegrationEnabled: boolean;
  atlasClusterWebsocketConnectionBaseUrl: string;
  dataRegion?: string;
  regionalConnectionBaseUrl?: string;
  vercelEmail?: string;
  vercelUserId?: string;
  vercelTeamName?: string;
  vercelTeamId?: string;
  beamerApiKey?: string;
}

declare global {
  interface Window {
    REQUEST_PARAMS: RequestParams;
  }
}

const RequestParamsContext = React.createContext(window.REQUEST_PARAMS ?? {});

interface RequestParamsProviderProps {
  children: React.ReactNode;
  value?: RequestParams;
}

export const RequestParamsProvider = ({ children, value }: RequestParamsProviderProps) => {
  return (
    <RequestParamsContext.Provider value={value ?? window.REQUEST_PARAMS ?? {}}>
      {children}
    </RequestParamsContext.Provider>
  );
};

export const useRequestParams = () => {
  return useContext(RequestParamsContext);
};
