import styled from '@emotion/styled';

const SpinningLoader = styled.img`
  animation: spinning 2s infinite linear;

  @keyframes spinning {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const SmallLoader = () => {
  return <SpinningLoader data-testid="small-loader" src="/static/images/small-loader.svg" />;
};

export default SmallLoader;
