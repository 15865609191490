// libraries
import { produce } from 'immer';
import { createSelector } from 'reselect';

import { SetEnableCurrentIpWarningParams } from '@packages/types/settings';

import * as api from 'js/common/services/api';

import * as backboneReduxSync from '../backboneReduxSync';
import * as team from './team';
// dependencies
import { actionTypes as commonActionTypes } from './common';
import { getUser, getUserInvite, getUsersKeyedById, setMultipleUsers } from './user';

const { SET_GROUP_USERS, SET_GROUP_TEAMS } = commonActionTypes;

const {
  actionTypes: { SYNC_CHANGES_FROM_BACKBONE, SYNC_FROM_BACKBONE },
} = backboneReduxSync;

const SET_GROUP = 'group/SET_GROUP';
const SET_GROUP_NAME = 'group/SET_GROUP_NAME';
const REMOVE_GROUP = 'group/REMOVE_GROUP';
const REMOVE_TEAM_FROM_GROUP = 'group/REMOVE_TEAM_FROM_GROUP';
const SET_MULTIPLE_GROUPS = 'group/SET_MULTIPLE_GROUPS';
const GROUP_USERS_LOADING = 'group/GROUP_USERS_LOADING';
const GROUP_USERS_ERROR = 'group/GROUP_USERS_ERROR';
const REMOVE_USER_FROM_GROUP = 'group/REMOVE_USER_FROM_GROUP';
const GRANT_API_USER_GROUP_ACCESS = 'group/GRANT_API_USER_ACCESS';

const BULK_GROUP_USERS_LOADING = 'group/BULK_GROUP_USERS_LOADING';
const SET_BULK_GROUP_USERS = 'group/SET_BULK_GROUP_USERS';

const ADD_LOADING = 'group/ADD_LOADING';
const ADD_GROUP_ERROR = 'group/ADD_GROUP_ERROR';
const ADD_USERS_TEAMS = 'group/ADD_USERS_TEAMS';

const GROUP_TEAMS_LOADING = 'group/GROUP_TEAMS_LOADING';
const GROUP_TEAMS_ERROR = 'group/GROUP_TEAMS_ERROR';

const CLEAR_GROUP_ERROR_MESSAGE = 'group/CLEAR_GROUP_ERROR_MESSAGE';

const SET_PAGERDUTY_INTEGRATION = 'group/SET_PAGERDUTY_INTEGRATION';
const REMOVE_PAGERDUTY_INTEGRATION = 'group/REMOVE_PAGERDUTY_INTEGRATION';
const SET_SLACK_INTEGRATION = 'group/SET_SLACK_INTEGRATION';
const REMOVE_SLACK_INTEGRATION = 'group/REMOVE_SLACK_INTEGRATION';
const SET_PROMETHEUS_INTEGRATION = 'group/SET_PROMETHEUS_INTEGRATION';
const REMOVE_PROMETHEUS_INTEGRATION = 'group/REMOVE_PROMETHEUS_INTEGRATION';
const SET_DATADOG_INTEGRATION = 'group/SET_DATADOG_INTEGRATION';
const REMOVE_DATADOG_INTEGRATION = 'group/REMOVE_DATADOG_INTEGRATION';
const SET_VICTOROPS_INTEGRATION = 'group/SET_VICTOROPS_INTEGRATION';
const REMOVE_VICTOROPS_INTEGRATION = 'group/REMOVE_VICTOROPS_INTEGRATION';
const SET_OPSGENIE_INTEGRATION = 'group/SET_OPSGENIE_INTEGRATION';
const REMOVE_OPSGENIE_INTEGRATION = 'group/REMOVE_OPSGENIE_INTEGRATION';
const SET_HIP_CHAT_INTEGRATION = 'group/SET_HIP_CHAT_INTEGRATION';
const REMOVE_HIP_CHAT_INTEGRATION = 'group/REMOVE_HIP_CHAT_INTEGRATION';
const SET_MICROSOFT_TEAMS_INTEGRATION = 'group/SET_MICROSOFT_TEAMS_INTEGRATION';
const REMOVE_MICROSOFT_TEAMS_INTEGRATION = 'group/REMOVE_MICROSOFT_TEAMS_INTEGRATION';
const SET_WEBHOOK_INTEGRATION = 'group/SET_WEBHOOK_INTEGRATION';
const REMOVE_WEBHOOK_INTEGRATION = 'group/REMOVE_WEBHOOK_INTEGRATION';
const SET_NEW_RELIC_INTEGRATION = 'grouop/SET_NEW_RELIC_INTEGRATION';
const REMOVE_NEW_RELIC_INTEGRATION = 'grouop/REMOVE_NEW_RELIC_INTEGRATION';
const SET_GROUP_TIME_ZONE = 'group/SET_GROUP_TIME_ZONE';
const SET_ENABLE_CURRENT_IP_WARNING = 'group/SET_ENABLE_CURRENT_IP_WARNING';

const UPDATE_DB_STATS = 'group/UPDATE_DB_STATS';
const UPDATE_LOG_COLLECTION = 'group/UPDATE_LOG_COLLECTION';
const UPDATE_PROFILERS = 'group/UPDATE_PROFILERS';
const TOGGLE_SUPPRESS_MONGOS_AUTO_DISCOVERY = 'group/TOGGLE_SUPPRESS_MONGOS_AUTO_DISCOVERY';
const SET_PREFERRED_HOSTNAMES = 'group/SET_PREFERRED_HOSTNAMES';

const initialState = {};

// Reducer
const groupReducer = produce((draft, action) => {
  const { type, meta = {}, payload } = action;
  const { groupId, groupIds } = meta;

  const setIfUndefined = (target, value) => (target == null ? value : target);

  if (groupId) {
    draft[groupId] = setIfUndefined(draft[groupId], {});
  }

  switch (type) {
    case SET_GROUP:
      draft[groupId].data = payload;
      break;

    case SET_GROUP_NAME:
      draft[groupId].data.name = payload.groupName;
      break;

    case REMOVE_GROUP:
      delete draft[payload.groupId];
      break;

    case SET_MULTIPLE_GROUPS:
      if (payload.length === 0) {
        return;
      }
      payload.forEach((group) => {
        draft[group.id] = setIfUndefined(draft[group.id], {});
        draft[group.id].data = setIfUndefined(draft[group.id].data, {});

        Object.entries(group).forEach(([key, value]) => {
          draft[group.id].data[key] = value;
        });
      });
      break;

    case GROUP_USERS_LOADING:
      draft[groupId].usersError = null;
      draft[groupId].usersLoading = true;
      break;

    // NOTE: This action is also handled in the user reducer, to store the actual user data.
    case SET_GROUP_USERS: {
      draft[groupId].usersError = null;
      draft[groupId].usersLoading = false;
      draft[groupId].users = payload.map((user) => user.userId);
      break;
    }

    case GROUP_USERS_ERROR:
      draft[groupId].usersError = payload;
      draft[groupId].usersLoading = false;
      break;

    case BULK_GROUP_USERS_LOADING:
      groupIds.forEach((groupId) => {
        draft[groupId].usersError = null;
        draft[groupId].usersLoading = false;
      });
      break;

    case SET_BULK_GROUP_USERS:
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'void' is not assignable to type 'WritableDra... Remove this comment to see the full error message
      draft = Object.entries(payload).forEach(([groupId, users]) => {
        draft[groupId].usersError = null;
        draft[groupId].usersLoading = false;
        draft[groupId].users = (users as any).map((user) => user.userId);
      });
      break;

    case REMOVE_USER_FROM_GROUP:
      draft[groupId].users = setIfUndefined(draft[groupId].users, []);
      draft[groupId].users.splice(
        draft[groupId].users.findIndex((userId) => userId === payload.userId),
        1
      );
      break;

    case ADD_LOADING:
      draft[groupId].addLoading = true;
      break;

    case ADD_GROUP_ERROR:
      draft[groupId].addLoading = false;
      draft[groupId].addGroupError = payload;
      break;

    case ADD_USERS_TEAMS: {
      // NOTE: We will almost definitely re-fetch the actual list of users/teams from the API,
      // but it doesn't hurt anything to optimistically store these in the meantime.
      const { users = [], teams = [] } = payload;
      draft[groupId].users = users.map((u) => u.userId);
      draft[groupId].teams = teams.map((t) => t.id || t.teamId);
      draft[groupId].addLoading = false;
      draft[groupId].addGroupError = null;
      break;
    }

    case SET_GROUP_TEAMS: {
      draft[groupId].teams = payload.map((t) => t.id);
      draft[groupId].teamsError = null;
      draft[groupId].teamsLoading = false;
      break;
    }

    case REMOVE_TEAM_FROM_GROUP:
      draft[groupId].teams = setIfUndefined(draft[groupId].teams, []);
      draft[groupId].teams.splice(
        draft[groupId].teams.findIndex((teamId) => teamId === payload.teamId),
        1
      );
      break;

    case GROUP_TEAMS_LOADING:
      draft[groupId].teamsError = null;
      draft[groupId].teamsLoading = true;
      break;

    case GROUP_TEAMS_ERROR:
      draft[groupId].teamsError = payload;
      draft[groupId].teamsLoading = false;
      break;

    case CLEAR_GROUP_ERROR_MESSAGE:
      draft[groupId].addGroupError = null;
      break;

    case SET_SLACK_INTEGRATION:
      draft[groupId].data = setIfUndefined(draft[groupId].data, {});
      draft[groupId].data.integrations = setIfUndefined(draft[groupId].data.integrations, []);
      draft[groupId].data.integrations.SLACK = [
        {
          _t: 'SLACK',
          apiToken: payload.slackApiToken,
          channelName: payload.slackChannelName,
          teamName: payload.slackTeamName,
        },
      ];
      break;

    case REMOVE_SLACK_INTEGRATION:
      draft[groupId].data = setIfUndefined(draft[groupId].data, {});
      draft[groupId].data.integrations = setIfUndefined(draft[groupId].data.integrations, []);
      draft[groupId].data.integrations.SLACK = [
        {
          apiToken: null,
          channelName: null,
          teamName: null,
        },
      ];
      break;

    case SET_PROMETHEUS_INTEGRATION:
      draft[groupId].data = setIfUndefined(draft[groupId].data, {});
      draft[groupId].data.prom = payload.response;
      break;

    case REMOVE_PROMETHEUS_INTEGRATION:
      draft[groupId].data = setIfUndefined(draft[groupId].data, {});
      draft[groupId].data.prom = null;
      break;

    case SET_DATADOG_INTEGRATION:
      draft[groupId].data = setIfUndefined(draft[groupId].data, {});
      draft[groupId].data.integrations = setIfUndefined(draft[groupId].data.integrations, []);
      draft[groupId].data.integrations.DATADOG = [
        {
          _t: 'DATADOG',
          apiKey: payload.datadogApiKey,
          region: payload.datadogRegion,
          customEndpoint: payload.datadogCustomEndpoint,
        },
      ];
      break;

    case REMOVE_DATADOG_INTEGRATION:
      draft[groupId].data = setIfUndefined(draft[groupId].data, {});
      draft[groupId].data.integrations = setIfUndefined(draft[groupId].data.integrations, []);
      draft[groupId].data.integrations.DATADOG = [
        {
          apiKey: null,
          region: null,
          customEndpoint: null,
        },
      ];
      break;

    case SET_OPSGENIE_INTEGRATION:
      draft[groupId].data = setIfUndefined(draft[groupId].data, {});
      draft[groupId].data.integrations = setIfUndefined(draft[groupId].data.integrations, []);
      draft[groupId].data.integrations.OPS_GENIE = [
        {
          _t: 'OPS_GENIE',
          apiKey: payload.opsGenieApiKey,
          region: payload.opsGenieRegion,
        },
      ];
      break;

    case REMOVE_OPSGENIE_INTEGRATION:
      draft[groupId].data = setIfUndefined(draft[groupId].data, {});
      draft[groupId].data.integrations = setIfUndefined(draft[groupId].data.integrations, []);
      draft[groupId].data.integrations.OPS_GENIE = [
        {
          apiKey: null,
          region: null,
        },
      ];
      break;

    case SET_VICTOROPS_INTEGRATION:
      draft[groupId].data = setIfUndefined(draft[groupId].data, {});
      draft[groupId].data.integrations = setIfUndefined(draft[groupId].data.integrations, []);
      draft[groupId].data.integrations.VICTOR_OPS = [
        {
          _t: 'VICTOR_OPS',
          apiKey: payload.victorOpsApiKey,
          routingKey: payload.victorOpsRoutingKey,
        },
      ];
      break;

    case REMOVE_VICTOROPS_INTEGRATION:
      draft[groupId].data = setIfUndefined(draft[groupId].data, {});
      draft[groupId].data.integrations = setIfUndefined(draft[groupId].data.integrations, []);
      draft[groupId].data.integrations.VICTOR_OPS = [
        {
          apiKey: null,
          routingKey: null,
        },
      ];
      break;

    case SET_PAGERDUTY_INTEGRATION:
      draft[groupId].data = setIfUndefined(draft[groupId].data, {});
      draft[groupId].data.integrations = setIfUndefined(draft[groupId].data.integrations, []);
      draft[groupId].data.integrations.PAGER_DUTY = [
        {
          _t: 'PAGER_DUTY',
          serviceKey: payload.pagerDutyServiceKey,
          region: payload.pagerDutyRegion,
        },
      ];
      break;

    case REMOVE_PAGERDUTY_INTEGRATION:
      draft[groupId].data = setIfUndefined(draft[groupId].data, {});
      draft[groupId].data.integrations = setIfUndefined(draft[groupId].data.integrations, []);
      draft[groupId].data.integrations.PAGER_DUTY = [
        {
          serviceKey: null,
          region: null,
        },
      ];
      break;

    case SET_HIP_CHAT_INTEGRATION:
      draft[groupId].data = setIfUndefined(draft[groupId].data, {});
      draft[groupId].data.integrations = setIfUndefined(draft[groupId].data.integrations, []);
      draft[groupId].data.integrations.HIP_CHAT = [
        {
          _t: 'HIP_CHAT',
          notificationToken: payload.hipChatNotificationToken,
          roomName: payload.hipChatRoomName,
        },
      ];
      break;

    case REMOVE_HIP_CHAT_INTEGRATION:
      draft[groupId].data = setIfUndefined(draft[groupId].data, {});
      draft[groupId].data.integrations = setIfUndefined(draft[groupId].data.integrations, []);
      draft[groupId].data.integrations.HIP_CHAT = [
        {
          notificationToken: null,
          roomName: null,
        },
      ];
      break;

    case SET_MICROSOFT_TEAMS_INTEGRATION:
      draft[groupId].data = setIfUndefined(draft[groupId].data, {});
      draft[groupId].data.integrations = setIfUndefined(draft[groupId].data.integrations, []);
      draft[groupId].data.integrations.MICROSOFT_TEAMS = [
        {
          _t: 'MICROSOFT_TEAMS',
          webhookUrl: payload.microsoftTeamsWebhookUrl,
        },
      ];
      break;

    case REMOVE_MICROSOFT_TEAMS_INTEGRATION:
      draft[groupId].data = setIfUndefined(draft[groupId].data, {});
      draft[groupId].data.integrations = setIfUndefined(draft[groupId].data.integrations, []);
      draft[groupId].data.integrations.MICROSOFT_TEAMS = [
        {
          webhookUrl: null,
        },
      ];
      break;

    case SET_WEBHOOK_INTEGRATION:
      draft[groupId].data = setIfUndefined(draft[groupId].data, {});
      draft[groupId].data.integrations = setIfUndefined(draft[groupId].data.integrations, []);
      draft[groupId].data.integrations.WEBHOOK = [
        {
          _t: 'WEBHOOK',
          webhookUrl: payload.webhookUrl,
          webhookSecret: payload.webhookSecret,
        },
      ];
      break;

    case REMOVE_WEBHOOK_INTEGRATION:
      draft[groupId].data = setIfUndefined(draft[groupId].data, {});
      draft[groupId].data.integrations = setIfUndefined(draft[groupId].data.integrations, []);
      draft[groupId].data.integrations.WEBHOOK = [{ webhookUrl: null, webhookSecret: null }];
      break;

    case SET_NEW_RELIC_INTEGRATION:
      draft[groupId].data = setIfUndefined(draft[groupId].data, {});
      draft[groupId].data.newRelicLicenseKey = payload.newRelicLicenseKey;
      draft[groupId].data.newRelicInsightsAccountId = payload.newRelicInsightsAccountId;
      draft[groupId].data.newRelicInsightsWriteToken = payload.newRelicInsightsWriteToken;
      draft[groupId].data.newRelicInsightsReadToken = payload.newRelicInsightsReadToken;
      break;

    case REMOVE_NEW_RELIC_INTEGRATION:
      draft[groupId].data = setIfUndefined(draft[groupId].data, {});
      draft[groupId].data.newRelicLicenseKey = null;
      draft[groupId].data.newRelicInsightsAccountId = null;
      draft[groupId].data.newRelicInsightsWriteToken = null;
      draft[groupId].data.newRelicInsightsReadToken = null;
      break;

    case SET_GROUP_TIME_ZONE:
      draft[groupId].data.defaultTimeZoneId = payload.timeZoneId;
      break;

    case UPDATE_DB_STATS:
      draft[groupId].data = setIfUndefined(draft[groupId].data, {});
      draft[groupId].data.disableDbstats = payload.value;
      break;

    case UPDATE_LOG_COLLECTION:
      draft[groupId].data = setIfUndefined(draft[groupId].data, {});
      draft[groupId].data.enableAllHostLogs = payload.value;
      break;

    case UPDATE_PROFILERS:
      draft[groupId].data = setIfUndefined(draft[groupId].data, {});
      draft[groupId].data.enableAllHostProfilers = payload.value;
      break;

    case TOGGLE_SUPPRESS_MONGOS_AUTO_DISCOVERY:
      draft[groupId].data = setIfUndefined(draft[groupId].data, {});
      draft[groupId].data.suppressMongosAutoDiscovery = payload.value;
      break;

    case SET_PREFERRED_HOSTNAMES:
      draft[groupId].data = setIfUndefined(draft[groupId].data, {});
      draft[groupId].data.preferredHostnames = payload;
      break;

    case SET_ENABLE_CURRENT_IP_WARNING: {
      draft[groupId].data = setIfUndefined(draft[groupId].data, {});
      draft[groupId].data.enableCurrentIpWarning = payload;
      break;
    }

    case SYNC_FROM_BACKBONE: {
      const settingsModel = action.payload;
      const group = settingsModel.get('CURRENT_GROUP');
      return group ? groupReducer(draft, setGroup(group)) : draft;
    }

    case SYNC_CHANGES_FROM_BACKBONE: {
      const changes = action.payload;
      const group = changes.CURRENT_GROUP;
      return group ? groupReducer(draft, setGroup(group)) : draft;
    }

    default:
      return draft;
  }
}, initialState);

export default groupReducer;

// Selectors

const getGroupInfo = (state, { groupId }) => state.group[groupId] || {};

/* NOTE:
 * The following selectors, which depend on getGroupInfo, take the same { groupId } second parameter.
 */

export const getGroup = createSelector(getGroupInfo, (info) => info.data || {});

export const getGroupTeamsError = createSelector(getGroupInfo, (info) => info.teamsError);
export const getGroupUserIds = createSelector(getGroupInfo, (info) => info.users || []);
export const getGroupUsersError = createSelector(getGroupInfo, (info) => info.usersError);
export const getEnableCurrentIpWarning = createSelector(getGroupInfo, (info) => {
  if ('data' in info && 'enableCurrentIpWarning' in info.data) {
    return Boolean(info.data.enableCurrentIpWarning);
  }

  return false;
});
export const groupTeamsLoading = createSelector(getGroupInfo, (info) => !!info.teamsLoading);
export const groupUsersLoading = createSelector(getGroupInfo, (info) => !!info.usersLoading);
export const groupAddIsLoading = createSelector(getGroupInfo, (info) => !!info.addLoading);
export const groupAddError = createSelector(getGroupInfo, (info) => info.addGroupError);

export const getSuppressMongosDiscovery = createSelector(getGroupInfo, (info) => info.data.suppressMongosAutoDiscovery);
export const getExtendedStorageSizesEnabled = createSelector(
  getGroupInfo,
  (info) => info.data.extendedStorageSizesEnabled
);

export const getHydratedUsers = createSelector(getGroupUserIds, getUsersKeyedById, (ids, users) =>
  ids.map((id) => users[id])
);

export const getGroupTeamIds = createSelector(getGroupInfo, (info) => info.teams || []);
export const getHydratedTeams = (state, { groupId }) =>
  getGroupTeamIds(state, { groupId }).map((teamId) => team.getTeamData(state, { teamId }));

// Action Creators

const setGroup = (payload) => ({
  type: SET_GROUP,
  payload,
  meta: {
    groupId: payload.id,
  },
});

const setGroupName = (payload, groupId) => ({
  type: SET_GROUP_NAME,
  payload,
  meta: {
    groupId,
  },
});

const setPreferredHostnames = (payload, groupId) => ({
  type: SET_PREFERRED_HOSTNAMES,
  payload,
  meta: {
    groupId,
  },
});

const setGroupTimeZone = (payload, groupId) => ({
  type: SET_GROUP_TIME_ZONE,
  payload,
  meta: {
    groupId,
  },
});

const removeGroup = (payload) => ({
  type: REMOVE_GROUP,
  payload,
});

export const setMultipleGroups = (payload = []) => ({
  type: SET_MULTIPLE_GROUPS,
  payload,
});

const setGroupTeams = ({ teams, groupId }) => ({
  type: SET_GROUP_TEAMS,
  payload: teams,
  meta: {
    groupId,
  },
});

const clearError = (groupId) => ({
  type: CLEAR_GROUP_ERROR_MESSAGE,
  meta: {
    groupId,
  },
});
export { setGroup, removeGroup, setGroupTeams, clearError };

const grantApiUserGroupAccess = ({ apiUsers, groupId }) => ({
  type: GRANT_API_USER_GROUP_ACCESS,
  payload: {
    apiUsers,
    groupId,
  },
});

export const loadGroup = (groupId) => (dispatch) => {
  return api.group.group({ groupId }).then((response) => {
    dispatch(setGroup(response));
  });
};

export const loadGroupUsers =
  ({ groupId }) =>
  async (dispatch) => {
    dispatch({ type: GROUP_USERS_LOADING, meta: { groupId } });

    try {
      const users = await api.settings.getGroupUsers(groupId);

      dispatch({
        type: SET_GROUP_USERS,
        payload: users,
        meta: { groupId },
      });
    } catch (e) {
      dispatch({
        type: GROUP_USERS_ERROR,
        payload: e,
        meta: { groupId },
      });
    }
  };

export const loadGroupTeams =
  ({ groupId }) =>
  async (dispatch) => {
    dispatch({ type: GROUP_TEAMS_LOADING, meta: { groupId } });

    try {
      const teams = await api.group.getTeams({ groupId });

      dispatch({
        type: SET_GROUP_TEAMS,
        payload: teams,
        meta: { groupId },
      });
    } catch (e) {
      dispatch({
        type: GROUP_TEAMS_ERROR,
        payload: e,
        meta: { groupId },
      });
    }
  };

export const removeUserFromGroup =
  ({ groupId, userId }) =>
  async (dispatch, getState) => {
    const userObject = getUser(getState(), { userId });

    if (userObject == null) {
      return Promise.reject(new Error(`Attempted to update roles on an unknown user ID ${userId}`));
    }

    const { username } = userObject;
    // This can throw an error, but since it is not caught, it should be picked up in DeleteModal where this is handled
    await api.user.removeUserFromGroup({ groupId, username });

    dispatch({
      type: REMOVE_USER_FROM_GROUP,
      payload: userObject,
      meta: { groupId },
    });
  };

// NOTE(Jeremy): I have it taking the username here instead of the user ID because
// invited users don't technically have a "user ID" yet, since they don't have a user object.
// The key returned by the API in the userId field is just the username, anyways, so let's go by that.
export const deleteGroupInvitation =
  ({ groupId, username }) =>
  async (dispatch, getState) => {
    const userObject = getUserInvite(getState(), { username });

    await api.group.deleteGroupInvitation({ groupId, username });

    dispatch({
      type: REMOVE_USER_FROM_GROUP,
      payload: userObject,
      meta: { groupId },
    });
  };

/**
 * Add the given users/teams to the given group id. Defaults to empty arrays for users/teams and the current
 * group ID from settings for the group.
 * @param {{ groupId: ?string, users: ?Array.<Object>, teams: ?Array.<Object> }} options
 * @return {function(function, function): Promise} Thunk action that returns a promise, which resolves after all API calls.
 */
export const addMultipleUsersOrTeams =
  ({ groupId, users = [], teams = [] }) =>
  async (dispatch) => {
    dispatch({ type: ADD_LOADING, meta: { groupId } });

    try {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ groupId: any; users: never[]; ... Remove this comment to see the full error message
      await api.group.grantAccess({ groupId, users, teams });

      // We need to make sure all user objects, including new invite users,
      // exist in our redux store before we try to access the full object
      // from selectors that hydrate the full object.
      dispatch(setMultipleUsers(users));

      dispatch({
        type: ADD_USERS_TEAMS,
        payload: {
          users,
          teams,
        },
        meta: { groupId },
      });
    } catch (e) {
      dispatch({
        type: ADD_GROUP_ERROR,
        payload: e,
        meta: { groupId },
      });
      return Promise.reject();
    }
  };

export const grantAccess =
  ({ orgId, groupId, users = [], teams = [], apiUsers = [] }) =>
  async (dispatch) => {
    dispatch({ type: ADD_LOADING, meta: { groupId } });

    try {
      await api.group.grantAccess({ groupId, users, teams, apiUsers });

      // We need to make sure all user objects, including new invite users,
      // exist in our redux store before we try to access the full object
      // from selectors that hydrate the full object.
      dispatch(setMultipleUsers(users));

      dispatch({
        type: ADD_USERS_TEAMS,
        payload: {
          users,
          teams,
        },
        meta: { groupId },
      });

      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ apiUsers: never[]; groupId: an... Remove this comment to see the full error message
      dispatch(grantApiUserGroupAccess({ apiUsers, groupId, orgId }));
    } catch (e) {
      dispatch({
        type: ADD_GROUP_ERROR,
        payload: e,
        meta: { groupId },
      });
      return Promise.reject();
    }
  };

export const deleteTeamFromGroup =
  ({ groupId, teamId }) =>
  async (dispatch) => {
    await api.group.deleteTeamFromGroup({ groupId, teamId });

    dispatch({
      type: REMOVE_TEAM_FROM_GROUP,
      payload: { teamId },
      meta: { groupId },
    });
  };

export const clearGroupErrorMessage =
  ({ groupId }) =>
  (dispatch) => {
    dispatch(clearError(groupId));
  };

export const setSlackIntegrationForGroup =
  ({ groupId, body }) =>
  async (dispatch) => {
    const response = await api.group.setSlackIntegration({ groupId, body });
    const {
      groupSlackApiToken: slackApiToken,
      groupSlackTeamName: slackTeamName,
      groupSlackChannelName: slackChannelName,
    } = response;
    dispatch({
      type: SET_SLACK_INTEGRATION,
      meta: { groupId },
      payload: { slackApiToken, slackTeamName, slackChannelName },
    });
    return response;
  };

export const removeSlackIntegrationFromGroup =
  ({ groupId }) =>
  async (dispatch) => {
    await api.group.removeSlackIntegration({ groupId });

    dispatch({
      type: REMOVE_SLACK_INTEGRATION,
      meta: { groupId },
    });
  };

export const setPrometheusIntegrationForGroup =
  ({ groupId, body }) =>
  async (dispatch) => {
    const response = await api.group.setPrometheusIntegration({ groupId, body });

    dispatch({
      type: SET_PROMETHEUS_INTEGRATION,
      meta: { groupId },
      payload: { response },
    });
    return response;
  };

export const removePrometheusIntegrationFromGroup =
  ({ groupId }) =>
  async (dispatch) => {
    await api.group.removePrometheusIntegration({ groupId });
    dispatch({
      type: REMOVE_PROMETHEUS_INTEGRATION,
      meta: { groupId },
    });
  };

export const setPagerDutyIntegrationForGroup =
  ({ groupId, body }) =>
  async (dispatch) => {
    const response = await api.group.setPagerDutyIntegration({ groupId, body });
    const { groupPagerDuty, pagerDutyRegion } = response;
    dispatch({
      type: SET_PAGERDUTY_INTEGRATION,
      meta: { groupId },
      payload: { pagerDutyServiceKey: groupPagerDuty, pagerDutyRegion: pagerDutyRegion },
    });
    return response;
  };

export const removePagerDutyIntegrationFromGroup =
  ({ groupId }) =>
  async (dispatch) => {
    await api.group.removePagerDutyIntegration({ groupId });
    dispatch({
      type: REMOVE_PAGERDUTY_INTEGRATION,
      meta: { groupId },
    });
  };

export const setDatadogIntegrationForGroup =
  ({ groupId, body }) =>
  async (dispatch) => {
    const response = await api.group.setDatadogIntegration({ groupId, body });
    const { datadogApiKey, datadogRegion, datadogCustomEndpoint } = response;
    dispatch({
      type: SET_DATADOG_INTEGRATION,
      meta: { groupId },
      payload: { datadogApiKey, datadogRegion, datadogCustomEndpoint },
    });
    return response;
  };

export const removeDatadogIntegrationFromGroup =
  ({ groupId }) =>
  async (dispatch) => {
    await api.group.removeDatadogIntegration({ groupId });

    dispatch({
      type: REMOVE_DATADOG_INTEGRATION,
      meta: { groupId },
    });
  };

export const setVictorOpsIntegrationForGroup =
  ({ groupId, body }) =>
  async (dispatch) => {
    const response = await api.group.setVictorOpsIntegration({ groupId, body });
    const { groupVictorOpsApiKey, groupVictorOpsRoutingKey } = response;

    dispatch({
      type: SET_VICTOROPS_INTEGRATION,
      meta: { groupId },
      payload: {
        victorOpsApiKey: groupVictorOpsApiKey,
        victorOpsRoutingKey: groupVictorOpsRoutingKey,
      },
    });

    return response;
  };

export const removeVictorOpsIntegrationFromGroup =
  ({ groupId }) =>
  async (dispatch) => {
    await api.group.removeVictorOpsIntegration({ groupId });

    dispatch({
      type: REMOVE_VICTOROPS_INTEGRATION,
      meta: { groupId },
    });
  };

export const setOpsgenieIntegrationForGroup =
  ({ groupId, body }) =>
  async (dispatch) => {
    const response = await api.group.setOpsgenieIntegration({ groupId, body });
    const { groupOpsGenie, groupOpsGenieRegion } = response;
    dispatch({
      type: SET_OPSGENIE_INTEGRATION,
      meta: { groupId },
      payload: {
        opsGenieApiKey: groupOpsGenie,
        opsGenieRegion: groupOpsGenieRegion,
      },
    });

    return response;
  };

export const removeOpsgenieIntegrationFromGroup =
  ({ groupId }) =>
  async (dispatch) => {
    await api.group.removeOpsgenieIntegration({ groupId });

    dispatch({
      type: REMOVE_OPSGENIE_INTEGRATION,
      meta: { groupId },
    });
  };

export const setHipChatIntegrationForGroup =
  ({ groupId, body }) =>
  async (dispatch) => {
    const response = await api.group.setHipChatIntegration({ groupId, body });
    const { groupHipChatNotificationToken: hipChatNotificationToken, groupHipChatRoomName: hipChatRoomName } = response;

    dispatch({
      type: SET_HIP_CHAT_INTEGRATION,
      meta: { groupId },
      payload: {
        hipChatNotificationToken,
        hipChatRoomName,
      },
    });

    return response;
  };

export const removeHipChatIntegrationFromGroup =
  ({ groupId }) =>
  async (dispatch) => {
    await api.group.removeHipChatIntegration({ groupId });

    dispatch({
      type: REMOVE_HIP_CHAT_INTEGRATION,
      meta: { groupId },
    });
  };

export const setMicrosoftTeamsIntegrationForGroup =
  ({ groupId, body }) =>
  async (dispatch) => {
    const response = await api.group.setMicrosoftTeamsIntegration({ groupId, body });
    const { microsoftTeamsWebhookUrl } = response;
    dispatch({
      type: SET_MICROSOFT_TEAMS_INTEGRATION,
      meta: { groupId },
      payload: { microsoftTeamsWebhookUrl },
    });
    return response;
  };

export const removeMicrosoftTeamsIntegrationFromGroup =
  ({ groupId }) =>
  async (dispatch) => {
    await api.group.removeMicrosoftTeamsIntegration({ groupId });
    dispatch({
      type: REMOVE_MICROSOFT_TEAMS_INTEGRATION,
      meta: { groupId },
    });
  };

export const setWebhookIntegrationForGroup =
  ({ groupId, body }) =>
  async (dispatch) => {
    const response = await api.group.setWebhookIntegration({ groupId, body });
    const { webhookUrl, webhookSecret } = response;

    dispatch({
      type: SET_WEBHOOK_INTEGRATION,
      meta: { groupId },
      payload: {
        webhookUrl,
        webhookSecret,
      },
    });

    return response;
  };

export const removeWebhookIntegrationFromGroup =
  ({ groupId }) =>
  async (dispatch) => {
    await api.group.removeWebhookIntegration({ groupId });

    dispatch({
      type: REMOVE_WEBHOOK_INTEGRATION,
      meta: { groupId },
    });
  };

export const setNewRelicIntegrationForGroup =
  ({ groupId, body }) =>
  async (dispatch) => {
    const response = await api.group.setNewRelicIntegration({ groupId, body });
    const { newRelicLicenseKey, newRelicInsightsAccountId, newRelicInsightsWriteToken, newRelicInsightsReadToken } =
      response;

    dispatch({
      type: SET_NEW_RELIC_INTEGRATION,
      meta: { groupId },
      payload: {
        newRelicLicenseKey,
        newRelicInsightsAccountId,
        newRelicInsightsWriteToken,
        newRelicInsightsReadToken,
      },
    });

    return response;
  };

export const removeNewRelicIntegrationFromGroup =
  ({ groupId }) =>
  async (dispatch) => {
    await api.group.removeNewRelicIntegration({ groupId });

    dispatch({
      type: REMOVE_NEW_RELIC_INTEGRATION,
      meta: { groupId },
    });
  };

export const updateDbStats =
  ({ groupId, enabled }) =>
  async (dispatch) => {
    await api.group.updateDbStatsEnabled({ groupId, enabled });

    dispatch({
      type: UPDATE_DB_STATS,
      meta: { groupId },
      payload: {
        value: !enabled,
      },
    });
  };

export const updateAllHostsLogs =
  ({ groupId, enabled }) =>
  async (dispatch) => {
    await api.group.updateLogCollectionAllHosts({ groupId, enabled });

    dispatch({
      type: UPDATE_LOG_COLLECTION,
      meta: { groupId },
      payload: {
        value: enabled,
      },
    });
  };

export const updateAllHostsProfilers =
  ({ groupId, enabled }) =>
  async (dispatch) => {
    await api.group.updateProfilingInfoAllHosts({ groupId, enabled });

    dispatch({
      type: UPDATE_PROFILERS,
      meta: { groupId },
      payload: {
        value: enabled,
      },
    });
  };

export const updateSuppressMongosAutoDiscovery =
  ({ groupId, enabled }) =>
  async (dispatch) => {
    await api.group.updateSuppressMongosDiscovery({ groupId, enabled });

    dispatch({
      type: TOGGLE_SUPPRESS_MONGOS_AUTO_DISCOVERY,
      meta: { groupId },
      payload: {
        value: enabled,
      },
    });
  };

export const addPreferredHostname =
  ({ groupId, isEndsWith, isRegexp, value }) =>
  async (dispatch) => {
    await api.group.addPreferredHostname({ groupId, isEndsWith, isRegexp, value });
    const response = await api.group.getPreferredHostnames(groupId);
    dispatch(setPreferredHostnames(response, groupId));
  };

export const deletePreferredHostname = (groupId, nameId) => async (dispatch) => {
  await api.group.deletePreferredHostname(groupId, nameId);
  const response = await api.group.getPreferredHostnames(groupId);
  dispatch(setPreferredHostnames(response, groupId));
};

export const updateGroupName = (groupId, groupName) => (dispatch) => {
  return api.group.renameGroup({ groupId, groupName }).then((response) => {
    dispatch(setGroupName(response, groupId));
    return response;
  });
};

export const updateGroupTimeZoneId = (groupId, timeZoneId, timeZoneDisplay) => (dispatch) => {
  return api.group.setGroupTimeZone({ groupId, timeZoneId, timeZoneDisplay }).then((response) => {
    dispatch(setGroupTimeZone(response, groupId));
    return response;
  });
};

// get users for all groups belonging to an organization
export const loadUsersForGroups =
  ({ orgId, groupIds }) =>
  async (dispatch) => {
    dispatch({
      type: BULK_GROUP_USERS_LOADING,
      meta: {
        groupIds,
      },
    });
    const response = await api.organization.getUsersByGroup({ orgId });
    const usersByGroup = response.reduce(
      (usersByGroup, { groupId, users = [] }) => ({
        ...usersByGroup,
        [groupId]: users,
      }),
      {}
    );
    return dispatch({
      type: SET_BULK_GROUP_USERS,
      payload: usersByGroup,
      meta: {
        orgId,
      },
    });
  };

export const setEnableCurrentIpWarning =
  ({ groupId, isEnabled }: SetEnableCurrentIpWarningParams) =>
  async (dispatch) => {
    const response = await api.settings.setEnableCurrentIpWarning({ groupId, isEnabled });

    if (response.ok) {
      return dispatch({
        type: SET_ENABLE_CURRENT_IP_WARNING,
        payload: isEnabled,
        meta: {
          groupId,
        },
      });
    }
  };

export { GRANT_API_USER_GROUP_ACCESS };
