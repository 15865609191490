// services
import * as api from 'js/common/services/api/backupAdminApi';

// ActionTypes
export interface BackupMetricsAction {
  type:
    | 'SET_AVAILABLE_BACKUP_CHARTS'
    | 'SET_BACKUP_METRICS_METADATA'
    | 'SET_BACKUP_METRICS_DATA_CPU'
    | 'SET_BACKUP_METRICS_DATA_JVM_HEAP'
    | 'SET_BACKUP_METRICS_DATA_JVM_GC_COLLECTION'
    | 'SET_BACKUP_METRICS_DATA_INGESTION_LATENCY'
    | 'SET_BACKUP_METRICS_DATA_INGESTION_BYTES'
    | 'SET_BACKUP_METRICS_DATA_OPLOGSTORE_REQUEST_BYTES'
    | 'SET_BACKUP_METRICS_DATA_OPLOGSTORE_DURATION'
    | 'SET_BACKUP_METRICS_DATA_BLOCKSTORE_REQUEST_BYTES'
    | 'SET_BACKUP_METRICS_DATA_BLOCKSTORE_DURATION'
    | 'SET_BACKUP_METRICS_DATA_CHECKPOINT_RESPONSE_LATENCY'
    | 'SET_METRICS_DISPLAY_ROW'
    | 'SET_SELECTED_CHARTS'
    | 'SET_AVAILABLE_HOSTS'
    | 'SET_SELECTED_HOSTS'
    | 'SET_IS_ZOOM_CUSTOM'
    | 'SET_BACKUP_METRICS_DATA_META_WINDOW'
    | 'SET_BACKUP_METRICS_DATA_BACKUP_JOB_WRITE_LATENCY'
    | 'SET_BACKUP_METRICS_DATA_GROUP_DAO_READ_LATENCY';
  payload?: any;
}

export interface Window {
  since: number;
  until: number;
}

export interface Zoom {
  label: string;
  millis: number;
}

export interface MetaData {
  zoom?: Zoom;
  window?: Window;
}

export type Metrics = {
  [key in string]: {
    data: Array<
      | {
          [key: string]: Array<number>;
        }
      | number
    >;
    hosts: Array<string>;
  };
};

export interface AvailableMetrics {
  [key: string]: AvailableMetric;
}

type UnitType = 'scalar' | 'bytes' | 'percent';
type UnitFrequency = 'second' | 'milliseconds' | 'none';

interface AvailableMetric {
  label: string;
  info: string;
  series: Array<{
    units: {
      frequency: UnitFrequency;
      type: UnitType;
    };
  }>;
  units: {
    frequency: UnitFrequency;
    type: UnitType;
  };
}

export interface MetricsQuery {
  since?: number;
  until?: number;
  retention?: number;
  aggregator?: String;
}

export interface State {
  metricsMeta: MetaData;
  metrics: Metrics;
  availableMetrics: AvailableMetrics;
  metricsDisplayRow: boolean;
  selectedCharts: Array<string>;
  availableHosts: Array<string>;
  selectedHosts: Array<string>;
  isZoomCustom: boolean;
}

const actionMetricChart = {
  SET_BACKUP_METRICS_DATA_JVM_HEAP: 'jvm_memory_bytes_used',
  SET_BACKUP_METRICS_DATA_JVM_GC_COLLECTION: 'jvm_gc_collection_seconds',
  SET_BACKUP_METRICS_DATA_CPU: 'process_cpu_seconds_total',
  SET_BACKUP_METRICS_DATA_INGESTION_BYTES: 'mms_bslurp_data_ingestion_bytes_total',
  SET_BACKUP_METRICS_DATA_INGESTION_LATENCY: 'mms_bslurp_network_ingestion_latency_ms',
  SET_BACKUP_METRICS_DATA_BACKUP_JOB_WRITE_LATENCY: 'mms_backup_dao_implicitjobv2_newoplogs_duration_seconds',
  SET_BACKUP_METRICS_DATA_GROUP_DAO_READ_LATENCY: 'mms_monitoring_dao_group_findbyid_duration_seconds',
  SET_BACKUP_METRICS_DATA_OPLOGSTORE_REQUEST_BYTES: 'mms_oplogstore_requests_size_bytes_total',
  SET_BACKUP_METRICS_DATA_OPLOGSTORE_DURATION: 'mms_oplogstore_requests_duration_seconds',
  SET_BACKUP_METRICS_DATA_BLOCKSTORE_REQUEST_BYTES: 'mms_backup_blockstore_requests_size_bytes_total',
  SET_BACKUP_METRICS_DATA_BLOCKSTORE_DURATION: 'mms_backup_blockstore_requests_duration_seconds',
  SET_BACKUP_METRICS_DATA_CHECKPOINT_RESPONSE_LATENCY: 'mms_backup_checkpoint_response_time_seconds',
};

const metricMetadata = {
  jvm_memory_bytes_used: {
    labels: [{ area: 'heap' }],
    charts: [
      {
        chartName: 'JVM Heap Usage',
        info: 'Heap usage of of the Ops Manager application server over time. A healthy value is expected to exhibit a saw-tooth pattern that rises over time and then drops back a low value again. A consistently high value can indicate memory pressure, and that the application server may benefit from being configured with additional heap.',
        series: { units: { frequency: 'none', type: 'gigabytes' } },
      },
    ],
  },
  jvm_gc_collection_seconds: {
    labels: [{ gc: 'G1 Young Generation' }],
    charts: [
      {
        chartName: 'JVM Garbage Collection',
        info: 'The average Ops Manager application server young generation garbage collection pause time. Values routinely in excess of 1 second can indicate can indicate memory pressure, and that the application server may benefit from being configured with a larger heap.',
        series: { units: { frequency: 'none', type: 'milliseconds' } },
      },
    ],
  },
  process_cpu_seconds_total: {
    labels: [{}],
    charts: [
      {
        chartName: 'Process CPU Usage',
        info: 'The average Ops Manager application process user and system CPU utilization on a scale of 0 to 100 * [num-CPU-cores]. For example, a fully saturated process on a system with 8 cores would expect values near 800%. Saturated CPU utilization during Backup Snapshots can cause a bottleneck to snapshot performance due to contention around in-process compression.',
        series: { units: { frequency: 'none', type: 'percent' } },
      },
    ],
  },
  mms_backup_dao_implicitjobv2_newoplogs_duration_seconds: {
    labels: [{}],
    charts: [
      {
        chartName: 'AppDB latency to perform one Backup Job write',
        info: 'The average duration to perform one write of a Backup Job to the Ops Manager Application Database (AppDB). Values routinely exceeding 10s of milliseconds can indicate a high geographical latency between Ops Manager application servers and the AppDB, or an AppDB whose performance has become saturated. High values can become a MongoDB 4.2+ Backup Snapshot performance bottleneck.',
        series: { units: { frequency: 'none', type: 'seconds' } },
      },
    ],
  },
  mms_monitoring_dao_group_findbyid_duration_seconds: {
    labels: [{ readpref: 'primary' }],
    charts: [
      {
        chartName: 'AppDB latency to read Project metadata',
        info: 'The average duration to perform one read of Project metadata from the Ops Manager Application Database (AppDB). Values exceeding 10s milliseconds can indicate a high geographical latency between Ops Manager application servers and the AppDB, or an AppDB whose performance has become saturated. High values can become a MongoDB 4.2+ Backup Snapshot performance bottleneck.',
        series: { units: { frequency: 'none', type: 'seconds' } },
      },
    ],
  },
  mms_backup_checkpoint_response_time_seconds: {
    labels: [{ template_path: '/backup/wtcheckpoint/v2/{groupId}/{rsId}/{backupId}/dataBlocks' }],
    charts: [
      {
        chartName: 'Latency per Snapshot blocks request',
        info: 'The average Ops Manager application server HTTP response time per the Snapshot blocks request.',
        series: { units: { frequency: 'none', type: 'seconds' } },
      },
    ],
  },
  mms_bslurp_network_ingestion_latency_ms: {
    labels: [{ source: 'dataBlocksV2' }, { source: 'oplogStream' }],
    charts: [
      {
        chartName: 'Network latency to receive one MongoDB 4.2+ snapshot block',
        info: 'The average duration for a MongoDB 4.2+ snapshot request within the Ops Manager application server to read one batch of incoming block data from the MongoDB Agent. High values, such as those exceeding a few seconds, likely indicate an upstream network bottleneck between the transmitting MongoDB Agent and the application server.',
        series: { units: { frequency: 'none', type: 'milliseconds' } },
      },
      {
        chartName: 'Network latency to receive one oplog slice',
        info: 'The average duration for an oplog request within the Ops Manager application server to read one slice of oplog data from the MongoDB Agent.',
        series: { units: { frequency: 'none', type: 'milliseconds' } },
      },
    ],
  },
  mms_backup_blockstore_requests_duration_seconds: {
    labels: [
      { type: 'mongo', request: 'saveBlocks' },
      { type: 's3', request: 'saveBlock' },
    ],
    charts: [
      {
        chartName: 'Latency to write one block to MongoDB Snapshot Stores',
        info: 'The average duration the Ops Manager application server observes to write one snapshot block to MongoDB Snapshot Stores. If multiple MongoDB Snapshot Stores are present, the value is the average duration across the stores.',
        series: { units: { frequency: 'none', type: 'seconds' } },
      },
      {
        chartName: 'Latency to write one block to S3 Snapshot Stores',
        info: 'The average duration the Ops Manager application server observes to write one snapshot block to S3 Snapshot Stores. If multiple S3 Snapshot Stores are present, the value is the average duration across the stores.',
        series: { units: { frequency: 'none', type: 'seconds' } },
      },
    ],
  },
  mms_oplogstore_requests_duration_seconds: {
    labels: [
      { type: 'mongo', request: 'add' },
      { type: 's3', request: 'addOplogSlice' },
    ],
    charts: [
      {
        chartName: 'Latency to write one oplog slice to MongoDB Oplog Stores',
        info: 'The average duration the Ops Manager application server observes to write one oplog slice to MongoDB Oplog Stores. If multiple MongoDB Oplog Stores are present, the value is the average duration across the stores.',
        series: { units: { frequency: 'none', type: 'seconds' } },
      },
      {
        chartName: 'Latency to write one oplog slice to S3 Oplog Stores',
        info: 'The average duration the Ops Manager application server observes to write one oplog slice to S3 Oplog Stores. If multiple S3 Oplog Stores are present, the value is the average duration across the stores.',
        series: { units: { frequency: 'none', type: 'seconds' } },
      },
    ],
  },
  mms_bslurp_data_ingestion_bytes_total: {
    labels: [
      { type: 'wtc', source: 'dataBlocksV2' },
      { type: 'wtc', source: 'oplogStream' },
    ],
    charts: [
      {
        chartName: 'Throughput of MongoDB 4.2+ snapshot bytes received',
        info: 'The rate of MongoDB 4.2+ snapshot bytes received per Ops Manager application server process. Low values per process can indicate a number of potential causes from an upstream network bottleneck between the transmitting MongoDB Agent and the application server, to downstream backpressure from a slow AppDB or slow Snapshot store performance.',
        series: { units: { frequency: 'second', type: 'bytes' } },
      },
      {
        chartName: 'Throughput of oplog bytes received',
        info: 'The rate of oplog bytes received per Ops Manager application server process.',
        series: { units: { frequency: 'second', type: 'bytes' } },
      },
    ],
  },
  mms_backup_blockstore_requests_size_bytes_total: {
    labels: [
      { type: 'mongo', request: 'saveBlocks' },
      { type: 's3', request: 'saveBlock' },
    ],
    charts: [
      {
        chartName: 'Throughput of compressed bytes written to MongoDB Snapshot Stores',
        info: 'The rate of compressed Backup snapshot bytes written across all MongoDB Snapshot Stores by Ops Manager application server.',
        series: { units: { frequency: 'second', type: 'bytes' } },
      },
      {
        chartName: 'Throughput of compressed bytes written to S3 Snapshot Stores',
        info: 'The rate of compressed Backup snapshot bytes written across all S3 Snapshot Stores by Ops Manager application server.',
        series: { units: { frequency: 'second', type: 'bytes' } },
      },
    ],
  },
  mms_oplogstore_requests_size_bytes_total: {
    labels: [
      { type: 'mongo', request: 'addOplogSlice' },
      { type: 's3', request: 'addOplogSlice' },
    ],
    charts: [
      {
        chartName: 'Throughput of compressed oplog bytes written to MongoDB Oplog Stores',
        info: 'The rate of compressed Backup oplog bytes written across all MongoDB Oplog Stores by Ops Manager application server.',
        series: { units: { frequency: 'second', type: 'bytes' } },
      },
      {
        chartName: 'Throughput of compressed oplog bytes written to S3 Oplog Stores',
        info: 'The rate of compressed Backup oplog bytes written across all S3 Oplog Stores by Ops Manager application server.',
        series: { units: { frequency: 'second', type: 'bytes' } },
      },
    ],
  },
};

const metricKeys = Object.keys(metricMetadata);
const metricLabelsMap = {};
const metricChartsMap = {};
metricKeys.forEach((key) => {
  metricLabelsMap[key] = metricMetadata[key].labels.map((label) => {
    return `${key}_${JSON.stringify(label)}`;
  });
  metricChartsMap[key] = metricMetadata[key].charts;
});

const availableCharts: Array<any> = [];
metricKeys.forEach((key) => {
  for (let i = 0; i < metricLabelsMap[key].length; i++) {
    const label = metricLabelsMap[key][i];
    const chart = metricChartsMap[key][i];
    const chartFormatted = {
      [label]: {
        label: chart.chartName,
        info: chart.info,
        series: [chart.series],
        units: chart.series.units,
      },
    };
    availableCharts.push(chartFormatted);
  }
});

const metrics = Object.values(metricLabelsMap)
  .flat()
  .map((metricKey: string) => {
    return {
      [metricKey]: {
        hosts: [],
        data: [],
      },
    };
  });

const defaultState: State = {
  metricsMeta: {},
  metrics: Object.assign({}, ...metrics),
  availableMetrics: {},
  metricsDisplayRow: false,
  selectedCharts: [],
  availableHosts: [],
  selectedHosts: [],
  isZoomCustom: false,
};

function labelsEqual(requestLabel, responseLabel) {
  if (requestLabel == null || responseLabel == null) return false;
  if (Object.keys(requestLabel).length !== Object.keys(responseLabel).length) {
    return false;
  }
  for (const key in requestLabel) {
    if (requestLabel[key] !== responseLabel[key]) {
      return false;
    }
  }
  return true;
}

// Reducer
export default function backupMetricsReducer(state: State = defaultState, action: BackupMetricsAction): State {
  const { type, payload } = action;
  switch (type) {
    case 'SET_BACKUP_METRICS_METADATA': {
      return {
        ...state,
        metricsMeta: payload,
      };
    }
    case 'SET_AVAILABLE_BACKUP_CHARTS': {
      return {
        ...state,
        availableMetrics: payload.metrics,
      };
    }
    case 'SET_AVAILABLE_HOSTS': {
      return {
        ...state,
        availableHosts: payload.hosts,
      };
    }
    case 'SET_BACKUP_METRICS_DATA_CPU':
    case 'SET_BACKUP_METRICS_DATA_JVM_HEAP':
    case 'SET_BACKUP_METRICS_DATA_JVM_GC_COLLECTION':
    case 'SET_BACKUP_METRICS_DATA_INGESTION_LATENCY':
    case 'SET_BACKUP_METRICS_DATA_INGESTION_BYTES':
    case 'SET_BACKUP_METRICS_DATA_BACKUP_JOB_WRITE_LATENCY':
    case 'SET_BACKUP_METRICS_DATA_GROUP_DAO_READ_LATENCY':
    case 'SET_BACKUP_METRICS_DATA_OPLOGSTORE_REQUEST_BYTES':
    case 'SET_BACKUP_METRICS_DATA_OPLOGSTORE_DURATION':
    case 'SET_BACKUP_METRICS_DATA_BLOCKSTORE_REQUEST_BYTES':
    case 'SET_BACKUP_METRICS_DATA_BLOCKSTORE_DURATION':
    case 'SET_BACKUP_METRICS_DATA_CHECKPOINT_RESPONSE_LATENCY': {
      const metricName = actionMetricChart[type];
      const filteredResult = payload.metrics.filter((metric) => metric.name === metricName);
      const metricsUpdated = { ...state.metrics };

      filteredResult.forEach((labelData) => {
        const matchingLabel = metricMetadata[metricName].labels.find((label) => labelsEqual(label, labelData.label));
        const metricAndLabel = `${metricName}_${JSON.stringify(matchingLabel)}`;

        metricsUpdated[metricAndLabel] = {
          data: labelData.data,
          hosts: payload.hosts,
        };
      });

      return {
        ...state,
        metrics: {
          ...metricsUpdated,
        },
      };
    }

    case 'SET_METRICS_DISPLAY_ROW': {
      return {
        ...state,
        metricsDisplayRow: payload,
      };
    }
    case 'SET_SELECTED_CHARTS': {
      return {
        ...state,
        selectedCharts: payload,
      };
    }
    case 'SET_SELECTED_HOSTS': {
      return {
        ...state,
        selectedHosts: payload,
      };
    }
    case 'SET_IS_ZOOM_CUSTOM': {
      return {
        ...state,
        isZoomCustom: payload,
      };
    }
    case 'SET_BACKUP_METRICS_DATA_META_WINDOW': {
      return {
        ...state,
        metricsMeta: {
          ...state.metricsMeta,
          window: payload,
        },
      };
    }
    default:
      return state;
  }
}
const getBackupMetrics = (state): State => state.backupMetrics;

// Selectors
export const getBackupMetricsMeta = (state): MetaData => getBackupMetrics(state).metricsMeta || {};
export const getBackupMetricsData = (
  state,
  metricId
): { data: Array<{ [p: string]: Array<number> } | number>; hosts: Array<string> } =>
  getBackupMetrics(state).metrics[metricId] || [];
export const getAvailableBackupMetrics = (state): AvailableMetrics => getBackupMetrics(state).availableMetrics || {};
export const getAvailableHosts = (state): Array<string> => getBackupMetrics(state).availableHosts || {};
export const getWindow = (state): Window | undefined => getBackupMetricsMeta(state).window;
export const getMetricsDisplayRow = (state): boolean => getBackupMetrics(state).metricsDisplayRow;
export const getSelectedCharts = (state): Array<string> => getBackupMetrics(state).selectedCharts;
export const getSelectedHosts = (state): Array<string> => getBackupMetrics(state).selectedHosts;
export const getIsZoomCustom = (state): boolean => getBackupMetrics(state).isZoomCustom;
export const getAvailableBackupMetricsForMetricId = (state, metricId): AvailableMetric =>
  getBackupMetrics(state).availableMetrics[metricId] ?? {};

// Action Creators
export const setBackupMetricsMetaData = (payload) => ({
  type: 'SET_BACKUP_METRICS_METADATA',
  payload,
});

export const setBackupMetricsDataCPU = (payload) => ({
  type: 'SET_BACKUP_METRICS_DATA_CPU',
  payload,
});

export const setBackupMetricsDataJVMHeap = (payload) => ({
  type: 'SET_BACKUP_METRICS_DATA_JVM_HEAP',
  payload,
});

export const setBackupMetricsDataJvmGcCollection = (payload) => ({
  type: 'SET_BACKUP_METRICS_DATA_JVM_GC_COLLECTION',
  payload,
});

export const setBackupMetricsDataIngestionBytes = (payload) => ({
  type: 'SET_BACKUP_METRICS_DATA_INGESTION_BYTES',
  payload,
});

export const setBackupMetricsDataIngestionLatency = (payload) => ({
  type: 'SET_BACKUP_METRICS_DATA_INGESTION_LATENCY',
  payload,
});

export const setBackupMetricsBackupJobWriteLatency = (payload) => ({
  type: 'SET_BACKUP_METRICS_DATA_BACKUP_JOB_WRITE_LATENCY',
  payload,
});

export const setBackupMetricsDataGroupDaoReadLatency = (payload) => ({
  type: 'SET_BACKUP_METRICS_DATA_GROUP_DAO_READ_LATENCY',
  payload,
});

export const setBackupMetricsOplogstoreRequestBytes = (payload) => ({
  type: 'SET_BACKUP_METRICS_DATA_OPLOGSTORE_REQUEST_BYTES',
  payload,
});

export const setBackupMetricsOplogstoreDuration = (payload) => ({
  type: 'SET_BACKUP_METRICS_DATA_OPLOGSTORE_DURATION',
  payload,
});

export const setBackupMetricsBlockstoreRequestBytes = (payload) => ({
  type: 'SET_BACKUP_METRICS_DATA_BLOCKSTORE_REQUEST_BYTES',
  payload,
});

export const setBackupMetricsBlockstoreDuration = (payload) => ({
  type: 'SET_BACKUP_METRICS_DATA_BLOCKSTORE_DURATION',
  payload,
});

export const setBackupMetricsCheckpointResponseLatency = (payload) => ({
  type: 'SET_BACKUP_METRICS_DATA_CHECKPOINT_RESPONSE_LATENCY',
  payload,
});

export const setAvailableBackupCharts = (payload) => ({
  type: 'SET_AVAILABLE_BACKUP_CHARTS',
  payload,
});

export const setMetricsDisplayRow = (payload: boolean) => ({
  type: 'SET_METRICS_DISPLAY_ROW',
  payload,
});

export const setSelectedCharts = (payload) => ({
  type: 'SET_SELECTED_CHARTS',
  payload,
});

export const setAvailableHosts = (payload) => ({
  type: 'SET_AVAILABLE_HOSTS',
  payload,
});

export const setSelectedHosts = (payload) => ({
  type: 'SET_SELECTED_HOSTS',
  payload,
});

export const setIsZoomCustom = (payload: boolean) => ({
  type: 'SET_IS_ZOOM_CUSTOM',
  payload,
});

export const setBackupMetricsDataMetaWindow = (payload: Window) => ({
  type: 'SET_BACKUP_METRICS_DATA_META_WINDOW',
  payload,
});

const metricKeysActionsToDispatch = {
  process_cpu_seconds_total: setBackupMetricsDataCPU,
  jvm_memory_bytes_used: setBackupMetricsDataJVMHeap,
  jvm_gc_collection_seconds: setBackupMetricsDataJvmGcCollection,
  mms_bslurp_data_ingestion_bytes_total: setBackupMetricsDataIngestionBytes,
  mms_bslurp_network_ingestion_latency_ms: setBackupMetricsDataIngestionLatency,
  mms_backup_dao_implicitjobv2_newoplogs_duration_seconds: setBackupMetricsBackupJobWriteLatency,
  mms_monitoring_dao_group_findbyid_duration_seconds: setBackupMetricsDataGroupDaoReadLatency,
  mms_oplogstore_requests_size_bytes_total: setBackupMetricsOplogstoreRequestBytes,
  mms_oplogstore_requests_duration_seconds: setBackupMetricsOplogstoreDuration,
  mms_backup_blockstore_requests_size_bytes_total: setBackupMetricsBlockstoreRequestBytes,
  mms_backup_blockstore_requests_duration_seconds: setBackupMetricsBlockstoreDuration,
  mms_backup_checkpoint_response_time_seconds: setBackupMetricsCheckpointResponseLatency,
};

// dispatchers
export const loadBackupMetricsData = (queryString: MetricsQuery) => (dispatch) => {
  if (queryString.retention !== undefined) {
    const endDate = new Date().getTime();
    const startDate = endDate - queryString.retention;
    queryString.since = startDate;
    queryString.until = endDate;
  }

  let apiCallsFinished = 0;
  Object.keys(metricMetadata).forEach((metricKey) => {
    api.default
      .getBackupMetrics(
        [{ name: metricKey, labels: metricMetadata[metricKey].labels }],
        queryString.since,
        queryString.until
      )
      .then((response) => {
        dispatch(metricKeysActionsToDispatch[metricKey](response));
        apiCallsFinished++;
        if (apiCallsFinished === 1) {
          dispatch(setAvailableHosts(response));
          dispatch(setSelectedHosts(response.hosts.sort()));
        }
      });
  });

  dispatch(setBackupMetricsMetaData(formatTimestamp(queryString)));
};

export const loadAvailableBackupCharts = (dispatch) => {
  dispatch(
    setAvailableBackupCharts({
      metrics: {
        ...Object.assign({}, ...availableCharts),
      },
    })
  );
};

export const loadAllBackupChartsSelected = (dispatch) => {
  dispatch(setSelectedCharts(Object.values(metricLabelsMap).flat()));
};

export const loadSelectedBackupCharts = (selectedCharts: Array<string>) => (dispatch) => {
  dispatch(setSelectedCharts(selectedCharts));
};

export const loadSelectedHosts = (selectedHosts: Array<string>) => (dispatch) => {
  dispatch(setSelectedHosts(selectedHosts));
};

const ONE_HOUR_IN_MILLISECONDS = 3600000;
export const buildQueryParams = (isZoomCustom: boolean, window: Window | undefined) => {
  return {
    retention: !isZoomCustom ? ONE_HOUR_IN_MILLISECONDS : undefined,
    since: isZoomCustom && window ? window.since : undefined,
    until: isZoomCustom && window ? window.until : undefined,
  };
};

export const formatTimestamp = (queryString: MetricsQuery) => {
  const { retention, until, since } = queryString;

  if (retention === undefined) {
    return { window: { until, since } };
  }

  const count = retention / ONE_HOUR_IN_MILLISECONDS;
  return {
    zoom: { label: `${count} hour`, millis: retention },
    window: { until, since },
  };
};
