const SET_ACTIVE_CLUSTER_NAME = 'nds/activeCluster/SET_ACTIVE_CLUSTER_NAME';
const RESET_ACTIVE_CLUSTER = 'nds/activeCluster/RESET_ACTIVE_CLUSTER';

export default function activeClusterNameReducer(state = {}, { type, name }) {
  switch (type) {
    case SET_ACTIVE_CLUSTER_NAME:
      return { name };
    case RESET_ACTIVE_CLUSTER:
      return {};
    default:
      return state;
  }
}

export const setActiveClusterName = (name: string) => (dispatch) => {
  dispatch({
    type: SET_ACTIVE_CLUSTER_NAME,
    name,
  });
};

export const resetActiveCluster = () => (dispatch) => {
  dispatch({
    type: RESET_ACTIVE_CLUSTER,
  });
};

export const getActiveClusterName = (state) => state.nds.activeCluster?.name;
