import { useReducer } from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Banner from '@leafygreen-ui/banner';
import Button from '@leafygreen-ui/button';
import Icon from '@leafygreen-ui/icon';
import { H2 } from '@leafygreen-ui/typography';
import { Helmet } from 'react-helmet';
import { Link, RouteComponentProps } from 'react-router-dom';

import { FieldAction, FieldNames } from '@packages/types/auth/field';
import { WindowLocation } from '@packages/types/browser';

import { useRequestParams } from 'js/common/context/RequestParamsContext';
import { user } from 'js/common/services/api';
import { getErrorMessageFromCode, getResetErrorMessageFromCode } from 'js/common/services/authErrorHelper';
import { mq } from 'js/common/utils/mediaQueries';

import { ResetFormValues, TParams } from 'js/auth/types/reset';
import colors from 'js/auth/utils/palette';

import FormInput from './FormInput';
import HeaderLogo from './HeaderLogo';
import Layout from './Layout';
import { bannerStyle } from './styles/banner';
import { ErrorMessage, header } from './styles/form';
import { MultiFactorAuthContainer } from './styles/multiFactorPage';
import { resetButton, resetIcon, ResetMFASuccess, resetSuccessLoginLink, ResetSuccessText } from './styles/reset';

const ResetMultiFactorText = styled.p(() =>
  mq({
    fontSize: '13px',
    color: colors.cardHeadlineText,
    lineHeight: '20px',
    letterSpacing: 0,
    marginBottom: '24px',
    width: ['auto', 'calc(100vw - 32px)', 'auto', 'auto'],
  })
);

const Text = styled.p(() =>
  mq({
    fontSize: '13px',
    lineHeight: '20px',
    color: colors.labelText,
    margin: '5px 0 0 0',
    width: ['300px', 'auto', '300px', '300px'],
    letterSpacing: '0.02em',
  })
);

const resetMultiFactorLink = css(
  mq({
    display: 'block',
    textDecoration: 'none',
    color: colors.link,
    fontSize: '14px',
    lineHeight: ['20px', '17px', '17px', '20px'],
    textAlign: ['left', 'center', 'left', 'left'],
  })
);

const ResetAlertLink = styled.a({
  color: colors.link,
  textDecoration: 'none',
});
interface ResetMultiFactorPageProps {
  match?: RouteComponentProps<TParams>;
  windowLocation: WindowLocation;
}

interface ProcessResetCompleteErrorResponse {
  type: 'processResetCompleteErrorResponse';
  payload: {
    resetErrorCode: string;
    customerSyncComplete: boolean;
  };
}

interface ReducerState {
  shouldContactSupport: boolean;
  resetSuccess: boolean;
  resetErrorCode: string;
  customerSyncComplete: boolean;
  username: string;
  password: string;
  dbUsername: string;
  dbPassword: string;
  apiKey: string;
  disableSubmit: boolean;
}

const reducerState = {
  shouldContactSupport: false,
  resetSuccess: false,
  resetErrorCode: '',
  customerSyncComplete: false,
  username: '',
  password: '',
  dbUsername: '',
  dbPassword: '',
  apiKey: '',
  disableSubmit: false,
};

function resetMultiFactorReducer(state: ReducerState, action: FieldAction | ProcessResetCompleteErrorResponse) {
  switch (action.type) {
    case 'field': {
      const { field, value } = action.payload;
      return {
        ...state,
        [field]: typeof value !== 'undefined' ? value : true,
      };
    }
    case 'processResetCompleteErrorResponse': {
      const { resetErrorCode, customerSyncComplete } = action.payload;
      return {
        ...state,
        resetSuccess: false,
        resetErrorCode,
        customerSyncComplete,
        disableSubmit: false,
      };
    }
    default:
      return state;
  }
}

export default function ResetMultiFactorPage({
  match: { params } = { params: '' },
  windowLocation = window.location,
}: ResetMultiFactorPageProps) {
  const { tempId, planType } = params;

  const isNDS = planType === 'atlas';

  const { onPrem, siteName, multiFactorAuthResetAllowed } = useRequestParams();

  const [
    {
      shouldContactSupport,
      resetSuccess,
      resetErrorCode,
      username,
      password,
      dbUsername,
      dbPassword,
      apiKey,
      disableSubmit,
    },
    dispatch,
  ] = useReducer(resetMultiFactorReducer, reducerState);

  const onFormSubmit = async (e) => {
    e.preventDefault();
    const resetType = 'MULTI_FACTOR_AUTHENTICATION';
    dispatch({ type: 'field', payload: { field: FieldNames.DISABLE_SUBMIT } });

    if (!tempId) {
      // don't proceed further if tempId is not present and username is not given
      if (!username) {
        return;
      }

      try {
        await user.resetRequest({ username, nds: !onPrem && isNDS, resetType });
      } catch (e) {
        console.log('Some error occurred processing the reset request', e);
      }
    } else {
      const formVals: ResetFormValues = {
        username,
        nds: !onPrem && isNDS,
        resetType,
        tempId,
        password,
      };

      if (isNDS) {
        formVals.dbUsername = dbUsername;
        formVals.dbPassword = dbPassword;
      } else {
        formVals.apiKey = apiKey;
      }

      try {
        await user.resetComplete(formVals);
      } catch ({ errorCode, customerSyncComplete: customerSyncCompleteParam }) {
        dispatch({
          type: 'processResetCompleteErrorResponse',
          payload: {
            resetErrorCode: errorCode,
            customerSyncComplete: customerSyncCompleteParam,
          },
        });
        return;
      }
    }
    dispatch({ type: 'field', payload: { field: FieldNames.RESET_SUCCESS } });
  };

  const isUsernameError = resetErrorCode.toString().includes('USERNAME');
  const isPasswordError = resetErrorCode.toString().includes('PASSWORD');
  const isAPIKeyError = resetErrorCode.toString().includes('GROUP_KEY');
  const resetErrorMessage = getResetErrorMessageFromCode(resetErrorCode);

  return (
    <>
      <Helmet title="Reset Two-Factor Authentication" />
      <Layout contentPlacement="right">
        <MultiFactorAuthContainer>
          <form noValidate onSubmit={onFormSubmit} method="post">
            <HeaderLogo />
            <H2 css={[header, css({ marginBottom: '16px' })]}>Reset Two-Factor Authentication</H2>
            {tempId ? (
              <>
                {shouldContactSupport && (
                  <Banner variant="danger" data-testid="dangerBanner" css={bannerStyle}>
                    &nbsp; Please contact support at{' '}
                    <ResetAlertLink href="mailto:cloud-account-support@mongodb.com">
                      cloud-account-support@mongodb.com
                    </ResetAlertLink>
                  </Banner>
                )}
                {resetSuccess ? (
                  <Banner variant="success" data-testid="resetCompleteSuccess" css={bannerStyle}>
                    &nbsp; Your 2FA has been successfully reset. Click{' '}
                    <Link to="/login" css={resetSuccessLoginLink}>
                      Log in
                    </Link>{' '}
                    to continue.
                  </Banner>
                ) : (
                  <>
                    {isNDS ? (
                      <ResetMultiFactorText>
                        Enter your MongoDB Atlas account username, password, and the database username (@admin) and
                        password of one of the managed database users in your MongoDB Atlas deployment.
                      </ResetMultiFactorText>
                    ) : (
                      <ResetMultiFactorText>
                        Enter your email or username, password and any valid Agent API key to reset your two-factor
                        authentication. The Agent API Key can be found in the configuration file for your Monitoring
                        Agent or Backup Agent.
                      </ResetMultiFactorText>
                    )}
                    {resetErrorCode && !isUsernameError && !isPasswordError && !isAPIKeyError && (
                      <Banner variant="danger" data-testid="resetError" css={bannerStyle}>
                        &nbsp;
                        <span>{resetErrorMessage}&nbsp;</span>
                        {resetErrorCode === 'MULTI_FACTOR_AUTH_USER_NOT_IN_GROUP' && (
                          <span>
                            Please file a support ticket at&nbsp;
                            <a href="https://support.mongodb.com">support.mongodb.com</a> for further assistance. If you
                            do not have access to our Support Portal, please send an email to&nbsp;
                            <a href="mailto:cloud-account-support@mongodb.com">cloud-account-support@mongodb.com</a> for
                            further assistance.
                          </span>
                        )}
                      </Banner>
                    )}
                    <FormInput
                      fieldName={FieldNames.USERNAME}
                      labelName={isNDS ? 'Your Account Username' : 'Username'}
                      onChange={(e) =>
                        dispatch({ type: 'field', payload: { field: FieldNames.USERNAME, value: e.target.value } })
                      }
                      value={username}
                      hasError={isUsernameError}
                      autoFocus
                    >
                      <Text>Enter your username if you signed up with a username</Text>
                      {isUsernameError && <ErrorMessage data-testid="error-message">{resetErrorMessage}</ErrorMessage>}
                    </FormInput>
                    <FormInput
                      fieldName={FieldNames.PASSWORD}
                      labelName={isNDS ? 'Your Account Password' : 'Password'}
                      type="password"
                      onChange={(e) =>
                        dispatch({ type: 'field', payload: { field: FieldNames.PASSWORD, value: e.target.value } })
                      }
                      value={password}
                      showCheckmark={password.length > 0}
                      hasError={isPasswordError}
                    >
                      {isPasswordError && <ErrorMessage data-testid="error-message">{resetErrorMessage}</ErrorMessage>}
                    </FormInput>
                    {!isNDS && (
                      <FormInput
                        fieldName={FieldNames.API_KEY}
                        labelName="API Key"
                        type="password"
                        onChange={(e) =>
                          dispatch({ type: 'field', payload: { field: FieldNames.API_KEY, value: e.target.value } })
                        }
                        value={apiKey}
                        showCheckmark={apiKey.length > 0}
                        hasError={isAPIKeyError}
                      >
                        {isAPIKeyError && <ErrorMessage data-testid="error-message">{resetErrorMessage}</ErrorMessage>}
                      </FormInput>
                    )}
                    {isNDS && (
                      <FormInput
                        fieldName={FieldNames.DB_USER_NAME}
                        labelName="Database Username"
                        type="password"
                        placeholder="@admin"
                        onChange={(e) =>
                          dispatch({
                            type: 'field',
                            payload: { field: FieldNames.DB_USER_NAME, value: e.target.value },
                          })
                        }
                        value={dbUsername}
                        showCheckmark={dbUsername.length > 0}
                      />
                    )}
                    {isNDS && (
                      <FormInput
                        fieldName={FieldNames.DB_PASSWORD}
                        labelName="Database Password"
                        type="password"
                        onChange={(e) =>
                          dispatch({ type: 'field', payload: { field: FieldNames.DB_PASSWORD, value: e.target.value } })
                        }
                        value={dbPassword}
                        showCheckmark={dbPassword.length > 0}
                      />
                    )}
                    <footer>
                      <Button
                        variant="primary"
                        size="default"
                        type="submit"
                        disabled={
                          isNDS
                            ? username === '' || password === '' || dbUsername === '' || dbPassword === ''
                            : username === '' || password === '' || apiKey === ''
                        }
                        css={resetButton}
                      >
                        Submit
                      </Button>
                    </footer>
                  </>
                )}
              </>
            ) : (
              <>
                {multiFactorAuthResetAllowed ? (
                  <>
                    <ResetMultiFactorText>
                      {isNDS ? (
                        <>
                          In order to reset your two-factor authentication, you must be able to receive email at the
                          address associated with your account, know your password, and know the username and password
                          of one of the Atlas MongoDB users for any Project of which you are a member.
                        </>
                      ) : (
                        <>
                          In order to reset your two-factor authentication, you must be able to receive email at the
                          address associated with your account, know your password, and know the Agent API Key for any
                          Project of which you are a member. The Agent API Key may be found in the configuration file
                          for your Automation, Backup or Monitoring Agent.
                          <br />
                          <br />
                          These configuration files can typically be found in the&nbsp;<kbd>/etc/mongodb-mms</kbd>{' '}
                          directory on any server managed by {siteName}.
                        </>
                      )}
                    </ResetMultiFactorText>
                    {resetSuccess ? (
                      <ResetMFASuccess>
                        <Icon glyph="CheckmarkWithCircle" fill={colors.resetSuccessIcon} css={resetIcon} />
                        &nbsp;
                        <ResetSuccessText>
                          Please check your email inbox for a link to complete the reset.
                        </ResetSuccessText>
                      </ResetMFASuccess>
                    ) : (
                      <>
                        {resetErrorCode && (
                          <Banner variant="danger" data-testid="dangerBanner">
                            &nbsp;
                            <span>{getErrorMessageFromCode(resetErrorCode)}&nbsp;</span>
                          </Banner>
                        )}
                        <FormInput
                          fieldName={FieldNames.USERNAME}
                          labelName="Email Address"
                          onChange={(e) =>
                            dispatch({ type: 'field', payload: { field: FieldNames.USERNAME, value: e.target.value } })
                          }
                          value={username}
                          autoFocus
                        />
                        <footer>
                          <Button
                            variant="primary"
                            size="default"
                            type="submit"
                            css={resetButton}
                            disabled={username === '' || disableSubmit}
                          >
                            Reset Two-Factor Authentication
                          </Button>
                          {!onPrem && multiFactorAuthResetAllowed && isNDS && (
                            <Link to="/reset/mfa/cloud" css={resetMultiFactorLink}>
                              Cloud Manager user? Click here
                            </Link>
                          )}
                          {!onPrem && multiFactorAuthResetAllowed && !isNDS && (
                            <Link to="/reset/mfa/atlas" css={resetMultiFactorLink}>
                              Atlas user? Click here
                            </Link>
                          )}
                        </footer>
                      </>
                    )}
                  </>
                ) : (
                  <ResetMultiFactorText>
                    The ability to reset your two-factor authentication has been disabled in this environment. Please
                    contact support for assistance.
                  </ResetMultiFactorText>
                )}
              </>
            )}
          </form>
        </MultiFactorAuthContainer>
      </Layout>
    </>
  );
}
