import _ from 'underscore';

/**
 *
 * Simple cache implementation supporting the following API:
 *
 * // AJAX request
 * const itemCache = new Cache({
 *     load() {
 *        return $.get({...});
 *     }
 * });
 *
 *
 * // With a Backbone.Model
 * const model = new Model();
 * const itemCache = new Cache({
 *     load() {
 *        return model.fetch().then(() => {
 *             return model;
 *        );
 *     }
 * });
 *
 * // get cached value
 * const mostRecentPromise = itemCache.get();
 *
 * // force cached udpate
 * const freshPromise = itemCache.refresh();
 *
 */
const Cache = function (opts) {
  this._load = opts.load;
};

_.extend(Cache.prototype, {
  get(opts) {
    this._promise = this._promise || this.refresh(opts);
    return this._promise;
  },

  refresh(opts) {
    this._promise = this._load(opts);
    return this._promise;
  },
});

export default Cache;
