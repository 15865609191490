import { AnalyticsSpecs, PersonalizationWizardResponse } from '@packages/types/setup/personalization';

import fetchWrapper from '../fetchWrapper';

export function submitPersonalizationWizardForm(
  groupId: string,
  form: AnalyticsSpecs
): Promise<PersonalizationWizardResponse> {
  return fetchWrapper(`/${groupId}/setup/personalization`, {
    method: 'POST',
    body: JSON.stringify({ ...form }),
  }).then((resp) => resp.json());
}
