import { useEffect, useState } from 'react';

import styled from '@emotion/styled';
import Button from '@leafygreen-ui/button';
import Icon from '@leafygreen-ui/icon';
import { palette } from '@leafygreen-ui/palette';
import { RadioBox, RadioBoxGroup } from '@leafygreen-ui/radio-box-group';

import { StyledButton } from '@packages/types/leafygreen-emotion';

import { MFAConfiguration } from '@packages/components/CloudMFA/ManageMFA';

import { LargeViewport, MediumMinusViewport, mq } from 'js/common/utils/mediaQueries';

import Authenticator from './Authenticator';
import Phone from './Phone';
import { AccordionBodyTextContainer } from './styles/mfa';

const Subtitle = styled.div`
  color: ${palette.gray.dark2};
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 8px;
`;

const SelectContainer = styled.div(() =>
  mq({
    position: 'relative',
  })
);

const Select = styled.select(() =>
  mq({
    boxSizing: 'border-box',
    height: '48px',
    width: ['auto', 'calc(100vw - 124px)', 'calc(100vw - 530px)', 'auto'],
    border: '1px solid #DDDDDD',
    borderRadius: '2px',
    background: 'linear-gradient(180deg, #FFFFFF 0%, #F6F6F6 100%)',
    boxShadow: 'inset 0 -2px 0 0 #DDDDDD',
    fontSize: '15px',
    lineHeight: '19px',
    color: palette.gray.dark3,
    paddingLeft: '12px',
    webkitAppearance: 'none',
    mozAppearance: 'none',
    appearance: 'none',
  })
);

const SelectIcon = styled(Icon)(() =>
  mq({
    position: 'absolute',
    left: [0, 'calc(100vw - 150px)', 'calc(100vw - 560px)', 0],
    bottom: '15px',
  })
);

const AuthenticatorButton = styled<StyledButton>(Button)<React.ComponentProps<'button'>>({
  transition: 'border 150ms ease-in-out',
  boxSizing: 'content-box',
  padding: '15px',
  fontSize: '13px',
  fontWeight: 'bold',
  backgroundColor: palette.white,
  border: `1px solid ${palette.gray.light1}`,
  color: palette.gray.dark2,
  borderRadius: '3px',
  backgroundImage: 'none',
  boxShadow: 'none',
  height: '16px',

  '&::before': {
    backgroundColor: palette.white,
    backgroundImage: 'none',
    boxShadow: 'none',
  },
});

interface PrimaryProps {
  active?: boolean;
  hasMultiFactorAuth: boolean;
  username: string;
  currentMfa: MFAConfiguration;
  updateMfaData: Function;
  onAuthExpired: Function;
  phoneSupported?: boolean;
  centralUrl: string;
  onSuccess: Function;
}

type PrimaryTab = '' | 'google' | 'smsVoice';

export default function Primary({
  hasMultiFactorAuth,
  username,
  currentMfa,
  updateMfaData,
  onAuthExpired,
  phoneSupported = true,
  active = false,
  centralUrl = '',
  onSuccess,
}: PrimaryProps) {
  const [selectedTab, setSelectedTab] = useState<PrimaryTab>(phoneSupported ? '' : 'google');

  useEffect(() => {
    if (active) {
      if (currentMfa.authenticator) {
        setSelectedTab('google');
      } else if (currentMfa.voice || currentMfa.phone) {
        setSelectedTab('smsVoice');
      } else {
        setSelectedTab('');
      }
    } else {
      setSelectedTab('');
    }
  }, [active, currentMfa.authenticator, currentMfa.phone, currentMfa.voice]);

  const onSuccessHandler = () => {
    setSelectedTab('');
    onSuccess();
  };

  const isGoogleSelected = selectedTab === 'google';
  const isSmsVoiceSelected = selectedTab === 'smsVoice';

  const subtitle = hasMultiFactorAuth ? 'Edit 2FA method' : 'Enable a 2FA method';
  return (
    <AccordionBodyTextContainer>
      <Subtitle data-testid="primarySubtitle">{subtitle}</Subtitle>

      {phoneSupported ? (
        <>
          <MediumMinusViewport>
            <SelectContainer>
              <Select
                name="mfamethods"
                value={selectedTab}
                onChange={(e) => setSelectedTab(e.target.value as PrimaryTab)}
              >
                <option key="default" value="">
                  Select a 2FA method
                </option>
                <option key="google" value="google">
                  Google Authenticator
                </option>
                <option key="smsVoice" value="smsVoice">
                  Voice/SMS Number
                </option>
              </Select>
              <SelectIcon glyph="CaretDown" fill={palette.gray.base} />
            </SelectContainer>
          </MediumMinusViewport>

          <LargeViewport>
            <RadioBoxGroup
              size="full"
              onChange={(e) => setSelectedTab(e.target.value as PrimaryTab)}
              value={selectedTab}
              data-testid="primaryMethod"
            >
              <RadioBox name="google" value="google">
                Google Authenticator
              </RadioBox>
              <RadioBox name="smsVoice" value="smsVoice">
                Voice/SMS Number
              </RadioBox>
            </RadioBoxGroup>
          </LargeViewport>
        </>
      ) : (
        <AuthenticatorButton name="google" type="button" onClick={() => setSelectedTab('google')}>
          Google Authenticator
        </AuthenticatorButton>
      )}

      {isGoogleSelected && (
        <Authenticator
          currentMfa={currentMfa}
          username={username}
          updateMfaData={updateMfaData}
          onAuthExpired={onAuthExpired}
          onSuccess={onSuccessHandler}
          centralUrl={centralUrl}
          active
        />
      )}
      {isSmsVoiceSelected && (
        <Phone
          active
          updateMfaData={updateMfaData}
          onAuthExpired={onAuthExpired}
          onSuccess={onSuccessHandler}
          onCancel={() => setSelectedTab('')}
          centralUrl={centralUrl}
        />
      )}
    </AccordionBodyTextContainer>
  );
}
