import { useEffect, useState } from 'react';

import { css } from '@emotion/react';
import Banner from '@leafygreen-ui/banner';
import { Body } from '@leafygreen-ui/typography';

import {
  FactorType,
  OktaMfaAuthTransaction,
  OktaMfaFactorVerificationResponse,
  WebAuthnOktaMfaFactor,
} from '@packages/types/accountMultiFactorAuth';
import { Search } from '@packages/types/auth/search';

import * as api from 'js/common/services/api';
import { LoginRedirectResponse } from 'js/common/services/api/authApi';
// common styles
import { bodyParagraphStyles } from 'js/common/styles/multiFactorAuth';
import { binToStr, strToBin } from 'js/common/utils/multiFactorAuthUtils';

const errorBannerStyles = css({
  marginBottom: '14px',
  boxSizing: 'border-box',
});

export type VerifyWebAuthnFactorResponse =
  | OktaMfaAuthTransaction
  | OktaMfaFactorVerificationResponse
  | LoginRedirectResponse;

interface VerifyWebAuthnFactorProps {
  webAuthnFactor: WebAuthnOktaMfaFactor;
  clientState?: Search;
  onSuccess: (response: VerifyWebAuthnFactorResponse | string) => void;
  isDeleteFlow?: boolean;
  isEmailChangeFlow?: boolean;
  navigatorCredentials?: CredentialsContainer;
}
interface CredentialDataOptions {
  clientData: string;
  authenticatorData: string;
  signatureData: string;
}

export default function VerifyWebAuthnFactor({
  webAuthnFactor,
  clientState,
  onSuccess,
  isDeleteFlow,
  isEmailChangeFlow,
  navigatorCredentials = navigator.credentials,
}: VerifyWebAuthnFactorProps) {
  const [errorMessage, setErrorMessage] = useState('');
  const { stateToken } = clientState || {};
  const isAuthFlow = !!stateToken;

  const getCredentials = async (
    response: OktaMfaAuthTransaction<WebAuthnOktaMfaFactor> | OktaMfaFactorVerificationResponse
  ) => {
    const { credentialId } =
      (response as OktaMfaFactorVerificationResponse).profile ??
      (response as OktaMfaAuthTransaction<WebAuthnOktaMfaFactor>)._embedded!.factor.profile;

    const allowCredentials = [
      {
        type: 'public-key' as const,
        id: strToBin(credentialId),
      },
    ];

    const challengeOptions =
      (response as OktaMfaFactorVerificationResponse)._embedded?.challenge ??
      (response as OktaMfaAuthTransaction<WebAuthnOktaMfaFactor>)._embedded!.factor._embedded!.challenge!;
    const challengeStr = challengeOptions.challenge;

    const publicKeyOptions: PublicKeyCredentialRequestOptions = {
      ...challengeOptions,
      challenge: strToBin(challengeStr),
      allowCredentials,
    };

    try {
      const credential: Credential | null = await navigatorCredentials.get({
        publicKey: { ...publicKeyOptions },
      });

      const { clientDataJSON, authenticatorData, signature } = (credential as PublicKeyCredential)
        ?.response as AuthenticatorAssertionResponse;

      return {
        clientData: binToStr(clientDataJSON),
        authenticatorData: binToStr(authenticatorData),
        signatureData: binToStr(signature),
      } as CredentialDataOptions;
    } catch (error) {
      console.log('Something went wrong while verifying the credentials', error);
      setErrorMessage('A server error has occurred. Please try again in a moment.');
    }
  };

  const WEBAUTHN_FACTOR_PARAMS = {
    factorType: FactorType.WebAuthn,
    factorId: webAuthnFactor.id,
  };

  const getChallengeNonce = async () => {
    let challengeNonce;

    try {
      if (isAuthFlow) {
        challengeNonce = (await api.auth.verifyAuthMfa({
          ...WEBAUTHN_FACTOR_PARAMS,
          stateToken,
          clientState,
        })) as OktaMfaAuthTransaction<WebAuthnOktaMfaFactor>;
      } else {
        challengeNonce = await api.accountMultiFactorAuth.verifyFactor({ ...WEBAUTHN_FACTOR_PARAMS }, isDeleteFlow);
      }

      return await getCredentials(challengeNonce);
    } catch (err) {
      console.error('Something went wrong while getting the credentials', err);
      setErrorMessage('A server error has occurred. Please try again in a moment.');
    }
  };

  const verifyChallenge = async ({ clientData, authenticatorData, signatureData }: CredentialDataOptions) => {
    let verificationResponse;

    try {
      if (isEmailChangeFlow) {
        let { accountTemporaryAuthTokenId } = await api.accountProfile.getEmailChangeRequestSessionWithFactors({
          ...WEBAUTHN_FACTOR_PARAMS,
          clientData,
          authenticatorData,
          signatureData,
        });
        onSuccess(accountTemporaryAuthTokenId);
        return;
      } else if (isAuthFlow) {
        verificationResponse = await api.auth.verifyAuthMfa({
          ...WEBAUTHN_FACTOR_PARAMS,
          stateToken,
          clientState,
          clientData,
          authenticatorData,
          signatureData,
        });
      } else {
        verificationResponse = await api.accountMultiFactorAuth.verifyFactor(
          {
            ...WEBAUTHN_FACTOR_PARAMS,
            clientData,
            authenticatorData,
            signatureData,
          },
          isDeleteFlow
        );
      }
      onSuccess(verificationResponse);
    } catch (err) {
      console.error('Something went wrong when verifying the challenge', err);
      setErrorMessage('A server error has occurred. Please try again in a moment.');
    }
  };

  useEffect(() => {
    sendWebAuthnChallenge();
  }, []);

  const sendWebAuthnChallenge = async () => {
    try {
      await verifyChallenge((await getChallengeNonce()) as CredentialDataOptions);
    } catch (err) {
      console.log('Something went wrong when verifying the challenge response', err);
      setErrorMessage('A server error has occurred. Please try again in a moment.');
    }
  };

  return (
    <>
      {errorMessage && (
        <Banner variant="danger" data-testid="error-banner" css={errorBannerStyles}>
          {errorMessage}
        </Banner>
      )}
      <Body css={bodyParagraphStyles}>Please follow instructions in your browser.</Body>
    </>
  );
}
