import _ from 'underscore';

import fetchWrapper from '../fetchWrapper';

export default {
  getRoles(groupId) {
    return fetchWrapper(`/nds/${groupId}/customDBRoles/roles`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  createRole(groupId, role) {
    return fetchWrapper(`/nds/${groupId}/customDBRoles/roles`, {
      method: 'POST',
      body: JSON.stringify(role),
    }).then((resp) => resp.json());
  },

  updateRole(groupId, role) {
    return fetchWrapper(`/nds/${groupId}/customDBRoles/roles/${encodeURIComponent(role.roleName)}`, {
      method: 'PATCH',
      body: JSON.stringify(_.omit(role, 'roleName')),
    }).then((resp) => resp.json());
  },

  deleteRole(groupId, roleName) {
    return fetchWrapper(`/nds/${groupId}/customDBRoles/roles/${encodeURIComponent(roleName)}`, {
      method: 'DELETE',
    });
  },

  getBuiltInRoles(groupId) {
    return fetchWrapper(`/nds/${groupId}/customDBRoles/builtIn`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },
};
