import fetchWrapper from 'js/common/services/api/fetchWrapper';

export default {
  getClusterTransactionByName(groupId, clusterName) {
    return fetchWrapper(`/nds/clustertransactions/${groupId}/name/${clusterName}`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getClusterTransactionByNonEdit(groupId) {
    return fetchWrapper(`/nds/clustertransactions/${groupId}/nonEdit`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  putClusterTransaction(groupId, clusterTransactionJson) {
    // TODO: CLOUDP-128236 on success of cart resurrection experiment, we should use the clusterTransaction type here
    return fetchWrapper(`/nds/clustertransactions/${groupId}`, {
      method: 'PUT',
      body: JSON.stringify(clusterTransactionJson),
    }).then((resp) => resp.json());
  },

  deleteClusterTransaction(groupId: string, clusterName: string, isEdit: boolean) {
    return fetchWrapper(`/nds/clustertransactions/${groupId}/name/${clusterName}?isEdit=${isEdit}`, {
      method: 'DELETE',
    });
  },
};
