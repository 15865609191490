import moment from 'moment';
import { Dispatch } from 'redux';

import { PolicyItem } from '@packages/types/nds/backup/jobTypes';
import { IngestionPipeline, IngestionPipelineRun, IngestionPipelineState } from '@packages/types/nds/pipelines';

import { getActiveGroupId } from '@packages/redux/modules/app';

import {
  createIngestionPipeline,
  createOnDemandPipelineRun,
  deleteIngestionPipeline,
  deleteIngestionPipelineRunDataSet,
  fetchBackupPolicyItemsForCluster,
  fetchBackupPolicyItemsForGroup,
  fetchIngestionPipelineRun,
  fetchIngestionPipelineRuns,
  fetchIngestionPipelines,
  patchIngestionPipeline,
  RunQueryParams,
} from 'js/common/services/api/nds/ingestionPipelinesApi';

import { groupNamespacesByDbName } from 'js/project/nds/pipelines/pipelineUtils';

interface State {
  nds: {
    dls: DLSState;
  };
}

export interface DLSState {
  ingestionPipelines: Array<IngestionPipeline>;
  ingestionPipelineRuns: Record<string, Array<IngestionPipelineRun>>;
  // mapping of clusterName to databaseName to collectionName represented in the following schema
  // { clusterName: { database0: [collection0, collection1, ...], ... }, ... }
  clusterNamespaceMap: Record<string, Record<string, Array<string>>>;
  groupBackupPolicyItems: Record<string, Array<PolicyItem>>;
  clusterBackupPolicyItems: Array<PolicyItem>;
}

enum DLSActionTypes {
  SET_PIPELINES = 'nds/dls/setPipelines',
  ADD_PIPELINE = 'nds/dls/addPipeline',
  REMOVE_PIPELINE = 'nds/dls/removePipeline',
  UPDATE_PIPELINE = 'nds/dls/updatePipeline',
  EXTEND_PIPELINE = 'nds/dls/extendPipeline',
  ADD_NAMESPACES = 'nds/dls/addNamespaces',
  PREPEND_PIPELINE_RUN = 'nds/dls/prependPipelineRun',
  ADD_PIPELINE_RUNS = 'nds/dls/addPipelineRuns',
  SET_PIPELINE_RUNS = 'nds/dls/setPipelineRuns',
  SET_PIPELINE_RUN = 'nds/dls/setPipelineRun',
  UPDATE_PIPELINE_RUN = 'nds/dls/updatePipelineRun',
  FETCH_GROUP_POLICY_ITEMS = 'nds/dls/fetchPolicyItems',
  FETCH_CLUSTER_POLICY_ITEMS = 'nds/dls/fetchClusterPolicyItems',
}

const DLSInitialState: DLSState = {
  ingestionPipelines: [],
  ingestionPipelineRuns: {},
  clusterNamespaceMap: {},
  groupBackupPolicyItems: {},
  clusterBackupPolicyItems: [],
};

interface DLSAction {
  type: DLSActionTypes;
  payload:
    | Array<IngestionPipeline>
    | IngestionPipeline
    | IngestionPipelineRun
    | AddNamespacePayload
    | AddIngestionPipelineRunsPayload
    | SetIngestionPipelineRunPayload
    | Array<PolicyItem>
    | Record<string, Array<PolicyItem>>;
}

interface AddNamespacePayload {
  clusterName: string;
  namespaces: Record<string, Array<string>>;
}

interface AddIngestionPipelineRunPayload {
  pipelineId: string;
  pipelineRun: IngestionPipelineRun;
}

interface AddIngestionPipelineRunsPayload {
  pipelineId: string;
  pipelineRuns: Array<IngestionPipelineRun>;
}

interface SetIngestionPipelineRunsPayload {
  pipelineId: string;
  pipelineRuns: Array<IngestionPipelineRun>;
}

interface SetIngestionPipelineRunPayload {
  pipelineId: string;
  pipelineRun: IngestionPipelineRun;
}

export default function dlsReducer(state: DLSState = DLSInitialState, action: DLSAction): DLSState {
  const { type, payload } = action;
  switch (type) {
    case DLSActionTypes.SET_PIPELINES: {
      return {
        ...state,
        ingestionPipelines: payload as Array<IngestionPipeline>,
      };
    }
    case DLSActionTypes.ADD_PIPELINE: {
      const ingestionPipeline = payload as IngestionPipeline;
      const ingestionPipelines = [...state.ingestionPipelines, ingestionPipeline];
      return {
        ...state,
        ingestionPipelines,
      };
    }
    case DLSActionTypes.REMOVE_PIPELINE: {
      const ingestionPipeline = payload as IngestionPipeline;
      const ingestionPipelines = state.ingestionPipelines.filter((pipeline) => pipeline._id !== ingestionPipeline._id);
      return {
        ...state,
        ingestionPipelines,
      };
    }
    case DLSActionTypes.ADD_NAMESPACES: {
      const { clusterName, namespaces } = payload as AddNamespacePayload;
      return {
        ...state,
        clusterNamespaceMap: {
          ...state.clusterNamespaceMap,
          [clusterName]: namespaces,
        },
      };
    }
    case DLSActionTypes.UPDATE_PIPELINE: {
      const updatedPipeline = payload as IngestionPipeline;
      const ingestionPipelines = state.ingestionPipelines.map((pipeline) =>
        updatedPipeline._id === pipeline._id ? updatedPipeline : pipeline
      );
      return {
        ...state,
        ingestionPipelines,
      };
    }
    case DLSActionTypes.PREPEND_PIPELINE_RUN: {
      const { pipelineId, pipelineRun } = payload as AddIngestionPipelineRunPayload;
      const { ingestionPipelineRuns } = state;
      // add new run to existing runs with the same pipeline ID
      return {
        ...state,
        ingestionPipelineRuns: {
          ...ingestionPipelineRuns,
          [pipelineId]: [pipelineRun, ...(ingestionPipelineRuns[pipelineId] || [])],
        },
      };
    }

    case DLSActionTypes.ADD_PIPELINE_RUNS: {
      const { pipelineId, pipelineRuns } = payload as AddIngestionPipelineRunsPayload;
      const { ingestionPipelineRuns } = state;
      const currentPipelineRuns = ingestionPipelineRuns[pipelineId] ?? [];
      // replace any existing runs with the same pipeline ID
      return {
        ...state,
        ingestionPipelineRuns: {
          ...ingestionPipelineRuns,
          [pipelineId]: [...currentPipelineRuns, ...pipelineRuns],
        },
      };
    }
    case DLSActionTypes.SET_PIPELINE_RUNS: {
      const { pipelineId, pipelineRuns } = payload as SetIngestionPipelineRunsPayload;
      const { ingestionPipelineRuns } = state;
      return {
        ...state,
        ingestionPipelineRuns: {
          ...ingestionPipelineRuns,
          [pipelineId]: pipelineRuns,
        },
      };
    }
    case DLSActionTypes.SET_PIPELINE_RUN: {
      const { pipelineId, pipelineRun } = payload as SetIngestionPipelineRunPayload;
      const { ingestionPipelineRuns } = state;

      return {
        ...state,
        ingestionPipelineRuns: {
          ...ingestionPipelineRuns,
          [pipelineId]: (ingestionPipelineRuns[pipelineId] || []).map((run) =>
            run._id === pipelineRun._id ? pipelineRun : run
          ),
        },
      };
    }
    case DLSActionTypes.UPDATE_PIPELINE_RUN: {
      const updated = payload as IngestionPipelineRun;
      const { ingestionPipelineRuns } = state;
      return {
        ...state,
        ingestionPipelineRuns: {
          ...ingestionPipelineRuns,
          [updated.pipelineId]: ingestionPipelineRuns[updated.pipelineId].map((run) =>
            run._id === updated._id ? updated : run
          ),
        },
      };
    }
    case DLSActionTypes.FETCH_GROUP_POLICY_ITEMS: {
      return {
        ...state,
        groupBackupPolicyItems: payload as Record<string, Array<PolicyItem>>,
      };
    }
    case DLSActionTypes.FETCH_CLUSTER_POLICY_ITEMS: {
      return {
        ...state,
        clusterBackupPolicyItems: payload as Array<PolicyItem>,
      };
    }
    default:
      return state;
  }
}

// selectors
export const selectDLS = (state: State): DLSState => state.nds.dls;

// action creators - sync
const setIngestionPipelines = (ingestionPipelines: Array<IngestionPipeline>) => ({
  type: DLSActionTypes.SET_PIPELINES,
  payload: ingestionPipelines,
});

export const addIngestionPipeline = (ingestionPipeline: IngestionPipeline) => ({
  type: DLSActionTypes.ADD_PIPELINE,
  payload: ingestionPipeline,
});

export const removeIngestionPipeline = (ingestionPipeline: IngestionPipeline) => ({
  type: DLSActionTypes.REMOVE_PIPELINE,
  payload: ingestionPipeline,
});

export const updateIngestionPipeline = (ingestionPipeline: IngestionPipeline) => ({
  type: DLSActionTypes.UPDATE_PIPELINE,
  payload: ingestionPipeline,
});

export const extendIngestionPipeline = (ingestionPipeline: IngestionPipeline, hours: number) => ({
  type: DLSActionTypes.EXTEND_PIPELINE,
  payload: { ingestionPipeline, hours },
});

export const addNameSpacesForClusterName = (clusterName: string, namespaces: Array<string>): DLSAction => {
  const groupedByDbName = groupNamespacesByDbName(namespaces);
  return {
    type: DLSActionTypes.ADD_NAMESPACES,
    payload: {
      clusterName,
      namespaces: groupedByDbName,
    },
  };
};

export const setIngestionPipelineRun = (pipelineId: string, pipelineRun: IngestionPipelineRun) => ({
  type: DLSActionTypes.SET_PIPELINE_RUN,
  payload: { pipelineId, pipelineRun },
});

export const prependIngestionPipelineRun = (pipelineId: string, pipelineRun: IngestionPipelineRun) => ({
  type: DLSActionTypes.PREPEND_PIPELINE_RUN,
  payload: { pipelineId, pipelineRun },
});

export const addIngestionPipelineRuns = (pipelineId: string, pipelineRuns: Array<IngestionPipelineRun>) => ({
  type: DLSActionTypes.ADD_PIPELINE_RUNS,
  payload: { pipelineId, pipelineRuns },
});

export const setIngestionPipelineRuns = (pipelineId: string, pipelineRuns: Array<IngestionPipelineRun>) => ({
  type: DLSActionTypes.SET_PIPELINE_RUNS,
  payload: { pipelineId, pipelineRuns },
});

export const updateIngestionPipelineRun = (pipelineRun: IngestionPipelineRun) => ({
  type: DLSActionTypes.UPDATE_PIPELINE_RUN,
  payload: pipelineRun,
});

const setGroupBackupPolicyItems = (groupBackupPolicyItems: Record<string, Array<PolicyItem>>) => ({
  type: DLSActionTypes.FETCH_GROUP_POLICY_ITEMS,
  payload: groupBackupPolicyItems,
});

const setClusterBackupPolicyItems = (clusterBackupPolicyItems: Array<PolicyItem>) => ({
  type: DLSActionTypes.FETCH_CLUSTER_POLICY_ITEMS,
  payload: clusterBackupPolicyItems,
});

// action creators - async
export const retrieveIngestionPipelines = (groupId: string) => (dispatch: Dispatch) => {
  return fetchIngestionPipelines(groupId).then((pipelines) => {
    dispatch(setIngestionPipelines(pipelines));
    return pipelines;
  });
};

export const retrieveIngestionPipelinesWithDatasets = (groupId: string) => (dispatch: Dispatch) => {
  return fetchIngestionPipelines(groupId)
    .then((pipelines) => {
      dispatch(setIngestionPipelines(pipelines));
      return pipelines;
    })
    .then((pipelines) =>
      Promise.all(
        pipelines.map((pipeline) =>
          dispatch(retrieveIngestionPipelineRuns(pipeline, { withDatasets: true })).then((pipelineRuns) => {
            return {
              pipeline,
              pipelineRuns,
            };
          })
        )
      )
    );
};

export const saveIngestionPipeline =
  (ingestionPipeline: IngestionPipeline) =>
  (dispatch, getState): Promise<IngestionPipeline> => {
    const groupId = getActiveGroupId(getState());
    if (ingestionPipeline._id) {
      return patchIngestionPipeline(groupId, ingestionPipeline).then((updatedPipeline) => {
        dispatch(updateIngestionPipeline(updatedPipeline));
        return updatedPipeline;
      });
    }
    return createIngestionPipeline(groupId, ingestionPipeline).then((createdPipeline) => {
      dispatch(addIngestionPipeline(createdPipeline));
      return createdPipeline;
    });
  };

export const triggerPipeline =
  (pipeline: IngestionPipeline, snapshotId: string) =>
  (dispatch): Promise<IngestionPipeline> =>
    createOnDemandPipelineRun(pipeline.groupId, pipeline.name, snapshotId).then((createdRun) => {
      if (!pipeline._id) {
        throw new Error('Malformed Data Lake Pipeline missing _id');
      }
      dispatch(prependIngestionPipelineRun(pipeline._id, createdRun));
      return pipeline;
    });

const updateIngestionPipelineStateAndUpdateStore = (
  groupId: string,
  pipeline: IngestionPipeline,
  state: IngestionPipelineState,
  dispatch: Dispatch
) => {
  const newPipeline = {
    ...pipeline,
    state,
  };
  return patchIngestionPipeline(groupId, newPipeline).then((updatedPipeline) => {
    dispatch(updateIngestionPipeline(updatedPipeline));
    return updatedPipeline;
  });
};

export const pausePipeline =
  (pipeline: IngestionPipeline) =>
  (dispatch, getState): Promise<IngestionPipeline> =>
    updateIngestionPipelineStateAndUpdateStore(
      getActiveGroupId(getState()),
      pipeline,
      IngestionPipelineState.PAUSED,
      dispatch
    );

export const resumePipeline =
  (pipeline: IngestionPipeline) =>
  (dispatch, getState): Promise<IngestionPipeline> =>
    updateIngestionPipelineStateAndUpdateStore(
      getActiveGroupId(getState()),
      pipeline,
      IngestionPipelineState.ACTIVE,
      dispatch
    );

// Remove updatePipelineBackupPolicy in https://jira.mongodb.org/browse/CLOUDP-128120
export const updatePipelineBackupPolicy =
  (pipeline: IngestionPipeline, policyItemId: string) =>
  (dispatch, getState): Promise<IngestionPipeline> => {
    const groupId = getActiveGroupId(getState());

    const newPipeline = {
      ...pipeline,
      source: {
        ...pipeline.source,
        policyItemId,
      },
    };

    return patchIngestionPipeline(groupId, newPipeline).then((updatedPipeline) => {
      dispatch(updateIngestionPipeline(updatedPipeline));
      return updatedPipeline;
    });
  };

export const updatePipeline =
  (newPipeline: IngestionPipeline) =>
  (dispatch, getState): Promise<IngestionPipeline> =>
    patchIngestionPipeline(getActiveGroupId(getState()), newPipeline).then((pipeline) => {
      dispatch(updateIngestionPipeline(pipeline));
      return pipeline;
    });

export const deletePipeline =
  (pipeline: IngestionPipeline) =>
  (dispatch, getState): Promise<IngestionPipeline> =>
    deleteIngestionPipeline(getActiveGroupId(getState()), pipeline.name).then(() => {
      dispatch(removeIngestionPipeline(pipeline));
      return pipeline;
    });

export const extendPipeline =
  (pipeline: IngestionPipeline, hours: number) =>
  (dispatch, getState): Promise<IngestionPipeline> => {
    const { deleteAfterDate } = pipeline;
    const newPipeline = {
      ...pipeline,
      deleteAfterDate: moment(deleteAfterDate).add(hours, 'hours').toDate(),
    };
    return patchIngestionPipeline(getActiveGroupId(getState()), newPipeline).then((updatedPipeline) => {
      dispatch(updateIngestionPipeline(updatedPipeline));
      return updatedPipeline;
    });
  };

export const appendIngestionPipelineRuns =
  (pipeline: IngestionPipeline, createdBefore?: string) =>
  (dispatch: Dispatch): Promise<Array<IngestionPipelineRun>> =>
    fetchIngestionPipelineRuns(pipeline, { createdBefore }).then((pipelineRuns) => {
      if (!pipeline._id) {
        throw new Error('Malformed Data Lake Pipeline missing _id');
      }
      dispatch(addIngestionPipelineRuns(pipeline._id, pipelineRuns));
      return pipelineRuns;
    });

export const retrieveIngestionPipelineRuns =
  (pipeline: IngestionPipeline, params?: Partial<RunQueryParams>) =>
  (dispatch: Dispatch): Promise<Array<IngestionPipelineRun>> =>
    fetchIngestionPipelineRuns(pipeline, params).then((pipelineRuns) => {
      if (!pipeline._id) {
        throw new Error('Malformed Data Lake Pipeline missing _id');
      }
      dispatch(setIngestionPipelineRuns(pipeline._id, pipelineRuns));
      return pipelineRuns;
    });

export const retrieveIngestionPipelineRun =
  (pipeline: IngestionPipeline, pipelineRun: IngestionPipelineRun) =>
  (dispatch: Dispatch): Promise<IngestionPipelineRun> =>
    fetchIngestionPipelineRun(pipeline.groupId, pipeline.name, pipelineRun._id).then((run) => {
      if (!pipeline._id) {
        throw new Error('Malformed Data Lake Pipeline missing _id');
      }
      dispatch(setIngestionPipelineRun(pipeline._id, run));
      return pipelineRun;
    });

export const deleteIngestionPipelineRun =
  (pipeline: IngestionPipeline, pipelineRun: IngestionPipelineRun) =>
  (dispatch: Dispatch): Promise<IngestionPipelineRun> =>
    deleteIngestionPipelineRunDataSet(pipeline.groupId, pipeline.name, pipelineRun._id).then((deleted) => {
      // update run state
      dispatch(updateIngestionPipelineRun(deleted));
      return deleted;
    });

// allow failure to return empty policy
export const retrieveBackupPolicyItemsForGroup =
  (groupId: string) =>
  (dispatch): Promise<Record<string, Array<PolicyItem>>> => {
    return fetchBackupPolicyItemsForGroup(groupId)
      .then((policyItemMap) => {
        dispatch(setGroupBackupPolicyItems(policyItemMap));
        return policyItemMap;
      })
      .catch((e) => {
        console.error(e);
        return {};
      });
  };

// allow failure to return empty policy
export const retrieveBackupPolicyItemsForCluster =
  (clusterName: string) =>
  (dispatch, getState): Promise<Array<PolicyItem>> => {
    const groupId = getActiveGroupId(getState());
    return fetchBackupPolicyItemsForCluster(groupId, clusterName)
      .then((policyItems) => {
        dispatch(setClusterBackupPolicyItems(policyItems));
        return policyItems;
      })
      .catch((e) => {
        console.error(e);
        return [];
      });
  };
