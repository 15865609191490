import Backbone from 'backbone';

import { DataLakeTenant } from '@packages/types/nds/dataLakes';

import {
  getDatabaseNames as getDatabasesForDataLakeTenant,
  getTenantDisplayName as getDataLakeTenantDisplayName,
} from 'js/project/nds/dataLakes/util/DataLakeUtils';

import BIConnectorSettings from './BIConnectorSettings';

const TYPES = {
  DATA_LAKE_TENANT: 'DATA_LAKE_TENANT',
  CLUSTER_DESCRIPTION: 'CLUSTER_DESCRIPTION',
};

export const CONNECTABLES = {
  CLUSTER: 'cluster',
  DATA_FEDERATION: 'data federation',
};

const FIELD_DEFS = {
  TYPE: 'type',
  NAME: 'name',
  UNIQUE_ID: 'uniqueId',
  CONNECTABLE_NAME: 'connectableName',
  IS_TENANT: 'isTenant',
  SRV_ADDRESS: 'srvAddress',
  PRIVATE_LINK_SRV_ADDRESSES: 'privateLinkSrvAddresses',
  DATA_LAKE_PRIVATE_LINK_HOSTNAME_PORT_SHELL: 'dataLakePrivateLinkHostnamePortShell',
  DATA_LAKE_TENANT_DATABASES: 'dataLakeTenantDatabases',
  DATA_LAKE_TENANT_ID: 'dataLakeTenantId',
  DATA_LAKE_TENANT_TYPE: 'dataLakeTenantType',
  PRIVATE_SRV_ADDRESS: 'privateSrvAddress',
  HOSTNAME_PORT_SHELL: 'hostnamePortShell',
  PRIVATE_LINK_HOSTNAME_PORT_SHELL: 'privateLinkHostnamePortShell',
  PRIVATE_HOSTNAME_PORT_SHELL: 'privateHostnamePortShell',
  HOSTNAME_PORT_DRIVER: 'hostnamePortDriver',
  PRIVATE_HOSTNAME_PORT_DRIVER: 'privateHostnamePortDriver',
  PRIVATE_LINK_HOSTNAME_PORT_DRIVER: 'privateLinkHostnamePortDriver',
  PRIVATE_LINK_ENDPOINT_TO_LOAD_BALANCED_SRV_URIS: 'privateLinkEndpointToLoadBalancedSRVURIs',
  REPLICA_SET_NAME: 'replicaSetName',
  SUPPORTS_RETRY_WRITE: 'supportsRetryWrite',
  HAS_ANALYTICS_NODES: 'hasAnalyticsNodes',
  BI_CONNECTOR: 'BIConnector',
  COMMAND_LINE_TOOLS_URL: 'commandLineToolsUrl',
  CONNECTABLE_SUPPORTS_SRV: 'connectableSupportsSRV',
  CONNECTABLE_SUPPORTS_IAM_AUTH: 'connectableSupportsIAMAuth',
  WRITE_CONCERN: 'writeConcern',
  MONGODB_MAJOR_VERSION: 'mongoDBMajorVersion',
  API_VERSION: 'apiVersion',
  DATA_EXPLORER_ROUTE: 'dataExplorerRoute',
} as const;

const AtlasConnectOptions = Backbone.Model.extend(
  {
    defaults: () => ({
      [FIELD_DEFS.TYPE]: TYPES.CLUSTER_DESCRIPTION,
      [FIELD_DEFS.NAME]: '',
      [FIELD_DEFS.CONNECTABLE_NAME]: CONNECTABLES.CLUSTER,
      [FIELD_DEFS.IS_TENANT]: false,
      [FIELD_DEFS.SRV_ADDRESS]: '',
      [FIELD_DEFS.PRIVATE_LINK_SRV_ADDRESSES]: '',
      [FIELD_DEFS.PRIVATE_SRV_ADDRESS]: '',
      [FIELD_DEFS.HOSTNAME_PORT_SHELL]: '',
      [FIELD_DEFS.PRIVATE_LINK_HOSTNAME_PORT_SHELL]: '',
      [FIELD_DEFS.PRIVATE_HOSTNAME_PORT_SHELL]: '',
      [FIELD_DEFS.HOSTNAME_PORT_DRIVER]: '',
      [FIELD_DEFS.PRIVATE_HOSTNAME_PORT_DRIVER]: '',
      [FIELD_DEFS.PRIVATE_LINK_HOSTNAME_PORT_DRIVER]: '',
      [FIELD_DEFS.REPLICA_SET_NAME]: null,
      [FIELD_DEFS.SUPPORTS_RETRY_WRITE]: false,
      [FIELD_DEFS.HAS_ANALYTICS_NODES]: false,
      [FIELD_DEFS.BI_CONNECTOR]: new BIConnectorSettings(),
      [FIELD_DEFS.COMMAND_LINE_TOOLS_URL]: '',
      [FIELD_DEFS.CONNECTABLE_SUPPORTS_SRV]: true,
      [FIELD_DEFS.CONNECTABLE_SUPPORTS_IAM_AUTH]: false,
      [FIELD_DEFS.WRITE_CONCERN]: 'majority',
      [FIELD_DEFS.MONGODB_MAJOR_VERSION]: '',
      [FIELD_DEFS.API_VERSION]: 0,
      [FIELD_DEFS.DATA_LAKE_PRIVATE_LINK_HOSTNAME_PORT_SHELL]: '',
      [FIELD_DEFS.DATA_LAKE_TENANT_DATABASES]: [],
      [FIELD_DEFS.DATA_LAKE_TENANT_ID]: null,
      [FIELD_DEFS.DATA_LAKE_TENANT_TYPE]: '',
      [FIELD_DEFS.DATA_EXPLORER_ROUTE]: null,
    }),
    getType() {
      return this.get(FIELD_DEFS.TYPE);
    },
    getName() {
      return this.get(FIELD_DEFS.NAME);
    },
    getUniqueId() {
      return this.get(FIELD_DEFS.UNIQUE_ID);
    },
    getMongoDBMajorVersion() {
      return this.get(FIELD_DEFS.MONGODB_MAJOR_VERSION);
    },
    getConnectableName() {
      return this.get(FIELD_DEFS.CONNECTABLE_NAME);
    },
    getSRVAddress() {
      return this.get(FIELD_DEFS.SRV_ADDRESS);
    },
    getPrivateSRVAddress() {
      return this.get(FIELD_DEFS.PRIVATE_SRV_ADDRESS);
    },
    getPrivateLinkSRVAddress(selectedHorizon) {
      const allAddresses = this.get(FIELD_DEFS.PRIVATE_LINK_SRV_ADDRESSES);

      if (allAddresses && Object.keys(allAddresses).length > 0) {
        return allAddresses[selectedHorizon];
      }
      return '';
    },
    getDataLakePrivateLinkHostnamePortShell() {
      return this.get(FIELD_DEFS.DATA_LAKE_PRIVATE_LINK_HOSTNAME_PORT_SHELL);
    },
    getDataLakeTenantDatabases() {
      return this.get(FIELD_DEFS.DATA_LAKE_TENANT_DATABASES);
    },
    setDataLakeTenantDatabases(databases) {
      this.set(FIELD_DEFS.DATA_LAKE_TENANT_DATABASES, databases);
    },
    getDataLakeTenantId() {
      return this.get(FIELD_DEFS.DATA_LAKE_TENANT_ID);
    },
    getDataLakeTenantType() {
      return this.get(FIELD_DEFS.DATA_LAKE_TENANT_TYPE);
    },
    getHostNamePortShell() {
      return this.get(FIELD_DEFS.HOSTNAME_PORT_SHELL);
    },
    getPrivateHostnamePortShell() {
      return this.get(FIELD_DEFS.PRIVATE_HOSTNAME_PORT_SHELL);
    },
    getPrivateLinkConnectHostnamePortShell(selectedHorizon) {
      const allStrings = this.get(FIELD_DEFS.PRIVATE_LINK_HOSTNAME_PORT_SHELL);
      if (allStrings && Object.keys(allStrings).length > 0) {
        return allStrings[selectedHorizon];
      }
      return '';
    },
    getHostNamePortDriver() {
      return this.get(FIELD_DEFS.HOSTNAME_PORT_DRIVER);
    },
    getPrivateConnectHostnamePortDriver() {
      return this.get(FIELD_DEFS.PRIVATE_HOSTNAME_PORT_DRIVER);
    },
    getPrivateLinkConnectHostnamePortDriver(selectedHorizon) {
      const allStrings = this.get(FIELD_DEFS.PRIVATE_LINK_HOSTNAME_PORT_DRIVER);
      if (allStrings && Object.keys(allStrings).length > 0) {
        return allStrings[selectedHorizon];
      }
      return '';
    },
    getPrivateLinkConnectOptimizedSRVHostname(selectedHorizon) {
      const allStrings = this.get(FIELD_DEFS.PRIVATE_LINK_ENDPOINT_TO_LOAD_BALANCED_SRV_URIS);
      if (allStrings && Object.keys(allStrings).length > 0) {
        return allStrings[selectedHorizon];
      }
      return '';
    },
    getReplicaSetName() {
      return this.get(FIELD_DEFS.REPLICA_SET_NAME);
    },
    getSupportsRetryWrite() {
      return this.get(FIELD_DEFS.SUPPORTS_RETRY_WRITE);
    },
    getHasAnalyticsNodes() {
      return this.get(FIELD_DEFS.HAS_ANALYTICS_NODES);
    },
    getBIConnector() {
      return this.get(FIELD_DEFS.BI_CONNECTOR);
    },
    getCommandLineToolsUrl() {
      return this.get(FIELD_DEFS.COMMAND_LINE_TOOLS_URL);
    },
    getConnectableSupportsSRV() {
      return this.get(FIELD_DEFS.CONNECTABLE_SUPPORTS_SRV);
    },
    getConnectableSupportsIAMAuth() {
      return this.get(FIELD_DEFS.CONNECTABLE_SUPPORTS_IAM_AUTH);
    },
    getWriteConcern() {
      return this.get(FIELD_DEFS.WRITE_CONCERN);
    },
    isTenant() {
      return this.isTenantCluster() || this.isDataLakeTenant();
    },
    isTenantCluster() {
      return this.get(FIELD_DEFS.TYPE) === TYPES.CLUSTER_DESCRIPTION && this.get(FIELD_DEFS.IS_TENANT);
    },
    isDataLakeTenant() {
      return this.get(FIELD_DEFS.TYPE) === TYPES.DATA_LAKE_TENANT;
    },
    getApiVersion() {
      return this.get(FIELD_DEFS.API_VERSION);
    },
    getDataExplorerRoute() {
      // NOTE: This is not guaranteed to be defined, unless `updateFromDeploymentItem` is called.
      // This can't be pulled from the cluster description for non-serverless clusters because it includes the metrics clusterId
      // therefore, we must call updateFromDeploymentItem in order to populate it.
      return this.get(FIELD_DEFS.DATA_EXPLORER_ROUTE);
    },
    updateFromDeploymentItem(deploymentItem) {
      this.set(FIELD_DEFS.DATA_EXPLORER_ROUTE, `${deploymentItem.getRootMetricsRoute()}/explorer`);
    },
    updateFromClusterDescription(clusterDescription) {
      const hasSrvConnectionString =
        !!clusterDescription.get('srvAddress') && clusterDescription.get('srvAddress').length > 0;

      this.set({
        [FIELD_DEFS.TYPE]: TYPES.CLUSTER_DESCRIPTION,
        [FIELD_DEFS.NAME]: clusterDescription.getName(),
        [FIELD_DEFS.UNIQUE_ID]: clusterDescription.getUniqueId(),
        [FIELD_DEFS.IS_TENANT]: clusterDescription.isTenant(),
        [FIELD_DEFS.SRV_ADDRESS]: clusterDescription.get('srvAddress'),
        [FIELD_DEFS.PRIVATE_LINK_SRV_ADDRESSES]: clusterDescription.getPrivateLinkSRVAddresses(),
        [FIELD_DEFS.PRIVATE_LINK_ENDPOINT_TO_LOAD_BALANCED_SRV_URIS]:
          clusterDescription.getEndpointToLoadBalancedSRVConnectionURI(),
        [FIELD_DEFS.PRIVATE_SRV_ADDRESS]: clusterDescription.getPrivateSRVAddress(),
        [FIELD_DEFS.HOSTNAME_PORT_SHELL]: clusterDescription.getConnectHostnamePortShell(),
        [FIELD_DEFS.PRIVATE_HOSTNAME_PORT_SHELL]: clusterDescription.getPrivateConnectHostnamePortShell(),
        [FIELD_DEFS.PRIVATE_LINK_HOSTNAME_PORT_SHELL]: clusterDescription.getPrivateLinkConnectHostnamePortShell(),
        [FIELD_DEFS.HOSTNAME_PORT_DRIVER]: clusterDescription.getConnectHostnamePortDriver(),
        [FIELD_DEFS.PRIVATE_HOSTNAME_PORT_DRIVER]: clusterDescription.getPrivateConnectHostnamePortDriver(),
        [FIELD_DEFS.PRIVATE_LINK_HOSTNAME_PORT_DRIVER]: clusterDescription.getPrivateLinkConnectHostnamePortDriver(),
        [FIELD_DEFS.REPLICA_SET_NAME]: clusterDescription.isShardedCluster()
          ? null
          : clusterDescription.getDeploymentItemName(),
        [FIELD_DEFS.SUPPORTS_RETRY_WRITE]: clusterDescription.supportsRetryWrite(),
        [FIELD_DEFS.CONNECTABLE_SUPPORTS_IAM_AUTH]: clusterDescription.supportsIAMAuth(),
        [FIELD_DEFS.HAS_ANALYTICS_NODES]: clusterDescription.hasAnalyticsNodes(),
        [FIELD_DEFS.BI_CONNECTOR]: clusterDescription.getBIConnector(),
        [FIELD_DEFS.COMMAND_LINE_TOOLS_URL]: clusterDescription.getCommandLineToolsUrl(),
        [FIELD_DEFS.MONGODB_MAJOR_VERSION]: clusterDescription.getMongoDBMajorVersion(),
        [FIELD_DEFS.CONNECTABLE_SUPPORTS_SRV]:
          clusterDescription.hostnameSubdomainLevelSupportsSrv() && hasSrvConnectionString,
        // TODO(etsai) - we'll need something a bit more robust for apiVersion in the future, not sure when this gets incremented
        [FIELD_DEFS.API_VERSION]: clusterDescription.getMongoDBMajorVersion() >= 4.9 ? 1 : 0,
        // Data explorer route is filled in from the deployment item for non-serverless
        [FIELD_DEFS.DATA_EXPLORER_ROUTE]: clusterDescription.isServerless()
          ? `#/serverless/explorer/${clusterDescription.getName()}`
          : null,
      });
    },
  },
  {
    fromDataLakeTenant(dataLakeTenant: DataLakeTenant) {
      const { hostnames, privateLinkHostname } = dataLakeTenant;
      const hostname = (hostnames && hostnames[0]) || '';
      const databases = getDatabasesForDataLakeTenant(dataLakeTenant);
      return new AtlasConnectOptions({
        [FIELD_DEFS.TYPE]: TYPES.DATA_LAKE_TENANT,
        [FIELD_DEFS.NAME]: getDataLakeTenantDisplayName(dataLakeTenant),
        [FIELD_DEFS.IS_TENANT]: true,
        [FIELD_DEFS.SRV_ADDRESS]: hostname,
        [FIELD_DEFS.HOSTNAME_PORT_SHELL]: hostname,
        [FIELD_DEFS.HOSTNAME_PORT_DRIVER]: hostname,
        [FIELD_DEFS.DATA_LAKE_PRIVATE_LINK_HOSTNAME_PORT_SHELL]: privateLinkHostname || hostname,
        [FIELD_DEFS.DATA_LAKE_TENANT_DATABASES]: databases,
        [FIELD_DEFS.DATA_LAKE_TENANT_ID]: dataLakeTenant.tenantId,
        [FIELD_DEFS.DATA_LAKE_TENANT_TYPE]: dataLakeTenant.dataLakeType,
        [FIELD_DEFS.CONNECTABLE_SUPPORTS_SRV]: false,
        [FIELD_DEFS.WRITE_CONCERN]: null,
        [FIELD_DEFS.CONNECTABLE_NAME]: CONNECTABLES.DATA_FEDERATION,
      });
    },
    fromClusterDescription(clusterDescription) {
      const instance = new AtlasConnectOptions();
      instance.updateFromClusterDescription(clusterDescription);
      instance.listenTo(clusterDescription, 'change', () => {
        instance.updateFromClusterDescription(clusterDescription);
      });
      return instance;
    },
    TYPES,
  }
);

export default AtlasConnectOptions;
