const SET_CLUSTER_DESCRIPTIONS_FETCH_IS_PENDING =
  'nds/clusterDescriptionsFetchIsPending/SET_CLUSTER_DESCRIPTIONS_HAVE_BEEN_FETCHED';

const initialState = true;

export default function clusterDescriptionsFetchIsPendingReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CLUSTER_DESCRIPTIONS_FETCH_IS_PENDING: {
      return action.fetchIsPending;
    }
    default:
      return state;
  }
}

export const setClusterDescriptionsFetchIsPending = (fetchIsPending) => ({
  type: SET_CLUSTER_DESCRIPTIONS_FETCH_IS_PENDING,
  fetchIsPending,
});
