import * as api from 'js/common/services/api';

// Actions Types
const SET_GEO_ZONE_MAP = 'nds/geoZoneMap/SET_GEO_ZONE_MAP';

const initialState = {
  errorLoading: false,
  isLoading: false,
  mapTopology: {},
};

// Reducer
export default function geoZoneMapReducer(state = initialState, action) {
  switch (action.type) {
    case SET_GEO_ZONE_MAP: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
}

const getGeoZoneMapData = (state) => state.nds.geoZoneMap;

// Selectors
export const getErrorLoading = (state) => getGeoZoneMapData(state).errorLoading;

export const getIsLoading = (state) => getGeoZoneMapData(state).isLoading;
export const getMapTopology = (state) => getGeoZoneMapData(state).mapTopology || {};

// Action Creators
export const loadMapTopology = () => (dispatch, getState) => {
  if (!getGeoZoneMapData(getState()).isLoading) {
    dispatch({
      type: SET_GEO_ZONE_MAP,
      payload: {
        errorLoading: false,
        isLoading: true,
      },
    });

    return api.nds.geoZoneMap
      .getMapTopology()
      .then((response) => {
        dispatch({
          type: SET_GEO_ZONE_MAP,
          payload: {
            errorLoading: false,
            isLoading: false,
            mapTopology: response,
          },
        });
      })
      .catch(() => {
        dispatch({
          type: SET_GEO_ZONE_MAP,
          payload: {
            errorLoading: true,
            isLoading: false,
          },
        });
      });
  }
};
