import { MouseEvent } from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Button from '@leafygreen-ui/button';
import { palette } from '@leafygreen-ui/palette';
import queryString from 'query-string';

import { Search } from '@packages/types/auth/search';
import { SocialAuthMethods } from '@packages/types/authMethod';
import { PartnerType } from '@packages/types/billing';

import GithubLogo from '@packages/components/GithubLogo';
import GoogleLogo from '@packages/components/GoogleLogo';

import { mq } from 'js/common/utils/mediaQueries';
import analytics from 'js/common/utils/segmentAnalytics';

import { getSignUpSource } from 'js/auth/utils/analyticsUtils';
import { thirdPartyColors } from 'js/auth/utils/palette';

// css variables
const buttonSize = 52;
// For Vercel flow, the window size is different than the regular flow, so the width is smaller than regular flow
const vercelSocialButtonWidth = '264px';

const socialButtonStyle = (props: { disabled: boolean; text: string; isVercelIntegration: boolean }) =>
  props.disabled
    ? css(
        mq({
          backgroundColor: thirdPartyColors.socialBtnBackgroundDisabled,
          border: `1px solid ${palette.gray.light1}`,
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'row',
          marginTop: '16px',
          borderRadius: '6px',
          width: props.isVercelIntegration ? vercelSocialButtonWidth : [296, 'calc(100vw - 36px)', 296, 296],
          height: `${buttonSize}px`,
        })
      )
    : css(
        mq({
          backgroundColor: thirdPartyColors.socialBtnBackground,
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'row',
          marginTop: '16px',
          borderRadius: '6px',
          width: props.isVercelIntegration ? vercelSocialButtonWidth : [296, 'calc(100vw - 36px)', 296, 296],
          height: `${buttonSize}px`,
          boxShadow: '0px 1px lightgray',
          ':hover, :focus': {
            boxShadow: `0px 0px 0px 3px ${thirdPartyColors.socialBtnBackgroundHover}`,
            borderColor: `${thirdPartyColors.socialBtnDisabled}`,
          },
        })
      );

const DisplayText = styled.div({
  color: palette.gray.dark1,
  paddingRight: '14px',
});

interface SocialAuthButtonProps {
  windowLocation?: Pick<Location, 'assign'>;
  location?: Pick<Location, 'search'>;
  provider: SocialAuthMethods;
  disabled?: boolean;
  className?: string;
  clientState?: Search;
  marketplaceLinkFlow?: PartnerType;
}

export default function SocialAuthButton({
  windowLocation = window.location,
  location: { search } = { search: '' },
  provider,
  disabled = false,
  marketplaceLinkFlow,
  clientState = {},
}: SocialAuthButtonProps) {
  const { isVercelIntegration, isAtlasCLI } = clientState;

  const onClick = async (e: MouseEvent) => {
    e.preventDefault();
    const signupSource = getSignUpSource(marketplaceLinkFlow, isVercelIntegration, isAtlasCLI);
    const anonymousId = analytics.user()?.anonymousId();
    const queryParams = { ...(queryString.parse(search) as Search), signupSource, ...clientState, anonymousId };
    const socialIdp = provider.toLowerCase();
    const ssoUrl = `/account/sso/${socialIdp}?${queryString.stringify(queryParams)}`;
    windowLocation.assign(ssoUrl);
  };

  const LogoAndTextContainer = styled.div({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 'auto',
  });

  const displayLogo = (provider: SocialAuthMethods) => {
    if (provider == SocialAuthMethods.GOOGLE) {
      return <GoogleLogo size={buttonSize} logoColor={disabled ? thirdPartyColors.socialBtnDisabled : undefined} />;
    } else if (provider == SocialAuthMethods.GITHUB) {
      return (
        <GithubLogo
          size={buttonSize}
          logoColor={disabled ? thirdPartyColors.socialBtnDisabled : thirdPartyColors.githubLogo}
        />
      );
    }
  };

  return (
    <Button
      disabled={disabled}
      baseFontSize={16}
      css={socialButtonStyle({ disabled: disabled, text: provider, isVercelIntegration: !!isVercelIntegration })}
      onClick={onClick}
    >
      <LogoAndTextContainer>
        {displayLogo(provider)}
        <DisplayText data-testid="displayText">{provider}</DisplayText>
      </LogoAndTextContainer>
    </Button>
  );
}
