import _ from 'underscore';

import * as api from 'js/common/services/api';

import * as group from '../group';

// actions
const SET_GROUPS = 'organization/SET_GROUPS';
const ADD_GROUP = 'organization/ADD_GROUP';
const REMOVE_GROUP = 'organization/REMOVE_GROUP';
const MOVE_GROUP = 'organization/MOVE_GROUP';

// Reducer

const initialState = [];

// eslint-disable-next-line no-multi-assign
export default function organizationGroupReducer(state = initialState, action) {
  switch (action.type) {
    case SET_GROUPS: {
      return action.payload;
    }
    case ADD_GROUP: {
      return [...state, action.payload];
    }
    case REMOVE_GROUP: {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
      return _.without(state, action.payload);
    }
    default:
      return state;
  }
}

export const Actions = {
  MOVE_GROUP,
};

// Selectors

// TODO(hswolff): De-dupe from organization/index.js once we move to es modules
const getOrgData = (state, props) => state.organization[props.orgId] || {};

const getData = (state, props) => {
  const orgData = getOrgData(state, props);
  orgData.group = orgData.group || [];
  return orgData.group;
};

export const getHydratedGroups = (state, props) =>
  getData(state, props).map((groupId) => {
    return { ...group.getGroup(state, { groupId }), userIds: group.getGroupUserIds(state, { groupId }) };
  });

// Action Creators

const setGroups = ({ groups, orgId }) => ({
  type: SET_GROUPS,
  payload: groups,
  meta: {
    orgId,
  },
});

const addGroup = ({ orgId, groupId }) => ({
  type: ADD_GROUP,
  payload: groupId,
  meta: {
    orgId,
  },
});

const removeGroup = ({ orgId, groupId }) => ({
  type: REMOVE_GROUP,
  payload: groupId,
  meta: {
    orgId,
  },
});

const moveGroupToOrg = ({ oldOrgId, newOrgId, groupId }) => ({
  type: MOVE_GROUP,
  payload: groupId,
  meta: {
    oldOrgId,
    newOrgId,
  },
});
export { setGroups, addGroup, removeGroup, moveGroupToOrg };

export const loadGroups = (orgId) => (dispatch) => {
  return api.organization.groups({ orgId }).then((response) => {
    // update top level group metadata object
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Group[]' is not assignable to pa... Remove this comment to see the full error message
    dispatch(group.setMultipleGroups(response));

    // set group Ids belonging to an organization.groups
    dispatch(
      setGroups({
        groups: response.map((r) => r.id),
        orgId,
      })
    );
  });
};

export const deleteGroup =
  ({ orgId, groupId }) =>
  (dispatch) => {
    return api.group.deleteGroup(groupId).then(() => {
      dispatch(removeGroup({ orgId, groupId }));
      dispatch(group.removeGroup({ orgId, groupId }));
    });
  };

export const moveGroup =
  ({ oldOrgId, newOrgId, groupId }) =>
  (dispatch) => {
    return api.group.migrateGroupToOrg({ groupId, orgId: newOrgId }).then(() => {
      dispatch(moveGroupToOrg({ oldOrgId, newOrgId, groupId }));
    });
  };

export const leaveGroup =
  ({ orgId, groupId, username }) =>
  (dispatch) => {
    return api.group.leaveGroup({ groupId, username }).then(() => {
      dispatch(loadGroups(orgId));
      dispatch(group.loadUsersForGroups({ orgId, groupIds: [groupId] }));
    });
  };
