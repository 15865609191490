interface InviteBannerMsgParams {
  inviterUsername: string;
  inviterGroupName: string;
  inviterOrgName: string;
}

const createInviteBannerMsgForRegistration = ({
  inviterUsername,
  inviterGroupName,
  inviterOrgName,
}: InviteBannerMsgParams) => {
  if (inviterGroupName !== undefined) {
    return `You have been invited by ${inviterUsername} to join the ${inviterGroupName} project in the ${inviterOrgName} organization. To accept the invitation, please create an account.`;
  }
  return `You have been invited by ${inviterUsername} to join the ${inviterOrgName} organization. To accept the invitation, please create an account.`;
};

const createInviteBannerMsgForLogin = ({
  inviterUsername,
  inviterGroupName,
  inviterOrgName,
}: InviteBannerMsgParams) => {
  if (inviterGroupName !== undefined) {
    return `You have been invited by ${inviterUsername} to join the ${inviterGroupName} project in the ${inviterOrgName} organization. To accept the invitation, please login to your account.`;
  }
  return `You have been invited by ${inviterUsername} to join the ${inviterOrgName} organization. To accept the invitation, please login to your account.`;
};

export { createInviteBannerMsgForRegistration, createInviteBannerMsgForLogin };
