import i18n from 'js/legacy/initializers/i18n';

export default {
  getErrorMessage(options) {
    const opts = options || {};
    let message;
    if (opts.error) {
      message = opts.error.toString();
    } else if (opts.xhr) {
      if (opts.xhr.responseJSON) {
        // TODO DK - eventually we should be able to factor out the first two of these:
        message = opts.xhr.responseJSON.errorMessage || opts.xhr.responseJSON.resource || opts.xhr.responseJSON.message;
      } else if (opts.xhr.responseText && !/<!DOCTYPE/.test(opts.xhr.responseText)) {
        message = opts.xhr.responseText;
      }

      message = message || i18n.httpErrorMsg(opts.xhr.status);
    }

    return message || "We're sorry, an unexpected error has occurred";
  },
};
