import { ReadPreference } from '@packages/types/deployment/biConnector';
import { CloudProvider } from '@packages/types/nds/provider';

export enum TenantState {
  ACTIVE = 'ACTIVE',
  UNVERIFIED = 'UNVERIFIED',
  DELETED = 'DELETED',
}

export interface DataSourceBase {
  storeName: string;
  provider: DataStoreProvider;
  provenanceFieldName?: string;
}

export interface S3DataSource extends DataSourceBase {
  provider: DataStoreProvider.S3;
  path: string;
  defaultFormat?: string;
  omitAttributes?: boolean;
}

export interface AtlasDataSource extends DataSourceBase {
  provider: DataStoreProvider.ATLAS;
  database?: string;
  collection?: string;
  collectionRegex?: string;
  databaseRegex?: string;
}

export interface HTTPDataSource extends DataSourceBase {
  provider: DataStoreProvider.HTTP;
  urls?: Array<string>;
  allowInsecure?: boolean;
  defaultFormat?: string;
}

export interface DLSAWSDataSource extends DataSourceBase {
  provider: DataStoreProvider.DLS_V2;
  datasetName?: string;
  datasetPrefix?: string;
  trimLevel?: number;
  maxDatasets?: number;

  // used for empty state when dataset name/prefix has yet
  // to be selected
  pipelineId?: string;
}

export interface OnlineArchiveDataSource extends DataSourceBase {
  provider: DataStoreProvider.ONLINE_ARCHIVE;
  datasetName: string;
  metadataLocation?: {
    provider: string;
    region: string;
  };
}

export interface AzureBlobStorageDataSource extends DataSourceBase {
  provider: DataStoreProvider.AZURE_BLOB_STORAGE;
  path: string;
  defaultFormat?: string;
  omitAttributes?: boolean;
}

export type DataSource =
  | S3DataSource
  | AtlasDataSource
  | HTTPDataSource
  | DLSAWSDataSource
  | OnlineArchiveDataSource
  | AzureBlobStorageDataSource;

export interface Collection {
  name: string;
  dataSources: Array<DataSource>;
}

export interface View {
  name: string;
  source: string;
  pipeline: string;
}

export interface Database {
  name: string;
  collections: Array<Collection>;
  views: Array<View>;
}

export enum DataStoreProvider {
  S3 = 's3',
  ATLAS = 'atlas',
  HTTP = 'http',
  ONLINE_ARCHIVE = 'online_archive',
  DLS_V2 = 'dls:aws',
  AZURE_BLOB_STORAGE = 'azure',
}

export interface Tag {
  name: string;
  value: string;
}

export interface AtlasDataStoreReadPreference {
  mode: ReadPreference;
  tagSets?: Array<Array<Tag>>;
  maxStalenessSeconds?: number;
}

export interface DataStoreBase {
  name: string;
  provider: DataStoreProvider;
}

export interface S3DataStore extends DataStoreBase {
  provider: DataStoreProvider.S3;

  region?: string;
  bucket: string;
  additionalStorageClasses?: Array<string>;
  prefix?: string;
  delimiter?: string;
  includeTags?: boolean;
  agentRegion?: string;
  regionDisplayName?: string;
}

export interface AtlasDataStore extends DataStoreBase {
  provider: DataStoreProvider.ATLAS;

  clusterName: string;
  projectId: string;
  readPreference?: AtlasDataStoreReadPreference;
}

export interface HttpDataStore extends DataStoreBase {
  provider: DataStoreProvider.HTTP;

  urls?: Array<string>;
  defaultFormat?: string;
}

export interface DLSDataStoreV2 extends DataStoreBase {
  provider: DataStoreProvider.DLS_V2;
  region?: string;
  name: string;
}

export interface AzureBlobStorageDataStore extends DataStoreBase {
  provider: DataStoreProvider.AZURE_BLOB_STORAGE;

  serviceUrl: string;
  containerName: string;
  region?: string;
  prefix?: string;
  delimiter?: string;
  replacementDelimiter?: string;
  public?: boolean;
}

export type DataStore = S3DataStore | AtlasDataStore | HttpDataStore | DLSDataStoreV2 | AzureBlobStorageDataStore;

export interface StorageConfig {
  databases: Array<Database>;
  stores: Array<DataStore>;
}

export interface DataProcessRegion {
  cloudProvider: CloudProvider;
  region: string;
}

export enum DataLakeType {
  USER = 'USER',
  ONLINE_ARCHIVE = 'ONLINE_ARCHIVE',
  CHARTS = 'CHARTS',
  ATLAS_SQL = 'ATLAS_SQL',
}

export interface DataLakeTenant {
  groupId: string;
  name: string;
  tenantId: string;
  dataLakeType: DataLakeType;
  createdDate: string;
  lastUpdatedDate: string;
  dataProcessRegion: DataProcessRegion | null;
  clientConfigCount: number;
  clientConfigSource: string;
  clientConfigConfirmed?: boolean;
  tenantConfigAccessed: boolean;
  /** @deprecated */
  queryExecuted: boolean;
  cloudProviderConfig: CloudProviderConfig;
  hostnames: Array<string>;
  privateLinkHostname: string;
  state: string;
  storage: StorageConfig;
}

export interface DataLakeTenantMap {
  [tenantName: string]: DataLakeTenant;
}

export interface Bucket {
  name: string;
  region?: string;
}

export interface AWSCloudProviderConfig {
  roleId: string | null;
  externalId?: string;
  iamUserARN?: string | null;
  iamAssumedRoleARN?: string | null;
  testS3Bucket: Bucket | null;
}

export interface AzureCloudProviderConfig {
  roleId: string | null;
  servicePrincipalId: string | null;
  tenantId: string | null;
  atlasAppId: string | null;
  serviceUrl: string | null;
  containerName: string | null;
  readOnly: boolean;
}

export interface CloudProviderConfigWithAWS {
  aws: AWSCloudProviderConfig;
}

export interface CloudProviderConfigWithAzure {
  azure: AzureCloudProviderConfig;
}

export type CloudProviderConfig = CloudProviderConfigWithAWS | CloudProviderConfigWithAzure;

export interface DataLakeMetrics {
  totalSuccessfulQueries: number;
  totalFailedQueries: number;
  totalDataScanned: number;
  totalDataReturned: number;
  averageExecutionTime: number;
}

export enum ClientConfigSource {
  DATA_LAKE = 'DATA_LAKE',
  ATLAS = 'ATLAS',
}

export interface ValidationResponse {
  errorsCount: number;
  errors: Array<string>;
}

export enum StorageConfigFailedReason {
  JSON_INVALID = 'JSON_INVALID',
  STORAGE_CONFIG_CLIENT_INVALID = 'STORAGE_CONFIG_CLIENT_INVALID',
  STORAGE_CONFIG_DESERIALIZATION_INVALID = 'STORAGE_CONFIG_DESERIALIZATION_INVALID',
  STORAGE_CONFIG_ADL_INVALID = 'STORAGE_CONFIG_ADL_INVALID',
}

export enum StorageConfigWarnReason {
  VISUAL_INCOMPATIBLE = 'VISUAL_INCOMPATIBLE',
  UNKNOWN = 'UNKNOWN',
}

export enum StorageValidationStatus {
  SUCCESS = 'SUCCESS',
  SKIPPED = 'SKIPPED',
  WARN = 'WARN',
  FAIL = 'FAIL',
}

interface BaseValidationResult {
  status: StorageValidationStatus;
}

export interface VisualIncompatibleResult extends BaseValidationResult {
  status: StorageValidationStatus.WARN;
  errorLevel: StorageConfigWarnReason;
  message: string;
  storageConfig: StorageConfig;
}

export interface ADLValidationFailedResult extends BaseValidationResult {
  status: StorageValidationStatus.WARN;
  errorLevel: StorageConfigFailedReason;
  message: string;
  storageConfig: StorageConfig;
}

export interface ErrorValidationResult extends BaseValidationResult {
  status: StorageValidationStatus.FAIL;
  errorLevel: StorageConfigFailedReason;
  message: string;
}

export interface SuccessValidationResult extends BaseValidationResult {
  status: StorageValidationStatus.SUCCESS;
  storageConfig: StorageConfig;
}

export interface SkippedValidationResult extends BaseValidationResult {
  status: StorageValidationStatus.SKIPPED;
  storageConfig: StorageConfig;
}

export type StorageValidationResult =
  | ErrorValidationResult
  | SuccessValidationResult
  | VisualIncompatibleResult
  | ADLValidationFailedResult
  | SkippedValidationResult;

export enum DataLakeStatus {
  DEFAULT = 'DEFAULT',
  COMPLETE = 'COMPLETE',
  ACTIVE = 'ACTIVE',
}
