import * as React from 'react';
import { ComponentPropsWithoutRef } from 'react';

import Button, { ButtonProps } from '@leafygreen-ui/button';
import classNames from 'classnames';

/* NOTE: Leafygreen Button supports more uses than this
 * (for example, if given an href prop it will render as an `a` tag),
 * but for those usecases it should be sufficient to use Button directly,
 * and getting the typing to pass through correctly would've been a headache and
 * a half.
 */
export interface ButtonWithLoaderAndLinkProps extends ButtonProps, ComponentPropsWithoutRef<'button'> {
  disabled?: boolean;
  label?: React.ReactNode;
  showLoader?: boolean;
  isLink?: boolean;
  loaderPlacement?: 'left' | 'right';
  iconClassName?: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
}

const ButtonWithLoaderAndLink: React.FC<ButtonWithLoaderAndLinkProps> = ({
  className,
  label = null,
  children,
  disabled = false,
  isLink = false,
  showLoader = false,
  loaderPlacement = 'right',
  iconClassName = '',
  variant,
  size,
  ...props
}: ButtonWithLoaderAndLinkProps) => {
  const contents = (
    <>
      {showLoader && loaderPlacement === 'left' && (
        <i
          className={`loading-spinner button-waiting-for-async-spinner-left ${iconClassName}`}
          data-testid="button-loader-icon"
        />
      )}
      {label || children}
      {showLoader && loaderPlacement === 'right' && (
        <i
          className={`loading-spinner button-waiting-for-async-spinner ${iconClassName}`}
          data-testid="button-loader-icon"
        />
      )}
    </>
  );

  if (isLink) {
    // Don't use the leafygreen button if we're not using any of its styling.
    return (
      <button type="button" className={classNames('link', className)} disabled={disabled || showLoader} {...props}>
        {contents}
      </button>
    );
  }

  return (
    <Button
      className={className}
      aria-pressed="false"
      disabled={disabled || showLoader}
      variant={variant}
      size={size}
      {...props}
    >
      {contents}
    </Button>
  );
};

export default ButtonWithLoaderAndLink;
