import { useEffect, useRef, useState } from 'react';

import styled from '@emotion/styled';
import { palette } from '@leafygreen-ui/palette';

import AutoAdvanceInputs from '@packages/components/AutoAdvanceInputs';
import { MFAConfiguration } from '@packages/components/CloudMFA/ManageMFA';

import { user } from 'js/common/services/api';
import { getErrorMessageFromCode } from 'js/common/services/authErrorHelper';

import { AutoAdvanceInputsContainer, Container, ErrorMessage, Step } from './styles/mfa';

const BarcodeContainer = styled.div`
  line-height: 20px;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 170px;
`;

const BitmapImage = styled.img`
  width: 102px;
  height: 101px;
  margin-left: 24px;
`;
const BarcodeLink = styled.a`
  color: ${palette.blue.base};
  text-decoration: none;
  line-height: 16px;
  cursor: pointer;
`;

const BarcodeValue = styled.div`
  margin: 4px 0;
`;
interface GoogleProps {
  active?: boolean;
  username: string;
  centralUrl?: string;
  currentMfa?: MFAConfiguration;
  updateMfaData: Function;
  onAuthExpired: Function;
  onSuccess?: Function;
}

type CodeIcon = '' | 'spinner';

export default function Authenticator({
  onSuccess = () => {},
  currentMfa: { updateKey } = {},
  active = false,
  updateMfaData,
  onAuthExpired,
  username,
  centralUrl = '',
}: GoogleProps) {
  const [codeIcon, setCodeIcon] = useState<CodeIcon>('');
  const [errorCode, setErrorCode] = useState('');
  const [showBarcodeValue, setShowBarcodeValue] = useState(false);
  const [barcodeSrc, setBarcodeSrc] = useState('');

  const autoAdvanceInputs = useRef<AutoAdvanceInputs>(null);

  useEffect(() => {
    const getBarcodeSrc = async () => {
      const response = await user.getMultiFactorAuthBarcode({ updateKey, centralUrl });
      const src = `data:image/png;base64,${response.imageData}`;
      setBarcodeSrc(src);
    };

    getBarcodeSrc();
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    autoAdvanceInputs.current.reset();
    setCodeIcon('');
    setErrorCode('');
    setShowBarcodeValue(false);
  }, [active]);

  const verifyCodeAndEnableMfa = async ({ value: authCode }: { value: string | number }) => {
    setCodeIcon('spinner');
    try {
      await Promise.all([
        user.multiFactorCheckUpdateCode({ authCode, centralUrl }),
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ authenticator: boolean; authCo... Remove this comment to see the full error message
        user.multiFactorAuthUpdate({ authenticator: true, authCode, centralUrl }),
      ]);
      setCodeIcon('');
      setErrorCode('');
      await updateMfaData();
      onSuccess();
    } catch (e) {
      const { errorCode } = e;
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      autoAdvanceInputs.current.reset();
      setCodeIcon('');
      setErrorCode(errorCode);
      onAuthExpired(e);
    }
  };

  return (
    <>
      {errorCode && <ErrorMessage>{getErrorMessageFromCode(errorCode)}</ErrorMessage>}
      <Container>
        <Step>1. Download the Google Authenticator app on your iOS, Android or Blackberry device.</Step>
        <Step>
          2. Using the app, scan <br />
          this barcode:
          {updateKey && (
            <BarcodeContainer>
              <BitmapImage src={barcodeSrc} alt="MFA Barcode" />
              {!showBarcodeValue && (
                <BarcodeLink data-testid="barcodeLink" onClick={() => setShowBarcodeValue(true)}>
                  Can&apos;t scan the barcode?
                </BarcodeLink>
              )}
              {showBarcodeValue && (
                <>
                  <p>
                    Enter the following account <br />
                    and key information into the <br />
                    app and continue:
                  </p>
                  <b>Account:</b>
                  <BarcodeValue className="e2e-barCodeRow">{username}</BarcodeValue>
                  <b>Key:</b>
                  <BarcodeValue className="e2e-barCodeRow">{updateKey}</BarcodeValue>
                </>
              )}
            </BarcodeContainer>
          )}
        </Step>
        <Step>
          3. Once this code has been scanned, enter the 6-digit code generated by the app here:
          <AutoAdvanceInputsContainer>
            <AutoAdvanceInputs
              inputsType="code"
              onComplete={verifyCodeAndEnableMfa}
              icon={codeIcon}
              ref={autoAdvanceInputs}
            />
          </AutoAdvanceInputsContainer>
        </Step>
      </Container>
    </>
  );
}
