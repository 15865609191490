import { useRef } from 'react';

import styled from '@emotion/styled';
import queryString from 'query-string';
import { Link } from 'react-router-dom';

import { Search } from '@packages/types/auth/search';
import { SocialAuthMethods } from '@packages/types/authMethod';

import { ThirdPartyIntegrationLineDivider } from '@packages/components/ThirdPartyIntegration/styles/common';
import ThirdPartyIntegrationLayout from '@packages/components/ThirdPartyIntegration/ThirdPartyIntegrationLayout';
import ThirdPartyIntegrationPage from '@packages/components/ThirdPartyIntegration/ThirdPartyIntegrationPage';

import { useRequestParams } from 'js/common/context/RequestParamsContext';
import { mq } from 'js/common/utils/mediaQueries';

import {
  SocialAuthButtonContainer,
  ThirdPartyIntegrationFormInput,
  ThirdPartyIntegrationRedirectText,
  thirdPartyIntegrationTextLinkStyle,
} from 'js/auth/components/styles/thirdPartyIntegration';
import useLoginAnalytics from 'js/auth/hooks/useLoginAnalytics';
import useLoginService, { UseLoginService } from 'js/auth/hooks/useLoginService';

import AuthErrorBanner from './AuthErrorBanner';
import ReCaptcha, { ReCaptchaHandles } from './ReCaptcha';
import SocialAuthButton from './SocialAuthButton';

const WideAuthErrorBanner = styled(AuthErrorBanner)`
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 20px;
`;

const ChangeEmailAnchor = styled.a`
  ${thirdPartyIntegrationTextLinkStyle}
  position: absolute;
  right: ${(props: { hasError: boolean }) => (props.hasError ? '33px' : '12px')};
  ${mq({
    top: ['40px', '40px', '33px', '33px'],
  })}
`;

const EmailInputContainer = styled.div`
  position: relative;
`;

export default function VercelLoginPage({
  location: { search } = { search: '' },
  windowLocation = window.location,
  browserDocument = document,
}: {
  location?: Pick<Location, 'search'>;
  windowLocation?: Pick<Location, 'assign'>;
  browserDocument?: Document;
}) {
  const requestParams = useRequestParams();
  const {
    reCaptchaEnabledLogin,
    isGoogleAuthEnabled,
    isGithubAuthEnabled,
    atlasSiteFullName,
    vercelEmail,
    vercelUserId,
    vercelTeamName,
    vercelTeamId,
  } = requestParams;

  const isSocialAuthEnabled = isGoogleAuthEnabled || isGithubAuthEnabled;
  const parsedSearchLocation = queryString.parse(search) as Search;
  const {
    vercelEmail: vercelEmailQueryParam,
    vercelUserId: vercelUserIdQueryParam,
    vercelTeamName: vercelTeamNameQueryParam,
    vercelTeamId: vercelTeamIdQueryParam,
  } = parsedSearchLocation;

  const clientState = {
    ...parsedSearchLocation,
    n: '/account/vercel/org/selection',
    isVercelIntegration: true,
    vercelEmail: vercelEmail ?? vercelEmailQueryParam,
    vercelUserId: vercelUserId ?? vercelUserIdQueryParam,
    vercelTeamId: vercelTeamId ?? vercelTeamIdQueryParam,
    vercelTeamName: vercelTeamName ?? vercelTeamNameQueryParam,
  };

  const reCaptchaRef = useRef<ReCaptchaHandles>(null);

  useLoginAnalytics(null);
  const {
    onLoginUser,
    onEmailChange,
    onPasswordChange,
    onChangeEmailClick,
    disableEmailInput,
    showInputErrors,
    loginReducerState,
    onSetReCaptchaResponse,
  }: UseLoginService = useLoginService({
    parsedSearchLocation,
    isVercelIntegration: true,
    windowLocation,
    reCaptchaRef,
    browserDocument,
    clientState,
  });

  const {
    username,
    password,
    onlyUsernameShowing,
    formSubmitting,
    authErrorCode,
    marketingUrl,
    isSubmitDisabled,
    isLoginDisabled,
  } = loginReducerState;

  const showPasswordInput = !onlyUsernameShowing;

  return (
    <ThirdPartyIntegrationPage pageTitle="Log in">
      <ThirdPartyIntegrationLayout
        isForm
        formProps={{
          onSubmit: onLoginUser,
          disabled: isSubmitDisabled || isLoginDisabled || formSubmitting,
          buttonText: 'Continue',
        }}
        title="Sign Into MongoDB"
      >
        <SocialAuthButtonContainer>
          {isGoogleAuthEnabled && (
            <>
              <SocialAuthButton
                clientState={clientState}
                disabled={isLoginDisabled}
                location={location}
                provider={SocialAuthMethods.GOOGLE}
              />
            </>
          )}
          {isGithubAuthEnabled && (
            <>
              <SocialAuthButton
                clientState={clientState}
                disabled={isLoginDisabled}
                location={location}
                provider={SocialAuthMethods.GITHUB}
              />
            </>
          )}
        </SocialAuthButtonContainer>
        {isSocialAuthEnabled && <ThirdPartyIntegrationLineDivider>or</ThirdPartyIntegrationLineDivider>}
        {authErrorCode && (
          <WideAuthErrorBanner authErrorCode={authErrorCode} marketingUrl={marketingUrl} siteName={atlasSiteFullName} />
        )}
        <EmailInputContainer>
          <ThirdPartyIntegrationFormInput
            fieldName="username"
            labelName="Email Address"
            autoComplete="email"
            onChange={onEmailChange}
            value={username}
            hasError={showInputErrors}
            disabled={disableEmailInput}
            showChangeUsername={showPasswordInput}
            autoFocus
          />
          {disableEmailInput && (
            <ChangeEmailAnchor tabIndex={-1} role="button" onClick={onChangeEmailClick} hasError={showInputErrors}>
              Change
            </ChangeEmailAnchor>
          )}
        </EmailInputContainer>
        {showPasswordInput && (
          <>
            <ThirdPartyIntegrationFormInput
              fieldName="password"
              labelName="Password"
              autoComplete="current-password"
              type="password"
              hasError={showInputErrors}
              onChange={onPasswordChange}
              value={password}
              autoFocus
            />
          </>
        )}
        {reCaptchaEnabledLogin && (
          <ReCaptcha
            invisible
            callback={(response) => onSetReCaptchaResponse(response)}
            ref={reCaptchaRef}
            action="login"
          />
        )}
        <ThirdPartyIntegrationRedirectText>
          {showPasswordInput ? (
            <Link
              to={{
                pathname: '/reset/password',
                search: `?email=${encodeURIComponent(username)}&isVercelIntegration=true`,
              }}
              css={thirdPartyIntegrationTextLinkStyle}
            >
              Forgot Password?
            </Link>
          ) : (
            <>
              Don&apos;t have a MongoDB account?
              <Link to={`/register/vercel${!!search ? search + '&' : '?'}signedOut=true`}>&nbsp;Sign up now</Link>
            </>
          )}
        </ThirdPartyIntegrationRedirectText>
      </ThirdPartyIntegrationLayout>
    </ThirdPartyIntegrationPage>
  );
}
