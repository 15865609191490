import {
  AtlasDataSource,
  AWSCloudProviderConfig,
  AzureBlobStorageDataSource,
  CloudProviderConfigWithAWS,
  Collection,
  Database,
  DataLakeTenant,
  DataLakeType,
  DataStoreProvider,
  DLSAWSDataSource,
  DLSDataStoreV2,
  HTTPDataSource,
  S3DataSource,
  S3DataStore,
} from '@packages/types/nds/dataLakes';
import { AzureBlobStorageDataStore } from '@packages/types/nds/dataLakes';

import { DLS_AWS_STORE_NAME } from 'js/project/nds/dataLakes/constants';

export const generateAWSCloudProviderConfig = (
  override: Partial<AWSCloudProviderConfig> = {}
): AWSCloudProviderConfig => ({
  externalId: '',
  iamAssumedRoleARN: null,
  iamUserARN: null,
  roleId: null,
  testS3Bucket: null,
  ...override,
});

export const generateDataLakeTenant = (override: Partial<DataLakeTenant> = {}): DataLakeTenant => ({
  groupId: '',
  name: '',
  tenantId: '',
  dataLakeType: DataLakeType.USER,
  createdDate: '',
  lastUpdatedDate: '',
  dataProcessRegion: null,
  clientConfigCount: 0,
  clientConfigSource: '',
  tenantConfigAccessed: false,
  queryExecuted: false,
  cloudProviderConfig: {
    aws: generateAWSCloudProviderConfig((override.cloudProviderConfig as CloudProviderConfigWithAWS)?.aws),
  },
  hostnames: [],
  privateLinkHostname: '',
  state: 'ACTIVE',
  storage: {
    stores: [],
    databases: [],
  },
  ...override,
});

export const generateDatabase = (override: Partial<Database>): Database => {
  return {
    name: '',
    collections: [],
    views: [],
    ...override,
  };
};

export const generateCollection = (override: Partial<Collection>): Collection => {
  return {
    name: '',
    dataSources: [],
    ...override,
  };
};

export const generateS3DataStore = (override: Partial<S3DataStore> = {}): S3DataStore => ({
  name: '',
  provider: DataStoreProvider.S3,
  bucket: '',
  delimiter: '/',
  ...override,
});

export const generateAzureBlobStorageStore = (
  override: Partial<AzureBlobStorageDataStore> = {}
): AzureBlobStorageDataStore => ({
  name: '',
  provider: DataStoreProvider.AZURE_BLOB_STORAGE,
  serviceUrl: '',
  containerName: '',
  ...override,
});

export const generateDLSAWSStore = (override: Partial<DLSDataStoreV2> = {}): DLSDataStoreV2 => ({
  name: DLS_AWS_STORE_NAME,
  provider: DataStoreProvider.DLS_V2,
  ...override,
});

export const generateDLSAWSDataSource = (override: Partial<DLSAWSDataSource> = {}): DLSAWSDataSource => ({
  provider: DataStoreProvider.DLS_V2,
  storeName: DLS_AWS_STORE_NAME,
  ...override,
});

export const generateWildcardDataSource = (override: Partial<DLSAWSDataSource> = {}): DLSAWSDataSource => ({
  datasetPrefix: '',
  trimLevel: 5,
  ...generateDLSAWSDataSource(override),
});

export const generateHttpDataSource = (override: Partial<HTTPDataSource> = {}): HTTPDataSource => ({
  provider: DataStoreProvider.HTTP,
  storeName: '',
  urls: [],
  ...override,
});

export const generateAtlasDataSource = (override: Partial<AtlasDataSource> = {}): AtlasDataSource => ({
  provider: DataStoreProvider.ATLAS,
  storeName: '',
  database: '',
  collection: '',
  ...override,
});

export const generateS3DataSource = (override: Partial<S3DataSource> = {}): S3DataSource => ({
  provider: DataStoreProvider.S3,
  storeName: '',
  path: '',
  ...override,
});

export const generateAzureBlobStorageDataSource = (
  override: Partial<AzureBlobStorageDataSource> = {}
): AzureBlobStorageDataSource => ({
  provider: DataStoreProvider.AZURE_BLOB_STORAGE,
  storeName: '',
  path: '',
  ...override,
});
