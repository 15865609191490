import { useEffect } from 'react';

import Cookies from 'js-cookie';

import { Search } from '@packages/types/auth/search';

import { useRequestParams } from 'js/common/context/RequestParamsContext';
import analytics from 'js/common/utils/segmentAnalytics';

import { getClearbitData, SignupMethod, trackRegistrationData } from 'js/auth/utils/analyticsUtils';

export function useGoogleAnalytics(parsedSearchLocation: Search, appUserParams) {
  const { uId, company, signupSource, signupMethod, referrer } = parsedSearchLocation;
  const { analyticsEnabled, eloquaSiteId } = useRequestParams();

  useEffect(() => {
    if (analyticsEnabled && appUserParams.username && signupMethod === SignupMethod.GOOGLE) {
      const decodedCompany = company?.replace(/\+/g, ' ');
      const { username, firstName, lastName, country } = appUserParams;
      const clearbitData = getClearbitData();
      const data = {
        email: username,
        ...clearbitData,
        first_name: firstName,
        last_name: lastName,
        company: decodedCompany,
        country,
        referrer,
        signup_source: signupSource ? signupSource.replace(/\+/g, ' ') : undefined,
        signup_method: signupMethod ? signupMethod.replace(/\+/g, ' ') : undefined,
        optimizelyEndUserId: Cookies.get('optimizelyEndUserId'),
      };

      const anonymousId = analytics.user()?.anonymousId();
      trackRegistrationData({
        eloquaSiteId,
        parsedSearchLocation,
        anonymousId,
        userId: uId,
        data,
      });
    }
  }, [appUserParams.username, appUserParams.firstName, appUserParams.lastName, appUserParams.country]);
}
