import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Button from '@leafygreen-ui/button';
import Icon from '@leafygreen-ui/icon';
import queryString from 'query-string';

import { Search } from '@packages/types/auth/search';

import { ThirdPartyIntegrationDescription } from '@packages/components/ThirdPartyIntegration/styles/common';
import ThirdPartyIntegrationLayout from '@packages/components/ThirdPartyIntegration/ThirdPartyIntegrationLayout';
import ThirdPartyIntegrationPage from '@packages/components/ThirdPartyIntegration/ThirdPartyIntegrationPage';

import { useRequestParams } from 'js/common/context/RequestParamsContext';

const clusterDescriptionStyle = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 18px;
  font-size: 16px;
  color: #001e2b;
  line-height: 28px;
`;

const StyledButton = styled(Button)`
  margin: 8px 0px 0px 42px;
`;

export default function VercelErrorHandlingPage({
  location: { search } = { search: '' },
}: {
  location?: Pick<Location, 'search'>;
}) {
  const parsedSearchLocation = queryString.parse(search) as Search;
  const { orgId, groupId } = parsedSearchLocation;
  const { centralUrl } = useRequestParams();

  const onClickViewDetails = async () => {
    window.location.assign(`${centralUrl}/v2#/org/${orgId}/integrations`);
  };

  const onClickSetUp = async () => {
    window.location.assign(`${centralUrl}/v2/${groupId}#/dataAPI`);
  };

  return (
    <ThirdPartyIntegrationPage>
      <ThirdPartyIntegrationLayout>
        <ThirdPartyIntegrationDescription css={clusterDescriptionStyle}>
          <Icon glyph="CheckmarkWithCircle" fill="#00A35C" size="xlarge" width={42} />
          Successfully integrated your Vercel and MongoDB Atlas account. You can now view your integration details in
          Atlas.
        </ThirdPartyIntegrationDescription>
        <StyledButton onClick={onClickViewDetails} css={{ marginBottom: '40px' }}>
          View Integration Details
        </StyledButton>
        <ThirdPartyIntegrationDescription css={clusterDescriptionStyle}>
          <Icon glyph="XWithCircle" fill="#DB3030" size="xlarge" width={36} />
          We encountered an error when trying to enable the Data API. Please set up the Data API manually.
        </ThirdPartyIntegrationDescription>
        <StyledButton onClick={onClickSetUp}>Manually Set Up the Data API</StyledButton>
      </ThirdPartyIntegrationLayout>
    </ThirdPartyIntegrationPage>
  );
}
