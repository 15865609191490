import 'cross-fetch/polyfill';
import queryString, { StringifyOptions } from 'query-string';
import _ from 'underscore';

import { CloudTeams, sendError } from 'js/common/utils/bugsnag';

let regionalBaseUrlGetter: () => string | null = () => null;

export function storeRegionalBaseUrlGetter(newGetter: () => string | null) {
  regionalBaseUrlGetter = newGetter;
}

const statusCodeToErrorCodes = {
  400: 'BAD_REQUEST',
  401: 'UNAUTHORIZED',
  403: 'FORBIDDEN',
  404: 'NOT_FOUND',
  405: 'METHOD_NOT_ALLOWED',
  500: 'SERVER_ERROR',
};

let onResponseHandlers: Array<OnResponseHandler> = [];

function createCsrfHeaders() {
  return Array.from(document.getElementsByTagName('meta')).reduce((acc, el) => {
    if (el.getAttribute('name') === 'csrf-token') {
      acc['X-CSRF-Token'] = el.getAttribute('content');
    }
    if (el.getAttribute('name') === 'csrf-time') {
      acc['X-CSRF-Time'] = el.getAttribute('content');
    }

    return acc;
  }, {});
}

let csrfHeaders = createCsrfHeaders();

function csrfSafeMethod(method) {
  // these HTTP methods do not require CSRF protection
  return /^(GET|HEAD|OPTIONS|TRACE)$/.test(method);
}

export interface FetchWrapperOptions extends Omit<RequestInit, 'cache'> {
  createCsrfHeaders?: boolean;
  cache?: boolean;
  isSendToRegionalServer?: boolean;
  customRegionalServerUrl?: string;
}

export default function fetchWrapper(url: string, options: FetchWrapperOptions = {}) {
  options.headers = options.headers || {};

  _.defaults(options.headers, {
    'Content-Type': 'application/json',
  });

  // Mostly used for testing purposes.
  if (options.createCsrfHeaders) {
    csrfHeaders = createCsrfHeaders();

    // Don't include in actual fetch request.
    delete options.createCsrfHeaders;
  }

  if (!csrfSafeMethod(options.method)) {
    _.extend(options.headers, csrfHeaders);
  }

  // If we have any empty strings in our header object then MS Edge throws a
  // TypeMismatchError. To fix this we remove any headers that have an empty
  // value.
  // See relevant GitHub issues: https://github.com/aurelia/fetch-client/issues/81
  Object.keys(options.headers).forEach((key) => {
    const val = options.headers![key];
    if (val === '') {
      delete options.headers![key];
    }
  });

  // Older versions of IE will cache AJAX requests causing strange issues with the pattern:
  //   - Update with AJAX, reload the document, query with AJAX
  // This snippet will globally turn off AJAX caching for all requests in Internet Explorer.
  // You may enable selectively by setting cache to true.
  if (options.cache !== true) {
    options.headers['Cache-Control'] = 'no-cache';
    (options.headers as any).Pragma = 'no-cache';
  }

  // Don't send the cache value to fetch
  delete options.cache;

  // Include credentials (aka cookies) in requests to the same site or add the credentials in
  // options when they're provided.
  if (!options.credentials) {
    options.credentials = 'same-origin';
  }

  if (options.isSendToRegionalServer) {
    // regionalBaseUrlGetter return the url that was set
    // when projects settings were loaded during application init
    const regionalBaseUrl = options.customRegionalServerUrl ? options.customRegionalServerUrl : regionalBaseUrlGetter();
    if (regionalBaseUrl && url) {
      url = new URL(url, regionalBaseUrl).toString();
      // Allow sharing of cookies and auth headers to support authentication on regional subdomains
      options.credentials = 'include';
    }
  }

  /**
   * On success we return the response directly.
   * On any form of error, we guarantee that the data returned to the rejection handler is always
   * an object of the form { errorCode, requestUrl }. Additionally, 500 errors from the server
   * will usually include a message field.
   */
  return fetch(url, options as RequestInit).then(
    // the raw Response object directly.
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(response: Response) => Response... Remove this comment to see the full error message
    (response) => {
      // There seems to be an edge case in Safari in which an error is being thrown
      // when we are cloning the response object.
      // This try/catch is to prevent any error from causing the page to not load.
      try {
        onResponseHandlers.forEach((handler) => {
          handler(url, options, response.clone());
        });
      } catch (error) {
        sendError({
          error,
          team: CloudTeams.Triage,
          metaData: {
            message: 'Error while running response handlers.',
            url: response.url,
          },
        });
      }

      if (response.ok) {
        return response;
      }

      function templateError(error) {
        // Ensure errorCode and requestUrl are present
        if (error.errorCode === undefined) {
          error.errorCode = statusCodeToErrorCodes[response.status];
        }
        error.requestUrl = response.url;
        error.statusCode = response.status;
        throw error;
      }

      // The response is not ok, so reject the promise with the JSON body
      return response.json().then(
        templateError, // on success, we template the parsed response with the status code and url
        () => templateError({}) // we want to ignore the parse error on failure
      );
    },
    (error) => {
      // Fetch will return TypeError on network errors. Ensure we convert to an object.
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ errorCode: 'NETWORK_ERROR', requestUrl: url, error });
    }
  );
}

// Usage 1: when sending a request of content type 'application/x-www-form-urlencoded'.
// Usage 2: when sending a GET request with queryString params (turns an object into `foo=value&otherKey=otherValue`)
// Also converts arrays (like { arr: [1, 2, 3] }) in the same way as $.param (e.g. 'arr[]=1&arr[]=2&arr=[]=3').
export const formParams = function formParams(
  params: Record<string, any>,
  { skipNull = true, arrayFormat = 'bracket', ...options }: StringifyOptions = {}
) {
  return queryString.stringify(params, { skipNull, arrayFormat, ...options });
};

// This is helper function to allow you to update the CSRF tokens that we use for every request.
// This method will update the values in the DOM and then update our local cached copy of them.
// A use case for this is when a user is going from logged out to logged in within the same page,
// and they need to set the csrf values at a later point.
export const updateCsrfHeaders = function updateCsrfHeaders({ csrfTime, csrfToken }) {
  Array.from(document.getElementsByTagName('meta')).forEach((el) => {
    if (el.getAttribute('name') === 'csrf-token') {
      el.setAttribute('content', csrfToken);
    }
    if (el.getAttribute('name') === 'csrf-time') {
      el.setAttribute('content', csrfTime);
    }
  });

  csrfHeaders = createCsrfHeaders();
};

type OnResponseHandler = (url: string, options: FetchWrapperOptions, response: Response) => void;

/**
 * Register to listen to all responses to AJAX requests. Handlers will be called with:
 * - The url of the request
 * - The options passed for the request
 * - The response for the request
 * @param {function} handler Response handler function.
 * @return {function} Unsubscribe function to remove the handler.
 */
export const registerOnResponseHandler = function registerOnResponseHandler(handler: OnResponseHandler) {
  onResponseHandlers.push(handler);

  // Return function that can be used to unsubscribe from response handlers.
  return function unsubscribeResponseHandler() {
    onResponseHandlers = onResponseHandlers.filter((h) => h !== handler);
  };
};
