export default {
  httpErrorMsg(statusCode) {
    if (statusCode === 400) {
      return this.entries.templateServerErrorMsg;
    }
    if (statusCode === 401 || statusCode === 403) {
      return 'You do not have permission to access this resource.';
    }
    return "We're sorry, an unexpected error has occurred";
  },

  multiFactorAuthErrorMsg(errorCode) {
    if (errorCode === 'INVALID_PHONE_NUMBER') {
      return 'The phone number entered is invalid. If this is not a US phone number try entering a + sign, followed by your country code, followed by your number. Some countries require a + sign, followed by your country code, followed by the number 1, followed by your number. For example, if your country code is 52 and your number is 9875551212, try +529875551212 or +5219875551212.';
    }
    if (errorCode === 'INVALID_MOBILE_NUMBER') {
      return 'The phone number entered is invalid.';
    }
    if (errorCode === 'NO_INTERNATIONAL_CALLS') {
      return 'Voice calls to international numbers are not supported.';
    }
    if (errorCode === 'NO_PHONE_NUMBER') {
      return 'No phone number was specified.';
    }
    if (errorCode === 'MULTI_FACTOR_AUTH_SMS_FAILED') {
      return 'An SMS message could not be sent.';
    }
    if (errorCode === 'MULTI_FACTOR_AUTH_VOICE_CALL_FAILED') {
      return 'A voice call could not be sent.';
    }
    if (errorCode === 'MULTI_FACTOR_AUTH_BACKUP_EQUALS_PRIMARY') {
      return 'The specified backup number is the same as the primary phone number. Please choose a different phone number.';
    }
    return "We're sorry, an unexpected error has occurred";
  },

  entries: {
    templateServerErrorMsg: 'The request contained invalid data.',
    hostChartNoDataAvailable: 'No data available.',
    clusterInvalidClusterNameErrorMsg: 'Please enter a valid cluster name.',
    invalidInputErrorMsg: 'Unable to validate the specified input data',
    passwordLengthRequirement:
      'Your password must be at least eight (8) characters long and contain at least one letter, one digit and one special character.',
  },
};
