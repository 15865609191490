import { AnalyticsInstanceSize, CloudProvider, InstanceSize } from '@packages/types/nds/provider';
import { RegionName } from '@packages/types/nds/region';

export enum TierType {
  FREE = 'FREE',
  PAID = 'PAID',
}

export enum BackupType {
  CONTINUOUS = 'CONTINUOUS',
  SNAPSHOTS = 'SNAPSHOTS',
}

export interface BasicClusterEventProperties {
  tier: TierType;
  cloud_provider: CloudProvider;
  region: RegionName;
  cross_region: boolean;
  global_cluster: boolean;
  instance_size: InstanceSize | AnalyticsInstanceSize;
  disk_size_gb: number;
  db_version: string;
  backup_selected: boolean;
  sharding: boolean;
  shard_count: number;
  BI_connector: boolean;
  encryption_key_management: boolean;
  cluster_name: string;
  is_temporary?: boolean;
  user_type?: string;
  ssl_enabled?: boolean;
  ca_file_provided?: boolean;
  hourly_cost?: string;
  auto_scaling_compute_enabled?: boolean;
  auto_scaling_compute_scaledown_enabled?: boolean;
  auto_scaling_compute_max_instance?: string;
  auto_scaling_compute_min_instance?: string;
  termination_protection_enabled?: boolean;
  backup_option?: BackupType;
  cluster_unique_id?: string;
  BI_node_type?: string;
  require_indexes?: boolean;
  server_side_JS?: boolean;
  autoIndex?: boolean;
  auto_expand_storage?: boolean;
  key_index_limit?: boolean;
  IOPS_speed?: number;
  context?: string;
}
