import type { ChurnSurveyForm } from '@packages/types/clusters/churnSurvey';

import fetchWrapper from '../fetchWrapper';

export function submitChurnSurvey(groupId: string, form: ChurnSurveyForm, didTerminate: boolean): Promise<unknown> {
  return fetchWrapper(`/${groupId}/churnSurvey`, {
    method: 'POST',
    body: JSON.stringify({ ...form, didTerminate }),
  });
}

export function isOnlyPaidGroup(groupId: string): Promise<{ isOnlyPaidGroup: boolean }> {
  return fetchWrapper(`/${groupId}/churnSurvey/isOnlyPaidGroup`).then((resp) => resp.json());
}
