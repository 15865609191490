import { palette } from '@leafygreen-ui/palette';

export const thirdPartyColors = {
  googleBtnBackgroundBase: '#4285f4',
  googleBtnBackgroundDark1: '#3578E7',
  googleBtnBackgroundDark2: '#1C5FCE',
  googleBtnText: '#FFF',
  googleBtnBackgroundDisabled: palette.gray.light1,
  realmPurple: '#6966ee',
  socialBtnBackground: palette.white,
  socialBtnBackgroundDisabled: palette.gray.light2,
  socialBtnBackgroundHover: '#E8ECEB',
  socialBtnDisabled: palette.gray.base,
  githubLogo: palette.black,
};

const colors = {
  alertBannerBorder: palette.yellow.light2,
  bannerBackground: palette.blue.light3,
  bannerBorder: palette.blue.light2,
  bannerText: palette.blue.dark2,
  cardBorder: palette.gray.light2,
  cardHeadlineText: palette.gray.dark2,
  checkmark: palette.green.dark1,
  copyRightText: palette.gray.base,
  disabledInputBackground: palette.gray.light2,
  formBorder: palette.gray.light2,
  footerBorder: palette.gray.light2,
  headerText: palette.gray.dark2,
  iconBackground: palette.white,
  inputBackground: palette.white,
  inputBorder: palette.gray.light1,
  inputSubTitleText: palette.gray.base,
  labelText: palette.gray.dark2,
  link: palette.blue.base,
  logoText: palette.gray.base,
  resetSuccessBackground: palette.green.light3,
  resetSuccessBorder: palette.green.light2,
  resetSuccessIcon: palette.green.dark1,
  resetSuccessText: palette.green.dark2,
  resetErrorBackground: palette.red.light3,
  resetErrorBorder: palette.red.light2,
  resetErrorText: palette.red.dark2,
  subtitleText: palette.gray.dark3,
  successSpinner: palette.green.dark1,
  text: palette.gray.dark3,
  validPasswordBoxBorder: palette.blue.light2,
  validPasswordBoxBackground: palette.blue.light3,
  warning: palette.red.base,
};

export default colors;
