// TS counterpart of com.xgen.svc.mms.model.auth.UiAuthMethod

export enum SocialAuthMethods {
  GOOGLE = 'Google',
  GITHUB = 'GitHub',
}

enum OtherAuthMethods {
  LOCAL = 'Local',
  LDAP = 'LDAP',
  SAML = 'SAML',
  OKTA = 'OKTA',
  EXTERNAL_IDP = 'External idP',
}

export const AuthMethods = {
  ...SocialAuthMethods,
  ...OtherAuthMethods,
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare -- intentionally naming the type the same as the enum
export type AuthMethods = SocialAuthMethods | OtherAuthMethods;
