import { GroupId } from '@packages/types/groups';
import { CreateOrganization, OrganizationId } from '@packages/types/organizations';

import * as api from 'js/common/services/api';
import analytics from 'js/common/utils/segmentAnalytics';

import { ClearbitData } from './analyticsUtils';

interface Params {
  orgName?: string;
  isNDS?: boolean;
  signupSource?: string;
  signupMethod?: string;
  isOnPrem?: boolean;
  centralUrl?: string;
  clearbitData?: ClearbitData;
}

export type CreateInitialOrgAndProject = (params: Params) => Promise<{ orgId: OrganizationId; groupId: GroupId }>;

export async function createInitialOrgAndProject({
  orgName,
  isNDS,
  signupSource,
  signupMethod,
  isOnPrem = false,
  centralUrl = '',
  clearbitData = {},
}: Params) {
  const createOrganizationRequestData: CreateOrganization = {
    name: orgName,
    initialGroupType: isOnPrem ? 'ONPREM' : isNDS ? 'NDS' : 'CLOUD',
    centralUrl,
    clearbitData,
  };

  const newOrgResponse = await api.organization.createOrganization(createOrganizationRequestData);
  const orgId = newOrgResponse.id;

  const newGroupResponse = await api.user.addAnotherGroup({
    orgId,
    group: 'Project 0',
    anonymousId: analytics.user()?.anonymousId() ?? null, // ops manager does not have segment enabled
    centralUrl,
    signupSource,
    signupMethod,
  });
  const groupId = newGroupResponse.newObjId;

  return { orgId, groupId, orgName: newOrgResponse.name };
}
