import type { CostEstimate } from '@packages/types/billing';
import { AutoSetupSettings } from '@packages/types/nds/clusterDescription';
import { CrossCloudProviderOptionsView } from '@packages/types/nds/provider';

import fetchWrapper from '../fetchWrapper';

export default {
  getClusterDescriptions(groupId, centralUrl = '') {
    return fetchWrapper(`${centralUrl}/nds/clusters/${groupId}`, {
      method: 'GET',
      credentials: 'include',
    }).then((resp) => resp.json());
  },

  getClusterDescription(groupId, clusterName) {
    return fetchWrapper(`/nds/clusters/${groupId}/${clusterName}`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  postClusterDescription(groupId, clusterDescriptionJson, recaptchaToken = '') {
    return fetchWrapper(`/nds/clusters/${groupId}?recaptchaToken=${recaptchaToken}`, {
      method: 'POST',
      body: JSON.stringify(clusterDescriptionJson),
    }).then((resp) => resp.json());
  },

  patchClusterDescription(groupId, clusterDescriptionJson) {
    return fetchWrapper(`/nds/clusters/${groupId}/${clusterDescriptionJson.name}`, {
      method: 'PATCH',
      body: JSON.stringify(clusterDescriptionJson),
    }).then((resp) => resp.json());
  },

  getDeletedClusterNamesWithActiveSnapshots(groupId) {
    return fetchWrapper(`/nds/clusters/deletedClusterNamesWithActiveSnapshots/${groupId}`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  // This endpoint is used from the Account/ app, so it needs `credentials: true` due to CORS policy
  createVercelCluster({ centralUrl, groupId, clusterDescriptionJson }) {
    return fetchWrapper(`${centralUrl}/nds/clusters/vercel/${groupId}`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(clusterDescriptionJson),
    }).then((resp) => resp.json());
  },

  // This endpoint was defined separately from patchClusterDescription so it can
  // explicitly set needsMongoDBConfigPublishAfter upon submit.
  patchGeoSharding(groupId, clusterName, geoShardingJson) {
    return fetchWrapper(`/nds/clusters/${groupId}/${clusterName}/geoSharding`, {
      method: 'PATCH',
      body: JSON.stringify(geoShardingJson),
    }).then((resp) => resp.json());
  },

  upgradeTenantCluster(groupId, clusterDescriptionJson) {
    return fetchWrapper(`/nds/clusterUpgrade/${groupId}`, {
      method: 'POST',
      body: JSON.stringify(clusterDescriptionJson),
    }).then((resp) => resp.json());
  },

  getCostEstimate(groupId, clusterDescriptionJson): Promise<CostEstimate> {
    return fetchWrapper(`/nds/clusters/${groupId}/costEstimate`, {
      method: 'POST',
      body: JSON.stringify(clusterDescriptionJson),
    }).then((resp) => resp.json());
  },

  getCrossCloudProviderOptions(groupId, providers): Promise<CrossCloudProviderOptionsView> {
    const providerString = providers.map((provider) => `providers=${encodeURIComponent(provider)}`).join('&');
    const method = 'GET';
    return fetchWrapper(`/nds/clusters/${groupId}/providerOptions?${providerString}`, { method }).then((resp) =>
      resp.json()
    );
  },

  getNewFormLocationMapping(groupId, replicationSpecs, customZoneMapping) {
    return fetchWrapper(`/nds/geoSharding/${groupId}/newFormLocationMapping`, {
      method: 'POST',
      body: JSON.stringify({
        replicationSpecs,
        customZoneMapping,
      }),
    }).then((resp) => resp.json());
  },

  getLocationMapping(groupId, replicationSpecs, customZoneMapping) {
    return fetchWrapper(`/nds/geoSharding/${groupId}/locationMapping`, {
      method: 'POST',
      body: JSON.stringify({
        replicationSpecs,
        customZoneMapping,
      }),
    }).then((resp) => resp.json());
  },

  getProcessArgs(groupId, clusterName) {
    return fetchWrapper(`/nds/clusters/${groupId}/${clusterName}/processArgs`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getDefaultProcessArgs(groupId) {
    return fetchWrapper(`/nds/clusters/${groupId}/processArgs/default`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  executeProcessArgsService(
    groupId,
    clusterName,
    processArgsJson,
    diskSizeGB,
    mongoDBVersion,
    instanceSize,
    serviceHttpMethod
  ) {
    const diskSizeGBParam = `diskSizeGB=${encodeURIComponent(diskSizeGB)}`;
    const mongoDBVersionParam = `mongoDBVersion=${encodeURIComponent(mongoDBVersion)}`;
    const instanceSizeParam = `instanceSize=${encodeURIComponent(instanceSize)}`;
    const queryParams = [diskSizeGBParam, mongoDBVersionParam, instanceSizeParam].join('&');

    return fetchWrapper(`/nds/clusters/${groupId}/${clusterName}/processArgs?${queryParams}`, {
      method: serviceHttpMethod,
      body: JSON.stringify(processArgsJson),
    }).then((resp) => resp.json());
  },

  postProcessArgs(groupId, clusterName, processArgsJson, diskSizeGB, mongoDBVersion, instanceSize) {
    return this.executeProcessArgsService(
      groupId,
      clusterName,
      processArgsJson,
      diskSizeGB,
      mongoDBVersion,
      instanceSize,
      'POST'
    );
  },

  patchProcessArgs(groupId, clusterName, processArgsJson, diskSizeGB, mongoDBVersion, instanceSize) {
    return this.executeProcessArgsService(
      groupId,
      clusterName,
      processArgsJson,
      diskSizeGB,
      mongoDBVersion,
      instanceSize,
      'PATCH'
    );
  },

  getClusterUsageStats(groupId, clusterName) {
    return fetchWrapper(`/nds/clusters/${groupId}/${clusterName}/clusterUsageStats`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getReplicationLag(groupId, clusterName) {
    return fetchWrapper(`/nds/clusters/${groupId}/${clusterName}/replicationLag`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  grantInfrastructureAccess(groupId, clusterName) {
    return fetchWrapper(`/nds/clusters/${groupId}/${clusterName}/bypassRestrictedEmployeeAccess`, {
      method: 'POST',
    });
  },

  grantSyncAccess(groupId, clusterName) {
    return fetchWrapper(`/nds/clusters/${groupId}/${clusterName}/grantSyncDebugAccess`, {
      method: 'POST',
    });
  },

  grantSyncAndInfrastructureAccess(groupId, clusterName) {
    return fetchWrapper(`/nds/clusters/${groupId}/${clusterName}/grantSyncDebugAndInfrastructureAccess`, {
      method: 'POST',
    });
  },

  revokeAllGrantedEmployeeClusterAccess(groupId, clusterName) {
    return fetchWrapper(`/nds/clusters/${groupId}/${clusterName}/revokeAllGrantedEmployeeClusterAccess`, {
      method: 'POST',
    });
  },

  fetchNamespaces(groupId, clusterName, requestOptions = {}): Promise<Array<string>> {
    return fetchWrapper(`/nds/clusters/${groupId}/${clusterName}/data/namespaces`, {
      method: 'GET',
      ...requestOptions,
    }).then((resp) => resp.json());
  },

  fetchDatabases(groupId, clusterName, requestOptions = {}) {
    return fetchWrapper(`/nds/clusters/${groupId}/${clusterName}/data/databases`, {
      method: 'GET',
      ...requestOptions,
    }).then((resp) => resp.json());
  },

  runSearchAggregation(groupId, clusterName, databaseName, collectionName, searchQuery) {
    return fetchWrapper(
      `/nds/clusters/${groupId}/${clusterName}/data/databases/${databaseName}/collections/${collectionName}/runSearchAggregation`,
      {
        method: 'POST',
        body: JSON.stringify(searchQuery),
      }
    ).then((resp) => resp.json());
  },

  sampleCollectionFieldNames(groupId, clusterName, databaseName, collectionName, requestOptions = {}) {
    return fetchWrapper(
      `/nds/clusters/${groupId}/${clusterName}/data/databases/${databaseName}/collections/${collectionName}/sampleCollectionFieldNames`,
      {
        method: 'GET',
        ...requestOptions,
      }
    ).then((resp) => resp.json());
  },

  getInstanceHardwareReplicationViews(groupId, clusterName) {
    return fetchWrapper(`/nds/clusters/${groupId}/${clusterName}/instances`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },
  createAutoSetupM0Cluster(groupId: string, autoSetupM0ParamsViewJson: AutoSetupSettings, recaptchaToken = '') {
    return fetchWrapper(`/nds/clusters/${groupId}/autoSetup?recaptchaToken=${recaptchaToken}`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(autoSetupM0ParamsViewJson),
    }).then((resp) => resp.json());
  },
};
