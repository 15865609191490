import fetchWrapper from '../fetchWrapper';

const mapTopologyJsonUrl = '/static/assets/json/world-countries-states-topology.json';

export default {
  getMapTopology() {
    return fetchWrapper(mapTopologyJsonUrl, {
      method: 'GET',
    }).then((resp) => resp.json());
  },
};
