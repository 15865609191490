import { useEffect, useState } from 'react';

import { css } from '@emotion/react';
import Banner from '@leafygreen-ui/banner';
import Card from '@leafygreen-ui/card';
import { usePoller } from '@leafygreen-ui/hooks';
import { palette } from '@leafygreen-ui/palette';

import { FactorType, PushFactorEnrollmentResponse } from '@packages/types/accountMultiFactorAuth';

import * as api from 'js/common/services/api';
import { getErrorMessageFromCode } from 'js/common/services/authErrorHelper';
// common styles
import { bannerStyles, setupStyles, Step } from 'js/common/styles/multiFactorAuth';
import { CloudTeams, sendError } from 'js/common/utils/bugsnag';
import { isLastAuthExpired } from 'js/common/utils/multiFactorAuthUtils';

const barcodeLoaderStyles = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '164px',
  width: '164px',
});

const refreshCodeLinkStyles = css({
  color: palette.blue.base,
  textDecoration: 'none',
  cursor: 'pointer',
  paddingTop: '',
});

export default function OktaVerifySetupCard({
  windowLocation = window.location,
  authExpiredPath,
  onSuccess,
  username,
}: {
  windowLocation?: Pick<Location, 'assign'>;
  authExpiredPath: string;
  onSuccess: Function;
  username: string;
}) {
  const [isEnrolling, setIsEnrolling] = useState(true);
  const [factorEnrollmentResponse, setFactorEnrollmentResponse] = useState<PushFactorEnrollmentResponse>();
  const [isBarcodeExpired, setIsBarcodeExpired] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const enrollOktaVerifyFactor = async () => {
    try {
      const response = await api.accountMultiFactorAuth.enrollFactor({ factorType: FactorType.Push });
      setFactorEnrollmentResponse(response as PushFactorEnrollmentResponse);
      setIsEnrolling(false);
    } catch ({ errorCode, errorMessage: enrollErrorMessage }) {
      sendError({ error: errorCode, team: CloudTeams.CoreIam });
      if (isLastAuthExpired(errorCode)) {
        windowLocation.assign(authExpiredPath);
      } else if (errorCode === 'RATE_LIMITED') {
        setErrorMessage(getErrorMessageFromCode(errorCode));
      } else {
        setErrorMessage(enrollErrorMessage);
      }
    }
  };

  useEffect(() => {
    if (username !== '') {
      enrollOktaVerifyFactor();
    }
  }, [username]);

  const pollForActivation = async () => {
    const {
      id,
      activation: { expiresAt },
    } = factorEnrollmentResponse as PushFactorEnrollmentResponse;

    // Verify that the qr code is still active. if not, set to expired
    const now = new Date();
    if (now > new Date(expiresAt)) {
      setErrorMessage('The QR code has expired. Please click "Refresh Code" to generate a new code.');
      setIsBarcodeExpired(true);
    }

    try {
      const { activated, userFactors } = await api.accountMultiFactorAuth.activateFactor({
        factorType: FactorType.Push,
        factorId: id,
      });

      if (activated) {
        onSuccess(userFactors);
      }
    } catch ({ errorCode }) {
      if (isLastAuthExpired(errorCode)) {
        windowLocation.assign(authExpiredPath);
      }
    }
  };

  usePoller(pollForActivation, {
    interval: 7e3,
    enabled: !!factorEnrollmentResponse && !isBarcodeExpired,
    immediate: false,
  });

  return (
    <Card css={setupStyles.card}>
      <Step.Container>
        <Step.Header>Step One</Step.Header>
        <div>Download the Okta Verify app on your mobile device.</div>
      </Step.Container>

      <Step.Container>
        <Step.Header>Step Two</Step.Header>
        <div>Using the app, tap the &quot;+&quot; icon, then scan the barcode.</div>
        {errorMessage && (
          <Banner variant="danger" data-testid="error-banner" css={bannerStyles}>
            {errorMessage}
          </Banner>
        )}
        {isEnrolling ? (
          <div css={barcodeLoaderStyles}>
            <i className="loading-spinner button-waiting-for-async-spinner" />
          </div>
        ) : (
          <img data-testid="qr-code" src={factorEnrollmentResponse?.activation.qrCode.href} alt="QR Code" />
        )}
        {isBarcodeExpired && (
          <div
            css={refreshCodeLinkStyles}
            data-testid="refresh-code"
            onClick={enrollOktaVerifyFactor}
            role="button"
            tabIndex={0}
          >
            Refresh Code
          </div>
        )}
      </Step.Container>
    </Card>
  );
}
