import * as app from '@packages/redux/modules/app';

import * as api from 'js/common/services/api';

// Action Types
const SET_GROUP_STATUS = 'nds/groupStatus/SET_GROUP_STATUS';

const initialState = {};

// Reducer
export default function groupStatusReducer(state = initialState, action) {
  switch (action.type) {
    case SET_GROUP_STATUS: {
      const newState = {
        ...state,
        groupStatus: action.payload,
      };
      return newState;
    }
    default:
      return state;
  }
}

const getGroupStatusData = (state) => {
  return state.nds.groupStatus;
};

// Selectors
export const getGroupStatus = (state) => getGroupStatusData(state) || {};

// Action Creators
const setGroupStatus = (payload) => ({
  type: SET_GROUP_STATUS,
  payload,
});
export { setGroupStatus };

export const loadGroupStatus = () => (dispatch, getState) => {
  const groupId = app.getActiveGroupId(getState());
  return api.nds.groupStatus.getGroupStatus(groupId).then((response) => {
    return dispatch(setGroupStatus(response));
  });
};
