import { CloudProvider } from '@packages/types/nds/provider';
import { TenantEndpoint } from '@packages/types/nds/serverlessPrivateLink';

import { getPrivateEndpointsForServerlessProject } from 'js/common/services/api/nds/serverlessPrivateEndpointApi';

export interface ServerlessPrivateEndpointState {
  awsServerlessPrivateEndpoints: Array<TenantEndpoint>;
  azureServerlessPrivateEndpoints: Array<TenantEndpoint>;
}

enum ServerlessPrivateEndpointActionTypes {
  UPDATE_AWS_SERVERLESS_PRIVATE_ENDPOINTS = '/nds/serverlessPrivateEndpoint/UPDATE_AWS_SERVERLESS_PRIVATE_ENDPOINTS',
  UPDATE_AZURE_SERVERLESS_PRIVATE_ENDPOINTS = '/nds/serverlessPrivateEndpoint/UPDATE_AZURE_SERVERLESS_PRIVATE_ENDPOINTS',
}

interface ServerlessPrivateEndpointAction {
  type: ServerlessPrivateEndpointActionTypes;
  payload: Array<TenantEndpoint>;
}

const initialState = {
  awsServerlessPrivateEndpoints: [],
  azureServerlessPrivateEndpoints: [],
};

export default function serverlessPrivateEndpointReducer(
  state: ServerlessPrivateEndpointState = initialState,
  action: ServerlessPrivateEndpointAction
): ServerlessPrivateEndpointState {
  switch (action.type) {
    case ServerlessPrivateEndpointActionTypes.UPDATE_AWS_SERVERLESS_PRIVATE_ENDPOINTS:
      return {
        ...state,
        awsServerlessPrivateEndpoints: action.payload as Array<TenantEndpoint>,
      };
    case ServerlessPrivateEndpointActionTypes.UPDATE_AZURE_SERVERLESS_PRIVATE_ENDPOINTS:
      return {
        ...state,
        azureServerlessPrivateEndpoints: action.payload as Array<TenantEndpoint>,
      };
    default:
      return state;
  }
}

const getServerlessPrivateEndpointData = (state): ServerlessPrivateEndpointState => state.nds.serverlessPrivateEndpoint;

// selectors
export const getAWSServerlessPrivateEndpoints = (state): Array<TenantEndpoint> =>
  getServerlessPrivateEndpointData(state).awsServerlessPrivateEndpoints || [];

export const getAzureServerlessPrivateEndpoints = (state): Array<TenantEndpoint> =>
  getServerlessPrivateEndpointData(state).azureServerlessPrivateEndpoints || [];

// action creators
const updateAWSServerlessPrivateEndpoints = (payload: Array<TenantEndpoint>) => ({
  type: ServerlessPrivateEndpointActionTypes.UPDATE_AWS_SERVERLESS_PRIVATE_ENDPOINTS,
  payload,
});

const updateAzureServerlessPrivateEndpoints = (payload: Array<TenantEndpoint>) => ({
  type: ServerlessPrivateEndpointActionTypes.UPDATE_AZURE_SERVERLESS_PRIVATE_ENDPOINTS,
  payload,
});

export const loadAWSServerlessPrivateEndpoints = (groupId) => async (dispatch) => {
  const privateEndpoints = await getPrivateEndpointsForServerlessProject(groupId, CloudProvider.AWS);
  dispatch(updateAWSServerlessPrivateEndpoints(privateEndpoints));
};

export const loadAzureServerlessPrivateEndpoints = (groupId) => async (dispatch) => {
  const privateEndpoints = await getPrivateEndpointsForServerlessProject(groupId, CloudProvider.AZURE);
  dispatch(updateAzureServerlessPrivateEndpoints(privateEndpoints));
};
