import * as React from 'react';
import { Component } from 'react';

import Portal from '@leafygreen-ui/portal';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const KeyCodes = {
  Escape: 27,
} as const;

const ModalFooter = ({ children }: { children: React.ReactNode }) => (
  <footer className="view-modal-footer">
    <hr />
    <div className="view-modal-actions">
      <div />
      <div>{children}</div>
    </div>
  </footer>
);

ModalFooter.propTypes = {
  children: PropTypes.node.isRequired,
};

export type ModalSize = 'xxs' | 'xs' | 'small' | 'medium' | 'large' | 'larger' | 'xlarge';

export interface ModalProps {
  open: boolean;
  children?: React.ReactNode;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  size?: ModalSize;
  hideClose: boolean;
  onClose: () => unknown;
  onBack?: () => unknown;
  customHeaderClasses: string;
  customHeaderTitleClasses: string;
  customModalClass: string;
  usePortal: boolean;
  // Has className as optional prop so this can be styled with styled-components
  className?: string;
  ['data-testid']?: string;
}

class Modal extends Component<ModalProps> {
  static ModalFooter = ModalFooter;

  /**
   * Given a props object this will pluck all known Modal props from it.
   * Useful for Modal derivatives.
   */
  static getModalProps<Obj extends { [Key in keyof ModalProps]?: ModalProps[Key] }>(
    props: Obj
  ): Pick<Obj, Exclude<keyof ModalProps & keyof Obj, 'children'>> {
    const modalProperties = Object.keys(Modal.propTypes) as Array<keyof typeof Modal.propTypes>;
    return modalProperties.reduce(
      (acc, name) => {
        if (name === 'children') {
          return acc;
        }

        acc[name] = props[name];
        return acc;
      },
      // NOTE(JeT): Casting to any makes this one function not internally typesafe, but it should be fine.
      {} as any
    );
  }

  static propTypes = {
    open: PropTypes.bool,
    title: PropTypes.node,
    subtitle: PropTypes.node,
    children: PropTypes.node,
    size: PropTypes.oneOf(['xxs', 'xs', 'small', 'medium', 'large', 'larger', 'xlarge']),
    hideClose: PropTypes.bool,
    onClose: PropTypes.func,
    onBack: PropTypes.func,
    customHeaderClasses: PropTypes.string,
    customHeaderTitleClasses: PropTypes.string,
    usePortal: PropTypes.bool,
    customModalClass: PropTypes.string,
    'data-testid': PropTypes.string,
  };

  static defaultProps = {
    open: true,
    children: undefined,
    title: undefined,
    subtitle: undefined,
    size: undefined,
    hideClose: false,
    onClose: () => {},
    onBack: undefined,
    customHeaderClasses: '',
    customHeaderTitleClasses: '',
    customModalClass: '',
    usePortal: false,
  };

  componentDidMount() {
    const { open } = this.props;
    if (open) {
      this.addGlobalHandlers();
    }
  }

  componentDidUpdate(prevProps: ModalProps) {
    const { open } = this.props;
    if (prevProps.open !== open) {
      if (open) {
        this.addGlobalHandlers();
      } else {
        this.removeGlobalHandlers();
      }
    }
  }

  componentWillUnmount() {
    this.removeGlobalHandlers();
  }

  listeningForMouseUp = false;

  scrollWrapperRef = React.createRef<HTMLDivElement>();

  overlayRef = React.createRef<HTMLDivElement>();

  getScrollWrapperRef = () => this.scrollWrapperRef;

  onDocumentMouseEvent = (e: React.MouseEvent) => {
    const { onClose } = this.props;

    if (!this.scrollWrapperRef.current || !this.overlayRef.current) {
      this.listeningForMouseUp = false;
      return;
    }

    const clickedElement = e.target as Element;
    const isMouseDown = e.type === 'mousedown';

    //clientWidth does not include scrollbar - scrollbar clicked doesn't register as mouseDown
    if (e.clientX >= clickedElement.clientWidth) {
      return;
    }

    // Only close the modal if we're catching a click on the scroll wrapper itself, not the contents of the modal.
    if (clickedElement === this.scrollWrapperRef.current || clickedElement === this.overlayRef.current) {
      if (isMouseDown) {
        this.listeningForMouseUp = true;
        return;
      }

      if (this.listeningForMouseUp) {
        onClose();
      }
    }
    this.listeningForMouseUp = false;
  };

  onDocumentKeydown = (e: KeyboardEvent) => {
    const { open, onClose } = this.props;
    if (open && e.keyCode === KeyCodes.Escape) {
      onClose();
    }
  };

  addGlobalHandlers() {
    // Create a global event handler. This is used to listen for when a user clicks
    // anywhere on the page, so we can close the dropdown if it is open.
    // Allows for better UX.
    document.addEventListener('keydown', this.onDocumentKeydown, true);
  }

  removeGlobalHandlers() {
    document.removeEventListener('keydown', this.onDocumentKeydown, true);
  }

  render() {
    const {
      open,
      title,
      subtitle,
      children,
      hideClose,
      onClose,
      onBack,
      size,
      customHeaderClasses,
      customHeaderTitleClasses,
      customModalClass,
      usePortal,
      className,
      'data-testid': dataTestId,
    } = this.props;

    if (!open) {
      return null;
    }

    const element = (
      <div className={className} data-testid={dataTestId}>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div
          className="view-modal-overlay"
          onMouseDown={this.onDocumentMouseEvent}
          onMouseUp={this.onDocumentMouseEvent}
          ref={this.overlayRef}
        />
        <div className="view-modal-layout">
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <div
            className="view-modal-scroll-wrapper"
            onMouseDown={this.onDocumentMouseEvent}
            onMouseUp={this.onDocumentMouseEvent}
            ref={this.scrollWrapperRef}
          >
            <div
              tabIndex={-1}
              className={classNames('view-modal-content', customModalClass, {
                [`view-modal-content-is-${size}`]: size,
              })}
            >
              {hideClose || (
                <button
                  type="button"
                  name="close"
                  className="view-modal-close close"
                  onClick={onClose}
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  ×
                </button>
              )}
              {title && (
                <header className={`view-modal-header ${customHeaderClasses}`}>
                  {onBack && (
                    <a className="view-modal-back view-modal-back-is-high" tabIndex={0} role="button" onClick={onBack}>
                      <i className="mms-icon-back" /> Back
                    </a>
                  )}
                  <h3 className={`view-modal-header-title ${customHeaderTitleClasses}`}>{title}</h3>
                  <h4 className="view-modal-header-subtitle">{subtitle}</h4>
                </header>
              )}
              <div className="view-modal-body">{children}</div>
            </div>
          </div>
        </div>
      </div>
    );

    if (usePortal) {
      return <Portal>{element}</Portal>;
    }
    return element;
  }
}

export default Modal;
