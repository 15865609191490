import { FromLocation } from '@packages/types/routes';

import { CloudTeams } from 'js/common/utils/bugsnag';

interface ClusterBuilderArgs {
  clusterName?: string;
  query?: string;
}

export const PageNames = {
  CLUSTER_BUILDER: 'Cluster Builder',
  CLUSTER_DETAIL: 'Cluster Detail',
  CLUSTER_BACKUP: 'Backup',
  CLUSTER_CARD: 'Cluster Card',
  CLUSTER_CONNECT_MODAL: 'Connection Modal',
  CLUSTER_ATLAS_SEARCH: 'Atlas Search',
  CLUSTER_CMD_LINE_TOOLS: 'Command Line Tools',
  CLUSTER_DB_ACCESS_HISTORY: 'DB Access History',
  CLUSTER_STARTER_TEMPLATES: 'Cluster Starter Templates',
  PERSONALIZATION_WIZARD: 'Personalization Wizard',
  QUICK_ACCESS_SETUP: 'Quick Access Setup',
  NETWORK_ACCESS: 'Network Access',
  ORGANIZATIONS_LINK_MARKETPLACE: 'Organizations - Link Marketplace',
  CREATE_ORGANIZATION: 'Create Organization',
  ONLINE_ARCHIVE: 'Online Archive',
  PAYMENT: 'Payment',
  PROJECT_SETTINGS: 'Project Settings',
  ORGANIZATION_SETTINGS: 'Organization Settings',
  BILLING: 'Billing',
  DATA_EXPLORER: 'Data Explorer',
  METRICS: 'Metrics',
  PERFORMANCE_ADVISOR: 'Performance Advisor',
  PROFILER: 'Profiler',
  CHARTS: 'Charts',
  UAD_CREATE: 'Universal Add Data - Create Dataset',
  UAD_CREATE_ATLAS: 'Universal Add Data - Create Database on Atlas',
  UAD_CREATE_APPLICATION: 'Universal Add Data - Create via Application',
  UAD_CREATE_SHELL: 'Universal Add Data - Create via MongoShell',
  UAD_LOAD_SAMPLE_DATA: 'Universal Add Data - Load Sample Data',
  UAD_MIGRATE: 'Universal Add Data - Migrate Database',
  UAD_MIGRATE_COMMAND: 'Universal Add Data - Binary Import and Export Tools',
  UAD_IMPORT: 'Universal Add Data - Import File',
  UAD_IMPORT_COMMAND: 'Universal Add Data - Import via Command Line',
  UAD_IMPORT_SHELL: 'Universal Add Data - Import via Mongo Shell',
  DATABASE_USERS: 'Database Users',
  DATABASE_ROLES: 'Database Roles',
  NETWORK_ACCESS_LIST: 'Network Access List',
  NETWORK_PEERING: 'Network Peering',
  NETWORK_PRIVATE_ENDPOINT: 'Network Private Endpoint',
  DATA_PROTECTION_CLUSTERS: 'Clusters',
  SECURITY_ADVANCED: 'Security Advanced',
  SECURITY_QUICKSTART: 'Security Quickstart',
  DATA_MODELING_TEMPLATES: 'Data Modeling Templates',
  ATLAS_SEARCH_LEFT_NAV: 'Atlas Search - Left Nav',
  PERSONALIZATION_WIZARD_MOBILE_INTENT: 'Personalization Wizard Mobile Intent',
  SUPPORT_PLANS: 'Support Plans',
  HOME_CENTER: 'Atlas Home Center',
  SAMPLE_DATA_SELECTOR: 'Sample Data Selector',
} as const;

export const FromLocationTrackingContexts: { [Path in FromLocation]: string } = {
  [FromLocation.UPGRADE_CTA]: 'Upgrade CTAs',
  [FromLocation.EMAIL_CTA]: 'Cluster Builder from Manage Project Alerts',
  [FromLocation.CART_EMAIL]: 'Cart Resurrection Email',
  [FromLocation.CLUSTER_HEADER_CTA]: 'Cluster Card Page',
  [FromLocation.DEFAULT]: 'Cluster Builder',
  [FromLocation.SUPPORT]: 'Support',
  [FromLocation.SUPPORT_MATRIX]: 'Support Matrix',
  [FromLocation.NETWORK_PRIVATE_ENDPOINT]: 'Network Private Endpoint',
  [FromLocation.STARTER_TEMPLATES]: 'Starter Templates',
};

export const Routes = {
  PersonalizationWizard: () => 'setup/personalization',
  PersonalizationWizardMobileIntent: () => 'setup/personalization/mobile',
  QuickAccessSetup: ({ includeToast = false }: { includeToast?: boolean } = {}) =>
    `setup/access${includeToast ? '?includeToast=true' : ''}`,
  ClusterCard: ({ fastPoll = false }: { fastPoll?: boolean } = {}) => `clusters${fastPoll ? '?fastPoll=true' : ''}`,
  ClusterDetail: () => 'clusters/detail',
  ServerlessClusterDetail: (clusterName) => `serverless/detail/${clusterName}`,
  ClusterBuilder: (args?: ClusterBuilderArgs) => {
    if (args) {
      const { clusterName, query } = args;
      return `clusters/edit${clusterName ? `/${clusterName}` : ''}${query ? `?${query}` : ''}`;
    }
    return 'clusters/edit';
  },
  ClusterAtlasSearch: () => 'clusters/atlasSearch',
  ClusterBackup: () => 'clusters/backup',
  ClusterConnectModal: () => 'clusters/connect',
  ClusterCmdLineTools: () => 'clusters/commandLineTools',
  ClusterDBAccessHistory: () => 'clusters/dbAccessHistory',
  StarterTemplates: () => 'clusters/starterTemplates',
  UseCaseBasedTemplates: () => 'clusters/useCaseBasedTemplates',
  Charts: () => 'charts',
  NTMCInterstitial: () => 'inviteNewUsers',
  DatabaseAccess: () => 'security/database',
  NetworkAccess: () => 'security/network',
  ProjectSettings: () => 'settings/groupSettings',
  ClusterOnlineArchive: () => 'clusters/onlineArchive',
  UniversalAddData: () => 'addData',
  UniversalAddDataBuildCluster: () => 'addData/buildCluster',
  UniversalAddDataCreate: (activeClusterName) => `addData/${activeClusterName}/create`,
  UniversalAddDataCreateOnAtlas: (activeClusterName) => `addData/${activeClusterName}/create/atlas`,
  UniversalAddDataCreateOnApplication: (activeClusterName) => `addData/${activeClusterName}/create/application`,
  UniversalAddDataCreateOnShell: (activeClusterName) => `addData/${activeClusterName}/create/shell`,
  UniversalAddDataLoad: (activeClusterName) => `addData/${activeClusterName}/load`,
  UniversalAddDataMigrate: (activeClusterName) => `addData/${activeClusterName}/migrate`,
  UniversalAddDataMigrateCommandLine: (activeClusterName) => `addData/${activeClusterName}/migrate/commandLine`,
  UniversalAddDataImport: (activeClusterName) => `addData/${activeClusterName}/import`,
  UniversalAddDataImportViaCommandLine: (activeClusterName) => `addData/${activeClusterName}/import/commandLine`,
  UniversalAddDataImportViaShell: (activeClusterName) => `addData/${activeClusterName}/import/shell`,
  DataModelingTemplates: (clusterName) => `dataModeling/${clusterName}/examples`,
  SearchCompoundQueryGuide: (clusterName) => `clusters/atlasSearch/${clusterName}/compoundQueryGuide`,
  HomeCenter: () => 'overview',
  Activity: () => 'activity',
  DataApi: () => 'dataAPI',
  AutoSetup: () => 'clusters/autoSetup',
  SampleDataSelector: (clusterName, query?: Record<string, string>) => {
    const defaultRoute = `clusters/sampleDataSelector/${clusterName}`;
    const queryString = new URLSearchParams(query).toString();
    if (queryString) return `${defaultRoute}?${queryString}`;
    return defaultRoute;
  },
};

export const PARTNER_CUSTOMER_LINK_NOTIFY_TEAM = CloudTeams.AtlasGrowth;

export const LOAD_SAMPLE_CLUSTER_BANNER_OPEN = 'loadSampleDataClusterBannerOpen';
