// Password validity dictated by https://jira.mongodb.org/browse/DEVOPS-206
const supportedPasswordCharacters = '~!@#$%^&*()-_=+[]{}|;:,\\.<>/?';

export { supportedPasswordCharacters };

const validations = [
  function allFieldsPresent(fields, nameMapper = {}) {
    const fieldsMissing = Object.keys(fields).reduce((acc, fieldName) => {
      const value = fields[fieldName];
      if (!value && fieldName !== 'groupname' && fieldName !== 'orgname' && fieldName !== 'tos') {
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
        acc.push(fieldName);
      }
      return acc;
    }, []);

    const mappedFieldNames = fieldsMissing.map((fieldName) => {
      return nameMapper[fieldName] || fieldName;
    });

    return {
      valid: fieldsMissing.length === 0,
      message: `${mappedFieldNames.join(', ')} must not be blank.`,
    };
  },

  function tosValidation(fields, nameMapper = {}) {
    if (fields.tos !== undefined) {
      return {
        valid: fields.tos,
        message: `You must check ${(nameMapper as any).tos}.`,
      };
    }

    return {
      valid: true,
    };
  },
];

export const validateFields = function validateFields(fields, nameMapper = {}) {
  return validations.reduce((acc, validationFunction) => {
    const validationResult = validationFunction(fields, nameMapper);
    if (validationResult && !validationResult.valid) {
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      return acc.concat(validationResult);
    }
    return acc;
  }, []);
};
