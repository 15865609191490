import fetchWrapper from '../fetchWrapper';

export default {
  getLiveImports(groupId) {
    return fetchWrapper(`/admin/nds/liveImports/${groupId}`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },
  getRecentLiveImports() {
    return fetchWrapper(`/admin/nds/liveImports/recent`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },
};
