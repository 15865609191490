import Cookies from 'js-cookie';

export enum COOKIE_KEYS {
  SEGMENT = 'segmentSessionId',
  DOT_COM = '__session.data',
  OPTIMIZELY_USER_ID = 'optimizelyEndUserId',
  CLEARBIT_DATA = 'clearbitData',
}

export function getDotComCookieProperties(): object {
  let dotComCookieProperties = {};

  // Parse .com cookie for additional values to send to Segment and Eloqua
  const dotComCookie = Cookies.get(COOKIE_KEYS.DOT_COM);

  if (dotComCookie) {
    try {
      dotComCookieProperties = JSON.parse(dotComCookie);
    } catch (e) {
      // ignore parsing error
    }
  }

  return dotComCookieProperties;
}
