import { FormEvent, useEffect, useState } from 'react';

import { css } from '@emotion/react';
import Banner from '@leafygreen-ui/banner';
import { palette } from '@leafygreen-ui/palette';
import { Option, Select, Size } from '@leafygreen-ui/select';
import { Link } from '@leafygreen-ui/typography';
import { APIKey } from 'baas-admin-sdk';
import queryString from 'query-string';
import _ from 'underscore';

import { Search } from '@packages/types/auth/search';
import { CloudProvider, Providers } from '@packages/types/nds/provider';
import { Region, RegionNames } from '@packages/types/nds/region';
import { ReplicationSpec } from '@packages/types/nds/replicationSpec';
import { VercelProject } from '@packages/types/vercel';

import DataApiLoader from '@packages/components/DataApiLoader';
import { DataApiToggle } from '@packages/components/DataApiToggle';
import {
  ThirdPartyIntegrationBodyLabel,
  ThirdPartyIntegrationDescription,
  ThirdPartyIntegrationLabel,
} from '@packages/components/ThirdPartyIntegration/styles/common';
import ThirdPartyIntegrationLayout from '@packages/components/ThirdPartyIntegration/ThirdPartyIntegrationLayout';
import ThirdPartyIntegrationPage from '@packages/components/ThirdPartyIntegration/ThirdPartyIntegrationPage';
import VercelProjectsToClusterLinker from '@packages/components/Vercel/VercelProjectsToClusterLinker';

import { useAppUserParams } from 'js/common/context/AppUserContext';
import { useRequestParams } from 'js/common/context/RequestParamsContext';
import clusterDescriptionsApi from 'js/common/services/api/nds/clusterDescriptionsApi';
import clusterFormApi from 'js/common/services/api/nds/clusterFormApi';
import organizationApi from 'js/common/services/api/organizationApi';
import { CloudTeams, sendError } from 'js/common/utils/bugsnag';
import deepClone from 'js/common/utils/deepClone';
import analytics, { SEGMENT_EVENTS } from 'js/common/utils/segmentAnalytics';

import { linkingErrorStyle } from 'js/auth/components/styles/thirdPartyIntegration';
//hook
import { useDataApiConnector } from 'js/project/dataAPI/hooks/useDataApiConnector';
import clusterTrackingUtil from 'js/project/nds/clusters/util/clusterTrackingUtil';
import { DefaultTemplates } from 'js/project/nds/types/defaultTemplate';

// images
import clusterImg from './images/cluster.svg';

const spacedFormSectionStyle = css`
  margin-bottom: 24px;
  &:last-of-type {
    margin-bottom: 40px;
  }
`;

const clusterDescriptionStyle = css`
  font-weight: bold;
  margin-top: 4px;
`;

const clusterDescriptionSectionStyle = css`
  margin-top: 8px;
`;

const clusterDescriptionImgStyle = css`
  float: left;
  margin-right: 16px;
  margin-top: 12px;
`;

/**
 * The query params in this page sometimes include username twice from Vercel so handle the different
 * possible cases in this method to extract a single username value
 * @param username input unsanitized username query param value
 * @returns the sanitized username value
 */
export const sanitizeUsername = (username: Array<string> | string | undefined) => {
  if (username === undefined) return username;
  if (Array.isArray(username)) return username[0];
  return username.split(',')[0];
};

interface Props {
  location?: Pick<Location, 'search'>;
  windowLocation?: Pick<Location, 'assign'>;
  clusterName?: string;
}

export default function VercelClusterCreationPage({
  location: { search } = { search: '' },
  windowLocation = window.location,
}: Props) {
  const [providerOptions, setProviderOptions] = useState<Providers | {}>({});
  const [defaultTemplates, setDefaultTemplates] = useState<DefaultTemplates | {}>({});
  const [selectedRegionKey, setSelectedRegionKey] = useState('US_EAST_1');
  const [selectedVercelProjects, setSelectedVercelProjects] = useState<Array<VercelProject>>([]);
  const [hasError, setHasError] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [readyForClusterLinking, setReadyForClusterLinking] = useState(false);
  const [dataApiKey, setDataApiKey] = useState<APIKey | null>(null);
  const [urlSlug, setUrlSlug] = useState('');
  const { groupId, vercelUserId, orgId, username, next, clusterName } = queryString.parse(search) as Search;
  const { centralUrl, baasCentralUrl } = useRequestParams();
  const { loadAppUserParams, appUserParams } = useAppUserParams();
  const uniqueClusterName = clusterName || 'Cluster0';
  const [submissionInProgress, setSubmissionInProgress] = useState(false);
  const [clusterCreationDone, setClusterCreationDone] = useState(false);

  // TODO: if user has any edge projects (edgeConfigId !== null) then this will be true (CLOUDP-148660)
  const dataApiForceEnabled = false;
  // TODO: initial state is based on the forcing logic to be done in CLOUDP-148660
  const [isDataAPIToggleOn, setIsDataAPIToggleOn] = useState<boolean>(dataApiForceEnabled);
  const [selectedCluster, setSelectedCluster] = useState<string>(uniqueClusterName);

  const redirectToVercel = () => {
    const vercelURI = decodeURI(next as string);
    if (new URL(vercelURI).hostname === 'vercel.com') {
      windowLocation.assign(vercelURI);
    }
  };

  const setupDataApi = async ({ apiKey, urlSlug }: { apiKey: APIKey; urlSlug: string }) => {
    setDataApiKey(apiKey);
    setUrlSlug(urlSlug);
    setReadyForClusterLinking(true);
  };

  useEffect(() => {
    loadAppUserParams();
  }, []);

  useDataApiConnector({
    loadBaasApp: true,
    enableDataApiForCluster: isDataAPIToggleOn && clusterCreationDone,
    fetchDataApiInfo: ({ apiKey, urlSlug }: { apiKey: APIKey; urlSlug: string }) => setupDataApi({ apiKey, urlSlug }),
    initConfig: {
      groupId,
      orgId,
      // This page sometimes has username twice in the query params and so requires sanitization
      username: sanitizeUsername(username),
      baasUrl: baasCentralUrl,
    },
    uniqueClusterName: selectedCluster,
    showModalOnError: true,
  });

  useEffect(() => {
    async function populateProviderData(): Promise<void> {
      if (typeof groupId !== 'string') {
        return;
      }

      try {
        const getProviderOptions: Promise<Providers> = clusterFormApi.getProviderOptions(groupId, centralUrl);
        const getDefaultTemplates: Promise<
          Pick<DefaultTemplates, CloudProvider.AWS | CloudProvider.GCP | CloudProvider.AZURE>
        > = clusterFormApi.getDefaultTemplates(groupId, centralUrl);

        const [options, defaultTemplates] = await Promise.all([getProviderOptions, getDefaultTemplates]);
        setProviderOptions(options);
        setDefaultTemplates(defaultTemplates);
      } catch (error) {
        sendError({ error, team: CloudTeams.CoreIam });
      }
    }

    populateProviderData();
  }, [groupId, setProviderOptions, setDefaultTemplates]);

  const generateDisplayName = ({ provider, location, name }) => {
    return `${provider} / ${location} (${name})`;
  };

  const generateNewClusterDescription = () => {
    // From the selectedRegion, get the region information and use it to select the provider template to generate the clusterDescription
    const selectedRegionData: Region | undefined = _.find(providerOptions[CloudProvider.FREE].regions, (region) => {
      return region.key === selectedRegionKey;
    });

    const selectedCloudProvider = selectedRegionData?.provider;
    const selectedProviderTemplates = defaultTemplates[selectedCloudProvider as string];
    // default values for 'm0' cluster for the selected provider
    const originalClusterDescription = selectedProviderTemplates['m0'];

    const existingReplicationSpec: ReplicationSpec = originalClusterDescription.replicationSpecList[0];
    const newReplicationSpec: ReplicationSpec = deepClone(existingReplicationSpec);

    // updates the name of the region in originalClusterDescription to the value that is currently selected
    newReplicationSpec.regionConfigs[0].regionName = RegionNames[selectedRegionKey];
    newReplicationSpec.regionConfigs[0].regionView = deepClone(selectedRegionData);

    const clusterDescriptionToBeSent = deepClone(originalClusterDescription);
    clusterDescriptionToBeSent['replicationSpecList'] = [newReplicationSpec];
    return clusterDescriptionToBeSent;
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setIsSubmitDisabled(true);
    setSubmissionInProgress(true);
  };

  useEffect(
    function createVercelCluster() {
      (async () => {
        // don't start cluster creation if the user hasn't confirmed yet
        if (!submissionInProgress) return;

        const clusterDescriptionJson = generateNewClusterDescription();
        clusterDescriptionJson.name = selectedCluster;
        if (groupId) {
          try {
            const { name } = await clusterDescriptionsApi.createVercelCluster({
              centralUrl,
              groupId,
              clusterDescriptionJson,
            });

            setSelectedCluster(name);
            setClusterCreationDone(true);
          } catch (error) {
            setHasError(true);
            sendError({ error, team: CloudTeams.CoreIam });
          }
        }
      })();
    },
    [submissionInProgress]
  );

  useEffect(
    function linkClusterToVercel() {
      (async () => {
        // don't start cluster linking if we're using data api and we don't have a data api key ready
        const dataApiOnAndReadyToProceed = isDataAPIToggleOn && readyForClusterLinking;
        const dataApiOffAndReadyToProceed = !isDataAPIToggleOn && clusterCreationDone;
        if (!dataApiOnAndReadyToProceed && !dataApiOffAndReadyToProceed) return;

        const clusterDescriptionJson = generateNewClusterDescription();
        if (orgId && vercelUserId && next && groupId) {
          try {
            await organizationApi.linkClusterToVercelProjects({
              centralUrl,
              orgId,
              vercelUserId,
              cluster: selectedCluster,
              vercelProjects: selectedVercelProjects,
              groupId,
              setupInitialDataApi: isDataAPIToggleOn,
              dataApiKeyName: dataApiKey?.name,
              dataApiKey: dataApiKey?.key,
              dataApiUrl: urlSlug,
            });

            const analyticsTrackProperties = {
              context: 'Vercel',
              region_autoselect: selectedRegionKey,
              user_country_code: appUserParams.country,
              data_api_toggle_status: isDataAPIToggleOn.toString(),
            };
            const basicClusterEventProperties = clusterTrackingUtil.getBasicClusterEventProperties({
              clusterDescription: clusterDescriptionJson,
            });

            analytics.track(SEGMENT_EVENTS.CLUSTER_SUBMITTED_SUCCESSFULLY, {
              ...basicClusterEventProperties,
              ...analyticsTrackProperties,
            });
            redirectToVercel();
          } catch (error) {
            setHasError(true);
            sendError({ error, team: CloudTeams.CoreIam });
          }
        }
      })();
    },
    [readyForClusterLinking, clusterCreationDone]
  );

  const sendToggleAnalytics = (val) => {
    analytics.track(SEGMENT_EVENTS.VERCEL_DATA_API_TOGGLE_STATUS, {
      context: 'Vercel Cluster Create Page',
      data_api_toggle_status: val.toString(),
    });
  };

  return (
    <ThirdPartyIntegrationPage pageTitle="Vercel Integration">
      {submissionInProgress ? (
        <DataApiLoader />
      ) : (
        <ThirdPartyIntegrationLayout
          title="Create a New Cluster with Vercel"
          isForm
          formProps={{
            onSubmit: handleSubmit,
            buttonText: 'Create New Cluster and Return to Vercel',
            disabled:
              !selectedRegionKey ||
              selectedVercelProjects.length <= 0 ||
              Object.keys(providerOptions).length === 0 ||
              isSubmitDisabled,
          }}
        >
          <div css={spacedFormSectionStyle}>
            <ThirdPartyIntegrationLabel htmlFor="database" id="database_label">
              Database Location
            </ThirdPartyIntegrationLabel>
            <ThirdPartyIntegrationDescription>
              Locate your database as close to your{' '}
              <a href="https://vercel.com/docs/serverless-functions/regions" target="_blank" rel="noreferrer">
                Vercel Serverless Function
              </a>{' '}
              region as possible. If you use Vercel&apos;s default, we recommend AWS / N. Virginia (us-east-1).
            </ThirdPartyIntegrationDescription>
            <Select
              name="database"
              size={Size.Default}
              value={selectedRegionKey}
              aria-labelledby="database_label"
              onChange={(option) => setSelectedRegionKey(option)}
              disabled={Object.keys(providerOptions).length === 0}
            >
              {providerOptions[CloudProvider.FREE] &&
                providerOptions[CloudProvider.FREE].regions.map(({ provider, location, name, key }) => {
                  return (
                    <Option value={key} key={key}>
                      {generateDisplayName({ provider, location, name })}
                    </Option>
                  );
                })}
            </Select>
          </div>
          <div css={spacedFormSectionStyle}>
            <ThirdPartyIntegrationBodyLabel>Your Cluster</ThirdPartyIntegrationBodyLabel>
            <ThirdPartyIntegrationDescription>
              You will be using our M0 Sandbox cluster. You can easily scale this cluster at any time in the future by{' '}
              <a href="https://docs.atlas.mongodb.com/scale-cluster/" target="_blank" rel="noreferrer">
                modifying the cluster
              </a>
              .
            </ThirdPartyIntegrationDescription>
            <div css={clusterDescriptionSectionStyle}>
              <img src={clusterImg} alt="cloud" css={clusterDescriptionImgStyle} />
              <ThirdPartyIntegrationBodyLabel>M0 Sandbox</ThirdPartyIntegrationBodyLabel>
              <ThirdPartyIntegrationDescription css={clusterDescriptionStyle}>
                (Shared RAM, 512 MB storage)
              </ThirdPartyIntegrationDescription>
            </div>
          </div>
          <div css={spacedFormSectionStyle}>
            <VercelProjectsToClusterLinker
              orgId={orgId}
              centralUrl={centralUrl}
              clusterNames={[uniqueClusterName]}
              defaultClusterName={uniqueClusterName}
              onChangeVercelProjects={setSelectedVercelProjects}
              hasLinkingError={hasError}
              isClusterDisabled
            />
          </div>
          <DataApiToggle
            toggled={isDataAPIToggleOn}
            onToggle={(val: boolean) => {
              setIsDataAPIToggleOn(val);
              sendToggleAnalytics(val);
            }}
            dataApiForceEnabled={dataApiForceEnabled}
          />
          {hasError ? (
            <Banner variant="danger" css={linkingErrorStyle}>
              The integration is set up successfully. However, an error happened with either creating a cluster or
              attempting to link the Atlas cluster to Vercel projects. Please visit the{' '}
              <Link href={`${centralUrl}/v2#/org/${orgId}/integrations`}>Organization&apos;s Integrations</Link> page to
              try again. Click <Link onClick={redirectToVercel}>here</Link> to close this window and return to Vercel.
            </Banner>
          ) : (
            <ThirdPartyIntegrationDescription css={{ color: palette.gray.dark3, marginBottom: '-24px' }}>
              A confirmation email will be sent to you with more detailed information regarding your new Atlas project,
              Atlas Cluster, and Vercel integration.
            </ThirdPartyIntegrationDescription>
          )}
        </ThirdPartyIntegrationLayout>
      )}
    </ThirdPartyIntegrationPage>
  );
}
