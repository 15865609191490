import { useState } from 'react';

import { css } from '@emotion/react';
import Checkbox from '@leafygreen-ui/checkbox';
import { palette } from '@leafygreen-ui/palette';
import TextInput from '@leafygreen-ui/text-input';

const checkboxSectionStyle = css`
  margin: 8px 0 24px;
`;

const smallDescriptionStyle = css`
  color: ${palette.gray.dark1};
  font-size: 11px;
  line-height: 20px;

  a {
    color: ${palette.blue.base};
    text-decoration: none;
  }
`;

const textInputStyle = css`
  label {
    color: ${palette.black};
    line-height: 20px;
    font-weight: 600;
    font-size: 13px;
    padding-bottom: 0px;
  }

  p {
    color: ${palette.gray.dark3};
    font-size: 11px;
    line-height: 20px;
    letter-spacing: 0.2px;
  }
`;

export default function IPAccessListField({ onSelectCheckbox }: { onSelectCheckbox: () => void }) {
  const [isCheckboxSelected, setIsCheckboxSelected] = useState(false);

  return (
    <>
      <TextInput
        label="IP Access List"
        description="Open inbound access for the database clusters within this Atlas project to all IP addresses (0.0.0.0/0)"
        disabled
        value="0.0.0.0/0"
        type="text"
        name="ip-access-list"
        css={textInputStyle}
      />
      <div css={checkboxSectionStyle}>
        <Checkbox
          checked={isCheckboxSelected}
          onChange={(e) => {
            setIsCheckboxSelected(e.target.checked);
            onSelectCheckbox();
          }}
          label={
            <span css={smallDescriptionStyle}>
              I understand that this is necessary for my Vercel deployment,{' '}
              <a
                href="https://vercel.com/support/articles/how-to-allowlist-deployment-ip-address"
                target="_blank"
                rel="noreferrer"
              >
                which uses dynamic IP addresses
              </a>
              , to connect to Atlas.
            </span>
          }
          data-testid="accept-checkbox"
        />
      </div>
    </>
  );
}
