import * as React from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { useRequestParams } from 'js/common/context/RequestParamsContext';
import { supportedPasswordCharacters } from 'js/common/services/validationService';
import { mq } from 'js/common/utils/mediaQueries';

import { ErrorMessage } from 'js/auth/components/styles/form';
import { onChangeTypeDef } from 'js/auth/types/formInput';
import colors from 'js/auth/utils/palette';

import FormInput from './FormInput';

const Text = styled.ul(() =>
  mq({
    boxSizing: 'border-box',
    border: `solid ${colors.validPasswordBoxBorder} 1px`,
    width: ['300px', 'calc(100vw - 32px)', '300px', '300px'],
    marginTop: '16px',
    backgroundColor: colors.validPasswordBoxBackground,
    padding: '16px',
    color: colors.link,

    '& li': {
      marginTop: '4px',
      marginLeft: '20px',
    },
  })
);

export interface Props {
  onChange?: onChangeTypeDef;
  value?: string;
  fieldName?: string;
  labelName?: string;
  autoComplete?: string;
  hasError?: boolean;
  errorMessage?: string;
  children?: React.ReactNode;
  forNewUser?: boolean;
  className?: string;
  tooltipText?: React.ReactNode;
  showRequirementsBox?: boolean;
  hasFullWidth?: boolean;
}

const escapeCharacterClassSpecialCharacters = (char) => {
  switch (char) {
    case '^':
      return '\\^';
    case '-':
      return '\\-';
    case ']':
      return '\\]';
    case '\\':
      return '\\\\';
    default:
      return char;
  }
};

const passwordCharactersRegExp = new RegExp(
  `[${supportedPasswordCharacters.split('').map(escapeCharacterClassSpecialCharacters).join('')}]`
);

export default function PasswordInputWithValidationCriteria({
  onChange = () => {},
  fieldName = 'password',
  labelName = 'Password',
  autoComplete = 'new-password',
  hasError = false,
  errorMessage = '',
  children,
  value = '',
  forNewUser = false,
  className,
  tooltipText,
  showRequirementsBox = true,
  hasFullWidth = false,
}: Props) {
  const { onPrem } = useRequestParams();

  const hasMinLength = value.length >= 8;

  const isValidOnPremPassword = () => {
    return (
      // has special character
      hasMinLength &&
      /[0-9]/.test(value) && // has digit
      /[a-zA-Z]/.test(value) && // has character
      passwordCharactersRegExp.test(value)
    );
  };

  return (
    <FormInput
      fieldName={fieldName}
      labelName={labelName}
      type="password"
      autoComplete={autoComplete}
      onChange={onChange}
      value={value}
      showCheckmark={onPrem ? isValidOnPremPassword() : hasMinLength}
      hasError={hasError}
      skipRegexValidation
      className={className}
      tooltipText={tooltipText}
    >
      {errorMessage && hasError && (
        <ErrorMessage data-testid="error-message" css={hasFullWidth ? css({ width: '100%' }) : css({ width: '300px' })}>
          {errorMessage}
        </ErrorMessage>
      )}
      {showRequirementsBox && !hasError && Boolean(value) && (
        <Text>
          Your password must:
          <li>
            Contain at least <b>8 characters</b>
          </li>
          {onPrem ? (
            <li>One letter, one number, and one special character</li>
          ) : (
            <>
              <li>Contain unique characters, numbers, or symbols</li>
              <li>Not contain your email address</li>
              {!forNewUser && <li>Different from your previous 4 passwords</li>}
            </>
          )}
        </Text>
      )}
      {children}
    </FormInput>
  );
}
