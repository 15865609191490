import { useEffect, useState } from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Button from '@leafygreen-ui/button';
import { palette } from '@leafygreen-ui/palette';

import { StyledButton } from '@packages/types/leafygreen-emotion';

import Accordion from '@packages/components/Accordion';
import LineDivider from '@packages/components/LineDivider';

import { user } from 'js/common/services/api';
import { mq } from 'js/common/utils/mediaQueries';

import Disable from './Disable';
import Phone from './Phone';
import Primary from './Primary';
import RecoveryCodes from './RecoveryCodes';
import { InfoText } from './styles/mfa';

const STEP1_IMG = '/static/images/mfa/step1.svg';
const STEP2_IMG = '/static/images/mfa/step2.svg';
const STEP3_IMG = '/static/images/mfa/step3.svg';
const INCOMPLETE_STEP2_IMG = '/static/images/mfa/incompleteStep2.svg';
const INCOMPLETE_STEP3_IMG = '/static/images/mfa/incompleteStep3.svg';
const COMPLETED_STEP_IMG = '/static/images/mfa/completed.svg';
const SKIPPED_STEP_IMG = '/static/images/mfa/skipped.svg';

interface ManageMFAProps {
  onAuthExpired: (error: Error) => boolean | string;
  username: string;
  siteName: string;
  hideDisableAccordion?: boolean;
  hasMultiFactorAuth?: boolean;
  phoneSupported?: boolean;
  centralUrl?: string;
  onFinish: () => void;
  multiFactorAuthLevelRequired: boolean;
}

export interface MFAConfiguration {
  phone?: string;
  voice?: boolean;
  extension?: string;
  authenticator?: boolean;
  backupPhone?: string;
  backupPhoneExtension?: string;
  updateKey?: string;
}

type OpenAccordion = '' | 'primary' | 'backup' | 'recoveryCodes' | 'disable';

interface FinishContainerProps {
  hideDisableAccordion: boolean;
}

const Container = styled.div`
  margin-top: 32px;
`;

const AccordionContainer = styled.div`
  margin-top: 24px;
`;

const HeadlineContainer = styled.div`
  display: flex;
  margin-left: -10px;
  font-size: 17px;
  color: ${palette.gray.dark2};
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
`;

const DisableContainer = styled.div`
  opacity: 0.99;
`;

const imageBaseStyles = css`
  height: 32px;
  width: 32px;
  margin-right: 20px;
`;

const PreviewText = styled.p(() =>
  mq({
    color: palette.gray.dark3,
    letterSpacing: 0,
    lineHeight: '21px',
    marginLeft: '42px',
    marginTop: ['-4px', '8px', '8px', '-4px'],
  })
);

const BackupPhoneContainer = styled.div`
  margin-left: 42px;
`;

const DisableText = styled.span`
  margin-left: 42px;
  font-size: 17px;
  color: ${palette.gray.dark2};
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
`;

const FinishButtonContainer = styled.div((props: FinishContainerProps) => ({
  display: 'flex',
  justifyContent: props.hideDisableAccordion ? 'normal' : 'flex-end',
}));

const FinishButton = styled<StyledButton>(Button)<React.ComponentProps<'button'>>(() =>
  mq({
    flex: ['none', 1, 1, 'none'],
    justifyContent: 'center',
  })
);

const StyledLineDivider = styled(LineDivider)(
  mq({
    width: ['auto', 'calc(100vw - 32px)', 'calc(100vw - 450px)', 'auto'],
    margin: '20px auto',
  })
);

export default function ManageMFA({
  onAuthExpired,
  hasMultiFactorAuth = false,
  phoneSupported = false,
  username = '',
  siteName = '',
  centralUrl = '',
  onFinish = () => {},
  multiFactorAuthLevelRequired = false,
  hideDisableAccordion = false,
}: ManageMFAProps) {
  const [openAccordion, setOpenAccordion] = useState<OpenAccordion>('');
  const [currentMfa, setCurrentMfa] = useState<MFAConfiguration>({});
  const [backupCodes, setBackupCodes] = useState<Array<String>>([]);
  const [skipBackupDevice, setSkipBackupDevice] = useState(false);
  const [ackRecoveryCodeGeneration, setAckRecoveryCodeGeneration] = useState(false);

  const { backupPhone, backupPhoneExtension, authenticator, voice, phone } = currentMfa;
  const hasCurrentMfa = authenticator || voice || phone;
  let isFinishDisabled = hideDisableAccordion;

  const onShowRecoveryCodes = async () => {
    try {
      const { backupCodes: newBackupCodes } = await user.generateBackupCodes({ centralUrl });
      setBackupCodes(newBackupCodes);
    } catch (e) {
      accordionToggles.closeAll();
      onAuthExpired(e as Error);
    }
  };

  useEffect(() => {
    if (openAccordion === 'recoveryCodes') {
      // only generate recovery codes when the Recovery Code Accordion is open
      onShowRecoveryCodes();
    }
  }, [openAccordion]);

  // accordion logic
  const accordionToggleCreator = (accordionName: OpenAccordion) => () => {
    const closeAccordion = openAccordion === accordionName;
    const newValue = closeAccordion ? '' : accordionName;
    setOpenAccordion(newValue);
  };

  const accordionToggles = {
    primary: accordionToggleCreator('primary'),
    backup: accordionToggleCreator('backup'),
    recoveryCodes: accordionToggleCreator('recoveryCodes'),
    disable: accordionToggleCreator('disable'),
    closeAll: accordionToggleCreator(''),
  };

  const isPrimaryOpen = openAccordion === 'primary';
  const isBackupOpen = openAccordion === 'backup';
  const isRecoveryCodesOpen = openAccordion === 'recoveryCodes';
  const isDisableOpen = openAccordion === 'disable';

  const updateMfaData = async () => {
    try {
      const multiFactorAuth = await user.multiFactorAuthGet({ centralUrl });
      setCurrentMfa(multiFactorAuth);
    } catch (e) {
      onAuthExpired(e as Error);
    }
  };

  useEffect(() => {
    async function fetchData() {
      await updateMfaData();
    }

    fetchData();
  }, []);

  const getIcon = (src: string, alt: string) => {
    return <img src={src} alt={alt} css={imageBaseStyles} />;
  };

  const getBackupPhoneStepIcon = () => {
    if (backupPhone && !isBackupOpen) {
      return getIcon(COMPLETED_STEP_IMG, 'Completed');
    } else if (skipBackupDevice) {
      return getIcon(SKIPPED_STEP_IMG, 'Skipped');
    } else if (!hasCurrentMfa) {
      return getIcon(INCOMPLETE_STEP2_IMG, 'Incomplete Step 2');
    } else {
      return getIcon(STEP2_IMG, 'Step 2');
    }
  };

  const getRecoveryCodesStepIcon = () => {
    if (ackRecoveryCodeGeneration) {
      return getIcon(COMPLETED_STEP_IMG, 'Completed');
    } else if (!phoneSupported) {
      if (!authenticator && !voice) {
        return getIcon(INCOMPLETE_STEP2_IMG, 'Incomplete Step 2');
      } else {
        return getIcon(STEP2_IMG, 'Step 2');
      }
    } else if (!hasCurrentMfa) {
      return getIcon(INCOMPLETE_STEP3_IMG, 'Incomplete Step 3');
    } else {
      return getIcon(STEP3_IMG, 'Step 3');
    }
  };

  if (currentMfa.authenticator || currentMfa.voice || currentMfa.phone) {
    if (hasMultiFactorAuth || ackRecoveryCodeGeneration) {
      isFinishDisabled = false;
    }
  } else if (multiFactorAuthLevelRequired) {
    isFinishDisabled = true;
  }

  return (
    <Container>
      <Accordion
        active={isPrimaryOpen}
        onHeadlineClick={accordionToggles.primary}
        headline={
          <HeadlineContainer>
            {hasCurrentMfa ? getIcon(COMPLETED_STEP_IMG, 'Completed') : getIcon(STEP1_IMG, 'Step 1')}
            <span data-testid="choosePrimaryMethod">Choose Primary Method</span>
          </HeadlineContainer>
        }
        preview={
          hasCurrentMfa ? (
            <PreviewText>
              You are currently using {!authenticator && 'the '}
              <b>{authenticator ? 'Google Authenticator' : 'Voice/SMS number'}</b>
              {phone && ` (${phone})`}
            </PreviewText>
          ) : (
            <PreviewText>
              Two-factor authentication is currently <b>disabled</b>.
            </PreviewText>
          )
        }
        isOnWhite
        data-testid="primaryAccordion"
      >
        <Primary
          active={isPrimaryOpen}
          currentMfa={currentMfa}
          updateMfaData={updateMfaData}
          onAuthExpired={onAuthExpired}
          username={username}
          hasMultiFactorAuth={hasMultiFactorAuth}
          phoneSupported={phoneSupported}
          centralUrl={centralUrl}
          onSuccess={accordionToggles.primary}
        />
      </Accordion>
      {phoneSupported && (
        <AccordionContainer>
          <Accordion
            active={isBackupOpen}
            onHeadlineClick={accordionToggles.backup}
            headline={
              <HeadlineContainer>
                {getBackupPhoneStepIcon()}
                <span data-testid="addABackupPhone">Add a Backup Phone</span>
              </HeadlineContainer>
            }
            preview={
              hasCurrentMfa ? (
                backupPhone ? (
                  <PreviewText>
                    You are currently using the <b>backup Voice/SMS number</b> ({backupPhone}
                    {backupPhoneExtension && `+${backupPhoneExtension}`})
                  </PreviewText>
                ) : (
                  <PreviewText>You do not have any backup device enabled.</PreviewText>
                )
              ) : null
            }
            disabled={!hasCurrentMfa}
            isOnWhite
            data-testid="backupAccordion"
          >
            <BackupPhoneContainer>
              {currentMfa.backupPhone ? (
                <Button
                  type="button"
                  name="removeBackupPhone"
                  onClick={async () => {
                    try {
                      await user.multiFactorBackupAuthDelete();
                      await updateMfaData();
                      accordionToggles.closeAll();
                    } catch (e) {
                      onAuthExpired(e as Error);
                    }
                  }}
                  variant="danger"
                >
                  Remove Backup Phone
                </Button>
              ) : (
                <>
                  <InfoText>
                    Setting up a backup phone number is a good idea in the event that your primary method of
                    authentication is unavailable, and it is recommended that all users have a backup in place. If
                    you’ve misplaced your phone, it&apos;s out of battery or damaged, we can send authentication codes
                    to your backup phone number.
                  </InfoText>
                  <Phone
                    active={isBackupOpen}
                    updateMfaData={updateMfaData}
                    onAuthExpired={onAuthExpired}
                    onSuccess={accordionToggles.closeAll}
                    onCancel={() => {
                      accordionToggles.backup();
                      setSkipBackupDevice(true);
                    }}
                    centralUrl={centralUrl}
                    setupBackupPhone
                  />
                </>
              )}
            </BackupPhoneContainer>
          </Accordion>
        </AccordionContainer>
      )}
      <AccordionContainer>
        <Accordion
          active={isRecoveryCodesOpen}
          onHeadlineClick={accordionToggles.recoveryCodes}
          headline={
            <HeadlineContainer>
              {getRecoveryCodesStepIcon()}
              <span data-testid="generateRecoveryCodes">Generate Recovery Codes</span>
            </HeadlineContainer>
          }
          disabled={!hasCurrentMfa}
          isOnWhite
          data-testid="recoveryAccordion"
        >
          <RecoveryCodes
            backupCodes={backupCodes}
            siteName={siteName}
            onAcknowledge={() => {
              accordionToggles.recoveryCodes();
              setAckRecoveryCodeGeneration(true);
            }}
          />
        </Accordion>
      </AccordionContainer>
      {!hideDisableAccordion && !multiFactorAuthLevelRequired && hasCurrentMfa && (
        <DisableContainer>
          <StyledLineDivider>or</StyledLineDivider>
          <AccordionContainer>
            <Accordion
              active={isDisableOpen}
              onHeadlineClick={accordionToggles.disable}
              headline={
                <>
                  <DisableText>Disable 2FA</DisableText>
                </>
              }
              isOnWhite
              data-testid="disableAccordion"
            >
              <Disable
                active={isDisableOpen}
                onDisableMfa={async () => {
                  await updateMfaData();
                  setAckRecoveryCodeGeneration(false);
                  setSkipBackupDevice(false);
                  accordionToggles.primary();
                }}
                onCancel={accordionToggles.closeAll}
                onAuthExpired={onAuthExpired}
              />
            </Accordion>
          </AccordionContainer>
        </DisableContainer>
      )}
      <FinishButtonContainer hideDisableAccordion={hideDisableAccordion}>
        <FinishButton
          type="button"
          variant="primary"
          name="finish"
          size="large"
          disabled={isFinishDisabled}
          onClick={onFinish}
          className="e2e-finish"
        >
          Finish
        </FinishButton>
      </FinishButtonContainer>
    </Container>
  );
}
