import { Region } from '@packages/types/nds/region';
import { StreamInstance, UserInterest } from '@packages/types/nds/streams';

import fetchWrapper from '../fetchWrapper';

export default {
  hasRegisteredInterest(groupId: string): Promise<boolean> {
    return fetchWrapper(`/nds/streams/${groupId}/interest`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  registerInterest(groupId: string, interest: UserInterest): Promise<void> {
    return fetchWrapper(`/nds/streams/${groupId}/interest`, {
      method: 'POST',
      body: JSON.stringify(interest),
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(_.noop);
  },

  getStreamInstances(groupId: string): Promise<Array<StreamInstance>> {
    return fetchWrapper(`/nds/streams/${groupId}/tenants`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  saveStreamInstance(groupId: string, streamInstance: Partial<StreamInstance>): Promise<StreamInstance> {
    return fetchWrapper(`/nds/streams/${groupId}/tenants`, {
      method: 'POST',
      body: JSON.stringify(streamInstance),
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((resp) => resp.json());
  },

  getStreamAvailableRegions(groupId: string): Promise<Array<Region>> {
    return fetchWrapper(`/nds/streams/${groupId}/regions`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  createDefaultStreamInstance(groupId: string): Promise<StreamInstance> {
    return fetchWrapper(`/nds/streams/${groupId}/tenants/default`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((resp) => resp.json());
  },
};
