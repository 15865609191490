import { Dispatch } from 'redux';

import api from 'js/common/services/api/nds/maintenanceWindowsApi';

import { MaintenanceWindow, TargetMongoDBVersionsMap } from 'js/project/nds/types/projectMaintenance';

export enum NDSMaintenanceWindowActionTypes {
  FetchMaintenanceWindow = '/nds/maintenanceWindow/FETCH',
  FetchTargetMongoDBVersions = '/nds/maintenanceWindow/targetMongoDBVersions/FETCH',
  PatchMaintenanceWindow = '/nds/maintenanceWindow/PATCH',
  DeferMaintenanceOneWeek = '/nds/maintenanceWindow/defer/POST',
  ResetCustomMaintenanceWindow = '/nds/maintenanceWindow/DELETE',
}

interface State {
  nds: {
    projectMaintenance: ProjectMaintenanceState;
  };
}

interface ProjectMaintenanceState {
  maintenanceWindow: MaintenanceWindow | null;
  targetMongoDBVersions: TargetMongoDBVersionsMap;
}

const defaultState: ProjectMaintenanceState = {
  maintenanceWindow: null,
  targetMongoDBVersions: {},
};

export default function maintenanceWindowReducer(state: ProjectMaintenanceState = defaultState, action) {
  switch (action.type) {
    case NDSMaintenanceWindowActionTypes.FetchMaintenanceWindow: {
      return {
        ...state,
        maintenanceWindow: action.payload,
      };
    }
    case NDSMaintenanceWindowActionTypes.FetchTargetMongoDBVersions: {
      return {
        ...state,
        targetMongoDBVersions: action.payload,
      };
    }
    case NDSMaintenanceWindowActionTypes.PatchMaintenanceWindow: {
      return {
        ...state,
        maintenanceWindow: action.payload,
      };
    }
    case NDSMaintenanceWindowActionTypes.DeferMaintenanceOneWeek: {
      return {
        ...state,
        maintenanceWindow: action.payload,
      };
    }
    case NDSMaintenanceWindowActionTypes.ResetCustomMaintenanceWindow: {
      return {
        ...state,
        maintenanceWindow: action.payload,
      };
    }
    default:
      return state;
  }
}

export const getMaintenanceWindow = (state: State): MaintenanceWindow | null => {
  return state.nds.projectMaintenance.maintenanceWindow;
};
export const getTargetMongoDBVersions = (state: State): TargetMongoDBVersionsMap => {
  return state.nds.projectMaintenance.targetMongoDBVersions;
};

export const fetchMaintenanceWindow = (groupId: string) => (dispatch: Dispatch) =>
  api.getMaintenanceWindow(groupId).then((resp) => {
    return dispatch({
      type: NDSMaintenanceWindowActionTypes.FetchMaintenanceWindow,
      payload: resp,
    });
  });

export const fetchTargetMongoDBVersions = (groupId: string) => (dispatch: Dispatch) =>
  api.getTargetMongoDBVersions(groupId).then((resp) => {
    return dispatch({
      type: NDSMaintenanceWindowActionTypes.FetchTargetMongoDBVersions,
      payload: resp,
    });
  });

export const patchMaintenanceWindow = (groupId: string, maintenanceWindow: MaintenanceWindow) => (dispatch: Dispatch) =>
  api.patchMaintenanceWindow(groupId, maintenanceWindow).then((resp) => {
    return dispatch({
      type: NDSMaintenanceWindowActionTypes.PatchMaintenanceWindow,
      payload: resp,
    });
  });

export const resetCustomMaintenanceWindow = (groupId: string) => (dispatch: Dispatch) =>
  api.resetCustomMaintenanceWindow(groupId).then((resp) => {
    return dispatch({
      type: NDSMaintenanceWindowActionTypes.ResetCustomMaintenanceWindow,
      payload: resp,
    });
  });

export const deferMaintenanceOneWeek = (groupId: string) => (dispatch: Dispatch) =>
  api.deferMaintenanceOneWeek(groupId).then((resp) => {
    return dispatch({
      type: NDSMaintenanceWindowActionTypes.DeferMaintenanceOneWeek,
      payload: resp,
    });
  });
