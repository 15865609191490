import { AWSPrivateLink, AzurePrivateLink, GCPPrivateServiceConnect } from '@packages/types/nds/privateLink';

import {
  fetchAWSPrivateLinks,
  fetchAzurePrivateLinks,
  fetchGCPPSCs,
} from 'js/common/services/api/nds/privateEndpointApi';

export interface PrivateEndpointState {
  awsPrivateLinks: Array<AWSPrivateLink>;
  azurePrivateLinks: Array<AzurePrivateLink>;
  gcpPSCs: Array<GCPPrivateServiceConnect>;
}

enum PrivateLinkActionTypes {
  UPDATE_AWS_PRIVATE_LINKS = '/nds/privateEndpoint/UPDATE_AWS_PRIVATE_LINKS',
  UPDATE_AZURE_PRIVATE_LINKS = '/nds/privateEndpoint/UPDATE_AZURE_PRIVATE_LINKS',
  UPDATE_GCP_PSC = '/nds/privateEndpoint/UPDATE_GCP_PSC',
}

interface PrivateLinkAction {
  type: PrivateLinkActionTypes;
  payload: Array<AzurePrivateLink> | Array<AWSPrivateLink> | Array<GCPPrivateServiceConnect>;
}

const initialState = {
  awsPrivateLinks: [],
  azurePrivateLinks: [],
  gcpPSCs: [],
};

// reducers
export default function privateEndpointReducer(
  state: PrivateEndpointState = initialState,
  action: PrivateLinkAction
): PrivateEndpointState {
  switch (action.type) {
    case PrivateLinkActionTypes.UPDATE_AZURE_PRIVATE_LINKS:
      return {
        ...state,
        azurePrivateLinks: action.payload as Array<AzurePrivateLink>,
      };
    case PrivateLinkActionTypes.UPDATE_AWS_PRIVATE_LINKS:
      return {
        ...state,
        awsPrivateLinks: action.payload as Array<AWSPrivateLink>,
      };
    case PrivateLinkActionTypes.UPDATE_GCP_PSC:
      return {
        ...state,
        gcpPSCs: action.payload as Array<GCPPrivateServiceConnect>,
      };
    default:
      return state;
  }
}

const getPrivateEndpointData = (state): PrivateEndpointState => state.nds.privateEndpoint;

// selectors
export const getAWSPrivateLinks = (state): Array<AWSPrivateLink> => getPrivateEndpointData(state).awsPrivateLinks || [];
export const getAzurePrivateLinks = (state): Array<AzurePrivateLink> =>
  getPrivateEndpointData(state).azurePrivateLinks || [];
export const getGCPPSCs = (state): Array<GCPPrivateServiceConnect> => getPrivateEndpointData(state).gcpPSCs || [];

// Action Creators
const updateAWSPrivateLinks = (payload: Array<AWSPrivateLink>) => ({
  type: PrivateLinkActionTypes.UPDATE_AWS_PRIVATE_LINKS,
  payload,
});

const updateAzurePrivateLinks = (payload: Array<AzurePrivateLink>) => ({
  type: PrivateLinkActionTypes.UPDATE_AZURE_PRIVATE_LINKS,
  payload,
});

const updateGCPPSCs = (payload: Array<GCPPrivateServiceConnect>) => ({
  type: PrivateLinkActionTypes.UPDATE_GCP_PSC,
  payload,
});

export const loadAWSPrivateLinks = (groupId) => async (dispatch) => {
  const privateLinks = await fetchAWSPrivateLinks(groupId);
  dispatch(updateAWSPrivateLinks(privateLinks));
};

export const loadAzurePrivateLinks = (groupId) => async (dispatch) => {
  const privateLinks = await fetchAzurePrivateLinks(groupId);
  dispatch(updateAzurePrivateLinks(privateLinks));
};

export const loadGCPPSCs = (groupId) => async (dispatch) => {
  const pscs = await fetchGCPPSCs(groupId);
  dispatch(updateGCPPSCs(pscs));
};
