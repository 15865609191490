import { ClusterOutageSimulation, CreateClusterOutageSimulation } from '@packages/types/nds/clusterOutageSimulation';

import fetchWrapper from '../fetchWrapper';

export default {
  findClusterOutage(groupId: string, clusterName: string): Promise<ClusterOutageSimulation> {
    return fetchWrapper(`/nds/clusters/${groupId}/${clusterName}/outage-simulation`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  createClusterOutage(
    groupId: string,
    clusterName: string,
    clusterOutage: CreateClusterOutageSimulation
  ): Promise<ClusterOutageSimulation> {
    return fetchWrapper(`/nds/clusters/${groupId}/${clusterName}/outage-simulation`, {
      method: 'POST',
      body: JSON.stringify(clusterOutage),
    }).then((resp) => resp.json());
  },

  requestEndClusterOutage(groupId: string, clusterName: string): Promise<ClusterOutageSimulation> {
    return fetchWrapper(`/nds/clusters/${groupId}/${clusterName}/outage-simulation`, {
      method: 'DELETE',
    }).then((resp) => resp.json());
  },
};
