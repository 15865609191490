import { useEffect, useRef } from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Banner, { Variant as BannerVariant } from '@leafygreen-ui/banner';
import Button from '@leafygreen-ui/button';
import Tooltip from '@leafygreen-ui/tooltip';
import { H2 } from '@leafygreen-ui/typography';
import queryString from 'query-string';
import { Helmet } from 'react-helmet';
import { Link, RouteComponentProps } from 'react-router-dom';

import { FieldNames } from '@packages/types/auth/field';
import { Search } from '@packages/types/auth/search';
import { SocialAuthMethods } from '@packages/types/authMethod';

import LineDivider from '@packages/components/LineDivider';

import * as api from 'js/common/services/api';
import * as errorHelper from 'js/common/services/authErrorHelper';
import { useRequestParams } from 'js/common/context/RequestParamsContext';
import { getMarketplaceRegistrationCookie } from 'js/common/utils/billingHelpers';
import { countries } from 'js/common/utils/countries';
import { jobFunctions } from 'js/common/utils/jobFunction';
import { mq, useViewportSize } from 'js/common/utils/mediaQueries';

import { alertBanner } from 'js/auth/components/styles/alert';
import { bannerStyle } from 'js/auth/components/styles/banner';
import useOktaSession from 'js/auth/hooks/useOktaSession';
import useRegistrationAnalytics from 'js/auth/hooks/useRegistrationAnalytics';
import useRegistrationReducer from 'js/auth/hooks/useRegistrationReducer';
import { RequestData } from 'js/auth/types/registration';
import { getSignUpSource, SignupMethod } from 'js/auth/utils/analyticsUtils';
import colors from 'js/auth/utils/palette';
import { fieldValidationMap, isUserInputValid, onRegisterUser } from 'js/auth/utils/registrationUtils';

import FormDropdown from './FormDropdown';
import FormInput from './FormInput';
import HeaderLogo from './HeaderLogo';
import InviteBanner from './InviteBanner';
import Layout from './Layout';
import PasswordInputWithValidationCriteria from './PasswordInputWithValidationCriteria';
import ReCaptcha, { ReCaptchaHandles } from './ReCaptcha';
import SocialAuthButton from './SocialAuthButton';
import { Container, ErrorMessage, LoginAndRegistrationHeader, Subheader, submitButton } from './styles/form';
import TermsOfServiceCheckbox from './TermsOfServiceCheckbox';

const marketplaceLink = css({
  color: colors.link,
});

const CheckboxWrapper = styled('div')(
  mq({
    width: ['250px', 'auto', '250px', '250px'],
  })
);

// This is a workaround because disabled buttons cannot
// have pointer events (therefore no tooltips)
const disabledButtonWrapper = css(
  mq({
    marginTop: [48, 48, 48, 48],
    display: 'inline-block',
  })
);

const submitButtonWithoutTopMargins = css(
  mq(submitButton, {
    marginTop: [0, 0, 0, 0],
  })
);

const StyledLineDivider = styled(LineDivider)(
  mq({
    width: ['300px', 'calc(100vw - 32px)', '300px', '300px'], // FormInput width
    margin: '20px 0',
  })
);

interface RegistrationPageProps {
  location?: Pick<Location, 'search'>;
  windowLocation?: Pick<Location, 'assign' | 'href'>;
  match?: RouteComponentProps;
}

export default function RegistrationPage({
  location: { search } = { search: '' },
  windowLocation = window.location,
  match: { params } = { params: '' },
}: RegistrationPageProps) {
  const requestParams = useRequestParams();
  const {
    onPrem,
    hasUsers,
    reCaptchaEnabledRegistration,
    isGoogleAuthEnabled,
    isGithubAuthEnabled,
    userRegistrationEnabled,
    userRegistrationDisabledRedirectUrl,
    registrationExtraFields,
    registrationDefaultCountry,
  } = requestParams;

  const parsedSearchLocation = queryString.parse(search) as Search;
  const {
    inviteToken,
    activationCode,
    groupname,
    orgname,
    username: invitedUsername,
    fromURI,
    reason,
  } = parsedSearchLocation;

  const isAtlasCLI = params.flow === 'cli';
  const clientState = {
    ...parsedSearchLocation,
    ...(isAtlasCLI && {
      isAtlasCLI: true,
      nRegister: '/account/connect',
      n: '/account/register/success',
    }),
  };

  const [
    {
      formFields,
      errorMessage,
      captchaError,
      errorField,
      formSubmitted,
      isCaptchaBoxChecked,
      inviteTokenInvalid,
      activationCodeInvalid,
      isSocialSignupDisabled,
      isRetiredUsername,
    },
    dispatch,
  ] = useRegistrationReducer({
    country:
      registrationExtraFields.includes(FieldNames.COUNTRY) && registrationDefaultCountry
        ? registrationDefaultCountry
        : '',
    username: inviteToken && invitedUsername ? invitedUsername : '',
  });

  const { username, password, firstName, lastName, company, tos, honeypot, phoneNumber, jobResponsibility, country } =
    formFields;

  const isInviteFlow = inviteToken !== undefined && inviteToken !== '';
  const isSmallViewport = useViewportSize('small');

  const marketplaceRegistrationCookie = getMarketplaceRegistrationCookie();
  const marketplaceType = marketplaceRegistrationCookie?.partnerType;
  const analyticsSignupSource = getSignUpSource(marketplaceType, false, isAtlasCLI);

  const reCaptchaRef = useRef<ReCaptchaHandles>(null);

  useOktaSession(dispatch);
  useRegistrationAnalytics({ analyticsSignupSource, marketplaceSource: marketplaceType });

  useEffect(() => {
    async function verifyActivationCode() {
      try {
        await api.billing.getAvailableActivationCode({ activationCode });
      } catch ({ errorCode }) {
        if (userRegistrationDisabledRedirectUrl) {
          windowLocation.href = userRegistrationDisabledRedirectUrl;
        } else {
          dispatch({ type: 'setInvalidActivationCode' });
        }
      }
    }

    async function verifyInvite() {
      try {
        await api.user.inviteGet({ username: invitedUsername, inviteToken });
      } catch ({ errorCode }) {
        if (userRegistrationDisabledRedirectUrl) {
          windowLocation.href = userRegistrationDisabledRedirectUrl;
        } else {
          dispatch({ type: 'setInvalidInviteToken' });
        }
      }
    }

    if (!userRegistrationEnabled) {
      // When self-serve registration is off, users should bear an invite token or activation code in order
      // to register. In case they do not (or if the codes are invalid), and the app environment requires
      // the user to be navigated away otherwise (e.g.: FedRAMP), redirect based on the conf property value
      if (activationCode) {
        verifyActivationCode();
      } else if (inviteToken) {
        verifyInvite();
      } else if (userRegistrationDisabledRedirectUrl) {
        windowLocation.href = userRegistrationDisabledRedirectUrl;
      }
    }
  }, [
    userRegistrationEnabled,
    userRegistrationDisabledRedirectUrl,
    invitedUsername,
    inviteToken,
    activationCode,
    windowLocation,
    dispatch,
  ]);

  const generateClientState = (): RequestData['clientState'] => {
    const defaultClientState = {
      // Keep incoming queryParams
      ...parsedSearchLocation,
      n: '/account/register/success',
      firstName,
      fromURI,
      signupSource: analyticsSignupSource,
      signupMethod: SignupMethod.FORM,
    };

    if (registrationExtraFields.includes(FieldNames.COMPANY)) {
      defaultClientState[FieldNames.COMPANY] = company;
    }

    if (isAtlasCLI) {
      return {
        ...defaultClientState,
        isAtlasCLI: true,
        nRegister: '/account/connect',
      };
    }
    return defaultClientState;
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();
    onRegisterUser({
      dispatch,
      clientState: generateClientState(),
      requestParams,
      searchObject: parsedSearchLocation,
      reCaptchaRef,
      windowLocation,
      registrationFormFields: formFields,
    });
  };

  const isSubmitDisabled =
    (!userRegistrationEnabled && !(activationCode ?? inviteToken)) ||
    inviteTokenInvalid ||
    activationCodeInvalid ||
    formSubmitted ||
    (reCaptchaEnabledRegistration && !isCaptchaBoxChecked) ||
    !(
      isUserInputValid(FieldNames.USERNAME, username) &&
      isUserInputValid(FieldNames.FIRST_NAME, firstName) &&
      isUserInputValid(FieldNames.LAST_NAME, lastName) &&
      password.length > 0 &&
      // for on-prem company is not shown, also company field is now optional
      (!registrationExtraFields.includes(FieldNames.COMPANY) ||
        company !== null ||
        isUserInputValid(FieldNames.COMPANY, company)) &&
      // for on-prem jobResponsibility is not shown
      (!registrationExtraFields.includes(FieldNames.JOB_RESPONSIBILITY) || jobResponsibility.length > 0) &&
      // for on-prem country is not shown
      (!registrationExtraFields.includes(FieldNames.COUNTRY) || country.length > 0) &&
      // for on-prem phone number is not shown
      (!registrationExtraFields.includes(FieldNames.PHONE_NUMBER) ||
        isUserInputValid(FieldNames.PHONE_NUMBER, phoneNumber)) &&
      // for on-prem only first user sees tos
      (onPrem && hasUsers ? true : tos) &&
      errorMessage.length === 0
    );

  // Set search to connect page to cover customers who click "Log in now"
  if (isAtlasCLI) {
    search = '?n=/account/connect';
  }

  const isSocialAuthEnabled = isGoogleAuthEnabled || isGithubAuthEnabled;

  const extraFieldsMap = {
    phoneNumber: () => (
      <FormInput
        key="phoneNumber"
        fieldName={FieldNames.PHONE_NUMBER}
        labelName="Phone Number"
        type="tel"
        autoComplete="tel"
        onChange={(e) =>
          dispatch({
            type: 'field',
            payload: { field: FieldNames.PHONE_NUMBER, value: e.target.value },
          })
        }
        value={phoneNumber}
        regexValidation={fieldValidationMap[FieldNames.PHONE_NUMBER]}
        hasError={errorField === FieldNames.PHONE_NUMBER}
        errorMessage={errorMessage}
      />
    ),
    company: () => (
      <FormInput
        key="company"
        fieldName={FieldNames.COMPANY}
        labelName="Company Name"
        autoComplete="organization"
        hasError={errorField === FieldNames.COMPANY}
        errorMessage={errorMessage}
        onChange={(e) =>
          dispatch({
            type: 'field',
            payload: { field: FieldNames.COMPANY, value: e.target.value },
          })
        }
        value={company}
        optional
      />
    ),
    jobResponsibility: () => (
      <FormDropdown
        key="jobResponsibility"
        fieldName={FieldNames.JOB_RESPONSIBILITY}
        labelName="Job Function"
        onChange={(e) =>
          dispatch({
            type: 'field',
            payload: { field: FieldNames.JOB_RESPONSIBILITY, value: e.target.value },
          })
        }
        value={jobResponsibility}
        options={jobFunctions.map((job) => ({ value: job }))}
        defaultOption="None Selected"
      />
    ),
    country: () => (
      <FormDropdown
        key="country"
        fieldName={FieldNames.COUNTRY}
        labelName="Country"
        onChange={(e) =>
          dispatch({
            type: 'field',
            payload: { field: FieldNames.COUNTRY, value: e.target.value },
          })
        }
        value={country}
        options={Object.keys(countries).map((countryCode) => ({
          value: countryCode,
          displayText: countries[countryCode],
        }))}
        defaultOption="Select Country"
      />
    ),
  };

  const extraFields = registrationExtraFields
    .filter((extraField) => extraFieldsMap[extraField] != null)
    .map((extraField) => extraFieldsMap[extraField]());

  return (
    <>
      <Helmet title="Create Account" />
      <Layout contentPlacement="right">
        <Container onSubmit={onFormSubmit} method="post" noValidate={onPrem}>
          <InviteBanner token={inviteToken} username={username} isFor="registration" retryInviteCheck={formSubmitted} />
          {reason && (
            <Banner variant={BannerVariant.Danger} data-testid="bannerErrorCode" css={bannerStyle}>
              {errorHelper.getErrorMessageFromCode(reason)}
            </Banner>
          )}

          <HeaderLogo />
          {activationCode && (
            <Banner css={alertBanner} data-testid="bannerActivationCode">
              You&apos;re about to redeem your activation code. It will automatically be applied to your new
              organization.
            </Banner>
          )}
          {marketplaceRegistrationCookie && (
            <Banner variant={BannerVariant.Info} css={bannerStyle} data-testid="bannerMpRegistration">
              Create a MongoDB account to finish linking your {marketplaceRegistrationCookie.partnerType} account. If
              you already have an account,{' '}
              <Link to="/login" css={marketplaceLink}>
                log in
              </Link>{' '}
              now.
            </Banner>
          )}
          <H2 css={LoginAndRegistrationHeader}>Create your account</H2>
          <Subheader>
            Have an account?
            <Link to={!onPrem ? `/login${search}` : '/login'}>&nbsp;Log in now</Link>
          </Subheader>
          {isGoogleAuthEnabled && (
            <>
              <SocialAuthButton
                clientState={clientState}
                disabled={isSocialSignupDisabled}
                provider={SocialAuthMethods.GOOGLE}
                location={location}
                data-testid="googleAuthButton"
              />
            </>
          )}
          {isGithubAuthEnabled && (
            <>
              <SocialAuthButton
                clientState={clientState}
                disabled={isSocialSignupDisabled}
                provider={SocialAuthMethods.GITHUB}
                location={location}
                data-testid="githubAuthButton"
              />
            </>
          )}
          {isSocialAuthEnabled && <StyledLineDivider>Or with email and password</StyledLineDivider>}
          {isRetiredUsername && (
            <Banner variant={BannerVariant.Danger} css={bannerStyle} data-testid="registrationErrorBanner">
              <strong>This email address cannot be used to create a MongoDB account. </strong>
              <br />
              Sign up for a new account with a different email address.
            </Banner>
          )}
          <FormInput
            fieldName="emailAddress"
            labelName={onPrem ? 'Username' : 'Email Address'}
            description="We recommend using your work email"
            autoComplete="username"
            type="email"
            onChange={(e) =>
              dispatch({
                type: 'field',
                payload: { field: FieldNames.USERNAME, value: e.target.value },
              })
            }
            value={username}
            regexValidation={fieldValidationMap[FieldNames.USERNAME]}
            hasError={errorField === FieldNames.USERNAME}
            errorMessage={errorMessage}
            disabled={isInviteFlow}
          />
          <FormInput
            fieldName={FieldNames.FIRST_NAME}
            labelName="First Name"
            autoComplete="given-name"
            onChange={(e) =>
              dispatch({
                type: 'field',
                payload: { field: FieldNames.FIRST_NAME, value: e.target.value },
              })
            }
            value={firstName}
            regexValidation={fieldValidationMap[FieldNames.FIRST_NAME]}
            hasError={errorField === FieldNames.FIRST_NAME}
            errorMessage={errorMessage}
          />
          <FormInput
            fieldName={FieldNames.LAST_NAME}
            labelName="Last Name"
            autoComplete="family-name"
            onChange={(e) =>
              dispatch({
                type: 'field',
                payload: { field: FieldNames.LAST_NAME, value: e.target.value },
              })
            }
            value={lastName}
            regexValidation={fieldValidationMap[FieldNames.LAST_NAME]}
            hasError={errorField === FieldNames.LAST_NAME}
            errorMessage={errorMessage}
          />
          <PasswordInputWithValidationCriteria
            onChange={(e) =>
              dispatch({
                type: 'field',
                payload: { field: FieldNames.PASSWORD, value: e.target.value },
              })
            }
            value={password}
            hasError={errorField === FieldNames.PASSWORD}
            errorMessage={errorMessage}
            forNewUser
          />

          {/* Extra Registration Fields that vary by environment: Company, Country, Phone Number and Job Responsibility */}
          {extraFields}

          {/* the request to create a user is rejected by Backend if this field is filled in */}
          <FormInput
            fieldName="registrationComment"
            labelName="Comment"
            value={honeypot as string}
            onChange={(e) =>
              dispatch({
                type: 'field',
                payload: { field: FieldNames.HONEYPOT, value: e.target.value },
              })
            }
            optional
            hidden
          />
          {groupname && (
            <FormInput labelName="Project Name" fieldName="groupName" value={groupname as string} disabled />
          )}
          {orgname && (
            <FormInput labelName="Organization Name" fieldName="orgName" value={orgname as string} disabled />
          )}

          {!onPrem && (
            <CheckboxWrapper>
              <TermsOfServiceCheckbox
                checked={tos}
                onChange={(e) =>
                  dispatch({
                    type: 'field',
                    payload: { field: FieldNames.TOS, value: e.target.checked },
                  })
                }
              />
            </CheckboxWrapper>
          )}
          {reCaptchaEnabledRegistration && (
            <>
              <ReCaptcha
                ref={reCaptchaRef}
                callback={(token) =>
                  dispatch({
                    type: 'setCaptchaBoxChecked',
                    payload: token.length > 0,
                  })
                }
                action="register"
              />
              {captchaError && <ErrorMessage data-testid="error-message">{captchaError}</ErrorMessage>}
            </>
          )}
          {onPrem && !hasUsers && (
            <TermsOfServiceCheckbox
              checked={tos}
              variant="onprem"
              onChange={(e) =>
                dispatch({
                  type: 'field',
                  payload: { field: FieldNames.TOS, value: e.target.checked },
                })
              }
            />
          )}
          <footer>
            {errorMessage && !errorField && (
              <ErrorMessage data-testid="registration-error-message">{errorMessage}</ErrorMessage>
            )}
            {userRegistrationEnabled || inviteToken || activationCode ? (
              <Button variant="primary" disabled={isSubmitDisabled} size="default" css={submitButton} type="submit">
                Sign up
              </Button>
            ) : (
              <Tooltip
                justify={isSmallViewport ? 'middle' : 'start'}
                trigger={
                  <div css={disabledButtonWrapper}>
                    <Button variant="primary" disabled css={submitButtonWithoutTopMargins} size="default" type="submit">
                      Sign up
                    </Button>
                  </div>
                }
                triggerEvent="hover"
                id="submitTooltip"
              >
                User registration is currently disabled. Please contact your administrator for an invitation.
              </Tooltip>
            )}
          </footer>
        </Container>
      </Layout>
    </>
  );
}
