import { css } from '@emotion/react';
import Card from '@leafygreen-ui/card';
import Icon from '@leafygreen-ui/icon';
import { Body } from '@leafygreen-ui/typography';

import { OktaMfaFactor } from '@packages/types/accountMultiFactorAuth';
import { Search } from '@packages/types/auth/search';

import VerifySelectedFactor, {
  VerifySelectedFactorResponse,
} from '@packages/components/MultiFactorAuth/VerifySelectedFactor';

// common styles
import { baseStyle } from 'js/common/styles/multiFactorAuth';

const textStyles = {
  lostMfa: css({
    marginTop: '20px',
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'pre-wrap',
  }),
  contactUs: css(baseStyle.clickableLink, {
    display: 'flex',
    alignItems: 'center',
  }),
  needMoreHelp: css({
    marginTop: '20px',
    alignSelf: 'start',
  }),
};

interface VerifyFactorCardProps {
  availableFactors: Array<OktaMfaFactor>;
  clientState?: Search;
  onSuccess: (response?: VerifySelectedFactorResponse) => void;
  isDeleteFlow?: boolean;
  isVercelIntegration?: boolean;
}

export default function VerifyFactorCard({
  availableFactors,
  clientState = {},
  onSuccess,
  isDeleteFlow,
  isVercelIntegration = false,
}: VerifyFactorCardProps) {
  const renderSelectedFactorAndBody = () => {
    return (
      <>
        <VerifySelectedFactor
          availableFactors={availableFactors}
          clientState={clientState}
          onSuccess={onSuccess}
          isDeleteFlow={isDeleteFlow}
          isVercelIntegration
        />
        <Body css={textStyles.lostMfa}>
          Lost your MFA method?{' '}
          <a href="https://www.mongodb.com/contact" css={textStyles.contactUs}>
            Contact us
            <Icon glyph="OpenNewTab" size={22} />
          </a>
        </Body>
      </>
    );
  };

  return (
    <>
      {isVercelIntegration ? (
        renderSelectedFactorAndBody()
      ) : (
        <>
          <Card css={baseStyle.body}>{renderSelectedFactorAndBody()}</Card>
          {/* TODO: CLOUDP-65142 - Update this link once documentation is ready */}
          <Body css={textStyles.needMoreHelp}>
            Need more help? View documentation on{' '}
            <a
              href="https://docs.atlas.mongodb.com/security-multi-factor-authentication/"
              css={baseStyle.clickableLink}
            >
              managing your multi-factor authentication options
            </a>
          </Body>
        </>
      )}
    </>
  );
}
