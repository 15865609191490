import fetchWrapper from '../fetchWrapper';

export function getOrganizationFreeTrialMapping(orgagnizationId: string) {
  return fetchWrapper(`/nds/freeTrialMappings/${orgagnizationId}`).then((resp) => resp.json());
}

/** admin API to fetch the full list of org/promo mapping objects
 * (only works for users with the GLOBAL_READ_ONLY role, don't use for anything customer-facing)
 */
export function getAllFreeTrialMappings() {
  return fetchWrapper(`/nds/freeTrialMappings/admin`).then((resp) => resp.json());
}

/** admin API to generate free trial mapping objects
 * (only works for users with the GLOBAL_READ_ONLY role, don't use for anything customer-facing)
 */
export function generateFreeTrialMappings() {
  return fetchWrapper(`/nds/freeTrialMappings/admin`, { method: 'POST' }).then((resp) => resp.json());
}
