import { useEffect, useState } from 'react';

import styled from '@emotion/styled';
import { Combobox, ComboboxOption } from '@leafygreen-ui/combobox';
import Icon from '@leafygreen-ui/icon';
import { palette } from '@leafygreen-ui/palette';

import { VercelProject } from '@packages/types/vercel';

import {
  ThirdPartyIntegrationBodyLabel,
  ThirdPartyIntegrationDescription,
} from '@packages/components/ThirdPartyIntegration/styles/common';

import { organization } from 'js/common/services/api';
import { CloudTeams, sendError } from 'js/common/utils/bugsnag';

export interface VercelProjectView extends VercelProject {
  isLinked: boolean;
}

const LinkerContainer = styled.div`
  margin-top: 24px;
`;

const ComboboxContainer = styled.div`
  display: flex;
  margin-top: 16px;

  label {
    color: ${palette.gray.dark3};
    line-height: 20px;
  }

  div[role='combobox'] {
    min-width: 141px;
    width: 216px;
  }

  div[data-leafygreen-ui='interaction-ring'] {
    right: 24px;
  }
`;

const ArrowContainer = styled.div`
  display: flex;
  margin: 32px 12px;
`;

export default function VercelProjectsToClusterLinker({
  orgId = '',
  selectedProjectName = '',
  centralUrl = '',
  clusterNames = [],
  defaultClusterName = '',
  isClusterDisabled = false,
  onChangeCluster = () => {},
  onChangeVercelProjects = () => {},
  defaultVercelProjectsIds = [],
  hasLinkingError = false,
  isConfigureFlow = false,
  setHasLinkingError = () => {},
}: {
  orgId?: string;
  selectedProjectName?: string;
  centralUrl?: string;
  clusterNames: Array<string>;
  defaultClusterName?: string;
  isClusterDisabled?: boolean;
  onChangeCluster?: (clusterName: string) => void;
  onChangeVercelProjects?: (projects: Array<VercelProject>) => void;
  defaultVercelProjectsIds?: Array<string>;
  hasLinkingError?: boolean;
  isConfigureFlow?: boolean;
  setHasLinkingError?: (val: boolean) => void;
}) {
  const [vercelProjects, setVercelProjects] = useState<Array<VercelProjectView>>([]);
  const [selectedCluster, setSelectedCluster] = useState('');
  const [selectedVercelProjectsIds, setSelectedVercelProjectsIds] = useState<Array<string>>([]);

  useEffect(() => {
    if (selectedProjectName !== '') {
      setSelectedCluster('');
      setSelectedVercelProjectsIds([]);
      onChangeCluster('');
      onChangeVercelProjects([]);
    }
  }, [selectedProjectName]);

  useEffect(() => {
    // if only 1 cluster available, select that by default
    if (!isConfigureFlow && clusterNames.length === 1) {
      const clusterName = clusterNames[0];
      setSelectedCluster(clusterName);
      onChangeCluster(clusterName);
    }
  }, [clusterNames]);

  useEffect(() => {
    async function getVercelProjects() {
      try {
        const projects: Array<VercelProjectView> = await organization.getVercelProjects({
          orgId,
          centralUrl,
        });
        setVercelProjects(projects);

        // if only 1 project in scope and it's not linked, select by default
        if (projects.length === 1 && !projects[0].isLinked) {
          const oneVercelProjectId = projects[0].id;
          setSelectedVercelProjectsIds([oneVercelProjectId]);
          onChangeVercelProjects(getProjectsWithIdsAndNames([oneVercelProjectId], projects));
        }
      } catch (error) {
        sendError({ error, team: CloudTeams.CoreIam });
      }
    }

    if (orgId !== '') {
      getVercelProjects();
    }
  }, [orgId]);

  const getProjectsWithIdsAndNames = (
    vercelProjectsIds: Array<string>,
    vercelProjectsInScope: Array<VercelProjectView>
  ) => {
    let projectsWithIdsAndNames: Array<VercelProject> = [];

    vercelProjectsInScope.forEach((vercelProject) => {
      if (vercelProjectsIds.includes(vercelProject.id)) {
        // remove 'isLinked' as it's not actual part of VercelProject object
        const { isLinked, ...projectWithoutIsLinked } = vercelProject;
        projectsWithIdsAndNames.push(projectWithoutIsLinked);
      }
    });

    return projectsWithIdsAndNames;
  };

  const onChangeSelectedProjects = (selectedVercelProjectsIds: Array<string>) => {
    setSelectedVercelProjectsIds(selectedVercelProjectsIds);
    onChangeVercelProjects(getProjectsWithIdsAndNames(selectedVercelProjectsIds, vercelProjects));
    setHasLinkingError(false);
  };

  return (
    <LinkerContainer>
      <ThirdPartyIntegrationBodyLabel>Link Clusters and Projects</ThirdPartyIntegrationBodyLabel>
      <ThirdPartyIntegrationDescription>
        Select the Atlas Clusters and Vercel Projects to link. You may add any additional mappings later on the MongoDB
        UI.
      </ThirdPartyIntegrationDescription>
      <ComboboxContainer>
        <Combobox
          label="Atlas Cluster"
          data-testid="atlasCluster"
          placeholder="Select a cluster"
          disabled={isClusterDisabled}
          clearable={!isClusterDisabled}
          value={defaultClusterName || selectedCluster}
          onChange={(cluster: string) => {
            setSelectedCluster(cluster);
            onChangeCluster(cluster);
          }}
        >
          {clusterNames.length > 0 &&
            clusterNames.map((clusterName) => (
              <ComboboxOption
                key={clusterName}
                value={clusterName}
                displayName={clusterName}
                disabled={clusterName.endsWith(' (Already Linked)')}
              />
            ))}
        </Combobox>
        <ArrowContainer>
          <Icon glyph="ArrowLeft" />
          <Icon glyph="ArrowRight" />
        </ArrowContainer>
        <Combobox
          label="Vercel Projects"
          multiselect
          placeholder="Select projects"
          onChange={onChangeSelectedProjects}
          value={defaultVercelProjectsIds.length > 0 ? defaultVercelProjectsIds : selectedVercelProjectsIds}
          chipTruncationLocation="end"
          state={hasLinkingError ? 'error' : 'none'}
          errorMessage={hasLinkingError ? 'The selected project is already linked to another Cluster.' : ''}
        >
          {vercelProjects.length > 0 &&
            vercelProjects.map(({ id, name, isLinked }) => (
              <ComboboxOption
                key={id}
                disabled={isLinked}
                value={id}
                displayName={isLinked ? `${name} (Already Linked)` : name}
              />
            ))}
        </Combobox>
      </ComboboxContainer>
    </LinkerContainer>
  );
}
