import {
  AWSPrivateLink,
  AzurePrivateLink,
  EndpointServiceCreateRequest,
  GCPPrivateServiceConnect,
} from '@packages/types/nds/privateLink';
import { CloudProvider } from '@packages/types/nds/provider';

import fetchWrapper from '../fetchWrapper';

export function fetchAWSPrivateLinks(groupId: string): Promise<Array<AWSPrivateLink>> {
  return fetchWrapper(`/nds/${groupId}/privateEndpoint/${CloudProvider.AWS}/endpointService`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function fetchAzurePrivateLinks(groupId: string): Promise<Array<AzurePrivateLink>> {
  return fetchWrapper(`/nds/${groupId}/privateEndpoint/${CloudProvider.AZURE}/endpointService`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function fetchGCPPSCs(groupId: string): Promise<Array<GCPPrivateServiceConnect>> {
  return fetchWrapper(`/nds/${groupId}/privateEndpoint/${CloudProvider.GCP}/endpointService`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function createPrivateLink(groupId: string, cloudProvider: CloudProvider, data: EndpointServiceCreateRequest) {
  return fetchWrapper(`/nds/${groupId}/privateEndpoint/${cloudProvider}/endpointService`, {
    method: 'PUT',
    body: JSON.stringify(data),
  }).then((resp) => resp.json());
}

export function deleteEndpoint(
  groupId: string,
  cloudProvider: CloudProvider,
  endpointServiceId: string,
  endpointId: string
) {
  return fetchWrapper(
    `/nds/${groupId}/privateEndpoint/${cloudProvider}/endpointService/${endpointServiceId}/endpoint/${endpointId}`,
    {
      method: 'DELETE',
    }
  ).then((resp) => resp.json());
}

export function deletePrivateLink(groupId: string, cloudProvider: CloudProvider, endpointServiceId: string) {
  return fetchWrapper(`/nds/${groupId}/privateEndpoint/${cloudProvider}/endpointService/${endpointServiceId}`, {
    method: 'DELETE',
  }).then((resp) => resp.json());
}

export function addEndpoint(
  groupId: string,
  cloudProvider: CloudProvider,
  endpointServiceId: string,
  endpointId: string,
  privateEndpointIPAddress?: string
) {
  return fetchWrapper(
    `/nds/${groupId}/privateEndpoint/${cloudProvider}/endpointService/${endpointServiceId}/endpoint`,
    {
      method: 'PUT',
      body: JSON.stringify({
        endpointId,
        ...(privateEndpointIPAddress ? { privateEndpointIPAddress } : {}),
      }),
    }
  ).then((resp) => resp.json());
}

export function addEndpointGroup(
  groupId: string,
  cloudProvider: CloudProvider,
  endpointServiceId: string,
  createEndpointGroupRequest: string
) {
  return fetchWrapper(
    `/nds/${groupId}/privateEndpoint/${cloudProvider}/endpointService/${endpointServiceId}/endpointGroup`,
    {
      method: 'PUT',
      body: createEndpointGroupRequest,
    }
  ).then((resp) => resp.json());
}

export function patchEndpointGroup(
  groupId: string,
  cloudProvider: CloudProvider,
  endpointServiceId: string,
  oldEndpointGroupName: string,
  createEndpointGroupRequest: string
) {
  return fetchWrapper(
    `/nds/${groupId}/privateEndpoint/${cloudProvider}/endpointService/${endpointServiceId}/endpointGroup/${oldEndpointGroupName}`,
    {
      method: 'PATCH',
      body: createEndpointGroupRequest,
    }
  ).then((resp) => resp.json());
}

export function patchEndpoint(
  groupId: string,
  cloudProvider: CloudProvider,
  endpointServiceId: string,
  oldEndpointId: string,
  newEndpointId: string,
  privateEndpointIPAddress?: string
) {
  return fetchWrapper(
    `/nds/${groupId}/privateEndpoint/${cloudProvider}/endpointService/${endpointServiceId}/endpoint/${oldEndpointId}`,
    {
      method: 'PATCH',
      body: JSON.stringify({
        endpointId: newEndpointId,
        ...(privateEndpointIPAddress ? { privateEndpointIPAddress } : {}),
      }),
    }
  ).then((resp) => resp.json());
}

export function getRegionalizedPrivateLinksEnabled(groupId: string) {
  return fetchWrapper(`/nds/${groupId}/privateEndpoint/regionalMode`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function updateRegionalizedPrivateLinksEnabled(groupId: string, enabled: boolean) {
  return fetchWrapper(`/nds/${groupId}/privateEndpoint/regionalMode`, {
    method: 'PATCH',
    body: JSON.stringify(enabled),
  }).then((resp) => resp.json());
}
