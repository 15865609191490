import { produce } from 'immer';
import { v4 as uuidv4 } from 'uuid';

import type { ClusterDescription } from '@packages/types/nds/clusterDescription';
import type { ProcessArgs } from '@packages/types/nds/ProcessArgs';
import type { InstanceSize } from '@packages/types/nds/provider';
import { RegionName } from '@packages/types/nds/region';

import { createJsonSessionStorage, JsonStorage } from 'js/common/services/localStorage';
import { sendError } from 'js/common/utils/bugsnag';
import { PAYMENT_PAGE_BUGSNAG_TEAM } from 'js/common/utils/bugsnagConstants';

import * as app from './app';
import * as viewer from './viewer';
import type { RootThunk } from '../rootReducer';

export interface BillingEstimate {
  readonly tier1ReadCents?: number | null;
  readonly tier2ReadCents?: number | null;
  readonly tier3ReadCents?: number | null;
  readonly tier4ReadCents?: number | null;
  readonly serverlessWriteCents?: number | null;
  readonly serverlessStorageCents?: number | null;
  readonly serverlessContinuousBackupCents?: number | null;
  readonly monthlyEstimateCents?: number | null;
  readonly hourlyEstimateCents?: number | null;
  readonly hourlyBaseEstimateCents?: number | null;
  readonly hourlyAnalyticsEstimateCents?: number | null;
  readonly monthlyFeesEstimateCents?: number | null;
}

export interface ClusterTransactionTrackingMetadata {
  readonly preSelectedTier?: string;
  readonly regionAutoSelect: RegionName;
  readonly queryString: string;
}

export interface ClusterTransaction {
  readonly isEdit: boolean;
  readonly clusterDescription: Readonly<ClusterDescription>;
  /** Not required for serverless clusters */
  readonly processArgs: Readonly<ProcessArgs> | null;
  readonly billingEstimate: BillingEstimate;
  readonly desiredInstanceSize: InstanceSize;
  readonly trackingMetadata: ClusterTransactionTrackingMetadata;
}

function sendPurchaseError(message: string, metaData: unknown = {}) {
  const error = {
    error: new Error(message),
    team: PAYMENT_PAGE_BUGSNAG_TEAM,
    metaData: { invalidObject: metaData },
  };
  sendError(error);
}

function isClusterTransaction(obj: unknown): obj is ClusterTransaction {
  if (typeof obj !== 'object' || obj == null) {
    sendPurchaseError('is not valid ClusterTransaction', obj);
    return false;
  }
  const { isEdit, clusterDescription, billingEstimate, desiredInstanceSize, trackingMetadata } =
    obj as ClusterTransaction;

  if (typeof isEdit !== 'boolean') {
    sendPurchaseError('is not valid ClusterTransaction', obj);
    return false;
  }
  if (typeof clusterDescription !== 'object') {
    sendPurchaseError('is not valid ClusterTransaction', obj);
    return false;
  }
  if (typeof billingEstimate !== 'object') {
    sendPurchaseError('is not valid ClusterTransaction', obj);
    return false;
  }
  if (typeof desiredInstanceSize !== 'string') {
    sendPurchaseError('is not valid ClusterTransaction', obj);
    return false;
  }
  if (typeof trackingMetadata !== 'object') {
    sendPurchaseError('is not valid ClusterTransaction', obj);
    return false;
  }
  return true;
}

interface OneOrgPurchaseState {
  readonly lastAddedTransactionId?: string;
  readonly purchaseDetails: {
    readonly cluster: {
      readonly [groupId: string]:
        | {
            readonly [transactionId: string]: ClusterTransaction | undefined;
          }
        | undefined;
    };
  };
}

function isOneOrgPurchaseState(obj: unknown): obj is OneOrgPurchaseState {
  if (typeof obj !== 'object' || obj == null) {
    sendPurchaseError('is not valid OneOrgPurchaseState', obj);
    return false;
  }
  const { lastAddedTransactionId, purchaseDetails } = obj as OneOrgPurchaseState;
  if (lastAddedTransactionId == null || typeof lastAddedTransactionId === 'string') {
    if (typeof purchaseDetails === 'object' && purchaseDetails != null) {
      if (typeof purchaseDetails.cluster === 'object' && purchaseDetails.cluster != null) {
        return Object.keys(purchaseDetails.cluster).every((groupId) => {
          if (typeof groupId !== 'string') {
            sendPurchaseError('is not valid OneOrgPurchaseState', obj);
            return false;
          }
          const purchaseDetail = purchaseDetails.cluster[groupId];
          if (typeof purchaseDetail !== 'object' || purchaseDetail == null) {
            sendPurchaseError('is not valid OneOrgPurchaseState', obj);
            return false;
          }
          return Object.keys(purchaseDetail).every((transactionId) => {
            if (typeof transactionId !== 'string') {
              sendPurchaseError('is not valid OneOrgPurchaseState', obj);
              return false;
            }
            return isClusterTransaction(purchaseDetail[transactionId]) || purchaseDetail[transactionId] === undefined;
          });
        });
      }
      sendPurchaseError('is not valid OneOrgPurchaseState', obj);
      return false;
    }
    sendPurchaseError('is not valid OneOrgPurchaseState', obj);
    return false;
  }
  sendPurchaseError('is not valid OneOrgPurchaseState', obj);
  return false;
}

export interface PurchaseState {
  readonly [orgId: string]: OneOrgPurchaseState | undefined;
}

export function isPurchaseState(obj: unknown): obj is PurchaseState {
  if (typeof obj !== 'object' || obj == null) {
    sendPurchaseError('is not valid PurchaseState', obj);
    return false;
  }
  return Object.keys(obj).every((key) => {
    if (typeof key !== 'string') {
      sendPurchaseError('is not valid PurchaseState', obj);
      return false;
    }
    return isOneOrgPurchaseState(obj[key]);
  });
}

export interface State {
  purchase: PurchaseState;
}

const initialState: PurchaseState = {};

const getSessionStorageKeyForUser = ({ userId }: { userId: string }) => `MMS.purchasePage.${userId}`;
let cachedSessionStorage: JsonStorage<PurchaseState> | null = null;
const getSessionStorageForUser = (state: State) => {
  const userId: string | null | undefined = viewer.getId(state);

  if (userId == null) {
    return null;
  }

  const key = getSessionStorageKeyForUser({ userId });

  if (cachedSessionStorage?.key !== key) {
    cachedSessionStorage = createJsonSessionStorage(key, { typecheck: isPurchaseState });
  }

  return cachedSessionStorage;
};

// Action definitions

enum PurchaseActionType {
  SyncFromSessionStorage = 'purchase/SYNC_FROM_SESSION_STORAGE',
  StoreClusterTransaction = 'purchase/STORE_CLUSTER_TRANSACTION',
}

interface SyncFromSessionStorageAction {
  type: PurchaseActionType.SyncFromSessionStorage;
  payload: PurchaseState;
}

interface StoreClusterTransactionAction {
  type: PurchaseActionType.StoreClusterTransaction;
  payload: ClusterTransaction;
  meta: {
    orgId: string;
    groupId: string;
    transactionId: string;
  };
}

type PurchaseAction = SyncFromSessionStorageAction | StoreClusterTransactionAction;

// REDUCER

export default function purchaseReducer(state = initialState, action: PurchaseAction): PurchaseState {
  return produce(state, (draft) => {
    switch (action.type) {
      case PurchaseActionType.SyncFromSessionStorage: {
        return action.payload;
      }

      case PurchaseActionType.StoreClusterTransaction: {
        const { orgId, groupId, transactionId } = action.meta;

        // First make sure we don't have any undefined objects to worry about
        draft[orgId] ??= { purchaseDetails: { cluster: {} } };
        draft[orgId]!.purchaseDetails.cluster[groupId] ??= {};

        // Then fill in the details
        draft[orgId]!.purchaseDetails.cluster[groupId]![transactionId] = action.payload;
        draft[orgId]!.lastAddedTransactionId = transactionId;
        break;
      }

      default: {
        break;
      }
    }
  });
}

// SELECTORS

export const getTransactionConfigurationObject = (
  state: State,
  { orgId, groupId, transactionId }: { orgId: string; groupId: string; transactionId: string }
) => state.purchase[orgId]?.purchaseDetails.cluster?.[groupId]?.[transactionId] ?? null;

export const getTransactionConfigurationObjectForActiveOrg = (
  state: State,
  { groupId, transactionId }: { groupId: string; transactionId: string }
) => getTransactionConfigurationObject(state, { orgId: app.getActiveOrgId(state), groupId, transactionId });

export const getTransactionConfigurationObjectForActiveGroupAndOrg = (
  state: State,
  { transactionId }: { transactionId: string }
) =>
  transactionId == null
    ? null
    : getTransactionConfigurationObject(state, {
        orgId: app.getActiveOrgId(state),
        groupId: app.getActiveGroupId(state),
        transactionId,
      });

export const getLastAddedTransactionId = (state: State, orgId: string) => state.purchase[orgId]?.lastAddedTransactionId;

// ACTION CREATORS

type PurchaseThunkAction<Return = void> = RootThunk<Return, PurchaseAction>;

export const syncFromSessionStorage = (): PurchaseThunkAction => (dispatch, getState) => {
  const sessionStorage = getSessionStorageForUser(getState());

  if (sessionStorage == null) {
    // We somehow don't have a logged-in user, and so can't get a user-specific sessionStorage
    const errorMessage = 'Could not sync purchase details without an active user';
    sendPurchaseError(errorMessage);
    throw new Error(errorMessage);
  }

  dispatch({
    type: PurchaseActionType.SyncFromSessionStorage,
    payload: sessionStorage.getData(),
  });
};

// This creates an action creator that, after we dispatch and update the redux store,
// we then save that data in sessionStorage for persistence.
// The side effect here is saving to sessionStorage.
const createActionCreatorWithSideEffect =
  <Args extends Array<any>>(actionCreator: (...args: Args) => PurchaseAction) =>
  (...args: Args): PurchaseThunkAction =>
  (dispatch, getState) => {
    const sessionStorage = getSessionStorageForUser(getState());

    dispatch(actionCreator(...args));

    if (sessionStorage == null) {
      console.warn('Could not save to session storage');
    } else {
      const data = getState().purchase;
      sessionStorage.saveData(data);
    }
  };

const storeClusterTransaction = createActionCreatorWithSideEffect(
  ({
    orgId,
    groupId,
    transactionId,
    transaction,
  }: {
    orgId: string;
    groupId: string;
    transactionId: string;
    transaction: ClusterTransaction;
  }): StoreClusterTransactionAction => ({
    type: PurchaseActionType.StoreClusterTransaction,
    payload: transaction,
    meta: {
      orgId,
      groupId,
      transactionId,
    },
  })
);

export const storeClusterTransactionForCurrentGroup =
  (transaction: ClusterTransaction): PurchaseThunkAction<string | undefined> =>
  (dispatch, getState) => {
    const orgId: string | null | undefined = app.getActiveOrgId(getState());
    const groupId: string | null | undefined = app.getActiveGroupId(getState());
    const transactionId = uuidv4();

    if (orgId == null || groupId == null) {
      const errorMessage = 'Could not store cluster transaction because no active org or group was detected';
      sendPurchaseError(errorMessage, { transaction, orgId, groupId });
      throw new Error(errorMessage);
    }

    dispatch(storeClusterTransaction({ orgId, groupId, transactionId, transaction }));

    return transactionId;
  };
