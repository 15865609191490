import { OktaMfaAuthTransaction } from '@packages/types/accountMultiFactorAuth';
import { Confirmation, DeviceCode } from '@packages/types/auth/deviceCode';
import { Search } from '@packages/types/auth/search';

import fetchWrapper, { formParams } from './fetchWrapper';

export function userAuthOkta({
  username,
  password,
  reCaptchaResponse,
  reCaptchaV3Response,
  clientState,
  idp,
  ssoDebug,
}) {
  return fetchWrapper('/account/auth', {
    method: 'POST',
    body: JSON.stringify({
      username,
      password,
      reCaptchaResponse,
      reCaptchaV3Response,
      clientState,
      idp,
      ssoDebug,
    }),
  }).then((resp) => resp.json());
}

export function getAuthMfaState({ stateToken }) {
  return fetchWrapper(`/account/auth/mfa/${stateToken}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export interface LoginRedirectResponse {
  loginRedirect: string;
}

export type VerifyAuthMfaResponse = OktaMfaAuthTransaction | LoginRedirectResponse;

export function verifyAuthMfa({
  stateToken,
  factorId,
  passcode,
  clientState,
  clientData,
  authenticatorData,
  signatureData,
}: {
  stateToken?: string;
  factorId: string;
  passcode?: string;
  clientState?: Search;
  clientData?: string;
  authenticatorData?: string;
  signatureData?: string;
}): Promise<VerifyAuthMfaResponse> {
  return fetchWrapper('/account/auth/mfa/verify', {
    method: 'POST',
    body: JSON.stringify({
      stateToken,
      factorId,
      passcode,
      clientState,
      clientData,
      authenticatorData,
      signatureData,
    }),
  }).then((resp) => resp.json());
}

export function resendAuthMfa({
  stateToken,
  factorId,
  passcode,
}: {
  stateToken?: string;
  factorId: string;
  passcode?: string;
}): Promise<OktaMfaAuthTransaction> {
  return fetchWrapper('/account/auth/mfa/verify/resend', {
    method: 'POST',
    body: JSON.stringify({
      stateToken,
      factorId,
      passcode,
    }),
  }).then((resp) => resp.json());
}

export function startOidc({ idpId, sessionToken, clientState }) {
  return fetchWrapper('/account/oidc/start', {
    method: 'POST',
    body: JSON.stringify({
      idpId,
      sessionToken,
      clientState,
    }),
  }).then((resp) => resp.json());
}

export function checkPassword(password) {
  return fetchWrapper('/account/checkPassword', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams({ password }),
  });
}

export function resetPassword({ username, nds }: { username: string; nds?: boolean }) {
  const params = {
    username,
  };
  if (nds != null) {
    (params as any).nds = nds ? 'true' : 'false';
  }

  return fetchWrapper('/account/resetPasswordRequest', {
    method: 'POST',
    body: JSON.stringify(params),
  }).then((resp) => resp.json());
}

export function createPasswordResetLinkAsAdmin(username: string, helpTicket: string) {
  const params = {
    username,
    helpTicket,
  };

  return fetchWrapper('/account/createPasswordResetLinkAsAdmin', {
    method: 'POST',
    body: JSON.stringify(params),
  }).then((resp) => resp.json());
}

export function resetComplete(data = {}) {
  const params = {
    username: (data as any).username,
    password: (data as any).password,
    passwordConfirm: (data as any).passwordConfirm,
    tempId: (data as any).tempId,
  };

  return fetchWrapper('/account/resetPasswordComplete', {
    method: 'POST',
    body: JSON.stringify(params),
  }).then((resp) => resp.json());
}

export function acceptTOS(queryParams) {
  return fetchWrapper(`/account/tos/accept${queryParams}`, {
    method: 'POST',
  }).then((resp) => resp.json());
}

export function eloqua(data = {}) {
  return fetchWrapper('/account/eloqua', {
    method: 'POST',
    body: formParams(data),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
}

export function params() {
  return fetchWrapper(`/account/params`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function clearbitApi() {
  const params = {
    variable: 'reveal',
    authorization: 'pk_b328ec11ac3fae0385e5fa0552778c4a',
  };

  return fetchWrapper(`https://reveal.clearbit.com/v1/companies/reveal?${formParams(params)}`, {
    method: 'GET',
    cache: true,
  })
    .then((response) => response.text())
    .then((body) => {
      // get window.reveal
      try {
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        const revealData = body.match(/^window.reveal = \((.*?)\)$/)[1];
        return JSON.parse(revealData);
      } catch (e) {
        return {};
      }
    });
}

export function accountLogout() {
  return fetchWrapper('/account/logout', {
    method: 'POST',
  });
}

export function cloudLogout(centralUrl = '') {
  return fetchWrapper(`${centralUrl}/user/logout`, {
    credentials: 'include',
    method: 'POST',
  });
}

export function universityLogout(universityUrl) {
  return fetchWrapper(`${universityUrl}/logout_from_account`, {
    credentials: 'include',
    method: 'POST',
  });
}

export function verify({ username, password, clientState }) {
  return fetchWrapper('/account/auth/verify', {
    method: 'POST',
    body: JSON.stringify({
      username,
      password,
      clientState,
    }),
  }).then((resp) => resp.json());
}

export function cancelLinking({ username, socialProvider }) {
  return fetchWrapper('/account/auth/verify/cancel', {
    method: 'DELETE',
    body: JSON.stringify({
      username,
      socialProvider,
    }),
  }).then((resp) => resp.json());
}

export function resendVerificationEmail({ clientState, analyticsPageTitle }, accountCentralUrl: string = '') {
  return fetchWrapper(`${accountCentralUrl}/account/resend/verification/email`, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify({
      clientState,
      analyticsPageTitle,
    }),
  }).then((resp) => resp.json());
}

export function unauthedResendVerificationEmail({ username, clientState, analyticsPageTitle, emailToken }) {
  return fetchWrapper('/account/unauthed/resend/verification/email', {
    method: 'POST',
    body: JSON.stringify({
      username,
      clientState,
      analyticsPageTitle,
      emailToken,
    }),
  }).then((resp) => resp.json());
}

export function verifyDeviceCode({ userCode }): Promise<DeviceCode> {
  return fetchWrapper(`/account/device/verify`, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    method: 'POST',
    body: formParams({ user_code: userCode }),
  }).then((resp) => resp.json());
}

export function confirmDeviceCode({ deviceCode, sessionId }): Promise<Confirmation> {
  return fetchWrapper(`/account/device/confirm`, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    method: 'POST',
    body: formParams({ device_code: deviceCode, session_id: sessionId }),
  }).then((resp) => resp.json());
}
