import { SharedTierTerm } from './nds/clusterEditor';

/**
 * Test group names should be written in camelCase.
 * Include the suffix "Control" for the base condition,
 * and "Variant" with a brief descriptor for experimental groups:
 *
 * {experimentName}Control
 * {experimentName}VariantBasic
 * {experimentName}VariantFreeHobby
 *
 * The word 'Experiment' should not be used in test group names,
 * as that term is reserved for experiments at large. We are leaving
 * historical test group names intact until experiments are removed from the codebase.
 *
 * Please avoid using {experimentName} without a suffix,
 * as it complicates targeting in downstream analytics tools.
 */
export enum TestGroupName {
  fakeTestGroupForUnitTestingControl = 'fakeTestGroupNameForUnitTestingControl',
  fakeTestGroupForUnitTestingVariant = 'fakeTestGroupNameForUnitTestingVariant',
  emailVerificationExperiment = 'emailVerificationExperiment',
  flexConsultingUIExperiment = 'flexConsultingUIExperiment',
  newTeamMemberCredits = 'newTeamMemberCreditsVariation',
  appcuesOnboardingTreatment = 'appcuesOnboardingTreatment',
  comaprisonTableVariant = 'comparisonTableVariant',
  dataLimitWarningCtaControl = 'dataLimitWarningCtaControl',
  dataLimitWarningCtaVariant = 'dataLimitWarningCtaVariant',
  cmabToggleVariant = 'cmabToggleVariant',
  localCurrencyVariant = 'localCurrencyVariant',
  surfaceUserGoalsVariant = 'surfaceUserGoalsVariant',
  universalDataAddVariant = 'universalDataAddVariant',
  dataModelingTemplatesVariant = 'dataModelingTemplatesVariant',
  clusterBuilderSidebarControl = 'clusterBuilderSidebarControl',
  clusterBuilderSidebarVariantAdditionalFeatures = 'clusterBuilderSidebarVariantAdditionalFeatures',
  clusterBuilderSidebarVariantResources = 'clusterBuilderSidebarVariantResources',
  clusterCardPageEditConfigButtonControl = 'clusterCardPageEditConfigButtonControl',
  clusterCardPageEditConfigButtonVariant = 'clusterCardPageEditConfigButtonVariant',
  dataAPIConnectOptionControl = 'dataApiConnectOptionControl',
  dataAPIConnectOptionVariant = 'dataApiConnectOptionVariant',
  searchIndexVideoControl = 'searchIndexVideoControl',
  searchIndexVideoVariant = 'searchIndexVideoVariant',
  ecosystemVariant = 'ecosystemVariant',
  ecosystemControl = 'ecosystemControl',
  comparisonTableExpandedVariant = 'comparisonTableExpandedVariant',
  comparisonTableCollapsedVariant = 'comparisonTableCollapsedVariant',
  useCaseBasedClusterTemplatesVariant = 'useCaseBasedClusterTemplatesVariant',
  searchNavIaVariant = 'searchNavigationVariant',
  searchLandingControl = 'searchLandingControl',
  searchLandingInformationalTimelineVariant = 'searchLandingInformationalTimelineVariant',
  searchLandingFeatureFirstVariant = 'searchLandingFeatureFirstVariant',
  searchLandingInformationalSearchEnvVariant = 'searchLandingInformationalSearchEnvVariant',
  redirectMobileUsersVariant = 'redirectMobileUsersVariant',
  improvedSearchQueryGuidanceVariant = 'improvedSearchQueryGuidanceVariant',
  improvedSearchQueryGuidanceControl = 'improvedSearchQueryGuidanceControl',
  cartResurrectionAutoSaveVariant = 'cartResurrectionAutoSaveVariant',
  cartResurrectionSaveButtonVariant = 'cartResurrectionSaveButtonVariant',
  comparisonTableRelaunchVariant = 'comparisonTableRelaunchVariant',
  homeCenterVariant = 'homeCenterVariant',
  triggersEmptyStateVariant = 'triggersEmptyStateVariant',
  cmabExperimentRelaunchVarA = 'cmabExperimentRelaunchVarA',
  cmabExperimentRelaunchVarAPrime = 'cmabExperimentRelaunchVarAPrime',
  cmabExperimentRelaunchTwoVarA = 'cmabExperimentRelaunchTwoVarA',
  cmabExperimentRelaunchTwoVarAPrime = 'cmabExperimentRelaunchTwoVarAPrime',
  newChartsTabControl = 'newChartsTabControl',
  newChartsTabVariant = 'newChartsTabVariant',
  dataAPIModalRebuildVariant = 'dataAPIModalRebuildVariant',
  sampleDataSelectorVariant = 'sampleDataSelectorVariant',
}

/**
 * Naming standard: {EXPERIMENT_NAME}_EXPERIMENT_YYYYMMDD, where
 * the date is the prod branch cut date.
 * EXAMPLE: FULL_PAGE_PAYMENT_CLICK_20210519
 */
export enum TestName {
  fakeTestForUnitTesting = 'FAKE_TEST_FOR_UNIT_TESTING',
  cmabToggle = 'CMAB_TOGGLE_20220317',
  emailVerification = 'EMAIL_VERIFICATION_20210309',
  flexConsultingUI = 'FLEX_CONSULTING_UI_20210707',
  newTeamMemberCredits = 'NEW_TEAM_MEMBER_CREDITS_20210914',
  appcuesOnboarding = 'APPCUES_ONBOARDING_EXPERIMENT_20210914',
  appCuesProductTour = 'ATLAS_APPCUES_PRODUCT_TOUR_20210917',
  comparisonTableExperiment = 'COMPARISON_TABLE_EXPERIMENT_20220105',
  cloudFeatureUpdatesExperiment = 'CLOUD_FEATURE_UPDATES_EXPERIMENT_20220126',
  dataLimitWarningCtaExperiment = 'DATA_LIMIT_WARNING_CTA_EXPERIMENT_20220126',
  commandLineToolsSidebar = 'COMMAND_LINE_TOOLS_SIDEBAR_20220306',
  commandLineToolsDataExplorer = 'COMMAND_LINE_TOOLS_DATA_EXPLORER_20220306',
  localCurrencyExperiment = 'LOCAL_CURRENCY_EXPERIMENT_20220119',
  surfaceUserGoals = 'SURFACE_USER_GOALS_20220314',
  universalAddDataExperiment = 'UNIVERSAL_ADD_DATA_20220408',
  dataModelingTemplatesExperiment = 'DATA_MODELING_TEMPLATES_RERUN_20230301',
  clusterBuilderSidebar = 'CLUSTER_BUILDER_SIDEBAR_20220613',
  clusterCardPageEditConfigButtonExperiment = 'CLUSTER_CARD_PAGE_EDIT_CONFIG_BUTTON_EXPERIMENT_20220715',
  dataAPIConnectOption = 'DATA_API_CONNECT_OPTION_20220622',
  searchIndexVideo = 'SEARCH_INDEX_VIDEO_20220713',
  ecosystemExperiment = 'ECOSYSTEM_EXPERIMENT',
  cartResurrectionAutoSaveExperiment = 'CART_RESURRECTION_AUTO_SAVE_20220801',
  cartResurrectionSaveButtonExperiment = 'CART_RESURRECTION_SAVE_BUTTON_20220801',
  comparisonTableIterationExperiment = 'COMPARISON_TABLE_ITERATION_20220707',
  useCaseBasedClusterTemplates = 'USE_CASE_BASED_CLUSTER_TEMPLATES_20220719',
  searchNavIa = 'SEARCH_NAVIGATION_EXPERIMENT_20221005',
  searchLandingExperiment = 'SEARCH_LANDING_EXPERIMENT_20221005',
  searchLandingRelaunch = 'SEARCH_LANDING_EXPERIMENT_RELAUNCH_20230301',
  redirectMobileUsers = 'REDIRECT_MOBILE_USERS_20220925',
  improvedSearchQueryGuidanceExperiment = 'IMPROVED_SEARCH_QUERY_GUIDANCE_20221026',
  comparisonTableExperimentRelaunch = 'COMPARISON_TABLE_RELAUNCH_20221109',
  homeCenterExperiment = 'HOME_CENTER_EXPERIMENT_20230215',
  cmabExperimentRelaunch = 'CMAB_RELAUNCH_20230125',
  cmabExperimentRelaunchTwo = 'CMAB_RELAUNCH_20230413',
  triggersEmptyStateRelaunch = 'TRIGGERS_EMPTY_STATE_RELAUNCH_20230501',
  newChartsTab = 'NEW_CHARTS_TAB_EXPERIMENT_20230215',
  dataAPIModalRebuild = 'DATA_API_MODAL_REBUILD_20230109',
  sampleDataSelectorExperiment = 'SAMPLE_DATA_SELECTOR_20230503',
  streamlinedOnboarding = 'STREAMLINED_ONBOARDING_20230301',
  freeTrialM10Upgrade = 'FREE_TRIAL_M10_UPGRADE_20230501',
}

/**
 * When adding a new experiment feature flag, please add the new key to the
 * `ExperimentFeatureFlag` enum.  Then, using this enum, add the proper
 * typing for your feature flag to the `ExperimentFeatureFlagsPossible` interface.
 *
 * Please update the Experiment Attributes Registry in the wiki, whenever updating this interface:
 * https://wiki.corp.mongodb.com/pages/viewpage.action?pageId=143699037#ExperimentAttributes-ExperimentAttributesRegistry
 *
 * @TODO https://jira.mongodb.org/browse/CLOUDP-138475
 * Move the ExperimentFeatureFlag enums to js/project/common/constants/index.ts
 * with the other Experiment constants.
 */

export enum ExperimentFeatureFlag {
  clusterCardDataExplorerCtaText = 'clusterCardDataExplorerCtaText',
  clusterListCreateCtaText = 'clusterListCreateCtaText',
  sharedTierTerm = 'sharedTierTerm',
  atlasSearchLeftNavLinkText = 'atlasSearchLeftNavLinkText',

  showCommandLineToolsSidebar = 'showCommandLineToolsSidebar',
  showClusterListControlsCompact = 'showClusterListControlsCompact',
  showDataExplorerEmptyImportBanner = 'showDataExplorerEmptyImportBanner',
  showLCOnThreeDoor = 'showLCOnThreeDoor',
  showLCOnClusterBuilder = 'showLCOnClusterBuilder',

  connectionMethodDataAPITitle = 'connectionMethodDataAPITitle',
  connectionMethodDataAPISubtext = 'connectionMethodDataAPISubtext',
  connectionMethodShellTitle = 'connectionMethodShellTitle',
  connectionMethodCompassTitle = 'connectionMethodCompassTitle',
  connectionMethodDriversTitle = 'connectionMethodDriversTitle',

  showFppWhenUserHasActivePayment = 'showFppWhenUserHasActivePayment',
  showFeeEstimatesOnFPP = 'showFeeEstimatesOnFPP',
  showMonthlyEstimatesOnFPP = 'showMonthlyEstimatesOnFPP',

  autoSaveDraftClusterConfig = 'autoSaveDraftClusterConfig',
  showSaveDraftConfigButton = 'showSaveDraftConfigButton',

  showChartsTabInCollectionHeader = 'showChartsTabInCollectionHeader',
  showVisualizeDataButtonInSubheaders = 'showVisualizeDataButtonInSubheaders',

  // Adding the following value for testing purposes.  Please remove as real number become available
  testNumber = 'testNumber',

  showCTOnThreeDoor = 'showCTOnThreeDoor',
  showCTOnClusterCard = 'showCTOnClusterCard',
  showNewDesignOnThreeDoor = 'showNewDesignOnThreeDoor',

  //Streamlined Onboarding
  isInStreamlinedOnboardingVariant = 'isInStreamlinedOnboardingVariant',

  // Free Trial
  isEligibleForFreeTrialM10 = 'isEligibleForFreeTrialM10',
}

export interface ExperimentFeatureFlagsPossible {
  // Please update the Experiment Attributes Registry in the wiki, whenever updating this interface:
  // https://wiki.corp.mongodb.com/pages/viewpage.action?pageId=143699037#ExperimentAttributes-ExperimentAttributesRegistry
  [ExperimentFeatureFlag.clusterCardDataExplorerCtaText]: string;
  [ExperimentFeatureFlag.clusterListCreateCtaText]: string;
  [ExperimentFeatureFlag.sharedTierTerm]: SharedTierTerm;
  [ExperimentFeatureFlag.atlasSearchLeftNavLinkText]: string | undefined;

  [ExperimentFeatureFlag.showCommandLineToolsSidebar]: boolean;
  [ExperimentFeatureFlag.showClusterListControlsCompact]: boolean;
  [ExperimentFeatureFlag.showDataExplorerEmptyImportBanner]: boolean;
  [ExperimentFeatureFlag.showLCOnThreeDoor]: boolean;
  [ExperimentFeatureFlag.showLCOnClusterBuilder]: boolean;

  [ExperimentFeatureFlag.connectionMethodDataAPITitle]: string;
  [ExperimentFeatureFlag.connectionMethodDataAPISubtext]: string;
  [ExperimentFeatureFlag.connectionMethodShellTitle]: string;
  [ExperimentFeatureFlag.connectionMethodCompassTitle]: string;
  [ExperimentFeatureFlag.connectionMethodDriversTitle]: string;

  [ExperimentFeatureFlag.showFppWhenUserHasActivePayment]: boolean;
  [ExperimentFeatureFlag.showFeeEstimatesOnFPP]: boolean;
  [ExperimentFeatureFlag.showMonthlyEstimatesOnFPP]: boolean;

  [ExperimentFeatureFlag.autoSaveDraftClusterConfig]: boolean;
  [ExperimentFeatureFlag.showSaveDraftConfigButton]: boolean;

  // Adding the following values for testing purposes.  Please remove as real number become available
  [ExperimentFeatureFlag.testNumber]: number;

  // show Comparison Table on Three Door Page
  [ExperimentFeatureFlag.showCTOnThreeDoor]: boolean;
  // show Comparison Table on Cluster Card
  [ExperimentFeatureFlag.showCTOnClusterCard]: boolean;
  // show new Three Door page design
  [ExperimentFeatureFlag.showNewDesignOnThreeDoor]: boolean;

  [ExperimentFeatureFlag.showChartsTabInCollectionHeader]: boolean;
  [ExperimentFeatureFlag.showVisualizeDataButtonInSubheaders]: boolean;

  //Streamlined Onboarding
  [ExperimentFeatureFlag.isInStreamlinedOnboardingVariant]: boolean;

  [ExperimentFeatureFlag.isEligibleForFreeTrialM10]: boolean;
}

export enum Scope {
  USER = 'USER',
  GROUP = 'GROUP',
  ORG = 'ORG',
}

export interface ABTest {
  _id: string;
  testName: TestName;
  description: string;
  jiraLink: string;
  scope: Scope;
  scopePercentage: number;
  enabled: boolean;
  testGroups: Array<TestGroup>;
  tag: string;
}

export interface TestGroup {
  testGroupName: TestGroupName;
  testGroupDatabaseId: string;
  percent: number;
}

interface AssignmentBase {
  assignmentDate: string;
  entityId: string;
  entityType: Scope;
  id: string;
  meta: string | null;
  tag: string;
  testId: string;
  testName: TestName;
}

export interface OverrideAssignment {
  emailAddress: string;
  testName: string;
  testId: string;
  testGroupId: string;
  testGroupDatabaseId: string;
  entityType: string;
  tag: string;
}

export interface OverrideAssignmentFromServer extends OverrideAssignment {
  userId: string;
  _id: string;
}

export interface OverrideAssignmentUpdateForm {
  testGroupId: string;
  testGroupDatabaseId: string;
}

export interface Assignment extends AssignmentBase {
  testGroupName: TestGroupName | null;
  testGroupDatabaseId: string | null;
}

export interface NonNullAssignment extends AssignmentBase {
  testGroupName: TestGroupName;
  testGroupDatabaseId: string;
}

export interface NullAssignment extends AssignmentBase {
  testGroupName: null;
  testGroupDatabaseId: null;
}

export interface ServerAssignment extends AssignmentBase {
  testGroupId: TestGroupName | null;
  testGroupDatabaseId: string | null;
}

export type Assignments = Array<Assignment>;
export type NonNullAssignments = Array<NonNullAssignment>;
export type ServerAssignments = Array<ServerAssignment>;

export type ExperimentFeatureFlagValue = string | boolean | number;

export type ExperimentFeatureFlags = Partial<ExperimentFeatureFlagsPossible>;

export type ExperimentFeatureFlagsFromServer = {
  [key in ExperimentFeatureFlag]?: string;
};

export interface AssignmentPayload {
  data: ServerAssignments;
  experimentFeatureFlags: ExperimentFeatureFlagsFromServer;
}

export interface BulkAllocationPayload {
  entities: Array<{
    id: string;
    type: Scope;
  }>;
  testIds: Array<string>;
}

export enum AllocationPoint {
  CLUSTER_CARD_PAGE_WITHOUT_SERVERLESS = 'CLUSTER_CARD_PAGE_WITHOUT_SERVERLESS',
  ATLAS_REGISTRATION_SUCCEEDED = 'ATLAS_REGISTRATION_SUCCEEDED',
  CLUSTER_BUILDER_SEEN = 'CLUSTER_BUILDER_SEEN',
  CLUSTER_CARD_PAGE = 'CLUSTER_CARD_PAGE',
  PROJECT_APPLICATION_START = 'PROJECT_APPLICATION_START',
  THREE_DOOR_PAGE_SEEN = 'THREE_DOOR_PAGE_SEEN',
  // edit/upgrade cluster
  EDIT_CLUSTER = 'EDIT_CLUSTER',
  NEW_CLUSTER = 'NEW_CLUSTER',
  CLUSTER_CARD_PAGE_AT_LEAST_ONE_DEPLOYMENT = 'CLUSTER_CARD_PAGE_AT_LEAST_ONE_DEPLOYMENT',
}
