import { EncryptionAtRest } from '@packages/types/nds/security/enterpriseSecurity';

import fetchWrapper from '../fetchWrapper';

const patchEncryptionAtRest = (
  groupId: string,
  encryptionAtRest: Partial<EncryptionAtRest>
): Promise<EncryptionAtRest> => {
  return fetchWrapper(`/nds/${groupId}/encryptionAtRest`, {
    method: 'PATCH',
    body: JSON.stringify(encryptionAtRest), // this is just aws kms
  }).then((resp) => resp.json());
};

const getEncryptionAtRest = (groupId) => {
  return fetchWrapper(`/nds/${groupId}/encryptionAtRest`, {
    method: 'GET',
  }).then((resp) => resp.json());
};

const getKmipProxyToolNames = (groupId) => {
  return fetchWrapper(`/nds/${groupId}/encryptionAtRest/kmipTool/namer`, {
    method: 'GET',
  }).then((resp) => resp.json());
};

export { patchEncryptionAtRest, getEncryptionAtRest, getKmipProxyToolNames };
