/**
 * This creates a 'scoped' redux reducer.
 * For example if you have a state object of:
 *  state = { [orgId]: {}, [orgId2]: {} }
 * Rather than manually scoping every object under each orgId this will create a reducer
 * that does that for you.
 *
 * scopeGetter is a function that is given the redux action and is expected to return the key
 * that all data should be scoped within.
 * i.e. scopeGetter = action => action.meta.orgId
 *
 * reducer is a regular reducer function that is given the scoped state.
 * i.e. reducer = function(scopedState, action) {};
 *
 * Returned is a scopedReducer that can be used in a regular reducer.
 * It has a .isScoped property that is the same value as the scopeGetter.
 *
 * Typical useage is:
 * const scopedReducer = createScopedReducer(scopeGetter, reducer);
 * function myRegularReducer(state, action) {
 *    if (scopedReducer.isScoped(action)) {
 *      return scopedReducer(state, action);
 *    }
 *
 *    return state;
 * }
 */
export const createScopedReducer = function createScopedReducer(scopeGetter, reducer) {
  function scopedReducer(state, action) {
    const scopeKey = scopeGetter(action);

    if (!scopeKey) {
      return state;
    }

    const scopedState = state && state[scopeKey];
    const nextScopedState = reducer(scopedState, action);

    if (scopedState === nextScopedState) {
      return state;
    }

    return {
      ...state,
      [scopeKey]: nextScopedState,
    };
  }

  scopedReducer.isScoped = scopeGetter;

  return scopedReducer;
};

// Simple utility to make it easier to create a reducer.
// Taken from Redux docs:
// https://redux.js.org/recipes/reducing-boilerplate#generating-reducers
export const createReducer = function createReducer(initialState, handlers) {
  return function reducer(state = initialState, action) {
    if (handlers[action.type]) {
      return handlers[action.type](state, action);
    }
    return state;
  };
};
