import { useEffect, useState } from 'react';

import { css } from '@emotion/react';
import Banner, { Variant as BannerVariant } from '@leafygreen-ui/banner';
import Button from '@leafygreen-ui/button';
import { palette } from '@leafygreen-ui/palette';
import { Body, Link, Subtitle } from '@leafygreen-ui/typography';
import queryString from 'query-string';
import { Helmet } from 'react-helmet';

import { Search } from '@packages/types/auth/search';

import useMfaFactors from '@packages/hooks/useMfaFactors';

import * as api from 'js/common/services/api';
import { useAccountUser } from 'js/common/context/AccountUserContext';
import { useRequestParams } from 'js/common/context/RequestParamsContext';
import { setMfaEncouragementSnoozedDateToCurrentDate } from 'js/common/services/api/accountMultiFactorAuthApi';
import { CloudTeams, sendError } from 'js/common/utils/bugsnag';
import analytics, { PAGE_CALLS, SEGMENT_EVENTS } from 'js/common/utils/segmentAnalytics';

import { MfaEncouragementLayout } from './MfaEncouragementLayout';

const headerStyle = css`
  color: ${palette.green.dark2};
  line-height: 20px;
  font-size: 14px;
  margin-top: 16px;
  font-weight: bold;
`;

const paragraphText = css`
  margin-top: 8px;
  line-height: 20px;
  font-size: 14px;
`;

const primaryStyle = css`
  margin-top: 32px;
  height: 36px;
`;

const secondaryStyle = css`
  margin-top: 32px;
  height: 36px;
  margin-left: 16px;
`;

const bannerStyle = css`
  margin-top: 24px;
`;

export default function MfaEncouragementPromptPage({
  windowLocation = window.location,
  location: { search } = { search: '' },
}: {
  windowLocation: Pick<Location, 'assign'>;
  location?: Pick<Location, 'search'>;
}) {
  const { factors: mfaFactors, isLoading: isMfaFactorsLoading } = useMfaFactors();
  const [isInMfaRequiredOrg, setIsInMfaRequiredOrg] = useState(false);
  const [isAccountProfileAppUserSet, setIsAccountProfileAppUserSet] = useState(false);
  const { loadAccountUserParams } = useAccountUser();
  const { analyticsEnabled } = useRequestParams();
  const parsedSearchLocation = queryString.parse(search) as Search;
  const { redirectUri } = parsedSearchLocation;
  const titleHeader =
    mfaFactors.length === 0
      ? 'Enable your multi-factor authentication methods'
      : 'Set up another MFA method for your account';

  const fetchAndSetAccountProfileAppUser = async () => {
    try {
      const { isInMfaRequiredOrg, userId } = await api.accountProfile.getAppUser();
      setIsInMfaRequiredOrg(isInMfaRequiredOrg);
      await analytics.updateState({
        persistedProperties: {
          userId,
          numberOfFactors: mfaFactors.length,
        },
      });
    } catch (error) {
      void sendError({ error, team: CloudTeams.CoreIam });
    } finally {
      setIsAccountProfileAppUserSet(true);
    }
  };

  const callAnalyticsPageEvent = () => {
    if (isAccountProfileAppUserSet && analyticsEnabled) {
      void analytics.page(PAGE_CALLS.MFA_ENCOURAGEMENT_PROMPT, {});
    }
  };

  useEffect(() => void fetchAndSetAccountProfileAppUser(), []);
  useEffect(() => callAnalyticsPageEvent(), [isAccountProfileAppUserSet, analyticsEnabled]);
  useEffect(() => void loadAccountUserParams(), [loadAccountUserParams]);

  async function onClickRemindMeLater() {
    try {
      await setMfaEncouragementSnoozedDateToCurrentDate();
    } catch (error) {
      sendError({
        error,
        team: CloudTeams.CoreIam,
      });
    } finally {
      await analytics.track(SEGMENT_EVENTS.MFA_ENCOURAGEMENT_SKIPPED, {});
      let urlSearchParams = new URLSearchParams(search);
      urlSearchParams.delete('redirectUri');
      windowLocation.assign(`${redirectUri || '/'}?${urlSearchParams.toString()}`);
    }
  }

  if (isMfaFactorsLoading || !isAccountProfileAppUserSet) {
    return null;
  }

  return (
    <>
      <Helmet title="Enable MFA" />
      <MfaEncouragementLayout title={titleHeader}>
        {mfaFactors.length === 0 && (
          <>
            <Subtitle css={headerStyle}> Secure your account across MongoDB</Subtitle>
            <Body css={paragraphText} data-testid="paragraph1">
              Secure your MongoDB account across MongoDB Atlas, University, Community, and Support with
              Multi-Factor-Authentication (MFA). Setting up MFA will help reduce risks from outside threats to your
              account.{' '}
              <Link href="https://www.mongodb.com/docs/atlas/security-multi-factor-authentication/">Learn More</Link>
            </Body>

            <Subtitle css={headerStyle}>Setup Backup MFA Methods</Subtitle>
            <Body css={paragraphText} data-testid="paragraph2">
              In addition, you will also be setting up an additional method in this setup. This is a precaution in the
              case you lose access to your first MFA enabled device and to avoid being locked out.
            </Body>
          </>
        )}

        {mfaFactors.length === 1 && (
          <Body css={paragraphText} data-testid="paragraph3">
            We recommend setting up an additional factor for MFA to reduce the risk of being locked out of your MongoDB
            account.{' '}
            <Link href="https://www.mongodb.com/docs/atlas/security-multi-factor-authentication/">Learn More</Link>
          </Body>
        )}
        {isInMfaRequiredOrg && (
          <Banner variant={BannerVariant.Warning} css={bannerStyle} data-testid="banner">
            One or more of the organizations you are in requires that you have MFA enabled. You must complete this setup
            in order to continue. <Link href="https://dochub.mongodb.org/core/atlas-require-mfa">Learn More</Link>
          </Banner>
        )}
        <Button
          variant="primary"
          css={primaryStyle}
          onClick={() => windowLocation.assign(`/account/security/mfa/setup${search}`)}
          name="setupButton"
        >
          Set up now
        </Button>
        {!(mfaFactors.length === 0 && isInMfaRequiredOrg) && (
          <Button variant="default" css={secondaryStyle} onClick={onClickRemindMeLater} name="snooze">
            Remind me later
          </Button>
        )}
      </MfaEncouragementLayout>
    </>
  );
}
