/* eslint-disable */
export function triggerQuoraTrackingPixel() {
  // NOTE(hswolff): Taken directly from script provided by Marketing
  // in CLOUDP-42880
  !(function (q, e, v, n, t, s) {
    if (q.qp) return;
    n = q.qp = function () {
      n.qp ? n.qp(...arguments) : n.queue.push(arguments);
    };
    n.queue = [];
    t = document.createElement(e);
    t.async = !0;
    t.src = v;
    s = document.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
  })(window, 'script', 'https://a.quora.com/qevents.js');
  qp('init', '563b2683a13f4dd98be0f5f0db52e776');
  qp('track', 'ViewContent');
}
