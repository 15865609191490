import { useRef } from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Banner, { Variant as BannerVariant } from '@leafygreen-ui/banner';
import { H2 } from '@leafygreen-ui/typography';
import queryString from 'query-string';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { Search } from '@packages/types/auth/search';
import { SocialAuthMethods } from '@packages/types/authMethod';
import { Document } from '@packages/types/browser';

import ButtonWithLoaderAndLink from '@packages/components/ButtonWithLoaderAndLink';
import LineDivider from '@packages/components/LineDivider';

import * as errorHelper from 'js/common/services/authErrorHelper';
import { useRequestParams } from 'js/common/context/RequestParamsContext';
import { getMarketplaceRegistrationCookie } from 'js/common/utils/billingHelpers';
import { mq } from 'js/common/utils/mediaQueries';
import analytics, { SEGMENT_EVENTS } from 'js/common/utils/segmentAnalytics';

import useLoginAnalytics from 'js/auth/hooks/useLoginAnalytics';
import useLoginService, { ALERTABLE_ERROR_CODES, UseLoginService } from 'js/auth/hooks/useLoginService';
import colors from 'js/auth/utils/palette';

import AuthErrorBanner from './AuthErrorBanner';
import FormInput from './FormInput';
import HeaderLogo from './HeaderLogo';
import InviteBanner from './InviteBanner';
import Layout from './Layout';
import ReCaptcha, { ReCaptchaHandles } from './ReCaptcha';
import SocialAuthButton from './SocialAuthButton';
import { bannerStyle } from './styles/banner';
import { Container, LoginAndRegistrationHeader, Subheader, submitButton } from './styles/form';

const forgotPassword = css({
  fontSize: '14px',
  textDecoration: 'none',
  color: colors.link,
  cursor: 'pointer',
});

const marketplaceLink = css({
  color: colors.link,
});

const ChangeEmail = styled.div((props: { hasError?: boolean }) =>
  mq({
    position: 'relative',
    bottom: ['50px', '58px', '50px', '50px'],
    left: props.hasError
      ? ['215px', 'calc(100vw - 119px)', '215px', '215px']
      : ['239px', 'calc(100vw - 91px)', '239px', '239px'],
    display: 'inline',
  })
);

const formInputComponentWidth = ['300px', 'calc(100vw - 32px)', '300px', '300px'];

const LinkContainer = styled.div(() =>
  mq({
    display: 'inline-block',
    textAlign: 'right',
    marginTop: '20px',
    width: formInputComponentWidth,
  })
);

const StyledLineDivider = styled(LineDivider)(
  mq({
    width: formInputComponentWidth,
    margin: '20px 0',
  })
);

// TODO: Uncomment this as part of https://jira.mongodb.org/browse/CLOUDP-57726
// const Iframe = styled.iframe`
//   width: 0;
//   height: 0;
//   border: 0;
// `;

export default function LoginPage({
  location: { search } = { search: '' },
  windowLocation = window.location,
  browserDocument = document,
}: {
  location?: Pick<Location, 'search'>;
  windowLocation?: Pick<Location, 'assign'>;
  browserDocument?: Document;
}) {
  const requestParams = useRequestParams();
  const {
    isLdap,
    userRegistrationEnabled,
    reCaptchaEnabledLogin,
    isGoogleAuthEnabled,
    isGithubAuthEnabled,
    onPrem,
    atlasSiteFullName,
    siteFullName,
  } = requestParams;

  const isSocialAuthEnabled = isGoogleAuthEnabled || isGithubAuthEnabled;
  const parsedSearchLocation = queryString.parse(search) as Search;
  const { activationCode, inviteToken, username: invitedUsername } = parsedSearchLocation;

  const reCaptchaRef = useRef<ReCaptchaHandles>(null);
  const marketplaceRegistrationCookie = getMarketplaceRegistrationCookie();
  const marketplaceType = marketplaceRegistrationCookie?.partnerType;

  useLoginAnalytics(marketplaceRegistrationCookie);
  const {
    onLoginUser,
    onEmailChange,
    onPasswordChange,
    onChangeEmailClick,
    disableEmailInput,
    showInputErrors,
    loginReducerState,
    onSetReCaptchaResponse,
    onSetReCaptchaV3Response,
  }: UseLoginService = useLoginService({
    parsedSearchLocation,
    windowLocation,
    reCaptchaRef,
    browserDocument,
  });

  const {
    username,
    password,
    onlyUsernameShowing,
    formSubmitting,
    // showSupportLogout, // TODO: Uncomment this as part of https://jira.mongodb.org/browse/CLOUDP-57726
    authErrorCode,
    bannerVariant,
    ssoBypassEnabled,
    marketingUrl,
    isSubmitDisabled,
    isLoginDisabled,
  } = loginReducerState;

  const displayErrorAsAlert = !!authErrorCode && ALERTABLE_ERROR_CODES.includes(authErrorCode);

  const sendPasswordResetAnalytics = () => {
    analytics.track(SEGMENT_EVENTS.UX_ACTION_PERFORMED, {
      action: 'Clicked Forgot Password CTA',
      context: 'Login Page',
      username,
    });
  };

  return (
    <>
      <Helmet title="Log in" />
      <Layout contentPlacement="left" isLoginPromo>
        <Container onSubmit={onLoginUser} method="post">
          <HeaderLogo />
          <InviteBanner
            token={inviteToken}
            username={invitedUsername}
            isFor="login"
            retryInviteCheck={formSubmitting}
          />
          {activationCode && (
            <Banner variant={BannerVariant.Danger} css={bannerStyle} data-testid="bannerActivationCode">
              You&apos;re about to redeem your activation code. It will automatically be applied to your new
              organization.
            </Banner>
          )}
          {marketplaceRegistrationCookie && (
            <Banner variant={BannerVariant.Info} css={bannerStyle} data-testid="bannerMpRegistration">
              Don&apos;t have a MongoDB account yet?{' '}
              <Link to="/register" css={marketplaceLink}>
                Sign up
              </Link>{' '}
              to complete linking your {marketplaceType} account to MongoDB.
            </Banner>
          )}
          <H2 data-testid="login-page-header" css={LoginAndRegistrationHeader}>
            Log in to your account
          </H2>
          {userRegistrationEnabled && (
            <Subheader>
              Don&apos;t have an account?
              <Link to={!onPrem ? `/register${search}` : '/register'}>&nbsp;Sign Up</Link>
            </Subheader>
          )}
          {displayErrorAsAlert && (
            <Banner variant={bannerVariant || BannerVariant.Danger} data-testid="bannerLoginError" css={bannerStyle}>
              {errorHelper.getErrorMessageFromCode(authErrorCode)}
            </Banner>
          )}
          {isGoogleAuthEnabled && (
            <>
              <SocialAuthButton
                disabled={isLoginDisabled}
                provider={SocialAuthMethods.GOOGLE}
                location={location}
                marketplaceLinkFlow={marketplaceType}
                data-testid="googleAuthButton"
              />
            </>
          )}
          {isGithubAuthEnabled && (
            <>
              <SocialAuthButton
                disabled={isLoginDisabled}
                provider={SocialAuthMethods.GITHUB}
                location={location}
                marketplaceLinkFlow={marketplaceType}
                data-testid="githubAuthButton"
              />
            </>
          )}
          {isSocialAuthEnabled && <StyledLineDivider>Or with email and password</StyledLineDivider>}

          {authErrorCode && !displayErrorAsAlert && (
            <AuthErrorBanner
              css={bannerStyle}
              authErrorCode={authErrorCode}
              marketingUrl={marketingUrl}
              siteName={onPrem ? siteFullName : atlasSiteFullName}
              onPrem={onPrem}
            />
          )}

          <FormInput
            fieldName="username"
            labelName={onPrem ? 'Username' : 'Email Address'}
            autoComplete="email"
            onChange={onEmailChange}
            value={username}
            hasError={showInputErrors}
            showChangeUsername={!onlyUsernameShowing}
            disabled={disableEmailInput || !!invitedUsername}
            tooltipText={onPrem ? 'This is your username or email address you used when you registered.' : ''}
            autoFocus
          />
          {disableEmailInput && (
            <ChangeEmail hasError={showInputErrors}>
              <a tabIndex={-1} role="button" onClick={onChangeEmailClick} css={forgotPassword}>
                Change
              </a>
            </ChangeEmail>
          )}
          {!onlyUsernameShowing && (
            <>
              <FormInput
                fieldName="password"
                labelName="Password"
                autoComplete="current-password"
                type="password"
                hasError={showInputErrors}
                onChange={onPasswordChange}
                value={password}
                autoFocus={!ssoBypassEnabled && !onPrem}
              />
              {!isLdap && (
                <LinkContainer>
                  <Link
                    to={{
                      pathname: '/reset/password',
                      search: `?email=${encodeURIComponent(username)}`,
                    }}
                    onClick={sendPasswordResetAnalytics}
                    css={forgotPassword}
                  >
                    &nbsp;Forgot Password?
                  </Link>
                </LinkContainer>
              )}
            </>
          )}
          {reCaptchaEnabledLogin && (
            /* Css positions recaptcha badge in front of LG button used for submit w/ 0 z order */
            <div css={{ position: 'relative', zIndex: 1 }}>
              <ReCaptcha
                invisible
                callback={(response) => onSetReCaptchaResponse(response)}
                callbackV3={(response) => onSetReCaptchaV3Response(response)}
                ref={reCaptchaRef}
                action="login"
              />
            </div>
          )}
          <footer>
            <ButtonWithLoaderAndLink
              variant="primary"
              css={submitButton}
              type="submit"
              showLoader={formSubmitting}
              disabled={isSubmitDisabled || isLoginDisabled}
              name="login"
            >
              {onlyUsernameShowing ? 'Next' : 'Login'}
            </ButtonWithLoaderAndLink>
          </footer>
          {/* TODO: Uncomment this as part of https://jira.mongodb.org/browse/CLOUDP-57726
           {showSupportLogout && (
            <Iframe title="&nbsp;" tabIndex={-1} src="https://mongodbcommunity.force.com/secur/logout.jsp" />
          )} */}
        </Container>
      </Layout>
    </>
  );
}
