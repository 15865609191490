import { useCallback, useEffect, useState } from 'react';

import Banner from '@leafygreen-ui/banner';

import * as api from 'js/common/services/api';
import * as errorHelper from 'js/common/services/authErrorHelper';

import { createInviteBannerMsgForLogin, createInviteBannerMsgForRegistration } from 'js/auth/utils/inviteUtils';

import { bannerStyle } from './styles/banner';

interface InviteBannerProps {
  token?: string;
  username?: string;
  isFor: 'login' | 'registration';
  retryInviteCheck?: boolean;
}

export default function InviteBanner({
  token = '',
  username = '',
  isFor = 'login',
  retryInviteCheck = false,
}: InviteBannerProps) {
  const [inviteMsg, setInviteMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  const fetchInvite = useCallback(async () => {
    if (!token) {
      return;
    }
    try {
      const invite = await api.user.inviteGet({ username, inviteToken: token });
      const msg =
        isFor === 'login' ? createInviteBannerMsgForLogin(invite) : createInviteBannerMsgForRegistration(invite);

      setInviteMsg(msg);
    } catch ({ errorCode }) {
      setErrorMsg(errorHelper.getErrorMessageFromCode(errorCode));
    }
  }, [isFor, token, username]);

  useEffect(() => {
    fetchInvite();
  }, [fetchInvite]);

  useEffect(() => {
    if (retryInviteCheck) {
      fetchInvite();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retryInviteCheck]);

  if (!token) {
    return null;
  }

  return (
    <Banner variant={errorMsg ? 'danger' : 'info'} dismissible css={bannerStyle} data-testid="inviteBanner">
      <span data-testid="invite">{errorMsg || inviteMsg}</span>
    </Banner>
  );
}
