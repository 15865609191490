import Button from '@leafygreen-ui/button';
import Card from '@leafygreen-ui/card';
import { palette } from '@leafygreen-ui/palette';
import { H2 } from '@leafygreen-ui/typography';

import { FormatDateTime } from '@packages/components/FormatDateTime';

import { header } from './styles/form';

interface DeviceAuthConfirmationProps {
  title: string;
  sourceUserAgent: string;
  sourceIp: string;
  onConfirm: () => void;
  onBack: () => void;
  createdAt: Date | string;
}

export function DeviceAuthConfirmation({
  title,
  sourceIp,
  sourceUserAgent,
  createdAt,
  onConfirm,
  onBack,
}: DeviceAuthConfirmationProps) {
  return (
    <>
      <H2 css={header}>{title}</H2>
      <Card css={{ minHeight: 200, maxWidth: 500 }}>
        <p>Confirm linking your account with the CLI.</p>
        <p css={{ color: palette.gray.dark1 }}>
          Requested from {sourceIp} ({sourceUserAgent}) on <FormatDateTime isoString={createdAt} />
        </p>
        <div css={{ marginTop: 30 }}>
          <Button css={{ marginRight: 20 }} variant="primary" onClick={onConfirm} name="confirm">
            Confirm authorization
          </Button>
          <Button onClick={onBack} name="back">
            Back
          </Button>
        </div>
      </Card>
    </>
  );
}
