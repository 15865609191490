import { UpdateStateOptions } from 'heliotrope';
import Cookies from 'js-cookie';

import { PartnerType } from '@packages/types/billing';

import * as api from 'js/common/services/api';
import { COOKIE_KEYS } from 'js/common/utils/cookieHelpers';
import { CountryCode } from 'js/common/utils/countries';
import analytics, {
  buildIntegrationsObject,
  EmailType,
  PAGE_CALLS,
  sanitizeLocation,
  SEGMENT_EVENTS,
} from 'js/common/utils/segmentAnalytics';

import { eloquaPost } from 'js/auth/utils/eloqua';

export interface RegistrationAnalyticsData {
  first_name: string;
  last_name: string;
  email: string;
  company?: string;
  country?: CountryCode;
  phone?: string;
  job_function?: string;
  signup_source: SignupSource;
  signup_method: SignupMethod;
}
export interface ClearbitData {
  cbit_company_city?: string;
  cbit_company_country_code?: string;
  cbit_company_domain?: string;
  cbit_company_employees_range?: string;
  cbit_company_country?: string;
  cbit_company_industry?: string;
  cbit_company_latitude?: string;
  cbit_company_longitude?: string;
  cbit_company_annual_revenue?: string;
  cbit_company_employees?: string;
  cbit_company_name?: string;
  cbit_company_revenue_range?: string;
  cbit_company_state?: string;
  cbit_company_street_address?: string;
  cbit_company_sub_industry?: string;
  cbit_company_ticker?: string;
  cbit_geoip_city?: string;
  cbit_geoip_country?: string;
  cbit_geoip_country_code?: string;
  cbit_geoip_state_code?: string;
}

export interface RegistrationAnalytics {
  eloquaSiteId?: string;
  parsedSearchLocation?: object;
  anonymousId?: string;
  userId?: string;
  data: RegistrationAnalyticsData;
  clearbitData?: ClearbitData;
}

export interface RegistrationError {
  errorCode: string;
  errorMessage?: string;
  signUpSource?: SignupSource;
  signUpMethod?: SignupMethod;
}

export enum SignupMethod {
  FORM = 'form',
  GOOGLE = 'google',
  GITHUB = 'github',
}

export enum SignupSource {
  ACCOUNT = 'Account',
  AWS_MARKETPLACE = 'AwsMarketplace',
  AZURE_MARKETPLACE = 'AzureMarketplace',
  GCP_MARKETPLACE = 'GcpMarketplace',
  VERCEL = 'Vercel',
  ATLAS_CLI = 'AtlasCLI',
}

export const trackRegistrationData = async ({
  eloquaSiteId,
  parsedSearchLocation,
  anonymousId,
  userId,
  data,
  clearbitData = {} as ClearbitData,
}: RegistrationAnalytics) => {
  if (userId) {
    await analytics.updateState({ persistedProperties: { cloud_user_id: userId } });
  }

  analytics.identify(data);

  analytics.track(SEGMENT_EVENTS.REGISTRATION_FORM_SUBMITTED, data);

  if (isBusinessEmail(data.email)) {
    analytics.track(SEGMENT_EVENTS.REGISTRATION_FORM_SUBMITTED_BUSINESS_EMAIL, data);
  }

  // make calls to eloqua - for a successful user
  eloquaPost({
    data: { ...data, anonymousId },
    locationObj: parsedSearchLocation,
    siteId: eloquaSiteId,
    clearbitData,
  });
};

export const trackRegistrationError = ({ errorCode, errorMessage, signUpSource, signUpMethod }: RegistrationError) => {
  analytics.track(SEGMENT_EVENTS.REGISTRATION_FAILED, {
    error_code: errorCode,
    error_message: errorMessage,
    signup_source: signUpSource,
    signup_method: signUpMethod,
  });
};

/*
  NOTE: This is a mirror of `isBusinessEmail` method from Marketing code hosted on
  mongodbcom-node repo and is used to confirm whether the domain of the email
  is business email or not to report to segment analytics.
*/
export const isBusinessEmail = (email) => {
  const nonBusinessEmails = [
    /* Default domains included */
    'aol.com',
    'att.net',
    'comcast.net',
    'facebook.com',
    'gmail.com',
    'gmx.com',
    'googlemail.com',
    'google.com',
    'hotmail.com',
    'hotmail.co.uk',
    'mac.com',
    'me.com',
    'mail.com',
    'msn.com',
    'live.com',
    'sbcglobal.net',
    'verizon.net',
    'yahoo.com',
    'yahoo.co.uk',

    /* Other global domains */
    'email.com',
    'fastmail.fm',
    'games.com' /* AOL */,
    'gmx.net',
    'hush.com',
    'hushmail.com',
    'icloud.com',
    'iname.com',
    'inbox.com',
    'lavabit.com',
    'love.com' /* AOL */,
    'outlook.com',
    'pobox.com',
    'protonmail.ch',
    'protonmail.com',
    'tutanota.de',
    'tutanota.com',
    'tutamail.com',
    'tuta.io',
    'keemail.me',
    'rocketmail.com' /* Yahoo */,
    'safe-mail.net',
    'wow.com' /* AOL */,
    'ygm.com' /* AOL */,
    'ymail.com' /* Yahoo */,
    'zoho.com',
    'yandex.com',

    /* United States ISP domains */
    'bellsouth.net',
    'charter.net',
    'cox.net',
    'earthlink.net',
    'juno.com',

    /* British ISP domains */
    'btinternet.com',
    'virginmedia.com',
    'blueyonder.co.uk',
    'freeserve.co.uk',
    'live.co.uk',
    'ntlworld.com',
    'o2.co.uk',
    'orange.net',
    'sky.com',
    'talktalk.co.uk',
    'tiscali.co.uk',
    'virgin.net',
    'wanadoo.co.uk',
    'bt.com',

    /* Domains used in Asia */
    'sina.com',
    'sina.cn',
    'qq.com',
    'naver.com',
    'hanmail.net',
    'daum.net',
    'nate.com',
    'yahoo.co.jp',
    'yahoo.co.kr',
    'yahoo.co.id',
    'yahoo.co.in',
    'yahoo.com.sg',
    'yahoo.com.ph',
    '163.com',
    'yeah.net',
    '126.com',
    '21cn.com',
    'aliyun.com',
    'foxmail.com',

    /* French ISP domains */
    'hotmail.fr',
    'live.fr',
    'laposte.net',
    'yahoo.fr',
    'wanadoo.fr',
    'orange.fr',
    'gmx.fr',
    'sfr.fr',
    'neuf.fr',
    'free.fr',

    /* German ISP domains */
    'gmx.de',
    'hotmail.de',
    'live.de',
    'online.de',
    't-online.de' /* T-Mobile */,
    'web.de',
    'yahoo.de',

    /* Italian ISP domains */
    'libero.it',
    'virgilio.it',
    'hotmail.it',
    'aol.it',
    'tiscali.it',
    'alice.it',
    'live.it',
    'yahoo.it',
    'email.it',
    'tin.it',
    'poste.it',
    'teletu.it',

    /* Russian ISP domains */
    'mail.ru',
    'rambler.ru',
    'yandex.ru',
    'ya.ru',
    'list.ru',

    /* Belgian ISP domains */
    'hotmail.be',
    'live.be',
    'skynet.be',
    'voo.be',
    'tvcablenet.be',
    'telenet.be',

    /* Argentinian ISP domains */
    'hotmail.com.ar',
    'live.com.ar',
    'yahoo.com.ar',
    'fibertel.com.ar',
    'speedy.com.ar',
    'arnet.com.ar',

    /* Domains used in Mexico */
    'yahoo.com.mx',
    'live.com.mx',
    'hotmail.es',
    'hotmail.com.mx',
    'prodigy.net.mx',

    /* Domains used in Brazil */
    'yahoo.com.br',
    'hotmail.com.br',
    'outlook.com.br',
    'uol.com.br',
    'bol.com.br',
    'terra.com.br',
    'ig.com.br',
    'itelefonica.com.br',
    'r7.com',
    'zipmail.com.br',
    'globo.com',
    'globomail.com',
    'oi.com.br',
  ];

  return nonBusinessEmails.indexOf(email.split('@')[1]) === -1;
};

export const getEmailType = (email) => (isBusinessEmail(email) ? EmailType.BUSINESS : EmailType.STANDARD);

export const shapeClearbitReveal = (clearbitResp): ClearbitData => {
  const company = clearbitResp?.company ?? {};
  const companyGeo = company.geo ?? {};
  const companyMetrics = company.metrics ?? {};
  const companyCategory = company.category ?? {};
  const geoIP = clearbitResp?.geoIP ?? {};
  const companyStreetAddress =
    companyGeo.streetNumber && companyGeo.streetName
      ? `${companyGeo.streetNumber} ${companyGeo.streetName}`
      : undefined;

  return {
    cbit_company_city: companyGeo.city,
    cbit_company_country_code: companyGeo.countryCode,
    cbit_company_domain: company.domain,
    cbit_company_employees_range: companyMetrics.employeesRange,
    cbit_company_country: companyGeo.country,
    cbit_company_industry: companyCategory.industry,
    cbit_company_latitude: companyGeo.lat,
    cbit_company_longitude: companyGeo.lng,
    cbit_company_annual_revenue: companyMetrics.annualRevenue,
    cbit_company_employees: companyMetrics.employees,
    cbit_company_name: company.name,
    cbit_company_revenue_range: companyMetrics.estimatedAnnualRevenue,
    cbit_company_state: companyGeo.state,
    cbit_company_street_address: companyStreetAddress,
    cbit_company_sub_industry: companyCategory.subIndustry,
    cbit_company_ticker: company.ticker,
    cbit_geoip_city: geoIP.city,
    cbit_geoip_country: geoIP.country,
    cbit_geoip_country_code: geoIP.countryCode,
    cbit_geoip_state_code: geoIP.stateCode,
  };
};

export const fetchAndShapeClearbit = async () => {
  const fetchedClearbitData = await api.auth.clearbitApi();
  return shapeClearbitReveal(fetchedClearbitData);
};

//TODO: CLOUDP-104556 - Remove whatever was able to be moved to the backend
export const sendAnalyticsEvents = async (queryParams, nextUrl, isVercelRegistration = false) => {
  const clearbitData = getClearbitData();
  const { uId, orgId, groupId, marketplaceAccountLink } = queryParams;

  if (uId || orgId || groupId) {
    // Also triggers identify call in Analytics SDK if uId present
    const updateStateProps: UpdateStateOptions = { persistedProperties: {} };

    if (uId) {
      updateStateProps.persistedProperties!.cloud_user_id = uId;
    }

    if (orgId) {
      updateStateProps.integrations = buildIntegrationsObject(orgId);
      updateStateProps.persistedProperties!.org_id = orgId;
    }

    if (groupId) {
      updateStateProps.persistedProperties!.group_id = groupId;
    }

    await analytics.updateState(updateStateProps);
  }

  // Send pageview:
  const sanitizedLocation = sanitizeLocation();
  analytics.page(PAGE_CALLS.REGISTRATION_SUCCESS, {
    path: sanitizedLocation,
    ...(!!marketplaceAccountLink
      ? {
          isFromMp: true,
          marketplaceSource: marketplaceAccountLink,
        }
      : {}),
    ...(isVercelRegistration && { context: 'vercel' }),
  });

  // Associate user with NDS plan:
  const identifyPromise = analytics.identify({
    nds_plan_type: 'NDS',
    ...clearbitData,
  });

  const trackPromise = analytics.track(
    isVercelRegistration ? SEGMENT_EVENTS.VERCEL_CONFIRMATION_SEEN : SEGMENT_EVENTS.REGISTRATION_SUCCESS_SEEN,
    {
      nextUrl,
      ...clearbitData,
      ...(isVercelRegistration && { context: 'vercel' }),
    },
    { timeout: 2000 }
  );
  return Promise.allSettled([identifyPromise, trackPromise]);
};

export const getClearbitData = () => {
  let clearbitData = Cookies.get(COOKIE_KEYS.CLEARBIT_DATA);
  try {
    clearbitData = JSON.parse(clearbitData);
  } catch {
    clearbitData = {};
  }
  return clearbitData;
};

export function getSignUpSource(
  signupType: PartnerType | undefined | null,
  isVercelIntegration?: boolean | null | undefined,
  isAtlasCLI?: boolean | null | undefined
) {
  if (isVercelIntegration) {
    return SignupSource.VERCEL;
  }

  if (isAtlasCLI) {
    return SignupSource.ATLAS_CLI;
  }

  switch (signupType) {
    case PartnerType.AWS:
      return SignupSource.AWS_MARKETPLACE;
    case PartnerType.AZURE:
      return SignupSource.AZURE_MARKETPLACE;
    case PartnerType.GCP:
      return SignupSource.GCP_MARKETPLACE;
    default:
      return SignupSource.ACCOUNT;
  }
}
