import { css } from '@emotion/react';
import { H3 } from '@leafygreen-ui/typography';

import SmallLoader from '@packages/components/SmallLoader';
import { ThirdPartyIntegrationDescription } from '@packages/components/ThirdPartyIntegration/styles/common';
import ThirdPartyIntegrationLayout from '@packages/components/ThirdPartyIntegration/ThirdPartyIntegrationLayout';

const descriptionStyle = css`
  text-align: center;
  font-size: 16px;
  color: #001e2b;
`;

const loaderStyle = css`
  margin: 50px 0px 20px 0px;
  text-align: center;
`;

const headerStyle = css`
  margin-top: 20px;
`;
const DataApiLoader = () => {
  return (
    <ThirdPartyIntegrationLayout>
      <H3 css={headerStyle}>Integrate Atlas with Vercel</H3>
      <div css={loaderStyle}>
        <SmallLoader />
      </div>
      <ThirdPartyIntegrationDescription css={descriptionStyle}>
        We are setting up your integration. This might take a minute.
      </ThirdPartyIntegrationDescription>
    </ThirdPartyIntegrationLayout>
  );
};

export default DataApiLoader;
