export enum ChartsOrganizationRole {
  GLOBAL = 'GLOBAL',
  ORG_OWNER = 'ORG_OWNER',
  ORG_GROUP_CREATOR = 'ORG_GROUP_CREATOR',
  ORG_BILLING_ADMIN = 'ORG_BILLING_ADMIN',
  ORG_READ_ONLY = 'ORG_READ_ONLY',
  ORG_MEMBER = 'ORG_MEMBER',
  ORG_UNKNOWN = 'ORG_UNKNOWN',
}

export enum ChartsGroupRole {
  GLOBAL = 'GLOBAL',
  CHARTS_PROJECT_OWNER = 'CHARTS_PROJECT_OWNER',
  CHARTS_DATA_ACCESS = 'CHARTS_DATA_ACCESS',
  CHARTS_PROJECT_MEMBER = 'CHARTS_PROJECT_MEMBER',
  CHARTS_UNKNOWN = 'CHARTS_UNKNOWN',
}

export interface ChartsUserRoleInfo {
  charts_organization_role: ChartsOrganizationRole;
  charts_group_role: ChartsGroupRole;
}
