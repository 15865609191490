import { getOr, set } from 'unchanged';

import * as api from 'js/common/services/api';

const SET_HOST_PARTITIONS = 'hostPartition/SET_HOST_PARTITIONS';

const initialState = {};

// reducer
export default function hostPartitionReducer(state = initialState, action) {
  switch (action.type) {
    case SET_HOST_PARTITIONS: {
      const { groupId, hostId } = action.meta;
      return set([groupId, hostId], action.payload, state);
    }
    default: {
      return state;
    }
  }
}

// selectors

const getHostPartitionResponse = (state, { groupId, hostId }) => getOr(null, ['hostPartition', groupId, hostId], state);

const getHostMainPartitionName = (state, { groupId, hostId }) => {
  const resp = getHostPartitionResponse(state, { groupId, hostId });
  if (resp == null) {
    return null;
  }

  // Just return the name of the first partition for now.
  return Object.keys(resp).find((partitionName) => resp[partitionName] != null && resp[partitionName][hostId] != null);
};
export { getHostMainPartitionName };

// action creators

const setHostPartitions = ({ groupId, hostId, partitions }) => ({
  type: SET_HOST_PARTITIONS,
  payload: partitions,
  meta: { groupId, hostId },
});

// Track in-flight requests for host partition information
const inProgressPartitionRequests = {};

export const loadHostPartitions =
  ({ groupId, hostId }) =>
  async (dispatch) => {
    // Track the request so subsequent dispatches of this action don't re-fetch unnecessarily
    const key = `${groupId}-${hostId}`;

    if (inProgressPartitionRequests[key] != null) {
      return inProgressPartitionRequests[key];
    }

    const requestPromise = api.host.getHostPartitions(groupId, hostId);
    inProgressPartitionRequests[key] = requestPromise;

    const resp = await requestPromise;
    const { partitions } = resp;
    dispatch(setHostPartitions({ groupId, hostId, partitions }));

    // The request is no longer in-flight, so stop tracking it.
    delete inProgressPartitionRequests[key];
  };
