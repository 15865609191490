import { ReactNode } from 'react';

import facepaint from 'facepaint';
import { useMediaQuery } from 'react-responsive';

/*  Breakpoints
  We support three screen sizes in our responsive designs:
  - small (0 to 767px), previously "mobile screens"
  - medium (768px to 1024px), previously "tablet screens"
  - large (1025px and up), previously "laptop/desktop screens"
*/
const small = 767;
const large = 1025;
const mediumMin = small + 1;
const mediumMax = large - 1;

// for use with emotion
export const mq = facepaint([
  `@media only screen and (max-width: ${small}px)`,
  `@media only screen and (min-width: ${mediumMin}px) and (max-width: ${mediumMax}px)`,
  `@media only screen and (min-width: ${large}px)`,
]);

// react hook

const smallViewportProps = { maxWidth: small };
const mediumMinusViewportProps = { maxWidth: mediumMax };
const mediumViewportProps = { minWidth: mediumMin, maxWidth: mediumMax };
const mediumPlusViewportProps = { minWidth: mediumMin };
const largeViewportProps = { minWidth: large };

const getMediaQueryProps = {
  small: smallViewportProps,
  mediumMinus: mediumMinusViewportProps,
  medium: mediumViewportProps,
  mediumPlus: mediumPlusViewportProps,
  large: largeViewportProps,
};

export const useViewportSize = (size: keyof typeof getMediaQueryProps) => {
  return useMediaQuery(getMediaQueryProps[size]);
};

// react components

interface MediaQueryProps {
  minWidth?: number | 'none';
  maxWidth?: number | 'none';
}

const getMediaQueryComponent = (mediaQueryProps: MediaQueryProps) => {
  return ({ children }: { children?: ReactNode }): JSX.Element | null => {
    const isMatchingSize = useMediaQuery(mediaQueryProps);
    return isMatchingSize ? (children as JSX.Element) : null;
  };
};

export const SmallViewport = getMediaQueryComponent(smallViewportProps);
export const MediumMinusViewport = getMediaQueryComponent(mediumMinusViewportProps);
export const MediumViewport = getMediaQueryComponent(mediumViewportProps);
export const MediumPlusViewport = getMediaQueryComponent(mediumPlusViewportProps);
export const LargeViewport = getMediaQueryComponent(largeViewportProps);
