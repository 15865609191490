import * as organization from '@packages/redux/modules/organization/index';
import { createReducer } from '@packages/redux/reducerUtils';

import * as api from 'js/common/services/api';

// actions
const SET_ORG_POLICIES = 'policy/SET_ORG_POLICIES';

// reducer
export default createReducer([], {
  [SET_ORG_POLICIES]: (state, action) => action.payload,
});

// selectors
export const getOrgPolicies = (state, props) => organization.getOrgData(state, props).policies || [];

// action creators
const setOrgPolicies = ({ policies, orgId }) => ({
  type: SET_ORG_POLICIES,
  payload: policies,
  meta: {
    orgId,
  },
});

export const loadOrgPolicies = (orgId) => (dispatch) => {
  return api.policy.getOrgPolicies({ orgId }).then((response) => {
    dispatch(
      setOrgPolicies({
        policies: response,
        orgId,
      })
    );
  });
};
