/* eslint-disable */
export function triggerQuantcastTrackingPixel() {
  // Taken directly from script provided by Marketing in CLOUDP-77381
  window._qevents = window._qevents || [];

  (function () {
    var elem = document.createElement('script');
    elem.src = (document.location.protocol == 'https:' ? 'https://secure' : 'http://edge') + '.quantserve.com/quant.js';
    elem.async = true;
    elem.type = 'text/javascript';
    var scpt = document.getElementsByTagName('script')[0];
    scpt.parentNode.insertBefore(elem, scpt);
  })();

  window._qevents.push({
    qacct: 'p-h1TyRnEGZ0qL2',
    labels: '_fp.event.Atlas Registration Confirmation',
    event: 'refresh',
  });
}
