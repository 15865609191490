import { ChangeEvent } from 'react';

import { css } from '@emotion/react';
import Checkbox from '@leafygreen-ui/checkbox';

import { FieldNames } from '@packages/types/auth/field';

import { checkbox } from './styles/form';

const checkboxCss = css(checkbox, {
  marginTop: 8,
});

export interface TermsOfServiceCheckboxProps {
  checked: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  variant?: 'atlas' | 'onprem';
  className?: string;
}

export default function TermsOfServiceCheckbox({
  checked = false,
  onChange,
  variant = 'atlas',
  className,
}: TermsOfServiceCheckboxProps) {
  let copyContent;

  if (variant === 'onprem') {
    copyContent = (
      <>
        I agree to the&nbsp;
        {/* JT: TODO: CLOUDP-44592 will update this link to point to generic TOS page */}
        <a target="_blank" rel="noopener noreferrer" href="http://www.mongodb.com/legal/evaluation-agreement">
          Evaluation Agreement
        </a>
      </>
    );
  } else {
    copyContent = (
      <>
        I accept the&nbsp;
        <a target="_blank" rel="noopener noreferrer" href="https://www.mongodb.com/legal/privacy-policy">
          Privacy Policy
        </a>
        &nbsp;and the&nbsp;
        <a target="_blank" rel="noopener noreferrer" href="https://www.mongodb.com/cloud-terms-and-conditions">
          Terms of Service
        </a>
      </>
    );
  }

  return (
    <Checkbox
      checked={checked}
      onChange={onChange}
      css={checkboxCss}
      label={copyContent}
      className={className}
      name={FieldNames.TOS}
    />
  );
}
