import Backbone from 'backbone';

import { DeploymentItem } from '@packages/types/nds/deployment';
import { LiveImportModel } from '@packages/types/nds/liveImport';
import { CloudProvider, InstanceSize } from '@packages/types/nds/provider';
import { ReplicationSpecList } from '@packages/types/nds/replicationSpec';
import { ServerlessBackupOptions } from '@packages/types/nds/serverlessBackupOptions';

export enum ClusterType {
  REPLICASET = 'REPLICASET',
  SHARDED = 'SHARDED',
  GEOSHARDED = 'GEOSHARDED',
}

export enum State {
  SUCCESS = 'SUCCESS',
  IDLE = 'IDLE',
  CREATING = 'CREATING',
  UPDATING = 'UPDATING',
  DELETING = 'DELETING',
  DELETED = 'DELETED',
  REPAIRING = 'REPAIRING',
}

export enum EncryptionAtRestProvider {
  NONE = 'NONE',
  AWS = 'AWS',
  AZURE = 'AZURE',
  GCP = 'GCP',
}

export enum RootCertType {
  DST = 'DST',
  ISRGROOTX1 = 'ISRGROOTX1',
}

export const ROOT_CERT_TYPES = [
  {
    label: 'DST Root CA X3 (Expires 9/21)',
    value: RootCertType.DST,
  },
  {
    label: 'ISRG Root X1',
    value: RootCertType.ISRGROOTX1,
  },
];

export enum VersionReleaseSystem {
  LTS = 'LTS',
  CONTINUOUS = 'CONTINUOUS',
}

export enum HostnameScheme {
  INTERNAL = 'INTERNAL',
  LEGACY = 'LEGACY',
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
  MESH = 'MESH',
}

export enum BiConnectorReadPreference {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  ANALYTICS = 'analytics',
}

export interface BiConnector {
  enabled: boolean;
  readPreference: BiConnectorReadPreference;
  publicHostname?: string;
  privateHostname?: string;
  privateLinkHostnamesMap?: Record<string, string>;
}

export interface ManagedNamespace {
  db: string;
  collection: string;
  customShardKey: string;
  isUniqueIndex?: boolean;
  isHashedIndex?: boolean;
  presplitHashedZones?: boolean;
  numInitialChunks?: number;
}

export interface GeoSharding {
  customZoneMapping: Record<string, string>;
  managedNamespaces: Array<ManagedNamespace>;
}

export interface NDSLabel {
  key: string;
  value: string;
}

export interface ResurrectOptions {
  clusterUniqueId: string;
}

export interface ClusterDescription {
  name: string;
  groupId?: string;
  uniqueId?: string;
  createDate?: string;
  groupName?: string;
  mongoDBVersion: string;
  mongoDBMajorVersion: string;
  fixedMongoDBFCV?: string;
  clusterType: ClusterType;
  replicationSpecList: ReplicationSpecList;
  diskSizeGB: number;
  diskSizeGBLimit?: number;
  mongoDBUriHosts: Array<string>;
  privateMongoDBUriHosts?: Array<string>;
  privateSrvAddress?: string;
  privateLinkMongoDBUriHosts?: Record<string, Array<string>>;
  privateLinkSrvAddresses?: Record<string, string>;
  mongoDBUriHostsLastUpdateDate: string;
  backupEnabled: boolean;
  diskBackupEnabled: boolean;
  pitEnabled: boolean;
  state: State;
  deleteAfterDate?: string;
  needsMongoDBConfigPublishAfter?: string;
  tenantUpgrading?: boolean;
  tenantAccessRevokedForPause?: boolean;
  tenantBackupEnabled?: boolean;
  serverlessBackupOptions?: ServerlessBackupOptions;
  isPaused: boolean;
  isUnderCompaction: boolean;
  biConnector: BiConnector;
  lastUpdateDate?: string;
  srvAddress?: string;
  clusterTags: Array<string>;
  geoSharding: GeoSharding;
  encryptionAtRestProvider: EncryptionAtRestProvider;
  isNvme: boolean;
  restrictedEmployeeAccessBypassDate?: string;
  labels: Array<NDSLabel>;
  isMonitoringPaused?: boolean;
  deploymentItemName?: string;
  deploymentClusterName?: string;
  forceReplicaSetReconfig?: boolean;
  resurrectRequested?: string;
  resurrectOptions?: ResurrectOptions;
  hostnameSubdomainLevel?: string;
  hostnameSchemeForAgents?: HostnameScheme;
  isCrossCloudCluster?: boolean;
  rootCertType: RootCertType;
  versionReleaseSystem: VersionReleaseSystem;
  endpointToLoadBalancedSRVConnectionURI?: Record<string, string>;
  isFastProvisioned: boolean;
  deviceSyncDebugAccessExpiresAfterDate?: string;
  isMongoDBVersionFixed: boolean;
  continuousDeliveryFCV?: string;
  '@provider'?: CloudProvider;
  terminationProtectionEnabled: boolean;
  encryptionAtRestValid?: boolean;
  instanceSize?: InstanceSize;
  numShards?: number;
  retainBackupsForDeleting?: boolean;
}

// Sparsely-typed Backbone models

export interface ClusterDescriptionModel {
  id: string;

  set: <Key extends keyof ClusterDescription>(key: Key, value: ClusterDescription[Key]) => void;
  get: <Key extends keyof ClusterDescription>(key: Key) => ClusterDescription[Key];
  getAttributes: () => ClusterDescription;
  clone: () => ClusterDescriptionModel;

  canGrantInfraAccess: (isOrganizationRestrictingEmployeeAccess: boolean) => boolean;
  canGrantSyncAccess: (hasLinkedSyncApplication: boolean) => boolean;
  getAnalyticsInstanceSize: () => InstanceSize;
  getCommandLineToolsUrl: () => string;
  getEncryptionAtRestProviderReadable: () => string;
  getGrantedEmployeeAccessTimeRemainingMs: () => number;
  getName: () => string;
  getGroupId: () => string;
  getLastUpdateDate: () => string;
  getMongoDBMajorVersion: () => number;
  getNumZones: () => number;
  getRemainingLifetime: () => { days?: number; hours?: number; minutes?: number } | null;
  getTotalNodes: () => number;
  getUniqueId: () => string;
  hasActiveRestrictedEmployeeAccessBypass: () => boolean;
  hasAnyBackupEnabled: () => boolean;
  hasDeviceSyncDebugAccessGranted: () => boolean;
  has1HourResolutionData: () => boolean;
  isBIConnectorEnabled: () => boolean;
  isCrossCloudCluster: () => boolean;
  isDeleting: () => boolean;
  isDiskBackupEnabled: () => boolean;
  isExpiring: () => boolean;
  isFastProvisioned: () => boolean;
  isFree: () => boolean;
  isGeoShardedCluster: () => boolean;
  isIdle: () => boolean;
  isMonitoringPaused: () => boolean;
  isMultiRegion: () => boolean;
  isPaused: () => boolean;
  setIsPaused: (isPaused: boolean) => void;
  isUnderCompaction: () => boolean;
  isPitEnabled: () => boolean;
  isServerless: () => boolean;
  isShardedCluster: () => boolean;
  isTenant: () => boolean;
  isTenantAccessRevokedForPause: () => boolean;
  isTenantUpgrading: () => boolean;
  supportsLiveImport: () => boolean;
  getInstanceSize: () => InstanceSize;
  getProvider: () => CloudProvider;
  getEncryptionAtRestValid: () => boolean;
  getNumShards: () => number;
  getClusterType: () => string;
  getReplicationSpecList: () => ReplicationSpecList;
  getCreateDate: () => string;
  isCreating: () => boolean;
  getServerlessRegionConfig: () => any;
  getPrivateLinkSRVAddresses: () => any;
  getRetainBackupsForDeleting: () => boolean;
}

export interface ClusterDetailModel {
  getClusterDescription: () => ClusterDescriptionModel;
  getDeploymentItem: () => DeploymentItem;
  getLiveImport: () => LiveImportModel;
  updateWithLiveImport: (imports: Backbone.Collection) => void;
  getDerivedBiConnectorState: () => string;
  getOpenAlerts: () => Backbone.Collection;
  updateWithOpenAlerts: (alerts: Backbone.Collection) => void;
  setDeploymentItem: (item: DeploymentItem) => void;
}

export interface ClusterDescriptionCollection extends Array<ClusterDescriptionModel> {
  first: () => ClusterDescriptionModel | undefined;
}

export interface ClusterDetailCollection {}

export interface AutoSetupSettings {
  automateSecurity: boolean;
  loadSampleDataset: boolean;
  clusterName: string;
}
