import { useEffect, useState } from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Banner from '@leafygreen-ui/banner';
import Button from '@leafygreen-ui/button';
import Toast from '@leafygreen-ui/toast';
import { H3 } from '@leafygreen-ui/typography';
import queryString from 'query-string';
import { Helmet } from 'react-helmet';

import { Search } from '@packages/types/auth/search';

import * as api from 'js/common/services/api';
import * as oktaUtils from 'js/common/utils/oktaUtils';
import { useRequestParams } from 'js/common/context/RequestParamsContext';
import { mq, useViewportSize } from 'js/common/utils/mediaQueries';
// analytics
import analytics, { PAGE_CALLS } from 'js/common/utils/segmentAnalytics';

import colors from 'js/auth/utils/palette';

import HeaderLogo from './HeaderLogo';
// images
import emailIcon from './images/email_icon.png';
import emailVerifiedIcon from './images/email_verified_icon.png';
import Layout from './Layout';

const Container = styled.div(() =>
  mq({
    margin: ['50px 0px 0 260px', '32px 16px', '50px 0px 0px 42px', '50px 0px 0px 260px'],
    width: ['338px', 'auto', '495px', '338px'],
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  })
);

const HeaderLogoContainer = styled.div(() =>
  mq({
    width: '338px',
    alignSelf: ['auto', 'auto', 'baseline', 'auto'],
  })
);

const BannerContainer = styled.div(() =>
  mq({
    margin: ['40px 0px 0px', '36px 19px 40px 24px', '40px 0px 0px', '40px 0px 0px'],
  })
);

const header = css(
  mq({
    letterSpacing: '0.6px',
    textAlign: 'center',
    marginTop: ['54px', '28px', '48px', '54px'],
  })
);

const EmailIcon = styled.img(() =>
  mq({
    width: '289px',
    height: '144px',
    margin: ['24px 16px 36px', '24px 96px 32px', '48px 96px 48px', '24px 16px 36px'],
  })
);

const EmailVerifiedIcon = styled.img(() =>
  mq({
    width: '312px',
    height: '200px',
    margin: ['24px 16px 36px', '24px 96px 32px', '48px 96px 48px', '24px 16px 36px'],
  })
);

const Text = styled.p(() =>
  mq({
    color: colors.labelText,
    fontSize: '15px',
    letterSpacing: '0',
    lineHeight: '19px',
    width: ['372px', '338px', '495px', '372px'],
    marginBottom: ['24px', '24px', '36px', '24px'],
    marginBlockStart: '0px',
  })
);

const resendLink = css({
  textDecoration: 'none',
  color: colors.link,
  cursor: 'pointer',
  fontSize: '15px',
});

const continueBtn = css({
  width: '160px',
  height: '48px',
});

const toastSuccess = css({
  left: '12px',
  maxWidth: 'calc(100vw - 24px)',
  bottom: '25px',
});
interface EmailVerificationPageProps {
  location?: Pick<Location, 'search'>;
  oktaSessionExists?: () => Promise<boolean>;
  windowLocation?: Pick<Location, 'assign'>;
}

export default function EmailVerificationPage({
  location: { search } = { search: '' },
  oktaSessionExists = oktaUtils.oktaSessionExists,
  windowLocation = window.location,
}: EmailVerificationPageProps) {
  const { analyticsEnabled, accountCentralUrl } = useRequestParams();

  const [hasEmailTokenVerificationError, setHasEmailTokenVerificationError] = useState(false);
  const [continueDisabled, setContinueDisabled] = useState(false);
  const [showToastComponent, setShowToastComponent] = useState(false);
  const parsedSearchLocation = queryString.parse(search) as Search;
  const { username, errorCode, uId, emailToken } = parsedSearchLocation;

  const needsToVerifyEmail = !!username;
  const hasLinkExpiredError = errorCode === 'INVALID_EMAIL_VERIFICATION_TOKEN';
  const pageTitle = needsToVerifyEmail ? 'Verify Email' : 'Email Verified';

  const isSmallViewport = useViewportSize('small');
  const isLargeViewport = useViewportSize('large');

  useEffect(() => {
    if (analyticsEnabled) {
      if (needsToVerifyEmail) {
        analytics.identify({
          userId: uId,
        });

        analytics.page(PAGE_CALLS.VERIFICATION_EMAIL, {
          userId: uId,
          resend: hasLinkExpiredError,
        });
      }
    }
  }, [analyticsEnabled]);

  const resendHandler = async () => {
    try {
      await api.auth.unauthedResendVerificationEmail({
        username,
        clientState: { ...parsedSearchLocation },
        analyticsPageTitle: pageTitle,
        emailToken,
      });

      if (isSmallViewport) {
        setShowToastComponent(true);
      }
    } catch (error) {
      console.log('An error occurred when resending verification email', error);
      if (error.errorCode == 'FORBIDDEN') {
        setHasEmailTokenVerificationError(true);
      }
      if (isSmallViewport) {
        setShowToastComponent(false);
      }
    }
  };

  const continueHandler = async () => {
    setContinueDisabled(true);
    try {
      const sessionExists: boolean = await oktaSessionExists();
      if (sessionExists) {
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ clientState: { inviteToken?: s... Remove this comment to see the full error message
        const { loginRedirect } = await api.auth.startOidc({ clientState: { ...parsedSearchLocation } });
        windowLocation.assign(loginRedirect);
      } else {
        // the presence of verificationToken will automatically verify user's email upon logging in
        windowLocation.assign(`/account/login${!!search ? search + '&' : '?'}signedOut=true`);
      }
    } catch (error) {
      setContinueDisabled(false);
      console.log("An error occurred when verifying user's email", error);
    }
  };

  return (
    <>
      <Helmet title={pageTitle} />
      <Layout contentPlacement="right">
        <Container>
          <HeaderLogoContainer>
            {' '}
            <HeaderLogo isCenter={isLargeViewport} />{' '}
          </HeaderLogoContainer>
          {needsToVerifyEmail ? (
            <>
              {hasEmailTokenVerificationError && (
                <BannerContainer>
                  <Banner variant="warning" data-testid="expiredBanner">
                    <b>An error occurred while validating your email verification resend request.</b> <br />
                    Please visit {accountCentralUrl} and re-login.
                  </Banner>
                </BannerContainer>
              )}
              {hasLinkExpiredError && (
                <BannerContainer>
                  <Banner variant="warning" data-testid="expiredBanner">
                    <b>Your verification link has expired.</b> <br />
                    Please click on the &quot;Resend email&quot; button to request a new email verification link.
                  </Banner>
                </BannerContainer>
              )}

              <H3 css={header}>Great, now verify your email</H3>
              <EmailIcon src={emailIcon} alt="Verify Email" />
              <Text data-testid="checkInbox">
                Check your inbox at <b>{username}</b> and click the verification link inside to complete your
                registration. This link will expire shortly, so verify soon!
              </Text>
              <Text data-testid="checkSpam">
                <b>Don&apos;t see an email?</b> Check your spam folder.
              </Text>
              <Text data-testid="linkExpired">
                <b>Link expired?</b>&nbsp;
                <a data-testid="resendLink" role="button" tabIndex={0} onClick={resendHandler} css={resendLink}>
                  Resend verification email
                </a>
              </Text>
              {showToastComponent && (
                <Toast
                  data-testid="resendVerificationEmailSuccess"
                  variant="success"
                  body={`We've sent a new verification email to <${username}>.`}
                  open={showToastComponent}
                  css={toastSuccess}
                />
              )}
            </>
          ) : (
            <>
              <H3 css={header}>Email successfully verified!</H3>
              <EmailVerifiedIcon src={emailVerifiedIcon} alt="Email Verified" />
              <Button
                variant="primary"
                onClick={continueHandler}
                size="default"
                name="continue"
                css={continueBtn}
                disabled={continueDisabled}
              >
                Continue
              </Button>
            </>
          )}
        </Container>
      </Layout>
    </>
  );
}
