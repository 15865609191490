import { BackupDeliveryMethod } from '@packages/types/nds/backup';

import { get } from 'js/common/utils/groupIdFromPath';

import fetchWrapper, { formParams } from '../fetchWrapper';

export default {
  getSnapshots(groupId, clusterUniqueId) {
    return fetchWrapper(`/nds/backup/serverless/${groupId}/${clusterUniqueId}/snapshots`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getRestoreHistory(groupId, clusterUniqueId) {
    return fetchWrapper(`/nds/backup/serverless/${groupId}/${clusterUniqueId}/restores`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getBackupJobMetadata(clusterUniqueId, groupId = get()) {
    return fetchWrapper(`/nds/backup/serverless/${groupId}/${clusterUniqueId}/job`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  createDownload(groupId, snapshotId, sourceClusterName) {
    const params = {
      snapshotId,
      sourceClusterName,
      deliveryMethod: BackupDeliveryMethod.MANUAL_DOWNLOAD,
    };

    return fetchWrapper(`/nds/backup/serverless/${groupId}/restore`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams(params),
    }).then((resp) => resp.json());
  },

  cancelDownload(groupId, restoreJobId) {
    return fetchWrapper(`/nds/backup/serverless/${groupId}/restore/${restoreJobId}`, {
      method: 'DELETE',
    }).then((resp) => resp.json());
  },

  // Note that this method does not use all arguments. This method signature is to keep consistent with the corresponding method in backupApi.js for generic use in AutomatedRestoreModal
  getRestoreDestinationItems(
    sourceGroupId,
    sourceClusterName,
    targetGroupId,
    isSharded,
    snapshotId,
    isPit,
    pitUTCSeconds,
    pitOplogTs,
    pitOplogInc
  ) {
    let url;
    if (isPit) {
      url =
        `/automation/restore/${targetGroupId}/destinationClustersCpsPit?sourceGroupId=${sourceGroupId}` +
        `&sourceClusterName=${sourceClusterName}&pitUTCSeconds=${pitUTCSeconds || ''}` +
        `&pitOplogTs=${pitOplogTs}&pitOplogInc=${pitOplogInc}`;
    } else {
      url = `/automation/restore/${targetGroupId}/destinationReplicaSets`;
    }

    return fetchWrapper(url, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  performAutomatedRestore(
    groupId,
    snapshotId,
    clusterName,
    destinationGroupId,
    destinationClusterName,
    isPit,
    pitUTCSeconds,
    pitOplogTs,
    pitOplogInc
  ) {
    const data = {
      snapshotId,
      deliveryMethod: isPit ? BackupDeliveryMethod.POINT_IN_TIME : BackupDeliveryMethod.AUTOMATION_PULL,
      sourceClusterName: clusterName,
      targetGroupId: destinationGroupId,
      targetClusterName: destinationClusterName,
      pointInTimeUTCSeconds: pitUTCSeconds,
      oplogTs: pitOplogTs,
      oplogInc: pitOplogInc,
    };

    return fetchWrapper(`/nds/backup/serverless/${groupId}/restore`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams(data),
    }).then((resp) => resp.json());
  },
};
