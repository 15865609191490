import { MouseEvent, useEffect, useState } from 'react';

import styled from '@emotion/styled';
import Button from '@leafygreen-ui/button';

import { StyledButton } from '@packages/types/leafygreen-emotion';

import { user } from 'js/common/services/api';
import { getErrorMessageFromCode } from 'js/common/services/authErrorHelper';
import { mq } from 'js/common/utils/mediaQueries';

import { AccordionBodyTextContainer, ErrorMessage, InfoText } from './styles/mfa';

const ButtonContainer = styled.div`
  display: flex;
  margin: 16px 0;
  height: 27px;
`;

const DisableButton = styled<StyledButton>(Button)<React.ComponentProps<'button'>>(() =>
  mq({
    flex: [0.2, 1, 1, 0.2],
    justifyContent: 'center',
    height: ['auto', '22px', '22px', 'auto'],

    '&:first-of-type': {
      marginRight: '8px',
    },
  })
);

interface DisableProps {
  active?: boolean;
  onDisableMfa: () => void;
  onCancel: (event: MouseEvent) => void;
  onAuthExpired: Function;
}

export default function Disable({ onDisableMfa, onCancel, onAuthExpired, active = false }: DisableProps) {
  const [errorCode, setErrorCode] = useState('');

  useEffect(() => {
    setErrorCode('');
  }, [active]);

  const disableMfa = async () => {
    try {
      await user.multiFactorAuthDelete();
      await onDisableMfa();
    } catch (e) {
      if (!onAuthExpired(e)) {
        setErrorCode(e.errorCode);
      }
    }
  };

  return (
    <AccordionBodyTextContainer>
      {errorCode && <ErrorMessage>{getErrorMessageFromCode(errorCode)}</ErrorMessage>}
      <InfoText>
        This will disable two-factor authentication for your account, permanently erasing any phone numbers, Google
        Authenticator codes, or backup codes that you have configured.
      </InfoText>
      <ButtonContainer>
        <DisableButton type="button" name="disableMfa" variant="danger" onClick={disableMfa}>
          Disable 2FA
        </DisableButton>
        <DisableButton type="button" name="cancel" onClick={onCancel}>
          Cancel
        </DisableButton>
      </ButtonContainer>
    </AccordionBodyTextContainer>
  );
}
