import fetchWrapper from '../fetchWrapper';

export function getAWSCustomDNSEnabled(groupId: string) {
  return fetchWrapper(`/nds/${groupId}/awsCustomDNS`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function updateAWSCustomDNS(groupId: string, awsCustomDNSEnabled: boolean) {
  return fetchWrapper(`/nds/${groupId}/awsCustomDNS`, {
    method: 'PATCH',
    body: JSON.stringify(awsCustomDNSEnabled),
  }).then((resp) => resp.json());
}
