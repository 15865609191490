import { useCallback, useEffect, useState } from 'react';

import {
  APIKey,
  DataAPIConfig,
  DeploymentModel,
  Location,
  PartialAPIKey,
  PresetRole,
  ProviderRegion,
} from 'baas-admin-sdk';
import { ObjectId } from 'bson';
import { useDispatch, useSelector } from 'react-redux';

import { DataSourceServiceType, LinkedDataSources, MongoService, MongoServiceConfig } from '@packages/types/dataAPI';

import * as app from '@packages/redux/modules/app';
import * as dataAPIActions from '@packages/redux/modules/dataAPI/actions';
import * as dataAPISelectors from '@packages/redux/modules/dataAPI/selectors';
import { DataAPIState } from '@packages/redux/modules/dataAPI/reducers';

// error reporting
import { CloudTeams, sendError } from 'js/common/utils/bugsnag';

import { AccessName, providerRegionToLocation } from 'js/project/dataAPI/constants';
import { useVercelQueryPropsForDataApi } from 'js/project/dataAPI/hooks/useVercelQueryPropsForDataApi';
import { dataSourceHasAccessRules, getURLEndpoint } from 'js/project/dataAPI/utils';

/**
 * Interface for all the values required by Data API redux actions. These are required when using this hook in a component / flow where these values don't preexist in the Redux store
 * e.g. while using this hook in the Auth flow where we might pick these values from query params instead
 */
interface InitConfig {
  groupId?: string;
  orgId?: string;
  username?: string;
  baasUrl?: string;
}

/**
 * Interface for all the props that are required to setup Data API
 * See each prop's own documentation for its explanation
 */
interface Props {
  /**
   * The cluster name to enable Data API for, also used to set the Data API API key name
   * e.g. Cluster0Vercel-Integration
   */
  uniqueClusterName: string;
  /**
   * Boolean flag that determines if Data API BaaS app and config should be loaded (or not). Useful for saving resources when used by
   * Vercel components where users aren't in the Data API variant
   */
  loadBaasApp: boolean;
  /**
   * Boolean flag to trigger Data API enablement for the given cluster {@link Props.uniqueClusterName}. This is different from the
   * loadBaasApp flag {@link Props.loadBaasApp} where the BaaS app is setup
   */
  enableDataApiForCluster: boolean;
  /**
   * A callback fn to invoke post completion of all tasks i.e. in this case after generating the Data API API key and URL slug
   * @returns unknown
   */
  fetchDataApiInfo: ({ apiKey, urlSlug }: { apiKey: APIKey | PartialAPIKey; urlSlug: string }) => unknown;
  /**
   * Optional config of type {@link InitConfig} used to persist the values in Redux for use by Data API
   */
  initConfig?: InitConfig;
  /**
   * Boolean flag that determines if the Data API error modal should be shown. It should only be shown to users on the Vercel side
   */
  showModalOnError?: boolean;
}

/**
 * Enum to track Data API enabling progress to trigger various events dependent on each phase
 */
enum DataApiEnablingPhase {
  IDLE,
  IS_ENABLING,
  DONE,
  FAILED,
}

/**
 * Report any errors that might occur at any point
 * @param error the actual error that occurred
 * @param errMsg captures the execution stage at which the error occurred
 */
const sendErrorToBugsnag = ({ error, errMsg }: { error: Error; errMsg: string }) => {
  sendError({
    error,
    team: CloudTeams.AtlasGrowth,
    metaData: {
      executionStage: errMsg,
      errLocation: 'Data API Connector Hook',
    },
  });
};

export function useDataApiConnector({
  loadBaasApp = false,
  enableDataApiForCluster = false,
  fetchDataApiInfo,
  initConfig,
  uniqueClusterName,
  showModalOnError = false,
}: Props) {
  // states
  const [url, setUrl] = useState<string>('');
  const [createdApiKey, setCreatedApiKey] = useState<APIKey | PartialAPIKey>();
  const [linkedDataSource, setLinkedDataSource] = useState<MongoService>();
  const [showEnableButton, setShowEnableButton] = useState(true);
  const [hasAccessPermissions, setHasAccessPermissions] = useState(false);
  const [rulesLoaded, setRulesLoaded] = useState(false);
  const [dataApiEnablingPhase, setDataApiEnablingPhase] = useState<DataApiEnablingPhase>(DataApiEnablingPhase.IDLE);

  // selectors
  const {
    enableDataAPIAppError,
    loadingDataAPIAppError,
    isCreatingDataAPIApp,
    baasApp,
    config,
    presetRoleConfigs,
    versions: dataAPIVersions,
    defaultRuleByDataSourceName,
    rulesByDataSourceName,
    endpointAPIUrlsByProviderRegion,
  }: DataAPIState = useSelector((state) => dataAPISelectors.getDataAPIState(state));
  const appId = useSelector((state) => dataAPISelectors.getBaasAppId(state));
  const orgId = useSelector(app.getActiveOrgId);
  const groupId = useSelector(app.getActiveGroupId);

  // dispatch
  const dispatch = useDispatch();
  const loadDataAPIApp = async (): Promise<void> => dispatch(dataAPIActions.loadDataAPIApp());
  const refreshAPIKeys = async (): Promise<Array<PartialAPIKey>> => dispatch(dataAPIActions.getAPIKeys({ appId }));
  const loadDataAPIConfig = async (): Promise<DataAPIConfig> => dispatch(dataAPIActions.loadDataAPIConfig(appId));
  const updateDataAPIConfig = async (newConfig: DataAPIConfig): Promise<void> =>
    dispatch(dataAPIActions.updateDataAPIConfig(appId, newConfig));
  const loadAuthProviders = async (): Promise<void> => dispatch(dataAPIActions.loadAuthProviders(appId));
  const getLinkedDataSources = async (): Promise<LinkedDataSources> => dispatch(dataAPIActions.getLinkedDataSources());
  const createDefaultRule = async (service: MongoService, presetRole: PresetRole): Promise<void> =>
    dispatch(dataAPIActions.createDataSourceDefaultRule(service, presetRole));
  const updateDefaultRule = async (service: MongoService, presetRole: PresetRole): Promise<void> =>
    dispatch(dataAPIActions.updateDataSourceDefaultRule(service, presetRole));
  const loadPresetRoleConfigs = async (): Promise<void> => dispatch(dataAPIActions.loadPresetRoleConfigs());
  const loadDataSourcesDefaultRule = async (services: Array<MongoService>): Promise<void> =>
    dispatch(dataAPIActions.loadDataSourcesDefaultRule(services));
  const loadDataSourceRules = async (services: Array<MongoService>): Promise<void> =>
    dispatch(dataAPIActions.loadDataSourceRules(services));
  const linkDataSourceAndCreateDefaultRule = async (name: string, role: PresetRole): Promise<void> =>
    dispatch(dataAPIActions.linkDataSourceAndCreateDefaultRule(name, role));
  const createAPIKey = async (key: APIKey): Promise<PartialAPIKey> =>
    dispatch(dataAPIActions.createAPIKey({ appId, key }));
  const enableDataAPI = (
    dataSources: Array<MongoServiceConfig>,
    deploymentModel: DeploymentModel,
    deploymentRegionLocation: Location,
    deploymentProviderRegion: ProviderRegion
  ): Promise<void> =>
    dispatch(
      dataAPIActions.enableDataAPIIfNecessary(
        dataSources,
        deploymentModel,
        deploymentRegionLocation,
        deploymentProviderRegion
      )
    );

  // Pick the latest Data API version to use
  const versions = config.versions ?? dataAPIVersions;
  const latestVersion = versions[versions.length - 1];

  // check for errors while loading / enabling Data API app
  const hasLoadingDataAppErrors = enableDataAPIAppError || loadingDataAPIAppError;

  // persist any passed query params values to Redux
  useVercelQueryPropsForDataApi({
    groupId: initConfig?.groupId,
    orgId: initConfig?.orgId,
    baasUrl: initConfig?.baasUrl,
    username: initConfig?.username,
  });

  /**  Start of helper methods **/

  // Helper method to generate the Data API URL slug
  const generateUrlSlug = async (): Promise<void> => {
    await loadDataAPIConfig()
      .then(() => {
        setUrl(
          getURLEndpoint({
            version: latestVersion,
            clientAppId: baasApp.getClientAppId(),
            deploymentModel: baasApp.getDeploymentModel(),
            providerRegionURL: endpointAPIUrlsByProviderRegion.get(baasApp.getProviderRegion()),
          })
        );
      })
      .catch((error) => {
        sendErrorToBugsnag({
          error,
          errMsg: 'Error occurred while loading Data API config',
        });
      });
  };

  // Helper method to generate the Data API API key
  const generateApiKey = async (): Promise<void> => {
    const objectId = new ObjectId();
    await createAPIKey(new APIKey({ name: `${uniqueClusterName}-Vercel-Integration-${objectId}` }))
      .then(
        (key: PartialAPIKey) => setCreatedApiKey(key),
        ({ message }) => {
          if (message !== '')
            sendErrorToBugsnag({
              error: message,
              errMsg: 'Error occurred while creating Data API API key',
            });
        }
      )
      .catch((error) => {
        sendErrorToBugsnag({
          error,
          errMsg: 'Error occurred while creating Data API API key',
        });
      });
  };

  // Helper method to verify an existing Data API config is not disabled
  // If disabled, update the config to enabled state
  const verifyDataAPIEnabled = async (): Promise<void> => {
    const currConfig = await loadDataAPIConfig();
    if (currConfig && currConfig.disabled) {
      await updateDataAPIConfig(
        new DataAPIConfig({
          ...currConfig,
          disabled: false,
        })
      ).catch((error) => {
        sendErrorToBugsnag({
          error,
          errMsg: 'Error occurred while updating Data API config',
        });
      });
    }
  };
  // Helper method to trigger Data API API key and URL slug generation
  const generateApiKeyAndUrlSlug = async (): Promise<void> => {
    await loadDataAPIApp();
    await generateUrlSlug();
    await generateApiKey();
    await verifyDataAPIEnabled();
    setDataApiEnablingPhase(DataApiEnablingPhase.DONE);
  };

  // Helper method to wrap the passed callback fn in a useCallback for caching
  const triggerPostCompletion = useCallback(() => {
    fetchDataApiInfo({ apiKey: createdApiKey!, urlSlug: url });
  }, [createdApiKey, fetchDataApiInfo, url]);

  /** End of helper methods */

  const fetchLinkedDataSourceIfNecessary = async () => {
    if (linkedDataSource) {
      return linkedDataSource;
    }
    const { mongoServices } = await getLinkedDataSources();
    const dataSource = mongoServices.find((source) => source.name === uniqueClusterName);
    setLinkedDataSource(dataSource);
    return dataSource;
  };

  /**
   *  Redirect to the error page if there is an error and groupId & orgID are set
   */
  useEffect(() => {
    if (dataApiEnablingPhase === DataApiEnablingPhase.FAILED && groupId && orgId && showModalOnError)
      window.location.assign(`/account/vercel/cluster/error?orgId=${orgId}&groupId=${groupId}`);
  }, [dataApiEnablingPhase, groupId, orgId, showModalOnError]);

  /**
   * Load the Data API app and preset role configs iff loadBaasApp {@link Props.loadBaasApp} is set to true.
   * This will occur on load for Vercel components in the background
   */
  useEffect(() => {
    if (!loadBaasApp) return;
    const loadDataApiAppAndRoles = async () => {
      await loadDataAPIApp().catch((error) => {
        sendErrorToBugsnag({ error, errMsg: 'Error occurred while loading Data API app' });
      });
      await loadPresetRoleConfigs().catch((error) => {
        sendErrorToBugsnag({
          error,
          errMsg: 'Error occurred while loading preset role configs app',
        });
      });
    };
    loadDataApiAppAndRoles();
  }, [loadBaasApp]);

  /**
   *  Once Data API BaaS app is loaded, load the default permissions
   */
  useEffect(() => {
    (async () => {
      if (baasApp && dataApiEnablingPhase === DataApiEnablingPhase.IDLE) {
        try {
          const linkedDataSourceConfig = await fetchLinkedDataSourceIfNecessary();
          if (!linkedDataSourceConfig) {
            setShowEnableButton(true);
            return;
          }
          await Promise.all([
            loadDataSourcesDefaultRule([linkedDataSourceConfig]),
            loadDataSourceRules([linkedDataSourceConfig]),
          ]);
        } catch (error) {
          sendErrorToBugsnag({
            error: error,
            errMsg: 'Error occurred while loading default permissions',
          });
        } finally {
          setRulesLoaded(true);
        }
      }
    })();
  }, [baasApp, dataApiEnablingPhase]);

  /**
   * Once the BaaS app is set up and default permissions have been loaded, set states for user access perms and enable button.
   * A user requires access permissions to be true in order to enable Data API for the org in scope. This is checked later in {@link handleEnableDataApi}
   * The button state will be later used to show a loader before the user is allowed to click on save on the calling components
   */
  useEffect(() => {
    if (baasApp && linkedDataSource && rulesLoaded) {
      const dataSourceName = linkedDataSource.name;
      const defaultDataSourceRule = defaultRuleByDataSourceName.get(dataSourceName);
      const hasAccessRules = dataSourceHasAccessRules(defaultDataSourceRule, rulesByDataSourceName.get(dataSourceName));
      setHasAccessPermissions(hasAccessRules);
      setShowEnableButton(!hasAccessRules);
    }
  }, [baasApp, linkedDataSource, defaultRuleByDataSourceName, rulesLoaded]);

  /**
   * Once Data API app has been successfully set up, generate the Data API API key and URL slug
   */
  useEffect(() => {
    if (baasApp && dataApiEnablingPhase === DataApiEnablingPhase.DONE) {
      (async () => {
        await refreshAPIKeys().catch((error) => {
          sendErrorToBugsnag({ error, errMsg: 'Error occurred while refreshing API keys' });
        });
        await generateUrlSlug();
        await verifyDataAPIEnabled();
        await loadAuthProviders().catch((error) => {
          sendErrorToBugsnag({
            error,
            errMsg: 'Error occurred while loading Data API Auth Providers',
          });
        });
        if (!createdApiKey) await generateApiKey();
      })();
    }
  }, [baasApp, dataApiEnablingPhase]);
  /**
   * Once the Data API API key and URL slug have been generated and
   * the cluster has been added to the VercelInfo, move on to the post
   * completion step passed as a prop. This only matters if the user
   * has opted to load BaaS App e.g. for Vercel, is in the variant
   */
  useEffect(() => {
    if (dataApiEnablingPhase === DataApiEnablingPhase.DONE && url !== '' && createdApiKey) triggerPostCompletion();
  }, [createdApiKey, url, dataApiEnablingPhase]);

  /**
   * Function to enable Data API triggered once
   * enableDataApiForCluster {@link Props.enableDataApiForCluster} is
   * set to true.
   * @returns void
   */
  const handleEnableDataApi = async () => {
    /* If any part of the hook (BaaS app setup, config/permissions/rules fetch) is in loading stage or there are errors during any of the stages, don't allow Data API setup yet
       Loading will be represented by a loader and errors by appropriate error msgs plus Bugsnag report. The enable button will also be disabled. */
    if (hasLoadingDataAppErrors || isCreatingDataAPIApp) return;
    /* If the enable button is disabled but there are no loading errors and a BaaS app already exists, simply generate the API Key and URL slug and mark the process as done */
    if (!showEnableButton && baasApp) {
      await generateApiKeyAndUrlSlug();
      return;
    }
    try {
      setDataApiEnablingPhase(DataApiEnablingPhase.IS_ENABLING);
      // Update permissions to read and write if Data API is enabled but cluster has no access permissions
      if (baasApp && !hasAccessPermissions) {
        const readAndWriteRoleConfig = presetRoleConfigs.find((role) => role.name === AccessName.ReadAndWrite);
        if (!readAndWriteRoleConfig) {
          return;
        }

        if (!linkedDataSource) {
          await linkDataSourceAndCreateDefaultRule(uniqueClusterName, readAndWriteRoleConfig);
        } else {
          const defaultDataSourceRule = defaultRuleByDataSourceName.get(linkedDataSource.name);
          const setPermissionsRequest = defaultDataSourceRule?.id ? updateDefaultRule : createDefaultRule;
          await setPermissionsRequest(linkedDataSource, readAndWriteRoleConfig);
        }

        setShowEnableButton(false);
        setHasAccessPermissions(true);
      } else {
        const dataSource = {
          clusterName: uniqueClusterName,
          serviceType: DataSourceServiceType.SVCTYPE_MONGODB_ATLAS,
        };

        await enableDataAPI(
          [dataSource],
          DeploymentModel.Global,
          providerRegionToLocation[ProviderRegion.AWSProviderRegionUSEast1],
          ProviderRegion.AWSProviderRegionUSEast1
        );

        setShowEnableButton(false);
      }
      setDataApiEnablingPhase(DataApiEnablingPhase.DONE);
    } catch (error) {
      setDataApiEnablingPhase(DataApiEnablingPhase.FAILED);
      sendErrorToBugsnag({ error, errMsg: 'Error occurred while enabling Data API app' });
    }
  };

  /**
   * Once {@link Props.enableDataApiForCluster} is set to true, call the helper fn to enable Data API
   */
  useEffect(() => {
    if (enableDataApiForCluster) handleEnableDataApi();
  }, [enableDataApiForCluster]);
}
