interface PlainObject {
  [key: string]: unknown;
}

const isPlainObject = (value): value is PlainObject => Object.prototype.toString.call(value) === '[object Object]';

function mergeObject<T>(destinationObj: T, sourceObj?: Partial<T | Extract<keyof T, string>>) {
  if (!isPlainObject(destinationObj)) {
    return sourceObj === undefined ? destinationObj : mergeObject<unknown>(sourceObj);
  }

  // destinationObj is an object, but the source isn't so just override it
  if (sourceObj !== undefined && !isPlainObject(sourceObj)) return sourceObj;

  const draft: PlainObject = { ...destinationObj };
  for (let prop in sourceObj) {
    draft[prop] = mergeObject<unknown>(destinationObj[prop], sourceObj[prop]);
  }

  return draft;
}

export const merge = (destinationObj: PlainObject, ...sourceObjs: Array<PlainObject>): PlainObject =>
  sourceObjs.reduce((draft, sourceObj) => mergeObject<PlainObject>(draft, sourceObj), destinationObj);
