import { useReducer } from 'react';

// reducers
import registrationReducer, { reducerState } from 'js/auth/reducers/registrationReducer';
import { RegistrationFormFields } from 'js/auth/types/registration';

export default function useRegistrationReducer(prefilledFormFields?: Partial<RegistrationFormFields>) {
  return useReducer(registrationReducer, reducerState, (state) => {
    return {
      ...state,
      formFields: {
        ...state.formFields,
        ...prefilledFormFields,
      },
    };
  });
}
