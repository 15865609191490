import * as React from 'react';

import styled from '@emotion/styled';
import { palette } from '@leafygreen-ui/palette';

const childHorizontalPadding = '10px';
const lineThickness = '1px';
const lineColor = palette.gray.light1;

const LineDividerContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;

  &:before,
  &:after {
    content: '';
    flex: 1 1 10%;
    border-bottom: ${lineThickness} solid ${lineColor};
    margin: 0 ${(props: { hasChildren: boolean }) => (props.hasChildren ? childHorizontalPadding : 0)};
    transform: translateY(-50%);
  }

  &:before {
    margin-left: 0;
  }

  &:after {
    margin-right: 0;
  }
`;

const LineDividerText = styled.span`
  font-size: 13px;
  line-height: 16px;
  font-weight: normal;
  color: ${palette.gray.dark1};
  text-align: center;
`;

export interface LineDividerProps {
  children?: React.ReactNode;
  className?: string;
}

export default function LineDivider({ children, className }: LineDividerProps) {
  return (
    <LineDividerContainer className={className} hasChildren={Boolean(children)}>
      {children && <LineDividerText>{children}</LineDividerText>}
    </LineDividerContainer>
  );
}
