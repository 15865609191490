import fetchWrapper from '../fetchWrapper';

export default {
  getIpWhitelist(groupId, centralUrl = '') {
    return fetchWrapper(`${centralUrl}/nds/${groupId}/ipWhitelist`, {
      method: 'GET',
      credentials: 'include',
    }).then((resp) => resp.json());
  },

  isWhitelistFreeOfZeroCidr(groupId) {
    return fetchWrapper(`/nds/${groupId}/isWhitelistFreeOfZeroCidr`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getPeerConnections(groupId, cloudProvider) {
    return fetchWrapper(`/nds/${groupId}/peers/${cloudProvider}`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getPeerConnectionsForGroup(groupId) {
    return fetchWrapper(`/nds/${groupId}/peers`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  terminatePeer(groupId, peerId) {
    return fetchWrapper(`/nds/${groupId}/peers/${peerId}`, {
      method: 'DELETE',
    }).then((resp) => resp.json());
  },

  validatePeeringAccess(groupId, peer) {
    return fetchWrapper(`/nds/${groupId}/peers/validate`, {
      method: 'POST',
      body: JSON.stringify(peer),
    }).then((resp) => resp.json());
  },

  getPrivateIpMode(groupId) {
    return fetchWrapper(`/nds/${groupId}/privateIpMode`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getAzureMultiTenantAppId(groupId) {
    return fetchWrapper(`/nds/${groupId}/azureMultiTenantAppId`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  updatePrivateIpMode(groupId, privateIpMode) {
    return fetchWrapper(`/nds/${groupId}/privateIpMode`, {
      method: 'PATCH',
      body: JSON.stringify(privateIpMode),
    }).then((resp) => resp.json());
  },

  putPeeringConnection(groupId, data) {
    return fetchWrapper(`/nds/${groupId}/peers`, {
      method: 'PUT',
      body: JSON.stringify(data),
    }).then((resp) => resp.json());
  },

  patchPeeringConnection(groupId, data) {
    return fetchWrapper(`/nds/${groupId}/peers`, {
      method: 'PATCH',
      body: JSON.stringify(data),
    }).then((resp) => resp.json());
  },
};
