import { LiveMigration } from '@packages/types/liveExport';

import fetchWrapper, { formParams } from './fetchWrapper';

export default {
  getUiMsgs() {
    return fetchWrapper('/uiMsgs', {
      credentials: 'include',
      method: 'GET',
    }).then((resp) => resp.json());
  },

  updateOrgPlan({ orgId, freeUntil, planType }) {
    const params = {
      freeUntil,
      planType,
    };

    return fetchWrapper(`/admin/orgs/${orgId}/plan`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams(params),
    }).then((resp) => resp.json());
  },

  unembargoOrg({ orgId }) {
    return fetchWrapper(`/v2/admin/legal/unembargoOrganization/${orgId}`, {
      method: 'POST',
    }).then((resp) => resp.json());
  },

  confirmEmbargo({ orgId }) {
    return fetchWrapper(`/v2/admin/legal/confirmEmbargo/${orgId}`, {
      method: 'POST',
    }).then((resp) => resp.json());
  },

  gdprEraseUser({ username }) {
    return fetchWrapper(`/v2/admin/legal/gdprEraseUser/${encodeURIComponent(username)}`, {
      method: 'DELETE',
    }).then((resp) => resp.json());
  },

  editServiceCredit({ id, reason, note }) {
    const params = JSON.stringify({
      reason,
      note,
    });

    return fetchWrapper(`/admin/billing/credits/credits/${id}`, {
      method: 'PATCH',
      body: params,
    }).then((resp) => resp.json());
  },

  deleteServiceCredit({ id }) {
    return fetchWrapper(`/admin/billing/credits/credits/${id}`, {
      method: 'DELETE',
    }).then((resp) => resp.json());
  },

  getApiUsers() {
    return fetchWrapper('/admin/apiUsers', {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  createApiUser({ desc, roles }) {
    return fetchWrapper('/admin/apiKeys', {
      method: 'POST',
      body: JSON.stringify({
        desc,
        roles,
      }),
    }).then((resp) => resp.json());
  },

  getGlobalApiRoles() {
    return fetchWrapper('/admin/apiKeys/roles', {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getGlobalApiKeys() {
    return fetchWrapper('/admin/apiKeys', {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getGlobalApiKey({ apiKeyId }) {
    const url = `/admin/apiKeys/${apiKeyId}`;
    return fetchWrapper(url, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  updateGlobalApiKey({ apiKeyId, desc, roles }) {
    const path = `/admin/apiKeys/${apiKeyId}`;
    return fetchWrapper(path, {
      method: 'PATCH',
      body: JSON.stringify({
        desc,
        roles,
      }),
    }).then((resp) => resp.json());
  },

  deleteGlobalApiKey({ userId }) {
    return fetchWrapper(`/admin/apiKeys/${userId}`, {
      method: 'DELETE',
    });
  },

  getAllPolicies() {
    return fetchWrapper('/admin/policies', {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getPolicyById({ policyId }) {
    return fetchWrapper(`/admin/policies/${policyId}`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getPolicyMetadata() {
    return fetchWrapper('/admin/policies/metadata', {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  createPolicyAsAdmin({ type, scope, planType, name, description, document }) {
    return fetchWrapper('/admin/policies', {
      method: 'POST',
      body: JSON.stringify({
        type,
        scope,
        planType,
        name,
        description,
        document,
      }),
    }).then((resp) => resp.json());
  },

  clonePolicyAsAdmin({ type, scope, planType, name, description, document, policyId }) {
    return fetchWrapper(`/admin/policies/${policyId}/clone`, {
      method: 'POST',
      body: JSON.stringify({
        type,
        scope,
        planType,
        name,
        description,
        document,
      }),
    }).then((resp) => resp.json());
  },

  patchPolicyAsAdmin({ type, scope, planType, name, description, document, policyId }) {
    return fetchWrapper(`/admin/policies/${policyId}`, {
      method: 'PATCH',
      body: JSON.stringify({
        type,
        scope,
        planType,
        name,
        description,
        document,
      }),
    }).then((resp) => resp.json());
  },

  deletePolicyAsAdmin({ policyId }) {
    return fetchWrapper(`/admin/policies/${policyId}`, {
      method: 'DELETE',
    });
  },

  getGlobalAccessList() {
    return fetchWrapper('/admin/accessList', {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  addGlobalAccessList({ cidrBlock, description }) {
    const params = { cidrBlock, description };
    return fetchWrapper('/admin/accessList', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: formParams(params),
    }).then((resp) => resp.json());
  },

  editGlobalAccessList({ id, cidrBlock, description }) {
    return fetchWrapper(`/admin/accessList/${id}`, {
      method: 'PATCH',
      body: JSON.stringify({
        cidrBlock,
        description,
      }),
    }).then((resp) => resp.json());
  },

  deleteGlobalAccessList({ id }) {
    return fetchWrapper(`/admin/accessList/${id}`, {
      method: 'DELETE',
    });
  },

  addProjectToOrg({ orgId, params }) {
    return fetchWrapper(`/admin/${orgId}/groups/addGroupSubmit`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams(params),
    }).then((resp) => resp.json());
  },

  addOrganization({ params }) {
    return fetchWrapper('/admin/orgs/addOrgSubmit', {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams(params),
    }).then((resp) => resp.json());
  },

  getExternalAuthenticationSettings() {
    return fetchWrapper('/admin/externalAuthenticationSettings', {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getSummaryInterval() {
    return fetchWrapper('/admin/alerts/globalAlerts/summaryInterval', {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getSourceOrgs() {
    return fetchWrapper('/admin/liveMigrations/sourceOrgs', {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getPushLiveMigrations(): Promise<Array<LiveMigration>> {
    return fetchWrapper('/admin/liveMigrations/pushLiveMigrations', {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getLiveExports() {
    return fetchWrapper('/admin/liveMigrations/liveExports', {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  failLiveExportsOnPrem({ id }) {
    return fetchWrapper(`/admin/liveMigrations/pushLiveMigrations/${id}/fail`, {
      method: 'PUT',
    }).then((resp) => resp.json());
  },

  getTargetOrgs() {
    return fetchWrapper('/admin/liveMigration/targetOrgs', {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  clearTargetOrgs() {
    return fetchWrapper('/admin/liveMigration/targetOrgs/clean', {
      method: 'DELETE',
    });
  },
};
