import * as React from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Icon from '@leafygreen-ui/icon';

import { mq } from 'js/common/utils/mediaQueries';

import { onChangeTypeDef } from 'js/auth/types/formInput';
import colors from 'js/auth/utils/palette';

import { Fieldset } from './styles/form';

interface Option {
  value: string;
  // eslint-disable-next-line react/no-unused-prop-types
  displayText?: string;
}

interface FormDropdownProps {
  fieldName?: string;
  labelName?: string;
  value?: string;
  onChange?: onChangeTypeDef;
  children?: React.ReactNode;
  options?: Array<string | Option>;
  defaultOption?: string;
}

const Label = styled.label(() => {
  return mq({
    fontSize: ['14px', '16px', '14px', '14px'],
    color: `${colors.labelText}`,
    position: 'relative',
    height: ['20px', '24px', '20px', '20px'],
    width: '36px',
    fontWeight: 'bold',
    lineHeight: ['20px', '24px', '20px', '20px'],
    letterSpacing: '0.02em',
  });
});

const CustomFieldset = styled(Fieldset)(() => {
  return {
    height: '64px',
  };
});

const Select = styled.select(() => {
  return mq({
    height: '40px',
    width: ['300px', 'calc(100vw - 32px)', '300px', '300px'],
    fontSize: '15px',
    display: 'block',
    marginTop: '5px',
    border: `1px solid ${colors.inputBorder}`,
    borderRadius: '2px',
    paddingLeft: '12px',
    background: colors.inputBackground,
    boxSizing: 'border-box',
    letterSpacing: '0.04em',
  });
});

const selectDropdownIconHide = css({
  webkitAppearance: 'none',
  mozAppearance: 'none',
  appearance: 'none',
  padding: '0 20px 0 12px',
});

const CustomIcon = styled(Icon)(
  mq({
    position: 'relative',
    left: ['272px', 'calc(100vw - 64px)', '272px', '272px'],
    bottom: '28px',
    backgroundColor: (props) => (props.disabled ? colors.disabledInputBackground : colors.iconBackground),
  })
);

export default function FormDropdown(props: FormDropdownProps) {
  const {
    fieldName = '',
    labelName = '',
    value = '',
    onChange = () => {},
    children,
    options = [],
    defaultOption = '',
  } = props;

  return (
    <CustomFieldset>
      {labelName && (
        <Label htmlFor={fieldName}>
          {labelName}
          <Select name={fieldName} value={value} onChange={onChange} css={selectDropdownIconHide}>
            <option key="default" value="">
              {defaultOption}
            </option>
            {options.map(({ value, displayText }: Option) => {
              return (
                <option key={value} value={value}>
                  {displayText ?? value}
                </option>
              );
            })}
          </Select>
          <CustomIcon glyph="CaretDown" />
        </Label>
      )}
      {children}
    </CustomFieldset>
  );
}
