export enum FromLocation {
  UPGRADE_CTA = 'ctaUpgrade',
  EMAIL_CTA = 'ctaEmail',
  CART_EMAIL = 'cartEmail',
  CLUSTER_HEADER_CTA = 'ctaClusterHeader',
  // Don't actually expect any "from" params to come through as 'DEFAULT'--this is mapped to the fallback value in FromLocationTrackingContexts
  DEFAULT = 'DEFAULT',
  // Not used for analytics tracking, need this to be able to go back to the right page from support FPP page
  SUPPORT = 'support',
  SUPPORT_MATRIX = 'supportMatrix',
  NETWORK_PRIVATE_ENDPOINT = 'networkPrivateEndpoint',
  STARTER_TEMPLATES = 'starterTemplates',
}
