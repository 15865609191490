import * as React from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Icon from '@leafygreen-ui/icon';
import { State } from '@leafygreen-ui/text-input';
import Tooltip from '@leafygreen-ui/tooltip';

import { RegexValidation } from '@packages/types/regexValidation';

import { mq } from 'js/common/utils/mediaQueries';

import { onChangeTypeDef } from 'js/auth/types/formInput';
import colors from 'js/auth/utils/palette';

import { CustomTextInput, Fieldset } from './styles/form';

export interface FormInputProps {
  fieldName?: string;
  labelName?: string;
  value?: string;
  regexValidation?: RegexValidation;
  skipRegexValidation?: boolean;
  type?: 'text' | 'password' | 'email' | 'tel';
  autoComplete?: string;
  children?: React.ReactNode;
  onChange?: onChangeTypeDef;
  showCheckmark?: boolean;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  hasError?: boolean;
  hidden?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  className?: string;
  placeholder?: string;
  tooltipText?: React.ReactNode;
  description?: string;
  errorMessage?: string;
  showChangeUsername?: boolean;
  optional?: boolean;
}

const Label = styled.label`
  font-size: 13px;
  color: ${colors.labelText};
  position: relative;
  height: 16px;
  width: 36px;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 0.02em;
`;

const iconStyle = css({
  marginLeft: '4px',
  marginBottom: '-4px',
});

const tooltipStyle = css({
  maxWidth: '275px',
});

// Reluctantly exporting Input to safely target and restyle the width of the element that's
// rendered from TextInput <- CustomTextInput, without using selectors such as "label > div"
// that would fragily rely on element order.
//
// NOTE: This is explicitly typed as "any" because @leafygreen-ui/text-input is missing an "export"
// needed for type declaration. (They need to export the "TextInputProps" interface.)
// When Visual Brand epic is complete, this "export" should be added
// into the LG module and this "any" can be removed
// TODO: https://jira.mongodb.org/browse/CLOUDP-116541
export const Input: any = styled(CustomTextInput)((props: { state: State; showChangeUsername: boolean }) => {
  return props.showChangeUsername
    ? mq({
        input: {
          paddingRight: props.state === 'error' ? '86px' : '62px',
        },
        marginTop: '5px',
      })
    : mq({
        marginTop: '5px',
      });
});

export default function FormInput(props: FormInputProps) {
  const {
    fieldName = '',
    labelName = '',
    type = 'text',
    value = '',
    regexValidation = {
      regExp: /\S/,
      errorMessage: 'Input is not valid',
    },
    skipRegexValidation,
    onChange = () => {},
    autoComplete,
    children,
    showCheckmark,
    onFocus,
    hasError = false,
    showChangeUsername = false,
    hidden = false,
    disabled = false,
    autoFocus = false,
    className = '',
    placeholder = '',
    tooltipText,
    description = '',
    errorMessage = '',
    optional = false,
  } = props;

  // Show explicitly provided error message if there is one. If not, use the provided regex validation's
  // error message if the value does not satisfy the regex.
  const precedentErrorMessage =
    errorMessage || (!regexValidation.regExp.test(value) ? regexValidation.errorMessage : '');

  // TODO: AM CLOUDP-78334 remove this tooltip, and then the label from leafygreen's input can be used
  const tooltipTrigger = (
    <span>
      <Icon glyph="InfoWithCircle" fill={colors.inputSubTitleText} size={16} title="" css={iconStyle} />
    </span>
  );

  // Prevent input from presenting error state before the user has even put in any input,
  // while respecting prefills as initial input
  const [afterFirstInput, setAfterFirstInput] = React.useState(value !== '');
  const onUserInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e);
    setAfterFirstInput(true);
  };

  let state: State = 'none';
  if (hasError) {
    state = 'error';
  } else if (showCheckmark) {
    state = 'valid';
  } else if (disabled) {
    // show neither valid nor error state given that this field is disabled
    state = 'none';
  } else if (!afterFirstInput) {
    state = 'none';
  } else if (skipRegexValidation) {
    state = 'none';
  } else if (regexValidation.regExp.test(value)) {
    state = 'valid';
    // After first input, but regex validation did not succeed. Render as 'error' state
  } else {
    state = 'error';
  }

  return (
    <Fieldset hidden={hidden} className={className}>
      {labelName && (
        <Label htmlFor={fieldName} id={`${fieldName}Label`}>
          {labelName}
          {tooltipText && (
            <Tooltip
              align="right"
              justify="middle"
              trigger={tooltipTrigger}
              triggerEvent="hover"
              css={tooltipStyle}
              id="formInputTooltip"
            >
              {tooltipText}
            </Tooltip>
          )}
          <Input
            label=""
            aria-labelledby={`${fieldName}Label`}
            autoFocus={autoFocus}
            type={type}
            name={fieldName}
            id={fieldName}
            value={value}
            onChange={onUserInput}
            autoComplete={autoComplete}
            onFocus={onFocus}
            state={state}
            disabled={disabled}
            placeholder={placeholder}
            description={description}
            errorMessage={precedentErrorMessage}
            optional={optional}
            showChangeUsername={showChangeUsername}
          />
        </Label>
      )}
      {children}
    </Fieldset>
  );
}
