import { useEffect, useState } from 'react';

import Button from '@leafygreen-ui/button';
import { H2 } from '@leafygreen-ui/typography';
import queryString from 'query-string';
import { Helmet } from 'react-helmet';

import { Search } from '@packages/types/auth/search';

import * as api from 'js/common/services/api';

import HeaderLogo from './HeaderLogo';
import Layout from './Layout';
import { Container, header } from './styles/form';
import { HeaderText } from './styles/reset';

interface UniversityPasswordResetPageProps {
  location?: Pick<Location, 'search'>;
}

export default function UniversityPasswordResetPage({
  location: { search } = { search: '' },
}: UniversityPasswordResetPageProps) {
  const [username, setUsername] = useState('');
  const [sentReset, setSentReset] = useState(false);

  const { email } = queryString.parse(search) as Search;

  useEffect(() => {
    if (email) {
      setUsername(email);
    }
  }, [email]);

  const onSubmit = async (e) => {
    e.preventDefault();
    setSentReset(true);
    if (!username) {
      return;
    }

    try {
      await api.auth.resetPassword({ username });
    } catch (exception) {
      console.log('Some error happened for resetting the password', exception);
    }
  };

  return (
    <>
      <Helmet title="Reset Password" />
      <Layout contentPlacement="left">
        <Container>
          <HeaderLogo />
          <H2 css={header}>
            {sentReset ? (
              'Check your Email'
            ) : (
              <>
                We&apos;ve Merged <br />
                Your Accounts
              </>
            )}
          </H2>
          <HeaderText>
            {sentReset ? (
              "We've sent a reset password email to your email address. If it doesn't appear within a few minutes, check your spam folder."
            ) : (
              <>
                We&apos;ve made it simpler to explore and use all of MongoDB with a <b>single account</b>. Reset your
                password now to get access to MongoDB University, Cloud (Atlas, App Services, Charts, and Cloud
                Manager), Support, and Community.
              </>
            )}
          </HeaderText>
          {sentReset ? (
            <Button size="default" href="/account/login">
              Return to Login
            </Button>
          ) : (
            <Button variant="primary" size="default" onClick={onSubmit}>
              Reset Password
            </Button>
          )}
        </Container>
      </Layout>
    </>
  );
}
