import { FormEvent, useState } from 'react';

import { css } from '@emotion/react';
import Button from '@leafygreen-ui/button';
import Card from '@leafygreen-ui/card';
import TextInput from '@leafygreen-ui/text-input';

import { FactorStatus, FactorType, OktaMfaFactor } from '@packages/types/accountMultiFactorAuth';

import VerifyPasscodeInput, {
  VerifyPasscodeInputResponse,
} from '@packages/components/MultiFactorAuth/VerifyPasscodeInput';

import * as api from 'js/common/services/api';
import { getErrorMessageFromCode } from 'js/common/services/authErrorHelper';
// common styles
import { setupStyles, Step } from 'js/common/styles/multiFactorAuth';
import { isLastAuthExpired } from 'js/common/utils/multiFactorAuthUtils';

const sendButtonStyles = css({
  width: '100px',
});

type InputState = 'none' | 'error' | 'valid';

export default function SmsSetupCard({
  windowLocation = window.location,
  authExpiredPath,
  onSuccess,
}: {
  windowLocation?: Pick<Location, 'assign'>;
  authExpiredPath: string;
  onSuccess: (response: VerifyPasscodeInputResponse | { userFactors: Array<OktaMfaFactor> }) => void;
}) {
  const [factorEnrollmentResponse, setFactorEnrollmentResponse] = useState<OktaMfaFactor>();
  const [allowSend, setAllowSend] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [inputState, setInputState] = useState<InputState>('none');
  const [errorMessage, setErrorMessage] = useState('');

  const enrollSmsFactor = async (e: FormEvent) => {
    setAllowSend(false);
    e.preventDefault();
    try {
      const response = await api.accountMultiFactorAuth.enrollFactor({
        factorType: FactorType.Sms,
        phoneNumber,
      });

      // Handle edge case where a number is automatically activated upon enrollment
      // if it had previously been verified by the current user

      if (response.status === FactorStatus.ACTIVE) {
        const userFactors = await api.accountMultiFactorAuth.getUserFactors();
        onSuccess({ userFactors });
      }

      setFactorEnrollmentResponse(response);
      setInputState('valid');

      // Enrolling the factor already sends a SMS, so we want to set
      // a timeout before allowing the user to send another request
      setTimeout(() => {
        setAllowSend(true);
      }, 30000);
    } catch ({ errorCode }) {
      if (isLastAuthExpired(errorCode)) {
        windowLocation.assign(authExpiredPath);
      } else {
        setErrorMessage(errorCode === 'RATE_LIMITED' ? getErrorMessageFromCode(errorCode) : 'Invalid phone number.');
        setInputState('error');
        setAllowSend(true);
      }
    }
  };

  return (
    <Card css={setupStyles.card}>
      <form onSubmit={enrollSmsFactor}>
        <Step.Container>
          <Step.Header>Step One</Step.Header>
          <p>Receive a code via SMS to authenticate. Enter your phone number below.</p>
          <TextInput
            css={setupStyles.phone}
            type="tel"
            placeholder="########"
            label="Phone Number"
            onChange={({ target: { value } }) => {
              setPhoneNumber(value);
              setInputState('none');
              setErrorMessage('');
            }}
            state={inputState}
            errorMessage={errorMessage}
            data-testid="phone-input"
          />
          <Button
            name="send"
            css={sendButtonStyles}
            type="submit"
            variant="primary"
            disabled={!allowSend || !phoneNumber}
          >
            Send Code
          </Button>
        </Step.Container>
        <Step.Container>
          <Step.Header>Step Two</Step.Header>
          <p>Enter the code sent to your phone number.</p>
          <VerifyPasscodeInput inputType="code" isActivation factor={factorEnrollmentResponse} onSuccess={onSuccess} />
        </Step.Container>
      </form>
    </Card>
  );
}
