// Utility to take a Class and apply an arbitrary number of mixins to it.

// Note: mixins are applying in order of definition, meaning the first mixin supplied
// will be the first to wrap the Clazz
export default function (Clazz) {
  return function (...args: Array<$TSFixMe>) {
    return [].slice.call(args).reduce((memo, mixin) => {
      return mixin(memo);
    }, Clazz);
  };
}
