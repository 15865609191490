import { useEffect } from 'react';

// eslint-disable-next-line no-restricted-imports
import * as fetchWrapper from 'js/common/services/api/fetchWrapper';
import { useRequestParams } from 'js/common/context/RequestParamsContext';
import { initializeBugsnag, setBugsnagMetadata } from 'js/common/utils/bugsnagInitializer';

export default function useBugsnag(appName: string, metaData: any, dependencies: Array<any> = []) {
  const { browserErrorTrackingEnabled } = useRequestParams();

  useEffect(() => {
    if (browserErrorTrackingEnabled) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ fetchWrapper: typeof fetchWrap... Remove this comment to see the full error message
      initializeBugsnag({
        fetchWrapper,
      });

      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ app: string; metaData: any; }'... Remove this comment to see the full error message
      setBugsnagMetadata({
        app: appName,
        metaData,
      });
    }
  }, [appName, ...dependencies]);
}
