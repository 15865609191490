import { useEffect, useState } from 'react';

import { H2 } from '@leafygreen-ui/typography';
import queryString from 'query-string';
import { Helmet } from 'react-helmet';

import { OktaMfaFactor } from '@packages/types/accountMultiFactorAuth';
import { Search } from '@packages/types/auth/search';

import VerifyFactorCard from '@packages/components/MultiFactorAuth/VerifyFactorCard';
import ThirdPartyIntegrationLayout from '@packages/components/ThirdPartyIntegration/ThirdPartyIntegrationLayout';
import ThirdPartyIntegrationPage from '@packages/components/ThirdPartyIntegration/ThirdPartyIntegrationPage';

import * as api from 'js/common/services/api';
import { getUniqueFactors } from 'js/common/utils/multiFactorAuthUtils';

import HeaderLogo from './HeaderLogo';
import Layout from './Layout';
import { header } from './styles/form';
import { MultiFactorAuthContainer } from './styles/multiFactorPage';

interface MultiFactorPageProps {
  windowLocation?: Pick<Location, 'assign' | 'href' | 'search'>;
}

export default function VerifyAccountMultiFactorAuthPage({ windowLocation = window.location }: MultiFactorPageProps) {
  const parsedSearchLocation = queryString.parse(windowLocation.search) as Search;
  const { stateToken, isVercelIntegration } = parsedSearchLocation;
  const clientState = { ...parsedSearchLocation };

  const [userFactors, setUserFactors] = useState<Array<OktaMfaFactor>>([]);

  const onMfaVerificationSuccess = ({ loginRedirect }) => {
    windowLocation.assign(loginRedirect);
  };

  useEffect(() => {
    async function getFactors() {
      try {
        const {
          _embedded: { factors },
        } = await api.auth.getAuthMfaState({ stateToken });
        setUserFactors(factors);
      } catch (error) {
        console.log("An error has occurred while getting the user's MFA factors:", error);
        windowLocation.assign('/account/login');
      }
    }

    getFactors();
  }, [stateToken]);

  const renderUserFactors = () => {
    return (
      userFactors.length > 0 && (
        <VerifyFactorCard
          availableFactors={getUniqueFactors(userFactors)}
          clientState={clientState}
          onSuccess={onMfaVerificationSuccess}
          isVercelIntegration={isVercelIntegration}
        />
      )
    );
  };

  if (isVercelIntegration) {
    return (
      <ThirdPartyIntegrationPage pageTitle="Verify Your Identity">
        <ThirdPartyIntegrationLayout title="Verify Your Identity">{renderUserFactors()}</ThirdPartyIntegrationLayout>
      </ThirdPartyIntegrationPage>
    );
  }
  return (
    <>
      <Helmet title="Verify Your Identity" />
      <Layout contentPlacement="right">
        <MultiFactorAuthContainer>
          <HeaderLogo /> <H2 css={header}>Verify Your Identity</H2> {renderUserFactors()}
        </MultiFactorAuthContainer>
      </Layout>
    </>
  );
}
