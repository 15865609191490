import { FormEvent, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Banner from '@leafygreen-ui/banner';
import { palette } from '@leafygreen-ui/palette';
import { Option, Select, Size } from '@leafygreen-ui/select';
import queryString from 'query-string';

import LineDivider from '@packages/components/LineDivider';
import { ThirdPartyIntegrationDescription } from '@packages/components/ThirdPartyIntegration/styles/common';
import ThirdPartyIntegrationLayout from '@packages/components/ThirdPartyIntegration/ThirdPartyIntegrationLayout';
import ThirdPartyIntegrationPage from '@packages/components/ThirdPartyIntegration/ThirdPartyIntegrationPage';

import * as api from 'js/common/services/api';
import { useAppUserParams } from 'js/common/context/AppUserContext';
import { useRequestParams } from 'js/common/context/RequestParamsContext';
import { CloudTeams, sendError } from 'js/common/utils/bugsnag';

import { SignupSource } from 'js/auth/utils/analyticsUtils';
import { createInitialOrgAndProject } from 'js/auth/utils/postRegistrationUtils';

const SpacedLineDivider = styled(LineDivider)`
  margin: 34px 0;
`;

const NewOrgLinkContainer = styled.div`
  margin-bottom: 48px;
`;

const NewOrgLink = styled.a`
  color: ${palette.blue.base};
  text-decoration: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;

interface Org {
  id: string;
  name: string;
  multiFactorAuthRequired: boolean;
  hasVercelIntegration: boolean;
  hasActiveVercelIntegration: boolean;
  isRestrictedByUiAccessList: boolean;
  numOwners: number;
  planType: string;
  roles: Array<string>;
}

const isOrgPermitted = (org: Org) => org.roles.includes('Organization Owner');

export default function VercelOrgSelectionPage({
  location: { search } = { search: '' },
  windowLocation = window.location,
}: {
  location?: Pick<Location, 'search'>;
  windowLocation?: Pick<Location, 'assign'>;
}) {
  const [orgs, setOrgs] = useState<Array<Org>>([]);
  const [selectedOrgId, setSelectedOrgId] = useState<string>('');
  const [hasError, setHasError] = useState(false);
  const [isContinueDisabled, setIsContinueDisabled] = useState(false);
  const { appUserParams, loadAppUserParams } = useAppUserParams();
  const { hasMultiFactorAuth, hasAccountMultiFactorAuth } = appUserParams;
  const userHasMultiFactorAuthEnabled = hasMultiFactorAuth || hasAccountMultiFactorAuth;
  const { centralUrl } = useRequestParams();
  const { signupMethod } = queryString.parse(search);

  useEffect(() => {
    loadAppUserParams();
  }, [loadAppUserParams]);

  const hasMfaForMfaRequiredOrg = useCallback(
    (org: Org) => !org.multiFactorAuthRequired || (org.multiFactorAuthRequired && userHasMultiFactorAuthEnabled),
    [appUserParams]
  );

  const defaultOrgId = useMemo(() => {
    let id = '';
    const selectableOrgs = orgs.filter((org) => isOrgPermitted(org) && !org.hasActiveVercelIntegration);
    if (selectableOrgs.length === 1) {
      id = selectableOrgs[0].id;
      setSelectedOrgId(id);
    }
    return id;
  }, [orgs, isOrgPermitted]);

  useEffect(() => {
    (async () => {
      try {
        const activeUserOrgs: Array<Org> = (await api.settings.activeUserOrganizations(centralUrl)).orgs;
        setOrgs(activeUserOrgs);
      } catch (error) {
        sendError({ error, team: CloudTeams.CoreIam });
      }
    })();
  }, [setOrgs]);

  const redirectUser = ({
    orgId,
    orgName,
    groupId,
    isNewVercelOrg,
  }: {
    orgId: string;
    orgName: string;
    groupId?: string;
    isNewVercelOrg: boolean;
  }): void => {
    let urlSearchParams = new URLSearchParams(search);

    // these params may already be present as queryParams so replace them to the
    // ones that the user has selected
    urlSearchParams.set('orgId', orgId);
    urlSearchParams.set('orgName', orgName);
    groupId && urlSearchParams.set('groupId', groupId);
    urlSearchParams.set('isNewVercelOrg', isNewVercelOrg.toString());

    let url = `/account/vercel/confirm${search ? `?${urlSearchParams.toString()}` : '?'}`;
    windowLocation.assign(url);
  };

  const handleNewOrgCreationClick = async (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setIsContinueDisabled(true);
    try {
      const { orgId, groupId, orgName } = await createInitialOrgAndProject({
        isNDS: true,
        centralUrl,
        signupSource: SignupSource.VERCEL,
        signupMethod: (signupMethod as string) ?? undefined,
      });
      redirectUser({ orgId, orgName, groupId, isNewVercelOrg: true });
    } catch (error) {
      sendError({ error, team: CloudTeams.CoreIam });
      setHasError(true);
      setIsContinueDisabled(false);
    }
  };

  const handleFormSubmission = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsContinueDisabled(true);
    const selectedOrg = orgs.find((org) => org.id === selectedOrgId);
    if (selectedOrgId && selectedOrg?.name) {
      redirectUser({
        orgId: selectedOrgId,
        orgName: selectedOrg.name,
        isNewVercelOrg: false,
      });
    }
  };

  const renderOrgOptions = () => {
    return orgs.map((org: Org) => {
      const isPermitted = isOrgPermitted(org);
      const userHasMfa = hasMfaForMfaRequiredOrg(org);
      let additionalText = '';

      if (!isPermitted) {
        additionalText = '(Insufficient Permissions)';
      } else if (!userHasMfa) {
        additionalText = '(MFA Required)';
      } else if (org.hasActiveVercelIntegration) {
        additionalText = '(Already Integrated)';
      } else if (org.isRestrictedByUiAccessList) {
        additionalText = '(Selection restricted by UI IP Access List)';
      } else if (org.id === defaultOrgId) {
        additionalText = '(default)';
      }

      return (
        <Option
          value={org.id}
          key={org.id}
          disabled={!isPermitted || !userHasMfa || org.hasActiveVercelIntegration || org.isRestrictedByUiAccessList}
        >
          {org.name} {additionalText}
        </Option>
      );
    });
  };

  return (
    <ThirdPartyIntegrationPage pageTitle="Vercel Organization Selection">
      <ThirdPartyIntegrationLayout
        title="Integrate Atlas with Vercel"
        isForm
        formProps={{
          onSubmit: handleFormSubmission,
          buttonText: 'Continue',
          disabled: !selectedOrgId || isContinueDisabled,
        }}
      >
        {hasError && (
          <Banner variant="danger" css={css({ margin: '24px 0px' })}>
            An error occurred with creating a new Atlas Organization. Please use one of the existing Atlas organizations
            to continue.
          </Banner>
        )}
        {orgs.length > 0 && (
          <>
            <Select
              label="Organization"
              description="Select an Atlas organization to integrate Vercel with."
              size={Size.Default}
              onChange={setSelectedOrgId}
              value={selectedOrgId}
              data-testid="atlas-organization-select"
            >
              {renderOrgOptions()}
            </Select>
            <SpacedLineDivider>or</SpacedLineDivider>
          </>
        )}
        <NewOrgLinkContainer>
          <NewOrgLink href="#" onClick={handleNewOrgCreationClick}>
            Create a new Atlas Organization
          </NewOrgLink>
        </NewOrgLinkContainer>
        <ThirdPartyIntegrationDescription css={{ color: palette.gray.dark2, marginBottom: '-24px' }}>
          A confirmation email will be sent to you with more detailed information regarding your new Vercel integration.
        </ThirdPartyIntegrationDescription>
      </ThirdPartyIntegrationLayout>
    </ThirdPartyIntegrationPage>
  );
}
