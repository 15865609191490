import ParentModel from 'backbone-parentmodel';
import moment from 'moment';

import deepClone from 'js/common/utils/deepClone';
import mixinBeforeExtension from 'js/common/utils/mixinBeforeExtension';
import validationMixin from 'js/common/utils/mixins/validation';
import mixInto from 'js/common/utils/mixInto';

import managedNamespaceUtils from 'js/project/nds/clusters/util/managedNamespaceUtils';
import replicationSpecListUtils from 'js/project/nds/clusters/util/replicationSpecListUtils';

import BIConnectorSettings from './BIConnectorSettings';
import ShardKeyType from './ShardKeyType';

// model

const mixinBeforeValidation = mixinBeforeExtension(validationMixin);

const VALID_CLUSTER_NAME_REGEX = /^[a-zA-Z0-9][a-zA-Z0-9-]*$/;

const PROVIDER_READABLE = {
  AWS: 'AWS',
  AZURE: 'Azure',
  GCP: 'GCP',
};

const PROVIDER_EAR_MAP = {
  AWS: 'awsKms',
  AZURE: 'azureKeyVault',
  GCP: 'googleCloudKms',
};

const EAR_PROVIDER_READABLE = {
  AWS: 'AWS KMS',
  AZURE: 'Azure Key Vault',
  GCP: 'Google Cloud KMS',
};

export default mixInto(ParentModel)(mixinBeforeValidation).extend(
  {
    idAttribute: 'name',

    childModels: {
      biConnector: BIConnectorSettings,
    },

    defaults() {
      // Set the defaults that should have explicit types for testing convenience.
      return {
        mongoDBUriHosts: [],
        backupEnabled: false,
        diskBackupEnabled: false,
        isPaused: false,
        clusterTags: [],
        biConnector: new BIConnectorSettings({}),
        encryptionAtRestValid: true,
      };
    },

    /**
     * Return a map of the managed namespaces keyed by the database name
     */
    getManagedNamespacesAsMap() {
      const result: $TSFixMe = {};
      const namespaces = this.get('geoSharding').managedNamespaces;
      namespaces.forEach((ns) => {
        if (result[ns.db]) {
          result[ns.db].push(ns);
        } else {
          result[ns.db] = [ns];
        }
      });
      return result;
    },

    getManagedNamespace(db, collection) {
      const namespaces = this.get('geoSharding').managedNamespaces;
      return namespaces.find((ns) => ns.db === db && ns.collection === collection);
    },

    supportsLiveImport() {
      // Geo-Zoned sharded clusters do not support live import
      return this.getClusterType() !== 'GEOSHARDED';
    },

    supportsRetryWrite() {
      return this.getMongoDBMajorVersion() >= 3.6;
    },

    supportsIAMAuth() {
      return this.getMongoDBMajorVersion() >= 4.4;
    },

    isDecomposedShardedCluster() {
      return this.get('clusterTags').includes('DECOMPOSED_SHARDED_CLUSTER');
    },

    getName() {
      return this.get('name');
    },

    getUniqueId() {
      return this.get('uniqueId');
    },

    getProvider() {
      return (
        this.get('replicationSpecList') &&
        replicationSpecListUtils.getCloudProviders(this.get('replicationSpecList'))[0]
      );
    },

    getEffectiveProviderFromReplicationSpecList() {
      if (((this.isFree() && this.isTenant()) || this.isServerless()) && this.get('replicationSpecList')) {
        return this.getReplicationSpec().regionConfigs[0].electableSpecs.backingProvider;
      }
    },

    getNumShards() {
      return this.get('replicationSpecList').reduce((acc, spec) => {
        return acc + spec.numShards;
      }, 0);
    },

    getNumZones() {
      return this.get('replicationSpecList').length;
    },

    getClusterType() {
      return this.get('clusterType');
    },

    getRetainBackupsForDeleting() {
      return this.get('retainBackupsForDeleting');
    },

    getEncryptionAtRestProvider() {
      return this.get('encryptionAtRestProvider');
    },

    getEncryptionAtRestProviderReadable() {
      return EAR_PROVIDER_READABLE[this.get('encryptionAtRestProvider')] || '';
    },

    setEncryptionAtRestProvider(provider) {
      this.set('encryptionAtRestProvider', provider);
    },

    getEncryptionAtRestValid() {
      return this.get('encryptionAtRestValid');
    },

    setEncryptionAtRestValid(valid) {
      this.set('encryptionAtRestValid', valid);
    },

    updateWithEncryptionAtRest(encryptionAtRest) {
      const encryptionAtRestProvider = this.getEncryptionAtRestProvider();
      if (!encryptionAtRestProvider) {
        return this.setEncryptionAtRestValid(true);
      }
      const encryptionAtRestKey = PROVIDER_EAR_MAP[encryptionAtRestProvider];
      const encryptionAtRestByProvider = encryptionAtRest.get(encryptionAtRestKey);

      // unknown provider encountered exit with default
      if (!encryptionAtRestByProvider) {
        return;
      }

      if (!encryptionAtRestByProvider.enabled) {
        return this.setEncryptionAtRestValid(true);
      }

      return this.setEncryptionAtRestValid(encryptionAtRestByProvider.valid);
    },

    getReplicationSpecList() {
      return this.get('replicationSpecList');
    },

    getReplicationSpec() {
      return this.get('replicationSpecList') && this.get('replicationSpecList')[0];
    },

    getReplicationSpecById(replicationSpecId) {
      const replicationSpecs = this.get('replicationSpecList');
      return replicationSpecs.find((spec) => {
        return spec.id === replicationSpecId;
      });
    },

    getReplicationSpecByZoneName(zoneName) {
      return this.getReplicationSpecList().find((spec) => spec.zoneName === zoneName);
    },

    getMongoDBMajorVersion() {
      return parseFloat(this.get('mongoDBMajorVersion')).toFixed(1);
    },

    needsMongoDBConfigPublish() {
      return this.get('needsMongoDBConfigPublishAfter') != null;
    },

    getLastUpdateDate() {
      return this.get('lastUpdateDate');
    },

    getCreateDate() {
      const dateString = this.get('createDate');
      return dateString ? new Date(dateString) : null;
    },

    isFree() {
      return this.getInstanceSize() === 'M0';
    },

    isTenant() {
      return this.getProvider() === 'FREE';
    },

    isServerless() {
      return this.getProvider() === 'SERVERLESS';
    },

    isFastProvisioned() {
      return this.get('isFastProvisioned');
    },

    getServerlessBackupOptions() {
      return this.get('serverlessBackupOptions');
    },

    isHeadBackupEnabled() {
      return this.get('backupEnabled');
    },

    setHeadBackupEnabled(headBackupEnabled) {
      this.set('backupEnabled', headBackupEnabled);
    },

    isDiskBackupEnabled() {
      return this.get('diskBackupEnabled');
    },

    setDiskBackupEnabled(diskBackupEnabled) {
      this.set('diskBackupEnabled', diskBackupEnabled);
    },

    isTenantBackupEnabled() {
      return this.get('tenantBackupEnabled');
    },

    setTenantBackupEnabled(tenantBackupEnabled) {
      this.set('tenantBackupEnabled', tenantBackupEnabled);
    },

    hasAnyBackupEnabled() {
      return this.isDiskBackupEnabled() || this.isHeadBackupEnabled() || this.isTenantBackupEnabled();
    },

    getGroupId() {
      return this.get('groupId');
    },

    isNew() {
      return !this.has('groupId');
    },

    isCreating() {
      return this.get('state') === 'CREATING';
    },

    isUpdating() {
      return this.get('state') === 'UPDATING';
    },

    isRepairing() {
      return this.get('state') === 'REPAIRING';
    },

    isDeleting() {
      return this.get('state') === 'DELETING';
    },

    isIdle() {
      return this.get('state') === 'IDLE' && !this.get('isPaused');
    },

    isPaused() {
      return this.get('isPaused');
    },

    isUnderCompaction() {
      return this.get('isUnderCompaction');
    },

    isMonitoringPaused() {
      return this.get('isMonitoringPaused');
    },

    isTenantUpgrading() {
      return this.get('tenantUpgrading');
    },

    isTenantAccessRevokedForPause() {
      return this.get('tenantAccessRevokedForPause');
    },

    isShardedCluster() {
      return this.getClusterType() === 'SHARDED' || this.getClusterType() === 'GEOSHARDED';
    },

    isGeoShardedCluster() {
      return this.getClusterType() === 'GEOSHARDED';
    },

    isExpiring() {
      return this.has('deleteAfterDate');
    },

    isAutoScaleDiskEnabled() {
      const autoScaling = replicationSpecListUtils.getFirstAutoScaling(this.get('replicationSpecList'));
      return autoScaling && autoScaling.diskGB && autoScaling.diskGB.enabled;
    },

    setAutoScaleDiskEnabled(isEnabled) {
      const replicationSpecList = this.get('replicationSpecList');
      const autoScaling = replicationSpecListUtils.getFirstAutoScaling(replicationSpecList);
      const updated = {
        ...deepClone(autoScaling),
        diskGB: {
          enabled: isEnabled,
        },
      };

      this.set('replicationSpecList', replicationSpecListUtils.unsafeUpdateAutoScaling(updated, replicationSpecList));
    },

    isAutoIndexEnabled() {
      const autoScaling = replicationSpecListUtils.getFirstAutoScaling(this.get('replicationSpecList'));
      return autoScaling?.autoIndex?.enabled;
    },

    setAutoIndexEnabled(isEnabled) {
      const replicationSpecList = this.get('replicationSpecList');
      const autoScaling = replicationSpecListUtils.getFirstAutoScaling(replicationSpecList);
      const updated = {
        ...deepClone(autoScaling),
        autoIndex: {
          enabled: isEnabled,
        },
      };

      this.set('replicationSpecList', replicationSpecListUtils.unsafeUpdateAutoScaling(updated, replicationSpecList));
    },

    setIsPaused(isPaused) {
      this.set('isPaused', isPaused);
    },

    getHoursSinceCreation() {
      const createDate = moment(this.get('createDate'));
      const now = moment();
      return now.diff(createDate, 'hours');
    },

    getRemainingLifetime() {
      const deletionDateString = this.get('deleteAfterDate');
      if (!deletionDateString) {
        return { days: Number.POSITIVE_INFINITY };
      }

      const deletionDate = moment(new Date(deletionDateString));
      const now = moment();
      const dur = moment.duration(deletionDate.diff(now));
      const days = Math.floor(dur.asDays());
      const hours = Math.floor(dur.subtract(days, 'd').asHours());

      // If there is less than 2 hours left, show the total remaining time
      // as minutes to make it clear that the cluster is expiring shortly.
      if (days === 0 && hours < 2 && hours >= 0) {
        return { minutes: Math.floor(dur.asMinutes()) };
      }

      const minutes = Math.floor(dur.subtract(hours, 'h').asMinutes());
      if (days < 0 || hours < 0 || minutes < 0) {
        return null;
      }

      return { days, hours, minutes };
    },

    getRegionReadable(regionName) {
      const region = this.getReplicationSpec().regionConfigs.find((region) => region.regionName === regionName);
      return (
        region && region.regionView && `${PROVIDER_READABLE[region.regionView.provider]} / ${region.regionView.name}`
      );
    },

    getRegionReadableNoProvider(regionName) {
      const region = this.getReplicationSpec().regionConfigs.find((region) => region.regionName === regionName);
      return region && region.regionView && region.regionView.name;
    },

    getRegionConfigs() {
      const replicationSpecList = this.getReplicationSpecList();
      return replicationSpecList.flatMap((replicationSpec) => replicationSpec.regionConfigs);
    },

    getRegions() {
      return this.getRegionConfigs().map((regionConfig) => regionConfig.regionName);
    },

    getRegionsReadable() {
      return this.getRegions().map(
        (regionName) => this.getRegionConfigs().find((region) => region.regionName === regionName).regionView.name
      );
    },

    getServerlessRegionConfig() {
      if (this.isServerless() && this.get('replicationSpecList')) {
        return this.getReplicationSpec().regionConfigs[0];
      }
    },

    getPreferredRegion() {
      return (
        this.getReplicationSpecList() &&
        replicationSpecListUtils.getPreferredRegions(this.getReplicationSpecList())[0].regionName
      );
    },

    getPreferredRegionById(replicationSpecId) {
      const replicationSpec = this.getReplicationSpecById(replicationSpecId);
      return replicationSpec && replicationSpecListUtils.getPreferredRegions([replicationSpec])[0].regionName;
    },

    isMultiRegion() {
      return (
        this.getReplicationSpec() &&
        this.getReplicationSpec().regionConfigs &&
        this.getReplicationSpec().regionConfigs.length > 1
      );
    },

    getRegion() {
      return this.getPreferredRegion();
    },

    getTotalNodes() {
      return replicationSpecListUtils.getTotalNodes(this.getReplicationSpecList()[0]);
    },

    getAdminUser() {
      return this.adminUser;
    },

    isCrossCloudCluster() {
      return this.get('isCrossCloudCluster');
    },

    hostnameSubdomainLevelSupportsSrv() {
      return !this.isCrossCloudCluster() || this.get('hostnameSubdomainLevel') === 'MONGODB';
    },

    getPrivateLinkSRVAddresses() {
      return this.get('privateLinkSrvAddresses');
    },

    getPrivateSRVAddress() {
      return this.get('privateSrvAddress');
    },
    getEndpointToLoadBalancedSRVConnectionURI() {
      return this.get('endpointToLoadBalancedSRVConnectionURI');
    },

    getPrivateLinkConnectHostnamePortShell() {
      const map = this.get('privateLinkMongoDBUriHosts');
      const interfaceIds = map ? Object.keys(map) : [];
      const idsToJoinedUris: $TSFixMe = {};

      interfaceIds.forEach((id) => {
        const uris = map[id];
        if (uris && uris.length) {
          if (this.isShardedCluster()) {
            // for clusters the URIs are all for mongos; return the first
            idsToJoinedUris[id] = uris[0];
          } else {
            idsToJoinedUris[id] = uris.join(',');
          }
        }
      });
      return idsToJoinedUris;
    },

    getPrivateConnectHostnamePortShell() {
      const uris = this.get('privateMongoDBUriHosts');

      if (uris && uris.length) {
        if (this.isShardedCluster()) {
          return uris[0]; // for clusters the URIs are all for mongos; return the first
        }
        return uris.join(',');
      }
    },

    getConnectHostnamePortShell() {
      const uris = this.get('mongoDBUriHosts');

      if (uris && uris.length) {
        if (this.isShardedCluster()) {
          return uris[0]; // for clusters the URIs are all for mongos; return the first
        }
        return uris.join(',');
      }
    },

    getConnectHostnamePortDriver() {
      const uris = this.get('mongoDBUriHosts');

      if (uris && uris.length) {
        return uris.join(',');
      }
    },

    getPrivateConnectHostnamePortDriver() {
      const uris = this.get('privateMongoDBUriHosts');

      if (uris && uris.length) {
        return uris.join(',');
      }
    },

    getPrivateLinkConnectHostnamePortDriver() {
      const map = this.get('privateLinkMongoDBUriHosts');
      const interfaceIds = map ? Object.keys(map) : [];
      const idsToJoinedUris: $TSFixMe = {};
      interfaceIds.forEach((id) => {
        const uris = map[id];
        if (uris && uris.length) {
          idsToJoinedUris[id] = uris.join(',');
        }
      });

      return idsToJoinedUris;
    },

    canGrantSyncAccess(hasLinkedSyncApplication) {
      return hasLinkedSyncApplication && !this.hasDeviceSyncDebugAccessGranted();
    },

    canGrantInfraAccess(isOrganizationRestrictingEmployeeAccess) {
      return !this.hasActiveRestrictedEmployeeAccessBypass() && isOrganizationRestrictingEmployeeAccess;
    },

    getDeploymentItemName() {
      return this.get('deploymentItemName');
    },

    hasAnalyticsNodes() {
      const replicationSpec = this.getReplicationSpec();
      const result =
        replicationSpec &&
        replicationSpec.regionConfigs &&
        replicationSpec.regionConfigs.filter(
          (region) => region && region.analyticsSpecs && region.analyticsSpecs.nodeCount > 0
        );
      return result && result.length > 0;
    },

    hasGeoShardedCollections() {
      return this.get('geoSharding').managedNamespaces.length > 0;
    },

    // if shardKey is specified use it to filter, otherwise just filter on db and coll
    isManagedNamespace(db, coll, shardKey = undefined) {
      return this.get('geoSharding').managedNamespaces.some((ns) => {
        if (shardKey && (shardKey as $TSFixMe).fields) {
          if ((shardKey as $TSFixMe).fields.length <= 1) {
            return false;
          }
          const firstShardField = (shardKey as $TSFixMe).fields[0];
          const customShardField = (shardKey as $TSFixMe).fields[1];

          return (
            ns.db === db &&
            ns.collection === coll &&
            ns.isShardKeyUnique === (shardKey as $TSFixMe).unique &&
            firstShardField.name === 'location' &&
            firstShardField.type === ShardKeyType.RANGE &&
            customShardField.name === ns.customShardKey &&
            customShardField.type === managedNamespaceUtils.getManagedNamespaceCustomShardKeyType(ns)
          );
        }
        return ns.db === db && ns.collection === coll;
      });
    },

    has1HourResolutionData() {
      const clusterAgeHours = this.getHoursSinceCreation();
      return Math.floor(clusterAgeHours) >= 2;
    },

    onValidate(validate, attributes, errors, options = {}) {
      validate.notBlank('Cluster Name', attributes.name, errors);
      validate.notBlank('Number of shards', attributes.replicationSpecList[0].numShards, errors);
      validate.isNumeric('Number of shards', attributes.replicationSpecList[0].numShards, options, errors);

      this.set('validationErrors', errors);
    },

    getValidationErrors() {
      return this.get('validationErrors');
    },

    getInstanceSize() {
      return replicationSpecListUtils.getFirstInstanceSize(this.getReplicationSpecList());
    },

    getAnalyticsInstanceSize() {
      return replicationSpecListUtils.getFirstAnalyticsInstanceSize(this.getReplicationSpecList());
    },

    getCommandLineToolsUrl() {
      return this.isServerless()
        ? `#/serverless/commandLineTools/${this.getName()}`
        : `#/clusters/commandLineTools/${this.getName()}`;
    },

    getBIConnector() {
      return this.get('biConnector');
    },

    setBIConnector(newBiConnectorSettings) {
      this.set('biConnector', newBiConnectorSettings);
    },

    isBIConnectorEnabled() {
      const biConnector = this.getBIConnector();
      return biConnector && biConnector.isEnabled();
    },

    isPitEnabled() {
      return this.get('pitEnabled');
    },

    getRestrictedEmployeeAccessBypassDate() {
      return this.get('restrictedEmployeeAccessBypassDate');
    },

    getRestrictedEmployeeAccessTimeRemainingMs() {
      const accessEndDate = this.getRestrictedEmployeeAccessBypassDate();
      if (accessEndDate) {
        return Math.max(0, moment(accessEndDate).diff(moment()));
      }
      return 0;
    },

    hasActiveRestrictedEmployeeAccessBypass() {
      return this.getRestrictedEmployeeAccessTimeRemainingMs() > 0;
    },

    getDeviceSyncDebugAccessExpiresAfterDate() {
      return this.get('deviceSyncDebugAccessExpiresAfterDate');
    },

    getDeviceSyncDebugAccessTimeRemainingMs() {
      const accessEndDate = this.getDeviceSyncDebugAccessExpiresAfterDate();
      if (accessEndDate) {
        return Math.max(0, moment(accessEndDate).diff(moment()));
      }
      return 0;
    },

    hasDeviceSyncDebugAccessGranted() {
      return this.getDeviceSyncDebugAccessTimeRemainingMs() > 0;
    },

    getGrantedEmployeeAccessTimeRemainingMs() {
      const deviceSyncAccessTimeRemainingInMs = this.getDeviceSyncDebugAccessTimeRemainingMs();
      const restrictedEmployeeAccessTimeRemainingInMs = this.getRestrictedEmployeeAccessTimeRemainingMs();

      return Math.max(deviceSyncAccessTimeRemainingInMs, restrictedEmployeeAccessTimeRemainingInMs);
    },

    hasAnyGrantedEmployeeAccess() {
      return this.hasDeviceSyncDebugAccessGranted() || this.hasActiveRestrictedEmployeeAccessBypass();
    },
  },
  {
    getDeploymentItemForClusterDescription(clusterDescription, deployment) {
      const deploymentItemName = clusterDescription.getDeploymentItemName();
      if (clusterDescription.isShardedCluster()) {
        return deployment.getClusters().getByName(deploymentItemName);
      }
      return deployment.getReplicaSets().getByName(deploymentItemName);
    },

    validateClusterName(name) {
      if (name && VALID_CLUSTER_NAME_REGEX.test(name)) {
        return { isValid: true };
      }
      if (name) {
        return {
          isValid: false,
          error: 'The name can only contain ASCII letters, numbers, and hyphens.',
        };
      }

      return { isValid: false }; // not valid, but there's no error because empty name
    },

    MIN_SHARDS: 2,
    MAX_SHARDS: 12,
  }
);
