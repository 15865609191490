import { useEffect } from 'react';

import Cookies from 'js-cookie';

import { PartnerType } from '@packages/types/billing';

import { useRequestParams } from 'js/common/context/RequestParamsContext';
import { getMarketplaceRegistrationCookie } from 'js/common/utils/billingHelpers';
import { COOKIE_KEYS } from 'js/common/utils/cookieHelpers';
import analytics, { PAGE_CALLS } from 'js/common/utils/segmentAnalytics';

import { fetchAndShapeClearbit, SignupSource } from 'js/auth/utils/analyticsUtils';

export default function useRegistrationAnalytics({
  analyticsSignupSource,
  marketplaceSource,
}: {
  analyticsSignupSource: SignupSource;
  marketplaceSource?: PartnerType;
}) {
  const { onPrem, analyticsEnabled } = useRequestParams();
  const marketplaceRegistrationCookie = getMarketplaceRegistrationCookie();

  useEffect(() => {
    if (analyticsEnabled && !onPrem) {
      analytics.page(PAGE_CALLS.REGISTRATION, {
        signup_source: analyticsSignupSource,
        isFromMp: !!marketplaceRegistrationCookie,
        marketplaceSource: marketplaceSource ?? '',
      });
    }
  }, [analyticsEnabled, onPrem]);

  useEffect(() => {
    async function fetchClearbit() {
      const fetchedClearbitData = await fetchAndShapeClearbit();
      Cookies.set(COOKIE_KEYS.CLEARBIT_DATA, fetchedClearbitData);
    }
    if (analyticsEnabled) {
      fetchClearbit();
    }
  }, []);
}
