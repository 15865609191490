import * as React from 'react';
import { ReactNode } from 'react';

import styled from '@emotion/styled';
import Card from '@leafygreen-ui/card';
import { palette } from '@leafygreen-ui/palette';
import { Helmet } from 'react-helmet';

const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  overflow-y: auto;
  background: ${palette.gray.light3};
`;

const paddingAsDesigned = 40;
const widthAsDesigned = 626;

type CustomCardType = React.ComponentType<Omit<React.ComponentProps<typeof Card>, 'as'>>;

const StyledCard = styled<CustomCardType>(Card)`
  max-width: ${widthAsDesigned}px;
  margin: 42px auto;
  padding: ${paddingAsDesigned}px;
  position: relative;
  box-sizing: border-box;
`;

export default function ThirdPartyIntegrationPage({
  pageTitle,
  children,
}: {
  pageTitle?: string;
  children?: ReactNode;
}) {
  return (
    <>
      {pageTitle && <Helmet title={pageTitle} />}
      <Container>
        <StyledCard>{children}</StyledCard>
      </Container>
    </>
  );
}
