import { SampleDataset } from '@packages/types/nds/loadSampleDataset';

import fetchWrapper from '../fetchWrapper';

export interface PostLoadSampleDatasetProps {
  clusterName: string;
  groupId: string;
  dataset?: SampleDataset;
}

export default {
  getLoadSampleDatasetStatuses(groupId) {
    return fetchWrapper(`/nds/sampleDatasetLoad/${groupId}`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },
  /**
   * Initiates the sample data load for a given cluster and group.
   * An optional third parameter `dataset` can be passed in. If this
   * value is `sampledata` (SampleDataset.DEFAULT) the entire sample
   * dataset will be downloaded.
   */
  postLoadSampleDataset(data: PostLoadSampleDatasetProps) {
    return fetchWrapper(`/nds/sampleDatasetLoad/${data.groupId}`, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  },
};
