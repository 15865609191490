import Backbone from 'backbone';

const BIConnectorSettings = Backbone.Model.extend({
  defaults: {
    enabled: false,
    readPreference: null,
    publicHostname: null,
    privateHostname: null,
  },

  isEnabled() {
    return this.get('enabled');
  },

  getReadPreference() {
    return this.get('readPreference');
  },

  isPrimarySelected() {
    return this.getReadPreference() === 'primary';
  },

  getPublicHostname() {
    return this.get('publicHostname');
  },

  getPrivateHostname() {
    return this.get('privateHostname');
  },

  getPrivateEndpointHostnamesMap() {
    return this.get('privateLinkHostnamesMap');
  },
});

export default BIConnectorSettings;
