import { Component } from 'react';

import { WithDefaultProps } from '@packages/types/withDefaultProps';

import Modal from '@packages/components/Modal';

import * as api from 'js/common/services/api';

import { MultiFactorAuth, MultiFactorAuthConnected } from './MultiFactorAuth';
import PasswordAuth from './PasswordAuth';

type AuthExpiredHandler = ReturnType<typeof api.utils.isAuthExpired>;

interface OwnProps {
  promptWithinModal?: boolean;
  errorCode?: string;
  children?: (options: { onAuthExpired: AuthExpiredHandler }) => void;
  onSuccess?: () => void;
  username?: string;
}

interface State {
  type: 'multifactor' | 'password' | null;
}

const defaultProps = {
  promptWithinModal: false,
  errorCode: '',
  children: () => {},
  onSuccess: () => {},
  username: '',
};

type Props = WithDefaultProps<OwnProps, typeof defaultProps>;

// This component can now be used either as a controlled component that receives an errorCode
// or it can be used as a wrapper around a group of components and passing the onAuthExpired to all.
// Both implementations function equivalently.
// You must pass this component either a children function or an errorCode to work.

class AuthExpired extends Component<Props, State> {
  static isAuthExpired = (errorCode: string) => {
    return errorCode === 'MULTI_FACTOR_AUTH_EXPIRED' || errorCode === 'PASSWORD_AUTH_EXPIRED';
  };

  static defaultProps = defaultProps;

  state = {
    type: null,
  };

  onAuthExpired = api.utils.isAuthExpired({
    password: () => this.setState({ type: 'password' }),
    multifactor: () => this.setState({ type: 'multifactor' }),
  });

  onSuccess = () => {
    const { onSuccess } = this.props;
    this.setState({ type: null });
    onSuccess();
  };

  closeModal = () => this.setState({ type: null });

  render() {
    const { children, promptWithinModal, errorCode, username } = this.props;
    const { type } = this.state;

    let verifyElement;
    if (type === 'multifactor' || errorCode === 'MULTI_FACTOR_AUTH_EXPIRED') {
      if (username) {
        verifyElement = <MultiFactorAuth onSuccess={this.onSuccess} username={username} />;
      } else {
        verifyElement = <MultiFactorAuthConnected onSuccess={this.onSuccess} />;
      }
    } else if (type === 'password' || errorCode === 'PASSWORD_AUTH_EXPIRED') {
      verifyElement = <PasswordAuth onSuccess={this.onSuccess} />;
    }

    if (promptWithinModal && verifyElement) {
      verifyElement = <Modal onClose={this.closeModal}>{verifyElement}</Modal>;
    }

    const showChildren = (promptWithinModal || !verifyElement) && !errorCode;

    return (
      <div>
        {verifyElement}
        {showChildren && children({ onAuthExpired: this.onAuthExpired })}
      </div>
    );
  }
}

export default AuthExpired;
