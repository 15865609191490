import { createSelector } from 'reselect';

import { Region } from '@packages/types/nds/region';
import { StreamInstance } from '@packages/types/nds/streams';

import * as api from 'js/common/services/api';

export interface StreamsState {
  requests: {
    hasRequestedAccess: boolean;
    showRequestToast: boolean;
  };
  instances: Array<StreamInstance>;
  availableRegions: Array<Region>;
}

const initialState: StreamsState = {
  requests: {
    hasRequestedAccess: false,
    showRequestToast: false,
  },
  instances: [],
  availableRegions: [],
};

// Action types
const SET_REQUESTED_ACCESS = 'nds/streams/SET_REQUEST_ACCESS';
const SET_STREAM_INSTANCES = 'nds/streams/SET_STREAM_INSTANCES';
const TOGGLE_REQUEST_TOAST = 'nds/streams/TOGGLE_REQUEST_TOAST';
const SET_STREAM_AVAILABLE_REGIONS = 'nds/streams/SET_STREAM_AVAILABLE_REGIONS';

// Action creators
export function setRequestedAccess(hasRequested: boolean) {
  return {
    type: SET_REQUESTED_ACCESS,
    payload: hasRequested,
  } as const;
}

export function toggleRequestToast() {
  return {
    type: TOGGLE_REQUEST_TOAST,
  } as const;
}

export function setStreamInstances(instances: Array<StreamInstance>) {
  return {
    type: SET_STREAM_INSTANCES,
    payload: instances,
  } as const;
}

export function setStreamAvailableRegions(regions: Array<Region>) {
  return {
    type: SET_STREAM_AVAILABLE_REGIONS,
    payload: regions,
  };
}

// Type export
type StreamsActions = ReturnType<
  typeof setRequestedAccess | typeof toggleRequestToast | typeof setStreamAvailableRegions
>;

export default function streamsReducer(state = initialState, action: StreamsActions) {
  switch (action.type) {
    case SET_REQUESTED_ACCESS:
      return {
        ...state,
        requests: {
          ...state.requests,
          hasRequestedAccess: action.payload,
        },
      };

    case TOGGLE_REQUEST_TOAST:
      return {
        ...state,
        requests: {
          ...state.requests,
          showRequestToast: !state.requests.showRequestToast,
        },
      };

    case SET_STREAM_INSTANCES:
      return {
        ...state,
        instances: action.payload,
      };

    case SET_STREAM_AVAILABLE_REGIONS:
      return {
        ...state,
        availableRegions: action.payload || [],
      };

    default:
      return state;
  }
}

export const loadHasRequestedAccess = (groupId) => (dispatch) => {
  return api.nds.streams.hasRegisteredInterest(groupId).then((response) => dispatch(setRequestedAccess(response)));
};

export const loadStreamInstances = (groupId) => (dispatch) => {
  return api.nds.streams.getStreamInstances(groupId).then((response) => dispatch(setStreamInstances(response)));
};

export const loadStreamRegions = (groupId) => (dispatch) => {
  return api.nds.streams.getStreamAvailableRegions(groupId).then((regions) => {
    dispatch(setStreamAvailableRegions(regions));
  });
};

// selectors
const getStreams = (state) => state.nds.streams as StreamsState;

// Selectors
export const getHasRequestedAccess = createSelector(
  [getStreams],
  (streams) => streams.requests.hasRequestedAccess || false
);
export const getShowRequestToast = createSelector(
  [getStreams],
  (streams) => streams.requests.showRequestToast || false
);

export const getStreamInstances = createSelector([getStreams], (streams) => streams.instances);

export const getStreamAvailableRegions = createSelector([getStreams], (streams) => streams.availableRegions);
