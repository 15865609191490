import ShardKeyType from 'js/project/nds/clusters/models/ShardKeyType';

export default {
  getManagedNamespaceCustomShardKeyType(managedNamespace) {
    if (managedNamespace.isCustomShardKeyHashed) {
      return ShardKeyType.HASHED;
    }
    return ShardKeyType.RANGE;
  },
};
