export const jobFunctions = [
  'IT Executive (CIO, CTO, VP Engineering, etc.)',
  'Business Executive (CEO, COO, CMO, etc.)',
  'Software Developer / Engineer',
  'Director / Development Manager',
  'Architect',
  'DBA',
  'Technical Operations',
  'Product / Project Manager',
  'Student',
  'Educator',
  'Other',
];
