import Card from '@leafygreen-ui/card';
import { H3, Link } from '@leafygreen-ui/typography';
import { Helmet } from 'react-helmet';

import HeaderLogo from './HeaderLogo';
import checkmark from './images/checkmark.svg';
import Layout from './Layout';
import { MultiFactorAuthContainer } from './styles/multiFactorPage';

export function DeviceAuthSuccessPage() {
  return (
    <>
      <Helmet title="Activation" />
      <Layout contentPlacement="right">
        <MultiFactorAuthContainer>
          <HeaderLogo />
          <Card css={{ minHeight: 200, maxWidth: 500, marginTop: 96 }}>
            <div
              css={{
                textAlign: 'center',
              }}
            >
              <img src={checkmark} alt="success checkmark" css={{ marginBottom: 20, marginTop: 10 }} />
              <H3>Your account is ready.</H3>
              <p css={{ fontSize: 15, fontWeight: 400 }}>Return to the CLI in your terminal window.</p>
              <p>
                <Link href="https://www.mongodb.com/docs/atlas/cli/stable/">MongoDB Atlas CLI docs</Link>
              </p>
              <p>
                <Link href="https://www.mongodb.com/docs/mongocli/stable/">
                  MongoDB CLI for Ops Manager and Cloud Manager docs
                </Link>
              </p>
              <p css={{ fontSize: 11, marginBottom: 0, marginTop: 50 }}>
                Click <Link href="/">here</Link> to open the Atlas UI.
              </p>
            </div>
          </Card>
        </MultiFactorAuthContainer>
      </Layout>
    </>
  );
}
