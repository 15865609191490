import Backbone from 'backbone';

const StitchApplication = Backbone.Model.extend(
  {
    defaults: {
      name: null,
      clusterNames: [],
      groupId: null,
      appId: null,
      product: null,
      serviceIdsByClusterName: {},
      clientAppId: null,
      deploymentModel: null,
      providerRegion: null,
      clustersLinkedToSync: [],
    },

    getClustersLinkedToSync() {
      return this.get('clustersLinkedToSync');
    },

    getName() {
      return this.get('name');
    },

    getGroupId() {
      return this.get('groupId');
    },

    getClusterNames() {
      return this.get('clusterNames');
    },

    getAppId() {
      return this.get('appId');
    },

    getProduct() {
      return this.get('product');
    },

    getRedirectUrl(baseUrl) {
      return `${baseUrl}/groups/${this.getGroupId()}/apps/${this.getAppId()}`;
    },

    getServiceId(clusterName) {
      return this.get('serviceIdsByClusterName')[clusterName];
    },

    getClientAppId() {
      return this.get('clientAppId');
    },

    getDeploymentModel() {
      return this.get('deploymentModel');
    },

    getProviderRegion() {
      return this.get('providerRegion');
    },
  },
  {
    // Take the json response from Stitch and build a backbone model
    fromApiResponse(opt) {
      const atlasServices = opt.atlasServices ? opt.atlasServices : [];
      const clustersLinkedToSync: Array<string> = [];

      const serviceIdsByClusterName = atlasServices.reduce((partialServiceIdsByClusterName, atlasService) => {
        if (atlasService.config) {
          partialServiceIdsByClusterName[atlasService.config.clusterName] = atlasService._id;
        }
        return partialServiceIdsByClusterName;
      }, {});

      atlasServices.forEach((service) => {
        const linkedToSyncStates = ['enabled', 'disabled'];
        const clusterLinkedToSync = linkedToSyncStates.includes(service.config?.sync?.state);
        const clusterLinkedToFlexibleSync = linkedToSyncStates.includes(service.config?.flexible_sync?.state);
        if (clusterLinkedToSync || clusterLinkedToFlexibleSync) {
          if (service.config.clusterName) {
            clustersLinkedToSync.push(service.config.clusterName);
          }
        }
      });

      return new StitchApplication({
        name: opt.name,
        appId: opt._id,
        clusterNames: Object.keys(serviceIdsByClusterName),
        groupId: opt.group_id,
        product: opt.product,
        serviceIdsByClusterName,
        clientAppId: opt.client_app_id,
        deploymentModel: opt.deployment_model,
        providerRegion: opt.provider_region,
        clustersLinkedToSync: clustersLinkedToSync,
      });
    },
  }
);

export default StitchApplication;
