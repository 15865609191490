import { useReducer } from 'react';

import { css } from '@emotion/react';
import Banner from '@leafygreen-ui/banner';
import Button from '@leafygreen-ui/button';
import { usePoller } from '@leafygreen-ui/hooks';
import { Body } from '@leafygreen-ui/typography';
import { produce } from 'immer';

import {
  AuthTransactionState,
  FactorResultType,
  OktaMfaAuthTransaction,
  PushOktaMfaFactor,
} from '@packages/types/accountMultiFactorAuth';
import { Search } from '@packages/types/auth/search';

import * as api from 'js/common/services/api';
// common styles
import { bodyParagraphStyles } from 'js/common/styles/multiFactorAuth';

const sendButtonStyles = css({
  width: '100px',
});

const errorBannerStyles = css({
  marginBottom: '14px',
  boxSizing: 'border-box',
});

type VerifyPushAction =
  | { type: 'onChallenge' }
  | { type: 'onChallengeSuccess' }
  | { type: 'onChallengeError' }
  | { type: 'onTimeout' }
  | { type: 'onReject' }
  | { type: 'onVerificationError' };

interface VerifyPushReducerState {
  isPushSent: boolean;
  isPushSuccess: boolean;
  isResend: boolean;
  errorMessage: string;
}

const initialState: VerifyPushReducerState = {
  isPushSent: false,
  isPushSuccess: false,
  isResend: false,
  errorMessage: '',
};

const verifyPushReducer = produce((draft: VerifyPushReducerState, action: VerifyPushAction) => {
  switch (action.type) {
    case 'onChallenge': {
      draft.isPushSent = true;
      draft.errorMessage = '';
      return;
    }
    case 'onChallengeSuccess': {
      draft.isPushSuccess = true;
      draft.errorMessage = '';
      return;
    }
    case 'onChallengeError': {
      draft.isPushSent = false;
      draft.isPushSuccess = false;
      draft.isResend = true;
      draft.errorMessage = 'A server error has occurred. Please try again in a moment.';
      return;
    }
    case 'onTimeout': {
      draft.isPushSent = false;
      draft.isPushSuccess = false;
      draft.isResend = true;
      draft.errorMessage = 'Your push notification has expired.';
      return;
    }
    case 'onReject': {
      draft.isPushSent = false;
      draft.isPushSuccess = false;
      draft.isResend = true;
      draft.errorMessage = 'You have rejected this login.';
      return;
    }
    case 'onVerificationError': {
      draft.isPushSuccess = false;
      draft.errorMessage = 'A server error has occurred. Please try again in a moment.';
      return;
    }

    default:
      return draft;
  }
}, initialState);

export type VerifyAuthPushFactorResponse = OktaMfaAuthTransaction;

interface VerifyPushFactorProps {
  pushFactor: PushOktaMfaFactor;
  clientState: Search;
  onSuccess: (response: VerifyAuthPushFactorResponse) => void;
}

export default function VerifyAuthPushFactor({ pushFactor, clientState, onSuccess }: VerifyPushFactorProps) {
  const [{ isPushSent, isPushSuccess, isResend, errorMessage }, dispatch] = useReducer(verifyPushReducer, initialState);

  const { stateToken } = clientState;

  const sendPushChallenge = async () => {
    dispatch({ type: 'onChallenge' });

    try {
      await api.auth.verifyAuthMfa({
        factorId: pushFactor.id,
        stateToken,
        clientState,
      });
      dispatch({ type: 'onChallengeSuccess' });
    } catch ({ errorCode }) {
      dispatch({ type: 'onChallengeError' });
    }
  };

  const resendPushChallenge = async () => {
    dispatch({ type: 'onChallenge' });

    try {
      await api.auth.resendAuthMfa({
        factorId: pushFactor.id,
        stateToken,
      });
      dispatch({ type: 'onChallengeSuccess' });
    } catch ({ errorCode }) {
      dispatch({ type: 'onChallengeError' });
    }
  };

  const pollForPushFactorVerification = async () => {
    try {
      const response = (await api.auth.verifyAuthMfa({
        factorId: pushFactor.id,
        stateToken,
        clientState,
      })) as OktaMfaAuthTransaction;

      if (response.status === AuthTransactionState.MfaChallenge) {
        if (response.factorResult === FactorResultType.REJECTED) {
          dispatch({ type: 'onReject' });
        } else if (response.factorResult === FactorResultType.TIMEOUT) {
          dispatch({ type: 'onTimeout' });
        }
      } else {
        // If no error and does not have mfa challenge status
        onSuccess(response);
      }
    } catch (error) {
      dispatch({ type: 'onVerificationError' });
      console.log('An error occurred during polling for factor verification', error);
    }
  };

  usePoller(pollForPushFactorVerification, {
    interval: 5e3,
    enabled: isPushSuccess && !errorMessage,
    immediate: false,
  });

  return (
    <>
      {errorMessage && (
        <Banner variant="danger" data-testid="error-banner" css={errorBannerStyles}>
          {errorMessage}
        </Banner>
      )}

      <Body css={bodyParagraphStyles}>Send a push notification to authenticate on your device.</Body>
      <Button
        css={sendButtonStyles}
        variant="primary"
        disabled={isPushSent}
        onClick={isResend ? resendPushChallenge : sendPushChallenge}
      >
        {isPushSent ? 'Push Sent' : 'Send Push'}
      </Button>
    </>
  );
}
