import { FormEvent, useEffect, useState } from 'react';

import Banner from '@leafygreen-ui/banner';
import { Link } from '@leafygreen-ui/typography';
import { APIKey } from 'baas-admin-sdk';
import queryString from 'query-string';

import { Search } from '@packages/types/auth/search';
import { VercelProject } from '@packages/types/vercel';

import ThirdPartyIntegrationPage from '@packages/components/ThirdPartyIntegration/ThirdPartyIntegrationPage';
import VercelCreateAndManageLinking from '@packages/components/Vercel/VercelCreateAndManageLinking';

import { useAppUserParams } from 'js/common/context/AppUserContext';
import { useRequestParams } from 'js/common/context/RequestParamsContext';
import organizationApi from 'js/common/services/api/organizationApi';
import { CloudTeams, sendError } from 'js/common/utils/bugsnag';

import { linkingErrorStyle } from 'js/auth/components/styles/thirdPartyIntegration';
import { sanitizeUsername } from 'js/auth/components/VercelClusterCreationPage';
import { useDataApiConnector } from 'js/project/dataAPI/hooks/useDataApiConnector';

export default function VercelClusterLinkingPage({
  location: { search } = { search: '' },
  windowLocation = window.location,
}: {
  location?: Pick<Location, 'search'>;
  windowLocation?: Pick<Location, 'assign'>;
}) {
  const [selectedCluster, setSelectedCluster] = useState('');
  const [selectedVercelProjects, setSelectedVercelProjects] = useState<Array<VercelProject>>([]);
  const [hasLinkingError, setHasLinkingError] = useState(false);
  const { orgName, orgId, vercelUserId, next, username } = queryString.parse(search) as Search;

  const { centralUrl, baasCentralUrl } = useRequestParams();
  const { loadAppUserParams } = useAppUserParams();
  const [isDataAPIToggleOn, setIsDataAPIToggleOn] = useState(false);
  const [submissionInProgress, setSubmissionInProgress] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState('');
  const [readyForClusterLinking, setReadyForClusterLinking] = useState(false);
  const [dataApiKey, setDataApiKey] = useState<APIKey | null>(null);
  const [urlSlug, setUrlSlug] = useState('');

  useEffect(() => {
    loadAppUserParams();
  }, [loadAppUserParams]);

  const redirectToVercel = () => {
    const vercelURI = decodeURI(next as string);

    if (new URL(vercelURI).hostname === 'vercel.com') {
      windowLocation.assign(vercelURI);
    }
  };

  const setupDataApi = async ({ apiKey, urlSlug: newUrlSlug }: { apiKey: APIKey; urlSlug: string }) => {
    // handle error handling for missing apiKey / urlSlug in CLOUDP-153903
    setDataApiKey(apiKey);
    setUrlSlug(newUrlSlug);
    setReadyForClusterLinking(true);
  };

  useEffect(
    function linkClusterToVercel() {
      (async () => {
        // don't start cluster creation and linking if we're using data api and we don't have a data api key ready
        const dataApiOnAndReadyToProceed = isDataAPIToggleOn && readyForClusterLinking;
        const dataApiOffAndReadyToProceed = !isDataAPIToggleOn && submissionInProgress;
        if (!dataApiOnAndReadyToProceed && !dataApiOffAndReadyToProceed) return;

        if (orgId && vercelUserId && next && selectedProjectId) {
          try {
            await organizationApi.linkClusterToVercelProjects({
              centralUrl,
              orgId,
              vercelUserId,
              cluster: selectedCluster,
              vercelProjects: selectedVercelProjects,
              groupId: selectedProjectId,
              setupInitialDataApi: isDataAPIToggleOn,
              dataApiKeyName: dataApiKey?.name,
              dataApiKey: dataApiKey?.key,
              dataApiUrl: urlSlug,
            });

            redirectToVercel();
          } catch (error) {
            setHasLinkingError(true);
            setReadyForClusterLinking(false);
            setSubmissionInProgress(false);
            sendError({ error, team: CloudTeams.CoreIam });
          }
        }
      })();
    },
    [readyForClusterLinking, submissionInProgress]
  );

  useDataApiConnector({
    uniqueClusterName: selectedCluster,
    loadBaasApp: selectedProjectId !== '',
    enableDataApiForCluster: isDataAPIToggleOn && submissionInProgress,
    fetchDataApiInfo: ({ apiKey, urlSlug }: { apiKey: APIKey; urlSlug: string }) => setupDataApi({ apiKey, urlSlug }),
    initConfig: {
      groupId: selectedProjectId,
      orgId,
      // This page sometimes has username twice in the query params and so requires sanitization
      username: sanitizeUsername(username),
      baasUrl: baasCentralUrl,
    },
    showModalOnError: true,
  });

  const linkExistingCluster = async (e: FormEvent<HTMLFormElement>, groupId: string): Promise<void> => {
    e.preventDefault();
    setSubmissionInProgress(true);
    setSelectedProjectId(groupId);
  };

  return (
    <>
      <ThirdPartyIntegrationPage pageTitle="Vercel Integration">
        <VercelCreateAndManageLinking
          orgName={orgName}
          orgId={orgId}
          onChangeCluster={setSelectedCluster}
          onChangeVercelProjects={setSelectedVercelProjects}
          setSelectedProjectId={setSelectedProjectId}
          onSubmit={linkExistingCluster}
          isSubmitDisabled={
            selectedVercelProjects.length === 0 || !selectedCluster || hasLinkingError || submissionInProgress
          }
          location={location}
          linkingError={
            hasLinkingError && (
              <Banner variant="danger" css={linkingErrorStyle}>
                The integration is set up successfully. However, an error happened when attempting to link the Atlas
                cluster to Vercel projects. Please visit the{' '}
                <Link href={`${centralUrl}/v2#/org/${orgId}/integrations`}>Organization&apos;s Integrations</Link> page
                to try again. Click <Link onClick={redirectToVercel}>here</Link> to close this window and return to
                Vercel.
              </Banner>
            )
          }
          setHasLinkingError={setHasLinkingError}
          setIsDataAPIToggleOn={setIsDataAPIToggleOn}
          submissionInProgress={submissionInProgress}
        />
      </ThirdPartyIntegrationPage>
    </>
  );
}
