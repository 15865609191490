import { forwardRef, RefForwardingComponent, RefObject, useEffect, useImperativeHandle, useRef } from 'react';

import { css } from '@emotion/react';

import { useRequestParams } from 'js/common/context/RequestParamsContext';

import { Fieldset } from './styles/form';

declare global {
  interface Window {
    grecaptcha?: ReCaptchaInstance;
    Recaptcha?: ReCaptchaInstance;
    reCaptchaOnload: () => void;
  }
}

interface ReCaptchaInstance {
  ready: (cb: () => any) => void;
  execute: (siteKey?: string, args?: { action: string }) => Promise<string>;
  render: (id: string, options: ReCaptchaRenderOptions) => any;
  getResponse: () => string;
  reset: () => void;
  reload: () => void;
}

type Callback = (response: any) => void;

interface ReCaptchaRenderOptions {
  siteKey: string;
  badge: string;
  callback: Callback;
}

interface ReCaptchaProps {
  callback?: Callback;
  invisible?: boolean;
  callbackV3?: Callback;
  action: string;
}

export interface ReCaptchaResponse {
  errorCode: string;
  response?: string;
}

export interface ReCaptchaHandles {
  execute: (action?: string) => void;
  reset: () => void;
  verify: () => ReCaptchaResponse;
}

const ReCaptcha: RefForwardingComponent<ReCaptchaHandles, ReCaptchaProps> = (
  { callback = () => {}, callbackV3 = () => {}, invisible = false, action },
  ref: RefObject<ReCaptchaHandles>
) => {
  const { reCaptchaPublic, reCaptchaV3Public, reCaptchaEnabledRegistration, reCaptchaEnabledLogin } =
    useRequestParams();

  useEffect(() => {
    // There is a race condition between the captcha js loading and the react component rendering.
    // If the js loads first, then the react component goes ahead and renders the captcha. Otherwise,
    // the componentDidMount() function populates the reCaptchaOnload function in registration/index.jsp
    // so that the captcha is rendered after the script is loaded.

    const renderRecaptcha = () => {
      window.grecaptcha &&
        window.grecaptcha.render('g-recaptcha', {
          siteKey: reCaptchaPublic,
          // Position bottomleft so we don't overlap Intercom widget
          badge: 'bottomleft',
          callback,
        });
    };

    if (reCaptchaEnabledRegistration || reCaptchaEnabledLogin) {
      if (typeof window.grecaptcha !== 'undefined' && typeof window.grecaptcha.render === 'function') {
        renderRecaptcha();
      } else {
        window.reCaptchaOnload = renderRecaptcha;
      }
    }
  }, [reCaptchaEnabledRegistration, reCaptchaPublic, reCaptchaEnabledLogin]);

  const reCaptchaRef = useRef<HTMLDivElement>(null);

  useImperativeHandle(ref, () => ({
    execute: () => {
      if (typeof window.grecaptcha !== 'undefined' && typeof window.grecaptcha.execute === 'function') {
        try {
          // As of CLOUDP-117766 we are running reCaptcha v3 in the background to gather data and investigate
          // the feasibility of migrating from v2 to v3 in CLOUDP-111211.
          window.grecaptcha.execute(reCaptchaV3Public, { action }).then(async (token) => {
            window.grecaptcha!.execute();
            callbackV3(token);
          });
        } catch {
          // In case the v3 call fails, want to ensure v2 still gets called
          window.grecaptcha.execute();
        }
      }
    },
    reset: () => {
      // Support both version 1 and 2.
      if (window.grecaptcha) {
        window.grecaptcha.reset();
      }
      if (window.Recaptcha) {
        window.Recaptcha && window.Recaptcha.reload();
      }
    },
    verify: () => {
      const reCaptchaResponse = window.grecaptcha && window.grecaptcha.getResponse();

      const result = {
        errorCode: '',
        response: reCaptchaResponse,
      };

      if (reCaptchaResponse === '') {
        result.errorCode = 'MISSING_CAPTCHA_RESPONSE';
      }

      return result;
    },
  }));

  return (
    <Fieldset
      css={css`
        margin-bottom: 0;
      `}
    >
      {invisible ? (
        // Login uses invisible captcha which is loaded with `data-size="invisible"`
        <div id="g-recaptcha" data-sitekey={reCaptchaPublic} data-size="invisible" ref={reCaptchaRef} />
      ) : (
        <div
          id="g-recaptcha"
          data-sitekey={reCaptchaPublic}
          data-badge="bottomleft"
          ref={reCaptchaRef}
          css={css`
            margin-top: 48px;
          `}
        />
      )}
    </Fieldset>
  );
};

export default forwardRef(ReCaptcha);
