import { useRef } from 'react';

import { css } from '@emotion/css';
import styled from '@emotion/styled';
import queryString from 'query-string';
import { Link } from 'react-router-dom';

import { FieldNames } from '@packages/types/auth/field';
import { Search } from '@packages/types/auth/search';
import { SocialAuthMethods } from '@packages/types/authMethod';

import { ThirdPartyIntegrationLineDivider } from '@packages/components/ThirdPartyIntegration/styles/common';
import ThirdPartyIntegrationLayout from '@packages/components/ThirdPartyIntegration/ThirdPartyIntegrationLayout';
import ThirdPartyIntegrationPage from '@packages/components/ThirdPartyIntegration/ThirdPartyIntegrationPage';

import { useRequestParams } from 'js/common/context/RequestParamsContext';

import {
  SocialAuthButtonContainer,
  ThirdPartyIntegrationFormInput,
  ThirdPartyIntegrationPasswordInput,
  ThirdPartyIntegrationRedirectText,
} from 'js/auth/components/styles/thirdPartyIntegration';
import useOktaSession from 'js/auth/hooks/useOktaSession';
import useRegistrationAnalytics from 'js/auth/hooks/useRegistrationAnalytics';
import useRegistrationReducer from 'js/auth/hooks/useRegistrationReducer';
import { getSignUpSource, SignupMethod } from 'js/auth/utils/analyticsUtils';
import { fieldValidationMap, isUserInputValid, onRegisterUser } from 'js/auth/utils/registrationUtils';

import AuthErrorBanner from './AuthErrorBanner';
import ReCaptcha, { ReCaptchaHandles } from './ReCaptcha';
import SocialAuthButton from './SocialAuthButton';
import { ErrorMessage } from './styles/form';
import TermsOfServiceCheckbox from './TermsOfServiceCheckbox';

interface Props {
  location?: Pick<Location, 'search'>;
  windowLocation?: Pick<Location, 'assign' | 'href'>;
}

const VercelTermsOfServiceCheckbox = styled(TermsOfServiceCheckbox)`
  margin: 24px 0 0;
  justify-content: left;
  span {
    flex-grow: 0;
    font-size: 13px;
    font-weight: 600;
  }
`;

const VercelPasswordTooltipParagraph = styled.p`
  margin: 0;
  padding: 0;
`;

export default function VercelRegistrationPage({
  location: { search } = { search: '' },
  windowLocation = window.location,
}: Props) {
  const requestParams = useRequestParams();
  const {
    reCaptchaEnabledRegistration,
    isGoogleAuthEnabled,
    isGithubAuthEnabled,
    vercelEmail,
    vercelUserId,
    vercelTeamName,
    vercelTeamId,
    siteName,
  } = requestParams;

  const parsedSearchLocation = queryString.parse(search) as Search;
  const {
    vercelEmail: vercelEmailFromClient,
    vercelUserId: vercelUserIdFromClient,
    vercelTeamId: vercelTeamIdFromClient,
    vercelTeamName: vercelTeamnameFromClient,
  } = parsedSearchLocation;

  const clientState = {
    ...parsedSearchLocation,
    n: '/account/vercel/org/selection',
    isVercelIntegration: true,
    vercelEmail: vercelEmail ?? vercelEmailFromClient,
    vercelUserId: vercelUserId ?? vercelUserIdFromClient,
    vercelTeamId: vercelTeamId ?? vercelTeamIdFromClient,
    vercelTeamName: vercelTeamName ?? vercelTeamnameFromClient,
  };

  const [
    { formFields, errorMessage, captchaError, errorField, formSubmitted, isCaptchaBoxChecked, isSocialSignupDisabled },
    dispatch,
  ] = useRegistrationReducer({
    username: vercelEmail ?? vercelEmailFromClient,
  });

  const { username, password, firstName, lastName, tos } = formFields;

  const reCaptchaRef = useRef<ReCaptchaHandles>(null);
  const reason = clientState?.reason ?? '';
  const authErrorCode = !username ? 'INVALID_VERCEL_ACCESS_CODE' : reason;
  const isSocialAuthEnabled = isGoogleAuthEnabled || isGithubAuthEnabled;
  const analyticsSignupSource = getSignUpSource(undefined, true, false);

  useOktaSession(dispatch);
  useRegistrationAnalytics({ analyticsSignupSource, marketplaceSource: undefined });

  const isSubmitDisabled =
    formSubmitted ||
    (reCaptchaEnabledRegistration && !isCaptchaBoxChecked) ||
    !(
      isUserInputValid(FieldNames.USERNAME, username) &&
      isUserInputValid(FieldNames.FIRST_NAME, firstName) &&
      isUserInputValid(FieldNames.LAST_NAME, lastName) &&
      tos &&
      password.length > 0 &&
      errorMessage.length === 0
    );

  const onSubmit = (e) => {
    e.preventDefault();

    const updatedClientState = {
      ...clientState,
      firstName,
      signupSource: analyticsSignupSource,
      signupMethod: SignupMethod.FORM,
      n: '/account/vercel/confirm', // send the user to confirmation page for Vercel Integration
      vercelEmail: vercelEmail ?? vercelEmailFromClient,
      vercelUserId: vercelUserId ?? vercelUserIdFromClient,
      vercelTeamId: vercelTeamId ?? vercelTeamIdFromClient,
      vercelTeamName: vercelTeamName ?? vercelTeamnameFromClient,
      isNewVercelOrg: 'true',
    };
    onRegisterUser({
      dispatch,
      clientState: updatedClientState,
      requestParams,
      searchObject: parsedSearchLocation,
      reCaptchaRef,
      windowLocation,
      registrationFormFields: formFields,
    });
  };

  return (
    <ThirdPartyIntegrationPage pageTitle="Create Account">
      <ThirdPartyIntegrationLayout
        title="Create Your MongoDB Account"
        isForm
        formProps={{ onSubmit, buttonText: 'Sign up', disabled: isSubmitDisabled }}
      >
        {authErrorCode && (
          <AuthErrorBanner
            className={css({ marginBottom: '24px' })}
            authErrorCode={authErrorCode}
            siteName={siteName}
          />
        )}
        <SocialAuthButtonContainer>
          {isGoogleAuthEnabled && (
            <>
              <SocialAuthButton
                clientState={clientState}
                disabled={isSocialSignupDisabled}
                provider={SocialAuthMethods.GOOGLE}
                location={location}
                data-testid="googleAuthButton"
              />
            </>
          )}
          {isGithubAuthEnabled && (
            <>
              <SocialAuthButton
                clientState={clientState}
                disabled={isSocialSignupDisabled}
                provider={SocialAuthMethods.GITHUB}
                location={location}
                data-testid="githubAuthButton"
              />
            </>
          )}
        </SocialAuthButtonContainer>
        {isSocialAuthEnabled && <ThirdPartyIntegrationLineDivider>or</ThirdPartyIntegrationLineDivider>}
        <ThirdPartyIntegrationFormInput
          fieldName="emailAddress"
          labelName="Email Address"
          autoComplete="username"
          type="email"
          onChange={(e) => dispatch({ type: 'field', payload: { field: FieldNames.USERNAME, value: e.target.value } })}
          value={username}
          regexValidation={fieldValidationMap[FieldNames.USERNAME]}
          hasError={errorField === FieldNames.USERNAME}
          errorMessage={errorMessage}
          tooltipText={
            'This is your email address associated with Vercel. This integration will sign you up for' +
            ' Atlas using the same email address, but you will be able to change it later via the Atlas UI.'
          }
          disabled
        />
        <ThirdPartyIntegrationFormInput
          fieldName={FieldNames.FIRST_NAME}
          labelName="First Name"
          autoComplete="given-name"
          onChange={(e) =>
            dispatch({ type: 'field', payload: { field: FieldNames.FIRST_NAME, value: e.target.value } })
          }
          value={firstName}
          regexValidation={fieldValidationMap[FieldNames.FIRST_NAME]}
          hasError={errorField === FieldNames.FIRST_NAME}
          errorMessage={errorMessage}
        />
        <ThirdPartyIntegrationFormInput
          fieldName={FieldNames.LAST_NAME}
          labelName="Last Name"
          autoComplete="family-name"
          onChange={(e) => dispatch({ type: 'field', payload: { field: FieldNames.LAST_NAME, value: e.target.value } })}
          value={lastName}
          regexValidation={fieldValidationMap[FieldNames.LAST_NAME]}
          hasError={errorField === FieldNames.LAST_NAME}
          errorMessage={errorMessage}
        />
        <ThirdPartyIntegrationPasswordInput
          onChange={(e) => dispatch({ type: 'field', payload: { field: FieldNames.PASSWORD, value: e.target.value } })}
          value={password}
          hasError={errorField === FieldNames.PASSWORD}
          errorMessage={errorMessage}
          forNewUser
          showRequirementsBox={false}
          hasFullWidth
          tooltipText={
            <VercelPasswordTooltipParagraph>
              Your password must:
              <br />
              Contain at least 8 characters
              <br />
              Contain unique characters, numbers, or symbols
              <br />
              Not contain your email address
            </VercelPasswordTooltipParagraph>
          }
        />
        <ThirdPartyIntegrationRedirectText>
          Already have an Atlas account?
          <Link to={`/login/vercel${!!search ? search + '&' : '?'}signedOut=true`}>&nbsp;Log in now</Link>
        </ThirdPartyIntegrationRedirectText>
        <VercelTermsOfServiceCheckbox
          checked={tos}
          onChange={(e) => dispatch({ type: 'field', payload: { field: FieldNames.TOS, value: e.target.checked } })}
        />
        {reCaptchaEnabledRegistration && (
          <>
            <ReCaptcha
              ref={reCaptchaRef}
              callback={(token) => dispatch({ type: 'setCaptchaBoxChecked', payload: token.length > 0 })}
              action="register"
            />
            {captchaError && <ErrorMessage data-testid="error-message">{captchaError}</ErrorMessage>}
          </>
        )}
      </ThirdPartyIntegrationLayout>
    </ThirdPartyIntegrationPage>
  );
}
