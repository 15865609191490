import * as api from 'js/common/services/api';
import { permissionValueToLabelMap } from 'js/common/services/permissionsService';

// dependencies
import { actionTypes as commonActionTypes } from './common';

const { SET_GROUP_USERS } = commonActionTypes;

// actions

const EDIT_USER_ROLES = 'user/EDIT_USER_ROLES';
const SET_MULTIPLE_USERS = 'user/SET_MULTIPLE_USERS';

const initialState = {};

// Reducer

// eslint-disable-next-line no-multi-assign
export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case EDIT_USER_ROLES: {
      const { meta, payload } = action;
      const nextState = { ...state };

      nextState[meta.userId].roles = payload.roles;
      nextState[meta.userId].rolesString = payload.rolesString;
      return nextState;
    }

    case SET_MULTIPLE_USERS:
    case SET_GROUP_USERS: {
      const { payload } = action;

      if (payload.length === 0) {
        return state;
      }

      const nextState = { ...state };

      return payload.reduce((acc, user) => {
        nextState[user.userId] = {
          ...nextState[user.userId],
          ...user,
        };

        // Sanitize data to make sure they're always a string.
        ['firstName', 'lastName'].forEach((prop) => {
          if (typeof nextState[user.userId][prop] !== 'string') {
            nextState[user.userId][prop] = '';
          }
        });

        return acc;
      }, nextState);
    }
    default:
      return state;
  }
}

// Selectors

export const getUsersKeyedById = (state) => state.user;

export const getUser = (state, { userId }) => {
  return getUsersKeyedById(state)[userId] || {};
};

// At the moment, invitations are stored with the username as the id.
// This is a separate selector in order to avoid too heavy of a dependency on that fact.
export const getUserInvite = (state, { username }) => {
  const user = getUser(state, { userId: username });
  if (user && !user.isInvite) {
    // eslint-disable-next-line no-console
    console.warn('Tried to fetch a user invitation for a regular user', { username });
    return null;
  }

  return user;
};

// Action Creators
export const editUserRoles = ({ userId, roles, rolesString }) => ({
  type: EDIT_USER_ROLES,
  payload: {
    roles,
    rolesString,
  },
  meta: {
    userId,
  },
});

export const setMultipleUsers = (users = []) => ({
  type: SET_MULTIPLE_USERS,
  payload: users,
});

export const saveUserRoles =
  ({ orgId, user }) =>
  (dispatch) => {
    return api.organization
      .editUser({
        orgId,
        username: user.username,
        user,
      })
      .then((response) => {
        user.rolesString = response.rolesString;
      })
      .then(() =>
        dispatch(
          editUserRoles({
            userId: user.userId,
            roles: user.roles,
            rolesString: user.rolesString,
          })
        )
      );
  };

export const saveGroupUserRoles =
  ({ groupId, userId, roles }) =>
  async (dispatch, getState) => {
    const userObject = getUser(getState(), { userId });

    if (userObject == null) {
      return Promise.reject(new Error(`Attempted to update roles on an unknown user ID ${userId}`));
    }

    const updatedRolesSet = new Set(roles);
    const oldRolesSet = new Set(userObject.roles);

    // The users API has the (in my opinion) slightly strange API of just taking a list
    // of added/removed roles, with removed roles prefixed by a '-',
    // instead of the full new list of roles.
    const roleChanges = [
      ...[...updatedRolesSet].filter((r) => !oldRolesSet.has(r)),
      ...[...oldRolesSet].filter((r) => !updatedRolesSet.has(r)).map((r) => `-${r}`),
    ];

    await api.user.updateUserRole({
      groupId,
      roles: roleChanges,
      username: userObject.username,
    });

    dispatch(
      editUserRoles({
        userId,
        roles,
        rolesString: roles.map((r) => permissionValueToLabelMap[r]).join(', '),
      })
    );
  };
