import { useReducer } from 'react';

import { css } from '@emotion/react';
import Banner from '@leafygreen-ui/banner';
import Button from '@leafygreen-ui/button';
import { Body } from '@leafygreen-ui/typography';
import { produce } from 'immer';

import { FactorType, SmsOktaMfaFactor } from '@packages/types/accountMultiFactorAuth';
import { Search } from '@packages/types/auth/search';

import VerifyPasscodeInput from '@packages/components/MultiFactorAuth/VerifyPasscodeInput';

import * as api from 'js/common/services/api';
// common styles
import { bodyParagraphStyles } from 'js/common/styles/multiFactorAuth';
import { redactPhone } from 'js/common/utils/multiFactorAuthUtils';

const sendButtonStyles = css({
  width: '100px',
});

const errorBannerStyles = css({
  marginBottom: '14px',
  boxSizing: 'border-box',
});

type VerifySmsAction =
  | { type: 'onChallenge' }
  | { type: 'onChallengeSuccess' }
  | { type: 'onChallengeError' }
  | { type: 'setShowSendAgain'; payload: boolean };

interface VerifySmsReducerState {
  isSmsSending: boolean;
  isSmsSent: boolean;
  showSendAgain: boolean;
  errorMessage: string;
}

const initialState: VerifySmsReducerState = {
  isSmsSending: false,
  isSmsSent: false,
  showSendAgain: false,
  errorMessage: '',
};

const verifySmsReducer = produce((draft: VerifySmsReducerState, action: VerifySmsAction) => {
  switch (action.type) {
    case 'onChallenge': {
      draft.isSmsSending = true;
      return;
    }
    case 'onChallengeSuccess': {
      draft.isSmsSent = true;
      draft.isSmsSending = false;
      draft.errorMessage = '';
      return;
    }
    case 'onChallengeError': {
      draft.isSmsSent = false;
      draft.isSmsSending = false;
      draft.errorMessage = 'A server error has occurred. Please try again in a moment.';
      return;
    }
    case 'setShowSendAgain': {
      draft.showSendAgain = action.payload;
      return;
    }
    default:
      return draft;
  }
}, initialState);

interface VerifySmsFactorProps {
  smsFactor: SmsOktaMfaFactor;
  clientState: Search;
  onSuccess: (accountTemporaryAuthTokenId?: string) => void;
  isDeleteFlow?: boolean;
  isEmailChangeFlow?: boolean;
}

export default function VerifySmsFactor({
  smsFactor,
  clientState,
  onSuccess,
  isDeleteFlow,
  isEmailChangeFlow,
}: VerifySmsFactorProps) {
  const [{ isSmsSent, isSmsSending, showSendAgain, errorMessage }, dispatch] = useReducer(
    verifySmsReducer,
    initialState
  );
  const { stateToken } = clientState;
  const isAuthFlow = !!stateToken;

  const sendSmsChallenge = async () => {
    dispatch({ type: 'onChallenge' });

    try {
      if (isAuthFlow) {
        await api.auth.verifyAuthMfa({
          factorId: smsFactor.id,
          stateToken,
          clientState,
        });
      } else {
        await api.accountMultiFactorAuth.verifyFactor(
          {
            factorType: FactorType.Sms,
            factorId: smsFactor.id,
          },
          isDeleteFlow
        );
      }

      dispatch({ type: 'onChallengeSuccess' });
      setTimeout(() => {
        dispatch({ type: 'setShowSendAgain', payload: true });
      }, 30000);
    } catch ({ errorCode }) {
      dispatch({ type: 'onChallengeError' });
    }
  };

  const resendSms = async () => {
    dispatch({ type: 'setShowSendAgain', payload: false });

    // If we're in the auth flow, there's a separate endpoint to resend
    if (isAuthFlow) {
      await api.auth.resendAuthMfa({
        factorId: smsFactor.id,
        stateToken,
      });
    } else {
      sendSmsChallenge();
    }
  };

  // Get last 4 digits
  const redactedNumber = redactPhone(smsFactor.profile.phoneNumber);
  return (
    <>
      {errorMessage && (
        <Banner variant="danger" data-testid="error-banner" css={errorBannerStyles}>
          {errorMessage}
        </Banner>
      )}
      {isSmsSent ? (
        <>
          <Body css={bodyParagraphStyles}>
            We sent an authentication code to your device ending in <b>{redactedNumber}</b>. Enter the code to continue
            and be redirected.
          </Body>
          {showSendAgain && (
            <Body css={bodyParagraphStyles}>
              <Button
                name="sendAgain"
                css={sendButtonStyles}
                disabled={isSmsSending}
                onClick={resendSms}
                variant="primary"
              >
                Send Again
              </Button>
            </Body>
          )}
          <VerifyPasscodeInput
            inputType="code"
            factor={smsFactor}
            clientState={clientState}
            onSuccess={onSuccess}
            isEmailChangeFlow={isEmailChangeFlow}
          />
        </>
      ) : (
        <>
          <Body css={bodyParagraphStyles}>
            Send an authentication code to device ending in <b>{redactedNumber}</b>.
          </Body>
          <Button
            name="send"
            css={sendButtonStyles}
            variant="primary"
            disabled={isSmsSending}
            onClick={sendSmsChallenge}
          >
            Send
          </Button>
        </>
      )}
    </>
  );
}
