export function capitalize(word) {
  return word.replace(/^./, (ch) => ch.toUpperCase());
}

export function camelCaseToText(string: string): string {
  const camelSplitter = /(.)([A-Z]+)/g;
  return string.replace(
    camelSplitter,
    (_, previous, uppers) => previous + ' ' + uppers.toLowerCase().split('').join(' ')
  );
}
