import styled from '@emotion/styled';
import Button from '@leafygreen-ui/button';
import { palette } from '@leafygreen-ui/palette';

import { Spinner } from '@packages/components/styles/multiFactorAuth';

import { mq } from 'js/common/utils/mediaQueries';

import { AccordionBodyTextContainer, InfoText } from './styles/mfa';

const RecoveryCodesContainer = styled.div`
  display: flex;
  margin-top: 14px;
  flex-direction: column;
  align-items: center;
`;

const Codes = styled.div(() =>
  mq({
    columnCount: 2,
    columnWidth: ['115px', '85px', '95px', '115px'],
    height: 'auto',
    fontSize: '13px',
    color: palette.gray.dark2,
    lineHeight: '20px',
  })
);

const Note = styled.div`
  margin-top: 16px;
  color: ${palette.gray.dark2};
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 16px;
  font-weight: bolder;
`;

const LoadingText = styled.div`
  color: ${palette.gray.dark2};
  font-size: 13px;
  line-height: 20px;
`;

interface RecoveryCodesProps {
  siteName: string;
  backupCodes: Array<String>;
  onAcknowledge: () => void;
}

export default function RecoveryCodes({ backupCodes = [], siteName, onAcknowledge }: RecoveryCodesProps) {
  const displayFormattedCode = (code: string) => (
    <div key={code}>{`${code.substring(0, 3)}-${code.substring(3, 5)}-${code.substring(5, 8)}`}</div>
  );

  return (
    <AccordionBodyTextContainer>
      <InfoText>
        We recommend that you also generate recovery codes at this time. These codes are good for one-time
        authentication when using your two factor authentication method is unavailable. For example, you may be on
        vacation without cell phone service and need to log into {siteName}. If you write these codes down and keep them
        in a safe place you&apos;ll still be able to access your account.
      </InfoText>
      <RecoveryCodesContainer>
        {backupCodes.length > 0 ? (
          <>
            <Codes data-testid="recoveryCodes">{backupCodes.map(displayFormattedCode)}</Codes>
            <Note>You can generate a new set of codes by re-opening this tab.</Note>
            <Button type="button" name="acknowledge" variant="primary" onClick={onAcknowledge}>
              Acknowledge
            </Button>
          </>
        ) : (
          <>
            <Spinner src="/static/images/mfa/spinner.svg" alt="spinner" />
            <LoadingText>Loading recovery codes...</LoadingText>
          </>
        )}
      </RecoveryCodesContainer>
    </AccordionBodyTextContainer>
  );
}
