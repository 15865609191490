import { BackingCloudProviderName, CloudProviderName } from './provider';

export enum RegionNames {
  AP_EAST_1 = 'AP_EAST_1',
  AP_NORTHEAST_1 = 'AP_NORTHEAST_1',
  AP_NORTHEAST_2 = 'AP_NORTHEAST_2',
  AP_SOUTHEAST_1 = 'AP_SOUTHEAST_1',
  AP_SOUTHEAST_2 = 'AP_SOUTHEAST_2',
  AP_SOUTHEAST_3 = 'AP_SOUTHEAST_3',
  AP_SOUTHEAST_4 = 'AP_SOUTHEAST_4',
  AP_SOUTH_1 = 'AP_SOUTH_1',
  AP_SOUTH_2 = 'AP_SOUTH_2',
  ASIA_EAST = 'ASIA_EAST',
  ASIA_EAST_2 = 'ASIA_EAST_2',
  ASIA_NORTHEAST_2 = 'ASIA_NORTHEAST_2',
  ASIA_NORTHEAST_3 = 'ASIA_NORTHEAST_3',
  ASIA_SOUTH_1 = 'ASIA_SOUTH_1',
  ASIA_SOUTH_2 = 'ASIA_SOUTH_2',
  ASIA_SOUTH_EAST = 'ASIA_SOUTH_EAST',
  AUSTRALIA_CENTRAL = 'AUSTRALIA_CENTRAL',
  AUSTRALIA_CENTRAL_2 = 'AUSTRALIA_CENTRAL_2',
  AUSTRALIA_EAST = 'AUSTRALIA_EAST',
  AUSTRALIA_SOUTHEAST_1 = 'AUSTRALIA_SOUTHEAST_1',
  AUSTRALIA_SOUTHEAST_2 = 'AUSTRALIA_SOUTHEAST_2',
  AUSTRALIA_SOUTH_EAST = 'AUSTRALIA_SOUTH_EAST',
  BRAZIL_SOUTH = 'BRAZIL_SOUTH',
  BRAZIL_SOUTHEAST = 'BRAZIL_SOUTHEAST',
  CANADA_CENTRAL = 'CANADA_CENTRAL',
  CANADA_EAST = 'CANADA_EAST',
  CA_CENTRAL_1 = 'CA_CENTRAL_1',
  CENTRAL_US = 'CENTRAL_US',
  CN_NORTH_1 = 'CN_NORTH_1',
  CN_NORTHWEST_1 = 'CN_NORTHWEST_1',
  EASTERN_ASIA_PACIFIC = 'EASTERN_ASIA_PACIFIC',
  EASTERN_US = 'EASTERN_US',
  EUROPE_NORTH = 'EUROPE_NORTH',
  EUROPE_NORTH_1 = 'EUROPE_NORTH_1',
  EUROPE_WEST = 'EUROPE_WEST',
  EUROPE_WEST_2 = 'EUROPE_WEST_2',
  EUROPE_WEST_3 = 'EUROPE_WEST_3',
  EUROPE_WEST_4 = 'EUROPE_WEST_4',
  EUROPE_WEST_6 = 'EUROPE_WEST_6',
  EUROPE_WEST_8 = 'EUROPE_WEST_8',
  EUROPE_WEST_9 = 'EUROPE_WEST_9',
  EUROPE_SOUTHWEST_1 = 'EUROPE_SOUTHWEST_1',
  EUROPE_CENTRAL_2 = 'EUROPE_CENTRAL_2',
  EU_CENTRAL_1 = 'EU_CENTRAL_1',
  EU_CENTRAL_2 = 'EU_CENTRAL_2',
  EU_SOUTH_1 = 'EU_SOUTH_1',
  EU_SOUTH_2 = 'EU_SOUTH_2',
  EU_NORTH_1 = 'EU_NORTH_1',
  EU_WEST_1 = 'EU_WEST_1',
  EU_WEST_2 = 'EU_WEST_2',
  EU_WEST_3 = 'EU_WEST_3',
  FRANCE_CENTRAL = 'FRANCE_CENTRAL',
  FRANCE_SOUTH = 'FRANCE_SOUTH',
  GERMANY_WEST_CENTRAL = 'GERMANY_WEST_CENTRAL',
  INDIA_CENTRAL = 'INDIA_CENTRAL',
  INDIA_SOUTH = 'INDIA_SOUTH',
  INDIA_WEST = 'INDIA_WEST',
  JAPAN_EAST = 'JAPAN_EAST',
  JAPAN_WEST = 'JAPAN_WEST',
  KOREA_CENTRAL = 'KOREA_CENTRAL',
  KOREA_SOUTH = 'KOREA_SOUTH',
  ME_SOUTH_1 = 'ME_SOUTH_1',
  ME_CENTRAL_1 = 'ME_CENTRAL_1',
  NORTHEASTERN_ASIA_PACIFIC = 'NORTHEASTERN_ASIA_PACIFIC',
  NORTH_AMERICA_NORTHEAST_1 = 'NORTH_AMERICA_NORTHEAST_1',
  NORTH_AMERICA_NORTHEAST_2 = 'NORTH_AMERICA_NORTHEAST_2',
  NORWAY_EAST = 'NORWAY_EAST',
  NORWAY_WEST = 'NORWAY_WEST',
  QATAR_CENTRAL = 'QATAR_CENTRAL',
  SA_EAST_1 = 'SA_EAST_1',
  SOUTHEASTERN_ASIA_PACIFIC = 'SOUTHEASTERN_ASIA_PACIFIC',
  SOUTH_AFRICA_NORTH = 'SOUTH_AFRICA_NORTH',
  SOUTH_AFRICA_WEST = 'SOUTH_AFRICA_WEST',
  SOUTH_AMERICA_EAST_1 = 'SOUTH_AMERICA_EAST_1',
  SOUTH_AMERICA_WEST_1 = 'SOUTH_AMERICA_WEST_1',
  SWITZERLAND_NORTH = 'SWITZERLAND_NORTH',
  SWITZERLAND_WEST = 'SWITZERLAND_WEST',
  SWEDEN_CENTRAL = 'SWEDEN_CENTRAL',
  SWEDEN_SOUTH = 'SWEDEN_SOUTH',
  UAE_CENTRAL = 'UAE_CENTRAL',
  UAE_NORTH = 'UAE_NORTH',
  UK_SOUTH = 'UK_SOUTH',
  UK_WEST = 'UK_WEST',
  US_CENTRAL = 'US_CENTRAL',
  US_EAST = 'US_EAST',
  US_EAST_1 = 'US_EAST_1',
  US_EAST_2 = 'US_EAST_2',
  US_EAST_4 = 'US_EAST_4',
  US_NORTH_CENTRAL = 'US_NORTH_CENTRAL',
  US_SOUTH_CENTRAL = 'US_SOUTH_CENTRAL',
  US_WEST = 'US_WEST',
  US_WEST_1 = 'US_WEST_1',
  US_WEST_2 = 'US_WEST_2',
  US_WEST_3 = 'US_WEST_3',
  WESTERN_EUROPE = 'WESTERN_EUROPE',
  WESTERN_US = 'WESTERN_US',
  US_GOV_WEST_1 = 'US_GOV_WEST_1',
  US_GOV_EAST_1 = 'US_GOV_EAST_1',
}

export const Continent = {
  EMEA: 'EMEA',
  EUROPE: 'Europe',
  AMERICAS: 'Americas',
  NORTH_AMERICA: 'North America',
  SOUTH_AMERICA: 'South America',
  APAC: 'APAC',
  ASIA: 'Asia',
  AUSTRALIA: 'Australia',
  MIDDLE_EAST: 'Middle East',
  AFRICA: 'Africa',
} as const;

export type ContinentName = (typeof Continent)[keyof typeof Continent];

export enum RegionNamesNone {
  UNSELECTED = '',
}

export type RegionName = keyof typeof RegionNames | RegionNamesNone.UNSELECTED;

export enum AWSEnv {
  GOV_CLOUD = 'Gov Cloud',
  STANDARD = 'Standard',
}

export interface Region {
  awsEnv?: AWSEnv;
  continent: ContinentName;
  isRecommended: boolean;
  key: RegionName;
  latitude: number;
  location: string;
  longitude: number;
  name: string;
  provider: BackingCloudProviderName;
}

export interface RegionByInstance {
  availableFamilies: Array<string>;
  isBlacklisted: boolean;
  providerName: CloudProviderName;
  regionName: string;
}

export const CANADIAN_REGIONS: Array<RegionName> = [
  RegionNames.CA_CENTRAL_1,
  RegionNames.CANADA_EAST,
  RegionNames.CANADA_CENTRAL,
  RegionNames.NORTH_AMERICA_NORTHEAST_1,
  RegionNames.NORTH_AMERICA_NORTHEAST_2,
];

export const EUROPEAN_CONTINENT_KEYS: ReadonlyArray<ContinentName> = [Continent.EMEA, Continent.EUROPE];

export const AMERICA_CONTINENT_KEYS: ReadonlyArray<ContinentName> = [
  Continent.AMERICAS,
  Continent.SOUTH_AMERICA,
  Continent.NORTH_AMERICA,
];

export const ASIA_CONTINENT_KEYS: ReadonlyArray<ContinentName> = [Continent.ASIA, Continent.APAC];

export const AUSTRALIA_CONTINENT_KEYS: ReadonlyArray<ContinentName> = [Continent.AUSTRALIA];

export type ContinentGrouping = 'Americas' | 'EMEA' | 'APAC';
/**
 * When adding a templated read-only node, we need to be able to tell which general areas of the world already have
 * coverage. For now at least, we're only getting as specific as Americas, EMEA, or APAC, and don't need to be more
 * precise. Also, GCP regions don't have more specific names. This mapping allows us to keep track of which region
 * is in which larger area of the world.
 */
export const normalizedContinentNames: Record<ContinentName, ContinentGrouping> = {
  Americas: 'Americas',
  'North America': 'Americas',
  'South America': 'Americas',
  APAC: 'APAC',
  Australia: 'APAC',
  Asia: 'APAC',
  EMEA: 'EMEA',
  Africa: 'EMEA',
  Europe: 'EMEA',
  'Middle East': 'EMEA',
};
