import { ServerlessLoadBalancingDeployment } from '@packages/types/dashboard/ServerlessLoadBalancingDeployment';

import fetchWrapper from '../fetchWrapper';

export default {
  getDeployments() {
    return fetchWrapper('/admin/nds/serverlessLoadBalancingDeployments', {
      method: 'GET',
    }).then((resp) => resp.json());
  },
  getDeploymentById(id: string) {
    return fetchWrapper(`/admin/nds/serverlessLoadBalancingDeployments/${id}`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },
  updateDeployment(id: string, serverlessLoadBalancingDeployment: ServerlessLoadBalancingDeployment) {
    return fetchWrapper(`/admin/nds/serverlessLoadBalancingDeployments/${id}`, {
      method: 'PATCH',
      body: JSON.stringify(serverlessLoadBalancingDeployment),
    });
  },
};
