import { useEffect, useReducer, useRef } from 'react';

import { Helmet } from 'react-helmet';

import AutoAdvanceInputs from '@packages/components/AutoAdvanceInputs';

import { useAccountUser } from 'js/common/context/AccountUserContext';
import { useRequestParams } from 'js/common/context/RequestParamsContext';
import { auth } from 'js/common/services/api';
import { oktaSessionExists } from 'js/common/utils/oktaUtils';

import deviceCodeReducer from 'js/auth/reducers/deviceCodeReducer';

import { DeviceAuthConfirmation } from './DeviceAuthConfirmation';
import { DeviceAuthInput } from './DeviceAuthInput';
import HeaderLogo from './HeaderLogo';
import Layout from './Layout';
import { MultiFactorAuthContainer } from './styles/multiFactorPage';

interface DeviceAuthPageProps {
  windowLocation: Pick<Location, 'assign'>;
  oktaUserSessionExists: () => Promise<boolean>;
}

export function DeviceAuthPage({
  windowLocation = window.location,
  oktaUserSessionExists = oktaSessionExists,
}: DeviceAuthPageProps) {
  const { accountCentralUrl } = useRequestParams();
  const { accountUserParams, loadAccountUserParams } = useAccountUser();
  const { failedToLoad, firstName, lastName, username } = accountUserParams;
  const autoAdvanceInputsRef = useRef<AutoAdvanceInputs>(null);

  function redirectToLogin() {
    windowLocation.assign(`/account/login?fromURI=${accountCentralUrl}/account/connect`);
  }

  const [{ sourceIp, sourceUserAgent, error, sessionId, createdAt, deviceCodeId, deviceCodeStatus }, fetchDispatch] =
    useReducer(deviceCodeReducer, {
      deviceCodeId: '',
      userCode: '',
      sessionId: '',
      sourceIp: '',
      sourceUserAgent: '',
      createdAt: '',
      deviceCodeStatus: 'LOADING',
    });

  useEffect(() => {
    async function loadAuthParams() {
      await loadAccountUserParams();
    }

    async function checkIfOktaSessionExists() {
      try {
        const sessionExists = await oktaUserSessionExists();
        if (!sessionExists) {
          redirectToLogin();
        }
      } catch {
        // ignore errors, so that users that are logged in can still complete the auth flow
      }
    }

    loadAuthParams();
    checkIfOktaSessionExists();

    if (failedToLoad) {
      redirectToLogin();
    } else {
      fetchDispatch({
        type: 'onLoad',
      });
    }
  }, [accountCentralUrl, windowLocation, failedToLoad, loadAccountUserParams, oktaSessionExists]);

  function onComplete({ value }) {
    if (value == null || value.length !== 8) {
      autoAdvanceInputsRef?.current?.reset();
      fetchDispatch({
        type: 'onCompleteError',
        payload: {
          error: 'invalid code',
        },
      });
      return;
    }
    auth
      .verifyDeviceCode({ userCode: value })
      .then((resp) => {
        fetchDispatch({
          type: 'onPostComplete',
          payload: {
            deviceCodeId: resp.deviceCode,
            userCode: value,
            sessionId: resp.sessionId,
            sourceIp: resp.sourceIp,
            sourceUserAgent: resp.sourceUserAgent,
            createdAt: resp.createdAt,
          },
        });
      })
      .catch((e) => {
        autoAdvanceInputsRef?.current?.reset();
        fetchDispatch({
          type: 'onCompleteError',
          payload: {
            error: e,
          },
        });
      });
  }

  function onConfirm() {
    auth
      .confirmDeviceCode({ deviceCode: deviceCodeId, sessionId })
      .then(({ loginRedirectUrl }) => {
        windowLocation.assign(loginRedirectUrl);
      })
      .catch((e) => {
        autoAdvanceInputsRef?.current?.reset();
        fetchDispatch({
          type: 'onCompleteError',
          payload: {
            error: e,
          },
        });
      });
  }

  function onBack() {
    fetchDispatch({
      type: 'onBack',
    });
    autoAdvanceInputsRef?.current?.reset();
  }

  function getPageContentPerStatus() {
    switch (deviceCodeStatus) {
      case 'INPUT':
        return (
          <DeviceAuthInput
            title="Paste or enter the verification code"
            username={username}
            firstName={firstName}
            lastName={lastName}
            onComplete={onComplete}
            autoAdvanceInputsRef={autoAdvanceInputsRef}
            error={error}
          />
        );
      case 'CONFIRMING':
        return (
          <DeviceAuthConfirmation
            title="Confirm your authorization"
            sourceIp={sourceIp}
            sourceUserAgent={sourceUserAgent}
            createdAt={createdAt}
            onConfirm={onConfirm}
            onBack={onBack}
          />
        );
      default:
        return <div />;
    }
  }

  return (
    <>
      <Helmet title="Activation" />
      <Layout contentPlacement="right">
        <MultiFactorAuthContainer>
          <HeaderLogo />
          {getPageContentPerStatus()}
        </MultiFactorAuthContainer>
      </Layout>
    </>
  );
}
