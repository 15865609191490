import { useReducer } from 'react';

import { css } from '@emotion/react';
import Banner from '@leafygreen-ui/banner';
import Button from '@leafygreen-ui/button';
import { Body } from '@leafygreen-ui/typography';
import { produce } from 'immer';

import { EmailOktaMfaFactor, FactorType } from '@packages/types/accountMultiFactorAuth';
import { Search } from '@packages/types/auth/search';

import VerifyPasscodeInput from '@packages/components/MultiFactorAuth/VerifyPasscodeInput';

import * as api from 'js/common/services/api';
// common styles
import { bodyParagraphStyles } from 'js/common/styles/multiFactorAuth';
import { redactEmail } from 'js/common/utils/multiFactorAuthUtils';

const sendButtonStyles = css({
  width: '100px',
});

const errorBannerStyles = css({
  marginBottom: '14px',
  boxSizing: 'border-box',
});

type VerifyEmailAction =
  | { type: 'onChallenge' }
  | { type: 'onChallengeSuccess' }
  | { type: 'onChallengeError' }
  | { type: 'setShowSendAgain'; payload: boolean };

interface VerifyEmailReducerState {
  isEmailSending: boolean;
  isEmailSent: boolean;
  showSendAgain: boolean;
  errorMessage: string;
}

const initialState: VerifyEmailReducerState = {
  isEmailSending: false,
  isEmailSent: false,
  showSendAgain: false,
  errorMessage: '',
};

const verifyEmailReducer = produce((draft: VerifyEmailReducerState, action: VerifyEmailAction) => {
  switch (action.type) {
    case 'onChallenge': {
      draft.isEmailSending = true;
      return;
    }
    case 'onChallengeSuccess': {
      draft.isEmailSent = true;
      draft.isEmailSending = false;
      draft.errorMessage = '';
      return;
    }
    case 'onChallengeError': {
      draft.isEmailSent = false;
      draft.isEmailSending = false;
      draft.errorMessage = 'A server error has occurred. Please try again in a moment.';
      return;
    }
    case 'setShowSendAgain': {
      draft.showSendAgain = action.payload;
      return;
    }
    default:
      return draft;
  }
}, initialState);

interface VerifyEmailFactorProps {
  emailFactor: EmailOktaMfaFactor;
  clientState: Search;
  onSuccess: (accountTemporaryAuthTokenId?: string) => void;
  isDeleteFlow?: boolean;
  isEmailChangeFlow?: boolean;
}

export default function VerifyEmailFactor({
  emailFactor,
  clientState,
  onSuccess,
  isDeleteFlow,
  isEmailChangeFlow,
}: VerifyEmailFactorProps) {
  const [{ isEmailSent, isEmailSending, showSendAgain, errorMessage }, dispatch] = useReducer(
    verifyEmailReducer,
    initialState
  );
  const { stateToken } = clientState;
  const isAuthFlow = !!stateToken;

  const sendEmailChallenge = async () => {
    dispatch({ type: 'onChallenge' });

    try {
      if (isAuthFlow) {
        await api.auth.verifyAuthMfa({
          factorId: emailFactor.id,
          stateToken,
          clientState,
        });
      } else {
        await api.accountMultiFactorAuth.verifyFactor(
          {
            factorType: FactorType.Email,
            factorId: emailFactor.id,
          },
          isDeleteFlow
        );
      }

      dispatch({ type: 'onChallengeSuccess' });
      setTimeout(() => {
        dispatch({ type: 'setShowSendAgain', payload: true });
      }, 30000);
    } catch ({ errorCode }) {
      dispatch({ type: 'onChallengeError' });
    }
  };

  const resendEmail = async () => {
    dispatch({ type: 'setShowSendAgain', payload: false });

    // If we're in the auth flow, there's a separate endpoint to resend
    if (isAuthFlow) {
      await api.auth.resendAuthMfa({
        factorId: emailFactor.id,
        stateToken,
      });
    } else {
      sendEmailChallenge();
    }
  };

  const redactedEmail = redactEmail(emailFactor.profile.email);
  return (
    <>
      {errorMessage && (
        <Banner variant="danger" data-testid="error-banner" css={errorBannerStyles}>
          {errorMessage}
        </Banner>
      )}
      {isEmailSent ? (
        <>
          <Body css={bodyParagraphStyles}>
            A verification code has been sent to <b>{redactedEmail}</b>. Enter the code to continue and be redirected.
          </Body>
          {showSendAgain && (
            <Body css={bodyParagraphStyles}>
              <Button css={sendButtonStyles} disabled={isEmailSending} onClick={resendEmail} variant="primary">
                Send Again
              </Button>
            </Body>
          )}
          <VerifyPasscodeInput
            inputType="code"
            factor={emailFactor}
            clientState={clientState}
            onSuccess={onSuccess}
            isEmailChangeFlow={isEmailChangeFlow}
          />
        </>
      ) : (
        <>
          <Body css={bodyParagraphStyles}>
            Send a verification code to <b>{redactedEmail}</b>.
          </Body>
          <Button css={sendButtonStyles} variant="primary" disabled={isEmailSending} onClick={sendEmailChallenge}>
            Send Code
          </Button>
        </>
      )}
    </>
  );
}
