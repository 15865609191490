import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Button, { ButtonProps } from '@leafygreen-ui/button';
import { palette } from '@leafygreen-ui/palette';
import { Body, Description, Label } from '@leafygreen-ui/typography';

import LineDivider from '@packages/components/LineDivider';

import { mq } from 'js/common/utils/mediaQueries';

export const getLabelStyle = (isDisabled?: boolean) => css`
  color: ${isDisabled ? palette.gray.dark1 : palette.black};
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  margin-block-end: 0px;
`;

export const ThirdPartyIntegrationLabel = styled(Label)`
  ${({ disabled }) => getLabelStyle(disabled)}
`;

export const ThirdPartyIntegrationBodyLabel = styled(Body)<{ disabled?: boolean }>`
  ${({ disabled }) => getLabelStyle(disabled)}
`;

export const ThirdPartyIntegrationDescription = styled(Description)`
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  margin-block-start: 0px;
  color: ${palette.gray.dark1};
  a {
    color: ${palette.blue.base};
    text-decoration: none;
  }
`;

export const ThirdPartyIntegrationSubmitButton = styled<React.ComponentType<ButtonProps>>(Button)`
  margin: 24px 0px;
  width: 100%;
`;

export const containerStyle = css`
  margin: 24px auto 0;
  max-width: 524px;
`;

export const selectInputStyle = css`
  margin-top: 24px;

  label {
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    color: ${palette.black};
  }

  div {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: ${palette.gray.dark1};
  }

  button {
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    color: ${palette.gray.dark3};
  }
`;

export const ThirdPartyIntegrationLineDivider = styled(LineDivider)(
  mq({
    width: '100%',
    margin: '16px 0',
  })
);
