import fetchWrapper from '../fetchWrapper';

export default {
  patchAuditLog(groupId, auditLog) {
    return fetchWrapper(`/nds/${groupId}/auditLog`, {
      method: 'PATCH',
      body: JSON.stringify(auditLog),
    }).then((resp) => resp.json());
  },

  getAuditLog(groupId) {
    return fetchWrapper(`/nds/${groupId}/auditLog`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getAuditEventActions(groupId) {
    return fetchWrapper(`/nds/${groupId}/auditLog/auditEventActions`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },
};
