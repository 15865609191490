import 'cross-fetch/polyfill';

// Bugsnag limits the amount of metadata we can include. Our
// metadata includes at least group and as much of the 5 most recent ajax calls as we can make fit
// inside the payload limit. The payload will also need room to store breadcrumbs.
function truncateAndAttachAjaxMetadata(recentAjaxCalls) {
  return (report) => {
    const ajaxCallsPayload = recentAjaxCalls.map((ajaxCall) => Object.assign({}, ajaxCall));
    ajaxCallsPayload.reverse();

    function getPayloadSize() {
      const finalMetadata = Object.assign({}, report.metaData, { 'AJAX History': ajaxCallsPayload });
      const jsonString = JSON.stringify(finalMetadata);
      return jsonString.length;
    }

    // Maximum safe size for a payload to Bugsnag (Bugsnag transmits metadata in the body as of v4)
    // Bugsnag API server maximum request size in 1 MB. UTF-8 is up to 4 bytes per character.
    const MAX_PAYLOAD_SIZE = 2.5e5;
    function isPayloadSmallEnough() {
      return getPayloadSize() < MAX_PAYLOAD_SIZE;
    }

    function truncateValues(value) {
      if (Array.isArray(value)) {
        if (value.reduce((acc, item) => acc && !(typeof item === 'object'), true)) {
          return [1];
        }

        if (value.length > 25) {
          return truncateValues(value.slice(0, 25)).concat(['...']);
        }

        return value.map((item) => truncateValues(item));
      }
      if (value && typeof value === 'object') {
        return Object.keys(value).reduce((acc, key) => {
          acc[key] = truncateValues(value[key]);
          return acc;
        }, {});
      }

      return 1;
    }

    // Drop at most all but one call if payload too large
    while (!isPayloadSmallEnough() && ajaxCallsPayload.length > 1) {
      ajaxCallsPayload.pop();
    }

    // If payload still large attempt to trim the call
    if (!isPayloadSmallEnough()) {
      ajaxCallsPayload[0].response = truncateValues(ajaxCallsPayload[0].response);
    }

    // If payload still too large, drop response text
    if (!isPayloadSmallEnough()) {
      delete ajaxCallsPayload[0].response;
    }

    // If payload still too large, drop body
    if (!isPayloadSmallEnough()) {
      delete ajaxCallsPayload[0].body;
    }

    report.updateMetaData('AJAX History', ajaxCallsPayload);
  };
}

function initializeJQuery({ $, recentAjaxCalls }) {
  $(document).ajaxComplete((event, jqXHR, ajaxOptions) => {
    if (recentAjaxCalls.length >= 5) {
      recentAjaxCalls.pop();
    }

    recentAjaxCalls.push({
      url: ajaxOptions.url,
      headers: ajaxOptions.headers,
      method: ajaxOptions.type || ajaxOptions.method || 'GET',
      body: ajaxOptions.data,
      response: jqXHR.responseJSON || jqXHR.responseText,
    });
  });
}

function initializeFetchWrapper({ fetchWrapper, recentAjaxCalls }) {
  fetchWrapper.registerOnResponseHandler((url, options, response) => {
    if (recentAjaxCalls.length >= 5) {
      recentAjaxCalls.pop();
    }

    if (response.ok) {
      response.json().then(
        (json) => {
          recentAjaxCalls.push({
            url,
            method: options.method,
            headers: options.headers,
            body: options.body,
            response: json,
          });
        },
        () => {}
      );
    }
  });
}

function setBugsnagMetadata({ userId, username, app, metaData }) {
  const bugsnagClient = window.bugsnagClient;
  if (!bugsnagClient) {
    return;
  }

  if (userId || username) {
    bugsnagClient.user = {
      id: userId,
      username,
    };
  }

  bugsnagClient.metaData = {};

  bugsnagClient.metaData[app] = metaData;
}

/*
 * If we report an error from a version of the app whose javascript files are no longer hosted on our servers
 * bugsnag won't be able to associate the error with a sourcemapped line. Here, we'll use the app's gitVersion
 * written to the page when it was loaded as a proxy for which version of the javascript files this client is using
 * and compare it to the current git version of the server dynamically.
 */
function isDifferentAppVersion(pageGitVersion) {
  // In local development, in order to cache bust on every page refresh we use an ObjectID (24 hex characters) instead
  // of the gitHash as the pageGitVersion by setting mms.ui.cacheStatic to false. The version endpoint will always
  // return the actual git hash (40 hex characters) so we catch this case here and assume it's not a different app
  // version.
  if (pageGitVersion.length === 24) {
    return Promise.resolve(false);
  }

  return fetch('/version')
    .then((response) => {
      if (response.status !== 200) {
        throw new Error('Failed to get current gitVersion');
      }

      return response.text();
    })
    .then((gitVersion) => {
      return gitVersion !== pageGitVersion;
    })
    .catch(() => {
      // If we could not fetch app gitVersion, assume unchanged
      return false;
    });
}

let jqueryInitialized = false;
let fetchWrapperInitialized = false;
const recentAjaxCalls = [];

function initializeBugsnag({ $, fetchWrapper }) {
  const setupData = (window as any).bugsnagClientSetupData;

  if (!setupData || !(window as any).bugsnag) {
    return;
  }

  if (!window.bugsnagClient) {
    const cachedTruncateFn = truncateAndAttachAjaxMetadata(recentAjaxCalls);
    const beforeSend = (report) => {
      cachedTruncateFn(report);
      if (report.request.url.startsWith('file://')) {
        report.ignore();
      }
      return isDifferentAppVersion(setupData.gitVersion).then((appVersionChanged) => {
        if (appVersionChanged) {
          report.ignore();
        }
      });
    };

    window.bugsnagClient = (window as any).bugsnag({
      apiKey: setupData.apiKey,
      appVersion: setupData.appVersion,
      releaseStage: setupData.releaseStage,
      beforeSend,
    });
  }

  try {
    // For AJAX requests sent through jQuery store the request/response
    if ($ && !jqueryInitialized) {
      initializeJQuery({ $, recentAjaxCalls });
      jqueryInitialized = true;
    }

    // For AJAX requests sent through fetchWrapper store the request/response
    if (fetchWrapper && !fetchWrapperInitialized) {
      initializeFetchWrapper({ fetchWrapper, recentAjaxCalls });
      fetchWrapperInitialized = true;
    }
  } catch (error) {
    /* eslint-disable no-console */
    console.error('Failed to initialize bugsnag.');
    console.error(error);
    console.error(`recentAjaxCalls: ${recentAjaxCalls}`);
    console.error(`jqueryInitialized: ${jqueryInitialized}; fetchWrapperInitialized: ${fetchWrapperInitialized}`);
    /* eslint-enable no-console */
  }
}
export { setBugsnagMetadata, initializeBugsnag };
