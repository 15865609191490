import { useEffect, useState } from 'react';

import { OktaMfaFactor } from '@packages/types/accountMultiFactorAuth';

import * as api from 'js/common/services/api';

export default function useMfaFactors() {
  const [factors, setFactors] = useState<Array<OktaMfaFactor>>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [userFactorsLoadError, setUserFactorsLoadError] = useState();

  useEffect(() => {
    const loadUserFactors = async () => {
      setIsLoading(true);
      try {
        const response = await api.accountMultiFactorAuth.getUserFactors();
        setFactors(response);
      } catch ({ errorCode }) {
        setUserFactorsLoadError(errorCode);
      } finally {
        setIsLoading(false);
      }
    };

    loadUserFactors();
  }, []);

  return { factors, isLoading, userFactorsLoadError };
}
