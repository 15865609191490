export enum FieldNames {
  PASSWORD = 'password',
  USERNAME = 'username',
  COMPANY = 'company',
  COUNTRY = 'country',
  JOB_RESPONSIBILITY = 'jobResponsibility',
  PHONE_NUMBER = 'phoneNumber',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  HONEYPOT = 'honeypot',
  TOS = 'tos',
  IS_SUBMIT_DISABLED = 'isSubmitDisabled',
  IS_SOCIAL_SIGNUP_DISABLED = 'isSocialSignupDisabled',
  IS_LOGIN_DISABLED = 'isLoginDisabled',
  DISABLE_SUBMIT = 'disableSubmit',
  ACCORDION_OPEN = 'accordionOpen',
  RESET_SUCCESS = 'resetSuccess',
  SHOULD_CONTACT_SUPPORT = 'shouldContactSupport',
  API_KEY = 'apiKey',
  DB_USER_NAME = 'dbUsername',
  DB_PASSWORD = 'dbPassword',
}

export interface FieldPayload {
  field: FieldNames;
  value?: string | boolean;
}

export interface FieldAction {
  type: 'field';
  payload: FieldPayload;
}
