import _ from 'underscore';

// This Backbone utility allows a mixin to be applied to a Class before it is extended. It wraps
// the given Class and ensures that future extensions with matching members do not destroy
// underlying mixin.
// Supports mixin functions (methods) by calling them first before the overriding member and
// mixin properties by extending overriding properties into the mixin.

// Usage:   call this module with an object to mix in.
// Returns: a function that when given a Class, will mixin to it and ensure any future extensions will
//          call (for a function) or extend over (for a property) the original mixin
export default function (mixin) {
  // return a function that when given a class, wraps it and ensures the mixin's members are not
  // eclipsed by any extension members
  // Clazz must be a Backbone class (in that it has the static method: extend
  return function (Clazz) {
    // first extend the class over the mixin
    const MixedIntoClazz = Clazz.extend(mixin);

    // now, prevent extensions of this MixedInClazz from eclipsing a mixed-in method
    // we do this by wrapping Backbone.extend()
    MixedIntoClazz.extend = function () {
      // the class that the user has extended
      const ExtendedClazz = Clazz.extend.apply(this, arguments);

      // for all methods and props in the mixin,
      Object.keys(mixin).forEach((mixinMemberKey) => {
        // ignore if mixin member was not overrided
        if (mixin[mixinMemberKey] === ExtendedClazz.prototype[mixinMemberKey]) {
          return;
        }

        // now handle when both mixin member and extended member are objects
        if (typeof mixin[mixinMemberKey] === 'object' && typeof ExtendedClazz.prototype[mixinMemberKey] === 'object') {
          // by extending the instanceProp onto a copy of the mixin prop
          ExtendedClazz.prototype[mixinMemberKey] = _.extend(
            _.clone(mixin[mixinMemberKey]),
            ExtendedClazz.prototype[mixinMemberKey]
          );
        } else if (
          typeof mixin[mixinMemberKey] === 'function' &&
          typeof ExtendedClazz.prototype[mixinMemberKey] === 'function'
        ) {
          // or handle when both are methods
          // by tracking the overriding method
          const overridingMethod = ExtendedClazz.prototype[mixinMemberKey];

          // and replacing it with a wrapper
          ExtendedClazz.prototype[mixinMemberKey] = function () {
            // that calls the mixin method first
            const mixinResult = mixin[mixinMemberKey].apply(this, arguments);

            // before calling the overriding method
            const overridingResult = overridingMethod.apply(this, arguments);

            // if functions return objects, merge together results
            if (typeof mixinResult === 'object') {
              if (typeof overridingResult === 'object') {
                return _.extend({}, mixinResult, overridingResult);
              }
              throw new Error(
                `Mixin and overriding method returned different types. Offending member: ${mixinMemberKey}`
              );
            }
          };
        } else {
          // otherwise do not support
          throw new Error(
            `Cannot extend over mixin which are not matching types. Member: ${mixinMemberKey} must both be of type function or object.`
          );
        }
      });

      // finally return the extended clazz
      return ExtendedClazz;
    };

    return MixedIntoClazz;
  };
}
