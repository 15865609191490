import { useEffect, useState } from 'react';

import { css } from '@emotion/react';
import Banner from '@leafygreen-ui/banner';
import Card from '@leafygreen-ui/card';

import { FactorType, TotpFactorEnrollmentResponse } from '@packages/types/accountMultiFactorAuth';

import VerifyPasscodeInput, {
  VerifyPasscodeInputResponse,
} from '@packages/components/MultiFactorAuth/VerifyPasscodeInput';

import * as api from 'js/common/services/api';
import { getErrorMessageFromCode } from 'js/common/services/authErrorHelper';
// common styles
import { bannerStyles, baseStyle, setupStyles, Step } from 'js/common/styles/multiFactorAuth';
import { CloudTeams, sendError } from 'js/common/utils/bugsnag';
import { isLastAuthExpired } from 'js/common/utils/multiFactorAuthUtils';

const barcodeLoaderStyles = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '164px',
  width: '164px',
});

const barcodeValueStyles = css({
  margin: '4px 0px',
});

export default function AuthenticatorSetupCard({
  windowLocation = window.location,
  onSuccess,
  authExpiredPath,
  username,
}: {
  windowLocation?: Pick<Location, 'assign'>;
  onSuccess: (response: VerifyPasscodeInputResponse) => void;
  authExpiredPath: string;
  username: string;
}) {
  const [factorEnrollmentResponse, setFactorEnrollmentResponse] = useState<TotpFactorEnrollmentResponse>();
  const [errorMessage, setErrorMessage] = useState('');
  const [showBarcodeValue, setBarcodeValue] = useState(false);

  useEffect(() => {
    const enrollAuthFactor = async () => {
      try {
        const response = await api.accountMultiFactorAuth.enrollFactor({ factorType: FactorType.TokenSoftwareTotp });
        setFactorEnrollmentResponse(response as TotpFactorEnrollmentResponse);
      } catch ({ errorCode, errorMessage: enrollErrorMessage }) {
        sendError({ error: errorCode, team: CloudTeams.CoreIam });

        if (isLastAuthExpired(errorCode)) {
          windowLocation.assign(authExpiredPath);
        } else if (errorCode === 'RATE_LIMITED') {
          setErrorMessage(getErrorMessageFromCode(errorCode));
        } else {
          setErrorMessage(enrollErrorMessage);
        }
      }
    };

    if (username !== '') {
      enrollAuthFactor();
    }
  }, [username]);

  return (
    <Card css={setupStyles.card}>
      <Step.Container>
        <Step.Header>Step One</Step.Header>
        <div>
          Download/Use any authenticator application (Google Authenticator, Microsoft Authenticator,etc.) on your mobile
          device.
        </div>
      </Step.Container>
      <Step.Container>
        <Step.Header>Step Two</Step.Header>
        <div>
          On your authenticator application, tap the &quot;+&quot; icon and then select &quot;Scan QR code&quot; to scan
          the QR code below
        </div>
        {errorMessage && (
          <Banner variant="danger" data-testid="error-banner" css={bannerStyles}>
            {errorMessage}
          </Banner>
        )}
        {factorEnrollmentResponse ? (
          <img data-testid="qr-code" src={factorEnrollmentResponse.activation.qrCode.href} alt="QR Code" />
        ) : (
          <div css={barcodeLoaderStyles}>
            <i className="loading-spinner button-waiting-for-async-spinner" />
          </div>
        )}
        {!showBarcodeValue && (
          <div
            css={baseStyle.clickableLink}
            data-testid="barcode-link"
            onClick={() => {
              setBarcodeValue(true);
            }}
            role="button"
            tabIndex={0}
          >
            Can&apos;t scan the barcode?
          </div>
        )}
        {showBarcodeValue && (
          <>
            <p>Enter the following account and key information into the app and continue:</p>
            <b>Account:</b>
            <div data-testid="username" css={barcodeValueStyles}>
              {username}
            </div>
            <b>Key:</b>
            <div data-testid="shared-secret" css={barcodeValueStyles}>
              {factorEnrollmentResponse && factorEnrollmentResponse.activation.sharedSecret}
            </div>
          </>
        )}
      </Step.Container>
      <Step.Container>
        <Step.Header>Step Three</Step.Header>
        <p>Once this code has been scanned, enter the 6-digit code generated by the app.</p>
        <VerifyPasscodeInput inputType="code" isActivation factor={factorEnrollmentResponse} onSuccess={onSuccess} />
      </Step.Container>
    </Card>
  );
}
