export const CLUSTERTYPE_SERVERLESS = 'serverless';

export enum ClusterType {
  Atlas = 'atlas',
  Serverless = 'serverless',
}

export enum MongoDataSourceType {
  SelfHosted = 'mongodb',
  Atlas = 'mongodb-atlas',
  DataFederation = 'datalake',
}

export enum MongoServiceStatus {
  Unlinked = 'UNLINKED',
  Linking = 'LINKING',
  Linked = 'LINKED',
  Error = 'ERROR',
}

interface Namespace {
  database: string;
  collections: Array<string>;
}
export type Namespaces = Array<Namespace>;

export interface ClusterServiceConfig {
  serviceType: 'mongodb-atlas';
  clusterName: string;
  clusterType?: string;
  dbVersion?: string;
}

export interface DataLakeServiceConfig {
  serviceType: 'datalake';
  dataLakeName: string;
}

export interface SelfHostedMongoServiceConfig {
  serviceType: 'mongodb';
}

// Avoid using the LegacyServiceConfig interface when possible, its only purpose
// is to ensure backwards compatibility with configs in mms and baas-ui that predate
// the more strongly typed service configs above
interface LegacyServiceConfig {
  serviceType?: string;
  clusterName?: string;
  dataLakeName?: string;
}

export type MongoServiceConfig =
  | ClusterServiceConfig
  | DataLakeServiceConfig
  | SelfHostedMongoServiceConfig
  | LegacyServiceConfig;

export interface MongoService {
  _id: string;
  name: string;
  config?: MongoServiceConfig;
  status?: MongoServiceStatus;
  svcError?: string;
}
