import styled from '@emotion/styled';
import { palette } from '@leafygreen-ui/palette';

import { mq } from 'js/common/utils/mediaQueries';
import analytics, { SEGMENT_EVENTS } from 'js/common/utils/segmentAnalytics';

import { PlacementProps } from './Layout';

const loginPromoDesktopAsset = 'url(/static/images/auth/login_promo_desktop.png)';
const loginPromoMobileAsset = 'url(/static/images/auth/login_promo_mobile.png)';

export const loginPromoAssets = [
  loginPromoMobileAsset,
  loginPromoMobileAsset,
  loginPromoDesktopAsset,
  loginPromoDesktopAsset,
];

export const loginPromoConfig = {
  TITLE: "Discover What's New in MongoDB 6.0",
  SUBTITLE:
    'Power modern applications with enriched querying capabilities, new operators, added encryption features and more.',
  CTA_DESKTOP_TEXT: "Check out what's new",
  CTA_MOBILE_TEXT: "Check out what's new",
  CTA_URL:
    'https://www.mongodb.com/blog/post/big-reasons-upgrade-mongodb-6-0?utm_campaign=6&utm_source=website&utm_medium=cloud_login',
  SEGMENT_CAMPAIGN_PROP: 'MongoDB 6.0',
};

const LoginPromoDesktopTitle = styled.div(() =>
  mq({
    color: palette.white,
    fontWeight: 'bold',
    fontSize: ['23px', '23px', '23px', '32px'],
    lineHeight: ['31px', '31px', '31px', '40px'],
    width: ['370px', '270px', '270px'],
    maxWidth: '100%',
  })
);

const LoginPromoDesktopSubtext = styled.div(() =>
  mq({
    color: palette.white,
    fontWeight: 'normal',
    fontSize: ['14px', '14px', '14px', '16px'],
    lineHeight: ['20px', '20px', '20px', '24px'],
    width: ['290px', '290px', '290px', '350px'],
    maxWidth: '100%',
    padding: ['16px 0px', '16px 0px', '24px 0px', '24px 0px'],
  })
);

const LoginPromoDesktopContainer = styled.div((props: PlacementProps) =>
  mq({
    display: ['none', 'none', 'flex', 'flex'],
    maxWidth: '100%',
    flexDirection: 'column',
    padding: '60px',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
  })
);

const LoginPromoMobileContainer = styled.a(() =>
  mq({
    color: palette.white,
    fontSize: '15px',
    fontWeight: 'bold',
    lineHeight: '24px',
    textDecoration: 'none',
    display: ['none', 'flex', 'none', 'none'],
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
  })
);

const LoginPromoCTAOuter = styled(LoginPromoMobileContainer)(() =>
  mq({
    width: 'fit-content',
    display: ['flex', 'none', 'flex', 'flex'],
    fontSize: ['13px', '13px', '13px', '16px'],
    lineHeight: '24px',
  })
);

const RightArrow = styled.span({
  color: palette.white,
  fontWeight: 'lighter',
  fontFamily: 'system-ui',
  paddingLeft: '10px',
  display: 'inline-block',
  WebkitFontSmoothing: 'antialiased',
  MozOsxFontSmoothing: 'grayscale',
});

const LoginPromoCTA = styled.span`
  color: ${palette.white};
  border-bottom: 1px solid;
  padding-bottom: 2px;
  display: inline-block;
  font-weight: bold;
  &:hover {
    color: ${palette.gray.light2};
  }
`;

const trackCTAClicked = () => {
  analytics.track(SEGMENT_EVENTS.UX_ACTION_PERFORMED, {
    context: 'Cloud Login Page',
    action: 'Login Page CTA Clicked',
    campaign: loginPromoConfig.SEGMENT_CAMPAIGN_PROP,
  });
};

export function LoginPromo(props: PlacementProps) {
  const { placement } = props;
  return (
    <>
      <LoginPromoDesktopContainer placement={placement}>
        <LoginPromoDesktopTitle>{loginPromoConfig.TITLE}</LoginPromoDesktopTitle>
        <LoginPromoDesktopSubtext>{loginPromoConfig.SUBTITLE}</LoginPromoDesktopSubtext>
        <LoginPromoCTAOuter
          href={loginPromoConfig.CTA_URL}
          target="_blank"
          rel="noopener noreferrer"
          onClick={trackCTAClicked}
        >
          <LoginPromoCTA>{loginPromoConfig.CTA_DESKTOP_TEXT}</LoginPromoCTA>
          <RightArrow>&#8594;</RightArrow>
        </LoginPromoCTAOuter>
      </LoginPromoDesktopContainer>
      <LoginPromoMobileContainer
        onClick={trackCTAClicked}
        href={loginPromoConfig.CTA_URL}
        target="_blank"
        rel="noopener noreferrer"
      >
        <LoginPromoCTA>{loginPromoConfig.CTA_MOBILE_TEXT}</LoginPromoCTA>
        <RightArrow>&#8594;</RightArrow>
      </LoginPromoMobileContainer>
    </>
  );
}
