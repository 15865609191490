import * as api from 'js/common/services/api';

// Action Types
const SET_AUDIT_LOG = 'nds/auditLog/SET_AUDIT_LOG';

const initialState = {
  enabled: false,
  auditAuthorizationSuccess: false,
  configurationType: 'NONE',
};

// Reducer
// eslint-disable-next-line no-multi-assign
export default function auditLogReducer(state = initialState, action) {
  switch (action.type) {
    case SET_AUDIT_LOG: {
      const newState = {
        ...state,
        ...action.payload,
      };
      return newState;
    }
    default:
      return state;
  }
}

const getAuditLogData = (state) => {
  return state.nds.auditLog;
};

// Selectors
export const getAuditLog = (state) => getAuditLogData(state) || {};

// Action Creators
const setAuditLog = (payload) => ({
  type: SET_AUDIT_LOG,
  payload,
});
export { setAuditLog };

export const loadAuditLog = (groupId) => (dispatch) => {
  return api.nds.auditLog.getAuditLog(groupId).then((response) => {
    return dispatch(setAuditLog(response));
  });
};
