import { useEffect, useRef } from 'react';

import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import lottie from 'lottie-web';
import queryString from 'query-string';
import { Helmet } from 'react-helmet';

import { AllocationPoint, TestName } from '@packages/types/abTest';
import { Search } from '@packages/types/auth/search';

import * as api from 'js/common/services/api';
import * as abTestUtil from 'js/common/utils/abTestUtil';
import { useAppUserParams } from 'js/common/context/AppUserContext';
import { useRequestParams } from 'js/common/context/RequestParamsContext';
import { authenticateWithStitch, sanitizeFromURI } from 'js/common/utils/authRedirectUtils';
import { CloudTeams, sendError } from 'js/common/utils/bugsnag';
import { MediumPlusViewport, mq, SmallViewport } from 'js/common/utils/mediaQueries';
import { promisifiedTimeout } from 'js/common/utils/promisifyTimeout';
// analytics
import analytics, { PAGE_CALLS, sanitizeLocation, SEGMENT_EVENTS } from 'js/common/utils/segmentAnalytics';
import { Routes } from 'js/project/common/constants';

import * as postRegistrationHelpers from 'js/auth/utils/postRegistrationUtils';
// images
import mdbSettingUp from 'js/auth/components/images/mdb_setting_up.gif';
import realmSettingUp from 'js/auth/components/images/realm_setting_up.json';
import { useGoogleAnalytics } from 'js/auth/hooks/useGoogleAnalytics';
import { useQuantcastTrackingPixel } from 'js/auth/hooks/useQuantcastTrackingPixel';
import { useQuoraTrackingPixel } from 'js/auth/hooks/useQuoraTrackingPixel';
import { sendAnalyticsEvents, SignupMethod, SignupSource, trackRegistrationData } from 'js/auth/utils/analyticsUtils';
import colors, { thirdPartyColors } from 'js/auth/utils/palette';

enum DestinationApp {
  REALM = 'realm',
}

const Container = styled.div(() =>
  mq({
    display: 'flex',
    flexDirection: 'column',
    width: '100vw',
    alignItems: 'center',
    margin: ['5% 0', '55% 0', '5% 0', '5% 0'],
  })
);

const RealmAnimation = styled.div`
  margin-top: 8%;
`;

const Header = styled.h1`
  color: ${(props) => props.color || colors.successSpinner};
`;

const TextContainer = styled.div(() =>
  mq({
    maxWidth: ['520px', '200px', '520px', '520px'],
    letterSpacing: '0.6px',
    display: 'flex',
    alignItem: 'center',
    flexDirection: 'column',
  })
);

const Text = styled.div`
  text-align: center;
`;

const StrongGreenText = styled.strong`
  color: ${colors.successSpinner};
`;

const spinner = keyframes({
  '0%': {
    transform: 'rotate(0deg)',
  },
  '100%': {
    transform: 'rotate(360deg)',
  },
});

const SpinnerIcon = styled.span`
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  vertical-align: middle;
  border: solid 2px transparent;
  border-top-color: ${(props) => props.color || colors.successSpinner};
  border-left-color: ${(props) => props.color || colors.successSpinner};
  border-radius: 50%;
  animation: ${spinner} 400ms linear infinite;
`;

type WindowLocation = Pick<Location, 'replace'>;

interface RegistrationSuccessPageProps {
  location?: Pick<Location, 'search'>;
  trackRegistration?: (obj: object) => void;
  windowLocation?: WindowLocation;
}

interface RegistrationData {
  company?: string;
  firstName?: string;
  inviteToken?: string;
  groupId?: string;
  isInviteFlow: boolean;
}

const animationTime = 3000;

function RegistrationSuccessPage({
  location: { search } = { search: '' },
  trackRegistration = trackRegistrationData,
  windowLocation = window.location,
}: RegistrationSuccessPageProps) {
  const { appUserParams, loadAppUserParams } = useAppUserParams();
  // If csrfTime and csrfToken are present then authenticated requests can be made
  const { failedToLoad, csrfTime, csrfToken } = appUserParams;
  const { baasCentralUrl, centralUrl, segmentDefaultUserId, isPersonalizationWizardEnabled } = useRequestParams();
  const parsedSearchLocation = queryString.parse(search) as Search;
  const { destinationApp } = parsedSearchLocation;
  const destinationAppAsEnum = destinationApp as DestinationApp;
  const isRealmRegistration = destinationAppAsEnum == DestinationApp.REALM;

  const animationContainer = useRef<HTMLDivElement>(null);
  const RealmLottieAnimation = () => {
    useEffect(() => {
      if (animationContainer.current) {
        lottie.loadAnimation({
          container: animationContainer.current,
          renderer: 'svg',
          loop: true,
          autoplay: true,
          name: 'realm-setting-up',
          animationData: realmSettingUp,
        });
      }
    }, []);
    return <RealmAnimation ref={animationContainer} />;
  };

  useQuoraTrackingPixel();
  useQuantcastTrackingPixel(parsedSearchLocation.signupSource);
  useGoogleAnalytics(parsedSearchLocation, appUserParams);

  useEffect(() => {
    if (failedToLoad) {
      console.log('Failed to load params. Redirecting to the login page');
      return;
    }

    // Determine if a destination location was included in the query params
    parsedSearchLocation.uId = parsedSearchLocation.uId || segmentDefaultUserId;
    const nextUrl = sanitizeFromURI(parsedSearchLocation.fromURI, centralUrl);

    if (nextUrl) {
      // `nextUrl` is used to redirect a user to a product upon successful registration.
      // It's also used for deep linking to non-Cloud products upon successful login.
      // For deep linking to Cloud, use the `n` query param, and for Realm use `nStitch`.
      // Note that `nextUrl` will only redirect to mongodb.com domains to prevent abuse.
      registrationUtils.handleExplicitDestination({
        nextUrl,
        windowLocation,
        queryParams: parsedSearchLocation,
      });
    } else if (csrfToken === '' && csrfTime === '') {
      // This also updates the CSRF headers for subsequent requests.
      // It will also cause a re-render, so we'll need to exit immediately afterward,
      // ensuring nothing lower in the flow gets hit twice.
      loadAppUserParams();
    } else {
      const { username } = appUserParams;
      // Begin redirecting user to their new project
      registrationUtils.initAndRedirectUser({
        username,
        parsedSearchLocation,
        baasCentralUrl,
        centralUrl,
        windowLocation,
        segmentDefaultUserId,
        isRealmRegistration,
        isPersonalizationWizardEnabled,
      });
    }
  }, [csrfTime, csrfToken, search]);

  if (failedToLoad) {
    windowLocation.replace('/account/login?signedOut=true');
    return null;
  }

  return (
    <>
      <Helmet title="Registration Success" />
      <Container>
        <SmallViewport>
          <SpinnerIcon
            aria-label="Configuring your account..."
            color={isRealmRegistration ? thirdPartyColors.realmPurple : colors.successSpinner}
          />
        </SmallViewport>
        <MediumPlusViewport>
          {isRealmRegistration ? (
            <RealmLottieAnimation />
          ) : (
            <img src={mdbSettingUp} alt="Configuring your account..." />
          )}
        </MediumPlusViewport>
        <Header color={isRealmRegistration ? thirdPartyColors.realmPurple : colors.successSpinner}>Welcome!</Header>
        <TextContainer>
          <Text>
            {isRealmRegistration ? (
              <>Use your MongoDB account to build</>
            ) : (
              <>
                Use your account to deploy a <strong>cloud database</strong>
              </>
            )}
          </Text>
          <Text css={{ marginTop: 8 }}>
            {isRealmRegistration ? (
              <>
                with <strong>Atlas App Services</strong> and contact <strong>Support.</strong>
              </>
            ) : (
              <>
                with <StrongGreenText>MongoDB Atlas</StrongGreenText> and contact{' '}
                <StrongGreenText>Support.</StrongGreenText>
              </>
            )}
          </Text>
        </TextContainer>
      </Container>
    </>
  );
}

interface ProjectCreationData {
  groupId: string;
  orgId: string;
}

interface RegistrationUtils {
  initAndRedirectUser: (specs: {
    username: string;
    parsedSearchLocation: Search;
    baasCentralUrl: string;
    centralUrl: string;
    windowLocation: WindowLocation;
    segmentDefaultUserId?: string;
    isRealmRegistration: boolean;
    isPersonalizationWizardEnabled: boolean;
  }) => Promise<unknown>;
  createInitialOrgAndProject: (specs: {
    company: string;
    centralUrl: string;
    discountCode?: string;
    signupSource?: SignupSource | string;
    signupMethod?: SignupMethod | string;
  }) => Promise<ProjectCreationData>;
  getRedirectUrl: (specs: {
    centralUrl: string;
    parsedSearchLocation: Search;
    isInviteFlow: boolean;
    isPersonalizationWizardEnabled: boolean;
  }) => Promise<string>;
  staggeredRedirect: (windowLocation: WindowLocation, url: string) => Promise<void>;
  handleExplicitDestination: (props: {
    nextUrl: string;
    windowLocation: WindowLocation;
    queryParams: Search;
  }) => Promise<unknown>;
}

const getPersonalizationWizardUrl = (centralUrl: string, groupId: string, encodedRegistrationData: string): string =>
  `${centralUrl}/v2/${groupId}?registrationData=${encodedRegistrationData}#/${Routes.PersonalizationWizard()}`;

// We'll add all helper functions to an object, to make it easier to stub 'em in unit tests:
const registrationUtils: RegistrationUtils = {
  async initAndRedirectUser({
    username,
    parsedSearchLocation,
    baasCentralUrl,
    centralUrl,
    windowLocation,
    isRealmRegistration,
    isPersonalizationWizardEnabled,
  }) {
    const { company, inviteToken, couponCode, activationCode, signupSource, signupMethod } = parsedSearchLocation;

    const queryParams = { ...parsedSearchLocation };

    // If a `company` query param was relayed, we assume this user does not yet have a registered company / project.
    if (company) {
      // Gather acquired group and org IDs:
      const orgAndProjectData = await registrationUtils.createInitialOrgAndProject({
        company,
        centralUrl,
        discountCode: couponCode ?? activationCode,
        signupSource,
        signupMethod,
      });

      queryParams.orgId = orgAndProjectData.orgId;
      queryParams.groupId = orgAndProjectData.groupId;
    }

    if (isRealmRegistration) {
      // Ensure the user is authenticated with Realm before redirecting them there in the next step
      await authenticateWithStitch(username, baasCentralUrl, centralUrl);
    }

    // Compose redirect URL
    const nextUrl = await registrationUtils.getRedirectUrl({
      centralUrl,
      parsedSearchLocation: queryParams,
      isInviteFlow: !!inviteToken,
      isPersonalizationWizardEnabled,
    });

    await sendAnalyticsEvents(queryParams, nextUrl);

    // Redirect the page
    return registrationUtils.staggeredRedirect(windowLocation, nextUrl);
  },

  async createInitialOrgAndProject({ company, centralUrl, discountCode, signupSource, signupMethod }) {
    let groupId = '';
    let orgId = '';

    try {
      // this is required so that the call to createInitialOrgAndProject doesn't fail with "forbidden" error
      // Makes sure that whitespaces encoded as + signs are treated as spaces
      const decodedCompany = (company as string)?.replace(/\+/g, ' ');

      ({ groupId, orgId } = await postRegistrationHelpers.createInitialOrgAndProject({
        orgName: decodedCompany,
        isNDS: true,
        centralUrl,
        signupSource: signupSource ? signupSource.replace(/\+/g, ' ') : undefined,
        signupMethod: signupMethod ? signupMethod.replace(/\+/g, ' ') : undefined,
      }));
    } catch (error) {
      sendError({ error, team: CloudTeams.AtlasGrowth });
    }

    try {
      // Apply the coupon code to the newly created org
      if (discountCode) {
        await api.billing.applyCouponOrActivationCode({
          orgId,
          couponCode: discountCode,
          centralUrl,
        });
      }
    } catch (error) {
      sendError({ error, team: CloudTeams.AtlasGrowth });
    }

    // All org and project creation should now be done on the BE. Adding this event to confirm no users are hitting
    // this code path. If we detect no activity here, this method's removal can commence in CLOUDP-104880
    analytics.track(SEGMENT_EVENTS.DEPRECATED_CODE_PATH, {
      context: 'Registration Success Path',
      action: 'Creating initial org and project',
      company,
      signup_method: signupMethod,
      signup_source: signupSource,
    });

    return {
      groupId,
      orgId,
    };
  },

  async getRedirectUrl({ centralUrl, parsedSearchLocation, isInviteFlow, isPersonalizationWizardEnabled }) {
    const { nRegister } = parsedSearchLocation;
    const registrationData: RegistrationData = {
      ...parsedSearchLocation,
      isInviteFlow,
    };

    if (nRegister) {
      // Exclude nRegister from the new query string
      const { nRegister, ...newQueryString } = parsedSearchLocation;
      return `${centralUrl}${nRegister}?${queryString.stringify(newQueryString)}`;
    }

    await abTestUtil.setAndGetAssignmentsByPoint({
      allocationPoint: AllocationPoint.ATLAS_REGISTRATION_SUCCEEDED,
      centralUrl,
      groupId: parsedSearchLocation.groupId,
    });

    await abTestUtil.setAndGetAssignments({
      testNames: [TestName.surfaceUserGoals, TestName.universalAddDataExperiment],
      centralUrl,
      groupId: parsedSearchLocation.groupId,
    });

    const encodedRegistrationData = encodeURIComponent(JSON.stringify(registrationData));
    if (parsedSearchLocation.groupId && parsedSearchLocation.orgId && isPersonalizationWizardEnabled) {
      return getPersonalizationWizardUrl(centralUrl, parsedSearchLocation.groupId, encodedRegistrationData);
    }
    return `${centralUrl}/?registrationData=${encodedRegistrationData}`;
  },

  staggeredRedirect(windowLocation, url) {
    return promisifiedTimeout(() => windowLocation.replace(url), animationTime);
  },

  async handleExplicitDestination({ nextUrl, windowLocation, queryParams }) {
    const { tracking, groupId } = queryParams;

    if (tracking) {
      await sendAnalyticsEvents(queryParams, nextUrl);
    } else {
      // Send pageview before rerouting:
      const sanitizedLocation = sanitizeLocation();
      analytics.page(PAGE_CALLS.REGISTRATION_SUCCESS, {
        path: sanitizedLocation,
      });
    }

    // If the referring page left a slot for a groupId in the fromURI, insert it here.
    let finalUrl: string;
    if (groupId) {
      finalUrl = nextUrl.replace(GROUP_ID_TOKEN, groupId);
    } else {
      finalUrl = nextUrl;
    }

    // Send them on their way:
    return registrationUtils.staggeredRedirect(windowLocation, finalUrl);
  },
};

const GROUP_ID_TOKEN = '{groupId}';

export { registrationUtils, animationTime, Container, GROUP_ID_TOKEN };
export default RegistrationSuccessPage;
