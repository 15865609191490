import { useEffect, useState } from 'react';

import { css } from '@emotion/react';
import Banner from '@leafygreen-ui/banner';
import Card from '@leafygreen-ui/card';

import { FactorType, OktaMfaFactor } from '@packages/types/accountMultiFactorAuth';

import VerifyPasscodeInput, {
  VerifyPasscodeInputResponse,
} from '@packages/components/MultiFactorAuth/VerifyPasscodeInput';

import * as api from 'js/common/services/api';
import { getErrorMessageFromCode } from 'js/common/services/authErrorHelper';
import { bannerStyles, baseStyle, setupStyles, Step } from 'js/common/styles/multiFactorAuth';
import { isLastAuthExpired, redactEmail } from 'js/common/utils/multiFactorAuthUtils';

const errorBannerStyles = css(bannerStyles, {
  marginBottom: '24px',
});

export default function EmailSetupCard({
  windowLocation = window.location,
  onSuccess,
  authExpiredPath,
  username,
}: {
  windowLocation?: Pick<Location, 'assign'>;
  onSuccess: (response: VerifyPasscodeInputResponse) => void;
  authExpiredPath: string;
  username: string;
}) {
  const [factorActivationInfo, setFactorActivationInfo] = useState<OktaMfaFactor>();
  const [showSendAgain, setShowSendAgain] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const enrollEmailFactor = async () => {
    try {
      const response = await api.accountMultiFactorAuth.enrollFactor({ factorType: FactorType.Email });
      setFactorActivationInfo(response);
      setTimeout(() => {
        setShowSendAgain(true);
      }, 30000);
    } catch ({ errorCode, errorMessage: enrollErrorMessage }) {
      if (isLastAuthExpired(errorCode)) {
        windowLocation.assign(authExpiredPath);
      } else if (errorCode === 'RATE_LIMITED') {
        setErrorMessage(getErrorMessageFromCode(errorCode));
      } else {
        setErrorMessage(enrollErrorMessage);
      }
    }
  };

  useEffect(() => {
    if (username !== '') {
      enrollEmailFactor();
    }
  }, [username]);

  const resendEmail = () => {
    setShowSendAgain(false);
    enrollEmailFactor();
  };

  return (
    <Card css={setupStyles.card}>
      <Step.Container>
        <Step.Header>Step One</Step.Header>
        <div>
          A verification code was sent to <b>{redactEmail(username)}.</b>
        </div>
        {showSendAgain && (
          <div
            css={baseStyle.clickableLink}
            data-testid="resend-email"
            onClick={resendEmail}
            role="button"
            tabIndex={0}
          >
            Send again
          </div>
        )}
      </Step.Container>
      <Step.Container>
        <Step.Header>Step Two</Step.Header>
        <p>Check your email and enter the code below.</p>
        {errorMessage && (
          <Banner variant="danger" data-testid="error-banner" css={errorBannerStyles}>
            {errorMessage}
          </Banner>
        )}
        <VerifyPasscodeInput inputType="code" isActivation factor={factorActivationInfo} onSuccess={onSuccess} />
      </Step.Container>
    </Card>
  );
}
