import { Component, FormEvent } from 'react';

import Button from '@leafygreen-ui/button';
import classNames from 'classnames';
import { connect } from 'react-redux';
import _ from 'underscore';

import { MultiFactorAuth as MultiFactorAuthType } from '@packages/types/multiFactorAuth';
import { WithDefaultProps } from '@packages/types/withDefaultProps';

import * as viewer from '@packages/redux/modules/viewer';

import * as api from 'js/common/services/api';

interface OwnProps {
  onSuccess?: () => void;
  fetchMultiFactorStatus?: typeof api.user.multiFactorAuthGet;
  sendCode?: typeof api.user.multiFactorSendCode;
  checkCode?: typeof api.user.multiFactorCheckCode;
  forceBackupText?: typeof api.user.authForceBackupText;
  forceBackupCall?: typeof api.user.authForceBackupCall;
  username: string;
}

interface State {
  multiFactorData: MultiFactorAuthType | null;
  showSpinner: boolean;
  invalidAuthCode: boolean;
  authCode: string;
  hasSentBackupCode: boolean;
  errorSendingBackupCode: boolean;
  isBackupCodeButtonDisabled: boolean;
}

const defaultProps = {
  onSuccess: _.noop,
  fetchMultiFactorStatus: api.user.multiFactorAuthGet,
  sendCode: api.user.multiFactorSendCode,
  checkCode: api.user.multiFactorCheckCode,
  forceBackupText: api.user.authForceBackupText,
  forceBackupCall: api.user.authForceBackupCall,
};

type Props = WithDefaultProps<OwnProps, typeof defaultProps>;

class MultiFactorAuth extends Component<Props, State> {
  static defaultProps = defaultProps;

  state: State = {
    multiFactorData: null,
    showSpinner: false,
    invalidAuthCode: false,
    authCode: '',
    hasSentBackupCode: false,
    errorSendingBackupCode: false,
    isBackupCodeButtonDisabled: false,
  };

  async componentDidMount() {
    const { fetchMultiFactorStatus, sendCode } = this.props;
    const multiFactorData = await fetchMultiFactorStatus();
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({ multiFactorData });
    sendCode();
  }

  onSubmit = async (e: FormEvent) => {
    const { checkCode, onSuccess } = this.props;
    const { authCode } = this.state;
    e.preventDefault();

    this.setState({
      showSpinner: true,
      invalidAuthCode: false,
    });

    try {
      await checkCode({ authCode });
      this.setState({
        showSpinner: false,
      });
      onSuccess();
    } catch ({ errorCode }) {
      this.setState({
        showSpinner: false,
        invalidAuthCode: errorCode === 'MULTI_FACTOR_AUTH_CHECK_FAILED',
      });
    }
  };

  redactPhone = (phone: string) => {
    return phone.replace(/\d(?=\d{4})/g, '*');
  };

  sendBackupCode = (sendMethod: (options: { username: string }) => Promise<void>) => async () => {
    const { username } = this.props;
    this.setState({ isBackupCodeButtonDisabled: true });
    try {
      await sendMethod({ username });
      this.setState({ hasSentBackupCode: true, errorSendingBackupCode: false, isBackupCodeButtonDisabled: false });
    } catch ({ errorCode }) {
      this.setState({ hasSentBackupCode: false, errorSendingBackupCode: true, isBackupCodeButtonDisabled: false });
    }
  };

  render() {
    const { forceBackupText, forceBackupCall } = this.props;
    const {
      showSpinner,
      authCode,
      multiFactorData,
      invalidAuthCode,
      hasSentBackupCode,
      errorSendingBackupCode,
      isBackupCodeButtonDisabled,
    } = this.state;

    if (!multiFactorData) {
      return null;
    }

    const { authenticator, voice, phone, backupPhone } = multiFactorData;

    let text;
    if (authenticator) {
      text = 'Please enter the code from your Google authenticator app or your recovery code.';
    } else if (voice) {
      text = `We sent a voice call to: ${phone}`;
    } else {
      text = `We sent an SMS with your code to: ${phone}`;
    }

    return (
      <form className="auth-expired" onSubmit={this.onSubmit}>
        <div className="auth-expired-row">
          <div className="auth-expired-description">
            <p className="text-has-small-width text-has-margin-right">
              <b>2FA Verification required to continue.</b>
              <br />
              {text}
            </p>
          </div>
          <div className="auth-expired-actions">
            <div
              className={classNames('auth-expired-input-container form-group has-feedback auth-code-container', {
                'has-error': invalidAuthCode,
              })}
            >
              <input
                type="text"
                className="form-control"
                name="authCode"
                placeholder="Enter code"
                value={authCode}
                onChange={(e) => this.setState({ authCode: e.target.value })}
              />
              {invalidAuthCode && (
                <span className="form-control-feedback auth-code-feedback">
                  <i className="fa fa-times" />
                </span>
              )}
            </div>{' '}
            <div className="form-group">
              {showSpinner ? (
                <span className="auth-expired-spinner loading-spinner" />
              ) : (
                <button
                  onClick={this.onSubmit}
                  type="button"
                  className="button button-is-primary"
                  name="verifyAuthCode"
                >
                  Verify
                </button>
              )}
            </div>
          </div>
        </div>
        {backupPhone && (
          <div className="auth-expired-row auth-expired-row-is-aligned-right">
            {errorSendingBackupCode && !isBackupCodeButtonDisabled && (
              <span className="auth-expired-text auth-expired-text-has-margin-right auth-expired-text-is-red">
                <i className="mms-icon-remove" /> Code failed to be sent.
              </span>
            )}
            {hasSentBackupCode && !errorSendingBackupCode && !isBackupCodeButtonDisabled && (
              <span className="auth-expired-text auth-expired-text-has-margin-right auth-expired-text-is-green">
                <i className="mms-icon-check" /> Code has been sent.
              </span>
            )}
            <span className="auth-expired-text-is-small">
              Lost access? Send a code to <strong>{this.redactPhone(backupPhone)}</strong>:
            </span>
            <Button
              className="auth-expired-button auth-expired-button-has-margin-right"
              size="xsmall"
              type="button"
              name="sendSms"
              onClick={this.sendBackupCode(forceBackupText)}
              disabled={isBackupCodeButtonDisabled}
            >
              SMS
            </Button>
            <span className="auth-expired-text-is-small"> or </span>
            <Button
              className="auth-expired-button"
              size="xsmall"
              type="button"
              name="sendVoice"
              onClick={this.sendBackupCode(forceBackupCall)}
              disabled={isBackupCodeButtonDisabled}
            >
              Voice Call
            </Button>
          </div>
        )}
      </form>
    );
  }
}

export { MultiFactorAuth };

export const MultiFactorAuthConnected = connect((state) => ({
  username: viewer.getUsername(state),
}))(MultiFactorAuth);
