import { Dispatch } from 'redux';

// Other Selectors
import * as viewer from '@packages/redux/modules/viewer';

import * as api from 'js/common/services/api';

// Actions Types

const SET_GROUPS = 'allClusters/SET_GROUPS';
const IS_LOADING = 'allClusters/IS_LOADING';
const IS_ERROR = 'allClusters/IS_ERROR';

const initialState = {
  groups: {},
};

// Reducer

export default function clustersReducer(state = initialState, action) {
  switch (action.type) {
    case SET_GROUPS: {
      const newState = { ...state };
      newState.groups = {
        ...state.groups,
        [action.payload.userId]: action.payload.groups,
      };
      (newState as any).isError = false;
      (newState as any).isLoading = false;

      return newState;
    }
    case IS_ERROR: {
      const newState = { ...state };

      (newState as any).isError = true;
      (newState as any).isLoading = false;

      return newState;
    }
    case IS_LOADING: {
      const newState = { ...state };

      (newState as any).isError = false;
      (newState as any).isLoading = true;

      return newState;
    }
    default:
      return state;
  }
}

// Selectors

const getClustersData = (state) => state.allClusters;

function sortDeadClusters(group) {
  const sortedGroup = Object.assign({}, group, {
    // @ts-expect-error ts-migrate(2362) FIXME: The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
    clusters: group.clusters.slice().sort((a, b) => (a.availability === 'dead') - (b.availability === 'dead')),
  });

  return sortedGroup;
}
// Determines what Id is used lookup groups: alias userId if provided or current viewer Id otherwise.
function getLookupId(userId, state) {
  return userId || viewer.getId(state);
}

export const isLoading = (state) => getClustersData(state).isLoading;
export const isError = (state) => getClustersData(state).isError;
export const getGroups = (state, userId) => getClustersData(state).groups[userId] || [];

export const getSortedGroupsForUser = function getSortedGroups(state, userId) {
  const lookupId = getLookupId(userId, state);
  return getGroups(state, lookupId).map(sortDeadClusters);
};

export const getClusterCountForUser = function getClusterCount(state, userId) {
  const lookupId = getLookupId(userId, state);
  return getGroups(state, lookupId).reduce((clusterCount, group) => clusterCount + group.clusters.length, 0);
};

export const getVersionNumbersForUser = function getVersionNumbers(state, userId) {
  const lookupId = getLookupId(userId, state);
  const versions = getGroups(state, lookupId).reduce((groupAcc, group) => {
    group.clusters.forEach((cluster) => {
      cluster.versions.forEach((version) => {
        groupAcc[version] = true;
      });
    });

    return groupAcc;
  }, {});

  return Object.keys(versions);
};

// Action Creators

export const setGroups = ({ groups, userId }) => ({
  type: SET_GROUPS,
  payload: { groups, userId },
});

export const setLoading = () => ({
  type: IS_LOADING,
});

export const setError = () => ({
  type: IS_ERROR,
});

/**
 * Loads the groups for a provided userId.
 *
 * @param userId the userId for which to load groups for
 * @param isAlias a boolean indicating whether or not the caller is loading groups for a user that is not themselves.
 * NOTE - As of 10/20/2021, a call made with isAlias set to TRUE requires that the logged in user be part of the
 * GLOBAL_MONITORING_ADMIN roleset, see https://tinyurl.com/n9ekdwkv.
 *
 * @returns Promise<void>
 */
export const loadGroups =
  (userId: string, isAlias: boolean) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(setLoading());
    const getGroupsFunction = (isAlias: boolean) => {
      if (!isAlias) {
        // get groups for the currently logged in user
        return api.allClusters.getGroups();
      }
      return api.allClusters.getGroupsForUser(userId);
    };

    return getGroupsFunction(isAlias)
      .then((response) => {
        dispatch(
          setGroups({
            groups: response,
            userId,
          })
        );
      })
      .catch(() => {
        dispatch(setError());
      });
  };
