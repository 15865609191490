import { SimpleApiResponse } from '@packages/types/api';
import {
  ConnectedOrgConfig,
  Domain,
  DomainValidationError,
  FederatedUser,
  FederationSettings,
  IdentityProvider,
  IdpRedirect,
  LiveIdentityProvider,
  RoleMapping,
} from '@packages/types/federation';

import fetchWrapper from './fetchWrapper';

type queryComponent = string | boolean | number;

export function getIdentityProviderRedirect({
  username,
  clientState,
}: {
  username: string;
  clientState: object;
}): Promise<IdpRedirect> {
  return fetchWrapper(`/federation/discovery/idps`, {
    method: 'POST',
    body: JSON.stringify({
      username,
      clientState,
    }),
  }).then((response) => response.json());
}

export function getFederationSettings({ federationSettingsId }): Promise<FederationSettings> {
  return fetchWrapper(`/federationSettings/${federationSettingsId}`, { method: 'GET' }).then((response) =>
    response.json()
  );
}

export function createFederationSettings({
  federationSettings,
}: {
  federationSettings: { connectedOrgConfigs: [{ orgId: string }] };
}): Promise<FederationSettings> {
  return fetchWrapper('/federationSettings', {
    method: 'POST',
    body: JSON.stringify(federationSettings),
  }).then((response) => response.json());
}

export function deleteFederationSettings({ federationSettingsId }: { federationSettingsId: string }) {
  return fetchWrapper(`/federationSettings/${federationSettingsId}`, { method: 'DELETE' });
}

export function setUserOrgRestrictions({
  federationSettingsId,
  restrictOrgMembershipEnabled,
}: {
  federationSettingsId: string;
  restrictOrgMembershipEnabled: boolean;
}): Promise<FederationSettings> {
  return fetchWrapper(`/federationSettings/${federationSettingsId}/restrictOrgMembership`, {
    method: 'PUT',
    body: JSON.stringify({ restrictOrgMembershipEnabled }),
  }).then((response) => response.json());
}

export function addIdentityProvider({
  federationSettingsId,
  identityProvider,
}: {
  federationSettingsId: string;
  identityProvider: IdentityProvider;
}): Promise<IdentityProvider> {
  return fetchWrapper(`/federationSettings/${federationSettingsId}/identityProviders`, {
    method: 'POST',
    body: JSON.stringify(identityProvider),
  }).then((response) => response.json());
}

export function updateIdentityProvider({
  federationSettingsId,
  identityProvider,
}: {
  federationSettingsId: string;
  identityProvider: LiveIdentityProvider;
}): Promise<IdentityProvider> {
  return fetchWrapper(`/federationSettings/${federationSettingsId}/identityProviders/${identityProvider.id}`, {
    method: 'PATCH',
    body: JSON.stringify(identityProvider),
  }).then((response) => response.json());
}

export function setIdentityProviderAssociatedDomains({
  federationSettingsId,
  idpId,
  domains,
}: {
  federationSettingsId: string;
  idpId: string;
  domains: Array<string>;
}): Promise<IdentityProvider | SimpleApiResponse> {
  return fetchWrapper(`/federationSettings/${federationSettingsId}/identityProviders/${idpId}/associatedDomains`, {
    method: 'PUT',
    body: JSON.stringify(domains),
  }).then((response) => response.json());
}

export function deleteIdentityProvider({
  federationSettingsId,
  idpId,
}: {
  federationSettingsId: string;
  idpId: string;
}) {
  return fetchWrapper(`/federationSettings/${federationSettingsId}/identityProviders/${idpId}`, {
    method: 'DELETE',
  });
}

export function activateIdentityProvider({
  federationSettingsId,
  oktaIdpId,
}: {
  federationSettingsId: string;
  oktaIdpId: string;
}) {
  return fetchWrapper(`/federationSettings/${federationSettingsId}/identityProviders/${oktaIdpId}/activate`, {
    method: 'POST',
  });
}

export function deactivateIdentityProvider({
  federationSettingsId,
  oktaIdpId,
}: {
  federationSettingsId: string;
  oktaIdpId: string;
}) {
  return fetchWrapper(`/federationSettings/${federationSettingsId}/identityProviders/${oktaIdpId}/deactivate`, {
    method: 'POST',
  });
}

export type DomainResponse = Promise<Required<Domain> | (SimpleApiResponse & DomainValidationError)>;

export function addDomain({
  federationSettingsId,
  domain,
}: {
  federationSettingsId: string;
  domain: Domain;
}): DomainResponse {
  return fetchWrapper(`/federationSettings/${federationSettingsId}/domains`, {
    method: 'POST',
    body: JSON.stringify(domain),
  }).then((response) => response.json());
}

export function attemptVerification({
  federationSettingsId,
  domainId,
}: {
  federationSettingsId: string;
  domainId: string;
}): Promise<Domain | SimpleApiResponse> {
  return fetchWrapper(`/federationSettings/${federationSettingsId}/domains/${domainId}`, {
    method: 'PATCH',
    body: JSON.stringify({
      op: 'VERIFY',
    }),
  }).then((response) => response.json());
}

export function deleteConnectedOrgConfig({
  federationSettingsId,
  orgId,
}: {
  federationSettingsId: string;
  orgId: string;
}) {
  return fetchWrapper(`/federationSettings/${federationSettingsId}/connectedOrgConfigs/${orgId}`, {
    method: 'DELETE',
  });
}

export function upsertConnectedOrgConfig({
  federationSettingsId,
  orgId,
  connectedOrgConfig,
}: {
  federationSettingsId: string;
  orgId: string;
  connectedOrgConfig: ConnectedOrgConfig;
}): Promise<ConnectedOrgConfig> {
  return fetchWrapper(`/federationSettings/${federationSettingsId}/connectedOrgConfigs/${orgId}`, {
    method: 'PUT',
    body: JSON.stringify(connectedOrgConfig),
  }).then((response) => response.json());
}

export function addRoleMapping({
  federationSettingsId,
  orgId,
  roleMapping,
}: {
  federationSettingsId: string;
  orgId: string;
  roleMapping: Omit<RoleMapping, 'id'>;
}): Promise<RoleMapping> {
  return fetchWrapper(`/federationSettings/${federationSettingsId}/connectedOrgConfigs/${orgId}/roleMappings`, {
    method: 'POST',
    body: JSON.stringify(roleMapping),
  }).then((response) => response.json());
}

export function updateRoleMapping({
  federationSettingsId,
  orgId,
  roleMapping,
}: {
  federationSettingsId: string;
  orgId: string;
  roleMapping: RoleMapping;
}): Promise<RoleMapping> {
  return fetchWrapper(
    `/federationSettings/${federationSettingsId}/connectedOrgConfigs/${orgId}/roleMappings/${roleMapping.id}`,
    {
      method: 'PUT',
      body: JSON.stringify(roleMapping),
    }
  ).then((response) => response.json());
}

export function deleteRoleMapping({
  federationSettingsId,
  orgId,
  roleMappingId,
}: {
  federationSettingsId: string;
  orgId: string;
  roleMappingId: string;
}): Promise<Response> {
  return fetchWrapper(
    `/federationSettings/${federationSettingsId}/connectedOrgConfigs/${orgId}/roleMappings/${roleMappingId}`,
    { method: 'DELETE' }
  );
}

export function deleteDomain({ federationSettingsId, domainId }: { federationSettingsId: string; domainId: string }) {
  return fetchWrapper(`/federationSettings/${federationSettingsId}/domains/${domainId}`, {
    method: 'DELETE',
  });
}

export function getFederatedUsers({
  federationSettingsId,
  limit = 100,
  after,
  filter,
}: {
  federationSettingsId: string;
  limit?: number;
  after?: string;
  filter?: string;
}): Promise<{ previous?: string; next?: string; users: Array<FederatedUser> }> {
  const query = Object.entries({ limit, after, filter })
    .filter(([, value]) => value !== undefined)
    .map(([key, value]) => `${key}=${encodeURIComponent(value as queryComponent)}`)
    .join('&');
  return fetchWrapper(`/federationSettings/${federationSettingsId}/users?${query}`).then((response) => response.json());
}
