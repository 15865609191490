import * as React from 'react';

import Banner, { Variant as BannerVariant } from '@leafygreen-ui/banner';
import Card from '@leafygreen-ui/card';
import { H2 } from '@leafygreen-ui/typography';

import AutoAdvanceInputs from '@packages/components/AutoAdvanceInputs';

import { header } from './styles/form';

function pastedValueFormatting(pasteData: string) {
  return pasteData
    .trim()
    .split('')
    .filter((v) => v !== '-');
}

interface DeviceAuthInputProps {
  title: string;
  firstName: string;
  lastName: string;
  username: string;
  onComplete: (string) => void;
  autoAdvanceInputsRef: React.RefObject<AutoAdvanceInputs>;
  error?: unknown;
}

export function DeviceAuthInput({
  title,
  firstName,
  lastName,
  username,
  onComplete,
  error,
  autoAdvanceInputsRef,
}: DeviceAuthInputProps) {
  if (!username) {
    return null;
  }

  const isMongoDBEmployee = username.includes('@mongodb.com');
  return (
    <>
      <H2 css={header}>{title}</H2>
      {isMongoDBEmployee && (
        <Banner variant={BannerVariant.Info} css={{ marginBottom: 16, maxWidth: 520 }}>
          <strong>If you're a MongoDB employee, please ensure you are on the VPN.</strong>
        </Banner>
      )}
      <Card css={{ minHeight: 200, maxWidth: 500 }}>
        <p css={{ fontSize: 13, fontWeight: 400 }}>
          Hi, {firstName} {lastName} ({username})!
          <br />
          <strong>Paste or enter the code that was given to you through the CLI.</strong>
        </p>
        <AutoAdvanceInputs
          inputsType="deviceCode"
          onComplete={onComplete}
          pastedValueFormatting={pastedValueFormatting}
          ref={autoAdvanceInputsRef}
        />
        {error && (
          <Banner variant="danger" css={{ marginTop: 16 }}>
            <strong>Code entered is invalid or expired</strong>
            <br />
            Please return to the CLI to get a new verification code.
          </Banner>
        )}
      </Card>
    </>
  );
}
