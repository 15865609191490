import { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import * as app from '@packages/redux/modules/app';
import * as settings from '@packages/redux/modules/settings';
import * as viewer from '@packages/redux/modules/viewer';

export const useVercelQueryPropsForDataApi = ({
  groupId,
  orgId,
  username,
  baasUrl,
}: {
  groupId: string | undefined;
  orgId: string | undefined;
  username: string | undefined;
  baasUrl: string | undefined;
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (groupId) dispatch(app.setActiveGroupId(groupId));
    if (orgId) dispatch(app.setActiveOrgId(orgId));
    if (username) dispatch(viewer.setUsername(username));
    if (baasUrl) dispatch(settings.setBaasCentralUrl(baasUrl));
  }, [dispatch, groupId, orgId, username, baasUrl]);
};
