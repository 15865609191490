import fetchWrapper from '../fetchWrapper';

export interface Response {
  likelyRegionalOutage: boolean;
}

export default {
  isClusterInMajorityRegionalOutage(groupId: string, clusterName: string): Promise<Response> {
    return fetchWrapper(`/nds/regionalOutage/${groupId}/${clusterName}/likely-majority-regional-outage`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },
};
