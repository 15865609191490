import Backbone from 'backbone';

import StitchApplication from './StitchApplication';

export interface StitchApplicationCollectionInterface extends Backbone.Collection {
  getApplicationsByCluster(clusterName: string): StitchApplicationCollectionInterface;
}

const StitchApplicationCollection = Backbone.Collection.extend({
  model: StitchApplication,

  getApplicationsByCluster(clusterName) {
    return new StitchApplicationCollection(
      this.filter((app) => app.getClusterNames().some((appCluster) => appCluster === clusterName))
    );
  },
});

export default StitchApplicationCollection;
