import * as app from '@packages/redux/modules/app';

import * as api from 'js/common/services/api';

const initialState = {};

const SET = 'controlledFeatures/SET';
const ERROR = 'controlledFeatures/ERROR';

export default function controlledFeaturesReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET: {
      return {
        ...state,
        policies: payload,
      };
    }
    case ERROR: {
      return {
        ...state,
        error: payload,
      };
    }
    default: {
      return state;
    }
  }
}

export const checkAllPoliciesPresent = (state, ...policiesToCheck) => {
  return policiesToCheck.every((val) => state.controlledFeatures.policies?.map((p) => p.policy)?.includes(val));
};

// selectors
export const isExternallyManagedPolicyActive = (state) => {
  return checkAllPoliciesPresent(state, 'EXTERNALLY_MANAGED_LOCK');
};

export const isDisableUserManagementPolicyActive = (state) => {
  return checkAllPoliciesPresent(state, 'DISABLE_USER_MANAGEMENT');
};

export const isDisableAuthenticationMechanismsPolicyActive = (state) => {
  return checkAllPoliciesPresent(state, 'DISABLE_AUTHENTICATION_MECHANISMS');
};

export const isDisableSetMongodConfigPolicyActive = (state) => {
  return checkAllPoliciesPresent(state, 'DISABLE_SET_MONGOD_CONFIG');
};

export const isDisableMongodVersionPolicyActive = (state) => {
  return checkAllPoliciesPresent(state, 'DISABLE_SET_MONGOD_VERSION');
};

export const isDisabledBackupPolicyActive = (state) => {
  return checkAllPoliciesPresent(state, 'DISABLE_BACKUP_AGENT');
};

export const isDisabledImportAutomationActive = (state) => {
  return checkAllPoliciesPresent(state, 'DISABLE_IMPORT_TO_AUTOMATION');
};

export const isDisabledMongodLogManagementActive = (state) => {
  return checkAllPoliciesPresent(state, 'DISABLE_MONGOD_LOG_MANAGEMENT');
};

export const isDisabledMongodHostManagementActive = (state) => {
  return checkAllPoliciesPresent(state, 'DISABLE_MONGOD_HOST_MANAGEMENT');
};

export const isDisabledApiKeyManagementActive = (state) => {
  return checkAllPoliciesPresent(state, 'DISABLE_AGENT_API_KEY_MANAGEMENT');
};

export const isAutomationManagementDisabled = (state) => {
  return checkAllPoliciesPresent(state, 'EXTERNALLY_MANAGED_LOCK', 'DISABLE_IMPORT_TO_AUTOMATION');
};

export const isContinuousBackupDisabled = (state) => {
  return checkAllPoliciesPresent(state, 'DISABLE_CONTINUOUS_BACKUP');
};

export const isDeleteProjectDisabled = (state) => {
  return checkAllPoliciesPresent(state, 'DISABLE_DELETE_PROJECT');
};

// actions
export const loadControlledFeaturesForActiveGroup = () => (dispatch, getState) => {
  const groupId = app.getActiveGroupId(getState());

  return api.controlledFeatures
    .getControlledFeaturesForGroup(groupId)
    .then((response) => {
      return dispatch({
        type: SET,
        payload: response.policies,
      });
    })
    .catch((failure) => {
      return dispatch({
        type: ERROR,
        payload: failure,
      });
    });
};
