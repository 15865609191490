import { useEffect, useRef } from 'react';

import { useRequestParams } from 'js/common/context/RequestParamsContext';

import { SignupSource } from 'js/auth/utils/analyticsUtils';
import { triggerQuantcastTrackingPixel } from 'js/auth/utils/quantcastTrackingPixel';

export function useQuantcastTrackingPixel(signupSource?: SignupSource | string) {
  const { analyticsEnabled } = useRequestParams();
  const prevAnalytics = useRef<boolean>(false);

  const isAtlasRegistration = signupSource === SignupSource.ACCOUNT;

  useEffect(() => {
    if (analyticsEnabled && !prevAnalytics.current && isAtlasRegistration) {
      triggerQuantcastTrackingPixel();
      prevAnalytics.current = true;
    }
  }, [analyticsEnabled]);
}
