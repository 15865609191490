export enum CloudTeams {
  'AppServices' = 'App Services',
  'AtlasI' = 'Atlas I',
  'AtlasIi' = 'Atlas II',
  'AtlasIii' = 'Atlas III',
  'AtlasGrowth' = 'Atlas Growth',
  'AtlasInternTeam' = 'Atlas Intern Team',
  'AtlasMigration' = 'Atlas Migration',
  'AtlasTriage' = 'Atlas Triage',
  'AtlasExtendedI' = 'Atlas Extended I',
  'AtlasExtendedII' = 'Atlas Extended II',
  'AtlasXV' = 'Atlas XV',
  'Automation' = 'Automation',
  'BackupAtlas' = 'Backup - Atlas',
  'BackupPrivateCloud' = 'Backup - Private Cloud',
  'CoreBillingAndIntegrations' = 'Core Billing and Integrations',
  'CoreIam' = 'Core IAM',
  'Design' = 'Design',
  'DeveloperProductivity' = 'Developer Productivity',
  'EngineeringManagement' = 'Engineering Management',
  'FrontEndPlatform' = 'Front End Platform',
  'InsightsAndTelemetry' = 'Insights and Telemetry',
  'OpsManager' = 'Ops Manager',
  'Kubernetes' = 'Kubernetes',
  'Payments' = 'Payments',
  'ProgramManagement' = 'Program Management',
  'Search' = 'Search',
  'Security' = 'Security',
  'Sre' = 'SRE',
  'Triage' = 'Triage',
}

export interface BugsnagMetaData {
  team?: CloudTeams;
  [key: string]: any;
}

export type BugsnagReport = unknown; // Report object docs, in case anyone wants to fill 'em in: https://docs.bugsnag.com/platforms/javascript/legacy/customizing-error-reports/#the-report-object

export enum BugsnagSeverity {
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
}

export interface BugsnagOptions {
  metaData?: BugsnagMetaData;
  severity?: BugsnagSeverity;
  user?: object;
  beforeSend?: (report: BugsnagReport) => void;
  context?: string;
  device?: object;
  request?: object;
}

export type BugsnagCallback = (err: Error, report: BugsnagReport) => void;
export interface BugsnagClient {
  notify: (error: Error, options?: BugsnagOptions, cb?: BugsnagCallback) => void;
  user?: {
    id: string;
    username: string;
  };
  metaData?: Record<string, unknown>;
}

export type ErrorMaybe =
  | Error
  | {
      error?: Error;
      requestUrl?: string;
      errorCode?: string;
    };

export interface UseBugsnagNotifySpecs {
  error: ErrorMaybe;
  team: CloudTeams;
  metaData?: BugsnagMetaData;
  options?: BugsnagOptions;
  severity?: BugsnagSeverity;
}
