import { ChartsGroupRole, ChartsOrganizationRole } from '@packages/types/chartsUserRoles';

export const getChartsOrganizationRole = ({
  isGlobalChartsAdmin,
  isOrgOwner,
  isOrgGroupCreator,
  isOrgBillingAdmin,
  isOrgReadOnly,
  isOrgMember,
}: {
  isGlobalChartsAdmin: boolean;
  isOrgOwner: boolean;
  isOrgGroupCreator: boolean;
  isOrgBillingAdmin: boolean;
  isOrgReadOnly: boolean;
  isOrgMember: boolean;
}): ChartsOrganizationRole => {
  if (isGlobalChartsAdmin) {
    return ChartsOrganizationRole.GLOBAL;
  } else if (isOrgOwner) {
    return ChartsOrganizationRole.ORG_OWNER;
  } else if (isOrgGroupCreator) {
    return ChartsOrganizationRole.ORG_GROUP_CREATOR;
  } else if (isOrgBillingAdmin) {
    return ChartsOrganizationRole.ORG_BILLING_ADMIN;
  } else if (isOrgReadOnly) {
    return ChartsOrganizationRole.ORG_READ_ONLY;
  } else if (isOrgMember) {
    return ChartsOrganizationRole.ORG_MEMBER;
  }
  return ChartsOrganizationRole.ORG_UNKNOWN;
};

export const getChartsGroupRole = ({
  isGlobalChartsAdmin,
  isGroupOwner,
  isDataAccessAny,
  isAnyGroupRole,
}: {
  isGlobalChartsAdmin: boolean;
  isGroupOwner: boolean;
  isDataAccessAny: boolean;
  isAnyGroupRole: boolean;
}): ChartsGroupRole => {
  if (isGlobalChartsAdmin) {
    return ChartsGroupRole.GLOBAL;
  } else if (isGroupOwner) {
    return ChartsGroupRole.CHARTS_PROJECT_OWNER;
  } else if (isDataAccessAny) {
    return ChartsGroupRole.CHARTS_DATA_ACCESS;
  } else if (isAnyGroupRole) {
    return ChartsGroupRole.CHARTS_PROJECT_MEMBER;
  }
  return ChartsGroupRole.CHARTS_UNKNOWN;
};
