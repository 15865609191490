import * as React from 'react';

import styled from '@emotion/styled';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import UIMessages from '@packages/components/UIMessages';

import { useRequestParams } from 'js/common/context/RequestParamsContext';
import { mq } from 'js/common/utils/mediaQueries';

import { LoginPromo, loginPromoAssets } from 'js/auth/components/LoginPromo';
import colors from 'js/auth/utils/palette';

export interface PlacementProps {
  placement: 'left' | 'right';
}

interface ContentPatternProps extends PlacementProps {
  hasLoginPromoImage?: boolean;
}

interface LayoutProps {
  contentPlacement: PlacementProps['placement'];
  children: React.ReactNode;
  isLoginPromo?: boolean;
}

const defaultLoginDesktopAsset = 'url(/static/images/auth/login_desktop.png)';
const defaultLoginMobileAsset = 'url(/static/images/auth/login_mobile.png)';

const defaultLoginAssets = [
  defaultLoginMobileAsset,
  defaultLoginMobileAsset,
  defaultLoginDesktopAsset,
  defaultLoginDesktopAsset,
];

// Theses dimensions are based on the designs for these page type
const largeAsideWidth = '385px';
const largeMainWidth = 'calc(100vw - 385px)';
const smallAsideWidth = '185px';
const smallMainWidth = 'calc(100vw - 185px)';

const Container = styled.div(() =>
  mq({
    width: '100vw',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: ['row', 'column-reverse', 'row', 'row'],
  })
);

const UIMessagesCustom = styled(UIMessages)((props: PlacementProps) =>
  mq({
    width:
      props.placement === 'left'
        ? ['100vw', '100vw', largeAsideWidth, largeAsideWidth]
        : ['100vw', '100vw', smallMainWidth, largeMainWidth],
    height: props.placement === 'left' ? [86, 75, 86, 86] : [50, 75, 86, 50],
    fontSize: '15px',
    letterSpacing: 0,
    lineHeight: '18px',
    boxSizing: 'border-box',
    borderTopStyle: 'solid',
    borderBottomStyle: 'solid',
    borderColor: colors.alertBannerBorder,
    borderRadius: '1px',
  })
);

const Content = styled.div((props: ContentPatternProps) =>
  mq({
    width:
      props.placement === 'left'
        ? ['100vw', '100vw', largeAsideWidth, largeAsideWidth]
        : ['100vw', '100vw', smallMainWidth, largeMainWidth],
    height: ['auto', 'calc(100vh - 60px)', 'auto', 'auto'],
    order: [1, 1, 2, 2],
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: props.placement === 'left' ? 0 : [0, 0, smallAsideWidth, largeAsideWidth],
  })
);

const Pattern = styled.div((props: ContentPatternProps) =>
  mq({
    width:
      props.placement === 'left'
        ? ['100vw', '100vw', smallAsideWidth, largeAsideWidth]
        : ['100vw', '100vw', largeMainWidth, largeMainWidth],
    height: props.placement === 'left' ? ['auto', '60px', '100vh', '100vh'] : ['auto', '60px', '100%', '100%'],
    order: props.placement === 'left' ? [1, 2, 1, 1] : [1, 2, 2, 2],
    backgroundImage: props.hasLoginPromoImage ? loginPromoAssets : defaultLoginAssets,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: ['inherited', 'flex', 'block', 'block'],
    justifyContent: 'center',
    alignItems: ['inherit', 'center', 'inherit', 'inherit'],
    position:
      props.placement === 'left'
        ? ['inherit', 'inherit', 'fixed', 'fixed']
        : ['inherit', 'inherit', 'absolute', 'absolute'],
    left: props.placement === 'left' ? 0 : ['inherit', 'inherit', largeAsideWidth, largeAsideWidth],
  })
);

const OnPremFooterContainer = styled.footer((props: PlacementProps) =>
  mq({
    display: 'flex',
    justifyContent: 'space-between',
    borderTop: `1px solid ${colors.footerBorder}`,
    paddingBottom: '20px',
    flexShrink: 0,
    width:
      props.placement === 'left'
        ? ['100vw', '100vw', largeAsideWidth, largeAsideWidth]
        : ['100vw', '100vw', smallMainWidth, largeMainWidth],

    '& div, a': {
      marginTop: '20px',
      lineHeight: '20px',
      fontSize: '13px',
    },
  })
);

const CopyRightText = styled.div({
  color: colors.copyRightText,
  marginRight: '20px',
});

const HelpAndSupportLink = styled.a({
  textDecoration: 'none',
  color: colors.link,
  marginLeft: '20px',
});

export default function Layout({ contentPlacement, children, isLoginPromo = false }: LayoutProps) {
  const { onPrem, docsUrl } = useRequestParams();
  const patternPlacement = contentPlacement === 'left' ? 'right' : 'left';
  const copyrightYear = new Date().getFullYear();

  const { pathname } = useLocation();

  return (
    <>
      <Helmet titleTemplate="%s | MongoDB" />
      <Container>
        <Content placement={contentPlacement}>
          <UIMessagesCustom path={pathname} placement={contentPlacement} />
          {children}
          {onPrem && (
            <OnPremFooterContainer placement={contentPlacement}>
              <HelpAndSupportLink href={docsUrl}>Help & Support</HelpAndSupportLink>
              <CopyRightText>©{copyrightYear} MongoDB, Inc.</CopyRightText>
            </OnPremFooterContainer>
          )}
        </Content>
        <Pattern hasLoginPromoImage={isLoginPromo && !onPrem} placement={patternPlacement}>
          {isLoginPromo && !onPrem && <LoginPromo placement={patternPlacement} />}
        </Pattern>
      </Container>
    </>
  );
}
