import styled from '@emotion/styled';

import { mq } from 'js/common/utils/mediaQueries';

export const MultiFactorAuthContainer = styled.div(() =>
  mq({
    maxWidth: ['auto', 'calc(100vw - 32px)', 'calc(100vw - 240px)', 735],
    margin: ['50px 16px 50px 42px', '50px 16px', '50px 16px 50px 42px', '50px 16px 50px 42px'],
    flex: '1 0 auto',
  })
);
