import { useEffect } from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { H2 } from '@leafygreen-ui/typography';
import { Helmet } from 'react-helmet';

import { WindowLocation } from '@packages/types/browser';

import AuthExpired from '@packages/components/AuthExpired/AuthExpired';
import ManageMFA from '@packages/components/CloudMFA/ManageMFA';

import { useAppUserParams } from 'js/common/context/AppUserContext';
import { useRequestParams } from 'js/common/context/RequestParamsContext';
import { mq } from 'js/common/utils/mediaQueries';

import HeaderLogo from 'js/auth/components/HeaderLogo';
import Layout from 'js/auth/components/Layout';
import colors from 'js/auth/utils/palette';

import { header } from './styles/form';
import { MultiFactorAuthContainer } from './styles/multiFactorPage';

const HeaderText = styled.div(() =>
  mq({
    color: colors.subtitleText,
    fontSize: '13px',
    lineHeight: '20px',
    letterSpacing: 0,
    maxWidth: ['auto', 'calc(100vw - 48px)', 'calc(100vw - 250px)', 735],
  })
);

interface MultiFactorSetupPageProps {
  windowLocation: WindowLocation;
}

export default function MultiFactorSetupPage({ windowLocation = window.location }: MultiFactorSetupPageProps) {
  const { siteName, centralUrl, multiFactorAuthLevel, telecomSvcEnabled } = useRequestParams();
  const { appUserParams, loadAppUserParams } = useAppUserParams();
  const { hasMultiFactorAuth, username, failedToLoad } = appUserParams;

  useEffect(() => {
    async function loadAuthParams() {
      await loadAppUserParams();
    }

    loadAuthParams();

    if (failedToLoad) {
      windowLocation.assign('/account/login');
    }
  }, [failedToLoad, loadAppUserParams]);

  const onFinishSetup = () => {
    windowLocation.assign('/');
  };

  const isMultiFactorAuthLevelRequired = multiFactorAuthLevel === 'REQUIRED';

  return (
    <>
      <Helmet title="Setup Two-Factor Authentication" />
      <Layout contentPlacement="right">
        <MultiFactorAuthContainer>
          <HeaderLogo />
          <H2 css={[header, css({ marginBottom: '16px' })]}>Setup Two-Factor Authentication</H2>
          <HeaderText>
            {isMultiFactorAuthLevelRequired
              ? 'In order to protect your account and your data, we ask new and existing users to setup two factor authentication. We only use this information for account security.'
              : 'In order to protect your account and your data, your organization owner requires all new and existing members of your organization to setup two factor authentication. We only use this information for account security.'}
          </HeaderText>
          <AuthExpired promptWithinModal username={username}>
            {({ onAuthExpired }) => (
              <ManageMFA
                hasMultiFactorAuth={hasMultiFactorAuth}
                username={username}
                onAuthExpired={onAuthExpired}
                phoneSupported={telecomSvcEnabled}
                siteName={siteName}
                centralUrl={centralUrl}
                onFinish={onFinishSetup}
                multiFactorAuthLevelRequired={isMultiFactorAuthLevelRequired}
                hideDisableAccordion
              />
            )}
          </AuthExpired>
        </MultiFactorAuthContainer>
      </Layout>
    </>
  );
}
