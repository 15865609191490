import * as api from 'js/common/services/api';

const SET_IP_ACCESS_LIST = 'ipAccessList/SET_IP_ACCESS_LIST';

const initialState = {};

// Reducer

// eslint-disable-next-line no-multi-assign
export default function ipAllowlistReducer(state = initialState, action) {
  switch (action.type) {
    case SET_IP_ACCESS_LIST: {
      return {
        ...state,
        ipAccessList: action.payload,
      };
    }
    default:
      return state;
  }
}

const getIpAccessListData = (state) => state.nds.ipAccessList;

// Selectors
export const getIpAccessList = (state) => getIpAccessListData(state).ipAccessList;

export const loadIpAccessList = (groupId) => async (dispatch) => {
  const response = await api.nds.networkSettings.getIpWhitelist(groupId);

  dispatch({
    type: SET_IP_ACCESS_LIST,
    payload: response,
  });

  return response;
};
