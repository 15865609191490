import * as api from 'js/common/services/api';

// Actions Types
const SET_BACKUPS = 'nds/serverlessBackup/SET_BACKUPS';
const SET_RESTORE_HISTORY = 'nds/serverlessBackup/SET_RESTORE_HISTORY';
const SET_METADATA = 'nds/serverlessBackup/SET_METADATA';

const initialState = {};

// Reducer
export default function backupReducer(state = initialState, action) {
  switch (action.type) {
    case SET_BACKUPS: {
      const newState = {
        ...state,
        snapshots: action.payload,
      };

      return newState;
    }
    case SET_RESTORE_HISTORY: {
      const newState = {
        ...state,
        restores: action.payload,
      };

      return newState;
    }
    case SET_METADATA: {
      const newState = {
        ...state,
        metadata: action.payload,
      };

      return newState;
    }
    default:
      return state;
  }
}

const getServerlessBackup = (state) => state.nds.serverlessBackup;

// Selectors
export const getServerlessBackupSnapshots = (state) => getServerlessBackup(state).snapshots || [];
export const getServerlessBackupRestores = (state) => getServerlessBackup(state).restores || [];
export const getServerlessBackupJobMetadata = (state) => getServerlessBackup(state).metadata || {};

// Action Creators
export const loadServerlessBackupSnapshots = (groupId, clusterUniqueId) => (dispatch) => {
  return api.nds.serverlessBackup.getSnapshots(groupId, clusterUniqueId).then((response) => {
    dispatch({
      type: SET_BACKUPS,
      payload: response,
    });
  });
};

export const loadServerlessBackupRestoreHistory = (groupId, clusterUniqueId) => (dispatch) => {
  return api.nds.serverlessBackup.getRestoreHistory(groupId, clusterUniqueId).then((response) => {
    dispatch({
      type: SET_RESTORE_HISTORY,
      payload: response,
    });
  });
};

export const loadServerlessBackupJobMetadata = (clusterUniqueId) => (dispatch) => {
  return api.nds.serverlessBackup.getBackupJobMetadata(clusterUniqueId).then((response) => {
    dispatch({
      type: SET_METADATA,
      payload: response,
    });
  });
};
