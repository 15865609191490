import { DataProtectionSettings } from '@packages/types/nds/backup/jobTypes';

import * as api from 'js/common/services/api';

// Actions Types
const SET_BACKUPS = 'nds/diskBackup/SET_BACKUPS';
const SET_RESTORE_HISTORY = 'nds/diskBackup/SET_RESTORE_HISTORY';
const SET_EXPORT_HISTORY = 'nds/diskBackup/SET_EXPORT_HISTORY';
const SET_METADATA = 'nds/diskBackup/SET_METADATA';
const SET_EXPORT_BUCKETS = 'nds/diskBackup/SET_EXPORT_BUCKETS';
const SET_PROVIDER_OPTIONS = 'nds/diskBackup/SET_PROVIDER_OPTIONS';
const SET_DATA_PROTECTION_SETTINGS = 'nds/diskBackup/SET_DATA_PROTECTION_SETTINGS';

const initialState = {};

// Reducer
export default function backupReducer(state = initialState, action) {
  switch (action.type) {
    case SET_BACKUPS: {
      const newState = {
        ...state,
        snapshots: action.payload,
      };

      return newState;
    }
    case SET_RESTORE_HISTORY: {
      const newState = {
        ...state,
        restores: action.payload,
      };

      return newState;
    }
    case SET_EXPORT_HISTORY: {
      const newState = {
        ...state,
        exports: action.payload,
      };

      return newState;
    }
    case SET_METADATA: {
      const newState = {
        ...state,
        metadata: action.payload,
      };

      return newState;
    }
    case SET_EXPORT_BUCKETS: {
      const newState = {
        ...state,
        exportBuckets: action.payload,
      };

      return newState;
    }
    case SET_PROVIDER_OPTIONS: {
      const newState = {
        ...state,
        providerOptions: action.payload,
      };

      return newState;
    }
    case SET_DATA_PROTECTION_SETTINGS: {
      const newState = {
        ...state,
        dataProtectionSettings: action.payload as DataProtectionSettings,
      };

      return newState;
    }
    default:
      return state;
  }
}

const getDiskBackupData = (state) => state.nds.diskBackup;

// Selectors
export const getDiskBackups = (state) => getDiskBackupData(state).snapshots || [];
export const getDiskBackupRestoreHistory = (state) => getDiskBackupData(state).restores || [];
export const getExportHistory = (state) => getDiskBackupData(state).exports || [];
export const getDiskMetadata = (state) => getDiskBackupData(state).metadata || {};
export const getExportBuckets = (state) => getDiskBackupData(state).exportBuckets || [];
export const getProviderOptions = (state) => getDiskBackupData(state).providerOptions || {};
export const getDataProtectionSettings = (state): DataProtectionSettings =>
  getDiskBackupData(state).dataProtectionSettings || {};

// Action Creators
export const loadDiskBackups = (groupId, clusterUniqueId) => (dispatch) => {
  return api.nds.backup.getDiskBackups(groupId, clusterUniqueId).then((response) => {
    dispatch({
      type: SET_BACKUPS,
      payload: response,
    });
  });
};

export const loadDiskBackupRestoreHistory = (groupId, clusterUniqueId) => (dispatch) => {
  return api.nds.backup.getDiskBackupRestoreHistory(groupId, clusterUniqueId).then((response) => {
    dispatch({
      type: SET_RESTORE_HISTORY,
      payload: response,
    });
  });
};

export const loadDiskBackupMetadata = (clusterUniqueId) => (dispatch) => {
  return api.nds.backup.getDiskBackupMetadata(clusterUniqueId).then((response) => {
    dispatch({
      type: SET_METADATA,
      payload: response,
    });
    return response;
  });
};

export const loadExportBuckets = (groupId) => (dispatch) => {
  return api.nds.backup.getExportBuckets(groupId).then((response) => {
    dispatch({
      type: SET_EXPORT_BUCKETS,
      payload: response,
    });
  });
};

export const loadProviderOptions = (groupId: string) => (dispatch) => {
  return api.nds.clusterForm.getProviderOptions(groupId).then((response) => {
    return dispatch({
      type: SET_PROVIDER_OPTIONS,
      payload: response,
    });
  });
};

export const loadExportHistory = (groupId, clusterUniqueId) => (dispatch) => {
  return api.nds.backup.getExportHistory(groupId, clusterUniqueId).then((response) => {
    dispatch({
      type: SET_EXPORT_HISTORY,
      payload: response,
    });
  });
};

export const loadDataProtectionSettings = (groupId) => (dispatch) => {
  return api.nds.backup.getDataProtectionSettings(groupId).then((response) => {
    dispatch({
      type: SET_DATA_PROTECTION_SETTINGS,
      payload: response,
    });
  });
};
