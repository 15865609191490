import { getActiveGroupId } from '@packages/redux/modules/app';
import { getBaasCentralUrl } from '@packages/redux/modules/settings';
import { getId, getUsername } from '@packages/redux/modules/viewer';

export const getBaasParamsFromState = (state) => ({
  groupId: getActiveGroupId(state),
  baasUrl: getBaasCentralUrl(state),
  username: getUsername(state),
  userId: getId(state),
});

export const getBaasParamsFromSettingsModel = (settingsModel) => ({
  groupId: settingsModel.getCurrentGroupId(),
  baasUrl: settingsModel.getBaasUrl(),
  username: settingsModel.getCurrentUser(),
});
