import { createAsyncThunk, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { Draft } from 'immer';

import { DataFederationQueryLimit, LimitSpan, TenantName } from '@packages/types/nds/dataFederationQueryLimits';

import { RootState } from '@packages/redux/rootReducer';

import {
  configureQueryLimit,
  deleteQueryLimit,
  getQueryLimits,
} from 'js/common/services/api/nds/dataFederationQueryLimitsApi';

export interface DataFederationQueryLimitsState {
  queryLimits: Array<DataFederationQueryLimit>;
  fetchLimitsMetadata: {
    errorCount: number;
    successCount: number;
  };
}

// copied here from immer, as it's defined there but no longer exported
type WritableDraft<T> = { -readonly [K in keyof T]: Draft<T[K]> };

const queryLimitsInitialState: DataFederationQueryLimitsState = {
  queryLimits: [],
  fetchLimitsMetadata: {
    errorCount: 0,
    successCount: 0,
  },
};

export enum Action {
  FetchByGroupId = 'dataFederationQueryLimits/fetchByGroupId',
  PatchQueryLimit = 'dataFederationQueryLimits/patchQueryLimit',
  DeleteQueryLimit = 'dataFederationQueryLimits/deleteQueryLimit',
}

export const queryLimitsSelector = (s: RootState) => s.nds.dataFederationQueryLimits;

// async thunk
export const fetchDataFederationQueryLimitsByGroupId = createAsyncThunk(
  Action.FetchByGroupId,
  async (groupId: string) => await getQueryLimits(groupId)
);

interface ConfigureQueryLimitArgs {
  groupId: string;
  queryLimit: DataFederationQueryLimit;
}

export const patchDataFederationQueryLimit = createAsyncThunk(
  Action.PatchQueryLimit,
  async ({ groupId, queryLimit }: ConfigureQueryLimitArgs) => await configureQueryLimit(groupId, queryLimit)
);

interface DeleteQueryLimitArgs {
  groupId: string;
  tenantName: TenantName;
  limitSpan: LimitSpan;
}

export const deleteDataFederationQueryLimit = createAsyncThunk(
  Action.DeleteQueryLimit,
  async ({ groupId, tenantName, limitSpan }: DeleteQueryLimitArgs) => {
    await deleteQueryLimit(groupId, tenantName, limitSpan);
    return { tenantName, limitSpan };
  }
);

const setLimits = (
  state: WritableDraft<DataFederationQueryLimitsState>,
  action: PayloadAction<Array<DataFederationQueryLimit>>
): DataFederationQueryLimitsState => {
  state.queryLimits = action.payload;
  state.fetchLimitsMetadata = {
    successCount: state.fetchLimitsMetadata.successCount + 1,
    // reset error count upon successful fetch
    errorCount: 0,
  };
  return state;
};

const incrementFetchErrorCount = (state: WritableDraft<DataFederationQueryLimitsState>) => {
  state.fetchLimitsMetadata.errorCount++;
  return state;
};

const upsertLimit = (
  state: WritableDraft<DataFederationQueryLimitsState>,
  action: PayloadAction<DataFederationQueryLimit>
): DataFederationQueryLimitsState => {
  const upsertedQueryLimit = action.payload;
  const existingQueryLimits = state.queryLimits.filter(
    (l) => l.tenantName !== upsertedQueryLimit.tenantName || l.limitSpan !== upsertedQueryLimit.limitSpan
  );
  return {
    ...state,
    queryLimits: [...existingQueryLimits, upsertedQueryLimit],
  };
};

const deleteLimit = (
  state: WritableDraft<DataFederationQueryLimitsState>,
  action: PayloadAction<{
    tenantName: TenantName;
    limitSpan: LimitSpan;
  }>
): DataFederationQueryLimitsState => {
  const { tenantName, limitSpan } = action.payload;
  const newQueryLimits = state.queryLimits.filter((l) => l.tenantName !== tenantName || l.limitSpan !== limitSpan);
  return {
    ...state,
    queryLimits: newQueryLimits,
  };
};

export const dataFederationQueryLimitsReducer = createReducer(queryLimitsInitialState, (builder) => {
  builder
    .addCase(fetchDataFederationQueryLimitsByGroupId.fulfilled, setLimits)
    .addCase(fetchDataFederationQueryLimitsByGroupId.rejected, incrementFetchErrorCount)
    .addCase(patchDataFederationQueryLimit.fulfilled, upsertLimit)
    .addCase(deleteDataFederationQueryLimit.fulfilled, deleteLimit);
});

export default dataFederationQueryLimitsReducer;
