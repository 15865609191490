import { ChangeEventHandler, ClipboardEventHandler, Component, KeyboardEventHandler } from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { palette } from '@leafygreen-ui/palette';

import { WithDefaultProps } from '@packages/types/withDefaultProps';

import { Check, Spinner } from '@packages/components/styles/multiFactorAuth';

import { mq } from 'js/common/utils/mediaQueries';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: center;
`;

const Input = styled.input(({ isInvalid }: { isInvalid: boolean }) =>
  mq({
    boxSizing: 'border-box',
    width: ['24px', '24px', '20px', '24px'],
    height: '46px',
    border: `1px solid ${isInvalid ? palette.red.base : palette.gray.light1}`,
    borderRadius: '2px',
    backgroundColor: '#f8f8f8',
    boxShadow: `inset 0 1px 4px 0 ${palette.gray.light1}`,
    marginRight: '6px',
    fontSize: '23px',
    fontWeight: '300',
    textAlign: 'center',
    '&:focus': {
      color: palette.gray.dark2,
      letterSpacing: '0',
      lineHeight: '42px',
      border: `2px solid ${isInvalid ? palette.red.base : palette.green.dark1}`,
      outline: 'none',
    },
  })
);

const Dash = styled.div`
  font-size: 29px;
  margin: 4px 8px 0 4px;
  font-weight: 100;
`;

const Gap = styled.div`
  margin-right: 8px;
`;

const inputsTypeMap = {
  voiceCode: {
    length: 5,
  },
  code: {
    length: 6,
  },
  codeRecovery: {
    length: 8,
  },
  deviceCode: {
    length: 8,
  },
};

type Value = string | number;
export type InputType = keyof typeof inputsTypeMap;
export type Icon = '' | 'spinner' | 'complete';

// Creates initial value for the inputType.
// Each item in the array must be an empty string so React knows it's a controlled element.
function createInitialValueForType(inputType: InputType): Array<Value> {
  const typeInfo = inputsTypeMap[inputType];
  return Array.from({ length: typeInfo.length }, () => '');
}

const inputNamePrefix = 'input-';

function createInputNameFromIndex(index: number) {
  return `${inputNamePrefix}${index}`;
}

function getIndexFromName(inputName: string) {
  const stringNumber = inputName.replace(inputNamePrefix, '');
  return parseInt(stringNumber, 10);
}

interface OwnProps {
  inputsType?: InputType;
  onComplete: (options: { value: string }) => void;
  pastedValueFormatting?: (value: string) => Array<Value>;
  icon?: Icon;
  isInvalid?: boolean;
}

interface State {
  value: Array<Value>;
}

const defaultProps = {
  inputsType: 'code' as const,
  icon: undefined,
  isInvalid: false,
  pastedValueFormatting(pastedValue: string) {
    return (
      pastedValue
        .split('')
        .map((i) => parseInt(i, 10))
        // Make sure every item is a valid number.
        // Comparing greater than as NaN !== NaN is true.
        .filter((i) => i > -1)
    );
  },
};

type Props = WithDefaultProps<OwnProps, typeof defaultProps>;

class AutoAdvanceInputs extends Component<Props, State> {
  static defaultProps = defaultProps;

  previousValue: Value | null;

  state: State = {
    value: createInitialValueForType(this.props.inputsType),
  };

  onValueChange = (index: number, newVal: Value) => {
    const { value } = this.state;

    value[index] = newVal;

    this.setState({
      value,
    });

    const valueAsString = value.join('');

    // If we've filled in the entire valueAsString then submit.
    if (inputsTypeMap[this.props.inputsType].length === valueAsString.length && valueAsString !== this.previousValue) {
      this.props.onComplete({ value: valueAsString });

      // Store previous value so we don't call our `onComplete` handler multiple
      // times for the same value.
      // This case can happen if a user pastes a value - we get notified of
      // a paste and a change event which causes a double trigger.
      // This prevents double `onComplete` triggering.
      this.previousValue = valueAsString;
    }
  };

  onInputPaste: ClipboardEventHandler<HTMLInputElement> = (event) => {
    const target = event.target as HTMLInputElement;
    const eventInputIndex = getIndexFromName(target.name);

    const pasteData = event.clipboardData.getData('text');
    const values = this.props.pastedValueFormatting(pasteData);
    values.forEach((value, index) => {
      this.onValueChange(eventInputIndex + index, value);
    });
  };

  onInputKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    const deleteKeyCode = 8;

    if (event.nativeEvent.keyCode === deleteKeyCode) {
      const target = event.target as HTMLInputElement;
      const currentIndex = getIndexFromName(target.name);
      const inputPreviousIndex = currentIndex - 1;
      const inputPrevious = this.inputs[inputPreviousIndex];
      if (inputPrevious && target.value === '') {
        inputPrevious.focus();
      }
    }
  };

  onInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const eventInputIndex = getIndexFromName(event.target.name);
    const eventValue = event.target.value;

    this.onValueChange(eventInputIndex, eventValue);

    const code = this.state.value.join('');

    // If we've filled in the entire code then submit.
    if (this.state.value.length === code.length) {
      event.target.blur();
      return;
    }

    // Focus next input element that has no value.
    for (let i = eventInputIndex; i < this.inputs.length; i++) {
      const inputEl = this.inputs[i];
      if (inputEl?.value === '') {
        inputEl.focus();
        break;
      }
    }
  };

  getValue = () => this.state.value.join('');

  reset = () => {
    // Reset value to blank state.
    this.setState({ value: this.state.value.map(() => '') });

    // Autofocus the first input.
    this.inputs[0]?.focus();

    // Reset previous value when input is reset.
    this.previousValue = null;
  };

  createAriaLabelFromIndex = (index: number) => {
    return `${this.props.inputsType} ${index}`;
  };

  inputs: Array<HTMLInputElement | null> = [];

  createMultipleInputElements = ({ numberOfElements = 0, indexOffset = 0 }) => {
    const { isInvalid } = this.props;

    return Array.from({ length: numberOfElements }, (_, timesIndex) => {
      const index = indexOffset + timesIndex;
      return (
        <Input
          type="text"
          maxLength={1}
          onPaste={this.onInputPaste}
          onKeyDown={this.onInputKeyDown}
          onChange={this.onInputChange}
          value={this.state.value[index]}
          name={createInputNameFromIndex(index)}
          aria-label={this.createAriaLabelFromIndex(index)}
          key={index}
          ref={(ref) => {
            this.inputs[index] = ref;
          }}
          autoFocus={index === 0}
          data-testid="autoAdvanceInput"
          isInvalid={isInvalid}
        />
      );
    });
  };

  render() {
    const { inputsType, icon } = this.props;

    let content;

    switch (inputsType) {
      case 'voiceCode':
        content = [
          this.createMultipleInputElements({
            numberOfElements: 5,
            indexOffset: 0,
          }),
        ];
        break;

      case 'codeRecovery':
        content = [
          this.createMultipleInputElements({
            numberOfElements: 3,
            indexOffset: 0,
          }),
          <Dash key="dash1">-</Dash>,
          this.createMultipleInputElements({
            numberOfElements: 2,
            indexOffset: 3,
          }),
          <Dash key="dash2">-</Dash>,
          this.createMultipleInputElements({
            numberOfElements: 3,
            indexOffset: 5,
          }),
        ];
        break;

      case 'deviceCode':
        content = [
          this.createMultipleInputElements({
            numberOfElements: 4,
            indexOffset: 0,
          }),
          <Dash key="dash1">-</Dash>,
          this.createMultipleInputElements({
            numberOfElements: 4,
            indexOffset: 4,
          }),
        ];
        break;

      case 'code':
      default:
        content = [
          this.createMultipleInputElements({
            numberOfElements: 3,
            indexOffset: 0,
          }),
          <Gap key="dash1" />,
          this.createMultipleInputElements({
            numberOfElements: 3,
            indexOffset: 3,
          }),
        ];
        break;
    }

    return (
      <Container data-testid="autoAdvanceInputs">
        {content}
        {icon === 'spinner' && (
          <Spinner
            src="/static/images/mfa/spinner.svg"
            alt="spinner"
            css={css({
              width: '16px',
              height: '16px',
            })}
          />
        )}
        {icon === 'complete' && <Check src="/static/images/mfa/check.svg" alt="check" />}
      </Container>
    );
  }
}

export default AutoAdvanceInputs;
export { createInputNameFromIndex };
