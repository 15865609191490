import { ServerlessInstanceDescription } from '@packages/types/nds/serverless/serverlessInstances';

import fetchWrapper from '../fetchWrapper';

export default {
  getServerlessInstances(groupId: string) {
    return fetchWrapper(`/nds/serverless/${groupId}`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  postServerlessInstance(
    groupId: string,
    serverlessInstanceDescription: ServerlessInstanceDescription,
    recaptchaToken = ''
  ) {
    return fetchWrapper(`/nds/serverless/${groupId}?recaptchaToken=${recaptchaToken}`, {
      method: 'POST',
      body: JSON.stringify(serverlessInstanceDescription),
    }).then((resp) => resp.json());
  },

  patchServerlessInstance(
    groupId: string,
    instanceName: string,
    serverlessInstanceDescription: ServerlessInstanceDescription,
    recaptchaToken = ''
  ) {
    return fetchWrapper(`/nds/serverless/${groupId}/${instanceName}?recaptchaToken=${recaptchaToken}`, {
      method: 'PATCH',
      body: JSON.stringify(serverlessInstanceDescription),
    }).then((resp) => resp.json());
  },

  getCostEstimate(groupId: string, serverlessInstanceDescription: ServerlessInstanceDescription) {
    return fetchWrapper(`/nds/serverless/${groupId}/costEstimate`, {
      method: 'POST',
      body: JSON.stringify(serverlessInstanceDescription),
    }).then((resp) => resp.json());
  },

  postStartClusterUpgradeToServerless(groupId: string, serverlessInstanceDescription: ServerlessInstanceDescription) {
    return fetchWrapper(`/nds/clusterUpgradeToServerless/${groupId}`, {
      method: 'POST',
      body: JSON.stringify(serverlessInstanceDescription),
    }).then((resp) => resp.json());
  },
};
