/**
 * NOTE: THIS FILE IS DEPRECATED IN FAVOR OF
 * js/common/utils/experimentUtils.
 *
 * Please do not add any new code to this file.
 *
 * Ask in #ask-atlas-growth if you have any questions.
 */
import {
  AllocationPoint,
  Assignment,
  Assignments,
  ExperimentFeatureFlags,
  ExperimentFeatureFlagsFromServer,
  NonNullAssignment,
  NonNullAssignments,
  ServerAssignment,
  ServerAssignments,
  TestGroupName,
  TestName,
} from '@packages/types/abTest';

import * as api from 'js/common/services/api';
import { BugsnagSeverity, CloudTeams, sendError } from 'js/common/utils/bugsnag';
import deepClone from 'js/common/utils/deepClone';
import { isForcedIntoTestGroup } from 'js/common/utils/isForcedIntoTestGroup';
import analytics, {
  ExperimentEventViewed,
  ExperimentViewedAdditionalProperties,
  ExperimentViewedBaseEvent,
  HeliotropeReturn,
  SEGMENT_EVENTS,
} from 'js/common/utils/segmentAnalytics';

export interface IsInTestGroupProps {
  testName: TestName;
  testGroupName: TestGroupName;
  shouldAllocate: boolean;
  groupId?: string;
  /** Org ID is only needed if you don't have a group ID */
  orgId?: string;
  centralUrl?: string;
}

interface SetAndGetAssignmentsProps {
  testNames: Array<TestName>;
  /** Org ID is only needed if you don't have a group ID */
  orgId?: string;
  groupId?: string;
  centralUrl?: string;
}

interface SetAndGetAssignmentsByPointProps {
  allocationPoint: AllocationPoint;
  /** Org ID is only needed if you don't have a group ID */
  orgId?: string;
  groupId?: string;
  centralUrl?: string;
}

interface SetAndGetAssignmentsByPointListProps {
  allocationPoints: Array<AllocationPoint>;
  /** Org ID is only needed if you don't have a group ID */
  orgId?: string;
  groupId?: string;
  centralUrl?: string;
}

type AssignmentsMap = {
  [key in TestName]?: Assignment;
};

let assignmentsMap: AssignmentsMap = {};

type AllocationPointsMap = {
  [key in AllocationPoint]?: boolean;
};

let allocationPointsMap: AllocationPointsMap = {};

let experimentFeatureFlags: ExperimentFeatureFlags = {};

const toList = (map: AssignmentsMap): Assignments => Object.values(map).filter((v): v is Assignment => !!v);

const alertOnConflictingAssignments = (): void => {
  const assignmentArray: Assignments = deepClone(toList(assignmentsMap));

  const accumulator: Assignments = [];

  assignmentArray.forEach((current) => {
    if (
      !accumulator.some(
        (item) =>
          item.entityId === current.entityId &&
          item.entityType === current.entityType &&
          item.testGroupDatabaseId === current.testGroupDatabaseId &&
          item.testId === current.testId
      )
    ) {
      accumulator.push(current);
    } else {
      sendError({
        error: new Error('Conflict detected in test assignments map'),
        team: CloudTeams.AtlasGrowth,
        metaData: { assignments: { assignmentArray } },
        severity: BugsnagSeverity.WARNING,
      });
    }
  });
};

/**
 * @deprecated use experimentUtils instead
 */
export const cacheUtils = {
  addExperimentFeatureFlags(serverFeatureFlags: ExperimentFeatureFlagsFromServer): void {
    if (!serverFeatureFlags || Object.keys(serverFeatureFlags).length === 0) {
      return;
    }
    // Cast incoming string values to proper types
    const browserFeatureFlags: ExperimentFeatureFlags = {};
    Object.entries(serverFeatureFlags).forEach(([key, value]) => {
      if (value === 'true' || value === 'false') {
        browserFeatureFlags[key] = JSON.parse(value);
      } else if (!isNaN(parseFloat(value))) {
        browserFeatureFlags[key] = parseFloat(value);
      } else {
        browserFeatureFlags[key] = value;
      }
    });

    experimentFeatureFlags = { ...experimentFeatureFlags, ...browserFeatureFlags };
  },
  addAssignments(serverAssignments: ServerAssignments): Assignments {
    // Return current state if nothing to add
    if (!serverAssignments?.length) {
      return toList(assignmentsMap);
    }

    // Convert server assignment format to browser format
    const newAssignments: Assignments = serverAssignments.map(serverAssignmentToBrowserAssignment);

    newAssignments.forEach((newAssignment) => {
      const oldAssignment = assignmentsMap[newAssignment.testName];
      if (oldAssignment) {
        // ABTestSvc.java should not reassign entities. Send to BugSnag if this is the case
        // TODO: CLOUDP-116083 - Add "redundant assignment" detection back in.
        if (
          oldAssignment.testGroupDatabaseId !== newAssignment.testGroupDatabaseId &&
          oldAssignment.entityId === newAssignment.entityId
        ) {
          sendError({
            error: new Error('Test assignment overwritten with different test group'),
            team: CloudTeams.AtlasGrowth,
            metaData: { diff: { oldAssignment, newAssignment } },
          });
        }
      }

      // Add entry, replacing if it already exists in state
      assignmentsMap[newAssignment.testName] = newAssignment;
    });

    // Call identify with all non-null assignments
    analyticsAssignments.identify();

    return toList(assignmentsMap);
  },
};

/**
 * @deprecated use experimentUtils instead
 */
export const getAssignments = (): Assignments => {
  alertOnConflictingAssignments();
  return deepClone(toList(assignmentsMap));
};

type ExperimentFeatureFlagReturn<Key extends keyof ExperimentFeatureFlags> = NonNullable<
  (typeof experimentFeatureFlags)[Key]
>;

/**
 * @deprecated use experimentUtils instead
 */
export const getExperimentFeatureFlag = <Key extends keyof ExperimentFeatureFlags>(
  key: Key,
  defaultValue: ExperimentFeatureFlagReturn<Key>
): ExperimentFeatureFlagReturn<Key> => experimentFeatureFlags[key] ?? defaultValue;

/**
 * @deprecated use experimentUtils instead
 *
 * For use in unit tests.
 */
export const stateControls = {
  clearAssignments: (): void => {
    assignmentsMap = {};
  },
  clearExperimentFeatureFlags: (): void => {
    experimentFeatureFlags = {};
  },
  clearAllocationPointsMap: (): void => {
    allocationPointsMap = {};
  },
};

/**
 * @deprecated use experimentUtils instead
 *
 * For use in clearing state in unit tests.
 */
export const getAllocationPoints = (): AllocationPointsMap => ({ ...allocationPointsMap });

/**
 * @deprecated use experimentUtils instead
 *
 * For use in clearing state in unit tests.
 */
export const reset = () => {
  stateControls.clearAssignments();
  stateControls.clearExperimentFeatureFlags();
  stateControls.clearAllocationPointsMap();
};

interface IsInAnyTestGroupProps {
  testName: TestName;
  testGroupNames: ReadonlyArray<TestGroupName>;
  shouldAllocate: boolean;
  groupId?: string;
  /** Org ID is only needed if you don't have a group ID */
  orgId?: string;
  /**
   * If not on cloud.mongodb.com or one of its dev environments,
   * you must include centralUrl=cloud.mongodb.com so that the proper cookies are relayed in the request.
   */
  centralUrl?: string;
}

/**
 * @deprecated use experimentUtils instead
 *
 In order to generate assignments for experiments with project/org scope, a groupId must be included.
 More info about using this function can be found in the wiki:
 @see https://wiki.corp.mongodb.com/display/MMS/Frontend+AB+Test+Implementation
 */
export const isInAnyTestGroup = async ({
  testName,
  testGroupNames,
  groupId,
  orgId,
  shouldAllocate,
  centralUrl = '',
}: IsInAnyTestGroupProps) => {
  if (testGroupNames.some((testGroupName) => isForcedIntoTestGroup(testGroupName))) {
    return true;
  }

  // Checks for an existing assignment without generating an assignment in the backend.
  // Useful for showing test group "side-effects" without having to place user in a test.
  // (e.g. A nav item that appears on every page for people who have seen experimental UI)
  if (!shouldAllocate) {
    return testGroupNames.some((testGroupName) => assignmentsMap[testName]?.testGroupName === testGroupName);
  }

  const assignments = await setAndGetAssignments({
    testNames: [testName],
    groupId,
    orgId,
    centralUrl,
  });

  return (
    assignments?.[0] != null && testGroupNames.some((testGroupName) => assignments[0].testGroupName === testGroupName)
  );
};

/**
 * @deprecated use experimentUtils instead
 *
 * Function handling the most common case of only wanting to check one variant
 * */
export const isInTestGroup = ({
  testName,
  testGroupName,
  groupId,
  orgId,
  shouldAllocate,
  centralUrl,
}: IsInTestGroupProps): Promise<boolean> =>
  isInAnyTestGroup({
    testName,
    testGroupNames: [testGroupName],
    groupId,
    orgId,
    shouldAllocate,
    centralUrl,
  });

/**
 * @deprecated use experimentUtils instead
 *
 * In order to generate assignments for experiments with project/org scope, a groupId must be included.
 * More info about using this function can be found in the wiki:
 * https://wiki.corp.mongodb.com/display/MMS/Frontend+AB+Test+Implementation
 */
export const setAndGetAssignments = async ({
  testNames,
  groupId,
  orgId,
  // If not on cloud.mongodb.com or one of its dev environments, you must
  // include centralUrl=cloud.mongodb.com so that the proper cookies are relayed in the request
  centralUrl = '',
}: SetAndGetAssignmentsProps): Promise<Assignments | null> => {
  const unassignedTestNames = testNames.reduce((acc, testName) => {
    if (!assignmentsMap[testName]) {
      acc.push(testName);
    }
    return acc;
  }, [] as Array<TestName>);

  if (unassignedTestNames.length > 0) {
    try {
      // Generate assignments if unavailable, and place in state
      const { data, experimentFeatureFlags } = await api.abTest.generateABTestAssignmentsAndFlags({
        centralUrl,
        groupId,
        orgId,
        testNames: unassignedTestNames,
      });
      // Adds all new assignments, both null and valid
      cacheUtils.addAssignments(data);
      // Add feature flags
      cacheUtils.addExperimentFeatureFlags(experimentFeatureFlags);
    } catch (error) {
      sendError({ error, team: CloudTeams.AtlasGrowth, metaData: { testNames: unassignedTestNames } });
      return null;
    }
  }

  const assignmentsRequested = toList(assignmentsMap).filter((assignment) => testNames.includes(assignment.testName));
  return assignmentsRequested;
};

const analyticsAssignments = {
  identify: (): void => {
    const nonNullAssignments = getNonNullAssignments();
    const persistedProperties = getAbTestPersistedProps(nonNullAssignments);
    analytics.updateState({ persistedProperties });
  },
};

/**
 * @deprecated use experimentUtils instead
 */
export const filterNullAssignments = (assignments: Assignments): NonNullAssignments => {
  return assignments.filter(
    (assignment): assignment is NonNullAssignment => typeof assignment.testGroupName === 'string'
  );
};

/**
 * @deprecated use experimentUtils instead
 */
export const getNonNullAssignments = (): Array<NonNullAssignment> => {
  const allAssigments = getAssignments();
  return filterNullAssignments(allAssigments);
};

/**
 * @deprecated use experimentUtils instead
 */
export const getAssignmentMatchingName = (testName: TestName): Readonly<Assignment> | null =>
  assignmentsMap[testName] ?? null;

/**
 * @deprecated use experimentUtils instead
 */
export const trackControlOrTreatmentSeen = async (
  testName: TestName,
  additionalProps?: ExperimentViewedAdditionalProperties
): Promise<HeliotropeReturn> => {
  const nonNullAssignments = getNonNullAssignments();
  const targetAssignment = nonNullAssignments.find((assignment) => assignment.testName === testName);

  if (targetAssignment) {
    const props = formatTrackProperties(targetAssignment);
    const combinedProps: ExperimentEventViewed = { ...props, ...additionalProps };
    return analytics.track(SEGMENT_EVENTS.EXPERIMENT_VIEWED, combinedProps);
  }

  return Promise.resolve();
};

const formatTrackProperties = ({
  testGroupName,
  entityType,
  entityId,
  tag,
  assignmentDate,
  testGroupDatabaseId,
  testId,
  testName,
}: NonNullAssignment): ExperimentViewedBaseEvent => ({
  test_group_id: testGroupName,
  test_group_name: testGroupName,
  test_name: testName,
  entity_type: entityType,
  entity_id: entityId,
  test_assignment: formatUniqueTestAssignmentString(testName, testGroupName),
  test_tag: tag,
  assignment_date: assignmentDate,
  test_group_database_id: testGroupDatabaseId,
  test_id: testId,
});

/**
 * @deprecated use experimentUtils instead
 */
export const formatUniqueTestAssignmentString = (testName: TestName, testGroupName: TestGroupName): string =>
  `test_${testName}_variant_${testGroupName}`;

/**
 * @deprecated use experimentUtils instead
 */
export const serverAssignmentToBrowserAssignment = ({ testGroupId, ...rest }: ServerAssignment) => ({
  testGroupName: testGroupId,
  ...rest,
});

/**
 * @deprecated use experimentUtils instead
 */
export const getAbTestPersistedProps = (testAssignments: Array<NonNullAssignment> = []) => {
  return {
    experiment_ids: testAssignments.map(({ testId }) => testId),
    experiment_variant_ids: testAssignments.map(({ testGroupDatabaseId }) => testGroupDatabaseId),
    // CLOUDP-90409: kept for backwards compatibility
    test_assignments: testAssignments.map(({ testName, testGroupName }) =>
      formatUniqueTestAssignmentString(testName, testGroupName)
    ),
  };
};

/**
 * @deprecated use experimentUtils instead
 */
export const setAndGetAssignmentsByPoint = async ({
  allocationPoint,
  groupId,
  orgId,
  centralUrl = '',
}: SetAndGetAssignmentsByPointProps) => {
  if (!allocationPointsMap[allocationPoint]) {
    try {
      const { data, experimentFeatureFlags } = await api.abTest.generateABTestAssignmentsFromAllocationPoints({
        centralUrl,
        groupId,
        orgId,
        allocationPoints: [allocationPoint],
      });

      // Add feature flags
      cacheUtils.addExperimentFeatureFlags(experimentFeatureFlags);
      // Adds all new assignments, both null and valid
      cacheUtils.addAssignments(data);
      // Note that this adds existing and new assignments, as the allocation point id is not available on the assignment object
      allocationPointsMap[allocationPoint] = true;
    } catch (error) {
      sendError({
        error,
        team: CloudTeams.AtlasGrowth,
        metaData: {
          allocationPoints: [allocationPoint],
        },
      });
      return null;
    }
  }
};

/**
 * @deprecated use experimentUtils instead
 */
export const setAndGetAssignmentsByPointList = async ({
  allocationPoints,
  groupId,
  orgId,
  centralUrl = '',
}: SetAndGetAssignmentsByPointListProps) => {
  // Filter the new allocation points
  const filteredAllocationPoints = allocationPoints.filter((point) => {
    return !allocationPointsMap[point];
  });

  if (filteredAllocationPoints?.length === 0) {
    return null;
  }

  try {
    const { data, experimentFeatureFlags } = await api.abTest.generateABTestAssignmentsFromAllocationPoints({
      centralUrl,
      groupId,
      orgId,
      allocationPoints: filteredAllocationPoints,
    });

    // Add feature flags
    cacheUtils.addExperimentFeatureFlags(experimentFeatureFlags);
    // Adds all new assignments, both null and valid
    cacheUtils.addAssignments(data);
    // Note that this adds existing and new assignments, as the allocation point id is not available on the assignment object
    filteredAllocationPoints.forEach((allocationPoint) => {
      allocationPointsMap[allocationPoint] = true;
    });
  } catch (error) {
    sendError({
      error,
      team: CloudTeams.AtlasGrowth,
      metaData: {
        allocationPoints: allocationPoints,
      },
    });
    return null;
  }
};
