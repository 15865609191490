import { ExternalPrivateEndpoint } from '@packages/types/nds/privateNetworkSettings';

import { fetchPrivateEndpoints } from 'js/common/services/api/nds/privateNetworkSettingsApi';

export interface ExternalPrivateEndpointState {
  externalPrivateEndpoints: Array<ExternalPrivateEndpoint>;
}

enum ExternalPrivateEndpointActionTypes {
  UPDATE_EXTERNAL_PRIVATE_ENDPOINTS = '/nds/privateNetworkSettings/UPDATE_EXTERNAL_PRIVATE_ENDPOINTS',
}

interface ExternalPrivateEndpointAction {
  type: ExternalPrivateEndpointActionTypes;
  payload: Array<ExternalPrivateEndpoint>;
}

const initialState = {
  externalPrivateEndpoints: [],
};

export default function externalPrivateEndpointReducer(
  state: ExternalPrivateEndpointState = initialState,
  action: ExternalPrivateEndpointAction
): ExternalPrivateEndpointState {
  switch (action.type) {
    case ExternalPrivateEndpointActionTypes.UPDATE_EXTERNAL_PRIVATE_ENDPOINTS:
      return {
        ...state,
        externalPrivateEndpoints: action.payload as Array<ExternalPrivateEndpoint>,
      };
    default:
      return state;
  }
}

const getExternalPrivateEndpointData = (state): ExternalPrivateEndpointState => state.nds.privateNetworkSettings;

// selectors
export const getExternalPrivateEndpoints = (state): Array<ExternalPrivateEndpoint> =>
  getExternalPrivateEndpointData(state).externalPrivateEndpoints || [];

// Action Creators
const updateExternalPrivateEndpoints = (payload: Array<ExternalPrivateEndpoint>) => ({
  type: ExternalPrivateEndpointActionTypes.UPDATE_EXTERNAL_PRIVATE_ENDPOINTS,
  payload,
});

export const loadExternalPrivateEndpoints = (groupId) => async (dispatch) => {
  const privateEndpoints = await fetchPrivateEndpoints(groupId);
  dispatch(updateExternalPrivateEndpoints(privateEndpoints));
};
