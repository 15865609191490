import { useEffect, useState } from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Button from '@leafygreen-ui/button';
import { H2 } from '@leafygreen-ui/typography';
import queryString from 'query-string';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation } from 'react-router-dom';

import { Search } from '@packages/types/auth/search';
import { WindowLocation } from '@packages/types/browser';

import ThirdPartyIntegrationLayout from '@packages/components/ThirdPartyIntegration/ThirdPartyIntegrationLayout';
import ThirdPartyIntegrationPage from '@packages/components/ThirdPartyIntegration/ThirdPartyIntegrationPage';

import * as api from 'js/common/services/api';
import * as errorHelper from 'js/common/services/authErrorHelper';
import { useAccountUser } from 'js/common/context/AccountUserContext';
import { mq, useViewportSize } from 'js/common/utils/mediaQueries';
import analytics, { FormType, PAGE_CALLS, SEGMENT_EVENTS } from 'js/common/utils/segmentAnalytics';

import { getEmailType } from 'js/auth/utils/analyticsUtils';
import colors from 'js/auth/utils/palette';

import HeaderLogo from './HeaderLogo';
import Layout from './Layout';
import {
  flexibleWidthButton as cancelButtonCss,
  Container,
  header,
  submitButton as submitButtonCss,
} from './styles/form';
import TermsOfServiceCheckbox from './TermsOfServiceCheckbox';

const Text = styled.p`
  font-size: 14px;
  color: ${colors.labelText};
  margin: 0;
`;

const ErrorMessage = styled.p`
  color: ${colors.warning};
`;

const footerCss = css(
  mq({
    marginTop: 24,
    button: {
      margin: 0,
      '&:last-child': {
        marginTop: [0, 8, 0, 0],
        marginLeft: [8, 0, 8, 8],
      },
    },
  })
);

interface TermsOfServiceProps {
  windowLocation: WindowLocation;
}

export default function TermsOfServicePage({ windowLocation = window.location }: TermsOfServiceProps) {
  const history = useHistory();

  const { accountUserParams, loadAccountUserParams } = useAccountUser();

  const location = useLocation();
  const { search } = location;
  const parsedSearchLocation = queryString.parse(search) as Search;
  const { isVercelIntegration } = parsedSearchLocation;

  useEffect(() => {
    async function fetchAccountUserParams() {
      // Make sure the submit button is disabled until the params have been loaded
      try {
        setSubmitting(true);

        await loadAccountUserParams();
        analytics.page(PAGE_CALLS.TERMS_OF_SERVICE, {});
      } finally {
        setSubmitting(false);
      }
    }

    fetchAccountUserParams();
  }, [search]);

  const [tosChecked, setTosChecked] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const isSubmitEnabled = tosChecked && !submitting;

  const onCancelSignup = async () => {
    analytics.track(SEGMENT_EVENTS.UX_ACTION_PERFORMED, {
      action: 'Cancelled Sign-up',
      context: 'ToS Page',
    });
    if (isVercelIntegration) {
      history.push(`/login/vercel${search}&signedOut=true`);
    } else {
      history.push(`/register${search}`);
    }
  };

  const onTosChecked = async (e) => {
    setTosChecked(e.target.checked);
    const action = e.target.checked ? 'ToS Checked' : 'ToS Unchecked';
    analytics.track(SEGMENT_EVENTS.UX_ACTION_PERFORMED, {
      action,
      context: 'ToS Page',
    });
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    setErrorMessage('');

    analytics.track(SEGMENT_EVENTS.FORM_SUBMITTED, {
      action: 'ToS Acceptance',
      context: 'ToS Page',
      email_type: getEmailType(accountUserParams.username),
      form_type: FormType.STANDARD,
    });

    try {
      const { redirectURI } = await api.auth.acceptTOS(search);
      windowLocation.assign(redirectURI);
    } catch ({ errorCode }) {
      const errorMessageStr = errorHelper.getErrorMessageFromCodeForRegistration(errorCode);
      setSubmitting(false);
      setErrorMessage(errorMessageStr);

      analytics.track(SEGMENT_EVENTS.TOS_ACCEPTANCE_FAILED, {
        action: 'ToS Acceptance',
        error_code: errorCode,
        error_message: errorMessageStr,
      });
    }
  };

  const isSmallViewport = useViewportSize('small');

  const cancelButton = (
    <Button variant="default" size="default" css={cancelButtonCss} type="button" onClick={onCancelSignup}>
      Cancel Signup
    </Button>
  );

  const submitButton = (
    <Button variant="primary" disabled={!isSubmitEnabled} css={submitButtonCss} size="default" type="submit">
      Submit
    </Button>
  );

  const firstButton = isSmallViewport ? submitButton : cancelButton;
  const secondButton = isSmallViewport ? cancelButton : submitButton;

  const renderTOS = () => {
    return (
      <>
        {!isVercelIntegration && (
          <>
            <HeaderLogo />
            <H2 css={header}>Accept Privacy Policy &amp; Terms of Service</H2>
          </>
        )}
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        <Text>Please acknowledge the following terms and conditions to finish creating your account.</Text>
        <TermsOfServiceCheckbox checked={tosChecked} onChange={onTosChecked} />
        <footer css={footerCss}>
          {firstButton}
          {secondButton}
        </footer>
      </>
    );
  };

  if (isVercelIntegration) {
    return (
      <ThirdPartyIntegrationPage>
        <ThirdPartyIntegrationLayout title="Accept Privacy Policy &amp; Terms of Service">
          <form onSubmit={(e) => onFormSubmit(e)} method="post">
            {renderTOS()}
          </form>
        </ThirdPartyIntegrationLayout>
      </ThirdPartyIntegrationPage>
    );
  }
  return (
    <>
      <Helmet title="Privacy Policy & Terms of Service" />
      <Layout contentPlacement="right">
        <Container onSubmit={(e) => onFormSubmit(e)} method="post">
          {renderTOS()}
        </Container>
      </Layout>
    </>
  );
}
