import fetchWrapper from '../fetchWrapper';

export default {
  getRules() {
    return fetchWrapper(`/admin/nds/logIngestion`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },
  createRule(data) {
    return fetchWrapper(`/admin/nds/logIngestion`, {
      method: 'POST',
      body: JSON.stringify(data),
    }).then((resp) => resp.json());
  },
  deleteRule(id) {
    return fetchWrapper(`/admin/nds/logIngestion/${id}`, {
      method: 'DELETE',
    }).then((resp) => resp.json());
  },
  getRuleStats(id) {
    return fetchWrapper(`/admin/nds/logIngestion/${id}/stats`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },
};
