import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { mq } from 'js/common/utils/mediaQueries';

import colors from 'js/auth/utils/palette';

const resetSuccess = {
  background: colors.resetSuccessBackground,
  border: `1px solid ${colors.resetSuccessBorder}`,
  padding: '15px 5px',
  borderRadius: '0',
  borderWidth: '1px',
  marginTop: '40px',
};

export const ResetMFASuccess = styled.div(
  mq({
    ...resetSuccess,
    width: ['auto', 'auto', '300px', 'auto'],
  })
);

export const ResetSuccessText = styled.span({
  color: colors.resetSuccessText,
  lineHeight: '21px',
  fontSize: '13px',
  letterSpacing: 'normal',
});

export const resetIcon = css({
  margin: '0 3px',
});

export const resetButton = css(
  mq({
    margin: ['24px 0 16px', '48px 0 24px', '48px 0 24px', '24px 0 16px'],
    width: ['auto', 'calc(100vw - 32px)', 'auto', 'auto'],
    height: [32, 45, 45, 32],
    fontSize: [15, 18, 18, 15],
    lineHeight: ['17px', '21px', '17px', '17px'],
    justifyContent: 'center',

    '&: focus': {
      outlineColor: '#158242',
    },
  })
);

export const resetSuccessLoginLink = css({
  textDecoration: 'none',
  color: `${colors.link}`,
});

export const HeaderText = styled.p(
  mq({
    color: colors.text,
    lineHeight: '21px',
    width: ['300px', 'calc(100vw - 50px)', '300px', '300px'],
    fontSize: '13px',
    letterSpacing: 'normal',
    marginBottom: '40px',
  })
);

export const resetSuccessBanner = css({
  padding: '15px',
  border: `1px solid ${colors.resetSuccessBorder}`,
  borderRadius: '5px',
  wordWrap: 'break-word',
  marginTop: '30px',
});
