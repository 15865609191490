import { SyntheticEvent, useEffect, useRef, useState } from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Button from '@leafygreen-ui/button';
import { Radio, RadioGroup } from '@leafygreen-ui/radio-group';
import TextInput from '@leafygreen-ui/text-input';

import { StyledButton } from '@packages/types/leafygreen-emotion';

import AutoAdvanceInputs from '@packages/components/AutoAdvanceInputs';
import { spinner } from '@packages/components/styles/multiFactorAuth';

import { user } from 'js/common/services/api';
import { getErrorMessageFromCode } from 'js/common/services/authErrorHelper';
import { mq } from 'js/common/utils/mediaQueries';

import { AutoAdvanceInputsContainer, Container, ErrorMessage, Step } from './styles/mfa';

const PhoneNumberInput = styled(TextInput)(() =>
  mq({
    width: ['255px', 'calc(100vw - 140px)', 'calc(100vw - 530px)', '255px'],
    marginBottom: '16px',
  })
);

const ButtonContainer = styled.div(() =>
  mq({
    display: 'flex',
    marginBottom: '16px',
    height: '27px',
    width: ['255px', 'calc(100vw - 140px)', 'calc(100vw - 530px)', '255px'],
  })
);

const VerificationButton = styled<StyledButton>(Button)<React.ComponentProps<'button'>>(() => ({
  flex: 1,
  justifyContent: 'center',
  height: '22px',
  fontSize: '10px',
  fontWeight: 'bold',
  textTransform: 'uppercase',

  '&:first-of-type': {
    marginRight: '8px',
  },
}));

const ConfirmButton = styled<StyledButton>(Button)<React.ComponentProps<'button'>>(() =>
  mq({
    flex: ['none', '1', '1', 'none'],
    justifyContent: 'center',
    height: ['27px', '40px', '32px', '27px'],
    marginTop: '16px',

    '&:first-of-type': {
      marginRight: '8px',
    },
  })
);

const image = css`
  margin-top: 4px;
  margin-left: 10px;
  height: 20px;
  width: 20px;
`;

const spinnerImage = css`
  ${image};
  animation: ${spinner} 1.3s linear infinite;
`;

interface PhoneProps {
  active?: boolean;
  updateMfaData: Function;
  onAuthExpired: Function;
  onSuccess?: Function;
  onCancel?: (e: SyntheticEvent) => void;
  setupBackupPhone?: boolean;
  centralUrl?: string;
}

type ProgressIcon = '' | 'spinner' | 'complete';

export default function Phone({
  onSuccess = () => {},
  onCancel = () => {},
  updateMfaData,
  onAuthExpired,
  setupBackupPhone = false,
  active = false,
  centralUrl = '',
}: PhoneProps) {
  const [phoneIcon, setPhoneIcon] = useState<ProgressIcon>('');
  const [codeIcon, setCodeIcon] = useState<ProgressIcon>('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneExtension, setPhoneExtension] = useState('');
  const [callType, setCallType] = useState('sms');
  const [showSecondStep, setShowSecondStep] = useState(false);
  const [errorCode, setErrorCode] = useState('');

  const phoneInput = useRef<HTMLInputElement>(null);
  const autoAdvanceInputs = useRef<AutoAdvanceInputs>(null);

  const requireVerification = !setupBackupPhone;
  const useVoice = callType === 'voice';

  const resetState = () => {
    setPhoneIcon('');
    setCodeIcon('');
    setPhoneNumber('');
    setPhoneExtension('');
    setErrorCode('');
    setCallType('sms');
    setShowSecondStep(false);
  };

  useEffect(() => {
    if (active && phoneInput.current) {
      phoneInput.current.focus();
    }
    resetState();
  }, [active]);

  const sendPhoneCode = async (e: SyntheticEvent) => {
    e.preventDefault();
    setPhoneIcon('spinner');

    try {
      if (setupBackupPhone) {
        await user.multiFactorSendBackupSMS({ backupNumber: phoneNumber, centralUrl });
      } else {
        await user.multiFactorSendUpdateCode({
          mobileNumber: phoneNumber,
          voice: useVoice,
          extension: useVoice ? phoneExtension : null,
          centralUrl,
        });
      }

      setPhoneIcon('complete');
      setShowSecondStep(true);
      setErrorCode('');
    } catch ({ errorCode }) {
      setPhoneIcon('');
      setShowSecondStep(false);
      setErrorCode(errorCode);
    }
  };

  const verifyCodeAndEnable2fa = async ({ value: authCode }: { value: string | number }) => {
    setCodeIcon('spinner');

    try {
      await Promise.all([
        user.multiFactorCheckUpdateCode({ authCode, centralUrl }),
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ authCode: any; phone: string; ... Remove this comment to see the full error message
        user.multiFactorAuthUpdate({
          authCode,
          phone: phoneNumber,
          voice: useVoice,
          extension: useVoice ? phoneExtension : null,
          centralUrl,
        }),
      ]);

      await updateMfaData();
      setCodeIcon('complete');
      setErrorCode('');
      onSuccess();
    } catch (e) {
      const { errorCode } = e;
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      autoAdvanceInputs.current.reset();
      setCodeIcon('');
      setErrorCode(errorCode);
      onAuthExpired(e);
    }
  };

  const saveChangesWithoutVerification = async () => {
    setCodeIcon('spinner');

    try {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ backupPhone: string; backupPho... Remove this comment to see the full error message
      await user.multiFactorAuthUpdate({
        backupPhone: phoneNumber,
        backupPhoneExtension: useVoice ? phoneExtension : null,
        centralUrl,
      });
      await updateMfaData();
      setCodeIcon('complete');
      onSuccess();
    } catch (e) {
      if (!onAuthExpired(e)) {
        const { errorCode } = e;
        setErrorCode(errorCode);
      }
    }
  };

  return (
    <>
      {errorCode && <ErrorMessage data-testid="mfaError">{getErrorMessageFromCode(errorCode)}</ErrorMessage>}
      <Container>
        <Step>
          <form onSubmit={sendPhoneCode} data-testid="setupPhoneForm" noValidate>
            <PhoneNumberInput
              value={phoneNumber}
              label="1. Enter your phone number"
              onChange={(e) => setPhoneNumber(e.target.value)}
              ref={phoneInput}
              name="phoneNumber"
            />
            <ButtonContainer>
              <VerificationButton type="submit" name="verify" variant="primary">
                Verify
              </VerificationButton>
              <VerificationButton type="button" name="cancel" onClick={onCancel}>
                Cancel
              </VerificationButton>
              {phoneIcon === 'spinner' && <img src="/static/images/mfa/spinner.svg" alt="spinner" css={spinnerImage} />}
              {phoneIcon === 'complete' && <img src="/static/images/mfa/check.svg" alt="check" css={image} />}
            </ButtonContainer>
          </form>
          <>
            <span>Send codes via:</span>
            <RadioGroup value={callType} onChange={(e) => setCallType(e.target.value)} data-testid="callType">
              <Radio value="sms">&nbsp;Text message (SMS)</Radio>
              <Radio value="voice">&nbsp;Voice call (US/Canada only)</Radio>
            </RadioGroup>
            {useVoice && (
              <PhoneNumberInput
                name="phoneExt"
                label="Extension:"
                value={phoneExtension}
                onChange={(e) => setPhoneExtension(e.target.value)}
              />
            )}
            {!requireVerification && (
              <ButtonContainer>
                <ConfirmButton
                  type="button"
                  variant="primary"
                  name="saveChanges"
                  disabled={!showSecondStep}
                  onClick={saveChangesWithoutVerification}
                >
                  Save Changes
                </ConfirmButton>
                <ConfirmButton type="button" name="skip" onClick={onCancel}>
                  Skip
                </ConfirmButton>
              </ButtonContainer>
            )}
          </>
        </Step>
        {requireVerification && (
          <Step>
            <span css={{ fontWeight: 'bold' }}>2. Verify your code</span>
            {showSecondStep && (
              <AutoAdvanceInputsContainer>
                <AutoAdvanceInputs
                  inputsType="code"
                  onComplete={verifyCodeAndEnable2fa}
                  icon={codeIcon}
                  ref={autoAdvanceInputs}
                />
              </AutoAdvanceInputsContainer>
            )}
          </Step>
        )}
      </Container>
    </>
  );
}
