import { useEffect } from 'react';

import { MarketplaceRegistrationCookie } from '@packages/types/billing';

import analytics, { PAGE_CALLS } from 'js/common/utils/segmentAnalytics';

import { useQuoraTrackingPixel } from './useQuoraTrackingPixel';

export default function useLoginAnalytics(marketplaceRegistrationCookie: MarketplaceRegistrationCookie | null) {
  useEffect(() => {
    // Segment page call viewing the login page
    analytics.page(PAGE_CALLS.SIGN_IN, {
      isFromMp: !!marketplaceRegistrationCookie,
      marketplaceSource: marketplaceRegistrationCookie?.partnerType ?? '',
    });
  }, []);

  useQuoraTrackingPixel();
}
