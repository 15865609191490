// Bugsnag Version 6 docs:
// https://docs.bugsnag.com/platforms/javascript/legacy/reporting-handled-errors/
import {
  type BugsnagOptions,
  BugsnagSeverity,
  CloudTeams,
  ErrorMaybe,
  type UseBugsnagNotifySpecs,
} from '@packages/types/bugsnag';

// Re-export types for convenience
export { CloudTeams, BugsnagOptions, BugsnagSeverity, UseBugsnagNotifySpecs };

interface GenericObject {
  [x: string]: any;
}

/**
 * Bugsnag only accepts Errors and strings as its error arg. In our fetchWrapper (and potentially elsewhere),
 * entities other than Errors are thrown.  This function will convert whatever is fed into sendError into an actual error.
 */
export const coerceError = (errorMaybe: ErrorMaybe): { error: Error; rawObject: GenericObject | null } => {
  // If coercion fails, we'll default to this error:
  let error: Error = new Error('Unknown error');
  let rawObject: GenericObject | null = null;

  if (errorMaybe instanceof Error) {
    error = errorMaybe;
  } else {
    // If an object was passed in rather than an error, we'll collect this to send along as metadata
    rawObject = errorMaybe;

    if (typeof errorMaybe !== 'object') {
      // Let the "Unknown error" pass through
    } else if ('error' in errorMaybe) {
      // fetchWrapper can generate objects with an error param in some cases.
      // The following shouldn't be necessary, but TS won't believe the nested error is defined unless we do this.
      const { error: nestedError } = errorMaybe;
      if (nestedError) {
        error = nestedError;
      }
    } else if ('requestUrl' in errorMaybe && 'errorCode' in errorMaybe) {
      // fetchWrapper can generate objects matching this signature:
      error = new Error(`Failed to reach ${errorMaybe.requestUrl} due to ${errorMaybe.errorCode}`);
    }

    // Message to Bugsnag user that additional context is available:
    error.message = `${error.message}. See metaData object for related information.`;
  }

  return { error, rawObject };
};

/**
 * A function to check for a list of known Atlas Growth errors that we would like to downgrade
 * from a severity ERROR. Possible downgrade options are WARNING | INFO
 */
function getErrorSeverity(severity: BugsnagSeverity, rawObject: GenericObject | null, error: Error): BugsnagSeverity {
  if (severity !== BugsnagSeverity.ERROR) return severity;

  // the default case
  let finalSeverity = BugsnagSeverity.ERROR;

  switch (rawObject?.errorCode) {
    case 'IP_NOT_USER_EDITABLE':
    case 'NO_AUTHORIZATION':
    case 'NETWORK_ERROR':
      finalSeverity = BugsnagSeverity.WARNING;
      break;
    case 'SERVER_ERROR':
      if ([500, 503].includes(rawObject?.statusCode)) finalSeverity = BugsnagSeverity.WARNING;
      break;
    default:
      finalSeverity = BugsnagSeverity.ERROR;
  }

  const warningErrorMessages = [
    /can't access dead object/,
    /Experiment.*aborted/i,
    // Loading Chunk Failed error has been downgraded to a warning, our current hypothesis is that is caused by
    // idiosyncratc behavior on the client's network requests
    // Hopefully, CLOUDP-168300 will help resolve these
    /Loading chunk.*failed/i,
    // This error message is intentional and informational to the user, but we do not want to be alerted everytime the user sees this so the severity is downgraded
    /Cannot upgrade shared cluster while Realm Sync is setup/,
    // handled error from CreateUser flow
    /user should not contain any whitespace/i,
  ];

  if (warningErrorMessages.some((msg) => error.message.match(msg))) {
    finalSeverity = BugsnagSeverity.WARNING;
  }

  return finalSeverity;
}

/**
 * We'd like to sample certain abundant errors so as to not eat up too much quota.
 * This function returns true if the error occurrence should be in the sample sent to Bugsnag.
 */
function checkIfErrorInSample(error: Error, rawObject: GenericObject | null): boolean {
  let isInSample = true;

  // these error patterns can occur from the 1s API timeout
  const sdkSamplingErrors = [
    /Experiment.*aborted/i,
    /Experiment.*Experiment data was not fetched or entity is not in experiment/i,
  ];

  if (sdkSamplingErrors.some((msg) => error.message.match(msg))) {
    // sampling at 1/1000
    isInSample = Math.random() < 0.001;
  }

  // backend errors from CreateUser flow that we don't want to send to Bugsnag
  const createUserFilteredBackendErrors = [
    /DUPLICATE_DATABASE_USERNAME/i,
    /USERNAME_INVALID/i,
    /USERNAME_NOT_FOUND/i,
    /PASSWORD_TOO_COMMON/i,
  ];

  if (createUserFilteredBackendErrors.some((msg) => error.message.match(msg))) {
    // filter these out
    isInSample = false;
  }

  // noisy errors from AutomationChangesClient/Api that we want to sample while
  // we investigate root cause
  if (rawObject?.requestUrl?.includes('automation')) {
    // sampling at 1/100
    isInSample = Math.random() < 0.01;
  }

  return isInSample;
}

/**
 * A function that sends a properly-formed request to Bugsnag, and returns a promise
 * that resolves or rejects, dependent upon the success of the request.
 */
export function sendError({
  error: maybeError,
  team,
  metaData = {},
  severity = BugsnagSeverity.ERROR,
}: UseBugsnagNotifySpecs): Promise<void> {
  const browserErrorTrackingEnabled = (window as any).REQUEST_PARAMS?.browserErrorTrackingEnabled;
  if (!browserErrorTrackingEnabled) {
    return Promise.resolve();
  }
  // Convert non-errors into errors, so that Bugsnag can parse them:
  const { error, rawObject } = coerceError(maybeError);

  // Atlas Growth-specific logic
  if (team === CloudTeams.AtlasGrowth) {
    // Check Errors against a list of known Atlas Growth errors that need downgrading
    severity = getErrorSeverity(severity, rawObject, error);

    // check sampled/filtered errors
    if (!checkIfErrorInSample(error, rawObject)) {
      // early exit here if this is a sampled error and this error was not one of the lucky samples
      return Promise.resolve();
    }
  }

  // If an object was passed in rather than an error, we'll collect this to send along as metadata:
  if (rawObject) {
    metaData.rawObject = rawObject;
  }

  // Ensure team is included in request:
  metaData.team = team;

  const options: BugsnagOptions = {
    metaData,
    severity,
  };

  // Return a promise which resolves when Bugsnag request responds
  return new Promise<void>((resolve, reject) => {
    if (!window.bugsnagClient) {
      // Alert that Bugsnag wasn't loaded when expected
      error.message = `Bugsnag not loaded! Original error message: ${error.message}`;
      console.error(error);
      reject(error);
    } else {
      // Send request to Bugsnag and resolve/reject upon response
      const cb = (err: any, report: any) => (err ? reject(err) : resolve(report));
      try {
        window.bugsnagClient.notify(error, options, cb);
      } catch (e) {
        reject(e);
      }
    }
  });
}
