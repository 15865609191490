import { FormEvent, useEffect, useState } from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Icon from '@leafygreen-ui/icon';
import { palette } from '@leafygreen-ui/palette';
import { Body, Overline } from '@leafygreen-ui/typography';
import queryString from 'query-string';

import { Search } from '@packages/types/auth/search';

import ThirdPartyIntegrationLayout from '@packages/components/ThirdPartyIntegration/ThirdPartyIntegrationLayout';
import ThirdPartyIntegrationPage from '@packages/components/ThirdPartyIntegration/ThirdPartyIntegrationPage';

import * as api from 'js/common/services/api';
import { useAppUserParams } from 'js/common/context/AppUserContext';
import { useRequestParams } from 'js/common/context/RequestParamsContext';
import { CloudTeams, sendError } from 'js/common/utils/bugsnag';

import { useGoogleAnalytics } from 'js/auth/hooks/useGoogleAnalytics';
import { useQuoraTrackingPixel } from 'js/auth/hooks/useQuoraTrackingPixel';
import { sendAnalyticsEvents, SignupMethod } from 'js/auth/utils/analyticsUtils';
import colors from 'js/auth/utils/palette';

// images
import mongoCircle from './images/mongo_circle.svg';

interface Props {
  location?: Pick<Location, 'search'>;
  windowLocation?: Pick<Location, 'assign'>;
}

const BodyText = styled(Body)`
  font-size: 16px;
  line-height: 28px;
  margin: 24px 0;
  font-weight: 400;
  a {
    color: ${colors.link};
    text-decoration: none;
  }
`;

const GraphicContainer = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr 3fr;
`;

const GraphicContainerBody = styled.div`
  word-break: break-word;
`;

const GraphicArrowContainer = styled.div`
  margin-top: 116px;
  padding-left: 16px;
`;

const GraphicLogo = styled.img`
  height: 80px;
  width: 80px;
  margin-bottom: 12px;
`;

const GraphicTitle = styled<React.ComponentType<Omit<React.ComponentProps<typeof Overline>, 'as'>>>(Overline)`
  color: ${palette.green.dark2};
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  margin-bottom: 5px;
`;

const GraphicName = styled(Body)`
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 5px;
`;

const GraphicID = styled(Body)`
  color: ${palette.gray.base};
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 5px;
`;

const errorMessageStyle = css`
  color: ${colors.warning};
  text-align: center;
`;

export default function VercelConfirmationPage({
  location: { search } = { search: '' },
  windowLocation = window.location,
}: Props) {
  const [acknowledgeDisabled, setAcknowledgeDisabled] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');
  const parsedSearchLocation = queryString.parse(search) as Search;
  const {
    orgName,
    orgId,
    vercelUserId: vercelUserIdQueryParam,
    vercelEmail: vercelEmailQueryParam,
    vercelTeamId: vercelTeamIdQueryParam,
    vercelTeamName: vercelTeamNameQueryParam,
    isNewVercelOrg,
    signupMethod,
    signupSource,
  } = parsedSearchLocation;
  const {
    centralUrl,
    vercelUserId: vercelUserIdRequestParam,
    vercelEmail: vercelEmailRequestParam,
    vercelTeamId: vercelTeamIdRequestParam,
    vercelTeamName: vercelTeamNameRequestParam,
  } = useRequestParams();

  const vercelUserId = vercelUserIdQueryParam ?? vercelUserIdRequestParam;
  const vercelEmail = vercelEmailQueryParam ?? vercelEmailRequestParam;
  const vercelTeamId = vercelTeamIdQueryParam ?? vercelTeamIdRequestParam;
  const vercelTeamName = vercelTeamNameQueryParam ?? vercelTeamNameRequestParam;
  const isNewOrg = isNewVercelOrg === 'true';

  const displayOrgName = (orgName as string).replace(/\+/g, ' ');
  const displayTeamName = (vercelTeamName as string)?.replace(/\+/g, ' ');

  const { appUserParams, loadAppUserParams } = useAppUserParams();

  useEffect(() => {
    loadAppUserParams();
    //TODO: CLOUDP-104556 - Remove whatever was able to be moved to the backend
    if (signupMethod !== SignupMethod.GOOGLE && signupSource) {
      sendAnalyticsEvents(search, null);
    }
  }, []);

  useQuoraTrackingPixel();
  useGoogleAnalytics(parsedSearchLocation, appUserParams);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setAcknowledgeDisabled(true);
    try {
      if (orgId && vercelUserId) {
        await api.organization.confirmVercelIntegration({
          orgId,
          vercelUserId,
          centralUrl,
        });
        windowLocation.assign(
          isNewVercelOrg === 'true'
            ? `/account/vercel/cluster/create${search}`
            : `/account/vercel/cluster/link${search}&username=${appUserParams.username}`
        );
      } else {
        const error = new Error(
          `Missing orgId:${orgId} or vercelUserId:${vercelUserId} in Vercel integration. Please contact MongoDB customer service to help you resolve!`
        );
        sendError({ error, team: CloudTeams.CoreIam });
        throw error;
      }
    } catch (error) {
      sendError({ error, team: CloudTeams.CoreIam });
      setErrorMessage(error.message);
      setAcknowledgeDisabled(false);
    }
  };

  return (
    <ThirdPartyIntegrationPage pageTitle="Vercel Confirmation">
      <ThirdPartyIntegrationLayout
        title="Confirm Access to Your Organization "
        isForm
        formProps={{
          onSubmit: handleSubmit,
          buttonText: 'I Acknowledge',
          disabled: acknowledgeDisabled,
        }}
      >
        {errorMessage && <p css={errorMessageStyle}>{errorMessage}</p>}
        <BodyText>
          {isNewOrg && 'A MongoDB Atlas organization has been created.'} I acknowledge that I am giving Vercel access to
          my Atlas Organization.
        </BodyText>
        <GraphicContainer>
          <GraphicContainerBody>
            <GraphicLogo src="/static/images/vercel-icon.svg" alt="Vercel logo" />
            <GraphicTitle>vercel account</GraphicTitle>
            <GraphicName>&quot;{displayTeamName ?? vercelEmail}&quot;</GraphicName>
            <GraphicID>(ID: {vercelTeamId || vercelUserId})</GraphicID>
          </GraphicContainerBody>
          <GraphicArrowContainer>
            <Icon glyph="ArrowRight" size={40} />
          </GraphicArrowContainer>
          <GraphicContainerBody>
            <GraphicLogo src={mongoCircle} alt="MongoDB logo" />
            <GraphicTitle>atlas organization</GraphicTitle>
            <GraphicName>&quot;{displayOrgName}&quot;</GraphicName>
            <GraphicID>(ID: {orgId})</GraphicID>
          </GraphicContainerBody>
        </GraphicContainer>
        <BodyText>
          In order to remove this integration access to my MongoDB Atlas organization, I understand that I will need to
          manually take action to{' '}
          <a href="https://vercel.com/docs/integrations/manage-integration" target="_blank" rel="noreferrer">
            uninstall this Vercel Integration
          </a>
          .
        </BodyText>
      </ThirdPartyIntegrationLayout>
    </ThirdPartyIntegrationPage>
  );
}
