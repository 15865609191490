import { BackupType, BasicClusterEventProperties, TierType } from '@packages/types/clusters/clusterTracking';
import { ClusterDescriptionModel as TypedClusterDescriptionModel } from '@packages/types/nds/clusterDescription';
import { ClusterDescription } from '@packages/types/nds/clusterDescription';
import { ProcessArgs } from '@packages/types/nds/ProcessArgs';
import {
  AnalyticsInstanceSizes,
  BackingCloudProvider,
  CloudProvider,
  InstanceSize,
  InstanceSizes,
} from '@packages/types/nds/provider';

import * as api from 'js/common/services/api';
// analytics
import analytics, { OptionsProperties, SEGMENT_EVENTS, SegmentEvents } from 'js/common/utils/segmentAnalytics';
import { toCloudProvider } from 'js/project/nds/common/utils/cloudProviderUtils';

import * as clusterDescriptionUtils from 'js/project/nds/clusters/util/clusterDescriptionUtils';
import ClusterDescriptionModel from 'js/project/nds/clusters/models/ClusterDescription';
import replicationSpecListUtils from 'js/project/nds/clusters/util/replicationSpecListUtils';

interface GetBasicClusterEventPropertiesProps {
  // TODO: replace "any" with ClusterDescriptionModel type when it exists
  clusterDescription: ClusterDescription | any;
  processArgs?: ProcessArgs;
}

interface BroadcastClusterEventProps {
  segmentEvent: SegmentEvents;
  clusterDescription: ClusterDescription;
  processArgs: ProcessArgs;
  hourlyCost: string;
  context: string;
  options: OptionsProperties;
}

interface BroadcastDatabaseUserEventProps {
  segmentEvent: SegmentEvents;
  userType: string;
  isTemporaryUser: boolean;
  options: OptionsProperties;
}

interface DatabaseEventProperties {
  user_type: string;
  is_temporary: boolean;
}

interface BroadcastLiveImportEventProps {
  segmentEvent: SegmentEvents;
  sslEnabled?: boolean;
  caFileProvided?: boolean;
  // TODO: replace "any" with ClusterDescriptionModel type when it exists
  clusterDescription: any | ClusterDescription;
  options?: OptionsProperties;
}

function isFreeTier(instanceSize: string) {
  return instanceSize === InstanceSizes.M0 ? TierType.FREE : TierType.PAID;
}

function getBasicClusterEventProperties({
  clusterDescription,
  processArgs,
}: GetBasicClusterEventPropertiesProps): BasicClusterEventProperties {
  const clusterDesc: ClusterDescription =
    clusterDescription instanceof ClusterDescriptionModel ? clusterDescription.toJSON() : clusterDescription;

  const clusterInstanceSize = clusterDesc.replicationSpecList[0]?.regionConfigs[0]?.electableSpecs?.instanceSize;
  const clusterCloudProvider = clusterDesc.replicationSpecList[0]?.regionConfigs[0]?.cloudProvider;
  const clusterAutoScaling = replicationSpecListUtils.getFirstAutoScaling(clusterDesc.replicationSpecList);
  const isAnyBackupEnabled = clusterDesc.backupEnabled || clusterDesc.diskBackupEnabled;
  const isBiConnectorEnabled = clusterDesc.biConnector?.enabled;

  const clusterParams: BasicClusterEventProperties = {
    region: replicationSpecListUtils.getPreferredRegionName(clusterDesc.replicationSpecList[0]),
    instance_size: clusterInstanceSize,
    cloud_provider: clusterCloudProvider,
    tier: isFreeTier(clusterInstanceSize),
    global_cluster: clusterDesc.replicationSpecList.length > 1,
    disk_size_gb: clusterDesc.diskSizeGB,
    auto_expand_storage: clusterAutoScaling?.diskGB.enabled,
    autoIndex: clusterAutoScaling?.autoIndex?.enabled,
    db_version: clusterDesc.mongoDBVersion,
    backup_selected: isAnyBackupEnabled,
    sharding: clusterDescriptionUtils.isShardedCluster(clusterDesc.clusterType),
    shard_count: replicationSpecListUtils.getTotalShards(clusterDesc.replicationSpecList),
    BI_connector: isBiConnectorEnabled,
    encryption_key_management: clusterDesc.encryptionAtRestProvider !== 'NONE',
    termination_protection_enabled: clusterDesc.terminationProtectionEnabled,
    cross_region: clusterDesc.replicationSpecList.some((spec) => replicationSpecListUtils.hasMultipleRegions(spec)),
    cluster_name: clusterDesc.name,
  };

  if (clusterCloudProvider === CloudProvider.AWS) {
    clusterParams.IOPS_speed = clusterDesc.replicationSpecList[0]?.regionConfigs[0]?.electableSpecs?.diskIOPS;
  }

  if (clusterCloudProvider === CloudProvider.SERVERLESS) {
    clusterParams.cloud_provider = toCloudProvider(
      clusterDesc.replicationSpecList[0]?.regionConfigs[0]?.electableSpecs?.backingProvider as BackingCloudProvider
    );
    clusterParams.instance_size = AnalyticsInstanceSizes.SERVERLESS;
  }

  if (isAnyBackupEnabled) {
    clusterParams.backup_option = clusterDesc.diskBackupEnabled ? BackupType.SNAPSHOTS : BackupType.CONTINUOUS;
  }
  if (isBiConnectorEnabled) {
    clusterParams.BI_node_type = clusterDesc.biConnector.readPreference;
  }
  if (processArgs) {
    clusterParams.key_index_limit = processArgs.failIndexKeyTooLong;
    clusterParams.require_indexes = processArgs.noTableScan;
    clusterParams.server_side_JS = processArgs.javascriptEnabled;
  }

  const isClusterTierAutoscalingEnabled = clusterAutoScaling?.compute.enabled;
  if (isClusterTierAutoscalingEnabled) {
    const { scaleDownEnabled, minInstanceSize, maxInstanceSize } = clusterAutoScaling.compute;
    clusterParams.auto_scaling_compute_enabled = true;
    clusterParams.auto_scaling_compute_scaledown_enabled = scaleDownEnabled;
    if (maxInstanceSize) {
      clusterParams.auto_scaling_compute_max_instance = maxInstanceSize;
    }
    if (minInstanceSize) {
      clusterParams.auto_scaling_compute_min_instance = minInstanceSize;
    }
  }

  if (clusterDesc.uniqueId) {
    clusterParams.cluster_unique_id = clusterDesc.uniqueId;
  }
  return clusterParams;
}

function broadcastClusterEvent({
  segmentEvent,
  clusterDescription,
  processArgs,
  hourlyCost,
  context,
  options = {},
}: BroadcastClusterEventProps): void {
  const clusterEventProperties = getBasicClusterEventProperties({
    clusterDescription,
    processArgs,
  });
  clusterEventProperties.hourly_cost = hourlyCost;
  clusterEventProperties.context = context;
  analytics.track(segmentEvent, clusterEventProperties, options);
}

function broadcastDatabaseUserEvent({
  segmentEvent,
  userType,
  isTemporaryUser,
  options = {},
}: BroadcastDatabaseUserEventProps): void {
  const databaseEventProperties: DatabaseEventProperties = {
    user_type: userType,
    is_temporary: isTemporaryUser,
  };
  analytics.track(segmentEvent, databaseEventProperties, options);
}

function broadcastLiveImportEvent({
  segmentEvent,
  sslEnabled,
  caFileProvided,
  clusterDescription,
  options = {},
}: BroadcastLiveImportEventProps): void {
  const clusterDesc =
    clusterDescription instanceof ClusterDescriptionModel ? clusterDescription.toJSON() : clusterDescription;
  const { groupId, name } = clusterDesc;
  api.nds.clusterDescriptions.getProcessArgs(groupId, name).then((processArgs) => {
    const clusterEventProperties = getBasicClusterEventProperties({
      clusterDescription,
      processArgs,
    });
    if (segmentEvent === SEGMENT_EVENTS.CREDENTIALS_PROVIDED) {
      clusterEventProperties.ssl_enabled = !!sslEnabled;
      clusterEventProperties.ca_file_provided = !!caFileProvided;
    }
    analytics.track(segmentEvent, clusterEventProperties, options);
  });
}

function getNormalizedInstanceSize(
  clusterDescription: TypedClusterDescriptionModel
): InstanceSize | AnalyticsInstanceSizes.SERVERLESS {
  return clusterDescription.isServerless() ? AnalyticsInstanceSizes.SERVERLESS : clusterDescription.getInstanceSize();
}

export default {
  getBasicClusterEventProperties,
  broadcastClusterEvent,
  broadcastDatabaseUserEvent,
  broadcastLiveImportEvent,
  isFreeTier,
  getNormalizedInstanceSize,
};
