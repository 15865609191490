import { CloudProvider } from '@packages/types/nds/provider';
import { Region } from '@packages/types/nds/region';

import fetchWrapper from '../fetchWrapper';

export function getContainers(groupId: string) {
  return fetchWrapper(`/nds/${groupId}/containers`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function putContainer(groupId: string, data) {
  return fetchWrapper(`/nds/${groupId}/containers`, {
    method: 'PUT',
    body: JSON.stringify(data),
  }).then((resp) => resp.json());
}

export interface ContainerDefaultResponse {
  atlasCidrBlock: string;
  regions: Array<Region>;
}

export function getContainerDefaults(groupId: string, provider: CloudProvider): Promise<ContainerDefaultResponse> {
  return fetchWrapper(`/nds/${groupId}/containers/${provider.toLowerCase()}/defaults`, { method: 'GET' }).then((resp) =>
    resp.json()
  );
}
