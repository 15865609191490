import { useEffect, useRef } from 'react';

import { useRequestParams } from 'js/common/context/RequestParamsContext';

import { triggerQuoraTrackingPixel } from 'js/auth/utils/quoraTrackingPixel';

export function useQuoraTrackingPixel() {
  const { analyticsEnabled } = useRequestParams();

  const prevAnalytics = useRef<boolean>(false);

  useEffect(() => {
    if (analyticsEnabled && !prevAnalytics.current) {
      triggerQuoraTrackingPixel();
      prevAnalytics.current = true;
    }
  }, [analyticsEnabled]);
}
