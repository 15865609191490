import * as React from 'react';
import { Dispatch } from 'react';

import { FieldNames } from '@packages/types/auth/field';
import { Search } from '@packages/types/auth/search';
import { RegexValidation } from '@packages/types/regexValidation';

import * as api from 'js/common/services/api';
import { RequestParams } from 'js/common/context/RequestParamsContext';
import { updateCsrfHeaders } from 'js/common/services/api/fetchWrapper';
import { CountryCode } from 'js/common/utils/countries';
import analytics from 'js/common/utils/segmentAnalytics';

import { ReCaptchaHandles, ReCaptchaResponse } from 'js/auth/components/ReCaptcha';
import { ReducerAction, RegistrationFormFields, RequestData } from 'js/auth/types/registration';
import {
  ClearbitData,
  getClearbitData,
  RegistrationAnalyticsData,
  RegistrationError,
  SignupMethod,
  SignupSource,
  trackRegistrationData,
  trackRegistrationError,
} from 'js/auth/utils/analyticsUtils';
import { createInitialOrgAndProject } from 'js/auth/utils/postRegistrationUtils';

// These validations are based off those done on the backend API - see: https://tinyurl.com/h3rn8ja3
export const fieldValidationMap: { [key in FieldNames]?: RegexValidation } = {
  [FieldNames.USERNAME]: {
    regExp: /^.{1,}$/,
    errorMessage: 'Email Address must not be empty',
  },
  [FieldNames.FIRST_NAME]: {
    regExp: /^.{1,20}$/,
    errorMessage: 'First name must be between 1 and 20 characters long',
  },
  [FieldNames.LAST_NAME]: {
    regExp: /^.{1,20}$/,
    errorMessage: 'Last name must be between 1 and 20 characters long',
  },
  [FieldNames.PHONE_NUMBER]: {
    regExp: /^.{1,}$/,
    errorMessage: 'Phone number must not be empty',
  },
  [FieldNames.COMPANY]: {
    regExp: /^.{1,}$/,
    errorMessage: 'Company Name must not be empty',
  },
};

/**
 * Validates a value of a field against its predefined regex.
 * If no predefined regex exists for the field, the value of the field will be validated as any valid string.
 *
 * @param fieldName The name of the field as it exists in the FieldNames enum
 * @param fieldValue The value of the field
 * @returns a boolean indicating whether or not the value satisfies the defined regex for the field
 */
export function isUserInputValid(fieldName: FieldNames, fieldValue: string): boolean {
  // Default regex validation for user input is "any string"
  const regexForField: RegExp = fieldValidationMap[fieldName]?.regExp || /\S/;
  return regexForField.test(fieldValue);
}

const trackError = ({ errorCode, errorMessage: message, signUpSource }: RegistrationError) => {
  trackRegistrationError({
    errorCode,
    errorMessage: message,
    signUpSource,
    signUpMethod: SignupMethod.FORM,
  });
};

const trackAnalytics = ({
  anonymousId,
  analyticsSignupSource,
  requestParams,
  registrationFormFields,
  searchObject,
  clearbitData,
}: {
  anonymousId?: string;
  analyticsSignupSource: SignupSource;
  requestParams: RequestParams;
  registrationFormFields: RegistrationFormFields;
  searchObject: Search;
  clearbitData?: ClearbitData;
}) => {
  const { onPrem, analyticsEnabled, registrationExtraFields, eloquaSiteId, segmentDefaultUserId } = requestParams;

  const { firstName, lastName, username, company, country, jobResponsibility, phoneNumber } = registrationFormFields;

  if (onPrem || !analyticsEnabled) {
    return;
  }

  const data: RegistrationAnalyticsData = {
    first_name: firstName,
    last_name: lastName,
    email: username,
    signup_source: analyticsSignupSource,
    signup_method: SignupMethod.FORM,
  };

  if (registrationExtraFields.includes(FieldNames.COMPANY)) {
    data.company = company;
  }
  if (registrationExtraFields.includes(FieldNames.COUNTRY)) {
    data.country = country as CountryCode;
  }
  if (registrationExtraFields.includes(FieldNames.JOB_RESPONSIBILITY)) {
    data.job_function = jobResponsibility;
  }
  if (registrationExtraFields.includes(FieldNames.PHONE_NUMBER)) {
    data.phone = phoneNumber;
  }

  trackRegistrationData({
    eloquaSiteId,
    parsedSearchLocation: searchObject,
    anonymousId,
    userId: segmentDefaultUserId,
    data,
    clearbitData,
  });
};

export const onRegisterUser = async ({
  dispatch,
  clientState,
  requestParams,
  searchObject,
  reCaptchaRef,
  windowLocation,
  registrationFormFields,
}: {
  dispatch: Dispatch<ReducerAction>;
  clientState: object;
  requestParams: RequestParams;
  searchObject: Search;
  reCaptchaRef: React.RefObject<ReCaptchaHandles>;
  windowLocation: Pick<Location, 'assign' | 'href'>;
  registrationFormFields: RegistrationFormFields;
}) => {
  const { reCaptchaEnabledRegistration, onPrem } = requestParams;

  const { inviteToken, activationCode, groupname, orgname, jmp, c, kwd } = searchObject;

  dispatch({ type: 'formSubmitted' });

  const isInviteFlow = inviteToken !== undefined && inviteToken !== '';
  const hasGroupName = groupname !== undefined && groupname !== '';
  const hasOrgName = orgname !== undefined && orgname !== '';

  let registerCallResponse;

  const honeypot = registrationFormFields.honeypot;
  const clearbitData = getClearbitData();
  const analyticsSignupSource = clientState['signupSource'];

  const requestData: RequestData = {
    ...registrationFormFields,
    activationCode,
    c,
    kwd,
    jumpId: jmp,
    newGroup: !hasGroupName && !hasOrgName,
    honeypot: honeypot || null,
    clientState,
    clearbitData,
  };

  // For the invite flow, username and groupname are provided
  if (isInviteFlow) {
    requestData.groupName = groupname;
    requestData.invitationToken = inviteToken;
  }

  // Verify that the user is not robot if reCaptcha is enabled
  if (reCaptchaEnabledRegistration) {
    const { errorCode, response } = (reCaptchaRef.current && reCaptchaRef.current.verify()) as ReCaptchaResponse;

    requestData.response = response;
    if (errorCode !== '') {
      dispatch({ type: 'processCaptchaError', payload: errorCode });
      trackError({ errorCode });
      return;
    }
  }

  requestData.anonymousId = analytics.user()?.anonymousId();
  try {
    registerCallResponse = await api.user.userRegisterCall(requestData);
  } catch ({ errorCode, message }) {
    dispatch({ type: 'setError', payload: errorCode });

    if (reCaptchaEnabledRegistration && reCaptchaRef.current) {
      reCaptchaRef.current.reset();
    }

    trackError({ errorCode, errorMessage: message, signUpSource: analyticsSignupSource });
    return;
  }

  trackAnalytics({
    anonymousId: requestData.anonymousId,
    analyticsSignupSource,
    registrationFormFields,
    requestParams,
    searchObject,
    clearbitData,
  });

  const { loginRedirect, csrfToken, csrfTime } = registerCallResponse;
  updateCsrfHeaders({ csrfTime, csrfToken });

  // If using okta, sessionToken will be returned from userRegisterCall
  if (loginRedirect) {
    windowLocation.assign(loginRedirect);
    return;
  }

  /*
   For Onprem, the org and project should be created as part of the registration process, if the
   user isn't already being invited. Then let the backend handle where to redirect the user
  */
  if (onPrem) {
    if (isInviteFlow) {
      windowLocation.assign('/');
    } else {
      try {
        await createInitialOrgAndProject({ isOnPrem: onPrem });
      } catch (exception) {
        console.log('Some error happened creating initial org and project', exception);
      } finally {
        windowLocation.assign('/');
      }
    }
  }
};
