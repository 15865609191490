import { FactorMetadata, FactorProvider, FactorType, OktaMfaFactor } from '@packages/types/accountMultiFactorAuth';

import AuthenticatorImage from 'js/common/images/mfaFactors/authenticator_icon.svg';
import EmailImage from 'js/common/images/mfaFactors/email_icon.svg';
// images
import OktaVerifyImage from 'js/common/images/mfaFactors/okta_verify_app_icon.svg';
import SmsImage from 'js/common/images/mfaFactors/sms_icon.svg';
import VoiceImage from 'js/common/images/mfaFactors/voice.svg';
import WebAuthnImage from 'js/common/images/mfaFactors/webauthn_icon.svg';

export const multiFactorAuthSetupPath = '/security/mfa/setup';
export const multiFactorAuthPath = '/security/mfa';
export const multiFactorAuthRootUrl = '/account/profile/security/mfa';

// Update this when new factors are added
export const NUM_SUPPORTED_FACTORS = 3;

// Okta Verify Push also allows users to use a "backup" Okta Verify TOTP code, which are labeled as separate
// factors. We don't want to count this backup factor if it exists.
export const getUniqueFactors = (userFactors: Array<OktaMfaFactor>) => {
  return userFactors.filter(
    (factor) => !(factor.provider === FactorProvider.Okta && factor.factorType === FactorType.TokenSoftwareTotp)
  );
};

export const SETUP_ROOT_URL = `${multiFactorAuthRootUrl}/setup`;
export const VERIFY_ROOT_URL = `${multiFactorAuthRootUrl}/verify`;

// If a user hasn't set up more than 2 factors, then we redirect them to the MFA upsell page
export const getPostActivationRedirectUrl = ({
  userFactors,
  factorSuccessParam,
}: {
  userFactors: Array<OktaMfaFactor>;
  factorSuccessParam: string;
}) => {
  return getUniqueFactors(userFactors).length < 2
    ? `${SETUP_ROOT_URL}/another?success=${factorSuccessParam}`
    : '/account/profile/security';
};

export const redactEmail = (email) => {
  const splitEmail = email.split('@');
  const prefix = splitEmail[0];
  const postfix = splitEmail[1];
  if (prefix.length < 3) {
    return email;
  }

  return `${prefix[0]}...${prefix[prefix.length - 1]}@${postfix}`;
};

export const redactPhone = (phoneNumber) => {
  // Get last 4 digits
  return phoneNumber.slice(-4);
};

export const isLastAuthExpired = (errorCode) => {
  return errorCode === 'MULTI_FACTOR_AUTH_EXPIRED' || errorCode === 'PASSWORD_AUTH_EXPIRED';
};

// This order must stay the same for proper rendering
// Future factors are commented out
export const factorMetadata: Record<FactorType, FactorMetadata> = {
  [FactorType.Push]: {
    name: 'Okta Verify Mobile App',
    image: OktaVerifyImage,
    setupName: 'Okta Verify Mobile App',
    setupParam: 'oktaVerify',
    provider: FactorProvider.Okta,
  },
  [FactorType.TokenSoftwareTotp]: {
    name: 'Authenticator App',
    image: AuthenticatorImage,
    setupName: 'Authenticator App',
    setupParam: 'authenticator',
    provider: FactorProvider.Google,
  },
  [FactorType.WebAuthn]: {
    name: 'Security Key/Biometric',
    image: WebAuthnImage,
    setupName: 'Security Key/Biometric Authenticator',
    setupNote:
      'We support all keys that use the WebAuthn standard. Examples include YubiKey, TouchID (except for Firefox and Safari), and Microsoft Hello.',
    setupParam: 'webauthn',
    provider: FactorProvider.Fido,
  },
  [FactorType.Sms]: {
    name: 'SMS',
    image: SmsImage,
    setupName: 'SMS Authentication',
    setupParam: 'sms',
    provider: FactorProvider.Okta,
  },
  [FactorType.Email]: {
    name: 'Email',
    image: EmailImage,
    setupName: 'Email Authentication',
    setupParam: 'email',
    provider: FactorProvider.Okta,
  },
  [FactorType.Call]: {
    name: 'Voice',
    image: VoiceImage,
    setupName: 'Voice Authentication',
    setupParam: 'voice',
    provider: FactorProvider.Okta,
  },
};

export const getSetupInfo = (factorType: FactorType) => {
  const { setupName, setupParam } = factorMetadata[factorType];
  const setupText = `Set Up ${setupName}`;
  const setupHref = `${SETUP_ROOT_URL}/${setupParam}`;

  return { setupText, setupHref };
};

export const getFactorTypeFromSetupParam = (param?: string) => {
  if (!param) {
    return '';
  }
  const type = Object.keys(factorMetadata).find(
    (factorType) => factorMetadata[factorType].setupParam.toLowerCase() === param.toLowerCase()
  );
  return type || '';
};

export const getFactorId = (
  factorProvider: FactorProvider,
  factorType: FactorType,
  userFactors: Array<OktaMfaFactor>
): string | undefined => {
  return userFactors.find((factor) => factor.provider === factorProvider && factor.factorType === factorType)?.id;
};

/**
 * Converts any url safe characters in a base64 string to regular base64 characters
 * @param str base64 string that might contain url safe characters
 * @returns base64 formatted string
 */
const base64UrlSafeToBase64 = function (str) {
  return str.replace(new RegExp('_', 'g'), '/').replace(new RegExp('-', 'g'), '+');
};

/**
 * Converts base64 string to binary data view
 * @param str in base64 or base64UrlSafe format
 * @returns converted Uint8Array view of binary data
 */
export const strToBin = (str: string) => {
  return Uint8Array.from(atob(base64UrlSafeToBase64(str)), (c) => c.charCodeAt(0));
};

/**
 * Converts an ArrayBuffer object that contains binary data to base64 encoded string
 * @param bin ArrayBuffer object
 * @returns base64 encoded string
 */
export const binToStr = (bin: ArrayBuffer) => {
  return btoa(new Uint8Array(bin).reduce((s, byte) => s + String.fromCharCode(byte), ''));
};
