import { combineReducers } from 'redux';

import dataFederationQueryLimits from '@packages/redux/modules/nds/dataFederationQueryLimits';
import dls from '@packages/redux/modules/nds/dls';

import activeCluster from './activeCluster';
import auditLog from './auditLog';
import cloudProviderAccess from './cloudProviderAccess';
import clusterDescriptions from './clusterDescriptions';
import clusterDescriptionsFetchIsPending from './clusterDescriptionsFetchIsPending';
import clusterForm from './clusterForm';
import customRoles from './customRoles';
import dataLakes from './dataLakes';
import dataProcessingRegions from './dataProcessingRegions';
import dbUsers from './dbUsers';
import deletedClusterNamesWithActiveSnapshots from './deletedClusterNamesWithActiveSnapshots';
import diskBackup from './diskBackup';
import encryptionAtRest from './encryptionAtRest';
import geoZoneMap from './geoZoneMap';
import groupStatus from './groupStatus';
import ipAccessList from './ipAccessList';
import liveImports from './liveImports';
import onlineArchives from './onlineArchives';
import privateEndpoint from './privateEndpoint';
import privateNetworkSettings from './privateNetworkSettings';
import projectMaintenance from './projectMaintenance';
import projectSettings from './projectSettings';
import serverlessBackup from './serverlessBackup';
import serverlessInstanceForm from './serverlessInstanceForm';
import serverlessInstances from './serverlessInstances';
import serverlessMetrics from './serverlessMetrics';
import serverlessPrivateEndpoint from './serverlessPrivateEndpoint';
import streams from './streams';
import tenantBackup from './tenantBackup';
import userSecurity from './userSecurity';

export default combineReducers({
  activeCluster,
  deletedClusterNamesWithActiveSnapshots,
  clusterDescriptions,
  clusterDescriptionsFetchIsPending,
  clusterForm,
  cloudProviderAccess,
  diskBackup,
  dbUsers,
  tenantBackup,
  serverlessBackup,
  geoZoneMap,
  userSecurity,
  auditLog,
  encryptionAtRest,
  customRoles,
  dataLakes,
  dls,
  groupStatus,
  liveImports,
  ipAccessList,
  privateEndpoint,
  onlineArchives,
  serverlessInstances,
  serverlessInstanceForm,
  serverlessMetrics,
  streams,
  projectMaintenance,
  projectSettings,
  privateNetworkSettings,
  serverlessPrivateEndpoint,
  dataProcessingRegions,
  dataFederationQueryLimits,
});
