import $ from 'jquery';
import _ from 'underscore';

import { camelCaseToText } from '../stringUtils';

export default {
  validate(attributes, options) {
    const errors: $TSFixMe = {};

    this.onValidate(this.validations, attributes, errors, options);

    if (!_.isEmpty(errors)) {
      return errors;
    }

    return undefined;
  },

  // Validation Helper Methods
  validations: {
    notNull(fieldName, value, errors) {
      if (!errors[fieldName]) {
        if (value === null) {
          errors[fieldName] = `${camelCaseToText(fieldName)} should not be null`;
        }
      }
    },

    notUndefined(fieldName, value, errors) {
      if (!errors[fieldName]) {
        if (value === undefined) {
          errors[fieldName] = `${camelCaseToText(fieldName)} should not be undefined`;
        }
      }
    },

    notBlank(fieldName, value, errors) {
      if (!errors[fieldName]) {
        if ($.trim(value).length === 0) {
          errors[fieldName] = `${camelCaseToText(fieldName)} should not be blank`;
        }
      }
    },

    atLeastOneNotBlank(fieldNames, values, errors) {
      const foundField = _.find(values, (value) => {
        return $.trim(value).length !== 0;
      });

      if (!foundField) {
        fieldNames.forEach((fieldName) => {
          errors[fieldName] = `${camelCaseToText(fieldName)} should not be blank`;
        });
      }
    },

    matchesRegexp(fieldName, value, regexp, errors) {
      if (!errors[fieldName]) {
        if (!regexp.test(value)) {
          errors[fieldName] = `should match ${regexp.toString()}`;
        }
      }
    },

    validRegexp(fieldName, value, errors) {
      if (!errors[fieldName]) {
        try {
          RegExp(value);
        } catch (e) {
          errors[fieldName] = `not a valid regular expression: '${value}'`;
        }
      }
    },

    doesNotContainWhitespace(fieldName, value, errors) {
      if (!errors[fieldName]) {
        if (/\s/.test(value)) {
          errors[fieldName] = 'should not contain any whitespace';
        }
      }
    },

    notEquals(fieldName, value, unexpected, errors) {
      if (!errors[fieldName]) {
        if (value === unexpected) {
          errors[fieldName] = `should not be equal to ${unexpected}`;
        }
      }
    },

    isBoolean(fieldName, value, errors) {
      if (!errors[fieldName]) {
        if (!_.isBoolean(value)) {
          errors[fieldName] = `${camelCaseToText(fieldName)} should be a boolean`;
        }
      }
    },

    isNumeric(fieldName, value, opts, errors) {
      if (!errors[fieldName]) {
        if (!_.isNumber(value) || _.isNaN(value)) {
          errors[fieldName] = `${camelCaseToText(fieldName)} should be a number`;
        }

        if (opts.min !== undefined && value < opts.min) {
          errors[fieldName] = `${camelCaseToText(fieldName)} should be >= ${opts.min}`;
        }

        if (opts.max !== undefined && value > opts.max) {
          errors[fieldName] = `${camelCaseToText(fieldName)} should be <= ${opts.max}`;
        }
      }
    },

    sizeBounds(fieldName, value, opts, errors) {
      if (!errors[fieldName]) {
        if (opts.min !== undefined && value.length < opts.min) {
          errors[fieldName] = `${camelCaseToText(fieldName)} should have length >= ${opts.min}`;
        }

        if (opts.max !== undefined && value.length > opts.max) {
          errors[fieldName] = `${camelCaseToText(fieldName)} should have length <= ${opts.max}`;
        }
      }
    },

    areDatesOrdered(fieldName1, value1, fieldName2, value2, errors) {
      if (!errors[fieldName1]) {
        if (value1 >= value2) {
          errors[fieldName1] = `${fieldName1} should be a date earlier than ${fieldName2}`;
        }
      }
    },

    validCollection(fieldName, collection, errors, options: $TSFixMe = {}) {
      if (!errors[fieldName] && collection) {
        collection.each((item, index) => {
          if (item.isValid(options)) {
            return;
          }
          if (options.includeCollectionErrors) {
            Object.keys(item.validationError).forEach((attribute) => {
              const error = item.validationError[attribute];
              errors[`${fieldName} ${index + 1}'s ${attribute}`] = error;
            });
          } else {
            errors[fieldName] = `${camelCaseToText(fieldName)} should be valid`;
          }

          // don't exit early here, so all models are checked
        });
      }
    },

    valid(fieldName, model, errors, options) {
      if (!errors[fieldName] && model) {
        if (!model.isValid(options)) {
          errors[fieldName] = `${camelCaseToText(fieldName)} should be valid`;
        }
      }
    },
  },
};
