import { ExternalPrivateEndpoint } from '@packages/types/nds/privateNetworkSettings';
import { CloudProvider } from '@packages/types/nds/provider';

import fetchWrapper from '../fetchWrapper';

export function fetchPrivateEndpoints(groupId: string): Promise<Array<ExternalPrivateEndpoint>> {
  return fetchWrapper(`/nds/${groupId}/privateNetworkSettings/endpointIds`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function putPrivateLink(groupId: string, data: Array<ExternalPrivateEndpoint>) {
  return fetchWrapper(`/nds/${groupId}/privateNetworkSettings/endpointIds`, {
    method: 'PUT',
    body: JSON.stringify(data),
  }).then((resp) => resp.json());
}

export function fetchPrivateEndpointServices(groupId: string, provider: CloudProvider) {
  return fetchWrapper(`/nds/${groupId}/privateNetworkSettings/endpointServices/${provider}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function editEndpoint(
  groupId: string,
  data: ExternalPrivateEndpoint,
  existingEndpoints: Array<ExternalPrivateEndpoint>
) {
  return _modifyAndPutEndpoint(
    (endpoints: Array<ExternalPrivateEndpoint>) => {
      const filterEndpoints = endpoints.filter((e) => {
        return e.endpointId !== data.endpointId;
      });
      filterEndpoints.push(data);
      return filterEndpoints;
    },
    groupId,
    existingEndpoints
  );
}

export function addEndpoint(
  groupId: string,
  data: ExternalPrivateEndpoint,
  existingEndpoints: Array<ExternalPrivateEndpoint>
) {
  return _modifyAndPutEndpoint(
    (endpoints: Array<ExternalPrivateEndpoint>) => {
      if (endpoints.find((endpoint) => endpoint.endpointId === data.endpointId)) {
        throw Error(`This endpoint id is already present in the private network settings: ${data.endpointId}`);
      }
      endpoints.push(data);
      return endpoints;
    },
    groupId,
    existingEndpoints
  );
}

export function deleteEndpoint(
  groupId: string,
  data: ExternalPrivateEndpoint,
  existingEndpoints: Array<ExternalPrivateEndpoint>
) {
  return _modifyAndPutEndpoint(
    (endpoints: Array<ExternalPrivateEndpoint>) => {
      return endpoints.filter((endpoint: ExternalPrivateEndpoint) => endpoint.endpointId !== data.endpointId);
    },
    groupId,
    existingEndpoints
  );
}

type ModificationFunction = (existingEndpoints: Array<ExternalPrivateEndpoint>) => Array<ExternalPrivateEndpoint>;

function _modifyAndPutEndpoint(
  modificationFn: ModificationFunction,
  groupId: string,
  existingEndpoints: Array<ExternalPrivateEndpoint>
) {
  const modifiedEndpoints = modificationFn(existingEndpoints);
  return putPrivateLink(groupId, modifiedEndpoints);
}
