import * as api from 'js/common/services/api';

// Action Types
const SET_CUSTOM_ROLES = 'nds/customRoles/SET_CUSTOM_ROLES';

const initialState = {
  roles: [],
};

// Reducer
export default function customRolesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CUSTOM_ROLES: {
      const newState = {
        ...state,
        roles: action.payload,
      };
      return newState;
    }
    default:
      return state;
  }
}

const getCustomRolesData = (state) => {
  return state.nds.customRoles.roles;
};

// Selectors
export const getCustomRoles = (state) => getCustomRolesData(state) || {};

// Action Creators
const setCustomRoles = (payload) => ({
  type: SET_CUSTOM_ROLES,
  payload,
});
export { setCustomRoles };

export const loadCustomRoles = (groupId) => (dispatch) => {
  return api.nds.roles.getRoles(groupId).then((response) => {
    return dispatch(setCustomRoles(response));
  });
};
