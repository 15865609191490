import { css } from '@emotion/react';

import { mq } from 'js/common/utils/mediaQueries';

import colors from 'js/auth/utils/palette';

const commonAlert = {
  padding: '15px',
  borderRadius: '5px',
  wordWrap: 'break-word',
  marginTop: '30px',
  marginBottom: '30px',
  width: [270, 'calc(100vw - 64px)', 270, 270],
};

export const alertBanner = css(mq({ ...commonAlert }));

export const warningAlertBanner = css(
  mq({
    ...commonAlert,
    border: '1px solid transparent',
    color: colors.bannerText,
    borderColor: colors.bannerBorder,
    background: colors.bannerBackground,
  })
);

export const dangerAlertBanner = css(
  mq({
    ...commonAlert,
    border: '1px solid transparent',
    color: colors.resetErrorText,
    borderColor: colors.resetErrorBorder,
    background: colors.resetErrorBackground,
  })
);
