import { ClusterDescription } from '@packages/types/nds/clusterDescription';

import * as api from 'js/common/services/api';
import analytics, { SEGMENT_EVENTS } from 'js/common/utils/segmentAnalytics';

// ActionTypes
const SET_SERVERLESS_PROVIDER_OPTIONS = 'nds/serverlessInstanceForm/SET_SERVERLESS_PROVIDER_OPTIONS';
const SET_SERVERLESS_BACKUP_OPTIONS = 'nds/serverlessInstanceForm/SET_SERVERLESS_BACKUP_OPTIONS';
const SET_CONTINUOUS_DELIVERY_MONGODB_VERSION = 'nds/serverlessInstances/SET_CONTINUOUS_DELIVERY_MONGODB_VERSION';
const SET_TENANT_UPGRADE_FEATURE_ELIGIBILITY = 'nds/serverlessInstancesForm/SET_TENANT_UPGRADE_FEATURE_ELIGIBILITY';

const defaultState = {
  serverlessProviderOptions: {},
  serverlessBackupOptions: {},
};

// Reducer
export default function serverlessInstanceFormReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_SERVERLESS_PROVIDER_OPTIONS: {
      const newState = {
        ...state,
        serverlessProviderOptions: action.payload,
      };
      return newState;
    }
    case SET_SERVERLESS_BACKUP_OPTIONS: {
      const newState2 = {
        ...state,
        serverlessBackupOptions: action.payload,
      };
      return newState2;
    }
    case SET_CONTINUOUS_DELIVERY_MONGODB_VERSION: {
      const newState3 = {
        ...state,
        continuousDeliveryMongoDBVersion: action.payload,
      };
      return newState3;
    }
    case SET_TENANT_UPGRADE_FEATURE_ELIGIBILITY: {
      const newState4 = {
        ...state,
        tenantUpgradeFeatureUsage: action.payload,
      };
      return newState4;
    }
    default:
      return state;
  }
}

const getServerlessInstanceFormData = (state) => state.nds.serverlessInstanceForm;

// Selectors
export const getServerlessProviderOptions = (state) =>
  getServerlessInstanceFormData(state).serverlessProviderOptions || {};

export const getServerlessBackupOptions = (state) => getServerlessInstanceFormData(state).serverlessBackupOptions || {};

export const getServerlessContinuousBackupEnabled = (state) =>
  String(getServerlessBackupOptions(state).serverlessContinuousBackupEnabled || {});

export const getContinuousDeliveryMongoDBVersion = (state) =>
  String(getServerlessInstanceFormData(state).continuousDeliveryMongoDBVersion) || {};

export const getTenantUpgradeFeatureUsage = (state) =>
  getServerlessInstanceFormData(state).tenantUpgradeFeatureUsage || {};

// Action Creators
const setServerlessProviderOptions = (payload) => ({
  type: SET_SERVERLESS_PROVIDER_OPTIONS,
  payload,
});

const setServerlessBackupOptions = (payload) => ({
  type: SET_SERVERLESS_BACKUP_OPTIONS,
  payload,
});

const setContinuousDeliveryMongoDBVersion = (payload) => ({
  type: SET_CONTINUOUS_DELIVERY_MONGODB_VERSION,
  payload,
});

const setTenantUpgradeFeatureUsage = (payload) => ({
  type: SET_TENANT_UPGRADE_FEATURE_ELIGIBILITY,
  payload,
});

export {
  setServerlessProviderOptions,
  setServerlessBackupOptions,
  setContinuousDeliveryMongoDBVersion,
  setTenantUpgradeFeatureUsage,
};

export const loadServerlessProviderOptions = (groupId: string) => (dispatch) => {
  return api.nds.serverlessInstanceForm
    .getProviderOptions(groupId)
    .then((response) => dispatch(setServerlessProviderOptions(response)));
};

export const loadServerlessBackupOptions = (groupId: string, clusterId: string) => (dispatch) => {
  return api.nds.serverlessInstanceForm
    .getServerlessInstanceByName(groupId, clusterId)
    .then((response) => dispatch(setServerlessBackupOptions(response.serverlessBackupOptions)));
};

export const loadContinuousDeliveryMongoDBVersion = (groupId: string) => (dispatch) => {
  return api.nds.serverlessInstanceForm
    .getContinuousDeliveryMongoDBVersion(groupId)
    .then((response) => dispatch(setContinuousDeliveryMongoDBVersion(response.continuousDeliveryVersion)));
};

export const loadTenantUpgradeFeatureUsage =
  (groupId: string, clusterDescription: ClusterDescription) => (dispatch) => {
    return api.nds.serverlessInstanceForm.getTenantUpgradeFeatureUsage(groupId, clusterDescription).then((response) => {
      // Trigger segment event with info on features in use here so that it's only
      // triggered 1x on render
      if (response.isUsingSearch || response.isUsingAppServices || response.isUsingCharts) {
        let listOfFeaturesUsedForSegment = [] as Array<String>;
        if (response.isUsingAppServices) {
          listOfFeaturesUsedForSegment.push('App Services');
        }
        if (response.isUsingSearch) {
          listOfFeaturesUsedForSegment.push('Atlas Search');
        }
        if (response.isUsingCharts) {
          listOfFeaturesUsedForSegment.push('Charts');
        }
        const featuresInUse = listOfFeaturesUsedForSegment.join(', ');
        analytics.track(SEGMENT_EVENTS.UX_ACTION_PERFORMED, {
          context: 'Cluster Builder',
          action: 'Serverless Upgrade Limitation Shown',
          value: featuresInUse,
        });
      }
      dispatch(setTenantUpgradeFeatureUsage(response));
    });
  };
