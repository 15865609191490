import { useReducer } from 'react';

import { css } from '@emotion/react';
import Banner from '@leafygreen-ui/banner';
import Button from '@leafygreen-ui/button';
import { usePoller } from '@leafygreen-ui/hooks';
import { Body } from '@leafygreen-ui/typography';
import { produce } from 'immer';

import {
  FactorResultType,
  FactorType,
  PushFactorVerificationResponse,
  PushOktaMfaFactor,
} from '@packages/types/accountMultiFactorAuth';

import * as api from 'js/common/services/api';
// common styles
import { bodyParagraphStyles } from 'js/common/styles/multiFactorAuth';

const sendButtonStyles = css({
  width: '100px',
});

const errorBannerStyles = css({
  marginBottom: '14px',
});

type VerifyPushAction =
  | { type: 'onChallenge' }
  | { type: 'onChallengeSuccess'; payload: { challengeInfo: PushFactorVerificationResponse } }
  | { type: 'onChallengeError' }
  | { type: 'onTimeout' }
  | { type: 'onReject' }
  | { type: 'onSuccess' };

interface VerifyPushReducerState {
  isPushSent: boolean;
  challengeInfo?: PushFactorVerificationResponse;
  errorMessage: string;
}

const initialState: VerifyPushReducerState = {
  isPushSent: false,
  challengeInfo: undefined,
  errorMessage: '',
};

const verifyPushReducer = produce((draft: VerifyPushReducerState, action: VerifyPushAction) => {
  switch (action.type) {
    case 'onChallenge': {
      draft.isPushSent = true;
      draft.errorMessage = '';
      return;
    }
    case 'onChallengeSuccess': {
      const { challengeInfo } = action.payload;
      draft.challengeInfo = challengeInfo;
      draft.errorMessage = '';
      return;
    }
    case 'onChallengeError': {
      draft.isPushSent = false;
      draft.errorMessage = 'A server error has occurred. Please try again in a moment.';
      return;
    }
    case 'onTimeout': {
      draft.isPushSent = false;
      draft.challengeInfo = undefined;
      draft.errorMessage = 'Your push notification has expired.';
      return;
    }
    case 'onReject': {
      draft.isPushSent = false;
      draft.challengeInfo = undefined;
      draft.errorMessage = 'You have rejected this login.';
      return;
    }
    case 'onSuccess': {
      draft.challengeInfo = undefined;
      return;
    }

    default:
      return draft;
  }
}, initialState);

interface VerifyPushFactorProps {
  pushFactor: PushOktaMfaFactor;
  onSuccess: (accountTemporaryAuthTokenId?: string) => void;
  isDeleteFlow?: boolean;
  isEmailChangeFlow?: boolean;
}

export default function VerifyPushFactor({
  pushFactor,
  onSuccess,
  isDeleteFlow,
  isEmailChangeFlow,
}: VerifyPushFactorProps) {
  const [{ isPushSent, challengeInfo, errorMessage }, dispatch] = useReducer(verifyPushReducer, initialState);

  const sendPushChallenge = async () => {
    dispatch({ type: 'onChallenge' });

    try {
      const response = await api.accountMultiFactorAuth.verifyFactor(
        {
          factorType: FactorType.Push,
          factorId: pushFactor.id,
        },
        isDeleteFlow
      );
      dispatch({ type: 'onChallengeSuccess', payload: { challengeInfo: response as PushFactorVerificationResponse } });
    } catch ({ errorCode }) {
      dispatch({ type: 'onChallengeError' });
    }
  };

  const handleFactorResult = (factorResult?: FactorResultType, accountTemporaryAuthTokenId?: string) => {
    switch (factorResult) {
      case FactorResultType.REJECTED:
        dispatch({ type: 'onReject' });
        break;
      case FactorResultType.TIMEOUT:
        dispatch({ type: 'onTimeout' });
        break;
      case FactorResultType.SUCCESS:
        dispatch({ type: 'onSuccess' });
        onSuccess(accountTemporaryAuthTokenId);
        break;
      default:
      // do nothing
    }
  };

  const pollForPushFactorVerification = async () => {
    const { transactionId } = challengeInfo as PushFactorVerificationResponse;

    try {
      if (isEmailChangeFlow) {
        const { accountTemporaryAuthTokenId, factorResult } =
          await api.accountProfile.getEmailChangeRequestSessionWithPushFactor({
            factorId: pushFactor.id,
            transactionId,
          });
        handleFactorResult(factorResult, accountTemporaryAuthTokenId);
      } else {
        const { factorResult } = await api.accountMultiFactorAuth.verifyPushFactorChallenge({
          factorId: pushFactor.id,
          transactionId,
        });
        handleFactorResult(factorResult);
      }
    } catch (error) {
      console.log('An error occurred during polling', error);
    }
  };

  usePoller(pollForPushFactorVerification, {
    interval: 5e3,
    enabled: !!challengeInfo,
    immediate: false,
  });

  return (
    <>
      {errorMessage && (
        <Banner variant="danger" data-testid="error-banner" css={errorBannerStyles}>
          {errorMessage}
        </Banner>
      )}

      <Body css={bodyParagraphStyles}>Send a push notification to authenticate on your device.</Body>
      <Button css={sendButtonStyles} variant="primary" disabled={isPushSent} onClick={sendPushChallenge}>
        {isPushSent ? 'Push Sent' : 'Send Push'}
      </Button>
    </>
  );
}
