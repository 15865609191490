import { Dispatch, useEffect } from 'react';

import { FieldNames } from '@packages/types/auth/field';

import { useRequestParams } from 'js/common/context/RequestParamsContext';
import { endOktaSession, oktaSessionExists } from 'js/common/utils/oktaUtils';

import { ReducerAction } from 'js/auth/types/registration';

export default function useOktaSession(dispatch: Dispatch<ReducerAction>) {
  const { onPrem } = useRequestParams();

  useEffect(() => {
    async function checkIfOktaSessionExists() {
      let sessionExists;
      try {
        sessionExists = await oktaSessionExists();
      } catch (e) {
        // ignore errors
      }
      if (sessionExists) {
        await endOktaSession();
      }
      dispatch({ type: 'reducerField', payload: { field: FieldNames.IS_SOCIAL_SIGNUP_DISABLED, value: false } });
    }

    if (!onPrem) {
      checkIfOktaSessionExists();
    } else {
      dispatch({ type: 'reducerField', payload: { field: FieldNames.IS_SOCIAL_SIGNUP_DISABLED, value: false } });
    }
  }, [onPrem, oktaSessionExists]);
}
