import queryString from 'query-string';

import { AvailableMetrics, MetaData, Metrics, MetricsQuery } from '@packages/types/MetricInterfaces';

import { MetricsApi } from 'js/common/services/api/MetricsApiInterface';

import fetchWrapper, { FetchWrapperOptions } from '../fetchWrapper';

interface MetricsResponse {
  metrics: Metrics;
  meta: MetaData;
}

export interface SelectedChartsResponse {
  selectedCharts: Array<string>;
}

export interface SeparateOpcounterChartsResponse {
  separateOpcounterCharts: boolean;
}

export function getServerlessMetrics(
  groupId: string,
  uniqueId: string,
  query?: MetricsQuery,
  fetchOptions?: FetchWrapperOptions
): Promise<MetricsResponse> {
  let queryAsString = `retention=3600000`;
  if (query) {
    queryAsString = queryString.stringify(query);
  }
  return fetchWrapper(`/metrics/v1/groups/${groupId}/serverless/${uniqueId}?${queryAsString}`, {
    ...fetchOptions,
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getAvailableServerlessCharts(
  groupId: string,
  uniqueId: string,
  fetchOptions?: FetchWrapperOptions
): Promise<AvailableMetrics> {
  return fetchWrapper(`/metrics/v1/groups/${groupId}/serverless/${uniqueId}/available`, {
    ...fetchOptions,
    method: 'GET',
  }).then((resp) => resp.json());
}

export function updateSelectedServerlessCharts(groupId: string, selectedCharts: Array<string>): Promise<Response> {
  return fetchWrapper(`/metrics/serverlessChartsSelect/${groupId}`, {
    method: 'POST',
    body: JSON.stringify(selectedCharts),
  });
}

export function getSelectedServerlessCharts(groupId: string): Promise<SelectedChartsResponse> {
  return fetchWrapper(`/metrics/serverless/defaultChartIds/${groupId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getSeparateOpcounterCharts(): Promise<SeparateOpcounterChartsResponse> {
  return fetchWrapper(`/settings/separateOpcounterCharts`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export const ServerlessMetricsApi: MetricsApi = {
  getMetrics: getServerlessMetrics,
  getAvailableCharts: getAvailableServerlessCharts,
  updateSelectedCharts: updateSelectedServerlessCharts,
  getSelectedCharts: getSelectedServerlessCharts,
  getSeparateOpcounterCharts: getSeparateOpcounterCharts,
};
