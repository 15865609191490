import { FormEvent, MouseEventHandler, RefObject, useEffect, useReducer } from 'react';

import { Variant as BannerVariant } from '@leafygreen-ui/banner';

import { FieldNames } from '@packages/types/auth/field';
import { Search } from '@packages/types/auth/search';
import { Document } from '@packages/types/browser';

import * as api from 'js/common/services/api';
import * as errorHelper from 'js/common/services/authErrorHelper';
import * as oktaUtils from 'js/common/utils/oktaUtils';
import { useRequestParams } from 'js/common/context/RequestParamsContext';
import { justLoggedInRedirect } from 'js/common/utils/authRedirectUtils';
import { CloudTeams, sendError } from 'js/common/utils/bugsnag';
import analytics from 'js/common/utils/segmentAnalytics';

import { ReCaptchaHandles } from 'js/auth/components/ReCaptcha';
// reducers
import { loginReducer, reducerState, ReducerState } from 'js/auth/reducers/loginReducer';
import { onChangeTypeDef } from 'js/auth/types/formInput';

interface AuthResponse {
  uId?: string;
  username?: string;
  email?: string;
  currentOrgId?: string;
  csrfTime?: string;
  csrfToken?: string;
  loginRedirect: string;
  status: string;
}

export const ALERTABLE_ERROR_CODES = [
  'SOCIAL_USERNAME_DOMAIN_REQUIRES_FEDERATION',
  'USER_ORGS_RESTRICTED',
  'SSO_DEBUG_PROHIBITED',
  'IDLE_SESSION_TIMEOUT',
  'ACCOUNT_DELETION_REQUESTED',
  'JIT_FAILURE',
];

export interface UseLoginService {
  onLoginUser: (e: FormEvent<HTMLFormElement>) => Promise<void>;
  onEmailChange: onChangeTypeDef;
  onPasswordChange: onChangeTypeDef;
  onChangeEmailClick: MouseEventHandler<HTMLAnchorElement>;
  disableEmailInput: boolean;
  showInputErrors: boolean;
  loginReducerState: ReducerState;
  onSetReCaptchaResponse: (string) => void;
  onSetReCaptchaV3Response: (string) => void;
}

export default function useLoginService({
  parsedSearchLocation,
  isVercelIntegration = false,
  windowLocation,
  reCaptchaRef,
  browserDocument,
  clientState = { ...parsedSearchLocation },
}: {
  parsedSearchLocation: Search;
  isVercelIntegration?: boolean;
  windowLocation: Pick<Location, 'assign'>;
  reCaptchaRef: RefObject<ReCaptchaHandles>;
  browserDocument: Document;
  clientState?: object;
}): UseLoginService {
  const {
    centralUrl,
    universityCentralUrl,
    onPrem,
    oktaSessionDiscoveryEnabled,
    userRegistrationDisabledRedirectUrl,
    vercelEmail,
    reCaptchaEnabledLogin,
    baasCentralUrl,
  } = useRequestParams();

  const {
    inviteToken,
    username: invitedUsername,
    socialUsername,
    unauthorizedUsername,
    reason,
    ssoDebug,
    idp,
    signedOut,
  } = parsedSearchLocation;

  const [loginReducerState, dispatch] = useReducer(loginReducer, reducerState, (state) => {
    return {
      ...state,
      onlyUsernameShowing: !onPrem,
    };
  });

  const {
    username,
    password,
    onlyUsernameShowing,
    // showSupportLogout,
    authErrorCode,
    reCaptchaResponse,
    reCaptchaV3Response,
    differentUniversityPasswordError,
    ssoBypassEnabled,
    bannerVariant,
  } = loginReducerState;

  const onCallAuthApi = async () => {
    const loginData = {
      username,
      password,
      inviteToken,
      clientState,
      reCaptchaResponse,
      reCaptchaV3Response,
      ssoDebug,
      idp,
    };

    const onPremLoginData = {
      username,
      password,
      inviteToken,
      clientState,
      reCaptchaResponse,
      ssoDebug,
      idp,
    };

    let authResponse: AuthResponse;

    try {
      authResponse = onPrem ? await api.user.auth(onPremLoginData) : await api.auth.userAuthOkta(loginData);
    } catch ({ errorCode, resource }) {
      dispatch({ type: 'processAuthErrorResponse', payload: { errorCode, formSubmitting: false } });

      if (reCaptchaEnabledLogin && reCaptchaRef.current) {
        reCaptchaRef.current.reset();
      }

      if (errorHelper.hasMatchingErrorCode(errorCode) && resource) {
        windowLocation.assign(`${resource}?reason=${errorCode}`);
      }

      return;
    }

    const { uId: userId, username: responseUsername, email, loginRedirect } = authResponse;

    if (onPrem) {
      justLoggedInRedirect({
        userId,
        email,
        baasCentralUrl,
        username: responseUsername,
        windowLocation,
        query: parsedSearchLocation,
      });
    } else {
      windowLocation.assign(loginRedirect);
    }
  };

  const prefilledUsername = invitedUsername || socialUsername || unauthorizedUsername;

  useEffect(() => {
    if (prefilledUsername) {
      dispatch({ type: 'field', payload: { field: FieldNames.USERNAME, value: prefilledUsername } });
    }
  }, [prefilledUsername, isVercelIntegration, vercelEmail]);

  useEffect(() => {
    if (reason) {
      const reasonBannerVariant =
        reason === 'SOCIAL_USERNAME_DOMAIN_REQUIRES_FEDERATION'
          ? BannerVariant.Warning
          : reason === 'IDLE_SESSION_TIMEOUT' || reason === 'ACCOUNT_DELETION_REQUESTED'
          ? BannerVariant.Info
          : BannerVariant.Danger;
      dispatch({
        type: 'processAuthErrorResponse',
        payload: {
          errorCode: reason,
          bannerVariant: reasonBannerVariant,
          formSubmitting: false,
          marketingUrl: userRegistrationDisabledRedirectUrl,
        },
      });
    }
  }, [reason]);

  useEffect(() => {
    let disableSubmit = true;
    if ((onlyUsernameShowing && username) || password) {
      disableSubmit = false;
    }
    dispatch({ type: 'field', payload: { field: FieldNames.IS_SUBMIT_DISABLED, value: disableSubmit } });
  }, [password, username, onlyUsernameShowing]);

  useEffect(() => {
    async function checkIfSessionExists() {
      const hasError = !!authErrorCode || !!reason;
      const shouldEndSessions = signedOut === 'true' || !oktaSessionDiscoveryEnabled || !!inviteToken || hasError;
      try {
        const sessionExists = await oktaUtils.oktaSessionExists();
        // regardless of an existing okta session, app sessions must be terminated, so this flag takes precedence
        // over `sessionExists`
        if (shouldEndSessions) {
          dispatch({ type: 'field', payload: { field: FieldNames.IS_LOGIN_DISABLED, value: true } });
          const promiseArray = [
            api.auth.accountLogout(),
            api.auth.cloudLogout(centralUrl),
            api.auth.universityLogout(universityCentralUrl),
          ];
          // TODO: AM CLOUDP-57726 uncomment and fix the support logout issue
          // dispatch({ type: 'setShowSupportLogout' });
          if (sessionExists) {
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'void' is not assignable to param... Remove this comment to see the full error message
            promiseArray.push(oktaUtils.endOktaSession());
          }

          await Promise.all(promiseArray);
          dispatch({ type: 'field', payload: { field: FieldNames.IS_LOGIN_DISABLED, value: false } });
        } else if (sessionExists) {
          // Log the user into the correct application, knowing they already have an Okta session
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ clientState: { inviteToken?: s... Remove this comment to see the full error message
          const { loginRedirect } = await api.auth.startOidc({ clientState });
          windowLocation.assign(loginRedirect);
        } else {
          dispatch({ type: 'field', payload: { field: FieldNames.IS_LOGIN_DISABLED, value: false } });
        }
      } catch (error) {
        sendError({
          error,
          team: CloudTeams.CoreIam,
        });
        dispatch({ type: 'field', payload: { field: FieldNames.IS_LOGIN_DISABLED, value: false } });
      } finally {
        if (shouldEndSessions) {
          try {
            analytics.reset();
          } catch (error) {
            sendError({
              error,
              team: CloudTeams.AtlasGrowth,
            });
          }
        }
      }
    }

    if (!onPrem) {
      checkIfSessionExists();
    } else {
      dispatch({ type: 'field', payload: { field: FieldNames.IS_LOGIN_DISABLED, value: false } });
    }
  }, [authErrorCode, inviteToken, oktaSessionDiscoveryEnabled, reason]);

  useEffect(() => {
    if (reCaptchaResponse != null) {
      onCallAuthApi();
    }
  }, [reCaptchaResponse]);

  useEffect(() => {
    if (differentUniversityPasswordError) {
      windowLocation.assign(`/account/reset/university/password?email=${encodeURIComponent(username)}`);
    }
  }, [differentUniversityPasswordError]);

  useEffect(() => {
    if (ssoDebug) {
      dispatch({ type: 'bypassSso' });
    }
  }, [ssoDebug]);

  const onLoginUser = async (e) => {
    e.preventDefault();
    dispatch({ type: 'setFormSubmitting', payload: true });
    const hasValidRememberDeviceCookie =
      browserDocument.cookie && browserDocument.cookie.includes(`remember-user-device=${username.toLowerCase()}:`);

    if (onlyUsernameShowing) {
      try {
        const { loginRedirect } = await api.federation.getIdentityProviderRedirect({
          username: username!,
          clientState,
        });

        if (loginRedirect) {
          windowLocation.assign(loginRedirect);
        }

        dispatch({
          type: 'processIdpResponse',
          payload: {
            onlyUsernameShowing: !onPrem && !!loginRedirect,
            errorCode: '',
            isSubmitDisabled: false,
          },
        });
      } catch (err) {
        const { errorCode } = err;
        dispatch({
          type: 'processIdpResponse',
          payload: {
            onlyUsernameShowing: true,
            errorCode,
            isSubmitDisabled: false,
          },
        });
      } finally {
        dispatch({ type: 'setFormSubmitting', payload: false });
      }
    } else if (reCaptchaEnabledLogin && reCaptchaRef.current && !hasValidRememberDeviceCookie) {
      reCaptchaRef.current.execute();
    } else {
      onCallAuthApi();
    }
  };

  const onEmailChange: onChangeTypeDef = (e) => {
    dispatch({ type: 'field', payload: { field: FieldNames.USERNAME, value: e.target.value } });
  };

  const onPasswordChange: onChangeTypeDef = (e) => {
    dispatch({ type: 'field', payload: { field: FieldNames.PASSWORD, value: e.target.value } });
  };

  const onChangeEmailClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
    dispatch({ type: 'changeUsername' });
  };

  const onSetReCaptchaResponse = (response) => {
    dispatch({ type: 'setReCaptchaResponse', payload: { reCaptchaResponse: response } });
  };

  const onSetReCaptchaV3Response = (response) => {
    dispatch({ type: 'setReCaptchaV3Response', payload: { reCaptchaV3Response: response } });
  };

  const disableEmailInput = !onlyUsernameShowing && !ssoBypassEnabled && !onPrem;

  const prefilledUsernameChanged = prefilledUsername && username !== prefilledUsername;

  const showInputErrors =
    !!authErrorCode &&
    !ALERTABLE_ERROR_CODES.includes(authErrorCode) &&
    bannerVariant === BannerVariant.Danger &&
    !prefilledUsernameChanged;

  return {
    onLoginUser,
    onEmailChange,
    onPasswordChange,
    onChangeEmailClick,
    disableEmailInput,
    showInputErrors,
    loginReducerState,
    onSetReCaptchaResponse,
    onSetReCaptchaV3Response,
  };
}
