/**
 * Check if an API response contains an errorCode that says whether the user's
 * multifactor or password has expired and requires re-verification.
 * @param {Object|function} authExpiredHandlers This can either be an object
 *   with the shape of { password: fn, multifactor: fn } or just a function which
 *   will be used for both types of expiration.
 *   When an expiration is encountered the function is called.
 * @return {Function} A function is returned that can be put into the .catch
 *   handler of a promise chain:
 *   Example: promise.then().then().catch(isAuthExpired(fn))
 */
export const isAuthExpired = function isAuthExpired(authExpiredHandlers) {
  if (typeof authExpiredHandlers === 'function') {
    const handler = authExpiredHandlers;
    authExpiredHandlers = {
      password: handler,
      multifactor: handler,
    };
  }

  // Return a function that takes in an API response to check if auth is expired.
  // If auth is expired it'll call the handlers defined above.
  // This function returns a string of which type of auth is expired,
  // or returns false if auth is not expired in case you need to branch
  // based upon that result.
  return (apiResponse) => {
    const { errorCode } = apiResponse;
    if (errorCode === 'MULTI_FACTOR_AUTH_EXPIRED') {
      authExpiredHandlers.multifactor(apiResponse);

      return 'multifactor';
    }
    if (errorCode === 'PASSWORD_AUTH_EXPIRED') {
      authExpiredHandlers.password(apiResponse);

      return 'password';
    }

    return false;
  };
};
