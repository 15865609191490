export type PromisifiedTimeout = <Result>(cb: () => Result, time: number) => Promise<Result>;

export const promisifiedTimeout: PromisifiedTimeout = (cb, time) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      const result = cb();
      resolve(result);
    }, time);
  });
};
