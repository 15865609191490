import { BackupDeliveryMethod } from '@packages/types/nds/backup';
import { DataProtectionSettings } from '@packages/types/nds/backup/jobTypes';

import { get } from 'js/common/utils/groupIdFromPath';

import fetchWrapper, { formParams } from '../fetchWrapper';

export default {
  getDataProtectionClusterList(groupId) {
    return fetchWrapper(`/nds/backup/${groupId}/dataProtectionClusterList`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getDiskBackups(groupId, clusterUniqueId) {
    return fetchWrapper(`/nds/backup/${groupId}/${clusterUniqueId}/snapshots`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getDiskBackupRestoreHistory(groupId, clusterUniqueId) {
    return fetchWrapper(`/nds/backup/${groupId}/${clusterUniqueId}/restoreJobs`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getExportHistory(groupId, clusterUniqueId) {
    return fetchWrapper(`/nds/backup/${groupId}/${clusterUniqueId}/exportRestoreJobs`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getDiskBackupMetadata(clusterUniqueId, groupId = get()) {
    return fetchWrapper(`/nds/backup/${groupId}/${clusterUniqueId}/job`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  deleteSnapshot(groupId, snapshotId) {
    return fetchWrapper(`/nds/backup/${groupId}/snapshot/${snapshotId}`, {
      method: 'DELETE',
    }).then((resp) => resp.json());
  },

  performAutomatedRestore(
    groupId,
    snapshotId,
    clusterName,
    destinationGroupId,
    destinationClusterName,
    isPit,
    pitUTCSeconds,
    pitOplogTs,
    pitOplogInc
  ) {
    const data = {
      snapshotId,
      deliveryMethod: isPit ? BackupDeliveryMethod.POINT_IN_TIME : BackupDeliveryMethod.AUTOMATION_PULL,
      sourceClusterName: clusterName,
      targetGroupId: destinationGroupId,
      targetClusterName: destinationClusterName,
      pointInTimeUTCSeconds: pitUTCSeconds,
      oplogTs: pitOplogTs,
      oplogInc: pitOplogInc,
    };

    return fetchWrapper(`/nds/backup/${groupId}/restoreJob`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams(data),
    }).then((resp) => resp.json());
  },

  getRestoreDestinationItems(
    sourceGroupId,
    sourceClusterName,
    targetGroupId,
    isSharded,
    snapshotId,
    isPit,
    pitUTCSeconds,
    pitOplogTs,
    pitOplogInc
  ) {
    let url;
    if (isPit) {
      url =
        `/automation/restore/${targetGroupId}/destinationClustersCpsPit?sourceGroupId=${sourceGroupId}` +
        `&sourceClusterName=${sourceClusterName}&pitUTCSeconds=${pitUTCSeconds || ''}` +
        `&pitOplogTs=${pitOplogTs}&pitOplogInc=${pitOplogInc}`;
    } else if (isSharded) {
      url = `/automation/restore/${targetGroupId}/destinationClustersCps?snapshotId=${snapshotId}`;
    } else {
      url = `/automation/restore/${targetGroupId}/destinationReplicaSetsCps?snapshotId=${snapshotId}`;
    }

    return fetchWrapper(url, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  createDownload(groupId, snapshotId) {
    const params = {
      snapshotId,
      deliveryMethod: BackupDeliveryMethod.MANUAL_DOWNLOAD,
    };
    return fetchWrapper(`/nds/backup/${groupId}/restoreJob`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams(params),
    }).then((resp) => resp.json());
  },

  cancelDownload(groupId, restoreJobId) {
    return fetchWrapper(`/nds/backup/${groupId}/restoreJob/${restoreJobId}`, {
      method: 'DELETE',
    }).then((resp) => resp.json());
  },

  markRestoreJobsAsSeen(groupId, restoreJobIds) {
    const params = { restoreJobIds };
    return fetchWrapper(`/nds/backup/${groupId}/restoreJob/seen`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'PATCH',
      body: formParams(params),
    }).then((resp) => resp.json());
  },

  updateBackupPolicy(
    groupId,
    clusterUniqueId,
    referenceTimeInMins,
    restoreWindowDays,
    policies,
    policyId,
    updateExistingSnapshots,
    copySettings,
    deleteCopiedBackups
  ) {
    const data = {
      referenceTimeInMins,
      restoreWindowDays,
      policies: [
        {
          id: policyId,
          policyItems: policies,
        },
      ],
      updateSnapshots: updateExistingSnapshots,
      copySettings: copySettings,
      deleteCopiedBackups: deleteCopiedBackups,
    };

    return fetchWrapper(`/nds/backup/${groupId}/${clusterUniqueId}/backupPolicy`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(data),
    }).then((resp) => resp.json());
  },

  editSnapshotRetention(groupId, snapshotId, retentionValue, retentionUnit) {
    const data = {
      retentionValue,
      retentionUnit,
    };

    return fetchWrapper(`/nds/backup/${groupId}/snapshot/${snapshotId}/editRetention`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'PATCH',
      body: formParams(data),
    }).then((resp) => resp.json());
  },

  getPitRestoreRange(groupId, clusterUniqueId) {
    return fetchWrapper(`/nds/backup/${groupId}/${clusterUniqueId}/pitRestoreRange`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getSnapshots(groupId, clusterUniqueId) {
    return fetchWrapper(`/nds/backup/${groupId}/${clusterUniqueId}/snapshots`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getSnapshotsAffectedByPolicy(groupId, clusterUniqueId, policies, policyId) {
    const data = {
      policies: [
        {
          id: policyId,
          policyItems: policies,
        },
      ],
    };
    return fetchWrapper(`/nds/backup/${groupId}/${clusterUniqueId}/snapshotsAffectedByPolicy`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(data),
    }).then((resp) => resp.json());
  },

  takeSnapshot(groupId, clusterName, retentionInDays, snapshotDescription) {
    const data = {
      retentionInDays,
      snapshotDescription,
      clusterName,
    };

    return fetchWrapper(`/nds/backup/${groupId}/snapshots`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams(data),
    }).then((resp) => resp.json());
  },

  createExportBucket(groupId, iamRoleId, bucketName) {
    const data = {
      iamRoleId,
      bucketName,
    };
    return fetchWrapper(`/nds/backup/groups/${groupId}/backup/exportBuckets`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams(data),
    }).then((resp) => resp.json());
  },

  getExportBuckets(groupId) {
    return fetchWrapper(`/nds/backup/groups/${groupId}/backup/exportBuckets`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  removeExportBucket(groupId, exportBucketId) {
    return fetchWrapper(`/nds/backup/groups/${groupId}/backup/exportBuckets/${exportBucketId}`, {
      method: 'DELETE',
    }).then((resp) => resp.json());
  },

  createExportJob(groupId, clusterName, snapshotId, exportBucketId) {
    const data = {
      snapshotId,
      exportBucketId,
    };
    return fetchWrapper(`/nds/backup/groups/${groupId}/clusters/${clusterName}/backup/exports/`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: formParams(data),
    }).then((resp) => resp.json());
  },

  getDataProtectionSettings(groupId): Promise<DataProtectionSettings> {
    return fetchWrapper(`/nds/backup/${groupId}/dataProtection`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  disableDataProtectionSettings(groupId) {
    return fetchWrapper(`/nds/backup/${groupId}/dataProtection`, {
      method: 'DELETE',
    }).then((resp) => resp.json());
  },

  saveDataProtectionSettings(settings: DataProtectionSettings) {
    return fetchWrapper(`/nds/backup/${settings.projectId}/dataProtection`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
      body: JSON.stringify(settings),
    });
  },

  validateDataProtectionSettings(settings: DataProtectionSettings) {
    return fetchWrapper(`/nds/backup/${settings.projectId}/dataProtection/validate`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(settings),
    });
  },
};
