import { css } from '@emotion/react';
import styled from '@emotion/styled';
import TextInput from '@leafygreen-ui/text-input';

import { mq } from 'js/common/utils/mediaQueries';

import colors from 'js/auth/utils/palette';

export const CONTAINER_MARGINS = {
  mobile: {
    marginX: 16,
    marginY: 50,
  },
};

export const Container = styled.form(() =>
  mq({
    margin: [
      '50px 0px 50px 42px',
      `${CONTAINER_MARGINS.mobile.marginY}px ${CONTAINER_MARGINS.mobile.marginX}px`,
      '50px 0px 50px 42px',
      '50px 0px 50px 42px',
    ],
    flex: '1 0 auto',
  })
);

export const checkbox = css({
  marginTop: '20px',

  span: {
    fontSize: '14px',

    a: {
      textDecoration: 'none',
      color: colors.link,
    },
  },
});

export const submitButton = css(
  mq({
    height: [32, 45, 45, 32],
    width: ['90px', 'calc(100vw - 32px)', '90px', '90px'],
    fontSize: [15, 18, 18, 15],
    // On full-width mode, we want top/bottom margins
    marginTop: [48, 48, 48, 48],
    marginBottom: [0, 5, 0, 0],
    '& > span': {
      textAlign: 'center',
      width: '100%',
    },
  })
);

export const flexibleWidthButton = css(
  mq(submitButton, {
    width: ['auto', 'calc(100vw - 32px)', 'auto', 'auto'],
  })
);

export const header = css({
  marginTop: '32px',
  marginBottom: '24px',
});

export const LoginAndRegistrationHeader = css({
  marginTop: '32px',
  marginBottom: '16px',
});

export const Subheader = styled.span(() =>
  mq({
    marginBottom: '32px',
    color: `${colors.text}`,
    fontSize: '18px',
    fontWeight: 'bold',
    display: 'block',
    textAlign: 'start',

    '& a': {
      textDecoration: 'none',
      color: `${colors.link}`,
    },
  })
);

export const Fieldset = styled.fieldset`
  border: none;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
`;

export const ErrorMessage = styled.p`
  color: ${colors.warning};
`;

// NOTE: This is explicitly typed as "any" because @leafygreen-ui/text-input is missing an "export"
// needed for type declaration. (They need to export the "TextInputProps" interface.)
// When Visual Brand epic is complete, this "export" should be added
// into the LG module and this "any" can be removed
// TODO: https://jira.mongodb.org/browse/CLOUDP-116541
export const CustomTextInput: any = styled(TextInput)(() =>
  mq({
    width: ['300px', 'calc(100vw - 32px)', '300px', '300px'],
    input: {
      height: ['36px', '52px', '36px', '36px'],
      fontSize: ['14px', '16px', '14px', '14px'],
      lineHeight: ['20px', '24px', '20px', '20px'],
    },

    p: {
      fontSize: ['14px', '16px', '14px', '14px'],
      lineHeight: ['20px', '24px', '20px', '20px'],
    },

    label: {
      fontSize: ['14px', '16px', '14px', '14px'],
      lineHeight: ['20px', '24px', '20px', '20px'],
    },
  })
);
