/* eslint-disable react/no-unused-prop-types */
import * as React from 'react';
import { useCallback, useContext, useState } from 'react';

import { auth } from 'js/common/services/api';
// eslint-disable-next-line no-restricted-imports
import { updateCsrfHeaders } from 'js/common/services/api/fetchWrapper';

interface AccountUserParams {
  csrfTime: string;
  csrfToken: string;
  failedToLoad: boolean;
  username: string;
  firstName: string;
  lastName: string;
}

interface AccountUserProviderProps {
  accountUserParams?: AccountUserParams;
  loadAccountUserParams?: () => Promise<AccountUserParams>;
  children: React.ReactNode;
}

const initialParams: AccountUserParams = {
  csrfTime: '',
  csrfToken: '',
  failedToLoad: false,
  username: '',
  firstName: '',
  lastName: '',
};

const AccountUserContext = React.createContext({
  accountUserParams: initialParams,
  loadAccountUserParams: async () => {},
});

export const AccountUserProvider = ({ children }: AccountUserProviderProps) => {
  const [accountUserParams, setAccountUserParams] = useState(initialParams);

  const loadAccountUserParams = async () => {
    try {
      const { csrfTime, csrfToken, accountUser } = await auth.params();
      const { username, firstName, lastName } = accountUser;

      updateCsrfHeaders({ csrfTime, csrfToken });

      setAccountUserParams({
        csrfTime,
        csrfToken,
        failedToLoad: false,
        username,
        firstName: firstName ?? '',
        lastName: lastName ?? '',
      });
    } catch (e) {
      setAccountUserParams({
        ...accountUserParams,
        failedToLoad: true,
      });
    }
  };

  return (
    <AccountUserContext.Provider
      value={{ accountUserParams, loadAccountUserParams: useCallback(loadAccountUserParams, []) }}
    >
      {children}
    </AccountUserContext.Provider>
  );
};

export const useAccountUser = () => {
  return useContext(AccountUserContext);
};
