import { Metrics } from '@packages/types/charts';
import { AvailableMetric, AvailableMetrics, MetaData, MetricsQuery, Window } from '@packages/types/MetricInterfaces';

import { MetricsStateApi } from 'js/common/services/api/MetricsStateInterface';
import { ServerlessMetricsApi } from 'js/common/services/api/nds/serverlessMetricsApi';

import metricsService from 'js/project/metrics/services/metricsService';

// ActionTypes
const SET_SERVERLESS_METRICS_DATA = 'nds/serverlessMetrics/SET_SERVERLESS_METRICS_DATA';
const SET_AVAILABLE_SERVERLESS_CHARTS = 'nds/serverlessMetrics/SET_AVAILABLE_SERVERLESS_CHARTS';
const SET_METRICS_DISPLAY_ROW = 'nds/serverlessMetrics/SET_METRICS_DISPLAY_ROW';
const SET_SELECTED_CHARTS = 'nds/serverlessMetrics/SET_SELECTED_CHARTS';
const SET_IS_ZOOM_CUSTOM = 'nds/serverlessMetrics/SET_IS_ZOOM_CUSTOM';
const SET_SERVERLESS_METRICS_DATA_META_WINDOW = 'nds/serverlessMetrics/SET_SERVERLESS_METRICS_DATA_META_WINDOW';

export interface State {
  metricsMeta: MetaData;
  metrics: Metrics;
  availableMetrics: AvailableMetrics;
  metricsDisplayRow: boolean;
  selectedCharts: Array<string>;
  isZoomCustom: boolean;
}

const defaultState: State = {
  metricsMeta: {},
  metrics: {},
  availableMetrics: {},
  metricsDisplayRow: true,
  selectedCharts: [],
  isZoomCustom: false,
};

// Reducer
export default function serverlessMetricsReducer(state: State = defaultState, action): State {
  const { type, payload } = action;
  switch (type) {
    case SET_SERVERLESS_METRICS_DATA: {
      return {
        ...state,
        metrics: payload.metrics.metrics,
        metricsMeta: payload.meta,
      };
    }
    case SET_AVAILABLE_SERVERLESS_CHARTS: {
      return {
        ...state,
        availableMetrics: payload.metrics,
      };
    }
    case SET_METRICS_DISPLAY_ROW: {
      return {
        ...state,
        metricsDisplayRow: payload,
      };
    }
    case SET_SELECTED_CHARTS: {
      return {
        ...state,
        selectedCharts: payload,
      };
    }
    case SET_IS_ZOOM_CUSTOM: {
      return {
        ...state,
        isZoomCustom: payload,
      };
    }
    case SET_SERVERLESS_METRICS_DATA_META_WINDOW: {
      return {
        ...state,
        metricsMeta: {
          ...state.metricsMeta,
          window: payload,
        },
      };
    }
    default:
      return state;
  }
}
const getServerlessMetrics = (state): State => state.nds.serverlessMetrics;

// Selectors
const getServerlessMetricsData = (state): Metrics => getServerlessMetrics(state).metrics || {};
const getServerlessMetricsMeta = (state): MetaData => getServerlessMetrics(state).metricsMeta || {};
const getAvailableServerlessMetrics = (state): AvailableMetrics => getServerlessMetrics(state).availableMetrics || {};
const getWindow = (state): Window | undefined => getServerlessMetricsMeta(state).window;
const getMetricsDisplayRow = (state): boolean => getServerlessMetrics(state).metricsDisplayRow;
const getSelectedCharts = (state): Array<string> => getServerlessMetrics(state).selectedCharts;
const getIsZoomCustom = (state): boolean => getServerlessMetrics(state).isZoomCustom;
const getAvailableServerlessMetricsForMetricId = (state, metricId): AvailableMetric =>
  getServerlessMetrics(state).availableMetrics[metricId] ?? {};

// Action Creators
const setServerlessMetricsData = (payload) => ({
  type: SET_SERVERLESS_METRICS_DATA,
  payload,
});

const setAvailableServerlessCharts = (payload) => ({
  type: SET_AVAILABLE_SERVERLESS_CHARTS,
  payload,
});

const setMetricsDisplayRow = (payload: boolean) => ({
  type: SET_METRICS_DISPLAY_ROW,
  payload,
});

const setSelectedCharts = (payload) => ({
  type: SET_SELECTED_CHARTS,
  payload,
});

const setIsZoomCustom = (payload: boolean) => ({
  type: SET_IS_ZOOM_CUSTOM,
  payload,
});

const setServerlessMetricsDataMetaWindow = (payload: Window) => ({
  type: SET_SERVERLESS_METRICS_DATA_META_WINDOW,
  payload,
});

// dispatchers
const loadServerlessMetricsData =
  (groupId: string, uniqueId: string, settingsModel: any, queryString: MetricsQuery, retry: boolean) => (dispatch) => {
    const isAutoGranularity = !queryString.granularity;
    return ServerlessMetricsApi.getMetrics(groupId, uniqueId, queryString).then(
      (response) => {
        dispatch(setServerlessMetricsData(response));
        const newChartZoom = response.meta.zoom?.millis;
        const newGranularity = response.meta.granularities?.selected.millis;
        if (newChartZoom !== settingsModel.get('DEFAULT_CHART_ZOOM')) {
          settingsModel.set('DEFAULT_CHART_ZOOM', newChartZoom);
          metricsService.saveDefaultChartZoom(newChartZoom);
        }
        if (isAutoGranularity && settingsModel.get('DEFAULT_CHART_GRANULARITY') !== 0) {
          settingsModel.set('DEFAULT_CHART_GRANULARITY', 0);
          metricsService.saveDefaultChartGranularity(0);
        }
        if (newGranularity !== settingsModel.get('DEFAULT_CHART_GRANULARITY') && !isAutoGranularity) {
          settingsModel.set('DEFAULT_CHART_GRANULARITY', newGranularity);
          metricsService.saveDefaultChartGranularity(newGranularity);
        }
      },
      (error) => {
        if (error?.errorCode === 'DATA_NOT_KEPT_FOR_GRANULARITY_AND_TIMEFRAME' && retry) {
          // if granularity & zoom pair aren't valid, then transition user back to Auto gran and retry loading
          const newQueryString = { ...queryString, granularity: undefined };
          loadServerlessMetricsData(groupId, uniqueId, settingsModel, newQueryString, false)(dispatch);
        }
      }
    );
  };

const loadAvailableServerlessCharts = (groupId: string, uniqueId: string) => (dispatch) => {
  return ServerlessMetricsApi.getAvailableCharts(groupId, uniqueId).then((response) =>
    dispatch(setAvailableServerlessCharts(response))
  );
};

const loadSelectedServerlessCharts = (groupId: string) => (dispatch) => {
  return ServerlessMetricsApi.getSelectedCharts(groupId).then((response) =>
    dispatch(setSelectedCharts(response.selectedCharts))
  );
};

const buildQueryParams = (isZoomCustom: boolean, window: Window | undefined, settingsModel: any) => {
  const defaultGranularity = settingsModel.get('DEFAULT_CHART_GRANULARITY');
  const isGranularityAuto = defaultGranularity === 0;
  return {
    retention: !isZoomCustom ? settingsModel.get('DEFAULT_CHART_ZOOM') : undefined,
    since: isZoomCustom && window ? window.since : undefined,
    until: isZoomCustom && window ? window.until : undefined,
    granularity: !isGranularityAuto ? defaultGranularity : undefined,
  };
};

export const ServerlessMetricsRedux: MetricsStateApi = {
  buildQueryParams,
  getIsZoomCustom,
  getMetricsMeta: getServerlessMetricsMeta,
  loadAvailableCharts: loadAvailableServerlessCharts,
  loadMetricsData: loadServerlessMetricsData,
  getAvailableMetrics: getAvailableServerlessMetrics,
  getMetricsDisplayRow,
  getSelectedCharts,
  loadSelectedCharts: loadSelectedServerlessCharts,
  getAvailableMetricsForMetricId: getAvailableServerlessMetricsForMetricId,
  getMetricsData: getServerlessMetricsData,
  getWindow: getWindow,
  setSelectedCharts,
  setMetricsDisplayRow,
  setAvailableCharts: setAvailableServerlessCharts,
  setMetricsData: setServerlessMetricsData,
  setIsZoomCustom,
  setMetricsDataMetaWindow: setServerlessMetricsDataMetaWindow,
};
