import { useRef, useState } from 'react';

import { css } from '@emotion/react';
import { palette } from '@leafygreen-ui/palette';
import { Body } from '@leafygreen-ui/typography';

import {
  OktaMfaFactor,
  OktaMfaFactorActivationResponse,
  OktaMfaFactorVerificationResponse,
} from '@packages/types/accountMultiFactorAuth';
import { Search } from '@packages/types/auth/search';

import AutoAdvanceInputs from '@packages/components/AutoAdvanceInputs';

import * as api from 'js/common/services/api';
import { VerifyAuthMfaResponse } from 'js/common/services/api/authApi';
import { isLastAuthExpired } from 'js/common/utils/multiFactorAuthUtils';

const activationErrorMessageStyles = css({
  marginTop: '16px',
  marginBottom: '10px',
  color: palette.red.base,
});

type CodeIcon = '' | 'spinner' | 'complete';
type InputType = 'voiceCode' | 'code';

export type VerifyPasscodeInputResponse =
  | VerifyAuthMfaResponse
  | OktaMfaFactorActivationResponse
  | OktaMfaFactorVerificationResponse;

interface VerifyPasscodeInputProps {
  factor?: OktaMfaFactor;
  clientState?: Search;
  onSuccess: (response?: VerifyPasscodeInputResponse | string | { userFactors: Array<OktaMfaFactor> }) => void;
  isActivation?: boolean;
  inputType: InputType;
  isEmailChangeFlow?: boolean;
}

export default function VerifyPasscodeInput({
  factor,
  clientState = {},
  onSuccess,
  isActivation = false,
  inputType,
  isEmailChangeFlow,
}: VerifyPasscodeInputProps) {
  const [activationErrorMessage, setActivationErrorMessage] = useState('');
  const [codeIcon, setCodeIcon] = useState<CodeIcon>('');
  const autoAdvanceInputsRef = useRef<AutoAdvanceInputs>(null);

  const { stateToken } = clientState;
  const isAuthFlow = !!stateToken;

  const onComplete = async ({ value: authCode }: { value: string }) => {
    // If the factor isn't loaded yet, just throw an error and reset input
    if (!factor) {
      autoAdvanceInputsRef.current?.reset();
      setActivationErrorMessage("Your passcode doesn't match our records. Please try again.");
      return;
    }

    setCodeIcon('spinner');
    try {
      if (isEmailChangeFlow) {
        let { accountTemporaryAuthTokenId } = await api.accountProfile.getEmailChangeRequestSessionWithFactors({
          factorType: factor.factorType,
          factorId: factor.id,
          passcode: authCode,
        });
        onSuccess(accountTemporaryAuthTokenId);
      } else if (isAuthFlow) {
        onSuccess(
          await api.auth.verifyAuthMfa({
            factorId: factor.id,
            passcode: authCode,
            stateToken,
            clientState,
          })
        );
      } else if (isActivation) {
        onSuccess(
          await api.accountMultiFactorAuth.activateFactor({
            factorType: factor.factorType,
            factorId: factor.id,
            passcode: authCode,
          })
        );
      } else {
        onSuccess(
          await api.accountMultiFactorAuth.verifyFactor({
            factorType: factor.factorType,
            factorId: factor.id,
            passcode: authCode,
          })
        );
      }

      setCodeIcon('complete');
      setActivationErrorMessage('');
    } catch ({ errorCode }) {
      if (!isAuthFlow && isLastAuthExpired(errorCode)) {
        window.location.assign('/account/profile/security?expired=true');
      } else {
        autoAdvanceInputsRef.current?.reset();
        setCodeIcon('');
        setActivationErrorMessage("Your passcode doesn't match our records. Please try again.");
      }
    }
  };

  return (
    <>
      <AutoAdvanceInputs
        inputsType={inputType}
        onComplete={onComplete}
        icon={codeIcon}
        ref={autoAdvanceInputsRef}
        isInvalid={!!activationErrorMessage}
      />
      {activationErrorMessage && (
        <Body data-testid="error" css={activationErrorMessageStyles}>
          {activationErrorMessage}
        </Body>
      )}
    </>
  );
}
