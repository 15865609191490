import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import _ from 'underscore';

type ActionOrThunkAction = Action | ThunkAction<any, any, any, any>;

/**
 * An action to be dispatched that conditionally dispatches a given action, depending on the result of
 * a given selector.
 *
 * @example
 * class MyComponent extends Component {
 *     UNSAFE_componentWillMount() {
 *         // Will not fetch users from the API if we already have them in state.
 *         this.props.fetchUsers();
 *     }
 *     // ...
 * }
 * export default connect(
 *     (state) => ({
 *         users: getUsers(state),
 *     }),
 *     {
 *         fetchUsers: fetchIfNeeded(fetchUsers, getUsers),
 *     }
 * )(MyComponent);
 * @example
 * // Or for an action creator that needs parameters...
 * export default connect(
 *     (state) => ({ users: getUsers(state) }),
 *     (dispatch, { groupId }) => ({
 *         fetchUsers: () => dispatch(fetchIfNeeded(() => fetchUsers({ groupId }), getUsers)()),
 *     })
 * )(MyComponent);
 * @return
 *   Action creator, which returns a thunk action, which returns a promise that is either the
 *   result of dispatch(fetchAction) or an already-resolved promise.
 */
const fetchIfNeeded =
  <ActionArgs extends Array<any>, ActionResult extends ActionOrThunkAction, StateVal, State = any>(
    /** The action creator to dispatch that will perform a fetch */
    fetchActionCreator: (...args: ActionArgs) => ActionResult,
    /** A selector function to get the data used to decide whether to perform a fetch */
    selector: (state: State) => StateVal,
    /**
     * A function to determine if the fetch is needed or not. The result of the selector will be passed to it.
     * If and only if this function returns true, then the action will be dispatched.
     * Defaults to _.isEmpty.
     */
    isNeeded: (val: StateVal) => boolean = _.isEmpty
  ) =>
  (...args: ActionArgs): ThunkAction<Promise<StateVal>, State, never, Action> =>
  async (dispatch, getState) => {
    if (isNeeded(selector(getState()))) {
      await dispatch(fetchActionCreator(...args));
    }

    return selector(getState());
  };

export default fetchIfNeeded;
