import * as api from 'js/common/services/api';

const initialState = {
  metrics: [],
};

// actions
const FETCH_ONLINE_ARCHIVES = 'nds/onlineArchives/fetch';
const UPDATE_ONLINE_ARCHIVE = 'nds/onlineArchives/update';
const DELETE_ONLINE_ARCHIVE = 'nds/onlineArchives/delete';
const UPDATE_METRICS = 'nds/onlineArchives/updateMetrics';

export default function onlineArchiveReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ONLINE_ARCHIVES: {
      return {
        ...state,
        onlineArchives: payload,
      };
    }
    case UPDATE_ONLINE_ARCHIVE: {
      return {
        ...state,
        onlineArchives: (state as any).onlineArchives.map((a) => (a._id === payload._id ? payload : a)),
      };
    }
    case DELETE_ONLINE_ARCHIVE: {
      return {
        ...state,
        onlineArchives: (state as any).onlineArchives.map((a) =>
          a._id === payload ? { ...a, archiveState: 'DELETED' } : a
        ),
      };
    }
    case UPDATE_METRICS: {
      let found = (state as any).metrics.some((a) => a.archiveId === payload.archiveId);

      if (found) {
        return {
          ...state,
          metrics: (state as any).metrics.map((d) => (d.archiveId === payload.archiveId ? payload : d)),
        };
      }

      return {
        ...state,
        metrics: [...(state as any).metrics, payload],
      };
    }

    default: {
      return state;
    }
  }
}

// selectors
export const onlineArchivesSelector = (state) => state.nds.onlineArchives;

// action creators
const setOnlineArchives = (onlineArchives) => ({
  type: FETCH_ONLINE_ARCHIVES,
  payload: onlineArchives,
});

const setOnlineArchiveUpdated = (updatedArchive) => ({
  type: UPDATE_ONLINE_ARCHIVE,
  payload: updatedArchive,
});

const setOnlineArchiveDeleted = (archiveId) => ({
  type: DELETE_ONLINE_ARCHIVE,
  payload: archiveId,
});

const setMetrics = (metrics) => ({
  type: UPDATE_METRICS,
  payload: metrics,
});

export const loadMetrics = (groupId, clusterName, archiveId) => (dispatch) => {
  return api.nds.onlineArchive.getMetrics(groupId, clusterName, archiveId).then((metrics) => {
    dispatch(setMetrics(metrics));
  });
};

export const loadOnlineArchives = (groupId, clusterName) => (dispatch) => {
  return api.nds.onlineArchive.getOnlineArchives(groupId, clusterName).then((onlineArchives) => {
    dispatch(setOnlineArchives(onlineArchives));
  });
};

export const loadOnlineArchivesForGroup = (groupId) => (dispatch) => {
  return api.nds.onlineArchive.getOnlineArchivesForGroup(groupId).then((onlineArchives) => {
    dispatch(setOnlineArchives(onlineArchives));
  });
};

export const updateOnlineArchive = (groupId, clusterName, archiveId, update) => (dispatch) => {
  return api.nds.onlineArchive.updateOnlineArchive(groupId, clusterName, archiveId, update).then((updatedArchive) => {
    dispatch(setOnlineArchiveUpdated(updatedArchive));
  });
};

export const deleteOnlineArchive = (groupId, archiveId) => (dispatch) => {
  return api.nds.onlineArchive.deleteOnlineArchive(groupId, archiveId).then(() => {
    dispatch(setOnlineArchiveDeleted(archiveId));
  });
};
