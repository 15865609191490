import _ from 'underscore';

import { ApiUser } from '@packages/types/access';
import { Role } from '@packages/types/roles';

import * as org from '@packages/redux/modules/organization';
import { getActiveGroupId, getActiveOrgId } from '@packages/redux/modules/app';
import { GRANT_API_USER_GROUP_ACCESS } from '@packages/redux/modules/group';

import * as api from 'js/common/services/api';
import { permissionValueToLabelMap } from 'js/common/services/permissionsService';

// actions
const ADD_API_USER = 'apiUser/ADD_API_USER';
const UPDATE_API_USER = 'apiUser/UPDATE_API_USER';
const SET_MULTIPLE_API_USERS = 'apiUser/SET_MULTIPLE_API_USERS';
const UPDATE_API_USER_GROUP_ROLES = 'apiUser/UPDATE_API_USER_GROUP_ROLES';
const REMOVE_API_USER = 'apiUser/REMOVE_API_USER';
const REMOVE_API_USER_FROM_GROUP = 'apiUser/REMOVE_API_USER_FROM_GROUP';
const SET_API_USER_ACCESSLIST = 'apiUser/SET_API_USER_ACCESSLIST';
const ADD_ACCESSLIST_ENTRY = 'apiUser/ADD_ACCESSLIST_ENTRY';
const DELETE_ACCESSLIST_ENTRY = 'apiUser/DELETE_ACCESSLIST_ENTRY';
const UPDATE_ORG_UI_ACCESS_LIST_INHERITANCE = 'apiUser/UPDATE_ORG_UI_ACCESS_LIST_INHERITANCE';

type ApiUserState = { [apiUserId in string]?: ApiUser };

const initialState: ApiUserState = {};

// eslint-disable-next-line no-multi-assign
export default function apiUserReducer(state: ApiUserState = initialState, action): ApiUserState {
  switch (action.type) {
    case ADD_API_USER: {
      const apiUser = action.payload;

      return {
        ...state,
        [apiUser.userId]: apiUser,
      };
    }
    case UPDATE_API_USER: {
      const { apiUserId, orgRoles, orgRolesString, desc } = action.payload;

      return {
        ...state,
        [apiUserId]: {
          ...state[apiUserId],
          orgRoles,
          orgRolesString,
          desc,
        },
      };
    }
    case SET_MULTIPLE_API_USERS: {
      const { payload } = action;

      if (payload.length === 0) {
        return state;
      }

      const nextState = { ...state };

      return payload.reduce((acc, apiUser) => {
        nextState[apiUser.userId] = {
          ...nextState[apiUser.userId],
          ...apiUser,
        };

        return acc;
      }, nextState);
    }
    case REMOVE_API_USER: {
      const apiUserId = action.payload;
      return _.omit(state, apiUserId);
    }
    case SET_API_USER_ACCESSLIST: {
      const { accessList, apiUserId } = action.payload;

      const nextState = { ...state };

      nextState[apiUserId] = {
        ...nextState[apiUserId]!,
        accessList,
      };

      return nextState;
    }
    case ADD_ACCESSLIST_ENTRY: {
      const { apiUserId, accessListEntry } = action.payload;

      const apiUser = state[apiUserId]!;
      const { accessList = [] } = apiUser;

      return {
        ...state,
        [apiUserId]: {
          ...apiUser,
          accessList: [...accessList, accessListEntry],
        },
      };
    }
    case DELETE_ACCESSLIST_ENTRY: {
      const { apiUserId, accessListEntryId } = action.payload;

      const apiUser = state[apiUserId]!;

      return {
        ...state,
        [apiUserId]: {
          ...apiUser,
          accessList: apiUser.accessList!.filter((accessListEntry) => accessListEntry.id !== accessListEntryId),
        },
      };
    }
    case UPDATE_ORG_UI_ACCESS_LIST_INHERITANCE: {
      const { apiUserId, shouldApplyOrgUiAccessListForApi } = action.payload;

      const apiUser = state[apiUserId]!;

      return {
        ...state,
        [apiUserId]: {
          ...apiUser,
          shouldApplyOrgUiAccessListForApi,
        },
      };
    }
    case UPDATE_API_USER_GROUP_ROLES: {
      const { apiUserId, roles, rolesString, groupId, desc } = action.payload;

      const apiUser = state[apiUserId]!;

      const groupRoles = apiUser.groupRoles.map((groupRole) => {
        if (groupRole.groupId !== groupId) {
          return groupRole;
        }

        return {
          groupId,
          roles,
          rolesString,
        };
      });

      return {
        ...state,
        [apiUserId]: {
          ...state[apiUserId],
          groupRoles,
          desc,
        },
      };
    }
    case REMOVE_API_USER_FROM_GROUP: {
      const { apiUserId, groupId } = action.payload;

      const apiUser = state[apiUserId]!;

      return {
        ...state,
        [apiUserId]: {
          ...apiUser,
          groupRoles: apiUser.groupRoles.filter((groupRole) => groupRole.groupId !== groupId),
        },
      };
    }
    case GRANT_API_USER_GROUP_ACCESS: {
      const { apiUsers, groupId } = action.payload;

      const nextState = {
        ...state,
      };

      apiUsers.forEach((update) => {
        const apiUser = nextState[update.userId]!;

        const nextGroupRoles = apiUser.groupRoles.map((groupRole) => {
          if (groupRole.groupId !== groupId) {
            return groupRole;
          }

          return {
            ...groupRole,
            roles: update.roles,
            rolesString: update.roles.map((role) => permissionValueToLabelMap[role]).join(),
          };
        });

        nextState[apiUser.userId] = {
          ...apiUser,
          groupRoles: nextGroupRoles,
        };
      });

      return nextState;
    }
    default:
      return state;
  }
}

const getApiUsersKeyedById = (state) => state.apiUser;

const findByUsername = (state: ApiUserState, { apiUsername }): ApiUser | {} | undefined => {
  return apiUsername && state.apiUser
    ? Object.values(state.apiUser).find((apiUser) => apiUser.username === apiUsername)
    : {};
};

export const getApiUser = (state, { apiUserId }) => {
  return getApiUsersKeyedById(state)[apiUserId] || {};
};

export const getAllHydratedApiUsers = (state, comparator) => {
  comparator =
    comparator ||
    ((a, b) => {
      if (a.orgId < b.orgId) {
        return -1;
      }

      if (a.orgId > b.orgId) {
        return 1;
      }

      return 0;
    });

  return Object.values(getApiUsersKeyedById(state)).sort(comparator);
};

export const getHydratedOrgApiUsers = (state, { orgId }: any = {}) => {
  orgId = orgId || getActiveOrgId(state);

  const orgApiUsers = org.getApiUserIds(state, { orgId }) || [];

  return orgApiUsers.map((apiUserId) => getApiUser(state, { apiUserId }));
};

export const getGroupApiUsers = (state, { groupId }: any = {}) => {
  groupId = groupId || getActiveGroupId(state);

  return Object.values(state.apiUser).filter(
    (apiUser) => (apiUser as any).groupRoles.filter((group) => group.groupId === groupId).length > 0
  );
};

// Action Creators

const addApiUser = (apiUser) => ({
  type: ADD_API_USER,
  payload: apiUser,
});

const updateApiUser = (apiUserId, orgRoles, orgRolesString, desc) => ({
  type: UPDATE_API_USER,
  payload: {
    apiUserId,
    orgRoles,
    orgRolesString,
    desc,
  },
});

const setMultipleApiUsers = (apiUsers = []) => ({
  type: SET_MULTIPLE_API_USERS,
  payload: apiUsers,
});

const removeApiUser = (apiUserId) => ({
  type: REMOVE_API_USER,
  payload: apiUserId,
});

const setApiUserAccessList = ({ accessList, apiUserId }) => ({
  type: SET_API_USER_ACCESSLIST,
  payload: {
    accessList,
    apiUserId,
  },
});

const addAccessListEntry = ({ apiUserId, accessListEntry }) => ({
  type: ADD_ACCESSLIST_ENTRY,
  payload: {
    apiUserId,
    accessListEntry,
  },
});

const setApiUserGroupRoles = ({ apiUserId, groupId, roles, rolesString, desc }) => ({
  type: UPDATE_API_USER_GROUP_ROLES,
  payload: {
    apiUserId,
    groupId,
    roles,
    rolesString,
    desc,
  },
});

const removeApiUserFromGroupAction = ({ apiUserId, groupId }) => ({
  type: REMOVE_API_USER_FROM_GROUP,
  payload: {
    apiUserId,
    groupId,
  },
});

const deleteAccessListEntry = ({ apiUserId, accessListEntryId }) => ({
  type: DELETE_ACCESSLIST_ENTRY,
  payload: {
    apiUserId,
    accessListEntryId,
  },
});

const updateOrgUiAccessListInheritance = ({ apiUserId, shouldApplyOrgUiAccessListForApi }) => ({
  type: UPDATE_ORG_UI_ACCESS_LIST_INHERITANCE,
  payload: {
    apiUserId,
    shouldApplyOrgUiAccessListForApi,
  },
});

export const createApiUserInOrg =
  ({ orgId, desc, roles }) =>
  async (dispatch, getState) => {
    orgId = orgId || getActiveOrgId(getState());

    const response = await api.organization.createApiUser({ orgId, desc, roles });

    const username = response.username;
    const nonRedactedPrivateKey = response.privateKey;

    // Not keeping apiKey in the store
    delete response.privateKey;

    dispatch(addApiUser(response));
    dispatch(org.addApiUser({ apiUserId: response.userId, orgId }));

    return { nonRedactedPrivateKey, username };
  };

export const createGlobalApiUser =
  ({ desc, roles }) =>
  async (dispatch) => {
    const response = await api.admin.createApiUser({ desc, roles });

    const username = response.username;
    const nonRedactedPrivateKey = response.privateKey;

    delete response.privateKey;

    dispatch(addApiUser(response));
    return { nonRedactedPrivateKey, username };
  };

export const editApiUser =
  ({ orgId, username, desc, roles }: { orgId?: string; username: string; desc?: string; roles: Array<Role> }) =>
  async (dispatch, getState) => {
    orgId = orgId || getActiveOrgId(getState());

    const apiUser = findByUsername(getState(), { apiUsername: username }) as ApiUser;

    const response = await api.organization.updateApiUser({ orgId, apiUserId: apiUser.userId, desc, roles });

    dispatch(updateApiUser(apiUser.userId, roles, response.orgRolesString, desc));
  };

export const loadOrgApiUsers = (orgId) => async (dispatch, getState) => {
  orgId = orgId || getActiveOrgId(getState());

  const apiUsers = await api.organization.getApiUsers({ orgId });

  dispatch(setMultipleApiUsers(apiUsers));

  dispatch(
    org.setApiUsers({
      apiUsers: apiUsers.map((apiUser) => apiUser.userId),
      orgId,
    })
  );
};

export const loadApiUsers = () => async (dispatch) => {
  const apiUsers = await api.admin.getApiUsers();

  dispatch(setMultipleApiUsers(apiUsers));
};

export const deleteApiUser =
  ({ orgId, apiUserId }) =>
  async (dispatch, getState) => {
    orgId = orgId || getActiveOrgId(getState());

    await api.organization.deleteApiUser({ orgId, apiUserId });

    dispatch(org.removeApiUser({ apiUserId, orgId }));

    dispatch(removeApiUser(apiUserId));
  };

export const saveApiUserGroupRoles =
  ({ groupId, apiUserId, roles, desc }: { groupId?: string; apiUserId: string; desc?: string; roles: Array<Role> }) =>
  async (dispatch, getState) => {
    groupId = groupId || getActiveGroupId(getState());

    await api.group.setApiUserRoles({
      groupId,
      apiUserId,
      roles,
      desc,
    });

    dispatch(
      setApiUserGroupRoles({
        apiUserId,
        groupId,
        roles,
        rolesString: roles.map((r) => permissionValueToLabelMap[r]).join(', '),
        desc,
      })
    );
  };

export const removeApiUserFromGroup =
  ({ groupId, apiUserId }) =>
  async (dispatch, getState) => {
    groupId = groupId || getActiveGroupId(getState());

    await api.group.removeApiUser({ groupId, apiUserId });

    dispatch(
      removeApiUserFromGroupAction({
        apiUserId,
        groupId,
      })
    );
  };

export const createApiUserInActiveGroup =
  ({ desc, roles }) =>
  async (dispatch, getState) => {
    const groupId = getActiveGroupId(getState());

    const response = await api.group.createApiUser({ groupId, desc, roles });

    const username = response.username;
    const nonRedactedPrivateKey = response.privateKey;
    // Not keeping apiKey in the store
    delete response.privateKey;

    dispatch(addApiUser(response));
    dispatch(org.addApiUser({ apiUserId: response.userId, orgId: getActiveOrgId(getState()) }));
    return { nonRedactedPrivateKey, username };
  };

export const loadOrgApiUserAccessList =
  ({ orgId, apiUsername }) =>
  async (dispatch, getState) => {
    orgId = orgId || getActiveOrgId(getState());

    const apiUser = findByUsername(getState(), { apiUsername });

    const response = await api.organization.getApiUserApiAccessList({
      orgId,
      apiUserId: (apiUser as any).userId,
    });

    dispatch(
      setApiUserAccessList({
        accessList: response,
        apiUserId: (apiUser as any).userId,
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ accessList: any; apiUserId: an... Remove this comment to see the full error message
        orgId,
      })
    );
  };

export const loadGroupApiUserAccessList =
  ({ groupId, apiUsername }) =>
  async (dispatch, getState) => {
    groupId = groupId || getActiveGroupId(getState());

    const apiUser = findByUsername(getState(), { apiUsername });

    const response = await api.group.getApiUserApiAccessList({ groupId, apiUserId: (apiUser as any).userId });

    dispatch(
      setApiUserAccessList({
        accessList: response,
        apiUserId: (apiUser as any).userId,
      })
    );
  };

export const addOrgApiUserAccessListEntry =
  ({ orgId, apiUserId, ipAddress }) =>
  async (dispatch, getState) => {
    orgId = orgId || getActiveOrgId(getState());

    const response = await api.organization.addApiUserAccessListEntry({ orgId, apiUserId, ipAddress });
    const action = addAccessListEntry({ apiUserId, accessListEntry: response });

    dispatch(action);
  };

export const addActiveGroupApiUserAccessListEntry =
  ({ apiUserId, ipAddress }) =>
  async (dispatch, getState) => {
    const groupId = getActiveGroupId(getState());

    const response = await api.group.addApiUserAccessListEntry({ groupId, apiUserId, ipAddress });

    dispatch(
      addAccessListEntry({
        apiUserId,
        accessListEntry: response,
      })
    );
  };

export const removeOrgApiUserAccessListEntry =
  ({ orgId, apiUserId, accessListEntryId }) =>
  async (dispatch, getState) => {
    orgId = orgId || getActiveOrgId(getState());

    await api.organization.deleteApiUserAccessListEntry({ orgId, apiUserId, accessListEntryId });

    dispatch(
      deleteAccessListEntry({
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ orgId: any; apiUserId: any; ac... Remove this comment to see the full error message
        orgId,
        apiUserId,
        accessListEntryId,
      })
    );
  };

export const removeActiveGroupApiUserAccessListEntry =
  ({ groupId, apiUserId, accessListEntryId }) =>
  async (dispatch, getState) => {
    groupId = groupId || getActiveGroupId(getState());

    await api.group.deleteApiUserAccessListEntry({ groupId, apiUserId, accessListEntryId });

    dispatch(
      deleteAccessListEntry({
        apiUserId,
        accessListEntryId,
      })
    );
  };

export const updateOrgApiUserUiAccessListInheritance =
  ({ orgId, apiUserId, shouldApplyOrgUiAccessListForApi }) =>
  async (dispatch, getState) => {
    orgId = orgId || getActiveOrgId(getState());

    await api.organization.updateOrgUiAccessListInheritance({
      orgId,
      apiUserId,
      shouldApplyOrgUiAccessListForApi,
    });

    dispatch(
      updateOrgUiAccessListInheritance({
        apiUserId,
        shouldApplyOrgUiAccessListForApi,
      })
    );
  };

export const updateActiveGroupApiUserUiAccessListInheritance =
  ({ groupId, apiUserId, shouldApplyOrgUiAccessListForApi }) =>
  async (dispatch, getState) => {
    groupId = groupId || getActiveGroupId(getState());

    await api.group.updateOrgUiAccessListInheritance({
      groupId,
      apiUserId,
      shouldApplyOrgUiAccessListForApi,
    });

    dispatch(
      updateOrgUiAccessListInheritance({
        apiUserId,
        shouldApplyOrgUiAccessListForApi,
      })
    );
  };

export { findByUsername };
