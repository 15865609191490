import { Action, AnyAction, configureStore, Reducer } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';

export default function createStore<S, A extends Action = AnyAction>(rootReducer: Reducer<S, A>, preloadedState?) {
  return configureStore<S, A>({
    reducer: rootReducer,
    preloadedState,
    middleware: [thunk], // TODO(CLOUDP-142465): Remove this and use Redux default middleware
  });
}
