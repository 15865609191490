import { Dispatch } from 'redux';

import {
  CloudProviderAccess,
  CloudProviderAccessAWSIAMRole,
  CloudProviderAccessAzureServicePrincipal,
  CloudProviderAccessAzureServicePrincipalCreateRequest,
} from '@packages/types/nds/cloudProviderAccess';

import * as api from 'js/common/services/api/nds/cloudProviderAccessApi';

// Action Types
export enum CloudProviderAccessActionTypes {
  FetchCloudProviderAccess = 'nds/cloudProviderAccess/FETCH',
  DeleteCloudProviderAccessAWSIAMRole = 'nds/cloudProviderAccess/DELETE',
  PostCloudProviderAccessAWSIAMRole = 'nds/cloudProviderAccess/POST',
  PostCloudProviderAccessAzureServicePrincipal = 'nds/cloudProviderAccess/POST/Azure',
  PatchCloudProviderAccessAWSIAMRole = 'nds/cloudProviderAccess/PATCH',
  deleteCloudProviderAccessAzureServicePrincipal = 'nds/cloudProviderAccess/DELETE/Azure',
}

const defaultState: CloudProviderAccess = {
  awsIamRoles: [],
  azureServicePrincipals: [],
};

export interface FetchCloudProviderAccessAction {
  type: CloudProviderAccessActionTypes;
  payload: CloudProviderAccess;
}

interface CloudProviderAccessAWSIAMRoleAction {
  type: CloudProviderAccessActionTypes;
  payload: CloudProviderAccessAWSIAMRole;
}
interface CloudProviderAccessAzureServicePrincipalAction {
  type: CloudProviderAccessActionTypes;
  payload: CloudProviderAccessAzureServicePrincipal;
}

interface CloudProviderAccessAzureServicePrincipalAction {
  type: CloudProviderAccessActionTypes;
  payload: CloudProviderAccessAzureServicePrincipal;
}

function createdDateComparator(a: { createdDate: string }, b: { createdDate: string }): number {
  const aDate: string = a.createdDate || new Date(0).toISOString();
  const bDate: string = b.createdDate || new Date(0).toISOString();
  return aDate.localeCompare(bDate);
}

// Reducer
export default function cloudProviderAccessReducer(
  state: CloudProviderAccess = defaultState,
  action
): CloudProviderAccess {
  switch (action.type) {
    case CloudProviderAccessActionTypes.FetchCloudProviderAccess: {
      const { awsIamRoles = [], azureServicePrincipals = [] } = action.payload as CloudProviderAccess;
      return {
        ...state,
        awsIamRoles: awsIamRoles.sort(createdDateComparator),
        azureServicePrincipals: azureServicePrincipals.sort(createdDateComparator),
      };
    }
    case CloudProviderAccessActionTypes.DeleteCloudProviderAccessAWSIAMRole: {
      const deletedRoleId = action.payload.roleId;
      return {
        ...state,
        awsIamRoles: state.awsIamRoles.filter((role) => role.roleId !== deletedRoleId),
      };
    }
    case CloudProviderAccessActionTypes.deleteCloudProviderAccessAzureServicePrincipal: {
      const deletedPrincipalId = (action.payload as CloudProviderAccessAzureServicePrincipal)._id;
      return {
        ...state,
        azureServicePrincipals: state.azureServicePrincipals.filter((sp) => sp._id !== deletedPrincipalId),
      };
    }
    case CloudProviderAccessActionTypes.PostCloudProviderAccessAWSIAMRole: {
      return {
        ...state,
        awsIamRoles: [action.payload, ...state.awsIamRoles].sort(createdDateComparator),
      };
    }
    case CloudProviderAccessActionTypes.PostCloudProviderAccessAzureServicePrincipal: {
      return {
        ...state,
        azureServicePrincipals: [action.payload, ...state.azureServicePrincipals].sort(createdDateComparator),
      };
    }
    case CloudProviderAccessActionTypes.PatchCloudProviderAccessAWSIAMRole: {
      const patchedRole: CloudProviderAccessAWSIAMRole = action.payload;
      return {
        ...state,
        awsIamRoles: [
          patchedRole,
          ...state.awsIamRoles.filter((awsIamRole) => awsIamRole.roleId !== patchedRole.roleId),
        ].sort(createdDateComparator),
      };
    }
    default:
      return state;
  }
}

interface State {
  nds: {
    cloudProviderAccess: CloudProviderAccess;
  };
}

// Selectors
export const getCloudProviderAccess = (state: State): CloudProviderAccess => state.nds.cloudProviderAccess;
export const selectAwsIamRoles = (state: State): Array<CloudProviderAccessAWSIAMRole> =>
  getCloudProviderAccess(state).awsIamRoles;
export const selectValidatedAwsIamRoles = (state: State): Array<CloudProviderAccessAWSIAMRole> =>
  selectAwsIamRoles(state).filter((awsIamRole) => !!awsIamRole.iamAssumedRoleArn);
export const selectAzureServicePrincipals = (state: State): Array<CloudProviderAccessAzureServicePrincipal> =>
  getCloudProviderAccess(state).azureServicePrincipals;

// Action Creators
export const loadCloudProviderAccess = (groupId: string) => (dispatch: Dispatch) =>
  api.fetchCloudProviderAccess(groupId).then((response) => {
    const action: FetchCloudProviderAccessAction = {
      type: CloudProviderAccessActionTypes.FetchCloudProviderAccess,
      payload: response,
    };
    return dispatch(action);
  });

export const removeCloudProviderAccessAWSIAMRole = (
  role: CloudProviderAccessAWSIAMRole
): CloudProviderAccessAWSIAMRoleAction => ({
  type: CloudProviderAccessActionTypes.DeleteCloudProviderAccessAWSIAMRole,
  payload: role,
});

export const removeCloudProviderAccessAzureServicePrincipal = (
  servicePrincipal: CloudProviderAccessAzureServicePrincipal
): CloudProviderAccessAzureServicePrincipalAction => ({
  type: CloudProviderAccessActionTypes.deleteCloudProviderAccessAzureServicePrincipal,
  payload: servicePrincipal,
});

export const addCloudProviderAccessAWSIAMRole = (
  role: CloudProviderAccessAWSIAMRole
): CloudProviderAccessAWSIAMRoleAction => ({
  type: CloudProviderAccessActionTypes.PostCloudProviderAccessAWSIAMRole,
  payload: role,
});

export const addCloudProviderAccessAzureServicePrincipal = (
  servicePrincipal: CloudProviderAccessAzureServicePrincipal
): CloudProviderAccessAzureServicePrincipalAction => ({
  type: CloudProviderAccessActionTypes.PostCloudProviderAccessAzureServicePrincipal,
  payload: servicePrincipal,
});

export const updateCloudProviderAccessAWSIAMRole = (
  role: CloudProviderAccessAWSIAMRole
): CloudProviderAccessAWSIAMRoleAction => ({
  type: CloudProviderAccessActionTypes.PatchCloudProviderAccessAWSIAMRole,
  payload: role,
});

export const deleteCloudProviderAccessAWSIAMRole =
  (groupId: string, role: CloudProviderAccessAWSIAMRole) => (dispatch: Dispatch) =>
    api
      .deleteCloudProviderAccessAWSIAMRole(groupId, role)
      .then((response) => dispatch(removeCloudProviderAccessAWSIAMRole(role)));

export const deleteCloudProviderAccessAzureServicePrincipal =
  (groupId: string, servicePrincipal: CloudProviderAccessAzureServicePrincipal) => (dispatch: Dispatch) =>
    api
      .deleteCloudProviderAccessAzureServicePrincipal(groupId, servicePrincipal)
      .then((response) => dispatch(removeCloudProviderAccessAzureServicePrincipal(servicePrincipal)));

export const postCloudProviderAccessAWSIAMRole =
  (groupId: string, awsIamRole: Partial<CloudProviderAccessAWSIAMRole> = {}) =>
  (dispatch: Dispatch) =>
    api.postCloudProviderAccessAWSIAMRole(groupId, awsIamRole).then((response) => {
      dispatch(addCloudProviderAccessAWSIAMRole(response));
      return response;
    });

export const postCloudProviderAccessAzureServicePrincipal =
  (groupId: string, servicePrincipalRequest: CloudProviderAccessAzureServicePrincipalCreateRequest) =>
  (dispatch: Dispatch) =>
    api.postCloudProviderAccessAzureServicePrincipal(groupId, servicePrincipalRequest).then((response) => {
      dispatch(addCloudProviderAccessAzureServicePrincipal(response));
      return response;
    });

export const patchCloudProviderAccessAWSIAMRole =
  (groupId: string, role: CloudProviderAccessAWSIAMRole) => (dispatch: Dispatch) =>
    api
      .patchCloudProviderAccessAWSIAMRole(groupId, role)
      .then((response) => dispatch(updateCloudProviderAccessAWSIAMRole(response)));
