// These really belong in packages/intercom, but they're used by authErrorHelper
// which is in js/common and therefore shouldn't depend on packages/intercom.
// Eventually we should move authErrorHelper somewhere else
// where it can be allowed to depend on packages/intercom
// and then move these into packages/intercom.

export const showIntercom = () => {
  if ((window as any).Intercom) {
    (window as any).Intercom('show');
  }
};

export const hideIntercom = () => {
  if ((window as any).Intercom) {
    (window as any).Intercom('hide');
  }
};
