import { css } from '@emotion/react';
import Banner, { Variant as BannerVariant } from '@leafygreen-ui/banner';

import intercomService from '@packages/intercom/intercomService';

import * as errorHelper from 'js/common/services/authErrorHelper';

import colors from 'js/auth/utils/palette';

import { ErrorMessage } from './styles/errorMessage';

const textLink = css({
  textDecoration: 'none',
  color: colors.link,
  cursor: 'pointer',
});

const getInvalidUserAuthErrorMessage = () => {
  return (
    <>
      Please provide a valid email address and password. If you continue to have issues logging into your account,
      contact our{' '}
      <a
        role="button"
        onClick={() => {
          intercomService.showIntercom();
        }}
        tabIndex={0}
        css={textLink}
      >
        Support team.
      </a>
    </>
  );
};

export default function AuthErrorBanner({
  authErrorCode,
  onPrem = false,
  marketingUrl,
  siteName,
  className,
}: {
  authErrorCode: string;
  onPrem?: boolean;
  marketingUrl?: string;
  siteName: string;
  className?: string;
}) {
  return (
    <Banner variant={BannerVariant.Danger} data-testid="bannerAuthCodeError" className={className}>
      <ErrorMessage>
        {authErrorCode === 'INVALID_USER_AUTH' && !onPrem
          ? getInvalidUserAuthErrorMessage()
          : errorHelper.getErrorMessageFromCode(authErrorCode, { marketingUrl, siteName })}
      </ErrorMessage>
    </Banner>
  );
}
