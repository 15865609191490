import PropTypes from 'prop-types';

// Looking for a Typescript RegionOptionShape? Use RegionView in replicationSpec.ts
const REGION_OPTION_SHAPE = {
  continent: PropTypes.string,
  isRecommended: PropTypes.bool,
  key: PropTypes.string,
  latitude: PropTypes.number,
  location: PropTypes.string,
  longitude: PropTypes.number,
  name: PropTypes.string,
  provider: PropTypes.string,
};

export { REGION_OPTION_SHAPE };
