import { FTSCreateIndexRequest, FTSIndex, FTSUpdateIndexRequest } from '@packages/types/clusters/atlasSearch';

import fetchWrapper from '../fetchWrapper';

export default {
  createFTSIndex(groupId: string, clusterName: string, data: FTSCreateIndexRequest, createCollection: boolean = false) {
    return fetchWrapper(`/nds/clusters/${groupId}/${clusterName}/fts/indexes?createCollection=${createCollection}`, {
      method: 'POST',
      body: JSON.stringify(data),
    }).then((resp) => resp.json());
  },

  updateFTSIndex(groupId: string, clusterName: string, data: FTSUpdateIndexRequest) {
    return fetchWrapper(`/nds/clusters/${groupId}/${clusterName}/fts/indexes/${data.indexDefinition.indexID}`, {
      method: 'POST',
      body: JSON.stringify(data),
    }).then((resp) => resp.json());
  },

  fetchFTSIndexes(groupId, clusterName): Promise<Array<FTSIndex>> {
    return fetchWrapper(`/nds/clusters/${groupId}/${clusterName}/fts/indexes`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  fetchFTSIndexesMetadata(groupId, clusterName) {
    return fetchWrapper(`/nds/clusters/${groupId}/${clusterName}/fts/indexesMetadata`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  deleteFTSIndex(groupId, clusterName, indexId) {
    return fetchWrapper(`/nds/clusters/${groupId}/${clusterName}/fts/indexes/${indexId}`, {
      method: 'DELETE',
    }).then((resp) => resp.json());
  },

  fetchFTSAnalyzers(groupId, clusterName) {
    return fetchWrapper(`/nds/clusters/${groupId}/${clusterName}/fts/analyzers`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  putFTSAnalyzers(groupId, clusterName, analyzers) {
    return fetchWrapper(`/nds/clusters/${groupId}/${clusterName}/fts/analyzers`, {
      method: 'PUT',
      body: JSON.stringify(analyzers),
    }).then((resp) => resp.json());
  },

  fetchFTSIndexDraft(groupId, clusterName) {
    return fetchWrapper(`/nds/clusters/${groupId}/${clusterName}/fts/indexDraft`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  updateFTSIndexDraft(groupId, clusterName, data) {
    return fetchWrapper(`/nds/clusters/${groupId}/${clusterName}/fts/indexDraft`, {
      method: 'POST',
      body: JSON.stringify(data),
    }).then((resp) => resp.json());
  },

  deleteFTSIndexDraft(groupId, clusterName) {
    return fetchWrapper(`/nds/clusters/${groupId}/${clusterName}/fts/indexDraft`, {
      method: 'DELETE',
    });
  },
};
