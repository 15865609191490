import * as api from 'js/common/services/api';

// Action Types
const SET_LIVE_IMPORTS = 'nds/liveImports/SET_LIVE_IMPORTS';

const initialState = {};

// Reducer
export default function liveImportsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LIVE_IMPORTS: {
      const newState = {
        ...state,
        liveImports: action.payload,
      };
      return newState;
    }
    default:
      return state;
  }
}

const getLiveImportsData = (state) => {
  return state.nds.liveImports;
};

// Selectors
export const getLiveImports = (state) => getLiveImportsData(state) || {};

// Action Creators
const setLiveImports = (payload) => ({
  type: SET_LIVE_IMPORTS,
  payload,
});
export { setLiveImports };

export const loadLiveImports = (groupId) => (dispatch) => {
  return api.nds.liveImports.getLiveImports(groupId).then((response) => {
    return dispatch(setLiveImports(response));
  });
};
