import { auth } from 'js/common/services/api';
import { getDotComCookieProperties } from 'js/common/utils/cookieHelpers';
import analytics, { FormType, SEGMENT_EVENTS, TrackProperties } from 'js/common/utils/segmentAnalytics';

import { getEmailType, isBusinessEmail } from './analyticsUtils';

declare global {
  interface Window {
    GetElqCustomerGUID: () => string;
  }
}

interface Session {
  jump_id?: string;
  tck?: string;
  ad_id?: string;
  ad_id_set?: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_medium?: string;
  utm_source?: string;
  utm_term?: string;
  gclid?: string;
  form_url?: string;
}

export type EloquaData = {
  data: Object;
  elqSiteID: number;
  elqFormName: string;
  elqCustomerGUID: string;
  elqCookieWrite: number;
  url: string;
  anonymousId: string;
  web_check_businessemail: boolean;
} & Session &
  TrackProperties;

/*
  NOTE: This is a mirror of `session` method from Marketing code hosted on
  mongodbcom-node repo and is used to generate data to send to eloqua for analytics.
*/
function getSessionInfo(locationObj) {
  const s: Session = {};
  const arr = [
    'jmp',
    'tck',
    'ad_id',
    'ad_id_set',
    'utm_campaign',
    'utm_content',
    'utm_medium',
    'utm_source',
    'utm_term',
    'gclid',
    'form_url',
  ];

  arr.forEach((prop) => {
    if (locationObj[prop]) {
      if (prop === 'jmp') {
        s.jump_id = locationObj[prop];
      } else {
        s[prop] = locationObj[prop];
      }
    }
  });

  return s;
}

/*
  NOTE: This is a mirror of `post` method from Marketing code hosted on
  mongodbcom-node repo and is used to send data to eloqua for analytics.
  The request is made from backend from the /eloqua endpoint in AccountAuthResource
*/
export function eloquaPost({ data, locationObj, siteId, clearbitData }) {
  const dotComCookieProperties = getDotComCookieProperties();
  const { userAgent } = window.navigator;
  const eloquaData: EloquaData = {
    ...getSessionInfo(locationObj),
    ...data,
    elqSiteID: parseInt(siteId, 10),
    elqFormName: 'atlas-registration-1537380126407',
    elqCustomerGUID: typeof window.GetElqCustomerGUID === 'function' ? window.GetElqCustomerGUID() : '',
    elqCookieWrite: 0,
    url: window.location.href,
    anonymousId: data.anonymousId,
    web_check_businessemail: isBusinessEmail(data.email),
    userAgent,
    ...clearbitData,
    email_type: getEmailType(data.email),
    form_type: FormType.REGISTRATION,
  };

  analytics.track(SEGMENT_EVENTS.FORM_SUBMITTED, eloquaData);

  return auth.eloqua({ ...eloquaData, ...dotComCookieProperties });
}
