import { css } from '@emotion/react';

import { mq } from 'js/common/utils/mediaQueries';

import { CONTAINER_MARGINS } from 'js/auth/components/styles/form';

// Leafygreen banners have additional horizontal padding of 32px
const BANNER_PADDING = 32;

export const bannerStyle = css(
  mq({
    marginBottom: '30px',
    marginTop: '20px',
    minHeight: '0px',
    // We want the banner to be 300px to match other elements so we subtract additional padding here
    width: [
      300 - BANNER_PADDING,
      `calc(100vw - ${BANNER_PADDING + CONTAINER_MARGINS.mobile.marginX * 2}px)`,
      300 - BANNER_PADDING,
      300 - BANNER_PADDING,
    ],
    wordBreak: 'break-word',
  })
);
