import { useEffect, useState } from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Banner from '@leafygreen-ui/banner';
import Button from '@leafygreen-ui/button';
import TextInput from '@leafygreen-ui/text-input';
import { H2, H3 } from '@leafygreen-ui/typography';
import queryString from 'query-string';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { Search } from '@packages/types/auth/search';
import { SocialAuthMethods } from '@packages/types/authMethod';

import ThirdPartyIntegrationLayout from '@packages/components/ThirdPartyIntegration/ThirdPartyIntegrationLayout';
import ThirdPartyIntegrationPage from '@packages/components/ThirdPartyIntegration/ThirdPartyIntegrationPage';

import * as api from 'js/common/services/api';
import * as errorHelper from 'js/common/services/authErrorHelper';
import * as oktaUtils from 'js/common/utils/oktaUtils';
import { CloudTeams, sendError } from 'js/common/utils/bugsnag';
import { MediumPlusViewport, mq, SmallViewport, useViewportSize } from 'js/common/utils/mediaQueries';

import colors from 'js/auth/utils/palette';

import HeaderLogo from './HeaderLogo';
import Layout from './Layout';
import { Container, header } from './styles/form';

const BannerContainer = styled.div((props: { isVercelIntegration?: boolean }) =>
  mq({
    height: ['auto', 'auto', 'auto', 40],
    width: props.isVercelIntegration ? '100%' : ['387px', 'calc(100vw - 36px)', 'calc(100vw - 314px)', '387px'],
    margin: '24px 0px',
  })
);

const TextInputContainer = styled.div(() =>
  mq({
    margin: '24px 0px',

    '> div': {
      marginBottom: '24px',
    },
  })
);

const input = css(
  mq({
    width: ['300px', 'calc(100vw - 36px)', 'calc(100vw - 314px)', '300px'],
    input: {
      height: ['36px', '52px', '36px', '36px'],
      fontSize: ['14px', '16px', '14px', '14px'],
      lineHeight: ['20px', '24px', '20px', '20px'],
    },

    p: {
      fontSize: ['14px', '16px', '14px', '14px'],
      lineHeight: ['20px', '24px', '20px', '20px'],
    },

    label: {
      fontSize: ['14px', '16px', '14px', '14px'],
      lineHeight: ['20px', '24px', '20px', '20px'],
    },
  })
);

const text = css(
  mq({
    height: ['auto', 'auto', 'auto', 60],
    width: ['680px', 'calc(100vw - 20px)', 'calc(100vw - 260px)', '680px'],
    margin: '24px 0px',
    color: colors.headerText,
    fontSize: [14, 16, 16, 14],
    letterSpacing: ['0', 'normal', 'normal', '0'],
    lineHeight: ['20px', '24px', '24px', '20px'],
  })
);

const vercelText = css(
  mq({
    height: ['auto', 'auto', 'auto', 60],
    width: '100%',
    margin: '24px 0px',
    color: colors.headerText,
    fontSize: [14, 16, 16, 14],
    letterSpacing: ['0', 'normal', 'normal', '0'],
    lineHeight: ['20px', '24px', '24px', '20px'],
  })
);

const linkAccountsButtonsContainer = css(
  mq({
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: ['row', 'column-reverse', 'row', 'row'],
    width: ['380px', 'calc(100vw - 20px)', 'calc(100vw - 260px)', '416px'],
    alignItems: ['normal', 'center', 'normal', 'normal'],
    position: ['relative', 'absolute', 'relative', 'relative'],
    bottom: 0,

    a: {
      margin: '16px 0',
      color: colors.link,
      textDecoration: 'none',
      lineHeight: '19px',
      fontSize: '15px',
    },

    button: {
      width: [182, 'calc(100vw - 70px)', 240, 200],
      height: [32, 45, 45, 32],
      fontSize: [15, 18, 18, 15],
      lineHeight: ['17px', '21px', '21px', '17px'],
    },
  })
);

const vercelLinkAccountsButtonsContainer = css(
  mq({
    display: 'inline-flex',
    flexDirection: ['row', 'column-reverse', 'row', 'row'],
    alignItems: ['normal', 'center', 'normal', 'normal'],
    position: ['relative', 'relative', 'relative', 'relative'],
    bottom: 0,
    marginTop: '24px',

    a: {
      margin: '16px 0',
      color: colors.link,
      textDecoration: 'none',
      lineHeight: '19px',
      fontSize: '15px',
    },

    button: {
      height: [32, 45, 45, 32],
      fontSize: [15, 16, 16, 15],
      lineHeight: ['17px', '21px', '21px', '17px'],
      marginRight: '40px',
    },
  })
);

const ConfirmIdentityButtonsContainer = styled.footer((props: { isVercelIntegration?: boolean }) =>
  mq({
    width: props.isVercelIntegration ? '100%' : ['300px', 'calc(100vw - 36px)', 'calc(100vw - 314px)', '300px'],
    display: 'flex',
    flexDirection: ['row', 'column-reverse', 'row', 'row'],
    alignItems: ['normal', 'center', 'normal', 'normal'],
    marginTop: [0, '166px', 0, 0],

    button: {
      width: ['auto', 'calc(100vw - 36px)', 240, 'auto'],
      height: [32, 45, 45, 32],
      fontSize: [15, 18, 18, 15],
      lineHeight: ['17px', '21px', '21px', '17px'],
    },

    a: {
      color: colors.link,
      textDecoration: 'none',
      margin: '16px 0 20px',
      lineHeight: '30px',
      fontSize: '17px',
    },
  })
);

interface IdentityVerificationPageProps {
  location?: Pick<Location, 'search'>;
  windowLocation?: Pick<Location, 'assign'>;
  oktaSessionExists?: () => Promise<boolean>;
  endOktaSession?: () => void;
}

export default function IdentityVerificationPage({
  location: { search } = { search: '' },
  windowLocation = window.location,
  oktaSessionExists = oktaUtils.oktaSessionExists,
  endOktaSession = oktaUtils.endOktaSession,
}: IdentityVerificationPageProps) {
  const [showIdentityFields, setShowIdentityFields] = useState(false);
  const [password, setPassword] = useState('');
  const [authErrorCode, setAuthErrorCode] = useState('');
  const [formSubmitting, setFormSubmitting] = useState(false);
  const parsedSearchLocation = queryString.parse(search) as Search;
  const { email, isVercelIntegration, signupMethod } = parsedSearchLocation;
  const sanitizedSignupMethod = signupMethod?.toUpperCase() ?? '';
  const isSocialUser = Object.keys(SocialAuthMethods).includes(sanitizedSignupMethod);
  const socialProvider = isSocialUser ? SocialAuthMethods[sanitizedSignupMethod] : null;

  const isMobile = useViewportSize('small');

  const bannerStyle = css(
    mq({
      marginRight: isMobile ? '0px' : '32px',
      minHeight: '0px',
    })
  );

  useEffect(() => {
    async function endOktaSessionIfExists() {
      try {
        const sessionExists = await oktaSessionExists();

        if (sessionExists) {
          await endOktaSession();
        }
      } catch (error) {
        sendError({
          error,
          team: CloudTeams.CoreIam,
        });
      }
    }

    endOktaSessionIfExists();
  }, []);

  const onClickCancel = async () => {
    try {
      await api.auth.cancelLinking({ username: email, socialProvider: signupMethod });
    } catch (error) {
      sendError({
        error,
        team: CloudTeams.CoreIam,
      });
    }
    if (isVercelIntegration) {
      // remove email query param when cancelling the linking as it will be added when attempting to link again
      let urlSearchParams = new URLSearchParams(search);
      urlSearchParams.delete('email');

      windowLocation.assign(`/account/login/vercel?signedOut=true&${urlSearchParams.toString()}`);
    } else {
      windowLocation.assign('/account/login?signedOut=true');
    }
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitting(true);

    let authResponse;
    try {
      authResponse = await api.auth.verify({ username: email, password, clientState: parsedSearchLocation });
    } catch ({ errorCode }) {
      setFormSubmitting(false);
      setAuthErrorCode(errorCode);
      return;
    }

    const { loginRedirect } = authResponse;
    windowLocation.assign(loginRedirect);
  };

  const getErrorMessage = () => {
    // We want to give a more detailed reason for why the MFA required error is being returned here
    if (authErrorCode === 'MULTI_FACTOR_AUTH_REQUIRED') {
      return 'Please disable your multi-factor authentication before continuing.';
    }

    return errorHelper.getErrorMessageFromCode(authErrorCode);
  };

  const renderIdentityVerification = () => {
    return (
      <>
        {showIdentityFields ? (
          <>
            {isVercelIntegration ? (
              <H3 css={header}>Verify Your Identity</H3>
            ) : (
              <H2 css={header}>Verify Your Identity</H2>
            )}
            <BannerContainer isVercelIntegration>
              <Banner variant="warning" css={bannerStyle}>
                Verify your identity by entering your password for your MongoDB Account below
              </Banner>
            </BannerContainer>
            <TextInputContainer>
              <TextInput
                css={!isVercelIntegration && input}
                label="Email Address"
                description={`This is your ${socialProvider} email address we've matched to your MongoDB Account`}
                disabled
                value={email}
                type="email"
              />
              <TextInput
                css={!isVercelIntegration && input}
                label="Password"
                placeholder="Enter your password"
                value={password}
                type="password"
                state={authErrorCode !== '' ? 'error' : 'none'}
                onChange={(e) => setPassword(e.target.value)}
                errorMessage={getErrorMessage()}
              />
            </TextInputContainer>
            <ConfirmIdentityButtonsContainer isVercelIntegration>
              <SmallViewport>
                <Link onClick={onClickCancel}>Cancel</Link>
              </SmallViewport>
              <MediumPlusViewport>
                <Button
                  variant="default"
                  size="default"
                  onClick={onClickCancel}
                  name="cancel"
                  css={css(mq({ marginRight: ['8px', 0, '24px', '8px'] }))}
                >
                  Cancel
                </Button>
              </MediumPlusViewport>
              <Button
                variant="primary"
                size="default"
                name="confirmIdentity"
                disabled={password === '' || formSubmitting || email === ''}
                type="submit"
              >
                Confirm
              </Button>
            </ConfirmIdentityButtonsContainer>
          </>
        ) : (
          <>
            {isVercelIntegration ? (
              <H3 css={header}>Link {socialProvider} Account</H3>
            ) : (
              <H2 css={header}>Link {socialProvider} Account</H2>
            )}
            <div data-testid="identityDescription" css={isVercelIntegration ? vercelText : text}>
              By linking your {socialProvider} account, we will switch your authentication method for accessing your
              MongoDB account from email address to Log in with {socialProvider}. Moving forward, your credentials,
              personal information, and two-factor authentication will be managed by your {socialProvider} account.
            </div>
            <div css={isVercelIntegration ? vercelLinkAccountsButtonsContainer : linkAccountsButtonsContainer}>
              <SmallViewport>
                <Link onClick={onClickCancel}>Cancel, Return to Login</Link>
              </SmallViewport>
              <MediumPlusViewport>
                <Button variant="default" size="default" onClick={onClickCancel} name="cancel">
                  Cancel, Return to Login
                </Button>
              </MediumPlusViewport>
              <Button
                variant="primary"
                size="default"
                name="linkMyAccounts"
                onClick={() => setShowIdentityFields(true)}
              >
                Link My Accounts
              </Button>
            </div>
          </>
        )}
      </>
    );
  };

  if (isVercelIntegration) {
    return (
      <ThirdPartyIntegrationPage pageTitle="Verify Identity">
        <ThirdPartyIntegrationLayout>
          <form onSubmit={onFormSubmit} method="post">
            {renderIdentityVerification()}
          </form>
        </ThirdPartyIntegrationLayout>
      </ThirdPartyIntegrationPage>
    );
  }

  return (
    <>
      <Helmet title="Verify Identity" />
      <Layout contentPlacement="right">
        <Container onSubmit={onFormSubmit} noValidate method="post">
          <HeaderLogo />
          {renderIdentityVerification()}
        </Container>
      </Layout>
    </>
  );
}
