import * as React from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { palette } from '@leafygreen-ui/palette';

import { getLabelStyle } from '@packages/components/ThirdPartyIntegration/styles/common';

import FormInput, { Input } from 'js/auth/components/FormInput';
import PasswordInputWithValidationCriteria from 'js/auth/components/PasswordInputWithValidationCriteria';
import { onChangeTypeDef } from 'js/auth/types/formInput';

interface PasswordInputProps {
  onChange?: onChangeTypeDef;
  value?: string;
  hasError?: boolean;
  errorMessage?: string;
  forNewUser?: boolean;
  tooltipText?: React.ReactNode;
  className?: string;
  disabled?: boolean;
}

const getInputStyle = (isDisabled?: boolean) => css`
  margin-bottom: 16px;
  ${Input} {
    width: 100%;
  }

  fieldset > label {
    ${getLabelStyle(isDisabled)}
  }
`;

export const ThirdPartyIntegrationPasswordInput = styled(PasswordInputWithValidationCriteria)<PasswordInputProps>`
  ${({ disabled }) => getInputStyle(disabled)}
`;

export const ThirdPartyIntegrationFormInput = styled(FormInput)`
  ${({ disabled }) => getInputStyle(disabled)}
`;

export const thirdPartyIntegrationTextLinkStyle = css`
  color: ${palette.blue.base};
  text-decoration: none;
  cursor: pointer;
  font-weight: 500;
  line-height: 24px;
`;

export const ThirdPartyIntegrationRedirectText = styled.span`
  color: ${palette.gray.dark2};
  display: block;
  font-size: 16px;
  font-weight: 400;
  a {
    ${thirdPartyIntegrationTextLinkStyle}
  }
`;

export const linkingErrorStyle = css`
  margin-top: 36px;
  margin-bottom: -36px;
`;

export const SocialAuthButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
