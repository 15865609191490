import queryString from 'query-string';

import { FastTenantRecordType } from '@packages/types/dashboard/fastTenantRecord';
import { ForcePlanningHost, ForcePlanningReplicaSet } from '@packages/types/dashboard/forcePlanning';
import { MTMCompaction } from '@packages/types/dashboard/MTMCompaction';
import { MTMCluster, ServerlessMTMPool } from '@packages/types/dashboard/MTMManager';
import { PinnedCluster } from '@packages/types/dashboard/pinnedCluster';
import { PlanExecutorJobPriorityConfig, PlanExecutorJobPrioritySettings } from '@packages/types/dashboard/planExecutor';
import { ServerlessMTMLoad, ServerlessTraceSummary } from '@packages/types/dashboard/ServerlessMTMPoolManager';

import fetchWrapper, { formParams } from '../fetchWrapper';
import envoy from './envoyApi';
import externalMaintenance from './externalMaintenanceApi';
import logIngestion from './logIngestionApi';
import serverlessLoadBalancingDeployment from './serverlessLoadBalancingDeploymentApi';

interface MTMUpdate {
  tenantInstanceSize: string | undefined;
  maxCapacity: string | undefined;
  assignmentEnabled: boolean | undefined;
  isolationGroupIds: string | null;
}

export interface CustomBuildFilterOptions {
  trueNameFilter?: string;
  trueNameAfter?: string;
  trueNameBefore?: string;
}

export interface MongoDbBuild {
  trueName: string;
  gitVersion: string;
  modules: Array<string>;
  architecture: string;
  platform: string;
  flavor: string;
  minOsVersion: string | number;
  maxOsVersion: string | number;
  url: string;
}

export function getDataProcessingRegions() {
  return fetchWrapper('/admin/nds/dataProcessingRegions', {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function setDataProcessingRegion(groupId, data) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/dataProcessingRegion`, {
    method: 'POST',
    body: JSON.stringify(data),
  }).then((resp) => resp.json());
}

function deletePlanExecutorJobPriorityConfigValue(configId) {
  return fetchWrapper(`/admin/nds/ndsJobPriorityConfigs/${configId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

function savePlanExecutorJobPriorityConfigValue(payload: PlanExecutorJobPriorityConfig) {
  return fetchWrapper(`/admin/nds/ndsJobPriorityConfigs`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

function getPlanExecutorJobPriorityConfigValues(): Promise<Array<PlanExecutorJobPriorityConfig>> {
  return fetchWrapper(`/admin/nds/ndsJobPriorityConfigs`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

function updatePlanExecutorJobPriorityConfigValue(payload: PlanExecutorJobPriorityConfig) {
  return fetchWrapper(`/admin/nds/ndsJobPriorityConfigs/${payload.id}`, {
    method: 'PUT',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

function deletePlanExecutorJobPrioritySetting(settingsId) {
  return fetchWrapper(`/admin/nds/ndsJobPrioritySettings/${settingsId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

function addPlanExecutorJobPrioritySetting(payload: PlanExecutorJobPrioritySettings) {
  return fetchWrapper(`/admin/nds/ndsJobPrioritySettings`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

function getPlanExecutorJobPrioritySetting(): Promise<Array<PlanExecutorJobPrioritySettings>> {
  return fetchWrapper(`/admin/nds/ndsJobPrioritySettings`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

function updatePlanExecutorJobPrioritySetting(payload: PlanExecutorJobPrioritySettings) {
  return fetchWrapper(`/admin/nds/ndsJobPrioritySettings/${payload.id}`, {
    method: 'PUT',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

export function forceMigrateFromAvailabilitySets(
  clusterName: string,
  groupId: string,
  availabilitySetNames: Array<string>
) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/clusters/${clusterName}/availabilitySet`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams({
      availabilitySetNames,
    }),
  }).then((resp) => resp.json());
}

export function loadPlanJson(planId: string) {
  return fetchWrapper(`/admin/nds/plans/${planId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function skipMove(planId: string, moveId: string) {
  return fetchWrapper(`/admin/nds/plans/${planId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams({
      skipMoveId: moveId,
    }),
  }).then((resp) => resp.json());
}

export function rerunValidation(targetInstanceId: string) {
  return fetchWrapper(`/admin/nds/dataValidation/${targetInstanceId}/rerun`, {
    method: 'POST',
  }).then((resp) => resp.json());
}

export function getDataValidationRecords(filters = {}) {
  let urlWithFilters = '/admin/nds/dataValidations';
  if (Object.keys(filters).length > 0) {
    urlWithFilters += `?${Object.keys(filters)
      .filter((key) => filters[key] !== undefined && filters[key] !== '')
      .map((key) => {
        return `${key}=${filters[key]}`;
      })
      .join('&')}`;
  }
  return fetchWrapper(urlWithFilters, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getDataValidationAggregations(filters = {}) {
  let urlWithFilters = '/admin/nds/dataValidations/aggregations';
  if (Object.keys(filters).length > 0) {
    urlWithFilters += `?${Object.keys(filters)
      .filter((key) => filters[key] !== undefined && filters[key] !== '')
      .map((key) => {
        return `${key}=${filters[key]}`;
      })
      .join('&')}`;
  }
  return fetchWrapper(urlWithFilters, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getDryRunModeEnabled(): Promise<{ dryRunModeEnabled: boolean }> {
  return fetchWrapper('/admin/nds/dryRunMode', {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function requestValidation(hostname: string, mongoDBVersion?: string) {
  const args: { mongoDBVersion?: string } = {};
  if (mongoDBVersion) {
    args.mongoDBVersion = mongoDBVersion;
  }
  return fetchWrapper(`/admin/nds/dataValidation/${hostname}`, {
    method: 'POST',
    body: JSON.stringify(args),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((resp) => resp.json());
}

export function requestValidationForGroup(groupId: string) {
  return fetchWrapper(`/admin/nds/dataValidation/groupId/${groupId}`, {
    method: 'POST',
    body: JSON.stringify({}),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((resp) => resp.json());
}

export function getCustomBuilds(opts: CustomBuildFilterOptions): Promise<Array<MongoDbBuild>> {
  let uri = `/admin/nds/customBuilds`;
  const filters: Array<string> = [];
  if (opts.trueNameFilter) {
    filters.push(`trueNameFilter=${encodeURIComponent(opts.trueNameFilter)}`);
  }
  if (opts.trueNameAfter) {
    filters.push(`trueNameAfter=${encodeURIComponent(opts.trueNameAfter)}`);
  }
  if (opts.trueNameBefore) {
    filters.push(`trueNameBefore=${encodeURIComponent(opts.trueNameBefore)}`);
  }
  if (filters.length > 0) {
    uri += '?' + filters.join('&');
  }
  return fetchWrapper(uri, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function clearUnprovisionedTargetGroups(groupId: string) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/clearTargetGroups`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  }).then((resp) => resp.json());
}

export function getClusterDescriptionForHostname({ hostname }) {
  return fetchWrapper(`/admin/nds/${hostname}/clusterDescription`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getRsIdFromHostname({ hostname }) {
  return fetchWrapper(`/admin/nds/${hostname}/rsId`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getProviderOptions(provider: string) {
  return fetchWrapper(`/admin/nds/cloudProviders/${provider}/options`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getAllProviderOptions(): Promise<any> {
  return Promise.all([
    this.getProviderOptions('AWS'),
    this.getProviderOptions('AZURE'),
    this.getProviderOptions('GCP'),
    this.getProviderOptions('FREE'),
  ]).then(([awsOptions, azureOptions, gcpOptions, freeDefault]) => {
    return {
      AWS: awsOptions,
      AZURE: azureOptions,
      GCP: gcpOptions,
      FREE: freeDefault,
    };
  });
}

export function fixFeatureCompatibilityVersionForCluster(
  groupId: string,
  clusterName: string,
  featureCompatibilityVersion: string,
  reason: string
) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/clusterDescriptions/${clusterName}/featureCompatibilityVersion`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams({
      featureCompatibilityVersion,
      reason,
    }),
  }).then((resp) => resp.json());
}

export function getFixedFeatureCompatibilityVersionClusters(): Promise<Array<PinnedCluster>> {
  return fetchWrapper(`/admin/nds/fixedFeatureCompatibilityVersionClusters`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getFixedCpuArchClusters(): Promise<Array<PinnedCluster>> {
  return fetchWrapper(`/admin/nds/fixedCpuArchClusters`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function fixCpuArchForCluster(groupId: string, clusterName: string, preferredCpuArch: string) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/clusterDescriptions/${clusterName}/preferredCpuArch`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams({
      preferredCpuArch,
    }),
  }).then((resp) => resp.json());
}

export function getPlanExtraInformation(groupId: string, planId: string) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/clusterDescriptions/${planId}/planExtraInformation`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getReplicaSetHardware(groupId: string, clusterName: string) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/clusterDescriptions/${clusterName}/replicaSetHardware`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function clearFailedPlansCount(groupId) {
  return fetchWrapper(`/admin/nds/groups/${groupId}`, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    method: 'POST',
    body: formParams({
      clearFailedPlansCount: true,
    }),
  });
}

export function getProcessArgs(groupId, clusterName) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/${clusterName}/processArgs`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function updateProcessArgs(groupId, clusterName, newArgsJson) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/${clusterName}/processArgs`, {
    method: 'PATCH',
    body: JSON.stringify(newArgsJson),
  }).then((resp) => resp.json());
}

export function updateMongotProcessArgs(groupId, clusterName, newArgsJson) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/${clusterName}/mongotProcessArgs`, {
    method: 'PATCH',
    body: JSON.stringify(newArgsJson),
  }).then((resp) => resp.json());
}

export function getHasFTSEnabled(groupId, clusterName) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/${clusterName}/ftsEnabled`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getMongotVersion(groupId) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/mongotVersion`, {
    method: 'GET',
  })
    .then((resp) => resp.json())
    .catch(() => null);
}

export function getCloudProviderConsoleInfoDefaults(groupId, hostname) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/cloudProviderConsole/${hostname}/defaults`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getCloudProviderConsoleInfo(groupId, hostname, extension, body) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/cloudProviderConsole/${hostname}.${extension}`, {
    method: 'POST',
    body: JSON.stringify(body),
  });
}

export function cancelPendingIndexes(groupId, clusterName) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/${clusterName}/pendingIndexes`, {
    method: 'DELETE',
  }).then((resp) => resp.json());
}

export function clearIndexConfig(groupId, clusterName) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/${clusterName}/clearIndexConfig`, {
    method: 'DELETE',
  }).then((resp) => resp.json());
}

export function getAccessRequests() {
  return fetchWrapper('/admin/nds/serverAccess/requests', {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function checkOrgRestrictedEmployeeAccess({ hostname }) {
  return fetchWrapper(`/admin/nds/serverAccess/validateHostname?hostname=${hostname}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function submitAccessRequest({ hostname, reason, overwriteExistingHostAccess = false }) {
  return fetchWrapper(
    `/admin/nds/serverAccess/requests${overwriteExistingHostAccess ? '?overwriteExistingHostAccess=true' : ''}`,
    {
      method: 'POST',
      body: JSON.stringify({
        hostname,
        reason,
      }),
    }
  ).then((resp) => resp.json());
}

export function submitClusterAccessRequest({ hostname, reason, overwriteExistingHostAccess = false }) {
  return fetchWrapper(
    `/admin/nds/serverAccess/wholeClusterRequests${
      overwriteExistingHostAccess ? '?overwriteExistingHostAccess=true' : ''
    }`,
    {
      method: 'POST',
      body: JSON.stringify({
        hostname,
        reason,
      }),
    }
  ).then((resp) => resp.json());
}

export function submitSingleShardAccessRequest({ hostname, reason, overwriteExistingHostAccess = false }) {
  return fetchWrapper(
    `/admin/nds/serverAccess/singleShardRequests${
      overwriteExistingHostAccess ? '?overwriteExistingHostAccess=true' : ''
    }`,
    {
      method: 'POST',
      body: JSON.stringify({
        hostname,
        reason,
      }),
    }
  ).then((resp) => resp.json());
}

export function addMTMProfile(data) {
  return fetchWrapper(`/admin/nds/mtm/profiles`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams(data),
  });
}

export function addServerlessMTMProfile(data) {
  return fetchWrapper(`/admin/nds/mtm/serverless/profiles`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams(data),
  });
}

export function getServerlessMTMProfiles() {
  return fetchWrapper('/admin/nds/mtm/serverless/profiles', {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function requestClusterCriticalReboot(groupId, clusterName) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/${clusterName}/reboot`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
}

export function getLeakedItems(skip, limit, provider, type) {
  return fetchWrapper(`/admin/nds/leakedItems/?skip=${skip}&limit=${limit}&provider=${provider}&type=${type}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function approveLeakedItemForDeletion(cloudProvider, id) {
  return fetchWrapper(`/admin/nds/leakedItems/${cloudProvider}`, {
    method: 'PATCH',
    body: formParams({
      id,
    }),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
}

export function ignoreLeakedItem(cloudProvider, id) {
  return fetchWrapper(`/admin/nds/leakedItems/ignored/${cloudProvider}`, {
    method: 'PATCH',
    body: formParams({
      id,
    }),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
}

export function updateLeakedItem(cloudProvider, id, jiraTicket) {
  return fetchWrapper(`/admin/nds/leakedItems/${cloudProvider}/${id}`, {
    method: 'PATCH',
    body: formParams({
      jiraTicket,
    }),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
}

export function addOsTunedFileOverrides(groupId: string, clusterName: string, data: Object): Promise<Response> {
  return fetchWrapper(`/admin/nds/groups/${groupId}/clusterDescriptions/${clusterName}/osTunedFileOverrides`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

export function getMTMGroups() {
  return fetchWrapper('/admin/nds/groups/mtmGroups', {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getServerlessMTMGroups() {
  return fetchWrapper('/admin/nds/groups/serverless/mtmGroups', {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getMTMClusters(): Promise<Array<MTMCluster>> {
  return fetchWrapper('/admin/nds/mtm/clusters', {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getUnfinishedMTMCompactions(): Promise<Array<MTMCompaction>> {
  const url = '/admin/nds/mtm/clusters/compactions?status=PROCESSING&status=PAUSED';
  return fetchWrapper(url, {
    method: 'GET',
  }).then((resp) => {
    return resp.json();
  });
}

export function getFinishedMTMCompactions(limit: number): Promise<Array<MTMCompaction>> {
  const url = `/admin/nds/mtm/clusters/finishedCompactions?limit=${limit}`;
  return fetchWrapper(url, {
    method: 'GET',
  }).then((resp) => {
    return resp.json();
  });
}

export function getMTMCluster(clusterName: string, groupId: string): Promise<MTMCluster> {
  return fetchWrapper(`/admin/nds/mtm/clusters/${groupId}/${clusterName}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getServerlessMTMClusters() {
  return fetchWrapper('/admin/nds/mtm/serverless/clusters', {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function setMTMHolder(groupId, data) {
  return fetchWrapper(`/admin/nds/groups/${groupId}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams(data),
  });
}

export function setMTMCluster(groupId, clusterName, data) {
  return fetchWrapper(`/admin/nds/mtm/clusters/${groupId}/${clusterName}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams(data),
  });
}

export function setServerlessMTMCluster(groupId, clusterName, data) {
  return fetchWrapper(`/admin/nds/mtm/serverless/clusters/${groupId}/${clusterName}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams(data),
  });
}

export function unsetMTMCluster(groupId, clusterName) {
  return fetchWrapper(`/admin/nds/mtm/clusters/${groupId}/${clusterName}`, {
    method: 'DELETE',
  });
}

export function unsetServerlessMTMCluster(groupId, clusterName) {
  return fetchWrapper(`/admin/nds/mtm/serverless/clusters/${groupId}/${clusterName}`, {
    method: 'DELETE',
  });
}

export function updateMTMCluster(groupId: string, clusterName: string, data: MTMUpdate): Promise<Response> {
  return fetchWrapper(`/admin/nds/mtm/clusters/${groupId}/${clusterName}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams(data),
  });
}

export function updateServerlessMTMCluster(groupId: string, clusterName: string, data: MTMUpdate): Promise<Response> {
  return fetchWrapper(`/admin/nds/mtm/serverless/clusters/${groupId}/${clusterName}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams(data),
  });
}

export function getServerlessMTMPools(): Promise<Array<ServerlessMTMPool>> {
  return fetchWrapper('/admin/nds/mtm/serverless/pools', {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function createServerlessMTMPool(data: ServerlessMTMPool): Promise<ServerlessMTMPool> {
  return fetchWrapper(`/admin/nds/mtm/serverless/pools`, {
    method: 'POST',
    body: JSON.stringify(data),
  }).then((resp) => resp.json());
}

export function updateServerlessMTMPool(poolId: string, data: ServerlessMTMPool): Promise<ServerlessMTMPool> {
  return fetchWrapper(`/admin/nds/mtm/serverless/pools/${poolId}`, {
    method: 'PATCH',
    body: JSON.stringify(data),
  }).then((resp) => resp.json());
}

export function deleteServerlessMTMPool(poolId: string) {
  return fetchWrapper(`/admin/nds/mtm/serverless/pools/${poolId}`, {
    method: 'DELETE',
  });
}

export function getServerlessMTMLoad(
  groupId: string,
  clusterName: string,
  numTopTenants: number
): Promise<ServerlessMTMLoad> {
  return fetchWrapper(
    `/admin/nds/mtm/serverless/clusters/${groupId}/${clusterName}/load?numTopTenants=${numTopTenants}`,
    {
      method: 'GET',
    }
  ).then((resp) => resp.json());
}

export function getServerlessTraceSummariesByPool(
  poolId: string,
  startedAfterDate: string,
  endedBeforeDate: string
): Promise<Array<ServerlessTraceSummary>> {
  return fetchWrapper(
    `/admin/nds/serverless/pools/${poolId}/traceSummaries?startedAfterDate=${Date.parse(
      startedAfterDate
    )}&endedBeforeDate=${Date.parse(endedBeforeDate)}`,
    {
      method: 'GET',
    }
  ).then((resp) => resp.json());
}

export function getTenantUpgrades(status: string) {
  return fetchWrapper(`/admin/nds/tenantUpgrades?status=${status}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getTenantUpgradesToServerless(status: string) {
  return fetchWrapper(`/admin/nds/tenantUpgradesToServerless?status=${status}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getTenantUpgradeSnapshotDownloadURL(tenantUpgradeId: string) {
  return fetchWrapper(`/admin/nds/tenantUpgrades/${tenantUpgradeId}/downloadUrl`, {
    method: 'GET',
  }).then((resp) => resp.text());
}

export function getTenantUpgradeToServerlessSnapshotDownloadURL(tenantUpgradeId: string, clusterName: string) {
  return fetchWrapper(`/admin/nds/tenantUpgradesToServerless/${tenantUpgradeId}/${clusterName}/downloadUrl`, {
    method: 'GET',
  }).then((resp) => resp.text());
}

export function getFastSharedRecords(state: string, limit: number, skip: number) {
  return fetchWrapper(`/admin/nds/fastSharedPreallocatedRecords?state=${state}&limit=${limit}&skip=${skip}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getFastServerlessRecords(state: string, limit: number, skip: number) {
  return fetchWrapper(`/admin/nds/fastServerlessPreallocatedRecords?state=${state}&limit=${limit}&skip=${skip}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function setFastSharedRecordState(data: FastTenantRecordType): Promise<Response> {
  return fetchWrapper(`/admin/nds/setFastSharedPreallocatedRecordState`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((resp) => resp.json());
}

export function setFastServerlessRecordState(data: FastTenantRecordType): Promise<Response> {
  return fetchWrapper(`/admin/nds/setFastServerlessPreallocatedRecordState`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((resp) => resp.json());
}

export function restartProcess(processType: string, hostname: string): Promise<Response> {
  return fetchWrapper(`/admin/nds/restartProcess/${processType}/hostname/${hostname}`, {
    method: 'POST',
  }).then((resp) => resp.json());
}

export function scaleMTMCapacity(profileId: string, numMTMs: number): Promise<Response> {
  return fetchWrapper(`/admin/nds/mtm/serverless/profiles/${profileId}/scaleMTMCapacity?numMTMs=${numMTMs}`, {
    method: 'POST',
  }).then((resp) => resp.json());
}

export function setupServerless(
  orgId: string,
  groupId: string,
  cloudProvider: string,
  regionName: string,
  backingInstanceSize: string,
  createServerlessInstance: boolean
): Promise<Response> {
  return fetchWrapper(
    `/admin/nds/mtm/serverless/${orgId}/${cloudProvider}/${regionName}/setupServerless?backingInstanceSize=${backingInstanceSize}&groupId=${groupId}&createServerlessInstance${
      createServerlessInstance ? '=true' : '=false'
    }`,
    {
      method: 'POST',
    }
  ).then((resp) => resp.json());
}

export function setupFreeTier(
  orgId: string,
  cloudProvider: string,
  regionName: string,
  backingInstanceSize: string,
  tenantInstanceSize: string
): Promise<Response> {
  return fetchWrapper(`/admin/nds/mtm/clusters/${orgId}/setupFreeTier`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams({
      cloudProvider,
      regionName,
      backingInstanceSize,
      tenantInstanceSize,
    }),
  }).then((resp) => resp.json());
}

export function updateGCPPSCNATSubnetRange(groupId: string, gcpPSCRegionGroupCIDRRange: string): Promise<Response> {
  return fetchWrapper(`/admin/nds/groups/${groupId}/gcpPSCRegionGroupCIDRRange`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams({
      gcpPSCRegionGroupCIDRRange,
    }),
  }).then((resp) => resp.json());
}

export function getForcePlanningReplicaSets(
  groupId: string,
  clusterName: string
): Promise<Array<ForcePlanningReplicaSet>> {
  return fetchWrapper(`/admin/nds/groups/${groupId}/clusters/${clusterName}/forcePlanningReplicaSets`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function setForcePlanningReplicaSets(groupId: string, clusterName: string, payload: Array<ForcePlanningHost>) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/clusters/${clusterName}/forcePlanningReplicaSets`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
}

export function getLiveImportOverrides() {
  return fetchWrapper(`/admin/nds/liveImportOverrides`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function addLiveImportOverrides(
  groupId,
  clusterName,
  oplogBatchSize,
  collStatsThreshold,
  maxNumParallelPartitions,
  numInsertersPerPartition
) {
  return fetchWrapper(`/admin/nds/liveImportOverrides`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams({
      groupId,
      clusterName,
      oplogBatchSize,
      collStatsThreshold,
      maxNumParallelPartitions,
      numInsertersPerPartition,
    }),
  });
}

export function updateLiveImportOverrides(
  groupId,
  clusterName,
  oplogBatchSize,
  collStatsThreshold,
  maxNumParallelPartitions,
  numInsertersPerPartition
) {
  return fetchWrapper(`/admin/nds/liveImportOverrides/${groupId}/${clusterName}/`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams({
      oplogBatchSize,
      collStatsThreshold,
      maxNumParallelPartitions,
      numInsertersPerPartition,
    }),
  });
}

export function deleteLiveImportOverrides(groupId, clusterName) {
  return fetchWrapper(`/admin/nds/liveImportOverrides/${groupId}/${clusterName}`, {
    method: 'DELETE',
  });
}

export function getAdminClusterLockGivenHostname(hostname) {
  return fetchWrapper(`/admin/nds/hostnames/${hostname}/adminClusterLocks`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getAdminClusterLock(groupId: string, clusterName: string) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/clusters/${clusterName}/adminClusterLocks`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function acquireAdminClusterLock({ groupId, clusterName, reason }) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/clusters/${clusterName}/adminClusterLocks`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams({ reason }),
  }).then((resp) => resp.json());
}

export function acquireAdminClusterLockGivenHostname({ hostname, reason }) {
  return fetchWrapper(`/admin/nds/hostnames/${hostname}/adminClusterLocks`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams({ hostname, reason }),
  }).then((resp) => resp.json());
}

export function releaseAdminClusterLock({ groupId, clusterName }) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/clusters/${clusterName}/adminClusterLocks`, {
    method: 'DELETE',
  });
}

export function renewAdminClusterLock({ groupId, clusterName }) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/clusters/${clusterName}/adminClusterLocks`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  }).then((resp) => resp.json());
}

export function getMTMsForCompaction(filters = {}): Promise<Array<MTMCluster>> {
  let urlWithFilters = '/admin/nds/mtm/clusters/compactions/recommendations';

  if (Object.keys(filters).length > 0) {
    urlWithFilters += `?${Object.keys(filters)
      .filter((key) => filters[key])
      .map((key) => {
        return `${key}=${filters[key]}`;
      })
      .join('&')}`;
  }
  return fetchWrapper(urlWithFilters, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function initiateMTMCompactions(payload) {
  return fetchWrapper(`/admin/nds/mtm/clusters/compactions`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

export function setMTMCompactionAction(mtmCompactionId, status) {
  return fetchWrapper(`/admin/nds/mtm/clusters/compactions/${mtmCompactionId}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams({
      status,
    }),
  }).then((resp) => resp.json());
}

export function requestBlockServerlessTenant(tenantUniqueId) {
  return fetchWrapper(`/admin/nds/serverless/${tenantUniqueId}/blockTenant`, {
    method: 'POST',
  });
}

export function requestUnblockServerlessTenant(tenantUniqueId) {
  return fetchWrapper(`/admin/nds/serverless/${tenantUniqueId}/unblockTenant`, {
    method: 'POST',
  });
}

export function getProjectLogs(groupId: string, ts: Date, before: boolean, n: number, planId?: string) {
  const params = { planId, ts: ts.getTime(), before, n };
  return fetchWrapper(`/admin/nds/groups/${groupId}/appLogs?${queryString.stringify(params)}`, { method: 'GET' }).then(
    (resp) => resp.json()
  );
}

export { logIngestion, externalMaintenance, envoy, serverlessLoadBalancingDeployment };

export default {
  deletePlanExecutorJobPriorityConfigValue,
  savePlanExecutorJobPriorityConfigValue,
  updatePlanExecutorJobPriorityConfigValue,
  getPlanExecutorJobPriorityConfigValues,

  deletePlanExecutorJobPrioritySetting,
  addPlanExecutorJobPrioritySetting,
  updatePlanExecutorJobPrioritySetting,
  getPlanExecutorJobPrioritySetting,

  getDataProcessingRegions,
  setDataProcessingRegion,
};
