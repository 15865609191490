import * as api from 'js/common/services/api';

// Action Types
const SET_ENCRYPTION_AT_REST = 'nds/encryptionAtRest/SET_ENCRYPTION_AT_REST';

const initialState = {
  awsKms: {
    enabled: false,
    valid: false,
    accessKeyID: undefined,
    secretAccessKey: undefined,
    customerMasterKeyID: undefined,
    region: undefined,
    lastUpdatedKeyID: undefined,
  },
  azureKeyVault: {
    enabled: false,
    valid: false,
    clientID: undefined,
    tenantID: undefined,
    secret: undefined,
    azureEnvironment: undefined,
    subscriptionID: undefined,
    resourceGroupName: undefined,
    keyVaultName: undefined,
    keyIdentifier: undefined,
    lastUpdatedKeyID: undefined,
  },
  googleCloudKms: {
    enabled: false,
    valid: false,
    serviceAccountKey: undefined,
    keyVersionResourceID: undefined,
    lastUpdatedKeyID: undefined,
  },
};

// Reducer
export default function encryptionAtRestReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ENCRYPTION_AT_REST: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
}

const getEncryptionAtRestData = (state) => {
  return state.nds.encryptionAtRest;
};

// Selectors
export const getEncryptionAtRest = (state) => getEncryptionAtRestData(state) || {};

// Action Creators
const setEncryptionAtRest = (payload) => ({
  type: SET_ENCRYPTION_AT_REST,
  payload,
});
export { setEncryptionAtRest };

export const loadEncryptionAtRest = (groupId) => (dispatch) => {
  return api.nds.encryptionAtRest.getEncryptionAtRest(groupId).then((response) => {
    return dispatch(setEncryptionAtRest(response));
  });
};
