import * as api from 'js/common/services/api';

// Action Types
const SET_DATA_PROCESSING_REGIONS = 'nds/dataProcessingRegions/SET_DATA_PROCESSING_REGIONS';

const initialState = {};

// Reducer
export default function dataProcessingRegionsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_PROCESSING_REGIONS: {
      return {
        regions: action.payload,
      };
    }
    default:
      return state;
  }
}

const getDataProcessingRegionsData = (state) => {
  return state.nds.dataProcessingRegions;
};

// Selectors
export const getDataProcessingRegions = (state) => getDataProcessingRegionsData(state).regions || {};

// Action Creators
const setDataProcessingRegions = (payload) => ({
  type: SET_DATA_PROCESSING_REGIONS,
  payload,
});

export { setDataProcessingRegions };

export const loadAvailableDataProcessingRegions = () => (dispatch) => {
  return api.nds.admin.getDataProcessingRegions().then((response) => {
    return dispatch(setDataProcessingRegions(response));
  });
};
