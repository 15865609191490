import { CloudProvider } from '@packages/types/nds/provider';
import { TenantEndpoint } from '@packages/types/nds/serverlessPrivateLink';

import fetchWrapper from '../fetchWrapper';

export function getPrivateEndpointsForServerlessProject(
  groupId: string,
  cloudProvider: CloudProvider
): Promise<Array<TenantEndpoint>> {
  return fetchWrapper(`/nds/${groupId}/privateEndpoint/serverless/${cloudProvider}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getPrivateEndpointsForServerlessInstance(
  groupId: string,
  instanceName: string
): Promise<Array<TenantEndpoint>> {
  return fetchWrapper(`/nds/${groupId}/privateEndpoint/serverless/instance/${instanceName}/endpoint`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function deleteEndpoint(groupId: string, instanceName: string, endpointId: string) {
  return fetchWrapper(`/nds/${groupId}/privateEndpoint/serverless/instance/${instanceName}/endpoint/${endpointId}`, {
    method: 'DELETE',
  }).then((resp) => resp.json());
}

export function createEndpoint(groupId: string, instanceName: string): Promise<TenantEndpoint> {
  return fetchWrapper(`/nds/${groupId}/privateEndpoint/serverless/instance/${instanceName}/endpoint`, {
    method: 'POST',
  }).then((resp) => resp.json());
}

export function updatePrivateEndpoint(
  groupId: string,
  instanceName: string,
  endpointId: string,
  data: Partial<TenantEndpoint>
) {
  return fetchWrapper(`/nds/${groupId}/privateEndpoint/serverless/instance/${instanceName}/endpoint/${endpointId}`, {
    method: 'PATCH',
    body: JSON.stringify(data),
  }).then((resp) => resp.json());
}

export function getEndpoint(groupId: string, instanceName: string, endpointId: string): Promise<TenantEndpoint> {
  return fetchWrapper(`/nds/${groupId}/privateEndpoint/serverless/instance/${instanceName}/endpoint/${endpointId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}
