import { useState } from 'react';

import styled from '@emotion/styled';
import Checkbox from '@leafygreen-ui/checkbox';
import Toggle from '@leafygreen-ui/toggle';

import {
  ThirdPartyIntegrationBodyLabel,
  ThirdPartyIntegrationDescription,
} from '@packages/components/ThirdPartyIntegration/styles/common';

// styling

const StyledDataAPIToggle = styled.div<{ lastOfTypeMarginBottom?: string }>(
  (props) => `
  margin-bottom: 24px;
  &:last-of-type {
    margin-bottom: ${props.lastOfTypeMarginBottom || '40px'};
  }
`
);

const FlexContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
`;

/**
 * Interface for all the props that can be passed to the Data API Toggle component.
 * See each prop's own documentation for its explanation
 */
interface Props {
  /**
   * Flag to allow setting the toggle value from outside the component
   */
  toggled?: boolean;
  /**
   * Flag to allow setting the checkbox value from outside the component
   */
  checked?: boolean;
  /**
   * Flag to force enable Data API and disallow users from being able to manually disable it.
   * This is useful especially for Vercel Edge projects which require Data API to be enabled
   * Default = false
   */
  dataApiForceEnabled?: boolean;
  /**
   * Flag to allow users to untoggle Data API and show a checkbox that users need to check before they can disable it
   * Default = false
   */
  showCheckboxForDisabling?: boolean;
  /**
   * Callback function to trigger during the toggle's onChange
   * Default = empty callback
   */
  onToggle?: (val: boolean) => unknown;
  /**
   * Callback function to trigger during the checkbox's onChange
   * Default = empty callback
   */
  onCheckboxChange?: (val: boolean) => unknown;
  /**
   * Sometimes the margin bottom is more than we want so we want to explicitly set it
   */
  lastOfTypeMarginBottom?: string;
  /**
   * Flag to hold the initial value of the toggle loaded from the database whenever applicable
   */
  setupInitialDataApi?: boolean;
}

/**
 * A component to show Data API toggle button with validations in place to handle toggling on / off
 * and force enabling the component like in the case of Vercel Edge projects
 * ```
    <DataApiToggle />
 ```
 * @param props.toggled set the toggle value to true or false
 * @param props.checked set the checked value to true or false
 * @param props.dataApiForceEnabled force enable Data API toggle and disallow disabling
 * @param props.showCheckboxForDisabling allow toggling off Data API and show the relevant checkbox to confirm their choice
 * @param props.onToggle trigger the passed callback function during the toggle's onChange
 * @param props.onCheckboxChange trigger the passed callback function during the checkbox's onChange
 * @param props.lastOfTypeMarginBottom set the margin bottom value for the last of data api toggle div
 * @param props.setupInitialDataApi hold the initial value of the toggle
 */
export const DataApiToggle = ({
  toggled = false,
  checked = false,
  dataApiForceEnabled = false,
  showCheckboxForDisabling = false,
  onToggle = () => {},
  onCheckboxChange = () => {},
  lastOfTypeMarginBottom,
  setupInitialDataApi = false,
}: Props) => {
  const [showCheckbox, setShowCheckbox] = useState<boolean>(showCheckboxForDisabling);

  const handleToggleOnChange = (val: boolean) => {
    onToggle(val);
    // show the checkbox only for disabled data api and initial data api value is true
    setShowCheckbox(!val && setupInitialDataApi);
  };
  const handleCheckboxOnChange = (val: boolean) => {
    onCheckboxChange(val);
  };
  const dataApiHeader = dataApiForceEnabled ? 'Data API is Enabled' : 'Enable the Atlas Data API';
  const dataApiBody = dataApiForceEnabled ? (
    <span>
      The{' '}
      <a
        href="https://www.mongodb.com/docs/atlas/api/data-api/#when-to-use-the-data-api"
        target="_blank"
        rel="noreferrer"
      >
        Data API
      </a>{' '}
      is automatically enabled when using Vercel's Edge Network, functions, or other https services.
    </span>
  ) : (
    <span>
      The{' '}
      <a
        href="https://www.mongodb.com/docs/atlas/api/data-api/#when-to-use-the-data-api"
        target="_blank"
        rel="noreferrer"
      >
        Atlas Data API
      </a>{' '}
      lets you securely access data stored in Atlas using standard HTTPS requests. This can be disabled later.
    </span>
  );

  return (
    <StyledDataAPIToggle lastOfTypeMarginBottom={lastOfTypeMarginBottom}>
      <ThirdPartyIntegrationBodyLabel>{dataApiHeader}</ThirdPartyIntegrationBodyLabel>
      <FlexContainer>
        <ThirdPartyIntegrationDescription>{dataApiBody}</ThirdPartyIntegrationDescription>
        <Toggle
          data-testid="data-api-toggle"
          aria-labelledby="toggle"
          checked={dataApiForceEnabled || toggled}
          disabled={dataApiForceEnabled}
          onChange={(val: boolean) => handleToggleOnChange(val)}
        />
      </FlexContainer>
      {!dataApiForceEnabled && !toggled && showCheckbox && (
        <Checkbox
          animate
          data-testid="data-api-checkbox"
          checked={checked}
          onChange={() => handleCheckboxOnChange(!checked)}
          label="I understand that turning off the Data API will affect any app using the Data API."
        />
      )}
    </StyledDataAPIToggle>
  );
};
