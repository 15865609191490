export enum FactorType {
  Push = 'push',
  TokenSoftwareTotp = 'token:software:totp',
  Email = 'email',
  WebAuthn = 'webauthn',
  Sms = 'sms',
  Call = 'call',
}

export enum FactorResultType {
  SUCCESS = 'SUCCESS',
  CHALLENGE = 'CHALLENGE',
  WAITING = 'WAITING',
  FAILED = 'FAILED',
  REJECTED = 'REJECTED',
  TIMEOUT = 'TIMEOUT',
  TIME_WINDOW_EXCEEDED = 'TIME_WINDOW_EXCEEDED',
  PASSCODE_REPLAYED = 'PASSCODE_REPLAYED',
  ERROR = 'ERROR',
}

export enum FactorProvider {
  Okta = 'OKTA',
  Rsa = 'RSA',
  Google = 'GOOGLE',
  Symantec = 'SYMANTEC',
  Duo = 'DUO',
  Yubico = 'YUBICO',
  Fido = 'FIDO',
}

export enum FactorStatus {
  PENDING_ACTIVATION = 'PENDING_ACTIVATION',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  NOT_SETUP = 'NOT_SETUP',
  ENROLLED = 'ENROLLED',
  DISABLED = 'DISABLED',
  EXPIRED = 'EXPIRED',
}

export enum AuthTransactionState {
  MfaRequired = 'MFA_REQUIRED',
  MfaChallenge = 'MFA_CHALLENGE',
  Success = 'SUCCESS',
}

export interface OktaMfaFactorEnrollmentRequest {
  factorType: FactorType;
  phoneNumber?: string;
  phoneExtension?: string;
  passcode?: string;
}

export interface OktaMfaFactorVerificationRequest {
  factorType: FactorType;
  factorId: string;
  passcode?: string;
  attestation?: string;
  clientData?: string;
  authenticatorData?: string;
  signatureData?: string;
}

export interface OktaMfaFactorPushVerificationRequest {
  factorId: string;
  transactionId?: string;
}

export interface OktaMfaFactorVerificationResponse {
  factorResult: FactorResultType;
  transactionId?: string;
  profile?: WebAuthnOktaMfaFactor['profile'];
  _embedded?: WebAuthnOktaMfaFactor['_embedded'];
}

export interface OktaMfaAuthTransaction<Factor extends OktaMfaFactor = OktaMfaFactor> {
  status: AuthTransactionState;
  factorResult: FactorResultType;
  _embedded?: {
    factor: Factor;
  };
}

export interface PushFactorVerificationResponse extends OktaMfaFactorVerificationResponse {
  transactionId?: string;
}

export interface OktaMfaFactorActivationResponse {
  activated: boolean;
  userFactors: Array<OktaMfaFactor>;
}

export type OktaMfaFactor =
  | WebAuthnOktaMfaFactor
  | VoiceOktaMfaFactor
  | SmsOktaMfaFactor
  | EmailOktaMfaFactor
  | TotpOktaMfaFactor
  | PushOktaMfaFactor;

export interface OktaMfaFactorCommon {
  id: string;
  factorType: FactorType;
  provider: FactorProvider;
  status: FactorStatus;
  _embedded?: {
    challenge?: {
      challenge: string;
    };
  };
}

export interface WebAuthnOktaMfaFactor extends OktaMfaFactorCommon {
  factorType: FactorType.WebAuthn;
  profile: {
    credentialId: string;
    authenticatorName: string;
  };
}

export interface WebAuthnFactorEnrollmentResponse extends WebAuthnOktaMfaFactor {
  factorType: FactorType.WebAuthn;
  credentialOptions: PublicKeyCredentialCreationOptions;
}

export interface VoiceOktaMfaFactor extends OktaMfaFactorCommon {
  factorType: FactorType.Call;
  profile: {
    phoneNumber: string;
  };
}

export interface SmsOktaMfaFactor extends OktaMfaFactorCommon {
  factorType: FactorType.Sms;
  profile: {
    phoneNumber: string;
  };
}

export interface EmailOktaMfaFactor extends OktaMfaFactorCommon {
  factorType: FactorType.Email;
  profile: {
    email: string;
  };
}

export interface TotpOktaMfaFactor extends OktaMfaFactorCommon {
  factorType: FactorType.TokenSoftwareTotp;
  profile?: {
    credentialId: string;
  };
}

export interface TotpFactorEnrollmentResponse extends TotpOktaMfaFactor {
  activation: {
    sharedSecret: string;
    qrCode: QrCode;
  };
}

export interface PushOktaMfaFactor extends OktaMfaFactorCommon {
  factorType: FactorType.Push;
  profile?: {
    credentialId: string;
    deviceType: string;
    name: string;
    platform: string;
    version: string;
  };
}

export interface PushFactorEnrollmentResponse extends PushOktaMfaFactor {
  activation: {
    expiresAt: Date;
    factorResult: FactorResultType;
    qrCode: QrCode;
  };
}

export interface QrCode {
  href: string;
  type?: string;
}

export interface FactorMetadata {
  name: string;
  image: string;
  setupName: string;
  setupNote?: string;
  setupParam: string;
  provider: FactorProvider;
}
