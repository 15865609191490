import * as React from 'react';
import { Component, MouseEventHandler } from 'react';

import Button from '@leafygreen-ui/button';
import Card from '@leafygreen-ui/card';
import Icon from '@leafygreen-ui/icon';
import classNames from 'classnames';

import { WithDefaultProps } from '@packages/types/withDefaultProps';

// min height of accordion
let collapsedHeight: number | undefined;
const animationDurationMillis = 200;

interface OwnProps {
  active?: boolean;
  initialActive?: boolean;
  onHeadlineClick?: () => void;
  headline?: React.ReactNode;
  headlineText?: string;
  secondaryText?: React.ReactNode | string;
  secondarySubText?: string;
  preview?: React.ReactNode;
  disabled?: boolean;
  alignContent?: boolean;
  highlightSecondaryText?: boolean;
  errorSecondaryText?: boolean;
  isOnWhite?: boolean;
  animate?: boolean;
  showFooter?: boolean;
  showNext?: boolean;
  nextText?: string;
  onClickNext?: MouseEventHandler;
  showPrevious?: boolean;
  previousText?: string;
  onClickPrevious?: MouseEventHandler;
  usePrimaryNext?: boolean;
  colorCode?: string;
  hasSummary?: boolean;
  inheritOverflow?: boolean;
  sectionClassName?: string;
}

interface State {
  isActive: boolean;
}

const defaultProps = {
  initialActive: false,
  onHeadlineClick: () => {},
  disabled: false,
  alignContent: true,
  highlightSecondaryText: false,
  errorSecondaryText: false,
  isOnWhite: false,
  animate: false,
  showFooter: false,
  showNext: true,
  nextText: 'Next',
  onClickNext: () => {},
  showPrevious: true,
  previousText: 'Previous',
  onClickPrevious: () => {},
  usePrimaryNext: false,
  hasSummary: false,
  inheritOverflow: false,
};

type Props = WithDefaultProps<OwnProps, typeof defaultProps>;

class Accordion extends Component<Props, State> {
  static defaultProps = defaultProps;

  contentEl: HTMLElement | null;
  footerEl: HTMLElement | null;
  sectionEl: HTMLElement | null;

  state = {
    isActive: this.props.initialActive,
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    // if accordion changes between open / close state, animate transition
    const isActive = this.isControlled() ? this.props.active : this.state.isActive;
    const wasActive = this.isControlled() ? prevProps.active : prevState.isActive;

    if (isActive !== wasActive && this.props.animate) {
      if (isActive) {
        this.expandAccordion();
      } else {
        this.collapseAccordion();
      }
    }
  };

  onHeadlineClick = () => {
    if (this.isControlled()) {
      this.props.onHeadlineClick();
    } else {
      this.setState((state) => ({
        isActive: !state.isActive,
      }));
    }
  };

  // collapse accordion with animation
  collapseAccordion = () => {
    const sectionEl = this.sectionEl;
    const contentEl = this.contentEl;

    if (sectionEl && contentEl && typeof collapsedHeight !== 'undefined') {
      const expandedHeight = collapsedHeight + contentEl.scrollHeight;

      // in next frame, set height in pixels
      requestAnimationFrame(() => {
        sectionEl.style.height = `${expandedHeight}px`;

        // in next frame, transition to collapsed height
        requestAnimationFrame(() => {
          sectionEl.style.height = `${collapsedHeight}px`;
          // wait until animation is over to set height: auto
          setTimeout(() => {
            sectionEl.style.height = 'auto';
          }, animationDurationMillis);
        });
      });
    }
  };

  // expand accordion with animation
  expandAccordion = () => {
    const { showFooter } = this.props;

    const sectionEl = this.sectionEl;
    const contentEl = this.contentEl;
    const footerEl = this.footerEl;

    if (sectionEl && contentEl) {
      collapsedHeight = sectionEl.scrollHeight - contentEl.scrollHeight;
      if (showFooter && footerEl) {
        collapsedHeight -= footerEl.scrollHeight;
      }
      let expandedHeight = contentEl.scrollHeight + collapsedHeight;
      if (showFooter && footerEl) {
        expandedHeight += footerEl.scrollHeight;
      }

      sectionEl.style.overflow = 'hidden';
      const dynamicDurationMillis = 0;

      // in next frame, set height in pixels
      requestAnimationFrame(() => {
        sectionEl.style.height = `${collapsedHeight}px`;

        setTimeout(() => {
          requestAnimationFrame(() => {
            sectionEl.style.height = `${expandedHeight}px`;
            // wait until animation is over to set height: auto, overflow: visible
            setTimeout(() => {
              sectionEl.style.overflow = 'visible';
              sectionEl.style.height = 'auto';
            }, animationDurationMillis);
          });
          // wait until other accordions have collapsed to expand
        }, dynamicDurationMillis);
      });
    }
  };

  isControlled = () => this.props.active !== undefined;

  render() {
    const {
      active: isActiveProp,
      headline,
      headlineText,
      secondaryText,
      secondarySubText,
      preview,
      children,
      disabled,
      alignContent,
      highlightSecondaryText,
      errorSecondaryText,
      isOnWhite,
      showFooter,
      showNext,
      nextText,
      onClickNext,
      showPrevious,
      previousText,
      onClickPrevious,
      usePrimaryNext,
      colorCode,
      hasSummary,
      inheritOverflow,
      sectionClassName,
    } = this.props;
    const { isActive: isActiveState } = this.state;
    const isActive = this.isControlled() ? isActiveProp : isActiveState;

    return (
      <Card
        className={classNames(
          'accordion',
          {
            'accordion-inherit-overflow': inheritOverflow,
            'accordion-is-open': isActive,
            'accordion-is-disabled': disabled,
            'accordion-has-border': isOnWhite,
          },
          sectionClassName
        )}
      >
        <section
          ref={(ref) => {
            this.sectionEl = ref;
          }}
        >
          <div
            className={classNames('accordion-header', {
              'accordion-header-has-color-code': !!colorCode,
            })}
            style={{
              borderColor: colorCode || 'inherit',
            }}
          >
            <div
              role="button"
              tabIndex={0}
              className={classNames('accordion-headline', {
                'accordion-headline-is-disabled': disabled,
              })}
              onClick={() => !disabled && this.onHeadlineClick()}
            >
              <div className="accordion-headline-left">
                {headline || <span className="accordion-headline-text">{headlineText}</span>}
              </div>
              <div className="accordion-headline-right">
                <div
                  className={classNames('accordion-headline-secondary-text-section', {
                    'accordion-headline-secondary-text-section-has-no-subtext': !secondarySubText,
                  })}
                >
                  <span
                    className={classNames('accordion-headline-secondary-text', {
                      'accordion-headline-secondary-text-highlighted': highlightSecondaryText,
                      'accordion-headline-secondary-text-error': errorSecondaryText,
                    })}
                  >
                    {secondaryText}
                  </span>
                  <span
                    className={classNames('accordion-headline-secondary-subtext', {
                      'accordion-headline-secondary-subtext-error': errorSecondaryText,
                    })}
                  >
                    {secondarySubText}
                  </span>
                </div>

                <Icon glyph={isActive ? 'ChevronUp' : 'ChevronDown'} fill="#B8C4C2" />
              </div>
            </div>
            {preview && (
              <div
                className={classNames('accordion-preview', {
                  'accordion-content-is-aligned': alignContent,
                })}
              >
                {preview}
              </div>
            )}
          </div>
          <div
            ref={(ref) => {
              this.contentEl = ref;
            }}
            className={classNames('accordion-content', {
              'accordion-content-is-aligned': alignContent,
              'accordion-content-is-closed': !isActive,
              'accordion-content-has-summary': hasSummary,
            })}
          >
            {children}
          </div>
          {isActive && showFooter && (
            <div
              ref={(ref) => {
                this.footerEl = ref;
              }}
              className="accordion-footer"
            >
              {showPrevious && (
                <Button size="xsmall" onClick={onClickPrevious}>
                  {previousText}
                </Button>
              )}
              {showNext && (
                <Button
                  size="xsmall"
                  variant={usePrimaryNext ? 'primary' : 'default'}
                  className="accordion-footer-right-button"
                  onClick={onClickNext}
                >
                  {nextText}
                </Button>
              )}
            </div>
          )}
        </section>
      </Card>
    );
  }
}

export default Accordion;
