import { CreateAppFunction, DeploymentModel, Location, ProviderRegion } from 'baas-admin-sdk';

import { DataLakeServiceConfig, MongoServiceConfig } from '@packages/types/dataAPI';
import { ClusterDescription, State as ClusterState } from '@packages/types/nds/clusterDescription';
import { TenantState } from '@packages/types/nds/dataLakes';
import { CloudProvider } from '@packages/types/nds/provider';

import { loadClusterDescriptions } from '@packages/redux/modules/nds/clusterDescriptions';

import { createJsonLocalStorage } from 'js/common/services/localStorage';
import servicePoller from 'js/common/utils/servicePoller';
import { LINK_SERVICE_EXISTS_ERROR_CODE, SUPPORTED_MAJOR_VERSION } from 'js/project/baas/common/constants';
import {
  ClusterServiceConfig,
  MongoDataSourceType,
  MongoService,
  MongoServiceStatus,
} from 'js/project/baas/common/types';

import { getBaasParamsFromState } from 'js/project/applications/helpers/getBaasParams';
import applicationsService from 'js/project/applications/services/applicationsService';
import { isCluster, isDataLake } from 'js/project/dataAPI/utils';
import { getTriggersFromState } from 'js/project/triggers/helpers/getTriggers';
import { ScheduledTrigger } from 'js/project/triggers/types';

import { getActiveGroupId } from './app';

const NAME = 'triggers/';
const REFRESH_TIME = 900000; // 15 mins in ms
const MAX_LINKING_CHECK_ATTEMPTS = 180; // 15 mins of attempts

let localStorageInstanceForGroup;
const createLocalStorageKeyForGroup = (groupId) => `MMS.Triggers.${groupId}`;
const getLocalStorageForGroup = (rootState) => {
  const groupId = getActiveGroupId(rootState);
  if (!groupId) {
    return null;
  }

  const key = createLocalStorageKeyForGroup(groupId);

  if (!localStorageInstanceForGroup || localStorageInstanceForGroup.key !== key) {
    localStorageInstanceForGroup = createJsonLocalStorage(key);
  }

  return localStorageInstanceForGroup;
};

// ACTIONS
export enum ActionType {
  GetAppMetrics = 'triggers/GET_APP_METRICS',
  SetSvcsFromClusters = 'triggers/SET_SVCS_FROM_CLUSTER_DESCRIPTIONS',
  SetSvcsFromDataLakes = 'triggers/SET_SVCS_FROM_DATA_LAKES',
  GetLinkedDataSources = 'triggers/GET_LINKED_DATA_SOURCES',
}

// atlasTriggersApp
const SET_ATLAS_TRIGGERS_APP = `${NAME}SET_ATLAS_TRIGGERS_APP`;
const CREATE_ATLAS_TRIGGERS_APP_REQ = `${NAME}CREATE_ATLAS_TRIGGERS_APP_REQ`;
const CREATE_ATLAS_TRIGGERS_APP_RCV = `${NAME}CREATE_ATLAS_TRIGGERS_APP_RCV`;
export const CREATE_ATLAS_TRIGGERS_APP_ERR = `${NAME}CREATE_ATLAS_TRIGGERS_APP_ERR`;

// triggers
const SYNC_FROM_LOCAL_STORAGE = `${NAME}SYNC_FROM_LOCAL_STORAGE`;
const LOAD_SERVICES_REQ = `${NAME}LOAD_SERVICES_REQ`;
const LOAD_SERVICES_RCV = `${NAME}LOAD_SERVICES_RCV`;
const LOAD_SERVICES_ERR = `${NAME}LOAD_SERVICES_ERR`;
const LOAD_TRIGGERS_REQ = `${NAME}LOAD_TRIGGERS_REQ`;
const LOAD_TRIGGERS_RCV = `${NAME}LOAD_TRIGGERS_RCV`;
const LOAD_TRIGGERS_ERR = `${NAME}LOAD_TRIGGERS_ERR`;
const REMOVE_TRIGGER_REQ = `${NAME}REMOVE_TRIGGER_REQ`;
const REMOVE_TRIGGER_RCV = `${NAME}REMOVE_TRIGGER_RCV`;
const REMOVE_TRIGGER_ERR = `${NAME}REMOVE_TRIGGER_ERR`;
const REMOVE_TRIGGER_FUNCTION_REQ = `${NAME}REMOVE_TRIGGER_FUNCTION_REQ`;
const REMOVE_TRIGGER_FUNCTION_RCV = `${NAME}REMOVE_TRIGGER_FUNCTION_RCV`;
const REMOVE_TRIGGER_FUNCTION_ERR = `${NAME}REMOVE_TRIGGER_FUNCTION_ERR`;
const RESUME_TRIGGER_RCV = `${NAME}RESUME_TRIGGERS_RCV`;
const RESUME_TRIGGER_REQ = `${NAME}RESUME_TRIGGERS_REQ`;
export const RESUME_TRIGGER_ERR = `${NAME}RESUME_TRIGGERS_ERR`;
const CLEAR_TRIGGER_CREATED = `${NAME}CLEAR_TRIGGER_CREATED`;

// trigger
const CREATE_TRIGGER = `${NAME}CREATE_TRIGGER`;
const GET_TRIGGER = `${NAME}GET_TRIGGER`;
const UPDATE_TRIGGER = `${NAME}UPDATE_TRIGGER`;
const CLEAR_TRIGGER_AND_FUNCTION = `${NAME}CLEAR_TRIGGER_AND_FUNCTION`;
const GET_TRIGGER_EXECUTION = `${NAME}GET_TRIGGER_EXECUTION`;

// trigger functions
const GET_TRIGGER_FUNCTION = `${NAME}GET_TRIGGER_FUNCTION`;
const CREATE_TRIGGER_FUNCTION = `${NAME}CREATE_TRIGGER_FUNCTION`;
const UPDATE_TRIGGER_FUNCTION = `${NAME}UPDATE_TRIGGER_FUNCTION`;
const EXECUTE_DEBUG_SOURCE = `${NAME}EXECUTE_DEBUG_SOURCE`;
const CLEAR_DEBUG_RESULT = `${NAME}CLEAR_DEBUG_RESULT`;

export const SET_SVCS_FROM_DATA_SOURCE_DESCRIPTIONS = `${NAME}SET_SVCS_FROM_DATA_SOURCE_DESCRIPTIONS`;
export const SET_SVC_LINKING = `${NAME}SET_SVC_LINKING`;
export const SET_SVC_UNLINKED = `${NAME}SET_SVC_UNLINKED`;
export const SET_SVC_ERROR = `${NAME}SET_SVC_ERROR`;
export const LINK_TRIGGER_DATA_SOURCE = `${NAME}LINK_TRIGGER_DATA_SOURCE`;
const SET_SVC_NAMESPACES = `${NAME}SET_SVC_NAMESPACES`;

// logs
const LOAD_TRIGGERS_LOGS_REQ = `${NAME}LOAD_TRIGGERS_LOGS_REQ`;
const LOAD_TRIGGERS_LOGS_RCV = `${NAME}LOAD_TRIGGERS_LOGS_RCV`;
const LOAD_TRIGGERS_LOGS_ERR = `${NAME}LOAD_TRIGGERS_LOGS_ERR`;
const SET_PAGINATION_PARAMS = `${NAME}SET_PAGINATION_PARAMS`;

// dependencies
const ADD_DEPENDENCY_REQ = `${NAME}ADD_DEPENDENCY_REQ`;
const ADD_DEPENDENCY_RCV = `${NAME}ADD_DEPENDENCY_RCV`;
const ADD_DEPENDENCY_ERR = `${NAME}ADD_DEPENDENCY_ERR`;
const LOAD_DEPENDENCIES_REQ = `${NAME}LOAD_DEPENDENCIES_REQ`;
const LOAD_DEPENDENCIES_RCV = `${NAME}LOAD_DEPENDENCIES_RCV`;
const LOAD_DEPENDENCIES_ERR = `${NAME}LOAD_DEPENDENCIES_ERR`;
const UPLOAD_DEPENDENCIES_REQ = `${NAME}UPLOAD_DEPENDENCIES_REQ`;
const UPLOAD_DEPENDENCIES_RCV = `${NAME}UPLOAD_DEPENDENCIES_RCV`;
const UPLOAD_DEPENDENCIES_ERR = `${NAME}UPLOAD_DEPENDENCIES_ERR`;
const DELETE_DEPENDENCY_REQ = `${NAME}DELETE_DEPENDENCY_REQ`;
const DELETE_DEPENDENCY_RCV = `${NAME}DELETE_DEPENDENCY_RCV`;
const DELETE_DEPENDENCY_ERR = `${NAME}DELETE_DEPENDENCY_ERR`;
const DELETE_ALL_DEPENDENCIES_REQ = `${NAME}DELETE_ALL_DEPENDENCIES_REQ`;
const DELETE_ALL_DEPENDENCIES_RCV = `${NAME}DELETE_ALL_DEPENDENCIES_RCV`;
const DELETE_ALL_DEPENDENCIES_ERR = `${NAME}DELETE_ALL_DEPENDENCIES_ERR`;
const DEPLOY_DEPENDENCIES_REQ = `${NAME}DEPLOY_DEPENDENCIES_REQ`;
const DEPLOY_DEPENDENCIES_RCV = `${NAME}DEPLOY_DEPENDENCIES_RCV`;
const DEPLOY_DEPENDENCIES_ERR = `${NAME}DEPLOY_DEPENDENCIES_ERR`;
const EMPTY_DEPENDENCIES = `${NAME}EMPTY_DEPENDENCIES`;
const FETCH_INSTALL_STATUS_RCV = `${NAME}FETCH_INSTALL_STATUS_RCV`;
const FETCH_INSTALL_STATUS_ERR = `${NAME}FETCH_INSTALL_STATUS_ERR`;

const triggersLocalStorageInitialState = {
  metrics: {
    computeTime: 0,
    dataOutGB: 0,
    requestCount: 0,
    lastRefresh: undefined,
  },
};

const initialState = {
  ...triggersLocalStorageInitialState,
  stitchApp: undefined,
  trigger: undefined,
  triggerFunction: undefined,
  namespaces: [],
  debugResultSource: [],
  linkedClusters: [],
  dependencies: [],
  mongoServicesByName: {},
  createAtlasTriggersAppError: undefined,
  isCreatingAtlasTriggersApp: false,
  deployStatusPolling: false,
  hasStitchApp: false,
};

export function generateDataSourceMapKey(name: string, type: string) {
  return `${name}:${type}`;
}

export function dataSourceMapKey(dataSource) {
  return generateDataSourceMapKey(
    dataSource.config?.clusterName || dataSource.config?.dataLakeName || dataSource.name,
    dataSource.type
  );
}

function getDuplicateDataSourceError(baasParams, baasAppId: string) {
  const errorLink = `${baasParams.baasUrl}/groups/${baasParams.groupId}/apps/${baasAppId}/dataSources`;
  const linkedDataSourcesElement = <a href={errorLink}>Linked Data Sources</a>;
  return (
    <span>
      &nbsp;Linking a data source with a duplicate name is not permitted from Atlas Triggers. You may add this data
      source in the {linkedDataSourcesElement} section of your application.
    </span>
  );
}

function isClusterLinkable(description: ClusterDescription) {
  return (
    (description.state === ClusterState.CREATING || description.state === ClusterState.IDLE) &&
    !description.isPaused &&
    parseFloat(description.mongoDBMajorVersion) >= SUPPORTED_MAJOR_VERSION
  );
}

function isDataLakeLinkable(description) {
  return description.state === TenantState.ACTIVE;
}

interface GetAppMetricsAction {
  type: ActionType.GetAppMetrics;
  payload: any;
}

interface SetSvcsFromClustersAction {
  type: ActionType.SetSvcsFromClusters;
  payload: Array<ClusterDescription>;
}

interface SetSvcsFromDataLakesAction {
  type: ActionType.SetSvcsFromDataLakes;
  payload: Array<any>;
}

interface GetLinkedDataSourcesAction {
  type: ActionType.GetLinkedDataSources;
  payload: {
    mongoServices: Array<MongoService>;
    clusterDescriptions: Array<ClusterDescription>;
  };
}

export type TriggersAction =
  | SetSvcsFromClustersAction
  | SetSvcsFromDataLakesAction
  | GetAppMetricsAction
  | GetLinkedDataSourcesAction;

// Reducer
export default function triggersReducer(state = initialState, action: TriggersAction) {
  switch (action.type) {
    case SYNC_FROM_LOCAL_STORAGE: {
      const metrics = action.payload;
      return {
        ...state,
        metrics: metrics || triggersLocalStorageInitialState.metrics,
      };
    }
    case ActionType.GetAppMetrics: {
      const measurementUsageByName = action.payload.reduce((map, { name, data_points: dataPoints = [] }) => {
        map[name] = dataPoints.reduce((sum, dp) => sum + (dp as any).value, 0);
        return map;
      }, {});
      return {
        ...state,
        metrics: {
          ...state.metrics,
          computeTime: measurementUsageByName.compute_time,
          dataOutGB: measurementUsageByName.data_out,
          requestCount: measurementUsageByName.request_count,
          lastRefresh: new Date(),
        },
      };
    }
    case SET_ATLAS_TRIGGERS_APP:
      return {
        ...state,
        stitchApp: action.payload,
      };
    case CREATE_ATLAS_TRIGGERS_APP_REQ:
      return {
        ...state,
        createAtlasTriggersAppError: undefined,
        isCreatingAtlasTriggersApp: true,
      };
    case CREATE_ATLAS_TRIGGERS_APP_RCV:
      return {
        ...state,
        isCreatingAtlasTriggersApp: false,
      };
    case CREATE_ATLAS_TRIGGERS_APP_ERR:
      return {
        ...state,
        createAtlasTriggersAppError: action.payload.message,
        isCreatingAtlasTriggersApp: false,
      };
    case LOAD_TRIGGERS_LOGS_REQ:
      return {
        ...state,
        loadingTriggersLogs: true,
      };
    case LOAD_TRIGGERS_LOGS_RCV:
      return {
        ...state,
        loadingTriggersLogs: false,
        triggersLogsError: undefined,
        triggersLogs: action.payload.append
          ? (state as any).triggersLogs.concat(action.payload.triggersLogs)
          : action.payload.triggersLogs,
      };
    case LOAD_TRIGGERS_LOGS_ERR:
      return {
        ...state,
        loadingTriggersLogs: false,
        triggersLogsError: action.payload,
      };
    case SET_PAGINATION_PARAMS:
      return {
        ...state,
        paginationNextEndTime: action.payload.paginationNextEndTime,
        paginationCurrentSkip: action.payload.paginationCurrentSkip,
      };
    case GET_TRIGGER:
      return {
        ...state,
        trigger: action.payload,
      };
    case CREATE_TRIGGER:
      return {
        ...state,
        createdTriggerName: action.payload.name,
        trigger: action.payload,
        showTriggerCreated: true,
      };
    case UPDATE_TRIGGER:
      return {
        ...state,
        trigger: action.payload,
      };
    case CLEAR_TRIGGER_AND_FUNCTION:
      return {
        ...state,
        trigger: undefined,
        triggerFunction: undefined,
      };
    case GET_TRIGGER_EXECUTION: {
      // @ts-expect-error ts-migrate(2551) FIXME: Property 'triggers' does not exist on type '{ stit... Remove this comment to see the full error message
      const triggersById = state.triggers.reduce((acc, trigger) => {
        return {
          ...acc,
          [trigger.id]: trigger,
        };
      }, {});
      const triggerId = action.payload.resourceId;
      triggersById[triggerId] = {
        ...triggersById[triggerId],
        completedAt: action.payload.completedAt,
        // clusterTime is a Date type and is returned by default from the api as the 1970s epoch
        clusterTime: action.payload.clusterTime?.getTime() > 0 ? action.payload.clusterTime : undefined,
      };

      return {
        ...state,
        triggers: Object.values(triggersById),
      };
    }
    case CREATE_TRIGGER_FUNCTION:
      return {
        ...state,
        triggerFunction: action.payload,
      };
    case GET_TRIGGER_FUNCTION:
      return {
        ...state,
        triggerFunction: action.payload,
      };
    case UPDATE_TRIGGER_FUNCTION:
      return {
        ...state,
        triggerFunction: action.payload,
      };
    case EXECUTE_DEBUG_SOURCE:
      return {
        ...state,
        debugResultSource: [...state.debugResultSource, action.payload],
      };
    case CLEAR_DEBUG_RESULT:
      return {
        ...state,
        debugResultSource: [],
      };
    case CLEAR_TRIGGER_CREATED:
      return {
        ...state,
        createdTriggerName: '',
        showTriggerCreated: false,
      };
    case ActionType.GetLinkedDataSources: {
      const clusterDescriptionsByName = action.payload.clusterDescriptions.reduce(
        (clusterDescsByName, desc) => {
          clusterDescsByName[desc.name] = desc;
          return clusterDescsByName;
        },
        {} as Record<string, ClusterDescription>
      );

      const linkedServicesByName = action.payload.mongoServices.reduce(
        (servicesByName, linkedDataSource) => {
          const mapKey = dataSourceMapKey(linkedDataSource);
          const clusterDesc = clusterDescriptionsByName[(linkedDataSource.config as ClusterServiceConfig)?.clusterName];

          if (clusterDesc) {
            // Set cluster information relevant to document preimages and serverless
            const isServerless =
              clusterDesc.replicationSpecList[0].regionConfigs[0].cloudProvider === CloudProvider.SERVERLESS;

            servicesByName[mapKey] = {
              ...linkedDataSource,
              status: MongoServiceStatus.Linked,
              config: {
                serviceType: MongoDataSourceType.Atlas,
                dbVersion: clusterDesc.mongoDBVersion,
                clusterType: isServerless ? 'serverless' : clusterDesc.clusterType,
                clusterName: clusterDesc.name,
              },
            };
          } else {
            servicesByName[mapKey] = {
              ...linkedDataSource,
              config: {
                ...state.mongoServicesByName[mapKey]?.config,
                ...linkedDataSource.config,
              },
              status: MongoServiceStatus.Linked,
            };
          }
          return servicesByName;
        },
        {} as Record<string, MongoService>
      );

      return {
        ...state,
        mongoServicesByName: {
          ...state.mongoServicesByName,
          ...linkedServicesByName,
        },
      };
    }
    case ActionType.SetSvcsFromClusters: {
      const unlinkedClustersByName = action.payload
        .filter(isClusterLinkable)
        .reduce((clustersByName, { name, uniqueId, replicationSpecList, clusterType }: ClusterDescription) => {
          const mapKey = generateDataSourceMapKey(name, MongoDataSourceType.Atlas);
          const isServerless = replicationSpecList[0].regionConfigs[0].cloudProvider === CloudProvider.SERVERLESS;
          if (!state.mongoServicesByName[mapKey]) {
            clustersByName[mapKey] = {
              _id: uniqueId,
              name,
              type: MongoDataSourceType.Atlas,
              config: {
                serviceType: MongoDataSourceType.Atlas,
                clusterName: name,
                clusterType: isServerless ? 'serverless' : clusterType,
              },
              status: 'UNLINKED',
            };
          }
          return clustersByName;
        }, {});

      return {
        ...state,
        mongoServicesByName: {
          ...state.mongoServicesByName,
          ...unlinkedClustersByName,
        },
      };
    }
    case ActionType.SetSvcsFromDataLakes: {
      const unlinkedDataLakesByName = action.payload
        .filter(isDataLakeLinkable)
        .reduce((newServicesByName, { name, uniqueId }) => {
          const mapKey = generateDataSourceMapKey(name, MongoDataSourceType.DataFederation);
          if (!state.mongoServicesByName[mapKey]) {
            newServicesByName[mapKey] = {
              _id: uniqueId,
              name,
              type: MongoDataSourceType.DataFederation,
              config: {
                serviceType: MongoDataSourceType.DataFederation,
                dataLakeName: name,
              },
              status: 'UNLINKED',
            };
          }
          return newServicesByName;
        }, {});

      return {
        ...state,
        mongoServicesByName: {
          ...state.mongoServicesByName,
          ...unlinkedDataLakesByName,
        },
      };
    }
    case SET_SVC_LINKING: {
      const svcLinkingDataSourceKey = dataSourceMapKey(action.payload);
      return {
        ...state,
        mongoServicesByName: {
          ...state.mongoServicesByName,
          [svcLinkingDataSourceKey]: {
            ...state.mongoServicesByName[svcLinkingDataSourceKey],
            status: 'LINKING',
          },
        },
      };
    }
    case SET_SVC_UNLINKED: {
      const svcUnLinkedDataSourceKey = dataSourceMapKey(action.payload);
      return {
        ...state,
        mongoServicesByName: {
          ...state.mongoServicesByName,
          [svcUnLinkedDataSourceKey]: {
            ...state.mongoServicesByName[svcUnLinkedDataSourceKey],
            status: 'UNLINKED',
          },
        },
      };
    }
    case SET_SVC_ERROR: {
      const svcErrorKey = dataSourceMapKey(action.payload.dataSource);
      return {
        ...state,
        mongoServicesByName: {
          ...state.mongoServicesByName,
          [svcErrorKey]: {
            ...state.mongoServicesByName[svcErrorKey],
            status: 'ERROR',
            svcError: action.payload.svcError,
          },
        },
      };
    }
    case LINK_TRIGGER_DATA_SOURCE: {
      const linkTriggerDataSourceKey = dataSourceMapKey(action.payload);
      return {
        ...state,
        mongoServicesByName: {
          ...state.mongoServicesByName,
          [linkTriggerDataSourceKey]: {
            ...state.mongoServicesByName[linkTriggerDataSourceKey],
            _id: action.payload._id,
            status: 'LINKED',
          },
        },
      };
    }
    case SET_SVC_NAMESPACES:
      return {
        ...state,
        namespaces: action.payload,
      };
    case LOAD_SERVICES_REQ:
      return {
        ...state,
        loadingServices: true,
        loadServicesError: undefined,
      };
    case LOAD_SERVICES_RCV:
      return {
        ...state,
        loadingServices: false,
        loadServicesError: undefined,
      };
    case LOAD_SERVICES_ERR:
      return {
        ...state,
        loadingServices: false,
        loadServicesError: action.payload,
      };
    case LOAD_TRIGGERS_REQ:
      return {
        ...state,
        loadingTriggers: true,
        loadTriggersError: undefined,
      };
    case LOAD_TRIGGERS_RCV:
      return {
        ...state,
        loadingTriggers: false,
        loadTriggersError: undefined,
        triggers: action.payload,
      };
    case LOAD_TRIGGERS_ERR:
      return {
        ...state,
        loadingTriggers: false,
        loadTriggersError: action.payload,
      };
    case REMOVE_TRIGGER_REQ:
      return {
        ...state,
        removeTriggerError: undefined,
      };
    case REMOVE_TRIGGER_RCV:
      return {
        ...state,
        removeTriggerError: undefined,
      };
    case REMOVE_TRIGGER_ERR:
      return {
        ...state,
        removeTriggerError: action.payload,
      };
    case REMOVE_TRIGGER_FUNCTION_REQ:
      return {
        ...state,
        removeTriggerFunctionError: undefined,
      };
    case REMOVE_TRIGGER_FUNCTION_RCV:
      return {
        ...state,
        removeTriggerFunctionError: undefined,
      };
    case REMOVE_TRIGGER_FUNCTION_ERR:
      return {
        ...state,
        removeTriggerFunctionError: action.payload,
      };
    case RESUME_TRIGGER_RCV: {
      const triggerId = action.payload;

      // @ts-expect-error ts-migrate(2551) FIXME: Property 'triggers' does not exist on type '{ stit... Remove this comment to see the full error message
      const triggerObj = state.triggers.reduce((acc, trigger) => {
        return {
          ...acc,
          [trigger.id]: trigger,
        };
      }, {});

      triggerObj[triggerId] = {
        ...triggerObj[triggerId],
        error: undefined,
      };

      return {
        ...state,
        triggers: Object.values(triggerObj),
      };
    }
    case RESUME_TRIGGER_REQ:
      return {
        ...state,
        resumeTriggerError: undefined,
      };
    case RESUME_TRIGGER_ERR:
      return {
        ...state,
        resumeTriggerError: action.payload.message,
      };
    case ADD_DEPENDENCY_REQ:
      return {
        ...state,
        addDependencyError: '',
      };
    case ADD_DEPENDENCY_RCV:
      return {
        ...state,
        hasStitchApp: true,
        addDependencyError: '',
        deployStatusPolling: true,
      };
    case ADD_DEPENDENCY_ERR:
      return {
        ...state,
        addDependencyError: action.payload,
      };
    case LOAD_DEPENDENCIES_REQ:
      return {
        ...state,
        loadingDependencies: true,
        loadingDependenciesError: '',
      };
    case LOAD_DEPENDENCIES_RCV:
      return {
        ...state,
        hasStitchApp: true,
        installedViaPackageManager:
          action.payload.installedViaPackageManager && action.payload.dependenciesList.length > 0,
        dependencies: action.payload.dependenciesList.sort(({ name: a }, { name: b }) => a.localeCompare(b)),
        loadingDependencies: false,
        loadingDependenciesError: '',
      };
    case LOAD_DEPENDENCIES_ERR:
      return {
        ...state,
        loadingDependenciesError: action.payload,
        loadingDependencies: false,
      };
    case UPLOAD_DEPENDENCIES_REQ:
      return {
        ...state,
        uploadingDependenciesError: '',
      };
    case UPLOAD_DEPENDENCIES_RCV:
      return {
        ...state,
        hasStitchApp: true,
        uploadingDependenciesError: '',
        deployStatusPolling: true,
      };
    case UPLOAD_DEPENDENCIES_ERR:
      return {
        ...state,
        uploadingDependenciesError: action.payload,
      };
    case DELETE_DEPENDENCY_REQ:
      return {
        ...state,
        deleteDependencyError: '',
      };
    case DELETE_DEPENDENCY_RCV:
      return {
        ...state,
        hasStitchApp: true,
        deleteDependencyError: '',
      };
    case DELETE_DEPENDENCY_ERR:
      return {
        ...state,
        deleteDependencyError: action.payload,
      };
    case DELETE_ALL_DEPENDENCIES_REQ:
      return {
        ...state,
        deleteAllDependenciesError: '',
      };
    case DELETE_ALL_DEPENDENCIES_RCV:
      return {
        ...state,
        hasStitchApp: true,
        dependencies: [],
        deleteAllDependenciesError: '',
      };
    case DELETE_ALL_DEPENDENCIES_ERR:
      return {
        ...state,
        deleteAllDependenciesError: action.payload,
      };
    case DEPLOY_DEPENDENCIES_REQ:
      return {
        ...state,
        deploymentCheck: true,
      };
    case DEPLOY_DEPENDENCIES_RCV: {
      const { status, status_error_message } = action.payload[0];
      return {
        ...state,
        deploymentCheck: false,
        deploymentStatus: status,
        deploymentStatusError: status_error_message,
        deployStatusPolling: status === 'pending',
      };
    }
    case DEPLOY_DEPENDENCIES_ERR:
      return {
        ...state,
        deploymentError: action.payload,
        deploymentCheck: false,
      };
    case EMPTY_DEPENDENCIES:
      return {
        ...state,
        hasStitchApp: false,
        loadingDependencies: false,
      };
    case FETCH_INSTALL_STATUS_RCV:
      return {
        ...state,
        lastInstallStatus: action.payload,
      };
    case FETCH_INSTALL_STATUS_ERR:
      return {
        ...state,
        lastInstallStatus: undefined,
      };

    default:
      return state;
  }
}

// Selectors
export const getTriggersState = (state) => state.triggers;
const getStitchAppId = (state) =>
  getTriggersState(state).stitchApp ? getTriggersState(state).stitchApp.getAppId() : null;

// Action Creators
// Triggers
const setAtlasTriggersApp = (atlasTriggersApp) => ({
  type: SET_ATLAS_TRIGGERS_APP,
  payload: atlasTriggersApp,
});

const loadTriggersReq = () => ({
  type: LOAD_TRIGGERS_REQ,
});

const loadTriggersRcv = (triggers) => ({
  type: LOAD_TRIGGERS_RCV,
  payload: triggers,
});

const loadTriggersErr = (loadTriggersError) => ({
  type: LOAD_TRIGGERS_ERR,
  payload: loadTriggersError,
});

const loadServicesReq = () => ({
  type: LOAD_SERVICES_REQ,
});

const loadServicesRcv = () => ({
  type: LOAD_SERVICES_RCV,
});

const loadServicesErr = (loadServicesError) => ({
  type: LOAD_SERVICES_ERR,
  payload: loadServicesError,
});

const removeTriggerReq = () => ({
  type: REMOVE_TRIGGER_REQ,
});

const removeTriggerRcv = () => ({
  type: REMOVE_TRIGGER_RCV,
});

const removeTriggerErr = (removeTriggerError) => ({
  type: REMOVE_TRIGGER_ERR,
  payload: removeTriggerError,
});

const removeTriggerFunctionReq = () => ({
  type: REMOVE_TRIGGER_FUNCTION_REQ,
});

const removeTriggerFunctionRcv = () => ({
  type: REMOVE_TRIGGER_FUNCTION_RCV,
});

const removeTriggerFunctionErr = (removeTriggerFunctionError) => ({
  type: REMOVE_TRIGGER_FUNCTION_ERR,
  payload: removeTriggerFunctionError,
});

// Logs
const loadTriggersLogsReq = () => ({
  type: LOAD_TRIGGERS_LOGS_REQ,
});

const loadTriggersLogsRcv = (triggersLogs, { append } = { append: false }) => ({
  type: LOAD_TRIGGERS_LOGS_RCV,
  payload: { triggersLogs, append },
});

const loadTriggersLogsErr = (triggersLogsError) => ({
  type: LOAD_TRIGGERS_LOGS_ERR,
  payload: triggersLogsError,
});

const loadApp = async (loadFn, dispatch, getState) => {
  const stitchApps = await loadFn(getBaasParamsFromState(getState()));
  const atlasTriggersApp = stitchApps.find((app) => app.getProduct() === 'atlas');

  dispatch(setAtlasTriggersApp(atlasTriggersApp));
  return atlasTriggersApp;
};

const reloadAtlasTriggersApp = () => (dispatch, getState) => {
  return loadApp(applicationsService.reloadAtlasTriggersApplication, dispatch, getState);
};

export const loadAtlasTriggersApp = () => (dispatch, getState) => {
  return loadApp(applicationsService.loadAtlasTriggersApplication, dispatch, getState);
};

export const createAtlasTriggersApp =
  (deploymentModel: DeploymentModel, deploymentRegionLocation: Location, deploymentProviderRegion: ProviderRegion) =>
  async (dispatch, getState) => {
    dispatch({
      type: CREATE_ATLAS_TRIGGERS_APP_REQ,
    });

    try {
      await applicationsService.createApplication(
        'Triggers',
        deploymentModel,
        deploymentRegionLocation,
        deploymentProviderRegion,
        getBaasParamsFromState(getState()),
        'atlas'
      );
    } catch (error) {
      dispatch({
        type: CREATE_ATLAS_TRIGGERS_APP_ERR,
        payload: error,
      });
      throw error;
    }

    try {
      await dispatch(reloadAtlasTriggersApp());
    } catch (error) {
      dispatch({
        type: CREATE_ATLAS_TRIGGERS_APP_ERR,
        error,
      });
      throw error;
    }

    dispatch({
      type: CREATE_ATLAS_TRIGGERS_APP_RCV,
    });
  };

export const createAtlasTriggersAppIfNecessary =
  (deploymentModel: DeploymentModel, deploymentRegionLocation: Location, deploymentProviderRegion: ProviderRegion) =>
  async (dispatch, getState) => {
    const { stitchApp } = getTriggersFromState(getState());

    if (stitchApp) {
      return;
    }

    await dispatch(createAtlasTriggersApp(deploymentModel, deploymentRegionLocation, deploymentProviderRegion));
  };

export const syncFromLocalStorage = () => (dispatch, getState) => {
  const localStorageForGroup = getLocalStorageForGroup(getState());
  const localStorageData = { ...localStorageForGroup.getData() };

  dispatch({
    type: SYNC_FROM_LOCAL_STORAGE,
    payload: localStorageData,
  });
};

export const getMetrics = (groupId, appId, start, end, granularity) => async (dispatch, getState) => {
  const lastRefresh = getTriggersState(getState()).metrics.lastRefresh;
  const differenceInMillis = new Date().valueOf() - new Date(lastRefresh).valueOf();
  if (!lastRefresh || differenceInMillis > REFRESH_TIME) {
    const appMetrics = await applicationsService.getMetrics(
      getBaasParamsFromState(getState()),
      appId,
      start,
      end,
      granularity
    );

    const response = dispatch({
      type: ActionType.GetAppMetrics,
      payload: appMetrics.measurements,
    });

    const localStorageForGroup = getLocalStorageForGroup(getState());
    const data = getTriggersState(getState()).metrics;
    localStorageForGroup.saveData(data);

    return response;
  }
  return Promise.resolve();
};

// Logs
const setPaginationParams = (paginationNextEndTime, paginationCurrentSkip) => ({
  type: SET_PAGINATION_PARAMS,
  payload: { paginationNextEndTime, paginationCurrentSkip },
});

export const loadLogs = (stitchAppId, filter) => async (dispatch, getState) => {
  dispatch(loadTriggersLogsReq());
  if (!filter.type) {
    filter.type = 'DB_TRIGGER,SCHEDULED_TRIGGER';
  }

  let payload;
  try {
    payload = await applicationsService.getLogs(getBaasParamsFromState(getState()), stitchAppId, filter);
  } catch ({ message }) {
    dispatch(loadTriggersLogsErr(message));
    return;
  }

  const logs = payload.logs;
  const paginationNextEndTime = payload.nextEndDate ? new Date(payload.nextEndDate) : undefined;
  const paginationCurrentSkip = filter.skip;

  const { triggerLogItemFromRaw } = await import('js/project/triggers/helpers/converters');
  const { groupId } = getBaasParamsFromState(getState());

  dispatch(
    loadTriggersLogsRcv(
      logs.map((logItem) => triggerLogItemFromRaw(logItem, groupId, stitchAppId)),
      { append: !!paginationCurrentSkip }
    )
  );
  dispatch(setPaginationParams(paginationNextEndTime, paginationCurrentSkip));
};

export const getTrigger = (triggerId) => async (dispatch, getState) => {
  const stitchAppId = getStitchAppId(getState());
  const payload = await applicationsService.getTrigger(getBaasParamsFromState(getState()), stitchAppId, triggerId);
  const { fromRawTrigger } = await import('js/project/triggers/helpers/converters');

  dispatch({
    type: GET_TRIGGER,
    payload: fromRawTrigger(payload),
  });

  return payload;
};

// Triggers
export const createTrigger = (trigger) => async (dispatch, getState) => {
  const stitchAppId = getStitchAppId(getState());
  const { toRawTrigger, fromRawTrigger } = await import('js/project/triggers/helpers/converters');
  const payload = await applicationsService.createTrigger(
    getBaasParamsFromState(getState()),
    stitchAppId,
    toRawTrigger(trigger)
  );

  dispatch({
    type: CREATE_TRIGGER,
    payload: fromRawTrigger(payload),
  });

  return payload;
};

export const updateTrigger = (triggerId, updatedTrigger) => async (dispatch, getState) => {
  const stitchAppId = getStitchAppId(getState());
  const { toRawTrigger } = await import('js/project/triggers/helpers/converters');
  const payload = await applicationsService.updateTrigger(
    getBaasParamsFromState(getState()),
    stitchAppId,
    triggerId,
    toRawTrigger(updatedTrigger)
  );
  dispatch({
    type: UPDATE_TRIGGER,
    payload: updatedTrigger,
  });
  return payload;
};

export const clearTriggerAndFunction = () => (dispatch) => {
  dispatch({
    type: CLEAR_TRIGGER_AND_FUNCTION,
  });
};

export const getTriggerExecution = (triggerId) => async (dispatch, getState) => {
  const stitchAppId = getStitchAppId(getState());
  const payload = await applicationsService.getTriggerExecution(
    getBaasParamsFromState(getState()),
    stitchAppId,
    triggerId
  );
  dispatch({
    type: GET_TRIGGER_EXECUTION,
    payload,
  });
  return payload;
};

export const createTriggerFunction = (func) => async (dispatch, getState) => {
  const stitchAppId = getStitchAppId(getState());
  const { toRawFunction } = await import('js/project/triggers/helpers/converters');
  const response = await applicationsService.createFunction(
    getBaasParamsFromState(getState()),
    stitchAppId,
    toRawFunction(func)
  );

  dispatch({
    type: CREATE_TRIGGER_FUNCTION,
    payload: {
      ...func,
      id: response._id,
    },
  });

  return response;
};

export const getTriggerFunction = (funcId) => async (dispatch, getState) => {
  const stitchAppId = getStitchAppId(getState());
  const payload = await applicationsService.getFunction(getBaasParamsFromState(getState()), stitchAppId, funcId);
  const { fromRawFunction } = await import('js/project/triggers/helpers/converters');
  const stitchFunction = fromRawFunction(payload);
  dispatch({
    type: GET_TRIGGER_FUNCTION,
    payload: stitchFunction,
  });
  return payload;
};

export const updateTriggerFunction = (functionId, updatedFunc) => async (dispatch, getState) => {
  const stitchAppId = getStitchAppId(getState());
  const payload = await applicationsService.updateFunction(
    getBaasParamsFromState(getState()),
    stitchAppId,
    functionId,
    updatedFunc
  );
  dispatch({
    type: UPDATE_TRIGGER_FUNCTION,
    payload: updatedFunc,
  });
  return payload;
};

export const executeDebugSource = (source, evalSource) => async (dispatch, getState) => {
  const stitchAppId = getStitchAppId(getState());
  const date = new Date(Date.now()).toString();
  let payload;
  try {
    payload = await applicationsService.executeDebugSource(getBaasParamsFromState(getState()), stitchAppId, {
      source,
      evalSource,
      runAsSystem: true,
      userId: null,
    });
  } catch (errorPayload) {
    payload = { error: errorPayload.message, rawError: errorPayload };
  }

  dispatch({
    type: EXECUTE_DEBUG_SOURCE,
    payload: { ...payload, timestamp: `${date}` },
  });
  return payload;
};

export const clearDebugResult = () => (dispatch) => {
  dispatch({
    type: CLEAR_DEBUG_RESULT,
  });
};

export const getLinkedDataSources = () => async (dispatch, getState) => {
  const stitchAppId = getStitchAppId(getState());
  const baasParamsFromState = getBaasParamsFromState(getState());
  const mongoServices = await applicationsService.getMongoServices(baasParamsFromState, stitchAppId);
  const { payload: clusterDescriptions } = await dispatch(loadClusterDescriptions(baasParamsFromState.groupId));
  await mongoServices.forEach(async (svc) => {
    try {
      await applicationsService.listSvcNamespaces(getBaasParamsFromState(getState()), stitchAppId, svc._id);
    } catch (errorPayload) {
      if (!errorPayload.message.includes('this service was recently updated')) {
        dispatch({
          type: SET_SVC_ERROR,
          payload: {
            dataSource: svc,
            svcError: errorPayload.message,
          },
        });
        return;
      }
      dispatch({
        type: SET_SVC_LINKING,
        payload: svc,
      });
      let attempt = 1;
      let isLinking = true;
      // keep polling to see when linking is finished
      while (isLinking) {
        await new Promise((resolve) => setTimeout(resolve, 5e3)); // eslint-disable-line no-await-in-loop
        try {
          // eslint-disable-next-line no-await-in-loop
          await applicationsService.listSvcNamespaces(getBaasParamsFromState(getState()), stitchAppId, svc._id);
          dispatch({
            type: LINK_TRIGGER_DATA_SOURCE,
            payload: svc,
          });
          isLinking = false;
        } catch (error) {
          if (attempt > MAX_LINKING_CHECK_ATTEMPTS) {
            // Stop after 15min of attempts
            dispatch({
              type: SET_SVC_ERROR,
              payload: {
                ...svc,
                svcError: `error: unable to verify data source '${
                  svc.config.clusterName || svc.config.dataLakeName
                }' has been linked: ${error.message}`,
              },
            });
            isLinking = false;
          }
          attempt++;
        }
      }
    }
  });
  dispatch({
    type: ActionType.GetLinkedDataSources,
    payload: { mongoServices, clusterDescriptions },
  });
  return mongoServices;
};

export const setSvcsFromClusters = (clusters: Array<ClusterDescription>) => (dispatch) =>
  dispatch({
    type: ActionType.SetSvcsFromClusters,
    payload: clusters,
  });

export const setSvcsFromDataLakes = (dataLakes) => (dispatch) =>
  dispatch({
    type: ActionType.SetSvcsFromDataLakes,
    payload: dataLakes,
  });

export const setSvcLinking = (dataSource) => (dispatch) =>
  dispatch({
    type: SET_SVC_LINKING,
    payload: dataSource,
  });

export const setSvcUnlinked = (dataSource) => (dispatch) =>
  dispatch({
    type: SET_SVC_UNLINKED,
    payload: dataSource,
  });

export const linkTriggerDataSource = (dataSource) => async (dispatch, getState) => {
  const stitchAppId = getStitchAppId(getState());

  let payload;
  try {
    if (dataSource.type === MongoDataSourceType.DataFederation) {
      payload = await applicationsService.createAndLinkDataLakeService({
        ...getBaasParamsFromState(getState()),
        appId: stitchAppId,
        dataLakeName: dataSource.name,
        serviceName: dataSource.name,
      });
    } else {
      payload = await applicationsService.createAndLinkClusterService({
        ...getBaasParamsFromState(getState()),
        appId: stitchAppId,
        clusterName: dataSource.name,
        serviceName: dataSource.name,
      });
    }
  } catch (errorPayload) {
    dispatch({
      type: SET_SVC_ERROR,
      payload: {
        dataSource,
        svcError:
          errorPayload.code === LINK_SERVICE_EXISTS_ERROR_CODE
            ? getDuplicateDataSourceError(getBaasParamsFromState(getState()), getStitchAppId(getState()))
            : errorPayload.message,
      },
    });
  }

  // Check that the data source is ready to use by attempting to list namespaces.
  // Intentionally await in loop to poll periodically and catch errors.
  let attempt = 1;
  let isLinking = true;
  while (isLinking) {
    await new Promise((resolve) => setTimeout(resolve, 5e3)); // eslint-disable-line no-await-in-loop
    try {
      // eslint-disable-next-line no-await-in-loop
      await applicationsService.listSvcNamespaces(getBaasParamsFromState(getState()), stitchAppId, payload._id);
      isLinking = false;
    } catch (errorPayload) {
      if (attempt > MAX_LINKING_CHECK_ATTEMPTS) {
        // Stop after 15min of attempts
        dispatch({
          type: SET_SVC_ERROR,
          payload: {
            dataSource,
            svcError: `error: unable to verify if data source '${dataSource.name}' has been linked: ${errorPayload.message}`,
          },
        });
        return payload;
      }
      attempt++;
    }
  }
  dispatch({
    type: LINK_TRIGGER_DATA_SOURCE,
    payload,
  });
  return payload;
};

export const listSvcNamespaces = (svcId) => async (dispatch, getState) => {
  dispatch({
    type: SET_SVC_NAMESPACES,
    payload: [],
  });
  const stitchAppId = getStitchAppId(getState());
  const payload = await applicationsService.listSvcNamespaces(getBaasParamsFromState(getState()), stitchAppId, svcId);
  dispatch({
    type: SET_SVC_NAMESPACES,
    payload,
  });
  return payload;
};

export const loadTriggers = () => async (dispatch, getState) => {
  dispatch(loadTriggersReq());
  const stitchAppId = getStitchAppId(getState());
  let triggersPayload;
  try {
    triggersPayload = await applicationsService.getTriggers(getBaasParamsFromState(getState()), stitchAppId);
  } catch ({ message }) {
    dispatch(loadTriggersErr(message));
    return;
  }

  // Load services and pair them with the triggers
  dispatch(loadServicesReq());
  let servicesPayload;
  try {
    servicesPayload = await applicationsService.getServices(getBaasParamsFromState(getState()), stitchAppId);
  } catch ({ message }) {
    dispatch(loadServicesErr(message));
    return;
  }
  dispatch(loadServicesRcv());

  dispatch(
    loadTriggersRcv(
      triggersPayload.map((trigger) => {
        const service = servicesPayload.find((svc) => svc._id === trigger.serviceId);
        return {
          ...trigger,
          serviceName: service && service.name,
        };
      })
    )
  );
};

const removeTriggerFunction = (functionId) => async (dispatch, getState) => {
  dispatch(removeTriggerFunctionReq());
  const stitchAppId = getStitchAppId(getState());
  try {
    await applicationsService.removeFunction(getBaasParamsFromState(getState()), stitchAppId, functionId);
  } catch ({ message }) {
    dispatch(removeTriggerFunctionErr(message));
    return;
  }
  dispatch(removeTriggerFunctionRcv());
};

export const removeTriggerAndAssociatedFunction = (triggerId) => async (dispatch, getState) => {
  let { triggers } = getTriggersState(getState());
  let removedTrigger = triggers.find((trigger) => trigger.id === triggerId);

  if (!removedTrigger) {
    // this trigger isn't cached in local state, fetch triggers again
    try {
      await dispatch(loadTriggers());
    } catch ({ message }) {
      dispatch(loadTriggersErr(message));
      return;
    }
    ({ triggers } = getTriggersState(getState()));
    removedTrigger = triggers.find((trigger) => trigger.id === triggerId);
  }

  // Remove the trigger
  dispatch(removeTriggerReq());
  const stitchAppId = getStitchAppId(getState());
  try {
    await applicationsService.removeTrigger(getBaasParamsFromState(getState()), stitchAppId, triggerId);
  } catch ({ message }) {
    dispatch(removeTriggerErr(message));
    return;
  }

  // Remove trigger from local state
  const updatedTriggers = triggers.filter((trigger) => trigger.id !== triggerId);
  dispatch(removeTriggerRcv());
  dispatch(loadTriggersRcv(updatedTriggers));

  // if the trigger used an event processor instead of a function, we're done
  if (removedTrigger.eventProcessor) {
    return;
  }

  // remove the associated function if there is one
  const { functionId: triggerFunctionId } = removedTrigger;
  dispatch(removeTriggerFunction(triggerFunctionId));
};

export const resumeTrigger = (triggerId, disableToken) => async (dispatch, getState) => {
  dispatch({
    type: RESUME_TRIGGER_REQ,
  });
  try {
    await applicationsService.resumeTrigger(getBaasParamsFromState(getState()), getStitchAppId(getState()), triggerId, {
      disableToken,
    });
  } catch (err) {
    dispatch({
      type: RESUME_TRIGGER_ERR,
      payload: err,
    });
    return;
  }
  dispatch({
    type: RESUME_TRIGGER_RCV,
    payload: triggerId,
  });
};

export const clearTriggerCreatedAlert = () => (dispatch) => {
  dispatch({
    type: CLEAR_TRIGGER_CREATED,
  });
};

export const upsertDependency = (depName: string, version?: string) => async (dispatch, getState) => {
  const stitchAppId = getStitchAppId(getState());
  dispatch({
    type: ADD_DEPENDENCY_REQ,
  });

  try {
    await applicationsService.upsertDependency(getBaasParamsFromState(getState()), stitchAppId, depName, version);
  } catch ({ err }) {
    dispatch({
      type: ADD_DEPENDENCY_ERR,
      payload: err,
    });
    return;
  }

  dispatch({
    type: ADD_DEPENDENCY_RCV,
  });
};

export const loadDependencies = () => async (dispatch, getState) => {
  const stitchAppId = getStitchAppId(getState());
  if (!stitchAppId) {
    dispatch({
      type: EMPTY_DEPENDENCIES,
    });
    return;
  }

  dispatch({
    type: LOAD_DEPENDENCIES_REQ,
  });

  let dependenciesPayload;
  try {
    dependenciesPayload = await applicationsService.getDependencies(getBaasParamsFromState(getState()), stitchAppId);
  } catch ({ err }) {
    dispatch({
      type: LOAD_DEPENDENCIES_ERR,
      payload: err,
    });
    return;
  }

  dispatch({
    type: LOAD_DEPENDENCIES_RCV,
    payload: dependenciesPayload,
  });
};

export const uploadDependencies = (filename, body) => async (dispatch, getState) => {
  dispatch({
    type: UPLOAD_DEPENDENCIES_REQ,
  });

  const stitchAppId = getStitchAppId(getState());
  try {
    await applicationsService.uploadDependencies(getBaasParamsFromState(getState()), stitchAppId, filename, body);
  } catch ({ message }) {
    dispatch({
      type: UPLOAD_DEPENDENCIES_ERR,
      payload: message,
    });
    return;
  }

  dispatch({
    type: UPLOAD_DEPENDENCIES_RCV,
  });
};

export const deleteDependency = (dependencyName: string) => async (dispatch, getState) => {
  const stitchAppId = getStitchAppId(getState());

  dispatch({
    type: DELETE_DEPENDENCY_REQ,
  });

  try {
    await applicationsService.deleteDependency(getBaasParamsFromState(getState()), stitchAppId, dependencyName);
  } catch ({ err }) {
    dispatch({
      type: DELETE_DEPENDENCY_ERR,
      payload: err,
    });
    return;
  }

  dispatch({
    type: DELETE_DEPENDENCY_RCV,
  });
};

export const deleteAllDependencies = () => async (dispatch, getState) => {
  const stitchAppId = getStitchAppId(getState());

  dispatch({
    type: DELETE_ALL_DEPENDENCIES_REQ,
  });

  try {
    await applicationsService.deleteAllDependencies(getBaasParamsFromState(getState()), stitchAppId);
  } catch ({ err }) {
    dispatch({
      type: DELETE_ALL_DEPENDENCIES_ERR,
      payload: err,
    });
    return;
  }

  dispatch({
    type: DELETE_ALL_DEPENDENCIES_RCV,
  });
};

let triggersDependencyPoller;

const getTriggersDependencyPoller = (dispatch, getState) => {
  if (!triggersDependencyPoller) {
    triggersDependencyPoller = servicePoller.create(async () => {
      dispatch({
        type: DEPLOY_DEPENDENCIES_REQ,
      });

      const stitchAppId = getStitchAppId(getState());
      const { userId } = getBaasParamsFromState(getState());
      let deploymentsPayload;
      try {
        deploymentsPayload = await applicationsService.getDependencyDeployStatus(
          getBaasParamsFromState(getState()),
          stitchAppId,
          userId
        );
      } catch ({ err }) {
        dispatch({
          type: DEPLOY_DEPENDENCIES_ERR,
          payload: err,
        });
        return;
      }
      dispatch({
        type: DEPLOY_DEPENDENCIES_RCV,
        payload: deploymentsPayload,
      });
      if (getState().deployStatusPolling) {
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 0.
        getTriggersDependencyPoller().stop();
      }
    });
  }

  return triggersDependencyPoller;
};

export const startPolling = () => async (dispatch, getState) => {
  return getTriggersDependencyPoller(dispatch, getState).immediate().start(10e3);
};

export const fetchDependencyInstallStatus = () => async (dispatch, getState) => {
  const stitchAppId = getStitchAppId(getState());
  let installPayload;
  try {
    installPayload = await applicationsService.getDependencyInstallStatus(
      getBaasParamsFromState(getState()),
      stitchAppId
    );
  } catch (error) {
    dispatch({
      type: FETCH_INSTALL_STATUS_ERR,
      error,
    });
    return;
  }

  dispatch({
    type: FETCH_INSTALL_STATUS_RCV,
    payload: installPayload,
  });
};

export const linkClusters = (dataSources: Array<MongoServiceConfig>) => async (dispatch, getState) => {
  const baasParams = getBaasParamsFromState(getState());
  const baasAppId = getStitchAppId(getState());

  const mongoServices: Array<MongoService> = await applicationsService.getMongoServices(baasParams, baasAppId);

  for (const dataSource of dataSources) {
    if (
      isCluster(dataSource) &&
      !mongoServices.some((mongoService: MongoService) => mongoService.name === dataSource.clusterName)
    ) {
      const clusterDataSource = dataSource as ClusterServiceConfig;
      try {
        await applicationsService.createAndLinkClusterService({
          ...getBaasParamsFromState(getState()),
          appId: baasAppId,
          clusterName: clusterDataSource.clusterName,
          serviceName: clusterDataSource.clusterName,
        });
      } catch (error) {
        dispatch({
          type: SET_SVC_ERROR,
          payload: {
            dataSource: dataSource.clusterName,
            svcError: error.message,
          },
        });
      }
    }
  }
};

export const linkDataLake = (dataSource: MongoServiceConfig) => async (getDispatch, getState) => {
  const baasParams = getBaasParamsFromState(getState());
  const baasAppId = getStitchAppId(getState());

  const mongoServices: Array<MongoService> = await applicationsService.getMongoServices(baasParams, baasAppId);

  if (
    isDataLake(dataSource) &&
    !mongoServices.some((mongoService: MongoService) => mongoService.name === dataSource.dataLakeName)
  ) {
    const { dataLakeName } = dataSource;
    await applicationsService.createAndLinkDataLakeService({
      ...getBaasParamsFromState(getState()),
      appId: baasAppId,
      dataLakeName,
      serviceName: dataLakeName,
    });
  }
};

const getTriggerDeploymentConfig = async (
  provider: CloudProvider,
  region: string,
  {
    groupId,
    baasUrl,
    username,
  }: {
    groupId: any;
    baasUrl: any;
    username: any;
  }
): Promise<{ deploymentModel: DeploymentModel; deploymentRegionLocation: Location }> => {
  // const { nearestAppRegion } = await applicationsService.getNearestAtlasAppRegion(
  //   { groupId, baasUrl, username },
  //   provider,
  //   region
  // );
  // const deploymentRegionLocation: Location = providerRegionToLocation[nearestAppRegion];

  // hardcode deploymentModel to Global and location to aws-us-east-1
  return Promise.resolve({
    deploymentModel: DeploymentModel.Global,
    deploymentRegionLocation: Location.Virginia,
  });
};

export const createTriggerForAtlasDataFederation =
  ({
    provider,
    region,
    clusterName,
    dataLakeName,
    trigger,
    func,
  }: {
    provider: CloudProvider;
    region: string;
    clusterName: string;
    dataLakeName: string;
    trigger: ScheduledTrigger;
    func: CreateAppFunction;
  }) =>
  async (dispatch, getState) => {
    await dispatch(loadAtlasTriggersApp());

    const { deploymentModel, deploymentRegionLocation } = await getTriggerDeploymentConfig(
      provider,
      region,
      getBaasParamsFromState(getState())
    );
    await dispatch(
      createAtlasTriggersAppIfNecessary(
        deploymentModel,
        deploymentRegionLocation,
        // hardcode delpoyment provider region to aws-us-east-1
        ProviderRegion.AWSProviderRegionUSEast1
      )
    );

    // we only need to link the newly created federated database instance
    const dataLakeSource: DataLakeServiceConfig = {
      serviceType: MongoDataSourceType.DataFederation,
      dataLakeName,
    };
    await dispatch(linkDataLake(dataLakeSource));

    const { _id: functionId } = await dispatch(createTriggerFunction(func));
    return await dispatch(createTrigger({ ...trigger, functionId, functionName: func.name }));
  };
