import { useEffect } from 'react';

import { css } from '@emotion/react';
import Button, { Variant } from '@leafygreen-ui/button';
import Card from '@leafygreen-ui/card';
import Icon from '@leafygreen-ui/icon';
import { palette } from '@leafygreen-ui/palette';
import { Body, Link, Subtitle } from '@leafygreen-ui/typography';

import { FactorProvider, FactorType } from '@packages/types/accountMultiFactorAuth';

import useMfaFactors from '@packages/hooks/useMfaFactors';

import { useAccountUser } from 'js/common/context/AccountUserContext';
import AuthenticatorIcon from 'js/common/images/mfaFactors/authenticator_icon.svg';
import EmailIcon from 'js/common/images/mfaFactors/email_icon.svg';
import OktaVerifyAppIcon from 'js/common/images/mfaFactors/okta_verify_app_icon.svg';
import SmsIcon from 'js/common/images/mfaFactors/sms_icon.svg';
import WebAuthnIcon from 'js/common/images/mfaFactors/webauthn_icon.svg';
import { factorMetadata, getFactorId } from 'js/common/utils/multiFactorAuthUtils';

// images
import NotEnabledMFAMethodIcon from './images/not_enabled_MFA_method_icon.svg';

export default function MultiFactorAuthMethods({
  windowLocation = window.location,
  location: { search } = { search: '' },
}: {
  windowLocation?: Pick<Location, 'assign'>;
  location?: Pick<Location, 'search'>;
}) {
  const { factors, userFactorsLoadError } = useMfaFactors();
  const { loadAccountUserParams } = useAccountUser();

  useEffect(() => {
    loadAccountUserParams();
  }, [loadAccountUserParams]);

  const onSetupFactor = (setupParam) => {
    windowLocation.assign(`/account/security/mfa/setup/${setupParam}${search}`);
  };

  const getFactor = (factorProvider, factorType, factorIcon) => {
    const { name, setupParam } = factorMetadata[factorType];
    const isConfigured = !!getFactorId(factorProvider, factorType, factors);

    return (
      <Card
        css={css({
          width: '494px',
          margin: '0px 0px 16px',
          borderRadius: '7px',
          border: `1px solid ${palette.gray.light2}`,
          minHeight: '20px',
        })}
      >
        <div css={css({ display: 'flex' })}>
          {isConfigured ? (
            <Icon glyph="CheckmarkWithCircle" fill={palette.green.dark2} size={26} />
          ) : (
            <img src={NotEnabledMFAMethodIcon} alt="Not Enabled MFA Method" />
          )}
          <div css={css({ display: 'flex', alignItems: 'center', flexGrow: 2, marginLeft: '40px' })}>
            <img src={factorIcon} alt={name} />
            <Body css={css({ marginLeft: '12px' })}>{name}</Body>
          </div>
          {!isConfigured && (
            <Button
              variant={Variant.PrimaryOutline}
              disabled={!!userFactorsLoadError}
              size="small"
              css={css({ textTransform: 'uppercase' })}
              name={name}
              onClick={() => onSetupFactor(setupParam)}
            >
              Setup
            </Button>
          )}
        </div>
      </Card>
    );
  };

  return (
    <>
      <Subtitle>Multi-factor authentication (MFA) methods</Subtitle>
      <Body css={css({ marginBottom: '24px' })}>
        {factors.length === 0
          ? 'For increased security, we recommend configuring an MFA method to help protect you MongoDB account.'
          : 'In the case that you lose access to your first enabled MFA method, set up another one to avoid being locked out.'}
        &nbsp;&nbsp;<Link href="https://dochub.mongodb.org/core/atlas-mfa">Learn more</Link>
      </Body>
      {getFactor(FactorProvider.Okta, FactorType.Email, EmailIcon)}
      {getFactor(FactorProvider.Okta, FactorType.Push, OktaVerifyAppIcon)}
      {getFactor(FactorProvider.Fido, FactorType.WebAuthn, WebAuthnIcon)}
      {getFactor(FactorProvider.Google, FactorType.TokenSoftwareTotp, AuthenticatorIcon)}
      {getFactor(FactorProvider.Okta, FactorType.Sms, SmsIcon)}
    </>
  );
}
