import styled from '@emotion/styled';

import colors from 'js/auth/utils/palette';

export const ErrorMessage = styled.span`
  color: ${colors.warning};
  a {
    color: ${colors.link};
  }
`;
