import { ServerlessTenantMigration } from '@packages/types/dashboard/ServerlessMTMPoolManager';

import fetchWrapper from '../fetchWrapper';

export default {
  postServerlessTenantMigration(groupId: string, serverlessInstanceName: string) {
    return fetchWrapper(`/nds/serverless/tenantMigrations/${groupId}/${serverlessInstanceName}`, {
      method: 'POST',
    });
  },

  postTargetedServerlessTenantMigration(groupId: string, serverlessInstanceName: string, targetMTMClusterName: string) {
    return fetchWrapper(
      `/nds/serverless/tenantMigrations/${groupId}/${serverlessInstanceName}/${targetMTMClusterName}`,
      {
        method: 'POST',
      }
    );
  },

  getServerlessTenantMigration(tenantMigrationId: string): Promise<ServerlessTenantMigration> {
    return fetchWrapper(`/nds/serverless/tenantMigrations/${tenantMigrationId}`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getServerlessTenantMigrationsByPool(poolId: string): Promise<Array<ServerlessTenantMigration>> {
    return fetchWrapper(`/nds/serverless/tenantMigrations/pool/${poolId}`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  getInProgressServerlessTenantMigrationsByPool(poolId: string): Promise<Array<ServerlessTenantMigration>> {
    return fetchWrapper(`/nds/serverless/tenantMigrations/pool/inProgress/${poolId}`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },
};
