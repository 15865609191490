/**
 * NOTE: THIS FILE IS DEPRECATED.
 * For experiment assignment overrides, please see
 * https://wiki.corp.mongodb.com/display/MMS/Override+Assignments+for+AB+Tests
 *
 * Please do not add any new code to this file.
 *
 * Ask in #ask-atlas-growth if you have any questions.
 */
import queryString from 'query-string';

/**
 * @deprecated
 *
 * Query string key at which to populate a comma-separated list of test group IDs
 */
export const testGroupNamesQStringKey = 'testGroupNames';

/**
 * @deprecated
 *
 * Query string key at which to find the params passed in from the registration process
 */
export const registrationDataQStringKey = 'registrationData';

// Caching search upon load, so that if the application wipes the query string, we still have the initial values in this state.
let search = typeof window === 'object' ? window.location.search : '';

// A cache for testGroupNames so we don't run the parsing function every time we need them.
let testGroupNamesCache;

/**
 * @deprecated
 * Use Experiment Overrides instead: https://wiki.corp.mongodb.com/display/MMS/Override+Assignments+for+AB+Tests
 * The functions/approach in this file do not work with experimentUtils functions.
 *
 * Obtains a comma-separated list of AB test group IDs from the query string, allowing the developer to code a backdoor into a test variation.
 */
export const isForcedIntoTestGroup = (testGroupName: string): boolean => {
  // The first time isForcedIntoTestGroup is called, we'll cache the ids so as to avoid recalling the expensive behavior of getTestGroupNames
  testGroupNamesCache = testGroupNamesCache || utils.getTestGroupNames();

  return testGroupNamesCache.includes(testGroupName);
};

/**
 * @deprecated
 * Use Experiment Overrides instead: https://wiki.corp.mongodb.com/display/MMS/Override+Assignments+for+AB+Tests
 * The functions/approach in this file do not work with experimentUtils functions.
 */
export const utils = {
  getTestGroupNames(): Array<string> {
    const searchObj = queryString.parse(search);

    // Extract test group IDs directly from the qString if possible
    let testGroupNamesStr = searchObj[testGroupNamesQStringKey];

    if (!testGroupNamesStr) {
      // If we didn't find test group ID in the query string, attempt to extract them from the regitration params
      const registrationDataStr = searchObj[registrationDataQStringKey] as string;
      try {
        testGroupNamesStr = JSON.parse(registrationDataStr)[testGroupNamesQStringKey];
      } catch (e) {
        // ignore errors
      }
    }

    // If we failed to gather test group IDs, normalize with empty array
    const cache = typeof testGroupNamesStr === 'string' ? testGroupNamesStr.split(',') : [];

    return cache;
  },

  // Mainly for unit test purposes:
  reset(newSearch: string) {
    search = newSearch;
    testGroupNamesCache = undefined;
  },
};
