import PropTypes from 'prop-types';
import _ from 'underscore';

import { CloudProvider } from '@packages/types/nds/provider';

import { REGION_OPTION_SHAPE } from 'js/project/nds/common/components/regionSchema';

const DISK_SIZE_SHAPE = {
  type: PropTypes.string,
  iops: PropTypes.number,
  throughputMBPerSecond: PropTypes.number,
};

export const GEO_SHARDING_SHAPE = {
  managedNamespaces: PropTypes.arrayOf(
    PropTypes.shape({
      db: PropTypes.string,
      collection: PropTypes.string,
      customShardKey: PropTypes.string,
      isUniqueIndex: PropTypes.bool,
      isHashedIndex: PropTypes.bool,
      presplitHashedZones: PropTypes.bool,
      numInitialChunks: PropTypes.number,
    })
  ),
  customZoneMapping: PropTypes.objectOf(PropTypes.string),
};

export const MONGODB_MAJOR_VERSION_SHAPE = {
  default: PropTypes.bool,
  deprecated: PropTypes.bool,
  name: PropTypes.string,
};

const AVAILABLE_REGION_SHAPE = {
  isBlacklisted: PropTypes.bool,
  availableFamilies: PropTypes.arrayOf(PropTypes.string),
};

const REGION_FOR_INSTANCE_SHAPE = {
  regionName: PropTypes.string,
  providerName: PropTypes.string,
  isBlacklisted: PropTypes.bool,
  availableFamilies: PropTypes.arrayOf(PropTypes.string),
  mtmMongoDBMajorVersion: PropTypes.shape(MONGODB_MAJOR_VERSION_SHAPE),
};

const INSTANCE_SIZE_SHAPE = {
  name: PropTypes.string,
  ram: PropTypes.number,
  defaultStorageGB: PropTypes.number,
  storageOptionsGB: PropTypes.arrayOf(PropTypes.number),
  minStorageGB: PropTypes.number,
  maxStorageGB: PropTypes.number,
  maxAllowedStorageGB: PropTypes.number,
  defaultMaxAllowedStorageGB: PropTypes.number,
  vCPU: PropTypes.number,
  baseDollarsPerHour: PropTypes.object,
  availableRegions: PropTypes.arrayOf(PropTypes.shape(REGION_FOR_INSTANCE_SHAPE)),
  supportsSharding: PropTypes.bool,
  maxConns: PropTypes.number,
  networkingPerformance: PropTypes.string,
  maxIOPS: PropTypes.number,
  maxIOPSPerGB: PropTypes.number,
  maxStandardIOPS: PropTypes.number,
  minIOPS: PropTypes.number,
  minIOPSPerGB: PropTypes.number,
  isLowCPU: PropTypes.bool,
  highCPUEquivalent: PropTypes.string,
  hasInstanceFamilies: PropTypes.bool,
  isNVME: PropTypes.bool,
  isDeprecated: PropTypes.bool,
  providerFeatures: PropTypes.arrayOf(PropTypes.string),
};

const BI_CONNECTOR_SHAPE = {
  enabled: PropTypes.bool,
  readPreference: PropTypes.string,
};

const BI_CONNECTOR_READ_PREFERENCE = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  ANALYTICS: 'analytics',
};

const COMPUTE_AUTO_SCALING_SHAPE = {
  enabled: PropTypes.bool,
  scaleDownEnabled: PropTypes.bool,
  minInstanceSize: PropTypes.string,
  maxInstanceSize: PropTypes.string,
};

const DISK_AUTO_SCALING_SHAPE = {
  enabled: PropTypes.bool,
};

const INDEX_AUTO_SCALING_SHAPE = {
  enabled: PropTypes.bool,
};

const AUTO_SCALING_SHAPE = {
  diskGB: PropTypes.shape(DISK_AUTO_SCALING_SHAPE),
  compute: PropTypes.shape(COMPUTE_AUTO_SCALING_SHAPE),
  autoIndex: PropTypes.shape(INDEX_AUTO_SCALING_SHAPE),
};

interface PrivateIpMode {
  enabled: boolean;
}

interface ServerlessBillingEstimateInterface {
  tier1ReadDollars: number;
  tier2ReadDollars: number;
  tier3ReadDollars: number;
  tier4ReadDollars: number;
  serverlessWriteDollars: number;
  serverlessStorageDollars: number;
}

const BI_CONNECTOR_COST_ESTIMATE_SHAPE = {
  biConnectorMaxDailyCents: PropTypes.number.isRequired,
  biConnectorMaxMonthlyCents: PropTypes.number.isRequired,
  biConnectorSustainedDailyCents: PropTypes.number.isRequired,
};

const CLUSTER_TYPE = {
  REPLICASET: 'REPLICASET',
  SHARDED: 'SHARDED',
  GEOSHARDED: 'GEOSHARDED',
};

const ENCRYPTION_AT_REST_PROVIDER = {
  AWS: 'AWS',
  AZURE: 'AZURE',
  GCP: 'GCP',
  NONE: 'NONE',
};

const VOLUME_TYPE = {
  gp3: 'gp3',
  gp2: 'gp2',
  io1: 'io1',
  io2: 'io2',
};

const INSTANCE_CLASS = {
  LOW_CPU: 'lowCPU',
  HIGH_CPU: 'highCPU',
  NVME: 'NVMe',
};

const ROOT_CERT_TYPE = {
  DST: 'DST',
  ISRGROOTX1: 'ISRGROOTX1',
};

const HARDWARE_SPEC_SHAPE = {
  nodeCount: PropTypes.number.isRequired,
  instanceSize: PropTypes.string.isRequired,

  // AWS
  diskIOPS: PropTypes.number,
  volumeType: PropTypes.oneOf(Object.keys(VOLUME_TYPE)),
  encryptEBSVolume: PropTypes.bool,

  // Azure
  diskType: PropTypes.string,

  // Free and Serverless
  backingCloudProvider: PropTypes.string,

  // Serverless
  diskSizeGBLimit: PropTypes.number,
};

const REGION_CONFIG_SHAPE = {
  regionName: PropTypes.string.isRequired,
  cloudProvider: PropTypes.string.isRequired,
  autoScaling: PropTypes.shape(AUTO_SCALING_SHAPE).isRequired,
  priority: PropTypes.number.isRequired,
  electableSpecs: PropTypes.shape(HARDWARE_SPEC_SHAPE).isRequired,
  readOnlySpecs: PropTypes.shape(HARDWARE_SPEC_SHAPE).isRequired,
  analyticsSpecs: PropTypes.shape(HARDWARE_SPEC_SHAPE).isRequired,
  regionView: PropTypes.shape(REGION_OPTION_SHAPE),
};

const NEW_REPLICATION_SPEC_SHAPE = {
  id: PropTypes.string,
  zoneName: PropTypes.string,
  numShards: PropTypes.number,
  regionConfigs: PropTypes.arrayOf(PropTypes.shape(REGION_CONFIG_SHAPE)),
};

const INSTANCE_HARDWARE_REPLICATION_VIEW = {
  hostnames: PropTypes.arrayOf(
    PropTypes.shape({
      hostname: PropTypes.string,
      scheme: PropTypes.string,
    })
  ),
  instanceHardwareDisk: PropTypes.shape({
    instanceId: PropTypes.string,
    cloudProvider: PropTypes.string,
    lastDiskModifyDate: PropTypes.string,
    diskSizeGB: PropTypes.number,
  }),
  zoneName: PropTypes.string,
  region: PropTypes.shape({
    continent: PropTypes.string,
    key: PropTypes.string,
    location: PropTypes.string,
    name: PropTypes.string,
    provider: PropTypes.string,
  }),
  nodeType: PropTypes.string,
  regionPriority: PropTypes.number,
  replicationSpecId: PropTypes.string,
  publicIPSKUType: PropTypes.string,
};

type ZoneColorKey = 'CYAN' | 'DEEP_BLUE' | 'DEEP_ORANGE' | 'GREEN' | 'ORANGE' | 'PINK' | 'RED' | 'SKY_BLUE' | 'VIOLET';
const ZONE_COLORS: Record<
  ZoneColorKey,
  {
    r: number;
    g: number;
    b: number;
    alphaOptimal?: number;
    alphaExcellent?: number;
    alphaAcceptable?: number;
    zoneIndex: number;
    rgb: string;
  }
> = {
  CYAN: {
    alphaOptimal: 0.86,
    alphaExcellent: 0.51,
    alphaAcceptable: 0.25,
    r: 26,
    g: 255,
    b: 216,
    zoneIndex: 0,
    rgb: '', // programmatically overwritten below
  },
  DEEP_BLUE: {
    alphaExcellent: 0.51,
    alphaAcceptable: 0.2,
    r: 53,
    g: 125,
    b: 246,
    zoneIndex: 7,
    rgb: '', // programmatically overwritten below
  },
  DEEP_ORANGE: {
    alphaOptimal: 0.89,
    alphaExcellent: 0.59,
    alphaAcceptable: 0.29,
    r: 255,
    g: 104,
    b: 29,
    zoneIndex: 6,
    rgb: '', // programmatically overwritten below
  },
  GREEN: {
    alphaOptimal: 0.88,
    alphaExcellent: 0.5,
    alphaAcceptable: 0.21,
    r: 142,
    g: 255,
    b: 30,
    zoneIndex: 5,
    rgb: '', // programmatically overwritten below
  },
  ORANGE: {
    alphaOptimal: 0.92,
    alphaExcellent: 0.54,
    alphaAcceptable: 0.23,
    r: 247,
    g: 198,
    b: 74,
    zoneIndex: 2,
    rgb: '', // programmatically overwritten below
  },
  PINK: {
    alphaOptimal: 0.91,
    alphaExcellent: 0.55,
    alphaAcceptable: 0.25,
    r: 235,
    g: 61,
    b: 123,
    zoneIndex: 4,
    rgb: '', // programmatically overwritten below
  },
  RED: {
    alphaOptimal: 0.91,
    alphaExcellent: 0.62,
    alphaAcceptable: 0.25,
    r: 233,
    g: 59,
    b: 47,
    zoneIndex: 8,
    rgb: '', // programmatically overwritten below
  },
  SKY_BLUE: {
    alphaExcellent: 0.61,
    alphaAcceptable: 0.29,
    r: 28,
    g: 217,
    b: 255,
    zoneIndex: 3,
    rgb: '', // programmatically overwritten below
  },
  VIOLET: {
    alphaOptimal: 0.82,
    alphaAcceptable: 0.23,
    r: 199,
    g: 63,
    b: 246,
    zoneIndex: 1,
    rgb: '', // programmatically overwritten below
  },
};

_.each(ZONE_COLORS, (color) => {
  color.rgb = `rgb(${color.r}, ${color.g}, ${color.b})`;
});

const filteredColors = _.pick(ZONE_COLORS, (color) => !_.isUndefined(color.zoneIndex));
const filteredColorsWithKeys = _.mapObject(filteredColors, (color, key) => ({
  key,
  zoneIndex: color.zoneIndex,
}));
const sortedColors = _.sortBy(filteredColorsWithKeys, (color) => color.zoneIndex);
const ZONE_COLORS_ORDER = _.map(sortedColors, (color) => color.key);

const ISO_TO_ZONE_SCHEMA = {
  country: PropTypes.string.isRequired,
  isoCode: PropTypes.string.isRequired,
  latency: PropTypes.number.isRequired,
  generatedReplicationSpecId: PropTypes.string.isRequired,
  readableName: PropTypes.string.isRequired,
  replicationSpecId: PropTypes.string.isRequired,
  typeOneIsoCode: PropTypes.string.isRequired,
};
const ISO_TO_ZONE_SHAPE = PropTypes.shape(ISO_TO_ZONE_SCHEMA);

const ISO_TO_ZONE_MAP_SCHEMA = PropTypes.objectOf(ISO_TO_ZONE_SHAPE);

const PROVIDER_OPTIONS_SCHEMA = {
  '@provider': PropTypes.string.isRequired,
  diskSizes: PropTypes.objectOf(PropTypes.shape(DISK_SIZE_SHAPE)),
  instanceSizes: PropTypes.objectOf(PropTypes.shape(INSTANCE_SIZE_SHAPE)),
  minDevelopmentInstanceSize: PropTypes.string,
  minProductionInstanceSize: PropTypes.string,
  minShardingInstanceSize: PropTypes.string,
  mongodbMajorVersions: PropTypes.arrayOf(PropTypes.shape(MONGODB_MAJOR_VERSION_SHAPE)).isRequired,
  mongoDBMajorVersionsSubtext: PropTypes.objectOf(PropTypes.string),
  defaultCDMongoDBVersion: PropTypes.string,
  defaultCDMongoDBFCV: PropTypes.string,
  regions: PropTypes.arrayOf(PropTypes.shape(REGION_OPTION_SHAPE)).isRequired,
};

const CROSS_CLOUD_PROVIDER_OPTIONS_SCHEMA = {
  diskSizes: PropTypes.objectOf(PropTypes.shape(DISK_SIZE_SHAPE)),
  instanceSizes: PropTypes.objectOf(PropTypes.shape(INSTANCE_SIZE_SHAPE)),
  minDevelopmentInstanceSize: PropTypes.string,
  minProductionInstanceSize: PropTypes.string,
  minShardingInstanceSize: PropTypes.string,
  mongodbMajorVersions: PropTypes.arrayOf(PropTypes.shape(MONGODB_MAJOR_VERSION_SHAPE)).isRequired,
  mongoDBMajorVersionsSubtext: PropTypes.objectOf(PropTypes.string),
  defaultCDMongoDBVersion: PropTypes.string,
  defaultCDMongoDBFCV: PropTypes.string,
  regions: PropTypes.arrayOf(PropTypes.shape(REGION_OPTION_SHAPE)).isRequired,
};

const CLUSTER_FORM_VALUE_NAMES = [
  'clusterType',
  'replicationSpecList',
  'geoSharding',
  'diskSizeGB',
  'biConnector',
  'encryptionAtRestProvider',
  'mongoDBMajorVersion',
  'backupEnabled',
  'diskBackupEnabled',
  'pitEnabled',
  'tenantBackupEnabled',
  'serverlessBackupOptions',
  'name',
  'rootCertType',
  'versionReleaseSystem',
  'terminationProtectionEnabled',
  'acceptDataRisksAndForceReplicaSetReconfig',
] as const;

// Look for js/project/nds/types/ProcessArgs.ts
const PROCESS_ARGS_SCHEMA = {
  oplogSizeMB: PropTypes.number,
  noTableScan: PropTypes.bool,
  failIndexKeyTooLong: PropTypes.bool,
  minimumEnabledTlsProtocol: PropTypes.string,
  javascriptEnabled: PropTypes.bool,
  sampleSizeBIConnector: PropTypes.number,
  sampleRefreshIntervalBIConnector: PropTypes.number,
  defaultWriteConcern: PropTypes.string,
  defaultReadConcern: PropTypes.string,
};

// Look for js/project/nds/types/ClusterUsageStats.ts
const CLUSTER_USAGE_STATS_SCHEMA = {
  clusterMaxDiskUsageMB: PropTypes.number,
  currentOplogUsageSizeMB: PropTypes.number,
};

const ZONE_TERMS: Partial<Record<CloudProvider, string>> = {
  AWS: 'availability zone',
  AZURE: 'fault domains/availability zone',
  GCP: 'zone',
};

const PROVIDER_TEXT = {
  AZURE: 'Azure',
  AWS: 'AWS',
  GCP: 'GCP',
};

const NODE_TYPE = {
  ELECTABLE: 'ELECTABLE',
  READ_ONLY: 'READ_ONLY',
  ANALYTICS: 'ANALYTICS',
};

const CLUSTER_BUILDER_FILTER_INTERFACE = {
  isGlobalConfigurationVisible: PropTypes.bool.isRequired,
  isMultiRegionConfigurationVisible: PropTypes.bool.isRequired,
  advancedOptions: PropTypes.shape({
    shardingVisible: PropTypes.bool.isRequired,
    otherOptionsVisible: PropTypes.bool.isRequired,
  }).isRequired,
  providerTemplateKey: PropTypes.string.isRequired,
};

const HOURS_PER_MONTH = 720;

// these are the attributes that should be set when switching between cloud providers
// TODO<CLOUDP-127225>: maybe need to add here for serverless
const PROVIDER_ATTRIBUTES = {
  AWS: ['diskSizeGB', 'replicationSpecList', 'encryptionAtRestProvider', 'pitEnabled', 'mongoDBMajorVersion'],
  AZURE: ['diskSizeGB', 'replicationSpecList', 'encryptionAtRestProvider', 'pitEnabled', 'mongoDBMajorVersion'],
  GCP: ['diskSizeGB', 'replicationSpecList', 'encryptionAtRestProvider', 'pitEnabled', 'mongoDBMajorVersion'],
  FREE: [
    'mongoDBMajorVersion',
    'biConnector',
    'clusterType',
    'backupEnabled',
    'diskBackupEnabled',
    'encryptionAtRestProvider',
    'pitEnabled',
    'replicationSpecList',
  ],
} as const;

export {
  PROVIDER_OPTIONS_SCHEMA,
  CROSS_CLOUD_PROVIDER_OPTIONS_SCHEMA,
  CLUSTER_FORM_VALUE_NAMES,
  INSTANCE_SIZE_SHAPE,
  AUTO_SCALING_SHAPE,
  DISK_SIZE_SHAPE,
  BI_CONNECTOR_SHAPE,
  BI_CONNECTOR_COST_ESTIMATE_SHAPE,
  ServerlessBillingEstimateInterface,
  AVAILABLE_REGION_SHAPE,
  CLUSTER_TYPE,
  ISO_TO_ZONE_MAP_SCHEMA,
  ISO_TO_ZONE_SHAPE,
  NEW_REPLICATION_SPEC_SHAPE,
  ROOT_CERT_TYPE,
  ZONE_COLORS,
  ZONE_COLORS_ORDER,
  ENCRYPTION_AT_REST_PROVIDER,
  PrivateIpMode,
  INSTANCE_CLASS,
  PROCESS_ARGS_SCHEMA,
  CLUSTER_USAGE_STATS_SCHEMA,
  ZONE_TERMS,
  PROVIDER_TEXT,
  NODE_TYPE,
  BI_CONNECTOR_READ_PREFERENCE,
  CLUSTER_BUILDER_FILTER_INTERFACE,
  HOURS_PER_MONTH,
  PROVIDER_ATTRIBUTES,
  VOLUME_TYPE,
  INSTANCE_HARDWARE_REPLICATION_VIEW,
};
