import * as api from 'js/common/services/api';

// Action Types
const SET_CLUSTER_NAMES = 'nds/deletedClusterNamesWithActiveSnapshots/SET_CLUSTER_NAMES';

const initialState = {};

// Reducer
export default function deletedClusterNamesWithActiveSnapshotsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CLUSTER_NAMES: {
      return {
        ...state,
        deletedClusterNamesWithActiveSnapshots: action.payload,
      };
    }
    default:
      return state;
  }
}

const getDeletedClusterNamesWithActiveSnapshotsData = (state) => {
  return state.nds.deletedClusterNamesWithActiveSnapshots;
};

// Selectors
export const getDeletedClusterNamesWithActiveSnapshots = (state) => {
  return getDeletedClusterNamesWithActiveSnapshotsData(state).deletedClusterNamesWithActiveSnapshots || [];
};

// Action Creators
export const setDeletedClusterNamesWithActiveSnapshots = (payload) => ({
  type: SET_CLUSTER_NAMES,
  payload,
});

export const loadDeletedClusterNamesWithActiveSnapshots = (groupId) => (dispatch) => {
  return api.nds.clusterDescriptions.getDeletedClusterNamesWithActiveSnapshots(groupId).then((response) => {
    return dispatch(setDeletedClusterNamesWithActiveSnapshots(response));
  });
};
