import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as viewer from '@packages/redux/modules/viewer';

import { formatDateTime, formatDateTimeWithSeconds } from 'js/common/utils/dateFormatters';

function datePropToString(date?: string | number | Date | null): string | undefined {
  if (date == null) {
    return undefined;
  }
  if (typeof date === 'string') {
    return date;
  }
  if (typeof date === 'number') {
    return new Date(date).toISOString();
  }
  if (date instanceof Date) {
    // Should be an unnecessary check, but better not to make assumptions
    return date.toISOString();
  }
  // Fallback option in case our types missed something
  return undefined;
}

interface FormatDateTimeProps {
  className?: string;
  dateFormatCode?: string;
  timeFormatCode?: '24-hour' | 'am-pm' | '';
  timeZoneId?: string;
  isoString?: string | number | Date | null;
  includeSeconds?: boolean;
}

function FormatDateTime(props: FormatDateTimeProps) {
  // Note: default params aren't used if the value is an empty string. E.g. if timeZoneId = '', then
  // it will still be passed to formatter as '' instead of 'Etc/UTC'. The reason this still defaults
  // to UTC is because formatter uses '||' chaining
  const {
    className,
    dateFormatCode = 'us1',
    timeFormatCode = '',
    timeZoneId = 'Etc/UTC',
    isoString,
    includeSeconds = false,
  } = props;

  const formatter = includeSeconds ? formatDateTimeWithSeconds : formatDateTime;
  return (
    <time dateTime={datePropToString(isoString)} className={className}>
      {formatter(dateFormatCode, timeFormatCode, timeZoneId)(isoString)}
    </time>
  );
}

FormatDateTime.propTypes = {
  dateFormatCode: PropTypes.string,
  timeFormatCode: PropTypes.oneOf(['24-hour', 'am-pm', '']),
  timeZoneId: PropTypes.string,
  isoString: PropTypes.string,
  includeSeconds: PropTypes.bool,
  className: PropTypes.string,
} as any;

export default connect((state) => ({
  dateFormatCode: viewer.getDateFormatCode(state),
  timeFormatCode: viewer.getTimeFormatCode(state),
  timeZoneId: viewer.getTimeZoneId(state),
}))(FormatDateTime);

export { FormatDateTime };
