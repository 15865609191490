export interface CostEstimate {
  advancedSecurityDailyCents: number;
  advancedSecurityMonthlyCents: number;
  biConnectorMaxDailyCents: number;
  biConnectorMaxMonthlyCents: number;
  biConnectorSustainedDailyCents: number;
  dailyEstimateCents: number;
  enterpriseAuditingDailyCents: number;
  enterpriseAuditingMonthlyCents: number;
  monthlyEstimateCents: number;
  monthlyFeesEstimateCents: number;
  monthlyAnalyticsEstimateCents: number;
  monthlyBaseEstimateCents: number;
  snapshotBackupMonthlyCentsPerGB: number;
}

export enum PartnerType {
  AWS = 'AWS',
  AZURE = 'AZURE',
  GCP = 'GCP',
}

export enum PartnerLinkStatusCode {
  AVAILABLE = 'AVAILABLE',
  PENDING_CONFIRMATION = 'PENDING_CONFIRMATION',
  LINK_SUCCESS = 'LINK_SUCCESS',
  CUSTOMER_LINKED = 'CUSTOMER_LINKED',
  FAILED_PAYMENTS = 'FAILED_PAYMENTS',
  IS_LINKED_ORG = 'IS_LINKED_ORG',
  IS_PAYING_ORG = 'IS_PAYING_ORG',
  NOT_ATLAS = 'NOT_ATLAS',
  ORGANIZATION_LINKED = 'ORGANIZATION_LINKED',
  SUBSCRIPTION_INVALID_STATUS = 'SUBSCRIPTION_INVALID_STATUS',
  UNAUTHORIZED = 'UNAUTHORIZED',
  UNAVAILABLE = 'UNAVAILABLE',
}

export interface OrganizationLinkStatuses {
  linkable: boolean;
  linkStatusCode: PartnerLinkStatusCode;
  linkStatusDescription: string;
  userRoles: Array<string>;
  organization: {
    id: string;
    name: string;
  };
}
export interface PartnerCustomerLinkOrgStatus {
  partnerType: PartnerType;
  organizationLinkStatuses: Array<OrganizationLinkStatuses>;
}

export interface PartnerCustomerLinkOrgResult {
  success: boolean;
  linkStatusCode: PartnerLinkStatusCode;
  linkStatusDescription: string;
  partnerType: PartnerType;
  organizationId: string;
}

export interface PartnerSubscriptionStatusResult {
  success: boolean;
  subscriptionStatusCode: string;
  subscriptionStatusDescription: string;
  partnerType: string;
  organizationId: string;
}

export interface MarketplaceRegistrationData {
  partnerType: PartnerType;
}

export interface MarketplaceRegistrationCookie {
  partnerType: PartnerType;
}

export enum PartnerSubscriptionStatus {
  SUCCESS = 'success',
  PENDING = 'pending',
  FAILURE = 'failure',
  NOT_LINKING = 'not_linking',
}

export enum PartnerSubscriptionStatusCode {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  INACTIVE = 'INACTIVE',
  FAILED = 'FAILED',
  UNSUBSCRIBED = 'UNSUBSCRIBED',
  NOT_FOUND = 'NOT_FOUND',
}

export enum PaymentMethodStatus {
  ACTIVE = 'ACTIVE',
  PENDING_ACTIVATION = 'PENDING_ACTIVATION',
  INACTIVE = 'INACTIVE',
  DELETED = 'DELETED',
}

/**
 * This is only a partial representation of the fields of UsageSummary;
 * see `UsageSummary.java` for the rest and feel free to add the correct field types to this interface.
 */
export interface UsageSummary {
  subtotalCents: number;
  totalCents: number;
  backupTotalPriceCents: number;
  monitoring: Array<MonitoringSummary>;
  support: Array<SupportSummaryItem>;
}

interface MonitoringSummary {
  totalPriceCents: number;
  summaryGroup: string;
}

interface SupportSummaryItem {
  planType: string;
  totalPriceCents: number;
}

// Minor naming difference(s) + extra fields for modal compared to PaymentView
export interface MonthlyCommitPaymentView {
  id: string | null;
  orgId: string | null;
  paymentMethod: string | null; // payment method field
  created: string | null; // Date field
  status: string | null; // Status field
  netsuiteInvoiceUrl: string | null;
  netsuiteInvoiceInternalId: string | null;
  yayPayCustomerPortalUrl: string | null;
  salesforceOpportunityLink: string | null;
  currency: string | null; // netsuite info
  unitPrice: number | null;
  totalCents: number | null;
  taxTotalCents: number | null;
  amountDueCents: number | null;
  usageCents: number | null;
  invoicedCents: number | null; // akin to billedUsageCents
  activationCode: string | null;
  creditId: string | null;
  creditType: string | null;
  creditCreated: string | null; // extra info for modal
  creditStartDate: string | null;
  creditEndDate: string | null;
  amountCents: number | null;
  amountRemainingCents: number | null;
  monthlyCommitmentCents: number | null;
  totalBilledUsageCents: number | null;
  totalUsageCents: number | null;
  carryOverCents: number | null;
  isElastic: boolean | null;
}

export interface ProcessedMonthlyCommitPaymentView {
  id: string | null;
  orgId: string | null;
  paymentMethod: string | null; // payment method field
  created: string | null; // Date field
  status: string | null; // Status field
  netsuiteInvoiceUrl: string | null;
  netsuiteInvoiceInternalId: string | null;
  yayPayCustomerPortalUrl: string | null;
  salesforceOpportunityLink: string | null;
  currency: string | null; // netsuite info
  unitPrice: string;
  total: string;
  taxTotal: string;
  amountDue: string;
  usage: string;
  billedUsage: string; // akin to billedUsageCents
  activationCode: string | null;
  creditId: string | null;
  creditType: string | null;
  creditCreated: string | null; // extra info for modal
  creditStartDate: string | null;
  creditEndDate: string | null;
  detailsTotal: string;
  detailsPurchasedFrom: string;
  detailsTotalCreditsUsed: string;
  detailsTotalCreditsBilled: string;
  detailsCreditsRemaining: string;
  detailsCarryOverCredit: string;
  detailsCreditsUsed: string;
  detailsCreditsBilled: string;
  detailsMonthlyCommit: string;
  isElastic: boolean | null;
}

export interface PaymentView {
  id: string | null;
  orgId: string | null;
  paymentMethod: string | null; // payment method field
  paymentMethodId: string | null; // paymentMethodId string field
  created: string | null; // Date field
  status: string | null; // Status field
  netsuiteInvoiceInternalId: string | null;
  netsuiteInvoiceUrl: string | null;
  yayPayCustomerPortalUrl: string | null;
  salesforceOpportunityLink: string | null;
  currency: string | null;
  usageCents: number | null;
  billedUsageCents: number | null;
  amountDueCents: number | null;
  unitPrice: number | null;
  taxTotalCents: number | null;
  totalCents: number | null;
  activationCode: string | null;
  cardLast4: string | null;
  creditId: string | null;
  creditIds: Array<string> | null;
  creditType: string | null;
  cardType: string | null;
  isPulledForward: boolean | null;
  isElastic: boolean | null;
  lastFailedChargeAttempt: string | null;
  processingStartDate: string | null;
  amountBilledInLocalCurrency: number | null;
}

export interface ProcessedPaymentView {
  id: string | null;
  orgId: string | null;
  paymentMethod: string | null; // payment method field
  paymentMethodId: string | null; // paymentMethodId string field
  created: string | null; // Date field
  status: string | null; // Status field
  netsuiteInvoiceInternalId: string | null;
  netsuiteInvoiceUrl: string | null;
  yayPayCustomerPortalUrl: string | null;
  salesforceOpportunityLink: string | null;
  currency: string | null;
  usage: string;
  billedUsage: string;
  amountDue: string;
  unitPrice: string;
  taxTotal: string;
  total: string;
  activationCode: string | null;
  cardLast4: string | null;
  creditId: string | null;
  creditType: string | null;
  cardType: string | null;
  isPulledForward: boolean | null;
  isElastic: boolean | null;
  lastFailedChargeAttempt: string | null;
  processingStartDate: string | null;
  amountBilledInLocalCurrency: number | null;
}

export enum SelfServePaymentMethodType {
  PAYPAL = 'PAYPAL',
  CREDIT_CARD = 'CREDIT_CARD',
  AWS_MARKETPLACE_SELF_SERVE = 'AWS_MARKETPLACE_SELF_SERVE',
  GCP_MARKETPLACE_SELF_SERVE = 'GCP_MARKETPLACE_SELF_SERVE',
  AZURE_MARKETPLACE_SELF_SERVE = 'AZURE_MARKETPLACE_SELF_SERVE',
}

export interface PaymentMethod {
  id: string;
  type: string;
  created: string;
  status: PaymentMethodStatus;
}

export interface SelfServePaymentMethod extends PaymentMethod {
  type: SelfServePaymentMethodType;
  status: PaymentMethodStatus;
  billingAddress: {
    addrLine1: string;
    addrLine2: string;
    city: string;
    state: string;
    zip: string;
    country: string;
  } | null;
}

export interface PayPal extends SelfServePaymentMethod {
  type: SelfServePaymentMethodType.PAYPAL;
  payPalEmailAddress: string;
}

export interface CreditCard extends SelfServePaymentMethod {
  type: SelfServePaymentMethodType.CREDIT_CARD;
  billingAccount: string;
  cardLast4: string;
  deactivated: string | null;
  cardExpiration: string;
  cardType: string;
  created: string;
  nameOnCard: string;
  orgId: string;
  stripeCardId?: string;
  stripeCustomerId?: string;
}

export type TypedSelfServePaymentMethod<MethodType extends SelfServePaymentMethodType> =
  MethodType extends SelfServePaymentMethodType.PAYPAL
    ? PayPal
    : MethodType extends SelfServePaymentMethodType.CREDIT_CARD
    ? CreditCard
    : SelfServePaymentMethod & { type: MethodType };

export interface PartnerSelfServe {
  id: string;
  type: SelfServePaymentMethodType;
  status: PaymentMethodStatus;
}

export interface CompanyAddress {
  companyName?: string | null;
  id?: string | null;
  orgId?: string | null;
  created?: string | null;
  addrLine1: string;
  addrLine2?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  country?: string | null;
  vatNumber?: string | null;
}

export interface Invoice {
  id: string;
  startDate: Date;
  endDate: Date;
  status: string;
  amountBilledCents: bigint;
  subtotalCents: bigint;
  type: string;
  orgId: string;
  orgName: string;
}

export interface PayingOrg {
  payingOrgId: string;
  linkedOrgIds: Array<string>;
}

export interface LinkableOrg {
  orgId: string;
  orgName: string;
  planType: string;
  isPayingOrg: boolean;
  selected: boolean;
}

export interface LinkedOrg {
  orgId: string;
  orgName: string;
  planType: string;
}

export interface ConsultingDepth {
  name: string;
  friendlyName: string;
  units: number;
  duration: string;
  services: Array<string>;
}

export interface Payment {
  id: string;
  amountBilledCents: number;
  amountPaidCents: number;
  paymentMethodId: string;
}

export interface LineItem {
  appId: null | string;
  appName: null | string;
  awsRegionName: null | string;
  azureRegionName: null | string;
  clusterName: null | string;
  clusterUniqueId: null | string;
  configServer: null | string;
  creditId: null | string;
  discountCents: number;
  discountId: null | string;
  endDate: string;
  gcpBase: null | string;
  gcpDest: null | string;
  gcpRegionName: null | string;
  gcpSource: null | string;
  groupId: string;
  id: string;
  invoiceId: string;
  note: null | string;
  percentDiscount: number;
  quantity: number;
  replicaSet: null | string;
  sku: string;
  startDate: string;
  totalPriceCents: number;
  unitPriceDollars: number;
  description: null | string;
  usageOrStartDate: string;
}

export type StripeError = stripe.Error;

export interface BillingMetadata {
  skuInfo: {};
  skuUnitsSingular: {};
  skuUnitsPlural: {};
  summaryGroups: {};
  awsRegionNames: {};
  gcpRegionNames: {};
  azureRegionNames: {};
  planTypes: { [identifier: string]: string };
  flexConsultingDepths: {};
}

export interface Plan {
  startDate?: string | null;
  endDate?: string | null;
  entitlementsDrawdownMinimumCents: number | null;
  entitlementsDrawdownPercentage: number | null;
  hasAtlasSubscriptionUpliftOrMinimum: boolean;
  hasTieredSubscriptionPricing: boolean;
  supportSlaMinutes: number;
  planType: string;
  groupType?: string;
  entitlementsDescription?: string;
  prepaidPlans: Array<PrepaidPlan>;
  freeUntil?: string | null;
  minimumEnrollmentWindow?: number | null;
  elasticInvoicing: boolean;
  elasticInvoicingActivationDate: string | null;
  gcpMarketplace?: {
    entitlementId: string;
    accountId: string;
  };
}

export interface PrepaidPlan {
  activationCode: string;
  startDate: string;
  endDate: string;
  skus?: Array<string>;
  type: string;
  creditId?: string;
}

export interface Group {
  name: string;
  groupType: string;
  numBillableServers: number;
  numCluster: number;
  dataSizeBackedUpBytes: number;
}

export interface Discount {
  startDate: string;
  endDate: string;
  code: string;
  description: string;
  percentDiscount: number;
  elasticInvoicing?: boolean; //this is necessary because discounts are used in credit table, and in credit table we check for elasticInvoicing
}

export interface OrgPreferences {
  shouldSendInvoiceOnlyToBillingEmail?: boolean;
}

/**
 * See PlanBillingDetailsView.java
 */
export interface ReactivationDetails {
  estimatedCharges: string | null;
  planStartDate: string | null;
}

export interface PlanBillingDetails {
  reactivationDetails: ReactivationDetails;
}

export enum SelfServeProductType {
  ATLAS_DEVELOPER = 'ATLAS_DEVELOPER',
  NDS_SELF_SERVE_PRO = 'NDS_SELF_SERVE_PRO',
}

export interface SelfServeProduct {
  type: string;
  planType: string;
  minimumEnrollmentPeriodDays: number | null;
  freeTrialDays: number | null;
  entitlementsDrawdownMinimumCents: number;
  entitlementsDrawdownPercentage: number;
}

export interface Credit {
  id: string;
  orgId: string;
  created: string;
  activationCode?: string;
  reason?: string;
  issuer?: string;
  code?: string;
  amountCents: number;
  amountRemainingCents: number;
  totalBilledCents?: number;
  note: string;
  type: string;
  couponId?: string | null;
  startDate: string;
  endDate: string;
  orgName?: string;
  planType?: string;
  isValidPaymentMethod?: boolean;
  hourlyBillingLimitCents?: number;
  monthlyMinimum?: number;
  isValidToday: boolean;
  elasticInvoicing?: boolean;
  salesforceOpportunityLineItemId?: string;
  salesforceOpportunityLink?: string;
  isEligibleForTieredPricing?: boolean | false;
}

export enum BillingStatus {
  BILLING = 'BILLING',
  NOT_BILLING = 'NOT_BILLING',
}

export interface BraintreeCustomer {
  orgId: string;
  paymentMethodToken: string;
  payPalEmailAddress: string;
}

export enum RefundType {
  FULL = 'FULL',
  PARTIAL = 'PARTIAL',
  FULL_TAX = 'FULL_TAX',
}

export interface CostExplorerFilterOptions {
  orgs: ReadonlyArray<CostExplorerOrgFilterOptions>;
  services: ReadonlyArray<ServiceDescription>;
  minStartDate: Date;
  maxStartDate: Date;
}

export interface CostExplorerOrgFilterOptions {
  id: string;
  name?: string;
  projects?: ReadonlyArray<CostExplorerProjectFilterOptions>;
}

export interface CostExplorerProjectFilterOptions {
  id: string;
  name?: string;
  clusters?: ReadonlyArray<CostExplorerClusterFilterOptions>;
}

export interface CostExplorerClusterFilterOptions {
  id: string;
  name?: string;
}

export interface ServiceDescription {
  displayName: string;
  parent?: string;
  displayOrder: number;
}

export interface CostExplorerFilterResults {
  usageDetails: ReadonlyArray<CostExplorerFilterResultsUsageDetails>;
}

export interface CostExplorerFilterResultsUsageDetails {
  usageDate: string;
  organizationName?: string;
  organizationId: string;
  projectName?: string;
  projectId?: string;
  clusterName?: string;
  clusterId?: string;
  service?: string;
  usageAmount: number;
}

export interface CostExplorerFilterSelections {
  organizations: ReadonlyArray<string>;
  projects: ReadonlyArray<string>;
  clusters: ReadonlyArray<string>;
  services: ReadonlyArray<string>;
  startDate?: Date;
  endDate?: Date;
}

export interface CostExplorerQueryResult {
  results: CostExplorerFilterResults;
  selections: CostExplorerFilterSelections;
}
