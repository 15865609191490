import { Fragment, useState } from 'react';

import { css } from '@emotion/react';
import Button from '@leafygreen-ui/button';
import Icon from '@leafygreen-ui/icon';
import { Menu, MenuItem, MenuSeparator } from '@leafygreen-ui/menu';
import { Body } from '@leafygreen-ui/typography';

import { FactorType, OktaMfaFactor } from '@packages/types/accountMultiFactorAuth';
import { Search } from '@packages/types/auth/search';

import VerifyAuthPushFactor, {
  VerifyAuthPushFactorResponse,
} from '@packages/components/MultiFactorAuth/VerifyAuthPushFactor';
import VerifyEmailFactor from '@packages/components/MultiFactorAuth/VerifyEmailFactor';
import VerifyPasscodeInput, {
  VerifyPasscodeInputResponse,
} from '@packages/components/MultiFactorAuth/VerifyPasscodeInput';
import VerifyPushFactor from '@packages/components/MultiFactorAuth/VerifyPushFactor';
// import VerifyVoiceFactor from '@packages/components/MultiFactorAuth/VerifyVoiceFactor';
import VerifySmsFactor from '@packages/components/MultiFactorAuth/VerifySmsFactor';
import VerifyWebAuthnFactor, {
  VerifyWebAuthnFactorResponse,
} from '@packages/components/MultiFactorAuth/VerifyWebAuthnFactor';

// common styles
import { baseStyle, bodyParagraphStyles } from 'js/common/styles/multiFactorAuth';
import { factorMetadata } from 'js/common/utils/multiFactorAuthUtils';

const dropdownStyles = {
  menu: css({
    width: '210px',
  }),
  vercelMenu: css({
    width: '350px',
  }),
  button: css({
    zIndex: 5,
    width: '210px',
  }),
  vercelButton: css({
    zIndex: 5,
    width: '350px',
  }),
  buttonText: css({
    fontWeight: 'bold',
    display: 'inline-flex',
    justifyContent: 'space-between',
    width: '100%',
  }),
  menuItem: css({
    padding: '20px 32px',
  }),
};

export type VerifySelectedFactorResponse =
  | VerifyPasscodeInputResponse
  | VerifyWebAuthnFactorResponse
  | VerifyAuthPushFactorResponse;

export interface VerifySelectedFactorProps {
  availableFactors: Array<OktaMfaFactor>;
  clientState?: Search;
  onSuccess: (response?: VerifySelectedFactorResponse | string) => void;
  isDeleteFlow?: boolean;
  isVercelIntegration?: boolean;
  isEmailChangeFlow?: boolean;
}

export default function VerifySelectedFactor({
  availableFactors,
  clientState = {},
  onSuccess,
  isEmailChangeFlow,
  isDeleteFlow,
}: VerifySelectedFactorProps) {
  const [selectedFactor, setSelectedFactor] = useState<OktaMfaFactor>(availableFactors[0]);
  const { isVercelIntegration, stateToken } = clientState;
  const isAuthFlow = !!stateToken;

  const renderFactorVerification = () => {
    switch (selectedFactor.factorType) {
      case FactorType.Email: {
        return (
          <VerifyEmailFactor
            emailFactor={selectedFactor}
            clientState={clientState}
            onSuccess={onSuccess}
            isDeleteFlow={isDeleteFlow}
            isEmailChangeFlow={isEmailChangeFlow}
          />
        );
      }
      case FactorType.Push: {
        return isAuthFlow ? (
          <VerifyAuthPushFactor pushFactor={selectedFactor} clientState={clientState} onSuccess={onSuccess} />
        ) : (
          <VerifyPushFactor
            pushFactor={selectedFactor}
            onSuccess={onSuccess}
            isDeleteFlow={isDeleteFlow}
            isEmailChangeFlow={isEmailChangeFlow}
          />
        );
      }
      case FactorType.TokenSoftwareTotp: {
        return (
          <>
            <Body css={bodyParagraphStyles}>
              An authentication code has been sent to your device. Enter the code to continue and be redirected.
            </Body>
            <VerifyPasscodeInput
              inputType="code"
              factor={selectedFactor}
              clientState={clientState}
              onSuccess={onSuccess}
              isEmailChangeFlow={isEmailChangeFlow}
            />
          </>
        );
      }
      case FactorType.Call: {
        return (
          <Body css={bodyParagraphStyles}>
            Voice Factor is currently disabled, please authenticate with another factor.
          </Body>
          // return <VerifyVoiceFactor voiceFactor={selectedFactor} clientState={clientState} onSuccess={onSuccess} />;
        );
      }
      case FactorType.Sms: {
        return (
          <VerifySmsFactor
            smsFactor={selectedFactor}
            clientState={clientState}
            onSuccess={onSuccess}
            isEmailChangeFlow={isEmailChangeFlow}
            isDeleteFlow={isDeleteFlow}
          />
        );
      }
      case FactorType.WebAuthn: {
        return (
          <VerifyWebAuthnFactor
            webAuthnFactor={selectedFactor}
            clientState={clientState}
            onSuccess={onSuccess}
            isEmailChangeFlow={isEmailChangeFlow}
            isDeleteFlow={isDeleteFlow}
          />
        );
      }
      default: {
        // do nothing
        return null;
      }
    }
  };

  return (
    <>
      <div css={baseStyle.text}>
        <Body css={bodyParagraphStyles}>
          <b>Authentication Method</b>
        </Body>
        <MenuDropdown
          isVercelIntegration={isVercelIntegration}
          options={availableFactors}
          setSelected={setSelectedFactor}
          selectedFactor={selectedFactor}
        />
      </div>
      {renderFactorVerification()}
    </>
  );
}

/*
 React re-renders the entire component on state change. This is an issue with
 a controlled menu dropdown, as opening/closing the dropdown will update the
 isDropdownOpen state and cause the component to re-render and reset state
 in the nested factor verification components. The solution is to break out the
 dropdown into its own component.
*/
interface MenuDropdownProps {
  options: Array<OktaMfaFactor>;
  setSelected: (factor: OktaMfaFactor) => void;
  selectedFactor: OktaMfaFactor;
  isVercelIntegration?: boolean;
}

export function MenuDropdown({ isVercelIntegration, options, setSelected, selectedFactor }: MenuDropdownProps) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const getDisplayName = (factor: OktaMfaFactor) => {
    return factorMetadata[factor.factorType].name;
  };

  return (
    <Menu
      align="bottom"
      justify="start"
      trigger={
        <Button css={isVercelIntegration ? dropdownStyles.vercelButton : dropdownStyles.button}>
          <div css={dropdownStyles.buttonText}>
            {getDisplayName(selectedFactor)}
            <Icon glyph="CaretDown" />
          </div>
        </Button>
      }
      css={isVercelIntegration ? dropdownStyles.vercelMenu : dropdownStyles.menu}
      setOpen={setIsDropdownOpen}
      open={isDropdownOpen}
    >
      {options.map((factor) => (
        <Fragment key={factor.id}>
          <MenuItem
            css={dropdownStyles.menuItem}
            active={factor.id === selectedFactor.id}
            onClick={() => {
              setSelected(factor);
              setIsDropdownOpen(false);
            }}
            data-testid={`menu-item-${factor.id}`}
          >
            {getDisplayName(factor)}
          </MenuItem>
          <MenuSeparator />
        </Fragment>
      ))}
    </Menu>
  );
}
