import { OktaAuth, SessionObject } from '@okta/okta-auth-js';

interface AuthClientOptions {
  oktaRedirectBase: string;
  oktaUrl: string;
}

let authClient: OktaAuth;

const checkInitialized = () => {
  if (!authClient) {
    throw Error('The auth client must be initialized. Call "initializeAuthClient" to auth client details');
  }
};

export const initializeAuthClient = ({ oktaRedirectBase, oktaUrl }: AuthClientOptions) => {
  if (authClient) {
    throw Error('The auth client has already been initialized');
  }

  authClient = new OktaAuth({
    issuer: `${oktaUrl}/oauth2/default`,
    redirectUri: `${oktaRedirectBase}/user/oidc/callback`,
  });
};

export const endOktaSession = () => {
  return authClient.closeSession();
};

export function oktaSessionExists(): Promise<boolean> {
  checkInitialized();

  return authClient.session.exists();
}

export function getCurrentOktaSession(): Promise<SessionObject> {
  checkInitialized();

  return authClient.session.get();
}
