import { produce } from 'immer';

import { PersonalizationWizardResponse } from '@packages/types/setup/personalization';

import * as api from 'js/common/services/api';
import { MINUTES_IN_HOUR, SECONDS_IN_MINUTE } from 'js/common/utils/timeUtils';

import * as backboneReduxSync from '../backboneReduxSync';

const {
  handleSyncFromBackbone,
  handleSyncChangesFromBackbone,
  actionTypes: { SYNC_CHANGES_FROM_BACKBONE, SYNC_FROM_BACKBONE },
} = backboneReduxSync;

const RESET = 'settings/RESET';
const SET_AB_TEST_ASSIGNMENTS = 'settings/SET_AB_TEST_ASSIGNMENTS';
const SET_ORG_CONTROLLED_FEATURE_FLAGS = 'settings/SET_ORG_CONTROLLED_FEATURE_FLAGS';
const TOGGLE_ORG_ENABLED_FEATURE_FLAGS = 'settings/TOGGLE_ORG_ENABLED_FEATURE_FLAGS';
const SET_GROUP_CONTROLLED_FEATURE_FLAGS = 'settings/SET_GROUP_CONTROLLED_FEATURE_FLAGS';
const TOGGLE_GROUP_ENABLED_FEATURE_FLAGS = 'settings/TOGGLE_GROUP_CONTROLLED_FEATURE_FLAGS';
const SET_ATLAS_OPERATOR_FEATURE_FLAG_MAP = 'settings/SET_ATLAS_OPERATOR_FEATURE_FLAG_MAP';
const TOGGLE_ATLAS_OPERATOR_ENABLED_FEATURE_FLAG = 'settings/TOGGLE_ATLAS_OPERATOR_ENABLED_FEATURE_FLAG';
const TOGGLE_EXTENDED_STORAGE_SIZES_ENABLED = 'settings/TOGGLE_EXTENDED_STORAGE_SIZES_ENABLED';
const SET_PERSONALIZATION_WIZARD_RESPONSE = 'settings/SET_PERSONALIZATION_WIZARD_RESPONSE';
const SET_BAAS_CENTRAL_URL = 'settings/SET_BAAS_CENTRAL_URL';

const initialState = {
  appEnv: 'PROD',
  accountCentralUrl: '',
  onPrem: false,
  onPremVersion: '',
  userRegistrationEnabled: true,
  siteName: '',
  multiFactorAuthResetAllowed: false,
  docsUrl: '',
  docsSearchUrl: '',
  reCaptchaEnabledRegistration: false,
  reCaptchaEnabledLogin: false,
  reCaptchaPublic: '',
  hasUsers: true,
  analyticsEnabled: true,
  selfServePaymentsEnabled: true,
  emailVerificationEnabled: false,
  isLdap: false,
  isSaml: false,
  featureFlags: {
    atlasOperatorFeatureFlagMap: {},
    groupEnabledFeatureFlags: [] as Array<string>,
    orgEnabledFeatureFlags: [] as Array<string>,
    groupControlledFeatureFlags: [] as Array<string>,
    orgControlledFeatureFlags: [] as Array<string>,
  },
  abTestAssignments: [],
  oktaEnabled: false,
  oktaAuthRedirectBase: '',
  oktaBaseUrl: '',
  isMongoDbUsageEnabled: false,
  isGoogleAuthEnabled: false,
  isGithubAuthEnabled: false,
  isPersonalApiKeysPageVisible: true,
  isCloudManagerEnabled: true,
  accountMultiFactorAuthEnabled: false,
  mongoNavEnv: '',
  mmsSessionMode: 'FIXED',
  mmsIdleSessionTimeoutSeconds: 12 * MINUTES_IN_HOUR * SECONDS_IN_MINUTE,
  mmsPublicApiWhitelistEnabled: true,
  mmsKubernetesEnabled: false,
  mmsPushLiveMigrationsEnabled: false,
  mmsPushLiveMigrationPrivateLinkEnabled: false,
  isProjectLinkedToVercel: false,
  vercelLinkedClusters: [],
  permittedDatadogRegions: [],
  permittedPagerDutyRegions: [],
  extendedStorageSizesEnabled: false,
  personalizationWizardResponse: null,
  baasCentralUrl: '',
  isMTMCompactionEnabled: false,
  isOidcIdpEnabled: false,
  isOidcIdpEnabledNonAtlas: false,
};

// Using typeof for a quick approximation, feel free to flesh this type out with the correct types.
export type SettingsState = typeof initialState;

// Add more fields as we need them.
const backboneToReduxKeyMapping = {
  // Mappings for Project's Settings model.
  CENTRAL_URL: 'centralUrl',
  ACCOUNT_CENTRAL_URL: 'accountCentralUrl',
  DOCS_URL: 'docsUrl',
  DOCS_SEARCH_URL: 'docsSearchUrl',
  IS_LDAP: 'isLdap',
  IS_SAML: 'isSaml',
  IS_MONGODB_USAGE_ENABLED: 'isMongoDbUsageEnabled',
  OKTA_ENABLED: 'oktaEnabled',
  OKTA_AUTH_REDIRECT_BASE: 'oktaAuthRedirectBase',
  OKTA_BASE_URL: 'oktaBaseUrl',
  GIT_BRANCH: 'gitBranch',
  GIT_SHORT_VERSION: 'gitShortVersion',
  PLAN_TYPE: 'planType',
  EFFECTIVE_PLAN_TYPE: 'effectivePlanType',
  GROUP_TYPE: 'groupType',
  ON_PREM: 'onPrem',
  ON_PREM_VERSION: 'onPremVersion',
  AWS_GRAVITON_MINIMUM_MONGODB_VERSION: 'awsGravitonMinimumMongoDBVersion',
  SITE_FULL_NAME: 'siteFullName',
  SITE_NAME: 'siteName',
  PUBLISHABLE_KEY_INC: 'publishableKeyInc',
  PUBLISHABLE_KEY_LTD: 'publishableKeyLtd',
  ENABLED_FEATURE_FLAGS: 'featureFlags',
  CURRENT_IPV4_ADDR: 'currentIpv4Address',
  GROUP_HAS_ACTIVE_BACKUPS: 'hasActiveBackups',
  CAN_USE_AUTOMATION_LOG_COLLECTION: 'canUseAutomationLogCollection',
  LOG_COLLECTION_MAX_DISK_SPACE_MB: 'logCollectionMaxDiskSpaceMB',
  AB_TEST_ASSIGNMENTS: 'abTestAssignments',
  SLACK_OAUTH2_CLIENT_ID: 'slackOAuth2ClientId',
  PAGERDUTY_APP_ID: 'pagerDutyAppId',
  PERMITTED_DATADOG_REGIONS: 'permittedDatadogRegions',
  PERMITTED_PAGER_DUTY_REGIONS: 'permittedPagerDutyRegions',
  MULTI_FACTOR_AUTH_LEVEL: 'multiFactorAuthLevel',
  SUPPORTS_MULTI_FACTOR_AUTH: 'supportsMultiFactorAuth',
  ACCOUNT_MULTI_FACTOR_AUTH_ENABLED: 'accountMultiFactorAuthEnabled',
  BAAS_URL: 'baasCentralUrl',
  CHARTS_URL: 'chartsCentralUrl',
  UNIVERSITY_URL: 'universityCentralUrl',
  SEGMENT_DEFAULT_USER_ID: 'segmentDefaultUserId',
  ANALYTICS_ENABLED: 'analyticsEnabled',
  SELF_SERVE_PAYMENTS_ENABLED: 'selfServePaymentsEnabled',
  EMAIL_VERIFICATION_ENABLED: 'emailVerificationEnabled',
  MONGO_NAV_ENV: 'mongoNavEnv',
  MMS_SESSION_MODE: 'mmsSessionMode',
  MMS_IDLE_SESSION_TIME_SECONDS: 'mmsIdleSessionTimeoutSeconds',
  MMS_PUBLIC_API_WHITELIST_ENABLED: 'mmsPublicApiWhitelistEnabled',
  MMS_KUBERNETES_ENABLED: 'mmsKubernetesEnabled',
  IS_NDS_GOV_ENABLED: 'isNdsGovEnabled',
  NDS_SERVERLESS_FEATURE_ENABLED: 'isNdsServerlessEnabled',
  EXTENDED_STORAGE_SIZES_ENABLED: 'extendedStorageSizesEnabled',
  NDS_SERVERLESS_ALLOW_SETUP_SERVERLESS_CREATE_TEST_INSTANCE: 'setupServerlessCreateTestInstanceAllowed',
  GROUP_ENABLED_FEATURE_FLAGS: 'featureFlags.groupEnabledFeatureFlags',
  ATLAS_CLUSTER_WEBSOCKET_CONNECTION_BASE_URL: 'atlasClusterWebsocketConnectionBaseUrl',
  MMS_PUSH_LIVE_MIGRATIONS_ENABLED: 'mmsPushLiveMigrationsEnabled',
  MMS_PUSH_LIVE_MIGRATIONS_PRIVATE_LINK_ENABLED: 'mmsPushLiveMigrationPrivateLinkEnabled',
  IS_PROJECT_LINKED_TO_VERCEL: 'isProjectLinkedToVercel',
  VERCEL_LINKED_CLUSTERS: 'vercelLinkedClusters',
  PERSONALIZATION_WIZARD_RESPONSE: 'personalizationWizardResponse',
  DATA_REGION: 'dataRegion',
  REGIONAL_CONNECTION_BASE_URL: 'regionalConnectionBaseUrl',
  ATLAS_REGIONAL_DATA_ENABLED: 'regionalDataEnabled',
  OIDC_IDP_ENABLED_NON_ATLAS: 'isOidcIdpEnabledNonAtlas',
  DARK_MODE_PREVIEW_ENABLED: 'isDarkModePreviewEnabled',

  // Mappings for Admin's AdminSettings model.
  onPrem: 'onPrem',
  emailVerificationEnabled: 'emailVerificationEnabled',
  mmsIdleSessionTimeoutSeconds: 'mmsIdleSessionTimeoutSeconds',
  mmsSessionMode: 'mmsSessionMode',
  isLdap: 'isLdap',
  isSaml: 'isSaml',
  isMongoDbUsageEnabled: 'isMongoDbUsageEnabled',
  userServiceType: 'userServiceType', // DB, Okta, LDAP, or SAML
  onPremVersion: 'onPremVersion',
  multiFactorAuthLevel: 'multiFactorAuthLevel',
  siteFullName: 'siteFullName',
  currentIpv4Address: 'currentIpv4Address',
  telecomSvcEnabled: 'telecomSvcEnabled',
  ndsServerlessOrganizationId: 'ndsServerlessOrganizationId',
  isNdsServerlessEnabled: 'isNdsServerlessEnabled',
  atlasClusterWebsocketConnectionBaseUrl: 'atlasClusterWebsocketConnectionBaseUrl',
  dataRegion: 'dataRegion',
  regionalConnectionBaseUrl: 'regionalConnectionBaseUrl',
  setupServerlessCreateTestInstanceAllowed: 'setupServerlessCreateTestInstanceAllowed',
  isMTMCompactionEnabled: 'isMTMCompactionEnabled',
};

export default function settingsReducer(state = initialState, action) {
  return produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case RESET:
        return payload;
      case SET_AB_TEST_ASSIGNMENTS:
        draft.abTestAssignments = payload;
        break;
      case SYNC_FROM_BACKBONE:
        return handleSyncFromBackbone(draft, action, backboneToReduxKeyMapping);
      case SYNC_CHANGES_FROM_BACKBONE:
        return handleSyncChangesFromBackbone(draft, action, backboneToReduxKeyMapping);
      case SET_ATLAS_OPERATOR_FEATURE_FLAG_MAP:
        draft.featureFlags.atlasOperatorFeatureFlagMap = payload;
        break;
      case SET_GROUP_CONTROLLED_FEATURE_FLAGS:
        draft.featureFlags.groupControlledFeatureFlags = payload;
        break;
      case SET_ORG_CONTROLLED_FEATURE_FLAGS:
        draft.featureFlags.orgControlledFeatureFlags = payload;
        break;
      case TOGGLE_ATLAS_OPERATOR_ENABLED_FEATURE_FLAG:
        draft.featureFlags.atlasOperatorFeatureFlagMap = {
          ...state.featureFlags.atlasOperatorFeatureFlagMap,
          [payload.featureFlag]: payload.enabled,
        };
        break;
      case TOGGLE_GROUP_ENABLED_FEATURE_FLAGS:
        if (payload.enabled) {
          draft.featureFlags.groupEnabledFeatureFlags = draft.featureFlags.groupEnabledFeatureFlags.filter(
            (enabledFlag) => enabledFlag !== payload.featureFlag
          );
          break;
        }
        draft.featureFlags.groupEnabledFeatureFlags.push(payload.featureFlag);
        break;
      case TOGGLE_ORG_ENABLED_FEATURE_FLAGS:
        if (payload.enabled) {
          draft.featureFlags.orgEnabledFeatureFlags = draft.featureFlags.orgEnabledFeatureFlags.filter(
            (enabledFlag) => enabledFlag !== payload.featureFlag
          );
          break;
        }
        draft.featureFlags.orgEnabledFeatureFlags.push(payload.featureFlag);
        break;
      case TOGGLE_EXTENDED_STORAGE_SIZES_ENABLED:
        draft.extendedStorageSizesEnabled = payload.enabled;
        break;
      case SET_PERSONALIZATION_WIZARD_RESPONSE:
        draft.personalizationWizardResponse = payload;
        break;
      case SET_BAAS_CENTRAL_URL:
        draft.baasCentralUrl = payload;
        break;
      default:
        return draft;
    }
  });
}

// Selectors
export const getDocsUrl = (state) => state.settings.docsUrl;
export const getDocsSearchUrl = (state) => state.settings.docsSearchUrl;
export const getOnPremVersion = (state) => state.settings.onPremVersion;
export const getGitBranch = (state) => state.settings.gitBranch;
export const getGitShortVersion = (state) => state.settings.gitShortVersion;
export const getTelecomSvcEnabled = (state) => state.settings.telecomSvcEnabled;
export const isLdap = (state) => state.settings.isLdap;
export const isSaml = (state) => state.settings.isSaml;
export const isMultiFactorAuthEnabled = (state) => state.settings.multiFactorAuthLevel !== 'OFF';
export const isMultiFactorAuthLevelRequired = (state) => state.settings.multiFactorAuthLevel === 'REQUIRED';
export const supportsMultiFactorAuth = (state) => state.settings.supportsMultiFactorAuth;
export const isOnPrem = (state) => state.settings.onPrem;
export const appEnv = (state) => state.settings.appEnv;
export const getUserServiceType = (state) => state.settings.userServiceType;
export const getCentralUrl = (state) => state.settings.centralUrl;
export const getAccountCentralUrl = (state) => state.settings.accountCentralUrl;
export const getSiteName = (state) => state.settings.siteName;
export const getSiteFullName = (state) => state.settings.siteFullName;
export const getSlackOAuth2ClientId = (state) => state.settings.slackOAuth2ClientId;
export const getPagerDutyAppId = (state) => state.settings.pagerDutyAppId;
export const getPermittedDatadogRegions = (state) => state.settings.permittedDatadogRegions;
export const getPermittedPagerDutyRegions = (state) => state.settings.permittedPagerDutyRegions;
export const getBaasCentralUrl = (state) => state.settings.baasCentralUrl;
export const getChartsCentralUrl = (state) => state.settings.chartsCentralUrl;
export const getUniversityCentralUrl = (state) => state.settings.universityCentralUrl;
export const isOktaEnabled = (state) => state.settings.oktaEnabled;
export const getOktaRedirectBase = (state) => state.settings.oktaAuthRedirectBase;
export const getOktaBaseUrl = (state) => state.settings.oktaBaseUrl;
export const isMongoDbUsageEnabled = (state) => state.settings.isMongoDbUsageEnabled;
export const getDefaultLicense = (state) => state.settings.defaultLicense;
export const getSegmentDefaultUserId = (state) => state.settings.segmentDefaultUserId;
export const getAnalyticsEnabled = (state) => state.settings.analyticsEnabled;
export const isCloudManagerEnabled = (state) => state.settings.isCloudManagerEnabled;
export const accountMultiFactorAuthEnabled = (state) => state.settings.accountMultiFactorAuthEnabled;
export const getMongoNavEnv = (state) => state.settings.mongoNavEnv;
export const getMmsSessionMode = (state) => state.settings.mmsSessionMode;
export const getMmsIdleSessionTimeoutSeconds = (state) => state.settings.mmsIdleSessionTimeoutSeconds;
export const getMmsPublicApiWhitelistEnabled = (state) => state.settings.mmsPublicApiWhitelistEnabled;
export const getPersonalizationWizardResponse = (state) => state.settings.personalizationWizardResponse;

export const getPlanType = (state) => state.settings.planType;
export const getEffectivePlanType = (state) => state.settings.effectivePlanType;
export const getGroupType = (state) => state.settings.groupType;
export const isNDSPlan = (state) => getGroupType(state) === 'NDS';
export const isCloudManager = (state) => getGroupType(state) === 'CLOUD';
export const isNdsGovEnabled = (state) => state.settings.isNdsGovEnabled;
export const isNdsServerlessEnabled = (state) => state.settings.isNdsServerlessEnabled;
export const isMTMCompactionEnabled = (state) => state.settings.isMTMCompactionEnabled;
export const setupServerlessCreateTestInstanceAllowed = (state) =>
  state.settings.setupServerlessCreateTestInstanceAllowed;
export const getRootCertPerEnv = (state) => state.settings.getRootCertPerEnv;
export const isSelfServePaymentsEnabled = (state) => state.settings.selfServePaymentsEnabled;
export const isEmailVerificationEnabled = (state) => state.settings.emailVerificationEnabled;
export const getPublishableKeyInc = (state) => state.settings.publishableKeyInc;
export const getPublishableKeyLtd = (state) => state.settings.publishableKeyLtd;
export const getDateFormats = (state) => state.settings.dateFormats;
export const getTimeFormats = (state) => state.settings.timeFormats;
export const getOrgControlledFeatureFlags = (state) =>
  (state.settings.featureFlags && state.settings.featureFlags.orgControlledFeatureFlags) || [];
export const getOrgEnabledFeatureFlags = (state) =>
  (state.settings.featureFlags && state.settings.featureFlags.orgEnabledFeatureFlags) || [];
export const getGroupControlledFeatureFlags = (state) =>
  (state.settings.featureFlags && state.settings.featureFlags.groupControlledFeatureFlags) || [];
export const getGroupEnabledFeatureFlags = (state) =>
  (state.settings.featureFlags && state.settings.featureFlags.groupEnabledFeatureFlags) || [];
export const getAtlasOperatorFeatureFlagMap = (state) =>
  (state.settings.featureFlags && state.settings.featureFlags.atlasOperatorFeatureFlagMap) || {};
export const getNDSServerlessOrganizationId = (state) => state.settings.ndsServerlessOrganizationId || '';
export const getSuppressMongosDiscovery = (state) => state.settings.suppressMongosAutoDiscovery;
export const isExtendedStorageSizesEnabled = (state) => state.settings.extendedStorageSizesEnabled;
export const getAtlasClusterWebsocketConnectionBaseUrl = (state) =>
  state.settings.atlasClusterWebsocketConnectionBaseUrl || '';
export const getAtlasRegionalDataEnabled = (state) => hasOrgFeature(state, 'ATLAS_DATA_REGIONALIZATION_ENABLED');
export const getDataRegion = (state) => {
  return state.settings.dataRegion || '';
};
export const getRegionalConnectionBaseUrl = (state) => {
  return state.settings.regionalConnectionBaseUrl || '';
};

export const hasOrgFeature = (state, featureFlag) =>
  getOrgEnabledFeatureFlags(state).indexOf(featureFlag.toUpperCase()) >= 0;
export const hasProjectFeature = (state, featureFlag) =>
  getGroupEnabledFeatureFlags(state).indexOf(featureFlag.toUpperCase()) >= 0;

export const getCurrentIpv4Address = (state) => state.settings.currentIpv4Address;
export const getCurrentIpAddress = (state) => state.settings.currentIpAddress;
export const hasActiveBackups = (state) => state.settings.hasActiveBackups;
export const canUseAutomationLogCollection = (state) => state.settings.canUseAutomationLogCollection;
export const getLogCollectionMaxDiskSpaceMB = (state) => state.settings.logCollectionMaxDiskSpaceMB;
export const getABTestAssignments = (state) => state.settings.abTestAssignments || [];

export const isAutomationDisabled = (state) =>
  getEffectivePlanType(state) === 'FREE_TIER' || !hasProjectFeature(state, 'AUTOMATION');

// Captcha related selectors
export const isReCaptchaEnabledLogin = (state) => state.settings.reCaptchaEnabledLogin;
export const isReCaptchaEnabledRegistration = (state) => state.settings.reCaptchaEnabledRegistration;
export const getReCaptchaPublic = (state) => state.settings.reCaptchaPublic;

// Registration specific selectors
export const isUserRegistrationEnabled = (state) => state.settings.userRegistrationEnabled;
export const getMultiFactorAuthResetAllowed = (state) => state.settings.multiFactorAuthResetAllowed;
export const hasUsers = (state) => state.settings.hasUsers;
export const isAnalyticsEnabled = (state) => state.settings.analyticsEnabled;

// Live migration specific selectors
export const isPushLiveMigrationEnabled = (state) => state.settings.mmsPushLiveMigrationsEnabled;
export const isPushLiveMigrationPrivateLinkEnabled = (state) => state.settings.mmsPushLiveMigrationPrivateLinkEnabled;

// Vercel integration specific selectors
export const isProjectLinkedToVercel = (state) => state.settings.isProjectLinkedToVercel;
export const vercelLinkedClusters = (state) => state.settings.vercelLinkedClusters;
export const isZeroTouchKubernetesEnabled = (state) => state.settings.mmsKubernetesEnabled && !isNDSPlan(state);

export const isDarkModePreviewEnabled = (state) => state.settings.isDarkModePreviewEnabled;

export const getAuid = (state) => state.settings.userAuid;

export const isOidcIdpEnabled = (state) => state.settings.isOidcIdpEnabled;

export const isOidcIdpEnabledNonAtlas = (state) => state.settings.isOidcIdpEnabledNonAtlas;
// Action Creators
export const resetState = (payload) => ({
  type: RESET,
  payload,
});

export const setBaasCentralUrl = (payload) => ({
  type: SET_BAAS_CENTRAL_URL,
  payload,
});

export const setABTestAssignments = (payload) => ({
  type: SET_AB_TEST_ASSIGNMENTS,
  payload,
});

export const setPersonalizationWizardResponse = (payload: PersonalizationWizardResponse) => (dispatch) => {
  dispatch({ type: SET_PERSONALIZATION_WIZARD_RESPONSE, payload });
};

export const setAtlasOperatorFeatureFlagMap = (payload) => ({
  type: SET_ATLAS_OPERATOR_FEATURE_FLAG_MAP,
  payload,
});

export const setGroupControlledFeatureFlags = (payload) => ({
  type: SET_GROUP_CONTROLLED_FEATURE_FLAGS,
  payload,
});

export const setOrgControlledFeatureFlags = (payload) => ({
  type: SET_ORG_CONTROLLED_FEATURE_FLAGS,
  payload,
});

export const toggleAtlasOperatorEnabledFeatureFlag = ({ enabled, featureFlag }) => ({
  type: TOGGLE_ATLAS_OPERATOR_ENABLED_FEATURE_FLAG,
  payload: {
    enabled,
    featureFlag,
  },
});

export const toggleGroupEnabledFeatureFlags = ({ enabled, featureFlag }) => ({
  type: TOGGLE_GROUP_ENABLED_FEATURE_FLAGS,
  payload: {
    enabled,
    featureFlag,
  },
});

export const toggleOrgEnabledFeatureFlags = ({ enabled, featureFlag }) => ({
  type: TOGGLE_ORG_ENABLED_FEATURE_FLAGS,
  payload: {
    enabled,
    featureFlag,
  },
});

export const loadAtlasOperatorFeatureFlags = (groupId) => (dispatch) => {
  api.settings.getAtlasOperatorControlledFeatureFlags(groupId).then((response) => {
    dispatch(setAtlasOperatorFeatureFlagMap(response));
  });
};

export const loadGroupControlledFeatureFlags = (groupId) => (dispatch) => {
  api.settings.getGroupControlledFeatureFlags(groupId).then((response) => {
    dispatch(setGroupControlledFeatureFlags(response.groupControlledFeatureFlags));
  });
};

export const loadOrgControlledFeatureFlags = (orgId) => (dispatch) => {
  api.settings.getOrgControlledFeatureFlags(orgId).then((response) => {
    dispatch(setOrgControlledFeatureFlags(response.orgControlledFeatureFlags));
  });
};

export const toggleAtlasOperatorFeatureFlag =
  ({ groupId, featureFlag, enabled }) =>
  async (dispatch) => {
    try {
      dispatch(toggleAtlasOperatorEnabledFeatureFlag({ featureFlag, enabled: !enabled }));
      await api.settings.toggleAtlasOperatorFeatureFlag({
        groupId,
        featureFlag,
        enabled: !enabled,
      });
    } catch {
      dispatch(toggleAtlasOperatorEnabledFeatureFlag({ featureFlag, enabled }));
    }
  };

export const toggleGroupFeatureFlag =
  ({ groupId, featureFlag, enabled }) =>
  async (dispatch) => {
    try {
      dispatch(toggleGroupEnabledFeatureFlags({ featureFlag, enabled }));
      await api.settings.toggleGroupFeatureFlag({ groupId, featureFlag, enabled: !enabled });
    } catch {
      dispatch(toggleGroupEnabledFeatureFlags({ featureFlag, enabled: !enabled }));
    }
  };

export const toggleOrgFeatureFlag =
  ({ orgId, featureFlag, enabled }: { orgId: string; featureFlag: string; enabled: boolean }) =>
  async (dispatch) => {
    try {
      dispatch(toggleOrgEnabledFeatureFlags({ featureFlag, enabled }));
      await api.settings.toggleOrgFeatureFlag({ orgId, featureFlag, enabled: !enabled });
    } catch {
      dispatch(toggleOrgEnabledFeatureFlags({ featureFlag, enabled: !enabled }));
    }
  };

export const toggleGroupEditableFeatureFlag =
  ({ groupId, featureFlag, enabled }: { groupId: string; featureFlag: string; enabled: boolean }) =>
  async (dispatch) => {
    try {
      dispatch(toggleGroupEnabledFeatureFlags({ featureFlag, enabled: !enabled }));
      await api.settings.toggleEditableFeatureFlag({ groupId, featureFlag, enabled });
    } catch {
      dispatch(toggleGroupEnabledFeatureFlags({ featureFlag, enabled }));
    }
  };

export const toggleExtendedStorageSizesEnabled =
  ({ groupId, enabled }) =>
  async (dispatch) => {
    const response = await api.settings.toggleExtendedStorageSizesEnabled({ groupId, enabled });

    dispatch({
      type: TOGGLE_EXTENDED_STORAGE_SIZES_ENABLED,
      meta: { groupId },
      payload: {
        enabled: response.value,
      },
    });
  };

export const hasDecoupledSearchFeatureEnabled = (state) =>
  hasProjectFeature(state, 'ATLAS_SEARCH_DECOUPLED_ARCHITECTURE') &&
  hasProjectFeature(state, 'ATLAS_SEARCH_DECOUPLED_ARCHITECTURE_API');
