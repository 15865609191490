import { FormEvent, ReactNode } from 'react';

import styled from '@emotion/styled';
import Button, { ButtonProps } from '@leafygreen-ui/button';
import { MongoDBLogo } from '@leafygreen-ui/logo';
import { H3 } from '@leafygreen-ui/typography';

const Container = styled.div<{
  isForm: boolean;
}>`
  width: 100%;
  position: relative;
  box-sizing: border-box;
`;

const SubmitButton = styled<React.ComponentType<{ type?: string } & ButtonProps>>(Button)`
  bottom: 0;
  width: 100%;
  margin-top: 48px;
`;

const Title = styled<React.ComponentType<Omit<React.ComponentProps<typeof H3>, 'as'>>>(H3)`
  margin: 0 0 24px;
`;
interface FormProps {
  buttonText: string;
  onSubmit: (e: FormEvent) => void;
  disabled?: boolean;
}

export default function ThirdPartyIntegrationLayout({
  title = '',
  isForm = false,
  formProps = {
    buttonText: '',
    onSubmit: () => {},
    disabled: false,
  },
  children,
}: {
  title?: string;
  isForm?: boolean;
  formProps?: FormProps;
  children?: ReactNode;
}) {
  return (
    <Container isForm={isForm}>
      <MongoDBLogo height={32} css={{ marginBottom: '24px' }} />
      {title && <Title data-testid="pageTitle">{title}</Title>}
      {isForm ? (
        <form onSubmit={formProps.onSubmit} method="post" name="third-party-integration-form">
          {children}
          <SubmitButton
            data-testid="submitbtn"
            variant="primary"
            size="large"
            type="submit"
            disabled={formProps.disabled}
          >
            {formProps.buttonText}
          </SubmitButton>
        </form>
      ) : (
        <>{children}</>
      )}
    </Container>
  );
}
