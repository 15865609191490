import { createSelector } from 'reselect';

import type { ProcessArgs } from '@packages/types/nds/ProcessArgs';
import type { Providers } from '@packages/types/nds/provider';

import * as api from 'js/common/services/api';

import type { DefaultTemplates } from 'js/project/nds/types/defaultTemplate';

// Action Types
const SET_DEFAULT_TEMPLATES = 'nds/clusterForm/SET_DEFAULT_TEMPLATES';
const SET_GROUP_LIMITS = 'nds/clusterForm/SET_GROUP_LIMITS';
const SET_PROVIDER_OPTIONS = 'nds/clusterForm/SET_PROVIDER_OPTIONS';
const SET_CROSS_CLOUD_PROVIDER_OPTIONS = 'nds/clusterForm/SET_CROSS_CLOUD_PROVIDER_OPTIONS';
const SET_PROCESS_ARGS = 'nds/clusterForm/SET_PROCESS_ARGS';
const SET_DEFAULT_PROCESS_ARGS = 'nds/clusterForm/SET_DEFAULT_PROCESS_ARGS';
const SET_CLUSTER_USAGE_STATS = 'nds/clusterForm/SET_CLUSTER_USAGE_STATS';
const SET_PRIVATE_IP_MODE = 'nds/clusterForm/SET_PRIVATE_IP_MODE';
const SET_CONTAINERS = 'nds/clusterForm/SET_CONTAINERS';
const SET_FTS_INDEXES = 'nds/clusterForm/SET_FTS_INDEXES';
const SET_INSTANCE_HARDWARE_REPLICATION_VIEWS = 'nds/clusterForm/SET_INSTANCE_HARDWARE_REPLICATION_VIEWS';
const SET_CLUSTER_OUTAGE_SIMULATION = 'nds/clusterForm/SET_CLUSTER_OUTAGE_SIMULATION';

const initialState = {};

// Reducer
// eslint-disable-next-line no-multi-assign
export default function clusterFormReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DEFAULT_TEMPLATES: {
      const newState = {
        ...state,
        defaultTemplates: action.payload,
      };

      return newState;
    }
    case SET_GROUP_LIMITS: {
      const newState = {
        ...state,
        groupLimits: action.payload,
      };

      return newState;
    }
    case SET_PROVIDER_OPTIONS: {
      const newState = {
        ...state,
        providerOptions: action.payload,
      };

      return newState;
    }
    case SET_CROSS_CLOUD_PROVIDER_OPTIONS: {
      const newState = {
        ...state,
        crossCloudProviderOptions: action.payload,
      };

      return newState;
    }
    case SET_PROCESS_ARGS: {
      const newState = {
        ...state,
        processArgs: action.payload,
      };

      return newState;
    }
    case SET_DEFAULT_PROCESS_ARGS: {
      const newState = {
        ...state,
        defaultProcessArgs: action.payload,
      };

      return newState;
    }
    case SET_CLUSTER_USAGE_STATS: {
      const newState = {
        ...state,
        clusterUsageStats: action.payload,
      };

      return newState;
    }
    case SET_PRIVATE_IP_MODE: {
      const newState = {
        ...state,
        privateIpMode: action.payload,
      };

      return newState;
    }
    case SET_CONTAINERS: {
      const newState = {
        ...state,
        cloudContainers: action.payload,
      };

      return newState;
    }
    case SET_FTS_INDEXES: {
      const newState = {
        ...state,
        ftsIndexes: action.payload,
      };

      return newState;
    }
    case SET_INSTANCE_HARDWARE_REPLICATION_VIEWS: {
      const newState = {
        ...state,
        instanceHardwareReplicationViews: action.payload,
      };

      return newState;
    }
    case SET_CLUSTER_OUTAGE_SIMULATION: {
      const newState = {
        ...state,
        clusterOutageSimulation: action.payload,
      };

      return newState;
    }
    default:
      return state;
  }
}

const getClusterFormData = (state) => state.nds.clusterForm;

// Selectors
export const getDefaultTemplates = createSelector(
  [getClusterFormData],
  (clusterFormData): DefaultTemplates => clusterFormData.defaultTemplates || {}
);
export const getGroupLimits = createSelector(
  [getClusterFormData],
  (clusterFormData) => clusterFormData.groupLimits || {}
);
export const getProviderOptions = createSelector(
  [getClusterFormData],
  (clusterFormData): Providers => clusterFormData.providerOptions || {}
);
export const getCrossCloudProviderOptions = createSelector(
  [getClusterFormData],
  (clusterFormData) => clusterFormData.crossCloudProviderOptions || {}
);
export const getProcessArgs = createSelector(
  [getClusterFormData],
  (clusterFormData): ProcessArgs => clusterFormData.processArgs || {}
);
export const getDefaultProcessArgs = createSelector(
  [getClusterFormData],
  (clusterFormData): ProcessArgs => clusterFormData.defaultProcessArgs || {}
);
export const getClusterUsageStats = createSelector(
  [getClusterFormData],
  (clusterFormData) => clusterFormData.clusterUsageStats || {}
);
export const getPrivateIpMode = createSelector(
  [getClusterFormData],
  (clusterFormData) => clusterFormData.privateIpMode || {}
);
export const getCloudContainers = createSelector(
  [getClusterFormData],
  (clusterFormData) => clusterFormData.cloudContainers || []
);
export const getFTSIndexes = createSelector(
  [getClusterFormData],
  (clusterFormData) => clusterFormData.ftsIndexes || []
);
export const getInstanceHardwareReplicationViews = createSelector(
  [getClusterFormData],
  (clusterFormData) => clusterFormData.instanceHardwareReplicationViews || []
);
export const getClusterOutageSimulation = createSelector(
  [getClusterFormData],
  (clusterFormData) => clusterFormData.clusterOutageSimulation
);

// Action Creators
const setDefaultTemplates = (payload) => ({
  type: SET_DEFAULT_TEMPLATES,
  payload,
});
export { setDefaultTemplates };

const setGroupLimits = (payload) => ({
  type: SET_GROUP_LIMITS,
  payload,
});

const setProviderOptions = (payload) => ({
  type: SET_PROVIDER_OPTIONS,
  payload,
});

const setCrossCloudProviderOptions = (payload) => ({
  type: SET_CROSS_CLOUD_PROVIDER_OPTIONS,
  payload,
});

const setProcessArgs = (payload) => ({
  type: SET_PROCESS_ARGS,
  payload,
});

const setDefaultProcessArgs = (payload) => ({
  type: SET_DEFAULT_PROCESS_ARGS,
  payload,
});

const setClusterUsageStats = (payload) => ({
  type: SET_CLUSTER_USAGE_STATS,
  payload,
});

const setPrivateIpMode = (payload) => ({
  type: SET_PRIVATE_IP_MODE,
  payload,
});

const setCloudContainers = (payload) => ({
  type: SET_CONTAINERS,
  payload,
});

const setFTSIndexes = (payload) => ({
  type: SET_FTS_INDEXES,
  payload,
});

const setInstanceHardwareReplicationViews = (payload) => ({
  type: SET_INSTANCE_HARDWARE_REPLICATION_VIEWS,
  payload,
});

const setClusterOutageSimulation = (payload) => ({
  type: SET_CLUSTER_OUTAGE_SIMULATION,
  payload,
});

export const loadDefaultTemplates = (groupId) => (dispatch) => {
  return api.nds.clusterForm.getDefaultTemplates(groupId).then((response) => dispatch(setDefaultTemplates(response)));
};

export const loadGroupLimits = (groupId: string) => (dispatch) => {
  return api.nds.limits.getLimits(groupId).then((response) => dispatch(setGroupLimits(response)));
};

export const loadProviderOptions = (groupId: string) => (dispatch) => {
  return api.nds.clusterForm.getProviderOptions(groupId).then((response) => dispatch(setProviderOptions(response)));
};

export const loadCrossCloudProviderOptions = (groupId, providers) => (dispatch) => {
  return api.nds.clusterDescriptions
    .getCrossCloudProviderOptions(groupId, providers)
    .then((response) => dispatch(setCrossCloudProviderOptions(response)));
};

export const loadProcessArgs = (groupId: string, clusterName: string) => (dispatch) => {
  return api.nds.clusterDescriptions
    .getProcessArgs(groupId, clusterName)
    .then((response) => dispatch(setProcessArgs(response)));
};

export const loadDefaultProcessArgs = (groupId: string) => (dispatch) => {
  return api.nds.clusterDescriptions
    .getDefaultProcessArgs(groupId)
    .then((response) => dispatch(setDefaultProcessArgs(response)));
};

export const loadClusterUsageStats = (groupId, clusterName) => (dispatch) => {
  return api.nds.clusterDescriptions
    .getClusterUsageStats(groupId, clusterName)
    .then((response) => dispatch(setClusterUsageStats(response)));
};

export const loadPrivateIpMode = (groupId) => (dispatch) => {
  return api.nds.networkSettings.getPrivateIpMode(groupId).then((response) => dispatch(setPrivateIpMode(response)));
};

export const loadCloudContainers = (groupId) => (dispatch) => {
  return api.nds.container.getContainers(groupId).then((response) => dispatch(setCloudContainers(response)));
};

export const loadFTSIndexes = (groupId, clusterName) => (dispatch) => {
  return api.nds.ftsIndexConfig
    .fetchFTSIndexes(groupId, clusterName)
    .then((response) => dispatch(setFTSIndexes(response)));
};

export const loadInstanceHardwareReplicationViews = (groupId, clusterName) => (dispatch) => {
  return api.nds.clusterDescriptions
    .getInstanceHardwareReplicationViews(groupId, clusterName)
    .then((response) => dispatch(setInstanceHardwareReplicationViews(response)));
};

export const loadClusterOutageSimulation = (groupId, clusterName) => (dispatch) => {
  return api.nds.clusterOutageSimulationApi
    .findClusterOutage(groupId, clusterName)
    .then((response) => dispatch(setClusterOutageSimulation(response)))
    .catch((err) => {
      if (err.statusCode === 404) {
        return null;
      }

      throw err;
    });
};

export { setPrivateIpMode };
