import { createSelector } from 'reselect';

import * as api from 'js/common/services/api';

// ActionTypes
const SET_SERVERLESS_INSTANCES = 'nds/serverlessInstances/SET_SERVERLESS_INSTANCES';

const defaultState: object = {};

// Reducer
export default function serverlessInstancesReducer(state: object = defaultState, action) {
  switch (action.type) {
    case SET_SERVERLESS_INSTANCES: {
      return action.payload.reduce((acc, serverlessInstance) => {
        acc[serverlessInstance.name] = {
          data: serverlessInstance,
        };
        return acc;
      }, {});
    }
    default:
      return state;
  }
}

const getServerlessInstancesData = (state): object => state.nds.serverlessInstances;

// Selectors
export const getServerlessInstancesKeyedByName = (state): object => getServerlessInstancesData(state);

export const getServerlessInstancesAsList = createSelector(
  [getServerlessInstancesKeyedByName],
  (keyedByName): Array<object> => Object.values(keyedByName).map((entry) => entry.data)
);

export const getServerlessInstance = (state, serverlessInstanceName: string): object | null => {
  const serverlessInstanceData = getServerlessInstancesKeyedByName(state)[serverlessInstanceName];
  return serverlessInstanceData ? serverlessInstanceData.data : null;
};

// Action Creators
const setServerlessInstances = (payload) => ({
  type: SET_SERVERLESS_INSTANCES,
  payload,
});
export { setServerlessInstances };

export const loadServerlessInstances = (groupId: string) => (dispatch) => {
  return api.nds.serverlessInstances
    .getServerlessInstances(groupId)
    .then((response) => dispatch(setServerlessInstances(response)));
};
