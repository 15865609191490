import { ReactNode } from 'react';

import { css } from '@emotion/react';
import { MongoDBLogo } from '@leafygreen-ui/logo';
import { H3 } from '@leafygreen-ui/typography';
import { Helmet } from 'react-helmet';

import Layout from './Layout';

interface MfaEncouragementLayoutProps {
  title: string;
  children: ReactNode;
}

const headerStyle = css`
  line-height: 32px;
  font-size: 24px;
  margin-top: 26px;
`;

const logoStyle = css`
  margin-top: 50px;
  height: 36px;
`;

export const MfaEncouragementLayout = ({ title, children }: MfaEncouragementLayoutProps) => {
  return (
    <>
      <Helmet title="MFA Setup" />
      <Layout contentPlacement="right">
        <div css={{ marginLeft: '40px', width: '550px' }}>
          <MongoDBLogo css={logoStyle} />
          <H3 css={headerStyle} data-testid="layout-card-header">
            {title}
          </H3>
          {children}
        </div>
      </Layout>
    </>
  );
};
