import { useState } from 'react';

import { css } from '@emotion/react';
import Banner from '@leafygreen-ui/banner';
import Button from '@leafygreen-ui/button';
import Card from '@leafygreen-ui/card';

import { FactorType, OktaMfaFactor, WebAuthnFactorEnrollmentResponse } from '@packages/types/accountMultiFactorAuth';

import * as api from 'js/common/services/api';
import { getErrorMessageFromCode } from 'js/common/services/authErrorHelper';
// common styles
import { bannerStyles, setupStyles, Step } from 'js/common/styles/multiFactorAuth';
import { CloudTeams, sendError } from 'js/common/utils/bugsnag';
import { binToStr, isLastAuthExpired, strToBin } from 'js/common/utils/multiFactorAuthUtils';

const enrollButtonStyles = css({
  width: '100px',
  marginTop: '12px',
});

type NewCredential = Credential | null;

export default function WebAuthnSetupCard({
  windowLocation = window.location,
  navigatorCredentials = navigator.credentials,
  authExpiredPath,
  onSuccess,
}: {
  windowLocation?: Pick<Location, 'assign'>;
  navigatorCredentials: CredentialsContainer;
  authExpiredPath: string;
  onSuccess: (factors: Array<OktaMfaFactor>) => void;
}) {
  const [errorMessage, setErrorMessage] = useState('');
  const [isEnrollDisabled, setIsEnrollDisabled] = useState(false);

  const enrollAndActivateWebAuthnFactor = async () => {
    setIsEnrollDisabled(true);
    try {
      const response = (await api.accountMultiFactorAuth.enrollFactor({
        factorType: FactorType.WebAuthn,
      })) as WebAuthnFactorEnrollmentResponse;

      let options = JSON.parse(JSON.stringify(response.credentialOptions as PublicKeyCredentialCreationOptions));

      options.challenge = strToBin(options.challenge);
      options.user.id = strToBin(options.user.id);
      options.excludeCredentials?.forEach((ec: { id: string | Uint8Array }) => (ec.id = strToBin(ec.id as string)));

      const newCredential: NewCredential = await navigatorCredentials.create({
        publicKey: options as PublicKeyCredentialCreationOptions,
      });

      const { attestationObject, clientDataJSON } = (newCredential as PublicKeyCredential)
        ?.response as AuthenticatorAttestationResponse;

      const attestation = binToStr(attestationObject);
      const clientData = binToStr(clientDataJSON);

      const { activated, userFactors } = await api.accountMultiFactorAuth.activateFactor({
        factorType: FactorType.WebAuthn,
        factorId: response.id,
        attestation,
        clientData,
      });

      if (activated) {
        onSuccess(userFactors);
      }
    } catch (e) {
      setIsEnrollDisabled(false);
      sendError({ error: e, team: CloudTeams.CoreIam });
      if (isLastAuthExpired(e.errorCode)) {
        windowLocation.assign(authExpiredPath);
      } else if (e.errorCode === 'RATE_LIMITED') {
        setErrorMessage(getErrorMessageFromCode(e.errorCode));
      } else {
        setErrorMessage(e.errorMessage);
      }
    }
  };

  return (
    <Card css={setupStyles.card}>
      <Step.Container>
        <Step.Header>Step One</Step.Header>
        <p>
          Your browser will prompt you to register a security key or biometric authenticator (Yubikey, Touch ID, Windows
          Hello, etc.). Follow the instructions to complete enrollment.
        </p>
        {errorMessage && (
          <Banner variant="danger" data-testid="error-banner" css={bannerStyles}>
            {errorMessage}
          </Banner>
        )}
        <Button
          name="enroll"
          css={enrollButtonStyles}
          onClick={enrollAndActivateWebAuthnFactor}
          variant="primary"
          disabled={isEnrollDisabled}
        >
          Enroll
        </Button>
      </Step.Container>
    </Card>
  );
}
