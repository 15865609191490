import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { palette } from '@leafygreen-ui/palette';

import { mq } from 'js/common/utils/mediaQueries';

export const FactorsContainer = styled.div(() =>
  mq({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: ['flex-start', 'center', 'flex-start', 'flex-start'],
    marginRight: '-12px',
  })
);

export const MfaContainer = styled.section(() =>
  mq({
    display: 'flex',
    width: '100%',
    maxWidth: ['450px', '450px', '450px', '730px'],
    flexDirection: 'column',
    alignItems: 'center',
  })
);

export const breadcrumbsStyles = css(
  mq({
    fontSize: '11px',
    color: palette.blue.base,
    alignSelf: 'flex-start',
    marginTop: ['0px', '24px', '0px', '0px'],
    paddingBottom: '0px',
  })
);

export const bannerStyles = css({ width: '100%', marginTop: '20px' });

export const bodyParagraphStyles = css({ marginBottom: '10px' });

export const baseStyle = {
  body: css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    boxSizing: 'border-box',
  }),
  text: css({
    marginBottom: '14px',
  }),
  header: css({
    fontSize: '15px',
    fontWeight: 'bold',
  }),
  clickableLink: css({ color: palette.blue.base, textDecoration: 'none', cursor: 'pointer' }),
};

// Setup pages have different styling
export const setupStyles = {
  header: css(
    mq({
      fontWeight: 'bold',
      color: palette.gray.dark2,
      marginTop: ['20px', '8px', '20px', '20px'],
      marginBottom: ['30px', '24px', '30px', '30px'],
      alignSelf: 'flex-start',
      fontSize: ['30px', '24px', '30px', '30px'],
    })
  ),
  card: css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'start',
    padding: '24px 32px 0px 32px',
  }),
  phone: css({
    width: '200px',
    paddingBottom: '8px',
  }),
  phoneExtension: css({
    width: '140px',
    paddingBottom: '8px',
    marginLeft: '20px',
  }),
};

export const Step = {
  Container: styled.div({
    paddingBottom: '36px',
  }),
  Header: styled.p({
    textTransform: 'uppercase',
    fontSize: '11px',
    fontWeight: 'bold',
    marginBottom: '4px',
    color: palette.green.dark2,
  }),
};
