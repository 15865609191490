import { useEffect, useState } from 'react';

import Banner from '@leafygreen-ui/banner';

import { admin } from 'js/common/services/api';

export interface Message {
  _id: string;
  msg: string;
  active: boolean;
  path: string;
}

function UIMessages({ path }: { path: string }) {
  const [messages, setMessages] = useState<Array<Message>>([]);

  useEffect(() => {
    async function loadUIMessages() {
      const msgs = await admin.getUiMsgs();
      setMessages(msgs);
    }

    loadUIMessages();
  }, []);

  const matchedMessages = messages.filter((msg) => {
    return msg.active && (!msg.path || ensureLeadingSlash(path).includes(msg.path));
  });

  if (!matchedMessages.length) {
    return null;
  }

  return (
    <>
      {matchedMessages.map((message) => (
        <Banner variant="warning" css={{ marginBottom: '15px' }} key={message._id}>
          {message.msg}
        </Banner>
      ))}
    </>
  );
}

function ensureLeadingSlash(path: string): string {
  return path.startsWith('/') ? path : '/' + path;
}

export default UIMessages;
