import { Dispatch } from 'redux';

import { RegionUsageRestrictions } from '@packages/types/nds/regionUsageRestrictions';

import { fetchRegionUsageRestrictions } from 'js/common/services/api/nds/projectSettingsApi';

export interface ProjectSettingsState {
  regionUsageRestrictions: RegionUsageRestrictions;
}

enum ProjectSettingsActionTypes {
  FETCH_REGION_USAGE_RESTRICTIONS = '/nds/projectSettings/FETCH_REGION_USAGE_RESTRICTIONS',
}

const initialState: ProjectSettingsState = {
  regionUsageRestrictions: RegionUsageRestrictions.NONE,
};

// reducers
export default function projectSettingsReducer(state: ProjectSettingsState = initialState, action) {
  switch (action.type) {
    case ProjectSettingsActionTypes.FETCH_REGION_USAGE_RESTRICTIONS:
      return {
        ...state,
        regionUsageRestrictions: action.payload.regionUsageRestrictions as RegionUsageRestrictions,
      };
    default:
      return state;
  }
}

// selectors
export const getProjectSettings = (state): ProjectSettingsState => state.nds.projectSettings;
export const getRegionUsageRestrictions = (state): RegionUsageRestrictions =>
  getProjectSettings(state).regionUsageRestrictions;

const setRegionUsageRestrictions = (payload: RegionUsageRestrictions) => ({
  type: ProjectSettingsActionTypes.FETCH_REGION_USAGE_RESTRICTIONS,
  payload,
});

export const loadRegionUsageRestrictions = (groupId: string) => async (dispatch: Dispatch) => {
  const regionUsageRestrictions = await fetchRegionUsageRestrictions(groupId);
  dispatch(setRegionUsageRestrictions(regionUsageRestrictions));
};
