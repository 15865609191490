import fetchWrapper from '../fetchWrapper';

export default {
  getUserSecurity(groupId) {
    return fetchWrapper(`/nds/${groupId}/userSecurity`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  patchUserSecurity(groupId, userSecurity) {
    return fetchWrapper(`/nds/${groupId}/userSecurity`, {
      method: 'PATCH',
      body: JSON.stringify(userSecurity),
    }).then((resp) => resp.json());
  },

  deleteUserToDNMapping(groupId) {
    return fetchWrapper(`/nds/${groupId}/userSecurity/ldap/userToDNMapping`, {
      method: 'DELETE',
    }).then((resp) => resp.json());
  },

  deleteCustomerX509(groupId) {
    return fetchWrapper(`/nds/${groupId}/userSecurity/customerX509`, {
      method: 'DELETE',
    }).then((resp) => resp.json());
  },

  postLDAPVerifyConnectivityRequest(groupId, ldapVerifyConnectivityRequest) {
    return fetchWrapper(`/nds/${groupId}/userSecurity/ldap/verify`, {
      method: 'POST',
      body: JSON.stringify(ldapVerifyConnectivityRequest),
    }).then((resp) => resp.json());
  },

  getLDAPVerifyConnectivityRequest(groupId, requestId) {
    return fetchWrapper(`/nds/${groupId}/userSecurity/ldap/verify/${requestId}`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },
};
