import { FormEvent, ReactNode, useEffect, useState } from 'react';

import { css } from '@emotion/react';
import Banner from '@leafygreen-ui/banner';
import { palette } from '@leafygreen-ui/palette';
import { Option, Select, Size } from '@leafygreen-ui/select';
import { Body } from '@leafygreen-ui/typography';
import { Link } from '@leafygreen-ui/typography';
import queryString from 'query-string';
import _ from 'underscore';

import { Search } from '@packages/types/auth/search';
import { CloudProvider } from '@packages/types/nds/provider';
import { LinkedCluster, VercelProject } from '@packages/types/vercel';

import DataApiLoader from '@packages/components/DataApiLoader';
import { DataApiToggle } from '@packages/components/DataApiToggle';
import LineDivider from '@packages/components/LineDivider';
import { selectInputStyle } from '@packages/components/ThirdPartyIntegration/styles/common';
import ThirdPartyIntegrationLayout from '@packages/components/ThirdPartyIntegration/ThirdPartyIntegrationLayout';
import VercelProjectsToClusterLinker from '@packages/components/Vercel/VercelProjectsToClusterLinker';

import * as api from 'js/common/services/api';
import { useRequestParams } from 'js/common/context/RequestParamsContext';
import { organization } from 'js/common/services/api';
import clusterDescriptionsApi from 'js/common/services/api/nds/clusterDescriptionsApi';
import networkSettingsApi from 'js/common/services/api/nds/networkSettingsApi';
import { CloudTeams, sendError } from 'js/common/utils/bugsnag';
import { isNameUnused } from 'js/common/utils/isNameUnused';
import analytics, { SEGMENT_EVENTS } from 'js/common/utils/segmentAnalytics';

import IPAccessListField from './IPAccessListField';

const lineDividerStyle = css`
  margin: 24px 0;
`;

const createNewStyle = css`
  margin-bottom: 48px;
  margin-top: 24px;
  color: ${palette.blue.base};
  font-size: 15px;
  font-weight: bold;
  line-height: 24px;
  display: inline-block;
  text-decoration: none;
`;

const bodyStyle = css`
  font-size: 13px;
  color: ${palette.gray.dark2};
  margin-bottom: -36px;
  margin-top: 24px;
`;

export interface GroupWithUserRoles {
  groupId: string;
  groupName: string;
  userRoles: Array<string>;
}

export interface Cluster {
  name: string;
  groupId: string;
  '@provider'?: CloudProvider;
}

export interface IpAccessList {
  [key: string]: string | boolean;
}

interface VercelCreateAndManageLinkingProps {
  isConfigureFlow?: boolean;
  orgName?: string;
  orgId?: string;
  location?: Pick<Location, 'search'>;
  windowLocation?: Pick<Location, 'assign'>;
  onChangeCluster?: (clusterName: string) => void;
  onChangeVercelProjects?: (projects: Array<VercelProject>) => void;
  onSubmit?: (e: FormEvent<HTMLFormElement>, groupId: string) => void;
  isSubmitDisabled?: boolean;
  linkedClusters?: Array<LinkedCluster>;
  linkingError?: ReactNode;
  setHasLinkingError: (val: boolean) => void;
  setIsDataAPIToggleOn: (val: boolean) => void;
  submissionInProgress?: boolean;
  setSelectedProjectId?: (projectId: string) => void;
}

export default function VercelCreateAndManageLinking({
  isConfigureFlow = false,
  orgName = '',
  orgId = '',
  location: { search } = { search: '' },
  windowLocation = window.location,
  onChangeCluster = () => {},
  onChangeVercelProjects = () => {},
  setSelectedProjectId = () => {},
  onSubmit = () => {},
  isSubmitDisabled = false,
  linkedClusters = [],
  linkingError,
  setHasLinkingError,
  setIsDataAPIToggleOn,
  submissionInProgress = false,
}: VercelCreateAndManageLinkingProps) {
  const [projects, setProjects] = useState<Array<GroupWithUserRoles>>([]);
  const [selectedProjectName, setSelectedProjectName] = useState('');
  const [clusters, setClusters] = useState<Array<Cluster>>([]);
  const [clusterNamesAvailableForLinking, setClusterNamesAvailableForLinking] = useState<Array<string>>([]);
  const [isCheckboxSelected, setIsCheckboxSelected] = useState(false);
  const [showIpAccessList, setShowIpAccessList] = useState(false);
  const [hasFreeTierCluster, setHasFreeTierCluster] = useState(false);
  const [hasAnyCluster, setHasAnyCluster] = useState(true);
  const [hasError, setHasError] = useState(false);
  const { centralUrl } = useRequestParams();
  const { username } = queryString.parse(search) as Search;
  // keep the current toggle state changes within the modal
  const [currDataApiToggleVal, setCurrDataApiToggleVal] = useState(false);

  const displayOrgName = (orgName as string).replace(/\+/g, ' ');

  // @ts-ignore TODO: implement checkbox functionality in CLOUDP-148664
  const [isDataAPICheckboxChecked, setIsDataAPICheckboxChecked] = useState<boolean>(false);

  // TODO: if user has any edge projects (edgeConfigId !== null) then this will be true (CLOUDP-148660)
  const dataApiForceEnabled = false;

  useEffect(() => {
    // get org's groups data from orgId
    async function getProjectsWithUserRoles() {
      try {
        const projectsWithUserRoles = await organization.getVisibleGroupsWithUserRoles({ centralUrl, orgId });
        setProjects(projectsWithUserRoles);
        if (projectsWithUserRoles.length === 1) {
          setSelectedProjectName(projectsWithUserRoles[0].groupName);
        }
      } catch (error) {
        sendError({ error, team: CloudTeams.CoreIam });
      }
    }

    if (orgId !== '') {
      getProjectsWithUserRoles();
    }
  }, [orgId]);

  useEffect(() => {
    const projectId =
      projects.find((proj: GroupWithUserRoles) => proj.groupName === selectedProjectName)?.groupId || '';
    setSelectedProjectId(projectId);
  }, [projects, selectedProjectName, setSelectedProjectId]);

  useEffect(() => {
    async function getClustersAndIpWhitelists() {
      try {
        const selectedProject = projects.find(
          (project: GroupWithUserRoles) => project.groupName === selectedProjectName
        );

        const getClusterDescriptions: Promise<Array<Cluster>> = clusterDescriptionsApi.getClusterDescriptions(
          selectedProject?.groupId,
          centralUrl
        );
        const getIpAWhitelist: Promise<Array<IpAccessList>> = networkSettingsApi.getIpWhitelist(
          selectedProject?.groupId,
          centralUrl
        );

        const [clusters, ipAccessList] = await Promise.all([getClusterDescriptions, getIpAWhitelist]);
        setClusters(clusters);
        setHasAnyCluster(clusters.length > 0);
        getClusterNamesAvailableForLinking(clusters);

        if (clusters.some((cluster) => cluster['@provider'] === 'FREE')) {
          setHasFreeTierCluster(true);
        }

        const ipAccessListValues = _.pluck(ipAccessList, 'value');
        setShowIpAccessList(!_.contains(ipAccessListValues, '0.0.0.0/0'));
      } catch (error) {
        sendError({ error, team: CloudTeams.CoreIam });
      }
    }

    if (selectedProjectName !== '') {
      getClustersAndIpWhitelists();
    }
  }, [selectedProjectName]);

  const isProjectNameUnique = (name: string) => {
    return projects.length > 0 && !projects.find((project) => project.groupName === name);
  };

  const generateUniqueProjectOrClusterName = (nameFor: string) => {
    let uniqueName;
    let suffix = 0;
    let condition: boolean = true;

    if (nameFor === 'Project' && !projects.length) {
      return `Project 0`;
    }

    if (nameFor === 'Cluster' && !clusters.length) {
      return 'Cluster0';
    }

    do {
      if (nameFor === 'Project') {
        uniqueName = `Project ${suffix++}`;
        condition = isProjectNameUnique(uniqueName);
      } else {
        uniqueName = `Cluster${suffix++}`;
        condition = isNameUnused(
          uniqueName,
          clusters.map((cluster) => cluster.name)
        );
      }
    } while (!condition);
    return uniqueName;
  };

  const onClickCreateNewProject = async () => {
    try {
      await api.group.validateConfigLimits({
        orgId,
        numOfTeamsToAdd: 0,
        usernames: [decodeURIComponent(username as string)],
        centralUrl,
      });

      const groupName = generateUniqueProjectOrClusterName('Project');

      await organization.validateGroupName({ orgId, groupName, centralUrl });

      const { id: groupId } = await api.group.createGroup({
        orgId,
        group: groupName,
        useCNRegionsOnly: false,
        regionUsageRestrictions: 'NONE',
        centralUrl,
      });

      await api.group.grantAccess({ groupId, users: [], teams: [], apiUsers: [], centralUrl });

      windowLocation.assign(`/account/vercel/cluster/create${search}&groupId=${groupId}`);
    } catch (error) {
      sendError({ error, team: CloudTeams.CoreIam });
      setHasError(true);
    }
  };

  const onClickCreateNewCluster = () => {
    const selectedProject = projects.find((project: GroupWithUserRoles) => project.groupName === selectedProjectName);

    const groupId = selectedProject?.groupId;
    const clusterName = generateUniqueProjectOrClusterName('Cluster');
    windowLocation.assign(`/account/vercel/cluster/create${search}&groupId=${groupId}&clusterName=${clusterName}`);
  };

  const onClickSubmit = (e: FormEvent<HTMLFormElement>) => {
    const selectedProject = projects.find((project: GroupWithUserRoles) => project.groupName === selectedProjectName);

    if (selectedProject) {
      onSubmit(e, selectedProject.groupId);
    }
  };

  const getClusterNamesAvailableForLinking = (availableClusters: Array<Cluster>) => {
    const clusterNames: Array<string> = availableClusters.map((cluster) => cluster.name);
    const selectedProject = projects.find((project: GroupWithUserRoles) => project.groupName === selectedProjectName);
    const hasLinkedClusterMatchingSelectedProject = linkedClusters.some(
      ({ groupId }) => groupId === selectedProject?.groupId
    );
    if (linkedClusters.length === 0 || (selectedProject && !hasLinkedClusterMatchingSelectedProject)) {
      setClusterNamesAvailableForLinking(clusterNames);
      return;
    }

    let clusterNamesAvailableForLinking: Array<string> = [];
    const clusterNamesForGroupMatchingSelectedProject = linkedClusters.map(({ clusterName, groupId }) =>
      groupId === selectedProject?.groupId ? clusterName : ''
    );

    if (hasLinkedClusterMatchingSelectedProject) {
      clusterNamesAvailableForLinking = clusterNames.map((clusterName) =>
        clusterNamesForGroupMatchingSelectedProject.includes(clusterName)
          ? `${clusterName} (Already Linked)`
          : clusterName
      );
    }

    setClusterNamesAvailableForLinking(clusterNamesAvailableForLinking);
  };

  const sendToggleAnalytics = (val) => {
    analytics.track(SEGMENT_EVENTS.VERCEL_DATA_API_TOGGLE_STATUS, {
      context: isConfigureFlow ? 'Add Another Cluster Link With Vercel Page' : 'Integrate Atlas with Vercel Page',
      data_api_toggle_status: val.toString(),
    });
  };

  return submissionInProgress && !isConfigureFlow ? (
    <DataApiLoader />
  ) : (
    <ThirdPartyIntegrationLayout
      title={isConfigureFlow ? 'Add Another Cluster Link With Vercel' : 'Integrate Atlas with Vercel'}
      isForm
      formProps={{
        onSubmit: onClickSubmit,
        disabled: (showIpAccessList && !isCheckboxSelected) || !selectedProjectName || isSubmitDisabled,
        buttonText: isConfigureFlow ? 'Save' : 'Connect and Return to Vercel',
      }}
    >
      <Select
        size={Size.Default}
        name="organization"
        description={
          isConfigureFlow
            ? 'Your current Atlas organization'
            : 'Disconnect the organization to switch to another organization'
        }
        defaultValue={displayOrgName}
        placeholder={displayOrgName}
        label="Organization"
        css={{ ...selectInputStyle, marginTop: '32px', marginBottom: '10px' }}
        disabled
      >
        <Option key={orgId} value={orgId}>
          {displayOrgName}
        </Option>
      </Select>
      {projects.length > 0 && (
        <Select
          size={Size.Default}
          name="project"
          data-testid="project"
          description="Select an Atlas project to integrate Vercel with"
          placeholder="Select an Atlas project"
          onChange={setSelectedProjectName}
          value={selectedProjectName}
          label="Project"
          css={selectInputStyle}
        >
          {projects.map((project) => {
            const isEnabled = project.userRoles.includes('GROUP_OWNER');
            const projectName = project.groupName;
            return (
              <Option key={projectName} value={projectName} disabled={!isEnabled}>
                {isEnabled ? projectName : `${projectName} (Insufficient Permissions)`}
              </Option>
            );
          })}
        </Select>
      )}
      {!selectedProjectName && !isConfigureFlow && (
        <>
          {projects.length > 0 && <LineDivider css={lineDividerStyle}>or</LineDivider>}
          {projects.length === 0 ? (
            <Body css={css({ marginTop: 24 })}>
              Please{' '}
              <a
                href="#"
                onClick={onClickCreateNewProject}
                css={css`
                  ${createNewStyle};
                  margin-top: 0;
                `}
              >
                Create new Atlas Project
              </a>{' '}
              to get started.
            </Body>
          ) : (
            <a href="#" css={createNewStyle} onClick={onClickCreateNewProject}>
              Create new Atlas Project
            </a>
          )}
        </>
      )}
      {selectedProjectName && (
        <>
          {!hasAnyCluster && isConfigureFlow && (
            <Banner variant="warning" css={css({ marginTop: '24px' })}>
              The selected project has no clusters that can be linked to Vercel Projects. Please create a cluster from
              the{' '}
              <Link
                href={`/v2/${projects.find((project) => project.groupName === selectedProjectName)?.groupId}#clusters`}
              >
                Clusters UI
              </Link>{' '}
              and then attempt to link.
            </Banner>
          )}
          {clusterNamesAvailableForLinking.length > 0 && (
            <VercelProjectsToClusterLinker
              clusterNames={clusterNamesAvailableForLinking}
              orgId={orgId}
              selectedProjectName={selectedProjectName}
              centralUrl={centralUrl}
              onChangeCluster={onChangeCluster}
              onChangeVercelProjects={onChangeVercelProjects}
              hasLinkingError={Boolean(linkingError)}
              isConfigureFlow={isConfigureFlow}
              setHasLinkingError={setHasLinkingError}
            />
          )}
        </>
      )}
      {selectedProjectName && !hasFreeTierCluster && !isConfigureFlow && (
        <>
          {clusterNamesAvailableForLinking.length > 0 && <LineDivider css={lineDividerStyle}>or</LineDivider>}
          {clusterNamesAvailableForLinking.length === 0 ? (
            <Body css={css({ marginTop: 24 })}>
              The selected project has no clusters that can be linked to Vercel Projects. Please{' '}
              <a
                href="#"
                onClick={onClickCreateNewCluster}
                css={css`
                  ${createNewStyle};
                  margin-top: 0;
                `}
              >
                Create new Atlas Cluster
              </a>{' '}
              to get started.
            </Body>
          ) : (
            <a href="#" onClick={onClickCreateNewCluster} css={createNewStyle}>
              Create new Atlas Cluster
            </a>
          )}
        </>
      )}
      {selectedProjectName && showIpAccessList && clusterNamesAvailableForLinking.length > 0 && (
        <IPAccessListField onSelectCheckbox={() => setIsCheckboxSelected(!isCheckboxSelected)} />
      )}
      {selectedProjectName && clusterNamesAvailableForLinking.length > 0 && (
        <DataApiToggle
          toggled={currDataApiToggleVal}
          onToggle={(val: boolean) => {
            setIsDataAPIToggleOn(val);
            setCurrDataApiToggleVal(val);
            sendToggleAnalytics(val);
          }}
          dataApiForceEnabled={dataApiForceEnabled}
        />
      )}
      {linkingError || hasError ? (
        <>
          {linkingError}
          {hasError && (
            <Banner variant="danger" css={css({ margin: '24px 0px' })}>
              An error occurred with creating a new Atlas Project. Please use one of the existing Atlas projects to
              continue.
            </Banner>
          )}
        </>
      ) : (
        <Body css={bodyStyle}>
          A confirmation email will be sent to you with more detailed information regarding your new Vercel integration.
        </Body>
      )}
    </ThirdPartyIntegrationLayout>
  );
}
