import {
  APIKey,
  AuthProviderType,
  DataAPIConfig,
  DeploymentModel,
  EndpointReturnType,
  EndpointValidationMethod,
  Location,
  MongoDBBaseRule,
  MongoDBNamespaceRule,
  PartialAPIKey,
  PartialAuthProviderConfig,
  PresetRole,
  ProviderRegion,
} from 'baas-admin-sdk';
import { Dispatch } from 'redux';

import { ClusterServiceConfig, DataAPILogItem, MongoService, MongoServiceConfig } from '@packages/types/dataAPI';

import { loadClusterDescriptions } from '@packages/redux/modules/nds/clusterDescriptions';
import { loadDataLakesWithStorage } from '@packages/redux/modules/nds/dataLakes';

import { createJsonLocalStorage } from 'js/common/services/localStorage';
import { RawLogFilter } from 'js/project/triggers/common/logUtil';

import { getBaasParamsFromState } from 'js/project/applications/helpers/getBaasParams';
import applicationsService from 'js/project/applications/services/applicationsService';
import { readAndWriteDefaultRule } from 'js/project/dataAPI/constants';
import { dataAPILogItemFromRaw } from 'js/project/dataAPI/converters';
import { isCluster } from 'js/project/dataAPI/utils';

import { getActiveGroupId } from '../app';
import { getBaasAppId, getDataAPIState } from './selectors';

interface BaseRequestPayload {
  appId: string;
}

interface CreateAPIKeyPayload extends BaseRequestPayload {
  key: APIKey;
}

interface DeleteAPIKeyPayload extends BaseRequestPayload {
  keyId: string;
}

const REFRESH_TIME = 900000; // 15 mins in ms

const dataAPIAppName = 'data';
const dataAPIAppProduct = 'data-api';

let localStorageInstanceForGroup;
const createLocalStorageDataAPIKeyForGroup = (groupId) => `MMS.DataAPI.${groupId}`;
const getLocalStorageForGroup = (rootState) => {
  const groupId = getActiveGroupId(rootState);
  if (!groupId) {
    return null;
  }

  const key = createLocalStorageDataAPIKeyForGroup(groupId);

  if (!localStorageInstanceForGroup || localStorageInstanceForGroup.key !== key) {
    localStorageInstanceForGroup = createJsonLocalStorage(key);
  }

  return localStorageInstanceForGroup;
};

export enum ActionType {
  // create app
  GetDataAPIAppReq = 'dataApi/GET_DATA_API_APP_REQ',
  GetDataAPIAppRcv = 'dataApi/GET_DATA_API_APP_RCV',
  GetDataAPIAppErr = 'dataApi/GET_DATA_API_APP_ERR',
  CreateDataAPIAppReq = 'dataAPI/CREATE_DATA_API_APP_REQ',
  CreateDataAPIAppRcv = 'dataAPI/CREATE_DATA_API_APP_RCV',
  CreateDataAPIAppErr = 'dataAPI/CREATE_DATA_API_APP_ERR',

  // metrics
  GetDataAPIAppMetrics = 'dataAPI/GET_DATA_API_APP_METRICS',
  SyncFromLocalStorage = 'dataAPI/SYNC_FROM_LOCAL_STORAGE',

  // API keys
  GetAPIKeys = 'dataAPI/GET_API_KEYS',
  CreateAPIKey = 'dataAPI/CREATE_API_KEY',
  DeleteAPIKey = 'dataAPI/DELETE_API_KEY',

  // logs
  LoadDataAPILogsReq = 'dataAPI/LOAD_DATA_API_LOGS_REQ',
  LoadDataAPILogsRcv = 'dataAPI/LOAD_DATA_API_LOGS_RCV',
  LoadDataAPILogsErr = 'dataAPI/LOAD_DATA_API_LOGS_ERR',

  // default rules
  LoadDefaultRulesReq = 'dataAPI/LOAD_DATA_SOURCE_DEFAULT_RULES_REQ',
  LoadDefaultRulesRcv = 'dataAPI/LOAD_DATA_SOURCE_DEFAULT_RULES_RCV',
  LoadDefaultRulesErr = 'dataAPI/LOAD_DATA_SOURCE_DEFAULT_RULES_ERR',
  SetDataSourceDefaultRuleErr = 'dataAPI/SET_DATA_SOURCE_DEFAULT_RULE_ERR',
  SetDataSourceDefaultRuleRcv = 'dataAPI/SET_DATA_SOURCE_DEFAULT_RULE_RCV',
  GetPresetRoleConfigs = 'dataAPI/GET_PRESET_ROLE_CONFIGS',

  // rules
  LoadDataSourceRulesReq = 'dataAPI/LOAD_DATA_SOURCE_RULES_REQ',
  LoadDataSourceRulesRcv = 'dataAPI/LOAD_DATA_SOURCE_RULES_RCV',
  LoadDataSourceRulesErr = 'dataAPI/LOAD_DATA_SOURCE_RULES_ERR',
  DeleteDataSourceRulesRcv = 'dataAPI/DELETE_DATA_SOURCE_RULES_RCV',
  DeleteDataSourceRulesErr = 'dataAPI/DELETE_DATA_SOURCE_RULES_ERR',

  // versions
  GetVersions = 'dataAPI/GET_VERSIONS',

  // config
  GetDataAPIConfigReq = 'dataAPI/GET_DATA_API_CONFIG_REQ',
  GetDataAPIConfigRcv = 'dataAPI/GET_DATA_API_CONFIG_RCV',
  GetDataAPIConfigErr = 'dataAPI/GET_DATA_API_CONFIG_ERR',

  // auth providers
  GetAuthProvidersReq = 'dataAPI/GET_AUTH_PROVIDERS_REQ',
  GetAuthProvidersRcv = 'dataAPI/GET_AUTH_PROVIDERS_RCV',
  GetAuthProvidersErr = 'dataAPI/GET_AUTH_PROVIDERS_ERR',

  // app settings
  GetAppSPASettingsGlobal = 'dataAPI/GET_APP_SPA_SETTINGS_GLOBAL',

  // provider regions
  GetNearestProviderRegionReq = 'dataAPI/GET_NEAREST_PROVIDER_REGION_REQ',
  GetNearestProviderRegionRcv = 'dataAPI/GET_NEAREST_PROVIDER_REGION_RCV',
  GetNearestProviderRegionErr = 'dataAPI/GET_NEAREST_PROVIDER_REGION_ERR',
}

interface GetDataAPIAppReqAction {
  type: ActionType.GetDataAPIAppReq;
}

interface GetDataAPIAppRcvAction {
  type: ActionType.GetDataAPIAppRcv;
  payload: any; // baasApp
}

interface GetDataAPIAppErrAction {
  type: ActionType.GetDataAPIAppErr;
  error: {
    message: string;
  };
}

interface CreateDataAPIAppReqAction {
  type: ActionType.CreateDataAPIAppReq;
}

interface CreateDataAPIAppRcvAction {
  type: ActionType.CreateDataAPIAppRcv;
  payload: DataAPIConfig;
}

interface CreateDataAPIAppErrAction {
  type: ActionType.CreateDataAPIAppErr;
  error: {
    message: string;
  };
}

interface GetDataAPIAppMetricsAction {
  type: ActionType.GetDataAPIAppMetrics;
  payload: any;
}

interface SyncFromLocalStorageAction {
  type: ActionType.SyncFromLocalStorage;
  payload: any;
}

interface GetAPIKeysAction {
  type: ActionType.GetAPIKeys;
  payload: Array<PartialAPIKey>;
}

interface CreateAPIKeyAction {
  type: ActionType.CreateAPIKey;
  payload: PartialAPIKey;
}

interface DeleteAPIKeyAction {
  type: ActionType.DeleteAPIKey;
  payload: string;
}

interface LoadDataAPILogsReqAction {
  type: ActionType.LoadDataAPILogsReq;
}

interface LoadDataAPILogsRcvAction {
  type: ActionType.LoadDataAPILogsRcv;
  payload: {
    logs: Array<DataAPILogItem>;
    paginationNextEndTime: Date;
    paginationCurrentSkip?: number;
  };
}

interface LoadDataAPILogsErrAction {
  type: ActionType.LoadDataAPILogsErr;
  error: {
    message: string;
  };
}

interface LoadDefaultRulesReqAction {
  type: ActionType.LoadDefaultRulesReq;
}

interface LoadDefaultRulesRcvAction {
  type: ActionType.LoadDefaultRulesRcv;
  payload: Map<string, MongoDBBaseRule>;
}

interface LoadDefaultRulesErrAction {
  type: ActionType.LoadDefaultRulesErr;
  error: {
    message: string;
  };
}

interface LoadDataSourceRulesReqAction {
  type: ActionType.LoadDataSourceRulesReq;
}

interface LoadDataSourceRulesRcvAction {
  type: ActionType.LoadDataSourceRulesRcv;
  payload: Map<string, Array<MongoDBNamespaceRule>>;
}

interface LoadDataSourceRulesErrAction {
  type: ActionType.LoadDataSourceRulesErr;
  error: {
    message: string;
  };
}

interface DeleteDataSourceRulesRcvAction {
  type: ActionType.DeleteDataSourceRulesRcv;
  payload: string;
}

interface DeleteDataSourceRulesErrAction {
  type: ActionType.DeleteDataSourceRulesErr;
  error: {
    message: string;
  };
}

interface SetDataSourceDefaultRuleErrAction {
  type: ActionType.SetDataSourceDefaultRuleErr;
  error: {
    message: string;
  };
}

interface SetDataSourceDefaultRuleRcvAction {
  type: ActionType.SetDataSourceDefaultRuleRcv;
  payload: {
    name: string;
    rule: MongoDBBaseRule;
  };
}

interface GetVersionsAction {
  type: ActionType.GetVersions;
  payload: Array<string>;
}

interface GetNearestProviderRegionReqAction {
  type: ActionType.GetNearestProviderRegionReq;
}

interface GetNearestProviderRegionRcvAction {
  type: ActionType.GetNearestProviderRegionRcv;
  payload: ProviderRegion;
}

interface GetNearestProviderRegionErrAction {
  type: ActionType.GetNearestProviderRegionErr;
}

interface GetPresetRoleConfigsAction {
  type: ActionType.GetPresetRoleConfigs;
  payload: Array<PresetRole>;
}

interface GetDataAPIConfigReqAction {
  type: ActionType.GetDataAPIConfigReq;
}

interface GetDataAPIConfigRcvAction {
  type: ActionType.GetDataAPIConfigRcv;
  payload: DataAPIConfig;
}

interface GetDataAPIConfigErrAction {
  type: ActionType.GetDataAPIConfigErr;
  error: {
    message: string;
  };
}

interface GetAuthProvidersReqAction {
  type: ActionType.GetAuthProvidersReq;
}

interface GetAuthProvidersRcvAction {
  type: ActionType.GetAuthProvidersRcv;
  payload: Array<PartialAuthProviderConfig>;
}

interface GetAuthProvidersErrAction {
  type: ActionType.GetAuthProvidersErr;
  error: {
    message: string;
  };
}

interface GetAppSPASettingsGlobalAction {
  type: ActionType.GetAppSPASettingsGlobal;
  payload: any; //App Settings
}

export type DataAPIAction =
  | GetDataAPIAppReqAction
  | GetDataAPIAppRcvAction
  | GetDataAPIAppErrAction
  | CreateDataAPIAppReqAction
  | CreateDataAPIAppRcvAction
  | CreateDataAPIAppErrAction
  | GetDataAPIAppMetricsAction
  | SyncFromLocalStorageAction
  | GetAPIKeysAction
  | CreateAPIKeyAction
  | DeleteAPIKeyAction
  | LoadDataAPILogsReqAction
  | LoadDataAPILogsRcvAction
  | LoadDataAPILogsErrAction
  | LoadDefaultRulesReqAction
  | LoadDefaultRulesRcvAction
  | LoadDefaultRulesErrAction
  | LoadDataSourceRulesReqAction
  | LoadDataSourceRulesRcvAction
  | LoadDataSourceRulesErrAction
  | SetDataSourceDefaultRuleErrAction
  | SetDataSourceDefaultRuleRcvAction
  | DeleteDataSourceRulesRcvAction
  | DeleteDataSourceRulesErrAction
  | GetVersionsAction
  | GetPresetRoleConfigsAction
  | GetDataAPIConfigReqAction
  | GetDataAPIConfigRcvAction
  | GetDataAPIConfigErrAction
  | GetAuthProvidersReqAction
  | GetAuthProvidersRcvAction
  | GetAuthProvidersErrAction
  | GetAppSPASettingsGlobalAction
  | GetNearestProviderRegionReqAction
  | GetNearestProviderRegionRcvAction
  | GetNearestProviderRegionErrAction;

// Action Creators
const loadApp = async (loadFn, dispatch, getState) => {
  dispatch({
    type: ActionType.GetDataAPIAppReq,
  });

  try {
    const baasApps = await loadFn(getBaasParamsFromState(getState()));
    const dataAPIApp = baasApps.find((app: any) => app.getProduct() === 'data-api');
    dispatch({
      type: ActionType.GetDataAPIAppRcv,
      payload: dataAPIApp,
    });
    return dataAPIApp;
  } catch (error) {
    dispatch({
      type: ActionType.GetDataAPIAppErr,
      error,
    });
    return;
  }
};

const reloadDataAPIApp = () => (dispatch, getState) => {
  return loadApp(applicationsService.reloadDataAPIApplication, dispatch, getState);
};

export const loadDataAPIApp = () => (dispatch, getState) => {
  return loadApp(applicationsService.loadDataAPIApplication, dispatch, getState);
};

export const enableDataAPIIfNecessary =
  (
    dataSources: Array<MongoServiceConfig>,
    deploymentModel: DeploymentModel,
    deploymentRegionLocation: Location,
    deploymentProviderRegion: ProviderRegion
  ) =>
  async (dispatch, getState) => {
    dispatch({
      type: ActionType.CreateDataAPIAppReq,
    });

    let { baasApp, config, defaultRuleByDataSourceName } = getDataAPIState(getState());

    // If the data app doesn't already exist, create it
    if (!baasApp) {
      try {
        await applicationsService.createApplication(
          dataAPIAppName,
          deploymentModel,
          deploymentRegionLocation,
          deploymentProviderRegion,
          getBaasParamsFromState(getState()),
          dataAPIAppProduct
        );
      } catch (error) {
        dispatch({
          type: ActionType.CreateDataAPIAppErr,
          error,
        });
        return;
      }

      try {
        await dispatch(reloadDataAPIApp());
        baasApp = getDataAPIState(getState()).baasApp;
      } catch (error) {
        dispatch({
          type: ActionType.CreateDataAPIAppErr,
          error,
        });
        return;
      }
    }

    const baasParams = getBaasParamsFromState(getState());
    const baasAppId = getDataAPIState(getState()).baasApp.getAppId();
    const versions = getDataAPIState(getState()).versions;

    // create the Data API config if it doesn't already exist
    try {
      if ((config?.versions?.length ?? 0) === 0) {
        config = await applicationsService.createDataAPIConfig(
          baasParams,
          baasAppId,
          new DataAPIConfig({
            versions: [versions[versions.length - 1]],
            disabled: false,
            returnType: EndpointReturnType.JSON,
            endpointValidationMethod: EndpointValidationMethod.NoValidation,
          })
        );
      }
    } catch (error) {
      dispatch({
        type: ActionType.CreateDataAPIAppErr,
        error,
      });
      return;
    }

    // Create the api key provider if it doesn't already exist
    try {
      const authProviders = getDataAPIState(getState()).authProviders;
      if (!authProviders.some((provider) => provider.type === AuthProviderType.APIKey)) {
        const apiKeyProvider = await applicationsService.enableAPIKeyProvider(baasParams, baasAppId);
        dispatch({
          type: ActionType.GetAuthProvidersRcv,
          payload: [apiKeyProvider],
        });
      }
    } catch (error) {
      dispatch({
        type: ActionType.CreateDataAPIAppErr,
        error,
      });
      return;
    }

    // Link clusters and create default rules for each connected data source
    try {
      const mongoServices: Array<MongoService> = await applicationsService.getMongoServices(baasParams, baasAppId);

      const createDataSourceRequests: Array<() => Promise<any>> = [];
      dataSources.forEach((dataSource) => {
        if (
          isCluster(dataSource) &&
          !mongoServices.some((mongoService: MongoService) => mongoService.name === dataSource.clusterName)
        ) {
          const clusterDataSource = dataSource as ClusterServiceConfig;
          createDataSourceRequests.push(() =>
            applicationsService.createAndLinkClusterService({
              ...getBaasParamsFromState(getState()),
              appId: baasAppId,
              clusterName: clusterDataSource.clusterName,
              serviceName: clusterDataSource.clusterName,
            })
          );
        }
      });

      await Promise.all(
        createDataSourceRequests.map(async (dataSourceRequest) => {
          const service = await dataSourceRequest();
          if (!defaultRuleByDataSourceName.has(service.name)) {
            const defaultRule: MongoDBBaseRule = await applicationsService.createServiceDefaultRule(
              getBaasParamsFromState(getState()),
              baasAppId,
              service._id,
              readAndWriteDefaultRule
            );
            if (defaultRule?.id) {
              defaultRuleByDataSourceName.set(service.name, defaultRule);
            }
          }
        })
      );
    } catch (error) {
      dispatch({
        type: ActionType.CreateDataAPIAppErr,
        error,
      });
    }

    dispatch({
      type: ActionType.LoadDefaultRulesRcv,
      payload: defaultRuleByDataSourceName,
    });

    dispatch({
      type: ActionType.CreateDataAPIAppRcv,
      payload: config,
    });
  };

export const loadDataSourcesDefaultRule = (dataSources: Array<MongoService>) => async (dispatch, getState) => {
  dispatch({
    type: ActionType.LoadDefaultRulesReq,
  });

  const defaultRuleByDataSourceName = new Map<string, MongoDBBaseRule>();

  const baasAppId = getDataAPIState(getState()).baasApp.getAppId();
  const baasParams = getBaasParamsFromState(getState());
  await Promise.all(
    dataSources.map(async (dataSource) => {
      try {
        const defaultRule: MongoDBBaseRule = await applicationsService.getServiceDefaultRule(
          baasParams,
          baasAppId,
          dataSource._id
        );
        defaultRuleByDataSourceName.set(dataSource.name, defaultRule);
      } catch (error) {
        // suppress 404s for default rule
        if (error.response?.status !== 404) {
          dispatch({
            type: ActionType.LoadDefaultRulesErr,
            error,
          });
        }
      }
    })
  );

  dispatch({
    type: ActionType.LoadDefaultRulesRcv,
    payload: defaultRuleByDataSourceName,
  });

  const localStorageForGroup = getLocalStorageForGroup(getState());
  const data = getDataAPIState(getState());
  localStorageForGroup.saveData(data);
};

export const loadDataSourceRules = (dataSources: Array<MongoService>) => async (dispatch, getState) => {
  dispatch({
    type: ActionType.LoadDataSourceRulesReq,
  });

  const rulesByDataSourceName = new Map<string, Array<MongoDBNamespaceRule>>();
  const baasAppId = getDataAPIState(getState()).baasApp.getAppId();
  const baasParams = getBaasParamsFromState(getState());

  await Promise.all(
    dataSources.map(async (dataSource) => {
      try {
        const rules = await applicationsService.getDataSourceRules(baasParams, baasAppId, dataSource._id);
        rulesByDataSourceName.set(dataSource.name, rules);
      } catch (error) {
        dispatch({
          type: ActionType.LoadDataSourceRulesErr,
          error,
        });
      }
    })
  );

  dispatch({
    type: ActionType.LoadDataSourceRulesRcv,
    payload: rulesByDataSourceName,
  });

  const localStorageForGroup = getLocalStorageForGroup(getState());
  const data = getDataAPIState(getState());
  localStorageForGroup.saveData(data);
};

export const linkDataSourceAndCreateDefaultRule =
  (dataSourceName: string, presetRole: PresetRole) => async (dispatch, getState) => {
    const baasAppId = getDataAPIState(getState()).baasApp.getAppId();
    const rule = new MongoDBBaseRule({
      roles: presetRole?.roles,
    });

    let createdRule: MongoDBBaseRule;
    try {
      const baasParams = getBaasParamsFromState(getState());
      const dataSource = await applicationsService.createAndLinkClusterService({
        ...baasParams,
        appId: baasAppId,
        clusterName: dataSourceName,
        serviceName: dataSourceName,
      });

      createdRule = await applicationsService.createServiceDefaultRule(baasParams, baasAppId, dataSource._id, rule);
    } catch (error) {
      dispatch({
        type: ActionType.SetDataSourceDefaultRuleErr,
        error,
      });
      return;
    }

    dispatch({
      type: ActionType.SetDataSourceDefaultRuleRcv,
      payload: { name: dataSourceName, rule: createdRule },
    });
  };

export const deleteDataSourceRules = (serviceId: string, serviceName: string) => async (dispatch, getState) => {
  const baasAppId = getDataAPIState(getState()).baasApp.getAppId();
  try {
    await applicationsService.deleteDataSourceRules(getBaasParamsFromState(getState()), baasAppId, serviceId);
  } catch (error) {
    // suppress 404s for no namespace rules
    if (error.response?.status !== 404) {
      dispatch({
        type: ActionType.DeleteDataSourceRulesErr,
        error,
      });
      return;
    }
  }

  dispatch({
    type: ActionType.DeleteDataSourceRulesRcv,
    payload: serviceName,
  });
};

export const updateDataSourceDefaultRule =
  (dataSource: MongoService, presetRole: PresetRole) => async (dispatch, getState) => {
    const baasAppId = getDataAPIState(getState()).baasApp.getAppId();
    const ruleId = getDataAPIState(getState()).defaultRuleByDataSourceName.get(dataSource?.name)?.id;
    const rule = new MongoDBBaseRule({
      id: ruleId,
      roles: presetRole.roles,
    });

    try {
      await applicationsService.updateServiceDefaultRule(
        getBaasParamsFromState(getState()),
        baasAppId,
        dataSource?._id,
        rule
      );
    } catch (error) {
      dispatch({
        type: ActionType.SetDataSourceDefaultRuleErr,
        error,
      });
      return;
    }

    dispatch({
      type: ActionType.SetDataSourceDefaultRuleRcv,
      payload: { name: dataSource?.name, rule: rule },
    });
  };

export const createDataSourceDefaultRule =
  (dataSource: MongoService, presetRole: PresetRole) => async (dispatch, getState) => {
    const baasAppId = getDataAPIState(getState()).baasApp.getAppId();
    const rule = new MongoDBBaseRule({
      roles: presetRole.roles,
    });

    try {
      await applicationsService.createServiceDefaultRule(
        getBaasParamsFromState(getState()),
        baasAppId,
        dataSource?._id,
        rule
      );
    } catch (error) {
      dispatch({
        type: ActionType.SetDataSourceDefaultRuleErr,
        error,
      });
      return;
    }

    dispatch({
      type: ActionType.SetDataSourceDefaultRuleRcv,
      payload: { name: dataSource?.name, rule: rule },
    });
  };

export const loadDataAPIMetrics = (groupId, appId, start, end, granularity) => async (dispatch, getState) => {
  const lastRefresh = getDataAPIState(getState()).metrics.lastRefresh;
  let differenceInMillis;
  if (lastRefresh) {
    differenceInMillis = new Date().valueOf() - new Date(lastRefresh).valueOf();
  }

  if (!lastRefresh || differenceInMillis > REFRESH_TIME) {
    const appMetrics = await applicationsService.getMetrics(
      getBaasParamsFromState(getState()),
      appId,
      start,
      end,
      granularity
    );

    dispatch({
      type: ActionType.GetDataAPIAppMetrics,
      payload: appMetrics.measurements,
      groupId,
    });

    const localStorageForGroup = getLocalStorageForGroup(getState());
    const data = getDataAPIState(getState());
    localStorageForGroup.saveData(data);
  }
};

export const loadDataAPILogs = (appId: string, filter: RawLogFilter) => async (dispatch: Dispatch, getState) => {
  dispatch({
    type: ActionType.LoadDataAPILogsReq,
  });

  let payload;
  try {
    payload = await applicationsService.getLogs(getBaasParamsFromState(getState()), appId, filter);
  } catch (error) {
    dispatch({
      type: ActionType.LoadDataAPILogsErr,
      error,
    });
    return;
  }

  const logs = payload.logs;
  const paginationNextEndTime = payload.nextEndDate ? new Date(payload.nextEndDate) : undefined;
  const paginationCurrentSkip = filter.skip;

  const { groupId } = getBaasParamsFromState(getState());
  dispatch({
    type: ActionType.LoadDataAPILogsRcv,
    payload: {
      logs: logs.map((logItem) => dataAPILogItemFromRaw(logItem, groupId, appId)),
      paginationCurrentSkip,
      paginationNextEndTime,
    },
  });
};

export const syncFromLocalStorage = () => (dispatch, getState) => {
  const localStorageForGroup = getLocalStorageForGroup(getState());
  const localStorageData = { ...localStorageForGroup.getData() };
  if (!localStorageData?.metrics && !localStorageData?.serviceDefaultRules) {
    return;
  }

  dispatch({
    type: ActionType.SyncFromLocalStorage,
    payload: localStorageData,
  });
};

export const getAPIKeys =
  ({ appId }: BaseRequestPayload) =>
  async (dispatch: Dispatch, getState) => {
    const payload: Array<PartialAPIKey> = await applicationsService.getAPIKeys(
      getBaasParamsFromState(getState()),
      appId
    );
    dispatch({
      type: ActionType.GetAPIKeys,
      payload,
    });
    return payload;
  };

export const createAPIKey =
  ({ appId, key }: CreateAPIKeyPayload) =>
  async (dispatch: Dispatch, getState) => {
    const payload: PartialAPIKey = await applicationsService.createAPIKey(
      getBaasParamsFromState(getState()),
      appId,
      key
    );
    dispatch({
      type: ActionType.CreateAPIKey,
      payload,
    });
    return payload;
  };

export const deleteAPIKey =
  ({ appId, keyId }: DeleteAPIKeyPayload) =>
  async (dispatch: Dispatch, getState) => {
    await applicationsService.deleteAPIKey(getBaasParamsFromState(getState()), appId, keyId);
    dispatch({
      type: ActionType.DeleteAPIKey,
      payload: keyId,
    });
  };

export const getLinkedDataSources = () => async (dispatch, getState) => {
  const baasAppId = getBaasAppId(getState());
  const baasParamsFromState = getBaasParamsFromState(getState());
  const mongoServices = await applicationsService.getMongoServices(baasParamsFromState, baasAppId);
  const loadedClusterDescriptions = await dispatch(loadClusterDescriptions(baasParamsFromState.groupId));
  const clusterDescriptions = loadedClusterDescriptions.payload;

  return { mongoServices, clusterDescriptions };
};

export const linkDataAPIDataSource = (dataSourceName: string) => async (_dispatch, getState) => {
  const baasAppId = getBaasAppId(getState());

  // Note: We assume the service created will have the same name as the data source
  // this can cause name conflicts once we support data lakes since they can have the same name
  const payload = await applicationsService.createAndLinkClusterService({
    ...getBaasParamsFromState(getState()),
    appId: baasAppId,
    clusterName: dataSourceName,
    serviceName: dataSourceName,
  });

  return payload;
};

export const unlinkDataAPIDataSource = (dataSource) => async (_dispatch, getState) => {
  const baasAppId = getBaasAppId(getState());
  const payload = await applicationsService.unlinkApplication(
    getBaasParamsFromState(getState()),
    baasAppId,
    dataSource._id
  );

  return payload;
};

export const getClusterDescriptions = () => async (dispatch, getState) => {
  const baasParamsFromState = getBaasParamsFromState(getState());
  const clusterDescriptions = await dispatch(loadClusterDescriptions(baasParamsFromState.groupId));
  return clusterDescriptions.payload;
};

export const getDataLakes = () => async (dispatch, getState) => {
  const dataLakes = await dispatch(loadDataLakesWithStorage());
  return dataLakes.payload;
};

export const listSvcNamespaces = (svcId) => async (dispatch, getState) => {
  const baasAppId = getBaasAppId(getState());
  const payload = await applicationsService.listSvcNamespaces(getBaasParamsFromState(getState()), baasAppId, svcId);
  return payload;
};

export const getDataSourceDefaultRule = (serviceId) => async (dispatch, getState) => {
  const payload = await applicationsService.getServiceDefaultRule(
    getBaasParamsFromState(getState()),
    getBaasAppId(getState()),
    serviceId
  );

  return payload;
};

export const loadPresetRoleConfigs = () => async (dispatch, getState) => {
  const payload = await applicationsService.getPresetRoleConfigs(getBaasParamsFromState(getState()));

  dispatch({
    type: ActionType.GetPresetRoleConfigs,
    payload,
  });
};

export const loadDataAPIVersions = () => async (dispatch, getState) => {
  const { groupId, baasUrl, username } = getBaasParamsFromState(getState());
  const versions = await applicationsService.getDataAPIVersions(groupId, baasUrl, username);

  dispatch({
    type: ActionType.GetVersions,
    payload: versions,
  });
};

export const getNearestProviderRegion = () => async (dispatch, getState) => {
  const { groupId, baasUrl, username } = getBaasParamsFromState(getState());
  dispatch({
    type: ActionType.GetNearestProviderRegionReq,
  });

  try {
    const { nearestProviderRegion } = await applicationsService.getNearestProviderRegion(groupId, baasUrl, username);

    dispatch({
      type: ActionType.GetNearestProviderRegionRcv,
      payload: nearestProviderRegion,
    });
  } catch (error) {
    dispatch({
      type: ActionType.GetNearestProviderRegionErr,
    });
  }
};

export const loadDataAPIConfig = (appId: string) => async (dispatch, getState) => {
  dispatch({
    type: ActionType.GetDataAPIConfigReq,
  });

  try {
    const payload = await applicationsService.getDataAPIConfig(getBaasParamsFromState(getState()), appId);

    dispatch({
      type: ActionType.GetDataAPIConfigRcv,
      payload,
    });

    return payload;
  } catch (error) {
    dispatch({
      type: ActionType.GetDataAPIConfigErr,
      error,
    });
  }
};

export const loadAuthProviders = (appId: string) => async (dispatch, getState) => {
  dispatch({
    type: ActionType.GetAuthProvidersReq,
  });

  try {
    const payload = await applicationsService.getAuthProviders(getBaasParamsFromState(getState()), appId);

    dispatch({
      type: ActionType.GetAuthProvidersRcv,
      payload,
    });
  } catch (error) {
    dispatch({
      type: ActionType.GetAuthProvidersErr,
      error,
    });
  }
};

export const getAppSPASettingsGlobal = () => async (dispatch, getState) => {
  const payload = await applicationsService.getAppSPASettingsGlobal(getBaasParamsFromState(getState()));

  dispatch({
    type: ActionType.GetAppSPASettingsGlobal,
    payload,
  });
};

export const updateDataAPIConfig = (appId: string, config: DataAPIConfig) => async (dispatch, getState) => {
  await applicationsService.updateDataAPIConfig(getBaasParamsFromState(getState()), appId, config);
};
