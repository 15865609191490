export interface DeviceCodeState {
  deviceCodeStatus: 'LOADING' | 'INPUT' | 'CONFIRMING' | 'CONFIRMED';
  deviceCodeId: string;
  userCode: string;
  sessionId: string;
  sourceIp: string;
  sourceUserAgent: string;
  createdAt: Date | string;
  error?: any;
}

export interface DeviceCodeActions {
  type: 'onLoad' | 'onPostComplete' | 'onConfirm' | 'onBack' | 'onCompleteError';
  payload?: any;
}

export default function deviceCodeReducer(state: DeviceCodeState, action: DeviceCodeActions): DeviceCodeState {
  const { type, payload } = action;
  switch (type) {
    case 'onLoad':
      return {
        ...state,
        deviceCodeStatus: 'INPUT',
      };
    case 'onPostComplete':
      return {
        ...state,
        userCode: payload.userCode,
        deviceCodeId: payload.deviceCodeId,
        sessionId: payload.sessionId,
        sourceIp: payload.sourceIp,
        sourceUserAgent: payload.sourceUserAgent,
        createdAt: payload.createdAt,
        error: undefined,
        deviceCodeStatus: 'CONFIRMING',
      };
    case 'onConfirm':
      return {
        ...state,
        deviceCodeStatus: 'CONFIRMED',
      };
    case 'onBack':
      return {
        ...state,
        deviceCodeStatus: 'INPUT',
      };
    case 'onCompleteError':
      return {
        ...state,
        userCode: '',
        error: payload.error,
        deviceCodeStatus: 'INPUT',
      };
    default:
      return state;
  }
}
