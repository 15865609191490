import styled from '@emotion/styled';
import { AtlasForGovernmentLogoLockup, MongoDBLogo } from '@leafygreen-ui/logo';

import { useRequestParams } from 'js/common/context/RequestParamsContext';

import colors from 'js/auth/utils/palette';

const LOGO_HEIGHT = 38;

const logos = {
  mdb_logo: <MongoDBLogo height={LOGO_HEIGHT} />,
  mdb_logo_gov: <AtlasForGovernmentLogoLockup height={LOGO_HEIGHT} />,
};

const LogoContainer = styled.div((props: HeaderLogoProps) => ({
  display: 'flex',
  flexWrap: 'wrap',
  width: props.isCenter ? 'inherit' : '300px',
  justifyContent: props.isCenter ? 'center' : 'start',
}));

const OnPremVersion = styled.div`
  height: 16px;
  font-size: 11px;
  font-weight: bold;
  margin: 12px 0 0 4px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: ${colors.logoText};
  text-transform: uppercase;
`;

export interface HeaderLogoProps {
  isCenter?: boolean;
  className?: string;
}

export default function HeaderLogo({ isCenter = false, className }: HeaderLogoProps) {
  const { onPrem, onPremVersion, authLogoFileName } = useRequestParams();

  return (
    <LogoContainer isCenter={isCenter} data-testid="headerLogo" className={className}>
      {logos[authLogoFileName]}
      {onPrem && <OnPremVersion>{`OPS MANAGER ${onPremVersion}`}</OnPremVersion>}
    </LogoContainer>
  );
}
