import fetchWrapper from '../fetchWrapper';

export function getUnacknowledgedTenantUpgradeFailure(groupId: string, clusterName: string) {
  return fetchWrapper(`/nds/clusterUpgrade/${groupId}/${clusterName}/unacknowledgedFailures`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function markTenantUpgradeFailuresAcknowledged(groupId: string, clusterName: string) {
  return fetchWrapper(`/nds/clusterUpgrade/${groupId}/${clusterName}/unacknowledgedFailures`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  }).then((resp) => resp.json());
}
