import { ClusterDescription } from '@packages/types/nds/clusterDescription';

import fetchWrapper from '../fetchWrapper';

export default {
  getProviderOptions(groupId: string) {
    return fetchWrapper(`/nds/serverless/${groupId}/options`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },
  getServerlessInstanceByName(groupId: string, serverlessInstanceName: string) {
    return fetchWrapper(`/nds/serverless/${groupId}/${serverlessInstanceName}`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },
  getTenantUpgradeFeatureUsage(groupId: string, clusterDescription: ClusterDescription) {
    return fetchWrapper(`/nds/clusterUpgradeToServerless/${groupId}/getTenantUpgradeFeatureUsage`, {
      method: 'POST',
      body: JSON.stringify(clusterDescription),
    }).then((resp) => resp.json());
  },
  getTenantUpgradeChartsFeatureUsage(groupId: string, deploymentName: string) {
    return fetchWrapper(`/nds/clusterUpgradeToServerless/${groupId}/${deploymentName}/getTenantUpgradeChartsUsage`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },
  getTenantUpgradeToServerlessStatusIsComplete(groupId: string, serverlessInstanceName: string) {
    return fetchWrapper(`/nds/clusterUpgradeToServerless/${groupId}/${serverlessInstanceName}/isComplete`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },
  getContinuousDeliveryMongoDBVersion(groupId: string) {
    return fetchWrapper(`/v2/${groupId}/continuousDeliveryVersion`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },
};
