import { DataFederationQueryLimit, LimitSpan, TenantName } from '@packages/types/nds/dataFederationQueryLimits';

import fetchWrapper from 'js/common/services/api/fetchWrapper';

function getBasePath(groupId: string) {
  return `/nds/dataLakes/${groupId}/queryLimits`;
}
function getResourcePath(groupId: string, tenantName: TenantName, limitSpan: LimitSpan) {
  const pathByResource = !tenantName ? `/${limitSpan}` : `/tenants/${tenantName}/${limitSpan}`;
  return `${getBasePath(groupId)}${pathByResource}`;
}

async function configureQueryLimit(
  groupId: string,
  queryLimit: DataFederationQueryLimit
): Promise<DataFederationQueryLimit> {
  const resp = await fetchWrapper(getResourcePath(groupId, queryLimit.tenantName, queryLimit.limitSpan), {
    method: 'PATCH',
    body: JSON.stringify(queryLimit),
  });
  return resp.json();
}

async function getQueryLimits(groupId: string): Promise<Array<DataFederationQueryLimit>> {
  const resp = await fetchWrapper(getBasePath(groupId), {
    method: 'GET',
  });
  return resp.json();
}

async function deleteQueryLimit(groupId: string, tenantName: TenantName, limitSpan: LimitSpan): Promise<void> {
  await fetchWrapper(getResourcePath(groupId, tenantName, limitSpan), {
    method: 'DELETE',
  });
}

export { configureQueryLimit, getQueryLimits, deleteQueryLimit };
