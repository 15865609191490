import { Component, FormEvent } from 'react';

import Button from '@leafygreen-ui/button';
import classNames from 'classnames';
import _ from 'underscore';

import { WithDefaultProps } from '@packages/types/withDefaultProps';

import * as api from 'js/common/services/api';

interface OwnProps {
  onSuccess?: () => void;
  checkPassword?: typeof api.user.checkPassword;
}

interface State {
  showSpinner: boolean;
  invalidPassword: boolean;
  password: string;
}

const defaultProps = {
  onSuccess: _.noop,
  checkPassword: api.user.checkPassword,
};

type Props = WithDefaultProps<OwnProps, typeof defaultProps>;

class PasswordAuth extends Component<Props, State> {
  static defaultProps = defaultProps;

  state = {
    showSpinner: false,
    invalidPassword: false,
    password: '',
  };

  onSubmit = async (e: FormEvent) => {
    e.preventDefault();

    this.setState({
      showSpinner: true,
      invalidPassword: false,
    });

    try {
      await this.props.checkPassword({ password: this.state.password });
      this.setState({
        showSpinner: false,
      });
      this.props.onSuccess();
    } catch ({ errorCode }) {
      this.setState({
        showSpinner: false,
        invalidPassword: errorCode === 'INVALID_PASSWORD' || errorCode === 'MISSING_CREDENTIALS',
      });
    }
  };

  render() {
    const { showSpinner, password, invalidPassword } = this.state;

    return (
      <form className="auth-expired auth-expired-row" onSubmit={this.onSubmit}>
        <div className="auth-expired-description">
          <p>
            <b>Password verification is required.</b>
            <br />
            Please enter your password.
          </p>
        </div>
        <div className="auth-expired-actions">
          <div
            className={classNames('auth-expired-input-container form-group has-feedback password-input-container', {
              'has-error': invalidPassword,
            })}
          >
            <input
              type="password"
              className="form-control no-password-reveal"
              id="password"
              name="password"
              placeholder="Enter password"
              value={password}
              onChange={(e) => this.setState({ password: e.target.value })}
            />
            {invalidPassword && (
              <span className="form-control-feedback password-feedback">
                <i className="fa fa-times" />
              </span>
            )}
          </div>{' '}
          <div className="form-group">
            {showSpinner ? (
              <span className="auth-expired-spinner loading-spinner" />
            ) : (
              <Button variant="primary" name="verify" onClick={this.onSubmit}>
                Verify
              </Button>
            )}
          </div>
        </div>
      </form>
    );
  }
}

export default PasswordAuth;
