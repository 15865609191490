import { useEffect, useState } from 'react';

import { css } from '@emotion/react';
import Button from '@leafygreen-ui/button';
import { RouteComponentProps } from 'react-router-dom';

import AuthenticatorSetupCard from '@packages/components/MultiFactorAuth/AuthenticatorSetupCard';
import EmailSetupCard from '@packages/components/MultiFactorAuth/EmailSetupCard';
import OktaVerifySetupCard from '@packages/components/MultiFactorAuth/OktaVerifySetupCard';
import SmsSetupCard from '@packages/components/MultiFactorAuth/SmsSetupCard';
import WebAuthnSetupCard from '@packages/components/MultiFactorAuth/WebAuthnSetupCard';
import useMfaFactors from '@packages/hooks/useMfaFactors';

import * as api from 'js/common/services/api';
import { useAccountUser } from 'js/common/context/AccountUserContext';
import { CloudTeams, sendError } from 'js/common/utils/bugsnag';
import analytics, { SEGMENT_EVENTS } from 'js/common/utils/segmentAnalytics';

import { MfaEncouragementLayout } from './MfaEncouragementLayout';

const AUTH_EXPIRED_PATH = '/account/login?signedOut=true';

const trackUserSetupFactor = async ({
  numOfFactorsBeforeActivation,
  factorName,
  orgId,
}: {
  numOfFactorsBeforeActivation: number;
  factorName: string;
  orgId: string;
}) => {
  const event =
    numOfFactorsBeforeActivation === 0
      ? SEGMENT_EVENTS.MFA_ENCOURAGEMENT_FIRST_FACTOR_SETUP
      : SEGMENT_EVENTS.MFA_ENCOURAGEMENT_SECOND_FACTOR_SETUP;
  return analytics.track(event, {
    factor_name: factorName,
    org_id: orgId,
  });
};

export default function AccountMultiFactorAuthFactorSetupPage({
  windowLocation = window.location,
  location: { search } = { search: '' },
  match: { params } = { params: '' },
}: {
  windowLocation: Pick<Location, 'assign'>;
  location?: Pick<Location, 'search'>;
  match?: RouteComponentProps;
}) {
  const factorSetupParam: string = params.factor;
  const [currentOrgId, setCurrentOrgId] = useState('');
  const [isAccountProfileAppUserSet, setIsAccountProfileAppUserSet] = useState(false);
  const { accountUserParams, loadAccountUserParams } = useAccountUser();
  const { csrfTime, csrfToken, username } = accountUserParams;
  const { factors } = useMfaFactors();

  useEffect(() => {
    const getCurrentOrgId = async () => {
      try {
        const { currentOrgId, userId } = await api.accountProfile.getAppUser();
        setCurrentOrgId(currentOrgId);
        await analytics.updateState({ persistedProperties: { userId } });
      } catch (error) {
        sendError({
          error,
          team: CloudTeams.CoreIam,
        });
      } finally {
        setIsAccountProfileAppUserSet(true);
      }
    };
    void getCurrentOrgId();
  }, []);

  useEffect(() => {
    async function updateCsrfHeaders() {
      await loadAccountUserParams();
    }

    if (csrfTime === '' && csrfToken === '') {
      void updateCsrfHeaders();
    }
  }, [loadAccountUserParams]);

  const onFactorActivationSuccess = async () => {
    await trackUserSetupFactor({
      numOfFactorsBeforeActivation: factors.length,
      factorName: factorSetupParam,
      orgId: currentOrgId,
    });
    windowLocation.assign(`/account/security/mfa/setup${search}`);
  };

  const getTitle = () => {
    let title;

    if (factorSetupParam === 'email') {
      title = 'Set up Email as an MFA method';
    } else if (factorSetupParam === 'sms') {
      title = 'Set up SMS as an MFA method';
    } else if (factorSetupParam === 'oktaVerify') {
      title = 'Set up Okta Verify Mobile App as an MFA method';
    } else if (factorSetupParam === 'webauthn') {
      title = 'Set up Security Key/Biometric as an MFA method';
    } else if (factorSetupParam === 'authenticator') {
      title = 'Set up Authenticator as an MFA method';
    }
    return title;
  };

  const renderFactorSetupCards = () => {
    switch (factorSetupParam) {
      case 'email':
        return (
          <EmailSetupCard
            windowLocation={windowLocation}
            onSuccess={onFactorActivationSuccess}
            authExpiredPath={AUTH_EXPIRED_PATH}
            username={username}
          />
        );
      case 'sms':
        return (
          <SmsSetupCard
            windowLocation={windowLocation}
            onSuccess={onFactorActivationSuccess}
            authExpiredPath={AUTH_EXPIRED_PATH}
          />
        );
      case 'oktaVerify':
        return (
          <OktaVerifySetupCard
            windowLocation={windowLocation}
            onSuccess={onFactorActivationSuccess}
            authExpiredPath={AUTH_EXPIRED_PATH}
            username={username}
          />
        );
      case 'webauthn':
        return (
          <WebAuthnSetupCard
            windowLocation={windowLocation}
            onSuccess={onFactorActivationSuccess}
            authExpiredPath={AUTH_EXPIRED_PATH}
            navigatorCredentials={navigator.credentials}
          />
        );
      case 'authenticator':
        return (
          <AuthenticatorSetupCard
            windowLocation={windowLocation}
            onSuccess={onFactorActivationSuccess}
            authExpiredPath={AUTH_EXPIRED_PATH}
            username={username}
          />
        );
      default:
        return null;
    }
  };

  if (!isAccountProfileAppUserSet) {
    return null;
  }

  return (
    <MfaEncouragementLayout title={getTitle()}>
      <div css={css({ marginTop: 24 })}>{renderFactorSetupCards()}</div>
      <Button
        onClick={() => windowLocation.assign(`/account/security/mfa/setup${search}`)}
        css={css({ marginTop: 24 })}
      >
        Go back
      </Button>
    </MfaEncouragementLayout>
  );
}
