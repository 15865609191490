import {
  AuthProviderConfig,
  BaasAdminClient,
  ErrInvalidSession,
  ErrUIIPRestricted,
  ErrUnauthorized,
} from 'baas-admin-sdk';
import { StitchAdminClientFactory } from 'mongodb-stitch';
import _ from 'underscore';

import * as api from 'js/common/services/api';
import { RequestParams } from 'js/common/context/RequestParamsContext';
import { MongoDataSourceType } from 'js/project/baas/common/types';
import Cache from 'js/project/common/util/Cache';

import StitchApplication from '../models/StitchApplication';
import StitchApplicationCollection from '../models/StitchApplicationCollection';

const allApplications = ['standard', 'atlas', 'data-api'];
const atlasTriggerApplications = ['atlas'];
const dataAPIApplications = ['data-api'];

// Caches
let atlasTriggersApplicationCache;
let dataAPIApplicationCache;
let applicationsCache;

const collections = {
  // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
  [allApplications]: new StitchApplicationCollection(),
  // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
  [atlasTriggerApplications]: new StitchApplicationCollection(),
  // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
  [dataAPIApplications]: new StitchApplicationCollection(),
};

const cachedAdminClientProms: $TSFixMe = {};
const cachedBaasAdminClients: $TSFixMe = {};
const cachedSettings: $TSFixMe = {};

// Data API
const dataAPITemplate = 'data-api-beta';

function settingsAreEqual(newSettings, baasUrl) {
  const props = ['groupId', 'username'];
  const oldProps = _.pick(cachedSettings[baasUrl], props);
  const newProps = _.pick(newSettings, props);
  return _.isEqual(oldProps, newProps);
}

function getApiKey() {
  // todo: refactor centralUrl logic for Stitch API Key in BAAS-19193
  const requestParams: RequestParams = window.REQUEST_PARAMS;
  const centralUrl = requestParams.centralUrl ?? '';
  return api.settings.addTempApiKey('Stitch API Key', centralUrl).then(({ key }) => key);
}

function getBaasAdminClient({ groupId, username, baasUrl }) {
  let cachedBaasAdminClient = cachedBaasAdminClients[baasUrl];
  if (!cachedBaasAdminClient || !cachedSettings || !settingsAreEqual({ groupId, username }, baasUrl)) {
    cachedSettings[baasUrl] = { groupId, username };
    cachedBaasAdminClient = new BaasAdminClient(baasUrl);
    cachedBaasAdminClients[baasUrl] = cachedBaasAdminClient;
  }
  return cachedBaasAdminClient;
}

function getAuthedBaasAdminClient({ groupId, baasUrl, username }) {
  const baasAdminClient = getBaasAdminClient({ groupId, baasUrl, username });
  // Make a request for the user profile to make sure we still have a valid
  // session. If we don't then refresh the session with a new API key
  return baasAdminClient
    .userProfile()
    .catch((error) => {
      // 'InvalidSession' means the existing API key expired
      // 'Unauthorized' means the client has not been authenticated
      // In either case, we want to refresh the client with a new API key
      // 'ErrUIIPRestricted' means the client doesn't have access to the UI for
      // this group from their IP and gets redirected to the organizations page
      if (error.code === ErrInvalidSession || error.code === ErrUnauthorized) {
        return refreshBaasAdminClient({ groupId, baasUrl, username });
      } else if (error.code === ErrUIIPRestricted) {
        window.location.assign('/v2#/preferences/organizations?restrictedOrigin=appServices');
      }
    })
    .then(() => baasAdminClient);
}

function refreshBaasAdminClient({ groupId, username, baasUrl }) {
  return getApiKey()
    .then((apiKey) => {
      const options = { username, apiKey, cors: true, cookie: true };
      // Auth with cookie first so we never end up in a situation where we can perform actions
      // but cannot redirect
      return getBaasAdminClient({ groupId, username, baasUrl })
        .authenticate('mongodb-cloud', options)
        .then(() => apiKey);
    })
    .then((apiKey) =>
      getBaasAdminClient({ groupId, username, baasUrl }).authenticate('mongodb-cloud', { username, apiKey })
    );
}

function getAdminClient({ groupId, username, baasUrl }) {
  let cachedAdminClientProm = cachedAdminClientProms[baasUrl];
  if (!cachedAdminClientProm || !cachedSettings || !settingsAreEqual({ groupId, username }, baasUrl)) {
    cachedSettings[baasUrl] = { groupId, username };
    cachedAdminClientProm = StitchAdminClientFactory.create(baasUrl);
    cachedAdminClientProms[baasUrl] = cachedAdminClientProm;
  }
  return cachedAdminClientProm;
}

function refreshAdminClient({ groupId, username, baasUrl }) {
  return getApiKey()
    .then((apiKey) => {
      const options = { username, apiKey, cors: true, cookie: true };
      // Auth with cookie first so we never end up in a situation where we can perform actions
      // but cannot redirect
      return getAdminClient({ groupId, username, baasUrl })
        .then((adminClient) => adminClient.authenticate('mongodbCloud', options))
        .then(() => apiKey);
    })
    .then((apiKey) =>
      getAdminClient({ groupId, username, baasUrl }).then((adminClient) => {
        const options = { username, apiKey };
        return adminClient.authenticate('mongodbCloud', options);
      })
    );
}

function getAuthedAdminClient({ groupId, baasUrl, username }) {
  const adminClientProm = getAdminClient({ groupId, baasUrl, username });
  // Make a request for the user profile to make sure we still have a valid
  // session. If we don't then refresh the session with a new API key
  return adminClientProm
    .then((adminClient) => adminClient.userProfile())
    .catch((error) => {
      // 'InvalidSession' means the existing API key expired
      // 'Unauthorized' means the client has not been authenticated
      // In either case, we want to refresh the client with a new API key
      // 'ErrUIIPRestricted' means the client doesn't have access to the UI for
      // this group from their IP and gets redirected to the organizations page
      if (error.code === ErrInvalidSession || error.code === ErrUnauthorized) {
        return refreshAdminClient({ groupId, baasUrl, username });
      } else if (error.code === ErrUIIPRestricted) {
        window.location.assign('/v2#/preferences/organizations?restrictedOrigin=appServices');
      }
    })
    .then(() => adminClientProm);
}

function getAtlasServices(services) {
  return services.filter((service) => service.type === MongoDataSourceType.Atlas);
}

const buildApplicationLoader =
  (products) =>
  ({ groupId, baasUrl, username }) => {
    return getAuthedAdminClient({ groupId, baasUrl, username }).then((client) => {
      const adminApps = client.apps(groupId);
      return Promise.all(products.map((product) => adminApps.list({ product }))).then((appsForProduct) => {
        const apps = appsForProduct.reduce((acc, apps) => {
          return acc.concat(apps.map((app, i) => ({ ...app, product: products[i] })));
        }, []);

        const serviceRequests: Array<$TSFixMe> = [];

        apps.forEach((app) => {
          const adminServices = adminApps.app(app._id).services();
          serviceRequests.push(
            adminServices.list().then((services) => {
              return Promise.all(
                getAtlasServices(services).map((atlasService) =>
                  adminServices
                    .service(atlasService._id)
                    .config()
                    .get()
                    .then((config) => ({
                      ...atlasService,
                      config,
                    }))
                )
              ).then((atlasServicesWithConfig) => {
                app.atlasServices = atlasServicesWithConfig;
              });
            })
          );
        });

        return Promise.all(serviceRequests).then(() => {
          const collection = collections[products];
          collection.reset(apps.map((app) => StitchApplication.fromApiResponse(app)));
          return collection;
        });
      });
    });
  };

function getAtlasTriggersApplicationCache() {
  if (!atlasTriggersApplicationCache) {
    atlasTriggersApplicationCache = new Cache({ load: buildApplicationLoader(atlasTriggerApplications) });
  }
  return atlasTriggersApplicationCache;
}

function getDataAPIApplicationCache() {
  if (!dataAPIApplicationCache) {
    dataAPIApplicationCache = new Cache({ load: buildApplicationLoader(dataAPIApplications) });
  }
  return dataAPIApplicationCache;
}

function getApplicationsCache() {
  if (!applicationsCache) {
    applicationsCache = new Cache({ load: buildApplicationLoader(allApplications) });
  }
  return applicationsCache;
}

export default {
  loadAtlasTriggersApplication({ groupId, baasUrl, username }) {
    return getAtlasTriggersApplicationCache().get({ groupId, baasUrl, username });
  },

  reloadAtlasTriggersApplication({ groupId, baasUrl, username }) {
    return getAtlasTriggersApplicationCache().refresh({ groupId, baasUrl, username });
  },

  loadDataAPIApplication({ groupId, baasUrl, username }) {
    return getDataAPIApplicationCache().get({ groupId, baasUrl, username });
  },

  reloadDataAPIApplication({ groupId, baasUrl, username }) {
    return getDataAPIApplicationCache().refresh({ groupId, baasUrl, username });
  },

  loadApplications({ groupId, baasUrl, username }) {
    return getApplicationsCache().get({ groupId, baasUrl, username });
  },

  reloadApplications({ groupId, baasUrl, username }) {
    return getApplicationsCache().refresh({ groupId, baasUrl, username });
  },

  authForRedirect(groupId, username, baasUrl) {
    return refreshAdminClient({ groupId, username, baasUrl });
  },

  validateSession(settings) {
    // If this promise resolves successfully then we are guaranteed
    // to have a valid session
    return getAuthedAdminClient(settings);
  },

  createApplication(
    appName,
    appDeploymentModel,
    appLocation,
    appProviderRegion,
    { groupId, baasUrl, username },
    product
  ) {
    return getAuthedAdminClient({ groupId, baasUrl, username })
      .then((client) => {
        const options = product && { product };
        return client.apps(groupId).create(
          {
            name: appName,
            deployment_model: appDeploymentModel,
            location: appLocation,
            provider_region: appProviderRegion,
          },
          options
        );
      })
      .then((app) => StitchApplication.fromApiResponse(app));
  },

  generateDataAPI({ groupId, baasUrl, username }, appId) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.private().group(groupId).app(appId).generate(dataAPITemplate)
    );
  },

  createAndLinkDataLakeService({ groupId, baasUrl, username, appId, dataLakeName, serviceName }) {
    return getAuthedAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).services().create({
        name: serviceName,
        type: MongoDataSourceType.DataFederation,
        config: {
          dataLakeName,
        },
      })
    );
  },

  createAndLinkClusterService({ groupId, baasUrl, username, appId, clusterName, serviceName }) {
    return getAuthedAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).services().create({
        name: serviceName,
        type: MongoDataSourceType.Atlas,
        config: {
          clusterName,
        },
      })
    );
  },

  unlinkApplication({ groupId, baasUrl, username }, appId, serviceId) {
    return getAuthedAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).services().service(serviceId).remove()
    );
  },

  deleteApplication({ groupId, baasUrl, username }, appId) {
    return getAuthedAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).remove()
    );
  },

  getServices({ groupId, baasUrl, username }, appId) {
    return getAuthedAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).services().list()
    );
  },

  listSvcNamespaces({ groupId, baasUrl, username }, appId, svcId) {
    return getAuthedAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).services().service(svcId).runCommand(`list_namespaces`, {})
    );
  },

  getMongoServices({ groupId, baasUrl, username }, appId) {
    return getAuthedAdminClient({ groupId, baasUrl, username }).then((client) => {
      const serviceRequest = () => client.apps(groupId).app(appId).services();

      return serviceRequest()
        .list()
        .then((svcs) => {
          const atlasServices = svcs.filter(
            (svc) => svc.type === MongoDataSourceType.Atlas || svc.type === MongoDataSourceType.DataFederation
          );
          const configRequests = atlasServices.map((svc) => serviceRequest().service(svc._id).config().get());
          return Promise.all(configRequests).then((configs) =>
            configs.map((config, idx) => ({ ...atlasServices[idx], config }))
          );
        });
    });
  },

  // API Keys
  getAPIKeys({ groupId, baasUrl, username }, appId) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).apiKeys().list()
    );
  },

  createAPIKey({ groupId, baasUrl, username }, appId, key) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).apiKeys().create(key)
    );
  },

  deleteAPIKey({ groupId, baasUrl, username }, appId, apiKeyId) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).apiKeys().apiKey(apiKeyId).remove()
    );
  },

  enableAPIKeyProvider({ groupId, baasUrl, username }, appId) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client
        .apps(groupId)
        .app(appId)
        .authProviders()
        .create(
          new AuthProviderConfig({
            name: 'api-key',
            // @ts-expect-error ts-migrate(2322) FIXME: Type '"api-key"' is not assignable to type 'AuthPr... Remove this comment to see the full error message
            type: 'api-key',
            disabled: false,
          })
        )
    );
  },

  getAuthProviders({ groupId, baasUrl, username }, appId) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).authProviders().list()
    );
  },

  // Triggers
  getTriggers({ groupId, baasUrl, username }, appId, filter?: $TSFixMe) {
    return import('js/project/triggers/helpers/converters').then(({ fromRawTrigger }) => {
      return getAuthedAdminClient({ groupId, baasUrl, username })
        .then((client) => {
          return client.apps(groupId).app(appId).eventSubscriptions().list(filter);
        })
        .then((rawTriggers) => rawTriggers.map((trigger) => fromRawTrigger(trigger)));
    });
  },

  getTrigger({ groupId, baasUrl, username }, appId, triggerId) {
    return getAuthedAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).eventSubscriptions().eventSubscription(triggerId).get()
    );
  },

  createTrigger({ groupId, baasUrl, username }, appId, trigger) {
    return getAuthedAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).eventSubscriptions().create(trigger)
    );
  },

  updateTrigger({ groupId, baasUrl, username }, appId, triggerId, updatedTrigger) {
    return getAuthedAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).eventSubscriptions().eventSubscription(triggerId).update(updatedTrigger)
    );
  },

  removeTrigger({ groupId, baasUrl, username }, appId, triggerId) {
    return getAuthedAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).eventSubscriptions().eventSubscription(triggerId).remove()
    );
  },

  resumeTrigger({ groupId, baasUrl, username }, appId, triggerId, { disableToken }) {
    return getAuthedAdminClient({ groupId, baasUrl, username }).then((client) =>
      client
        .apps(groupId)
        .app(appId)
        .eventSubscriptions()
        .eventSubscription(triggerId)
        .resume({ disable_token: disableToken })
    );
  },

  getTriggerExecution({ groupId, baasUrl, username }, appId, triggerId) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).eventSubscriptions().eventSubscription(triggerId).execution()
    );
  },

  // Functions
  getFunctions({ groupId, baasUrl, username }, appId) {
    return getAuthedAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).functions().list()
    );
  },

  getFunction({ groupId, baasUrl, username }, appId, functionId) {
    return getAuthedAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).functions().function(functionId).get()
    );
  },

  createFunction({ groupId, baasUrl, username }, appId, func) {
    return getAuthedAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).functions().create(func)
    );
  },

  updateFunction({ groupId, baasUrl, username }, appId, funcId, updatedFunction) {
    return getAuthedAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).functions().function(funcId).update(updatedFunction)
    );
  },

  removeFunction({ groupId, baasUrl, username }, appId, functionId) {
    return getAuthedAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).functions().function(functionId).remove()
    );
  },

  executeDebugSource({ groupId, baasUrl, username }, appId, { userId, source, evalSource, runAsSystem }) {
    return getAuthedAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).debug().executeFunctionSource({ userId, source, evalSource, runAsSystem })
    );
  },

  // Logs
  getLogs({ groupId, baasUrl, username }, appId, filter) {
    return getAuthedAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).logs().list(filter)
    );
  },

  // Metrics
  getMetrics({ groupId, baasUrl, username }, appId, start, end, granularity) {
    return getAuthedAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).measurements({ start, end, granularity })
    );
  },

  // Dependencies
  upsertDependency({ groupId, baasUrl, username }, appId, dependencyName, version) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).dependencies().upsert(dependencyName, version)
    );
  },

  getDependencies({ groupId, baasUrl, username }, appId) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).dependencies().list()
    );
  },

  deleteDependency({ groupId, baasUrl, username }, appId, dependencyName) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).dependencies().delete(dependencyName)
    );
  },

  uploadDependencies({ groupId, baasUrl, username }, appId, filename, body) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).dependencies().put(filename, body)
    );
  },

  deleteAllDependencies({ groupId, baasUrl, username }, appId) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).dependencies().deleteAll()
    );
  },

  getDependencyDeployStatus({ groupId, baasUrl, username }, appId, filter) {
    return getAuthedAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).deploy().deployments().list(filter)
    );
  },

  getDependencyInstallStatus({ groupId, baasUrl, username }, appId) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) => {
      return client.apps(groupId).app(appId).dependencies().status();
    });
  },

  // Rules
  getDataSourceRules({ groupId, baasUrl, username }, appId, serviceId) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).services().service(serviceId).rules().list()
    );
  },

  deleteDataSourceRules({ groupId, baasUrl, username }, appId, serviceId) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).services().service(serviceId).rules().remove()
    );
  },

  // Default Rules
  getPresetRoleConfigs({ groupId, baasUrl, username }) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.rules().presetRoles().list()
    );
  },

  getServiceDefaultRule({ groupId, baasUrl, username }, appId, serviceId) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).services().service(serviceId).defaultRule().get()
    );
  },

  createServiceDefaultRule({ groupId, baasUrl, username }, appId, serviceId, newDefaultRule) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).services().service(serviceId).defaultRule().create(newDefaultRule)
    );
  },

  updateServiceDefaultRule({ groupId, baasUrl, username }, appId, serviceId, updatedDefaultRule) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).services().service(serviceId).defaultRule().update(updatedDefaultRule)
    );
  },

  // Data API Config
  getDataAPIConfig({ groupId, baasUrl, username }, appId) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).dataAPI().config().get()
    );
  },

  createDataAPIConfig({ groupId, baasUrl, username }, appId, newConfig) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).dataAPI().config().create(newConfig)
    );
  },

  updateDataAPIConfig({ groupId, baasUrl, username }, appId, updatedConfig) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.apps(groupId).app(appId).dataAPI().config().update(updatedConfig)
    );
  },

  getDataAPIVersions(groupId, baasUrl, username) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.dataAPI().versions().list()
    );
  },

  getNearestProviderRegion(groupId, baasUrl, username) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.providerRegions().nearest().get()
    );
  },

  getAppSPASettingsGlobal({ groupId, baasUrl, username }) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.private().spa().settings().global()
    );
  },

  getNearestAtlasAppRegion({ groupId, baasUrl, username }, provider, atlasRegion) {
    return getAuthedBaasAdminClient({ groupId, baasUrl, username }).then((client) =>
      client.private().provider(provider).atlasRegions(atlasRegion).nearestAppRegion().get()
    );
  },
};
