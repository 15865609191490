export default {
  // Calculates the distance between two coordinates on Earth using the Haversine formula.
  // See https://www.movable-type.co.uk/scripts/latlong.html for more information.
  calculateDistanceBetweenPointsInKM(lat1: number, lon1: number, lat2: number, lon2: number): number {
    const R = 6371; // earth's radius in KM
    const latitude1 = (lat1 * Math.PI) / 180; // convert to radians
    const latitude2 = (lat2 * Math.PI) / 180;
    const deltaLatitude = ((lat2 - lat1) * Math.PI) / 180;
    const deltaLongitude = ((lon2 - lon1) * Math.PI) / 180;

    const a =
      Math.sin(deltaLatitude / 2) * Math.sin(deltaLatitude / 2) +
      Math.cos(latitude1) * Math.cos(latitude2) * Math.sin(deltaLongitude / 2) * Math.sin(deltaLongitude / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c;
  },
};
