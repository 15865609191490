import { BackingCloudProvider, CloudProvider, NonServerlessCloudProvider } from '@packages/types/nds/provider';

export function isNonServerlessCloudProvider(provider: CloudProvider): provider is NonServerlessCloudProvider {
  return provider !== CloudProvider.SERVERLESS;
}

export function isBackingProvider(provider: string): provider is BackingCloudProvider {
  return (
    provider === BackingCloudProvider.AWS ||
    provider === BackingCloudProvider.AZURE ||
    provider === BackingCloudProvider.GCP
  );
}

export function toCloudProvider(provider: BackingCloudProvider): NonServerlessCloudProvider {
  switch (provider) {
    case BackingCloudProvider.AWS:
      return CloudProvider.AWS;
    case BackingCloudProvider.AZURE:
      return CloudProvider.AZURE;
    case BackingCloudProvider.GCP:
      return CloudProvider.GCP;
  }
}

export function toBackingCloudProvider(provider: CloudProvider): BackingCloudProvider {
  switch (provider) {
    case CloudProvider.AWS:
      return BackingCloudProvider.AWS;
    case CloudProvider.AZURE:
      return BackingCloudProvider.AZURE;
    case CloudProvider.GCP:
      return BackingCloudProvider.GCP;
    default:
      throw new Error(`Unknown Cloud Provider: ${provider}`);
  }
}
