import { Language as CodeLanguage } from '@leafygreen-ui/code';
import { ProviderRegion } from 'baas-admin-sdk';
import { OptionProps } from 'react-select';

import { ClusterDescription } from '@packages/types/nds/clusterDescription';
import { CloudProvider } from '@packages/types/nds/provider';

export interface DataAPILogItem {
  apiKeyId?: string;
  coId?: string;
  completed?: string;
  endpointRoute?: string;
  error?: string;
  errorCode?: string;
  errorDetails?: { stackTrace?: string };
  id: string;
  memTimeUsage?: number;
  messages?: Array<string>;
  started?: string;
  status?: number;
  url?: string;
}

export interface LanguageOption {
  language: CodeLanguage;
  displayName: string;
}

export interface RegionOptionTypes extends Partial<OptionProps> {
  label: string;
  value: ProviderRegion;
}

export interface CloudProviderOptionTypes extends Partial<OptionProps> {
  label: string;
  value: CloudProvider;
}

export declare enum MongoServiceStatus {
  Unlinked = 'UNLINKED',
  Linking = 'LINKING',
  Linked = 'LINKED',
  Error = 'ERROR',
}

export enum DataSourceServiceType {
  SVCTYPE_MONGODB = 'mongodb',
  SVCTYPE_MONGODB_ATLAS = 'mongodb-atlas',
  SVCTYPE_DATALAKE = 'datalake',
}

interface Namespace {
  database: string;
  collections: Array<string>;
}
export declare type Namespaces = Array<Namespace>;

export interface ClusterServiceConfig {
  serviceType: 'mongodb-atlas';
  clusterName: string;
  dbVersion?: string;
}

export interface DataLakeServiceConfig {
  serviceType: 'datalake';
  dataLakeName: string;
}

export interface SelfHostedMongoServiceConfig {
  serviceType: 'mongodb';
}

export interface LegacyServiceConfig {
  serviceType?: string;
  clusterName?: string;
  dataLakeName?: string;
}

export declare type MongoServiceConfig =
  | ClusterServiceConfig
  | DataLakeServiceConfig
  | SelfHostedMongoServiceConfig
  | LegacyServiceConfig;

export interface MongoService {
  _id: string;
  name: string;
  config?: MongoServiceConfig;
  status?: MongoServiceStatus;
  svcError?: string;
}

export interface LinkedDataSources {
  clusterDescriptions: Array<ClusterDescription>;
  mongoServices: Array<MongoService>;
}

export enum Environment {
  Prod = 'prod',
  Staging = 'staging',
  QA = 'qa',
  Dev = 'dev',
  Local = 'local',
}
