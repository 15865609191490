import { useEffect } from 'react';

import queryString from 'query-string';

import { Search } from '@packages/types/auth/search';

import intercomService from '@packages/intercom/intercomService';

import { useRequestParams } from 'js/common/context/RequestParamsContext';

export default function useIntercomService(history) {
  const requestParams = useRequestParams();

  const { intercomEnabled, intercomAppId, onPrem } = requestParams;

  const intercomSettings = { isIntercomEnabled: intercomEnabled, isOnPrem: onPrem, intercomAppId };

  const { signedOut } = queryString.parse(history.location.search) as Search;

  useEffect(() => {
    if (signedOut) {
      intercomService.clearWidgetChatHistory();
    }
    intercomService.createWidgetForLoggedOutUser(null, intercomSettings, history.location);

    const unlisten = history.listen((location, action) => {
      // Only update intercom when we update the URL route.
      // That coincides when the history action is not REPLACE, i.e. either
      // PUSH or POP
      if (action && action !== 'REPLACE') {
        intercomService.updateWidgetForLoggedOutUser(null, intercomSettings, history.location);
      }
    });

    return () => unlisten();
  }, []);
}
