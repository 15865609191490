import { Language } from '@leafygreen-ui/code';
import { DeploymentModel, Location, MongoDBBaseRule, MongoDBRuleRole, ProviderRegion } from 'baas-admin-sdk';

import { CloudProviderOptionTypes, LanguageOption, RegionOptionTypes } from '@packages/types/dataAPI';
import { CloudProvider } from '@packages/types/nds/provider';

export enum LanguageType {
  Curl = 'cURL',
  Python = 'Python',
  Java = 'Java',
  Go = 'Go',
  Node = 'Node',
  CSharp = 'C#',
}

export const languageOptions: Array<LanguageOption> = [
  {
    language: Language.Bash,
    displayName: LanguageType.Curl,
  },
  {
    language: Language.Python,
    displayName: LanguageType.Python,
  },
  {
    language: Language.Java,
    displayName: LanguageType.Java,
  },
  {
    language: Language.Go,
    displayName: LanguageType.Go,
  },
  {
    language: Language.JavaScript,
    displayName: LanguageType.Node,
  },
  {
    language: Language.Csharp,
    displayName: LanguageType.CSharp,
  },
];

export enum DataAPIVersion {
  Beta = 'beta',
  V1 = 'v1',
}

export const DataAPIVersionDisplayName = {
  [DataAPIVersion.Beta]: 'Beta',
  [DataAPIVersion.V1]: 'V1',
};

// Postman variables
// Please do NOT edit these variables unless you got them directly from the MongoDB DevRel Postman workspace
export const DATA_API_POSTMAN_WORKSPACE_ID = '17898583-25682080-e247-4d25-8e5c-1798461c7db4';
export const DATA_API_POSTMAN_COLLECTION_URL =
  'entityId=17898583-25682080-e247-4d25-8e5c-1798461c7db4&entityType=collection&workspaceId=8355a86e-dec2-425c-9db0-cb5e0c3cec02';

export enum AccessName {
  DenyAllAccess = 'denyAllAccess',
  ReadOnly = 'readAll',
  ReadAndWrite = 'readAndWriteAll',
  CustomAccess = 'customAccess',
}

export const AccessDisplayName = {
  [AccessName.DenyAllAccess]: 'No Access',
  [AccessName.ReadOnly]: 'Read Only',
  [AccessName.ReadAndWrite]: 'Read and Write',
  [AccessName.CustomAccess]: 'Custom Access',
};

export const deploymentModelDisplayNames = {
  [DeploymentModel.Global]: 'Global',
  [DeploymentModel.Local]: 'Local',
};

export const regionOptionNames = {
  [ProviderRegion.AWSProviderRegionUSEast1]: 'Virginia (us-east-1)',
  [ProviderRegion.AWSProviderRegionUSWest2]: 'Oregon (us-west-2)',
  [ProviderRegion.AWSProviderRegionEUWest1]: 'Ireland (eu-west-1)',
  [ProviderRegion.AWSProviderRegionAPSoutheast2]: 'Sydney (ap-southeast-2)',
  [ProviderRegion.AWSProviderRegionEUCentral1]: 'Frankfurt (eu-central-1)',
  [ProviderRegion.AWSProviderRegionAPSouth1]: 'Mumbai (ap-south-1)',
  [ProviderRegion.AWSProviderRegionAPSoutheast1]: 'Singapore (ap-southeast-1)',
  [ProviderRegion.AWSProviderRegionUSEast2]: 'Ohio (us-east-2)',
  [ProviderRegion.AWSProviderRegionEUWest2]: 'London (eu-west-2)',
  [ProviderRegion.AWSProviderRegionSAEast1]: 'São Paulo (sa-east-1)',
  [ProviderRegion.AzureProviderRegionEastUS2]: 'Virginia (eastus2)',
  [ProviderRegion.AzureProviderRegionWestUS]: 'California (westus)',
  [ProviderRegion.AzureProviderRegionWestEurope]: 'Netherlands (westeurope)',
  [ProviderRegion.AzureProviderRegionSouthEastAsia]: 'Singapore (southeastasia)',
  [ProviderRegion.AzureProviderRegionEastAsia]: 'Hong Kong (eastasia)',
  [ProviderRegion.GCPProviderRegionUSCentral1]: 'Ohio (us-central1)',
  [ProviderRegion.GCPProviderRegionUSEast4]: 'Virginia (us-east4)',
  [ProviderRegion.GCPProviderRegionUSWest1]: 'Oregon (us-west1)',
  [ProviderRegion.GCPProviderRegionEuropeWest1]: 'Belgium (europe-west1)',
  [ProviderRegion.GCPProviderRegionAsiaSouth1]: 'Mumbai (asia-south1)',
};

export const awsBaseRegionOptions: Array<RegionOptionTypes> = [
  ProviderRegion.AWSProviderRegionUSEast1,
  ProviderRegion.AWSProviderRegionUSWest2,
  ProviderRegion.AWSProviderRegionEUWest1,
  ProviderRegion.AWSProviderRegionAPSoutheast2,
].map((name) => ({ value: name, label: regionOptionNames[name] }));

export const awsLocalRegionOptions: Array<RegionOptionTypes> = [
  ...awsBaseRegionOptions,
  ...[
    ProviderRegion.AWSProviderRegionEUCentral1,
    ProviderRegion.AWSProviderRegionAPSouth1,
    ProviderRegion.AWSProviderRegionAPSoutheast1,
    ProviderRegion.AWSProviderRegionEUWest2,
    ProviderRegion.AWSProviderRegionUSEast2,
    ProviderRegion.AWSProviderRegionSAEast1,
  ].map((name) => ({ value: name, label: regionOptionNames[name] })),
];

export const azureLocalRegionOptions: Array<RegionOptionTypes> = [
  ProviderRegion.AzureProviderRegionEastUS2,
  ProviderRegion.AzureProviderRegionEastAsia,
  ProviderRegion.AzureProviderRegionWestUS,
  ProviderRegion.AzureProviderRegionWestEurope,
  ProviderRegion.AzureProviderRegionSouthEastAsia,
].map((name) => ({ value: name, label: regionOptionNames[name] }));

export const gcpLocalRegionOptions: Array<RegionOptionTypes> = [
  ProviderRegion.GCPProviderRegionUSEast4,
  ProviderRegion.GCPProviderRegionUSCentral1,
  ProviderRegion.GCPProviderRegionUSWest1,
  ProviderRegion.GCPProviderRegionEuropeWest1,
  ProviderRegion.GCPProviderRegionAsiaSouth1,
].map((name) => ({ value: name, label: regionOptionNames[name] }));

export const cloudProviderOptions: Array<CloudProviderOptionTypes> = [
  { value: CloudProvider.AWS, label: CloudProvider.AWS.toUpperCase() },
  { value: CloudProvider.AZURE, label: CloudProvider.AZURE.toUpperCase() },
  { value: CloudProvider.GCP, label: CloudProvider.GCP.toUpperCase() },
];

export const localRegionOptionsByCloudProvider = {
  [CloudProvider.AWS]: awsLocalRegionOptions,
  [CloudProvider.AZURE]: azureLocalRegionOptions,
  [CloudProvider.GCP]: gcpLocalRegionOptions,
};

export const providerRegionToLocation = {
  [ProviderRegion.AWSProviderRegionUSEast1]: Location.Virginia,
  [ProviderRegion.AWSProviderRegionUSWest2]: Location.Oregon,
  [ProviderRegion.AWSProviderRegionEUCentral1]: Location.Frankfurt,
  [ProviderRegion.AWSProviderRegionEUWest1]: Location.Ireland,
  [ProviderRegion.AWSProviderRegionAPSoutheast1]: Location.Singapore,
  [ProviderRegion.AWSProviderRegionAPSoutheast2]: Location.Sydney,
  [ProviderRegion.AWSProviderRegionAPSouth1]: Location.Mumbai,
  [ProviderRegion.AWSProviderRegionEUWest2]: Location.Ireland,
  [ProviderRegion.AWSProviderRegionUSEast2]: Location.Virginia,
  [ProviderRegion.AWSProviderRegionSAEast1]: Location.SaoPaulo,
  [ProviderRegion.AzureProviderRegionEastUS2]: Location.Virginia,
  [ProviderRegion.AzureProviderRegionWestUS]: Location.Oregon,
  [ProviderRegion.AzureProviderRegionWestEurope]: Location.Frankfurt,
  [ProviderRegion.AzureProviderRegionEastAsia]: Location.Mumbai,
  [ProviderRegion.AzureProviderRegionSouthEastAsia]: Location.Singapore,
  [ProviderRegion.GCPProviderRegionUSCentral1]: Location.Virginia,
  [ProviderRegion.GCPProviderRegionUSEast4]: Location.Virginia,
  [ProviderRegion.GCPProviderRegionUSWest1]: Location.Oregon,
  [ProviderRegion.GCPProviderRegionEuropeWest1]: Location.Frankfurt,
  [ProviderRegion.GCPProviderRegionAsiaSouth1]: Location.Mumbai,
};
export const noAccessRole = new MongoDBRuleRole({
  name: 'denyAllAccess',
  applyWhen: {},
  read: false,
  write: false,
  insert: false,
  delete: false,
  search: false,
});

export const noAccessDefaultRule = new MongoDBBaseRule({
  filters: [],
  roles: [noAccessRole],
});

export const readAndWriteAccessRole = new MongoDBRuleRole({
  name: 'readAndWriteAll',
  applyWhen: {},
  fields: {},
  read: true,
  write: true,
  insert: true,
  delete: true,
  search: true,
});

export const readAndWriteDefaultRule = new MongoDBBaseRule({
  filters: [],
  roles: [readAndWriteAccessRole],
});

export enum AdvancedBaasFeature {
  DataAPISettings = 'data-api-settings',
  Rules = 'rules',
  Auth = 'auth',
  AppSettings = 'app-settings',
}
