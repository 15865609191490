import { createSelector, createStructuredSelector } from 'reselect';

import { PaymentMethodStatus } from '@packages/types/billing';
import { BillingMetadata, PaymentMethod } from '@packages/types/billing';
import { GroupId } from '@packages/types/groups';
import { OrganizationId } from '@packages/types/organizations';
import { VercelInfo } from '@packages/types/vercel';

import * as api from 'js/common/services/api';
import * as settingsHelpers from 'js/common/utils/settingsHelpers';
import { IdentifyParams } from 'js/common/utils/segmentAnalytics';

import * as backboneReduxSync from '../backboneReduxSync';
import * as group from './group';
import * as organization from './organization';
import * as organizationAlert from './organization/alert';
import * as organizationBilling from './organization/billing';
import * as organizationGroup from './organization/group';
// modules
import * as settings from './settings';
import * as team from './team';
import * as user from './user';
import * as viewer from './viewer';
import { loadCostExplorerFilterOptions } from './organization/billing';

const {
  actionTypes: { SYNC_CHANGES_FROM_BACKBONE, SYNC_FROM_BACKBONE },
} = backboneReduxSync;

const LOAD_INIT_DATA = 'app/LOAD_INIT_DATA';
const SET_ACTIVE_ORG_ID = 'app/SET_ACTIVE_ORG_ID';
const SET_IS_GOTO_OPEN = 'app/SET_IS_GOTO_OPEN';
const SET_SEARCH_TAB_REF = 'app/SET_SEARCH_TAB_REF';
const TOGGLE_FEATURE_UPDATES_FEED = 'app/TOGGLE_FEATURE_UPDATES_FEED';
const SET_FEATURE_UPDATES_UNREAD_COUNT = 'app/SET_FEATURE_UPDATES_UNREAD_COUNT';
const LOAD_UI_MESSAGES = 'app/LOAD_UI_MESSAGES';
const LOAD_ACTIVITY_METADATA = 'app/LOAD_ACTIVITY_METADATA';
const LOAD_BILLING_METADATA = 'app/LOAD_BILLING_METADATA';
const SET_ACTIVE_GROUP_ID = 'app/SET_ACTIVE_GROUP_ID';

const AVAILABLE_CREDITS_FILTER = 'available';
const HISTORICAL_CREDITS_FILTER = 'historical';

const initialState = {
  activeGroupId: null,
  activeOrgId: null,
  uiMessages: [],
  hasContinuousBackup: false,
  isGoToOpen: false,
  searchTabRef: null,
  isFeatureUpdatesFeedOpen: false,
  featureUpdatesUnreadCount: null,
  activityMetadata: {
    eventTypeInfo: {},
    categoryDisplayNames: {},
    categoryIcons: {},
    newCategoryIcons: {},
    categoryIconSvgFiles: {},
    hostTypes: {},
    metrics: {},
    metricNames: {},
    units: {},
    unitNames: {},
    sourceDisplayNames: {},
    orgActivityFeedCategories: {},
    projectActivityFeedCategories: {},
  },
  billingMetadata: {
    skuInfo: {},
    skuUnitsSingular: {},
    skuUnitsPlural: {},
    planTypes: {},
    summaryGroups: {},
    awsRegionNames: {},
    gcpRegionNames: {},
    azureRegionNames: {},
    flexConsultingDepths: {},
  },
};

const backboneToReduxKeyMapping = {
  GROUP_ID: 'activeGroupId',
  ORG_ID: 'activeOrgId',
  HAS_CONTINUOUS_BACKUP: 'hasContinuousBackup',
};

// Reducer
export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ACTIVE_ORG_ID: {
      return {
        ...state,
        activeOrgId: action.payload,
      };
    }
    case LOAD_UI_MESSAGES: {
      const newState = { ...state };
      newState.uiMessages = action.payload;

      return newState;
    }
    case LOAD_ACTIVITY_METADATA: {
      const newState = { ...state };
      newState.activityMetadata = action.payload;

      return newState;
    }
    case LOAD_BILLING_METADATA: {
      const newState = { ...state };
      newState.billingMetadata = action.payload;

      return newState;
    }
    case SET_IS_GOTO_OPEN: {
      const { isGoToOpen } = action.payload;

      return {
        ...state,
        isGoToOpen,
      };
    }
    case SET_SEARCH_TAB_REF: {
      const { searchTabRef } = action.payload;

      return {
        ...state,
        searchTabRef,
      };
    }
    case TOGGLE_FEATURE_UPDATES_FEED: {
      const { isFeatureUpdatesFeedOpen } = action.payload;

      return {
        ...state,
        isFeatureUpdatesFeedOpen,
      };
    }
    case SET_FEATURE_UPDATES_UNREAD_COUNT: {
      const { featureUpdatesUnreadCount } = action.payload;

      return {
        ...state,
        featureUpdatesUnreadCount,
      };
    }
    case SYNC_FROM_BACKBONE: {
      return backboneReduxSync.handleSyncFromBackbone(state, action, backboneToReduxKeyMapping);
    }
    case SYNC_CHANGES_FROM_BACKBONE: {
      return backboneReduxSync.handleSyncChangesFromBackbone(state, action, backboneToReduxKeyMapping);
    }
    case SET_ACTIVE_GROUP_ID: {
      return {
        ...state,
        activeGroupId: action.payload,
      };
    }
    default:
      return state;
  }
}

// Selectors
export const getUIMessages = (state) => state.app.uiMessages;
export const getActivityMetadata = (state) => state.app.activityMetadata || {};
export const getBillingMetadata = (state): BillingMetadata => state.app.billingMetadata;
export const hasContinuousBackup = (state) => state.app.hasContinuousBackup;
export const getIsGoToOpen = (state) => state.app.isGoToOpen;
export const getSearchTabRef = (state) => state.app.searchTabRef;
export const getIsFeatureUpdatesFeedOpen = (state) => state.app.isFeatureUpdatesFeedOpen;
export const getFeatureUpdatesUnreadCount = (state) => state.app.featureUpdatesUnreadCount;

export const getInfoForEventType = (state, { eventType }) => {
  const activityMetadata = getActivityMetadata(state);
  if (!activityMetadata) {
    return null;
  }

  return activityMetadata.eventTypeInfo[eventType];
};

const getActiveOrgId = (state): string => state.app.activeOrgId;
const getActiveOrg = (state) => organization.getOrgData(state, { orgId: getActiveOrgId(state) });
export const getActiveOrgAlerts = (state) => organizationAlert.getAlerts(state, { orgId: getActiveOrgId(state) }) || {};
export const getActiveOrgGroups = (state) => getActiveOrg(state).group;

export const getActiveOrgOpenAlertsCount = (state) =>
  organizationAlert.getOpenAlertsCount(state, { orgId: getActiveOrgId(state) }) || 0;

export const getActiveOrgAlertPagination = (state) =>
  organizationAlert.getAlertPagination(state, { orgId: getActiveOrgId(state) }) || {};
export const getActiveOrgCreated = (state) => getActiveOrg(state).data.created;
export const getActiveOrgDeleted = (state) => getActiveOrg(state).data.deleted;
export const getActiveOrgName = (state) => getActiveOrg(state).data.name;
export const getActiveOrgBillingEmail = (state) => getActiveOrg(state).data.billingEmail;
export const isActiveOrgBypassAccessAutoRestriction = (state) =>
  getActiveOrg(state).data.autoRestrictAccessInfo
    ? getActiveOrg(state).data.autoRestrictAccessInfo.bypassAccessAutoRestriction
    : false;
export const getActiveOrgPlanType = (state) => getActiveOrgPlan(state).planType;
export const getActiveOrgEffectivePlanType = (state) => getActiveOrgPlan(state).effectivePlanType;
export const getActiveOrgGroupType = (state) => (getActiveOrg(state).data ? getActiveOrg(state).data.groupType : '');
export const isActiveOrgCloudManager = (state) => getActiveOrgGroupType(state) === 'CLOUD';
export const isActiveOrgAtlas = (state) => getActiveOrgGroupType(state) === 'NDS';
export const isActiveOrgOnPrem = (state) => getActiveOrgGroupType(state) === 'ONPREM';
export const isActiveOrgFreeTierPlan = (state) => getActiveOrgPlanType(state) === 'FREE_TIER';
export const getActiveOrgFreeUntil = (state) => getActiveOrgPlan(state).freeUntil;
export const getActiveOrgPlanStartDate = (state) => getActiveOrgPlan(state).startDate;
export const getActiveOrgPrepaidPlans = (state) => getActiveOrgBilling(state).prepaidPlans || [];
export const getActiveOrgNumTrialDaysRemaining = (state) => getActiveOrgPlan(state).numTrialDaysRemaining;
export const getActiveOrgNDSHourlyBillingLimitCents = (state) => getActiveOrg(state).data.ndsHourlyBillingLimitCents;
export const getActiveOrgNDSMaxOutstandingBillCents = (state) => getActiveOrg(state).data.ndsMaxOutstandingBillCents;
export const getActiveOrgNDSMaxOutstandingBillCapCents = (state) =>
  getActiveOrg(state).data.ndsMaxOutstandingBillCapCents;
export const getActiveOrgMultiFactorAuthRequired = (state) => getActiveOrg(state).data.multiFactorAuthRequired;
export const getActiveOrganizationName = (state) => getActiveOrg(state).data.name;
export const getActiveOrganizationPaymentStatus = (state) => getActiveOrg(state).data.paymentStatus.status;
export const getActiveOrganizationVATStatus = (state) => getActiveOrg(state).data.vatStatus;
export const getActiveOrgFederationSettings = (state) => getActiveOrg(state).data.federationSettings;
export const getActiveOrgDefaultServerType = (state) => getActiveOrg(state).data.defaultServerType;
export const getActiveOrgAcceptedCNRegionsTermsOfService = (state) =>
  getActiveOrg(state).data.acceptedCNRegionsTermsOfServiceDate != null;
export const getActiveOrgVercelInfo = (state): VercelInfo => getActiveOrg(state).data.vercelInfo;
export const getActiveOrgVercelInfoEmail = (state): string => getActiveOrgVercelInfo(state)?.vercelEmail;
export const getActiveOrgVercelInfoUserId = (state): string => getActiveOrgVercelInfo(state)?.vercelUserId;
export const getActiveOrgVercelInfoTeamName = (state): string | undefined =>
  getActiveOrgVercelInfo(state)?.vercelTeamName;
export const getActiveOrgVercelInfoTeamId = (state): string | undefined => getActiveOrgVercelInfo(state)?.vercelTeamId;
export const getActiveOrgVercelInfoCreated = (state): string => getActiveOrgVercelInfo(state)?.created;

export const getDocumentBaseTitle = (state) => {
  const hasActiveOrg = !!getActiveOrgId(state);
  if (!hasActiveOrg) {
    return 'MongoDB Cloud Services';
  }

  const planType = getActiveOrgPlanType(state);
  if (settingsHelpers.isNDSPlan(planType)) {
    return 'Atlas: MongoDB Atlas';
  }
  return 'Cloud: MongoDB Cloud';
};

const getActiveOrgBilling = (state) => organizationBilling.getData(state, { orgId: getActiveOrgId(state) });

export const getActiveOrgPlan = (state) => getActiveOrgBilling(state).plan || {};
export const getActiveOrgCompanyAddress = (state) => getActiveOrgBilling(state).companyAddress;
export const getActiveOrgPaymentMethods = (state) => getActiveOrgBilling(state).paymentMethods;
export const getActiveOrgPendingInvoice = (state) => getActiveOrgBilling(state).pendingInvoice;
export const getActiveOrgRecentInvoice = (state) => getActiveOrgBilling(state).recentInvoice;
export const getOrgPreferences = (state) => getActiveOrgBilling(state).orgPreferences;

export const getActiveOrgActivePaymentMethod = createSelector(
  getActiveOrgPaymentMethods,
  (paymentMethods: Array<PaymentMethod>) =>
    paymentMethods && paymentMethods.find((p) => p.status === PaymentMethodStatus.ACTIVE)
);
export const getActiveOrgPendingPaymentMethod = createSelector(
  getActiveOrgPaymentMethods,
  (paymentMethods: Array<PaymentMethod>) =>
    paymentMethods && paymentMethods.find((p) => p.status === PaymentMethodStatus.PENDING_ACTIVATION)
);
export const getActiveOrgEffectivePaymentMethod = createSelector(
  [getActiveOrgActivePaymentMethod, getActiveOrgPendingPaymentMethod],
  (activePaymentMethod: PaymentMethod, pendingPaymentMethod: PaymentMethod): any =>
    activePaymentMethod || pendingPaymentMethod
);

export const getActiveOrgCredits = (state) =>
  getActiveOrgBilling(state).credits
    ? getActiveOrgBilling(state).credits.sort((x, y) => x.startDate - y.startDate)
    : [];

export const getActiveOrgAvailableCredits = (state) => getActiveOrgBilling(state).availableCredits || [];

export const getActiveOrgHistoricalCredits = (state) => getActiveOrgBilling(state).historicalCredits || [];

export const getActiveOrgMonthlyCommitmentCredits = (state) =>
  getActiveOrgBilling(state).monthlyCommitmentCredits
    ? getActiveOrgBilling(state).monthlyCommitmentCredits.sort((x, y) => x.startDate - y.startDate)
    : [];

export const getActiveOrgAvailableDiscounts = (state) => getActiveOrgBilling(state).discounts;
export const getActiveOrgHistoricalDiscounts = (state) => getActiveOrgBilling(state).historicDiscounts;
export const getActiveOrgUsageSummary = (state) => getActiveOrgBilling(state).usageSummary;
export const getActiveOrgBillingStatus = (state) => getActiveOrgBilling(state).billingStatus;
export const getCrossOrgTotal = (state) => getActiveOrgBilling(state).crossOrgTotal;
export const getActiveOrgBraintreeCustomer = (state) => getActiveOrgBilling(state).braintreeCustomer;
export const getPayingOrgView = (state) => getActiveOrgBilling(state).payingOrgView;
export const getLinkableOrganizations = (state) => getActiveOrgBilling(state).linkableOrganizations;
export const getActiveOrgLinkedOrganizations = (state) => getActiveOrgBilling(state).linkedOrganizations;
export const getActiveOrgPayingOrganization = (state) => getActiveOrgBilling(state).payingOrganization;
export const getIsEligibleToBecomePayingOrg = (state) => getActiveOrgBilling(state).isEligibleToBecomePayingOrg;
export const getMonthlyAggregatedUsageSummary = (state) => getActiveOrgBilling(state).monthlyAggregatedUsageSummary;
export const getOrgActivities = (state) => getActiveOrg(state).activities;
export const getOrgActivityTypes = (state) => getActiveOrg(state).activityTypes;
export const isPremiumPlanType = (state) => settings.getPlanType(state) === 'PREMIUM';
export const isOnPremPlanType = (state) => settings.getPlanType(state) === 'ONPREM';
export const getOrganizationSalesforceBillingMetadata = (state) =>
  getActiveOrgBilling(state).organizationSalesforceMetadata;
export const getActiveGroupId = (state): GroupId => state.app.activeGroupId;

// Takes a selector that needs a groupId param, and returns a selector that injects the active group.
const forActiveGroup = (groupSelector) => (state) => groupSelector(state, { groupId: getActiveGroupId(state) });

export const getActiveGroup = forActiveGroup(group.getGroup);
export const getActiveGroupName = (state) => getActiveGroup(state).name;
export const getActiveGroupTimeZoneId = (state) => getActiveGroup(state).defaultTimeZoneId;
export const getActiveGroupTimeZoneDisplay = (state) => getActiveGroup(state).defaultTimeZoneDisplay;
export const getActiveGroupTimeZoneDisplayShort = (state) => getActiveGroup(state).defaultTimeZoneDisplayShort;
export const getActiveGroupPlanType = (state) => getActiveGroup(state).groupType;
export const getActiveGroupIsNDSGroup = (state) => getActiveGroup(state).groupType === 'NDS';
export const getActiveGroupUseCNRegionsOnly = (state) => !!getActiveGroup(state).useCNRegionsOnly;
export const getActiveGroupTeamIds = forActiveGroup(group.getGroupTeamIds);
export const getActiveGroupUserIds = forActiveGroup(group.getGroupUserIds);
export const getActiveGroupTeamsError = forActiveGroup(group.getGroupTeamsError);
export const getActiveGroupUsersError = forActiveGroup(group.getGroupUsersError);
export const activeGroupTeamsLoading = forActiveGroup(group.groupTeamsLoading);
export const activeGroupUsersLoading = forActiveGroup(group.groupUsersLoading);
export const activeGroupAddIsLoading = forActiveGroup(group.groupAddIsLoading);
export const activeGroupAddError = forActiveGroup(group.groupAddError);

export const canViewUI = (state) => getActiveGroup(state).hasActiveUI || viewer.isGlobalReadOnly(state);
export const isGlobalReadOnly = (state) => viewer.isGlobalReadOnly(state);
// Return a full representation of all groups within an organization, not just the ids.
export const getActiveOrgHydratedGroups = (state) =>
  organizationGroup.getHydratedGroups(state, {
    orgId: getActiveOrgId(state),
  });

// Return a full representation of all users within an organization, not just the usernames.
export const getActiveOrgHydratedUsers = (state) =>
  organization.getHydratedUsers(state, {
    orgId: getActiveOrgId(state),
  });

// Return a full representation of all invoices within an organization, not just the ids.
export const getActiveOrgHydratedInvoices = (state) =>
  organizationBilling.getHydratedInvoices(state, {
    orgId: getActiveOrgId(state),
  });

// Return a full representation of all invoices within an organization, not just the ids.
export const getActiveOrgLinkedInvoices = (state) =>
  organizationBilling.getLinkedInvoices(state, {
    orgId: getActiveOrgId(state),
  });

// Return a full representation of all teams within an organization, not just the ids.
export const getActiveOrgHydratedTeams = (state) =>
  organization.getHydratedTeams(state, {
    orgId: getActiveOrgId(state),
  });

// Return a full representation of all saved Alert Settings within an organization, not just the ids.
export const getActiveOrgHydratedAlertSettings = (state) =>
  organizationAlert.getHydratedSavedAlertConfigurations(state, {
    orgId: getActiveOrgId(state),
  });

// Return a full representation of all deleted Alert Settings within an organization, not just the ids.
export const getActiveOrgHydratedDeletedAlertSettings = (state) =>
  organizationAlert.getHydratedDeletedAlertConfigurations(state, {
    orgId: getActiveOrgId(state),
  });

// Return a full representation of all users within the active group, not just the ids.
export const getActiveGroupHydratedUsers = forActiveGroup(group.getHydratedUsers);

export const getActiveGroupHydratedTeams = forActiveGroup(group.getHydratedTeams);

export const getActiveGroupOwnerCount = (state) => {
  const groupMembers = [...getActiveGroupHydratedUsers(state), ...getActiveGroupHydratedTeams(state)];
  return groupMembers.filter((member) => !member.isInvite && member.roles?.includes('GROUP_OWNER')).length;
};

export const getActiveGroupUserOrTeamFetchError = (state) => {
  return getActiveGroupUsersError(state) || getActiveGroupTeamsError(state);
};

export const getActiveOrgUrl = (state, relativePath = '') => {
  const orgId = getActiveOrgId(state);

  if (relativePath) {
    relativePath = `/${relativePath}`;
  }

  return `/v2#/org/${orgId}${relativePath}`;
};

export const getSegmentIdentifyParams = createStructuredSelector<IdentifyParams, any>({
  first_name: viewer.getFirstName,
  last_name: viewer.getLastName,
  email: viewer.getUsername,
  active_project_id: getActiveGroupId,
  is_cloud_manager_user: (state) => isActiveOrgCloudManager(state),
});

// Action Creators
const setActiveOrgId = (payload) => ({
  type: SET_ACTIVE_ORG_ID,
  payload,
});

const setActiveGroupId = (payload) => ({
  type: SET_ACTIVE_GROUP_ID,
  payload,
});

export { getActiveOrgId, getActiveOrg, setActiveOrgId, setActiveGroupId };

interface LoadInitData {
  orgId?: OrganizationId;
  groupId?: GroupId;
}

export const loadInitData =
  ({ orgId, groupId }: LoadInitData = {}) =>
  (dispatch) => {
    dispatch({
      type: LOAD_INIT_DATA,
    });

    return api.v2.params({ orgId, groupId }).then((response) => {
      const { appUser, userRoles, ...settingsValues } = response;

      dispatch(settings.resetState(settingsValues));
      dispatch(viewer.resetState({ ...appUser, ...userRoles }));
      return response;
    });
  };

export const loadUIMessages = () => async (dispatch) => {
  const messages = await api.admin.getUiMsgs();

  dispatch({
    type: LOAD_UI_MESSAGES,
    payload: messages,
  });
};

export const loadActivityMetadata = () => async (dispatch) => {
  const metadata = await api.activity.getMetadata();

  dispatch({
    type: LOAD_ACTIVITY_METADATA,
    payload: metadata,
  });
};

export const loadBillingMetadata = () => async (dispatch) => {
  const metadata = await api.billing.getMetadata();

  dispatch({
    type: LOAD_BILLING_METADATA,
    payload: metadata,
  });
};

export const setIsGoToOpen = (dispatch, isGoToOpen) => {
  dispatch({
    type: SET_IS_GOTO_OPEN,
    payload: { isGoToOpen },
  });
};

export const setSearchTabRef = (dispatch, searchTabRef) => {
  dispatch({
    type: SET_SEARCH_TAB_REF,
    payload: { searchTabRef },
  });
};

export const toggleFeatureUpdatesFeed = (dispatch, isFeatureUpdatesFeedOpen) => {
  dispatch({
    type: TOGGLE_FEATURE_UPDATES_FEED,
    payload: { isFeatureUpdatesFeedOpen },
  });
};

export const setFeatureUpdatesUnreadCount = (dispatch, featureUpdatesUnreadCount) => {
  dispatch({
    type: SET_FEATURE_UPDATES_UNREAD_COUNT,
    payload: { featureUpdatesUnreadCount },
  });
};

// Active org actions

export const loadAndSetActiveOrg = (orgId) => (dispatch) => {
  return dispatch(organization.loadOrg(orgId)).then(() => dispatch(setActiveOrgId(orgId)));
};

export const updateActiveOrgUserRoles = (currentUser) => (dispatch, getState) => {
  return dispatch(user.saveUserRoles({ orgId: getActiveOrgId(getState()), user: currentUser }));
};

export const loadActiveOrg = () => (dispatch, getState) => {
  return dispatch(organization.loadOrg(getActiveOrgId(getState())));
};

export const loadActiveOrgPlan = () => (dispatch, getState) => {
  return dispatch(organizationBilling.loadPlan(getActiveOrgId(getState())));
};

export const loadActiveOrgPrepaidPlans = () => (dispatch, getState) => {
  return dispatch(organizationBilling.loadPrepaidPlans(getActiveOrgId(getState())));
};

export const loadActiveOrgGroups = () => (dispatch, getState) => {
  return dispatch(organizationGroup.loadGroups(getActiveOrgId(getState())));
};

export const loadActiveOrgGroupsWithUsers = () => (dispatch, getState) => {
  const orgId = getActiveOrgId(getState());
  const groupIds = getActiveOrgGroups(getState());
  return dispatch(group.loadUsersForGroups({ orgId, groupIds }));
};

export const loadActiveOrgOpenAlertCount = () => (dispatch, getState) => {
  return dispatch(organizationAlert.loadOpenAlertsCount(getActiveOrgId(getState())));
};

export const loadActiveOrgUsers = () => (dispatch, getState) => {
  return dispatch(organization.loadUsers(getActiveOrgId(getState())));
};

export const loadActiveOrgActivities = () => (dispatch, getState) => {
  return dispatch(organization.loadActivities({ orgId: getActiveOrgId(getState()) }));
};

export const loadActiveOrgTeams = () => (dispatch, getState) => {
  return dispatch(organization.loadTeams(getActiveOrgId(getState())));
};

export const deleteActiveOrgTeam = (teamId) => (dispatch, getState) =>
  dispatch(organization.deleteTeam({ orgId: getActiveOrgId(getState()), teamId }));

export const loadActiveOrgInvoices = () => (dispatch, getState) => {
  return dispatch(organizationBilling.loadInvoices(getActiveOrgId(getState())));
};

export const loadActiveOrgLinkedInvoices = () => (dispatch, getState) => {
  return dispatch(organizationBilling.loadLinkedInvoices(getActiveOrgId(getState())));
};

export const loadActiveOrgFederationSettings = () => (dispatch, getState) => {
  return dispatch(organization.loadFederationSettings(getActiveOrgId(getState())));
};

export const loadActiveOrgDefaultServerType = () => (dispatch, getState) => {
  return dispatch(organization.loadDefaultServerType(getActiveOrgId(getState())));
};

export const updateActiveOrgName = (orgName) => (dispatch, getState) => {
  return dispatch(organization.updateOrgName(getActiveOrgId(getState()), orgName));
};

export const updateActiveGroupName = (groupName) => (dispatch, getState) => {
  return dispatch(group.updateGroupName(getActiveGroupId(getState()), groupName));
};

export const updateGroupTimeZoneId = (timeZoneId, timeZoneDisplay) => (dispatch, getState) => {
  return dispatch(group.updateGroupTimeZoneId(getActiveGroupId(getState()), timeZoneId, timeZoneDisplay));
};

export const setActiveMultiFactorAuthRequired = (multiFactorAuthRequired) => (dispatch, getState) => {
  dispatch(organization.setMultiFactorAuthRequired(getActiveOrgId(getState()), multiFactorAuthRequired));
};

export const setActivePublicApiAccessListRequired = (publicApiWhitelistRequired) => (dispatch, getState) => {
  dispatch(organization.setPublicApiAccessListRequired(getActiveOrgId(getState()), publicApiWhitelistRequired));
};

export const setRestrictEmployeeAccess = (restrictEmployeeAccess) => (dispatch, getState) => {
  dispatch(organization.setRestrictEmployeeAccess(getActiveOrgId(getState()), restrictEmployeeAccess));
};

export const setBypassAccessAutoRestriction = (bypassAccessAutoRestriction) => (dispatch, getState) => {
  dispatch(organization.setBypassAccessAutoRestriction(getActiveOrgId(getState()), bypassAccessAutoRestriction));
};

export const acceptCNRegionsTermsOfService = () => (dispatch, getState) => {
  dispatch(organization.acceptCNRegionsTermsOfService(getActiveOrgId(getState())));
};

export const clearSlackToken = () => (dispatch, getState) => {
  return dispatch(organization.clearSlackToken(getActiveOrgId(getState())));
};

export const setMultiFactorAuthRequired = (multiFactorAuthRequired) => (dispatch, getState) => {
  dispatch(organization.setMultiFactorAuthRequired(getActiveOrgId(getState()), multiFactorAuthRequired));
};

export const loadActiveOrgActivePaymentMethods = () => (dispatch, getState) => {
  return dispatch(organizationBilling.loadActivePaymentMethods(getActiveOrgId(getState())));
};

export const loadActiveOrgPaymentMethods = () => (dispatch, getState) => {
  return dispatch(organizationBilling.loadPaymentMethods(getActiveOrgId(getState())));
};

export const loadActiveOrgCompanyAddress = () => (dispatch, getState) => {
  return dispatch(organizationBilling.loadCompanyAddress(getActiveOrgId(getState())));
};

export const loadOrgPreferences = () => (dispatch, getState) => {
  return dispatch(organizationBilling.loadOrgPreferences(getActiveOrgId(getState())));
};

export const loadActiveOrgVATStatus = () => (dispatch, getState) => {
  return dispatch(organization.loadVATStatus(getActiveOrgId(getState())));
};

export const updateActiveOrgVATStatus = (vatStatus) => (dispatch, getState) => {
  return dispatch(organization.updateVATStatus({ orgId: getActiveOrgId(getState()), vatStatus }));
};

export const loadActiveOrgPendingInvoice = () => (dispatch, getState) => {
  return dispatch(organizationBilling.loadPendingInvoice(getActiveOrgId(getState())));
};

export const loadActiveOrgRecentInvoice = () => (dispatch, getState) => {
  return dispatch(organizationBilling.loadRecentInvoice(getActiveOrgId(getState())));
};

export const loadActiveOrgCredits = () => (dispatch, getState) => {
  return dispatch(organizationBilling.loadCredits(getActiveOrgId(getState())));
};

export const loadActiveOrgAvailableCredits = () => (dispatch, getState) => {
  return dispatch(organizationBilling.loadCredits(getActiveOrgId(getState()), AVAILABLE_CREDITS_FILTER));
};

export const loadActiveOrgHistoricalCredits = () => (dispatch, getState) => {
  return dispatch(organizationBilling.loadCredits(getActiveOrgId(getState()), HISTORICAL_CREDITS_FILTER));
};

export const loadActiveOrgDiscounts = () => (dispatch, getState) => {
  return dispatch(organizationBilling.loadDiscounts(getActiveOrgId(getState())));
};

export const loadActiveOrgHistoricDiscounts = () => (dispatch, getState) => {
  return dispatch(organizationBilling.loadHistoricDiscounts(getActiveOrgId(getState())));
};

export const loadActiveOrgBillingStatus = () => (dispatch, getState) => {
  return dispatch(organizationBilling.loadBillingStatus(getActiveOrgId(getState())));
};
export const loadActiveOrgUsageSummary = () => (dispatch, getState) => {
  return dispatch(organizationBilling.loadUsageSummary(getActiveOrgId(getState())));
};

export const loadCrossOrgTotal = () => (dispatch, getState) => {
  return dispatch(organizationBilling.loadCrossOrgTotal(getActiveOrgId(getState())));
};

export const loadActiveOrgBraintreeCustomer = () => (dispatch, getState) => {
  return dispatch(organizationBilling.loadBraintreeCustomer(getActiveOrgId(getState())));
};

export const loadPayingOrgView = () => (dispatch, getState) => {
  return dispatch(organizationBilling.loadPayingOrgView(getActiveOrgId(getState())));
};

export const loadLinkableOrganizations = () => (dispatch, getState) => {
  return dispatch(organizationBilling.loadLinkableOrganizations(getActiveOrgId(getState())));
};

export const loadActiveOrgLinkedOrganizations = () => (dispatch, getState) => {
  return dispatch(organizationBilling.loadLinkedOrganizations(getActiveOrgId(getState())));
};

export const loadActiveOrgPayingOrganization = () => (dispatch, getState) => {
  return dispatch(organizationBilling.loadPayingOrganization(getActiveOrgId(getState())));
};

export const loadIsEligibleToBecomePayingOrg = () => (dispatch, getState) => {
  return dispatch(organizationBilling.loadIsEligibleToBecomePayingOrg(getActiveOrgId(getState())));
};

export const loadMonthlyAggregatedUsageSummary = () => (dispatch, getState) => {
  return dispatch(organizationBilling.loadMonthlyAggregatedUsageSummary(getActiveOrgId(getState())));
};

export const loadOrganizationSalesforceMetadata = () => (dispatch, getState) => {
  return dispatch(organizationBilling.loadOrganizationSalesforceMetadata(getActiveOrgId(getState())));
};

export const loadActiveOrgCostExplorerFilterOptions = () => (dispatch: any, getState: any) => {
  return dispatch(loadCostExplorerFilterOptions(getActiveOrgId(getState())));
};

// Active group actions
const actionForActiveGroup =
  (actionCreator) =>
  ({ ...params } = {}) =>
  (dispatch, getState) =>
    dispatch(
      actionCreator({
        groupId: getActiveGroupId(getState()),
        ...params,
      })
    );

export const grantGroupAccess =
  ({ users, teams, apiUsers }) =>
  (dispatch, getState) => {
    return dispatch(
      group.grantAccess({
        users,
        teams,
        apiUsers,
        orgId: getActiveOrgId(getState()),
        groupId: getActiveGroupId(getState()),
      })
    );
  };
export const grantOrgAccess =
  ({ users }) =>
  (dispatch, getState) => {
    return api.organization.addUsers({ users, orgId: getActiveOrgId(getState()) });
  };

export const loadActiveGroupTeams = actionForActiveGroup(group.loadGroupTeams);
export const loadActiveGroupUsers = actionForActiveGroup(group.loadGroupUsers);
export const removeUserFromActiveGroup = actionForActiveGroup(group.removeUserFromGroup);
export const deleteActiveGroupInvitation = actionForActiveGroup(group.deleteGroupInvitation);
export const saveActiveGroupTeamRoles = actionForActiveGroup(team.saveGroupTeamRoles);
export const saveActiveGroupUserRoles = actionForActiveGroup(user.saveGroupUserRoles);
export const clearActiveGroupErrorMessage = actionForActiveGroup(group.clearGroupErrorMessage);

export const loadActiveGroupUsersAndTeams = () => (dispatch) =>
  Promise.all([dispatch(loadActiveGroupTeams()), dispatch(loadActiveGroupUsers())]);
